import gql from "graphql-tag";
import * as VueApolloComposable from "@vue/apollo-composable";
import * as VueCompositionApi from "@vue/composition-api";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AddAttachmentsToCarInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddAttachmentsToContractInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddAttachmentsToContractPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddAttachmentsToDeletedMiddlemanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddAttachmentsToDeletedWorkerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddAttachmentsToMiddlemanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddAttachmentsToWorkerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddContactsToUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddCreatedCompaniesToUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddCreatedContractsToUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddCreatedMiddlemansToUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddCreatedProjectsToUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddCreatedWorkersToUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddHistoriesToCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddHistoriesToCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddHistoriesToDeletedCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddHistoriesToDeletedCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddHistoriesToProjectInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AddProjectChangesToUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type Attachment = {
  __typename?: "Attachment";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AttachmentAggregateGroupBy = {
  __typename?: "AttachmentAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AttachmentConnection = {
  __typename?: "AttachmentConnection";
  /** Array of nodes. */
  nodes: Array<Attachment>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type AttachmentCountAggregate = {
  __typename?: "AttachmentCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type AttachmentDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<AttachmentDeleteFilter>>;
  or?: Maybe<Array<AttachmentDeleteFilter>>;
};

export type AttachmentDeleteResponse = {
  __typename?: "AttachmentDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<AttachmentFilter>>;
  or?: Maybe<Array<AttachmentFilter>>;
};

export type AttachmentInput = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
};

export type AttachmentMaxAggregate = {
  __typename?: "AttachmentMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AttachmentMinAggregate = {
  __typename?: "AttachmentMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AttachmentSort = {
  direction: SortDirection;
  field: AttachmentSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum AttachmentSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Id = "Id",
  Name = "Name",
  UpdatedAt = "UpdatedAt",
}

export type AttachmentUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<AttachmentUpdateFilter>>;
  or?: Maybe<Array<AttachmentUpdateFilter>>;
};

export type Car = {
  __typename?: "Car";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Evidence?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  Type: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  attachments: Array<CarAttachment>;
  createdBy?: Maybe<User>;
};

export type CarAttachmentsArgs = {
  filter?: Maybe<CarAttachmentFilter>;
  sorting?: Maybe<Array<CarAttachmentSort>>;
};

export type CarAggregateGroupBy = {
  __typename?: "CarAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Evidence?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CarAttachment = {
  __typename?: "CarAttachment";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CarAttachmentAggregateGroupBy = {
  __typename?: "CarAttachmentAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CarAttachmentConnection = {
  __typename?: "CarAttachmentConnection";
  /** Array of nodes. */
  nodes: Array<CarAttachment>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type CarAttachmentCountAggregate = {
  __typename?: "CarAttachmentCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type CarAttachmentDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<CarAttachmentDeleteFilter>>;
  or?: Maybe<Array<CarAttachmentDeleteFilter>>;
};

export type CarAttachmentDeleteResponse = {
  __typename?: "CarAttachmentDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CarAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<CarAttachmentFilter>>;
  or?: Maybe<Array<CarAttachmentFilter>>;
};

export type CarAttachmentMaxAggregate = {
  __typename?: "CarAttachmentMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CarAttachmentMinAggregate = {
  __typename?: "CarAttachmentMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CarAttachmentSort = {
  direction: SortDirection;
  field: CarAttachmentSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum CarAttachmentSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Id = "Id",
  Name = "Name",
  UpdatedAt = "UpdatedAt",
}

export type CarAttachmentUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<CarAttachmentUpdateFilter>>;
  or?: Maybe<Array<CarAttachmentUpdateFilter>>;
};

export type CarConnection = {
  __typename?: "CarConnection";
  /** Array of nodes. */
  nodes: Array<Car>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type CarCountAggregate = {
  __typename?: "CarCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Description?: Maybe<Scalars["Int"]>;
  Evidence?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  Type?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type CarDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Evidence?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<CarDeleteFilter>>;
  or?: Maybe<Array<CarDeleteFilter>>;
};

export type CarDeleteResponse = {
  __typename?: "CarDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Evidence?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CarFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Evidence?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<CarFilter>>;
  attachments?: Maybe<CarFilterCarAttachmentFilter>;
  createdBy?: Maybe<CarFilterUserFilter>;
  or?: Maybe<Array<CarFilter>>;
};

export type CarFilterCarAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<CarFilterCarAttachmentFilter>>;
  or?: Maybe<Array<CarFilterCarAttachmentFilter>>;
};

export type CarFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<CarFilterUserFilter>>;
  or?: Maybe<Array<CarFilterUserFilter>>;
};

export type CarInput = {
  Description?: Maybe<Scalars["String"]>;
  Evidence?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  Type: Scalars["String"];
  attachments?: Maybe<Array<AttachmentInput>>;
};

export type CarMaxAggregate = {
  __typename?: "CarMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Evidence?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CarMinAggregate = {
  __typename?: "CarMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Evidence?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CarSort = {
  direction: SortDirection;
  field: CarSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum CarSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Description = "Description",
  Evidence = "Evidence",
  Id = "Id",
  Name = "Name",
  Type = "Type",
  UpdatedAt = "UpdatedAt",
}

export type CarUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Evidence?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<CarUpdateFilter>>;
  or?: Maybe<Array<CarUpdateFilter>>;
};

export type Company = {
  __typename?: "Company";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Language?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
  contact?: Maybe<Contact>;
  createdBy?: Maybe<User>;
  histories: CompanyHistoriesConnection;
};

export type CompanyHistoriesArgs = {
  filter?: Maybe<ContactFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContactSort>>;
};

export type CompanyAggregateGroupBy = {
  __typename?: "CompanyAggregateGroupBy";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type CompanyConnection = {
  __typename?: "CompanyConnection";
  /** Array of nodes. */
  nodes: Array<Company>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type CompanyCountAggregate = {
  __typename?: "CompanyCountAggregate";
  Address?: Maybe<Scalars["Int"]>;
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Focus?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Language?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
  Webpage?: Maybe<Scalars["Int"]>;
};

export type CompanyDeleteFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<CompanyDeleteFilter>>;
  or?: Maybe<Array<CompanyDeleteFilter>>;
};

export type CompanyDeleteResponse = {
  __typename?: "CompanyDeleteResponse";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type CompanyFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<CompanyFilter>>;
  contact?: Maybe<CompanyFilterContactFilter>;
  createdBy?: Maybe<CompanyFilterUserFilter>;
  histories?: Maybe<CompanyFilterContactFilter>;
  or?: Maybe<Array<CompanyFilter>>;
};

export type CompanyFilterContactFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<CompanyFilterContactFilter>>;
  or?: Maybe<Array<CompanyFilterContactFilter>>;
};

export type CompanyFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<CompanyFilterUserFilter>>;
  or?: Maybe<Array<CompanyFilterUserFilter>>;
};

export type CompanyHistoriesConnection = {
  __typename?: "CompanyHistoriesConnection";
  /** Array of nodes. */
  nodes: Array<Contact>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type CompanyMaxAggregate = {
  __typename?: "CompanyMaxAggregate";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type CompanyMinAggregate = {
  __typename?: "CompanyMinAggregate";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type CompanyPilver = {
  __typename?: "CompanyPilver";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Language?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
  contact?: Maybe<ContactPilver>;
  createdBy?: Maybe<User>;
  histories: CompanyPilverHistoriesConnection;
};

export type CompanyPilverHistoriesArgs = {
  filter?: Maybe<ContactPilverFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContactPilverSort>>;
};

export type CompanyPilverAggregateGroupBy = {
  __typename?: "CompanyPilverAggregateGroupBy";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type CompanyPilverConnection = {
  __typename?: "CompanyPilverConnection";
  /** Array of nodes. */
  nodes: Array<CompanyPilver>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type CompanyPilverCountAggregate = {
  __typename?: "CompanyPilverCountAggregate";
  Address?: Maybe<Scalars["Int"]>;
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Focus?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Language?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
  Webpage?: Maybe<Scalars["Int"]>;
};

export type CompanyPilverDeleteFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<CompanyPilverDeleteFilter>>;
  or?: Maybe<Array<CompanyPilverDeleteFilter>>;
};

export type CompanyPilverDeleteResponse = {
  __typename?: "CompanyPilverDeleteResponse";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type CompanyPilverFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<CompanyPilverFilter>>;
  contact?: Maybe<CompanyPilverFilterContactPilverFilter>;
  createdBy?: Maybe<CompanyPilverFilterUserFilter>;
  histories?: Maybe<CompanyPilverFilterContactPilverFilter>;
  or?: Maybe<Array<CompanyPilverFilter>>;
};

export type CompanyPilverFilterContactPilverFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<CompanyPilverFilterContactPilverFilter>>;
  or?: Maybe<Array<CompanyPilverFilterContactPilverFilter>>;
};

export type CompanyPilverFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<CompanyPilverFilterUserFilter>>;
  or?: Maybe<Array<CompanyPilverFilterUserFilter>>;
};

export type CompanyPilverHistoriesConnection = {
  __typename?: "CompanyPilverHistoriesConnection";
  /** Array of nodes. */
  nodes: Array<ContactPilver>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type CompanyPilverMaxAggregate = {
  __typename?: "CompanyPilverMaxAggregate";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type CompanyPilverMinAggregate = {
  __typename?: "CompanyPilverMinAggregate";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type CompanyPilverSort = {
  direction: SortDirection;
  field: CompanyPilverSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum CompanyPilverSortFields {
  Address = "Address",
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Focus = "Focus",
  Id = "Id",
  Language = "Language",
  Name = "Name",
  UpdatedAt = "UpdatedAt",
  Webpage = "Webpage",
}

export type CompanyPilverUpdateFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<CompanyPilverUpdateFilter>>;
  or?: Maybe<Array<CompanyPilverUpdateFilter>>;
};

export type CompanySort = {
  direction: SortDirection;
  field: CompanySortFields;
  nulls?: Maybe<SortNulls>;
};

export enum CompanySortFields {
  Address = "Address",
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Focus = "Focus",
  Id = "Id",
  Language = "Language",
  Name = "Name",
  UpdatedAt = "UpdatedAt",
  Webpage = "Webpage",
}

export type CompanyUpdateFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<CompanyUpdateFilter>>;
  or?: Maybe<Array<CompanyUpdateFilter>>;
};

export type Contact = {
  __typename?: "Contact";
  ContactPerson?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  company?: Maybe<Company>;
  contactedBy?: Maybe<User>;
};

export type ContactAggregateGroupBy = {
  __typename?: "ContactAggregateGroupBy";
  ContactPerson?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContactConnection = {
  __typename?: "ContactConnection";
  /** Array of nodes. */
  nodes: Array<Contact>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type ContactCountAggregate = {
  __typename?: "ContactCountAggregate";
  ContactPerson?: Maybe<Scalars["Int"]>;
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Description?: Maybe<Scalars["Int"]>;
  Email?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Interest?: Maybe<Scalars["Int"]>;
  Phone?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type ContactDeleteFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContactDeleteFilter>>;
  or?: Maybe<Array<ContactDeleteFilter>>;
};

export type ContactDeleteResponse = {
  __typename?: "ContactDeleteResponse";
  ContactPerson?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContactFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContactFilter>>;
  company?: Maybe<ContactFilterCompanyFilter>;
  contactedBy?: Maybe<ContactFilterUserFilter>;
  or?: Maybe<Array<ContactFilter>>;
};

export type ContactFilterCompanyFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<ContactFilterCompanyFilter>>;
  or?: Maybe<Array<ContactFilterCompanyFilter>>;
};

export type ContactFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<ContactFilterUserFilter>>;
  or?: Maybe<Array<ContactFilterUserFilter>>;
};

export type ContactInput = {
  ContactPerson?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  companyId?: Maybe<Scalars["String"]>;
};

export type ContactMaxAggregate = {
  __typename?: "ContactMaxAggregate";
  ContactPerson?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContactMinAggregate = {
  __typename?: "ContactMinAggregate";
  ContactPerson?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContactPilver = {
  __typename?: "ContactPilver";
  ContactPerson?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  company?: Maybe<CompanyPilver>;
  contactedBy?: Maybe<User>;
};

export type ContactPilverAggregateGroupBy = {
  __typename?: "ContactPilverAggregateGroupBy";
  ContactPerson?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContactPilverConnection = {
  __typename?: "ContactPilverConnection";
  /** Array of nodes. */
  nodes: Array<ContactPilver>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type ContactPilverCountAggregate = {
  __typename?: "ContactPilverCountAggregate";
  ContactPerson?: Maybe<Scalars["Int"]>;
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Description?: Maybe<Scalars["Int"]>;
  Email?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Interest?: Maybe<Scalars["Int"]>;
  Phone?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type ContactPilverDeleteFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContactPilverDeleteFilter>>;
  or?: Maybe<Array<ContactPilverDeleteFilter>>;
};

export type ContactPilverDeleteResponse = {
  __typename?: "ContactPilverDeleteResponse";
  ContactPerson?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContactPilverFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContactPilverFilter>>;
  company?: Maybe<ContactPilverFilterCompanyPilverFilter>;
  contactedBy?: Maybe<ContactPilverFilterUserFilter>;
  or?: Maybe<Array<ContactPilverFilter>>;
};

export type ContactPilverFilterCompanyPilverFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<ContactPilverFilterCompanyPilverFilter>>;
  or?: Maybe<Array<ContactPilverFilterCompanyPilverFilter>>;
};

export type ContactPilverFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<ContactPilverFilterUserFilter>>;
  or?: Maybe<Array<ContactPilverFilterUserFilter>>;
};

export type ContactPilverInput = {
  ContactPerson?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  companyId?: Maybe<Scalars["String"]>;
};

export type ContactPilverMaxAggregate = {
  __typename?: "ContactPilverMaxAggregate";
  ContactPerson?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContactPilverMinAggregate = {
  __typename?: "ContactPilverMinAggregate";
  ContactPerson?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Interest?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContactPilverSort = {
  direction: SortDirection;
  field: ContactPilverSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum ContactPilverSortFields {
  ContactPerson = "ContactPerson",
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Description = "Description",
  Email = "Email",
  Id = "Id",
  Interest = "Interest",
  Phone = "Phone",
  UpdatedAt = "UpdatedAt",
}

export type ContactPilverUpdateFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContactPilverUpdateFilter>>;
  or?: Maybe<Array<ContactPilverUpdateFilter>>;
};

export type ContactSort = {
  direction: SortDirection;
  field: ContactSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum ContactSortFields {
  ContactPerson = "ContactPerson",
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Description = "Description",
  Email = "Email",
  Id = "Id",
  Interest = "Interest",
  Phone = "Phone",
  UpdatedAt = "UpdatedAt",
}

export type ContactUpdateFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContactUpdateFilter>>;
  or?: Maybe<Array<ContactUpdateFilter>>;
};

export type Contract = {
  __typename?: "Contract";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  Type: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Year?: Maybe<Scalars["Float"]>;
  attachments: Array<ContractAttachment>;
  createdBy?: Maybe<User>;
};

export type ContractAttachmentsArgs = {
  filter?: Maybe<ContractAttachmentFilter>;
  sorting?: Maybe<Array<ContractAttachmentSort>>;
};

export type ContractAggregateGroupBy = {
  __typename?: "ContractAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractAttachment = {
  __typename?: "ContractAttachment";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContractAttachmentAggregateGroupBy = {
  __typename?: "ContractAttachmentAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContractAttachmentConnection = {
  __typename?: "ContractAttachmentConnection";
  /** Array of nodes. */
  nodes: Array<ContractAttachment>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type ContractAttachmentCountAggregate = {
  __typename?: "ContractAttachmentCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type ContractAttachmentDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContractAttachmentDeleteFilter>>;
  or?: Maybe<Array<ContractAttachmentDeleteFilter>>;
};

export type ContractAttachmentDeleteResponse = {
  __typename?: "ContractAttachmentDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContractAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContractAttachmentFilter>>;
  or?: Maybe<Array<ContractAttachmentFilter>>;
};

export type ContractAttachmentMaxAggregate = {
  __typename?: "ContractAttachmentMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContractAttachmentMinAggregate = {
  __typename?: "ContractAttachmentMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContractAttachmentSort = {
  direction: SortDirection;
  field: ContractAttachmentSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum ContractAttachmentSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Id = "Id",
  Name = "Name",
  UpdatedAt = "UpdatedAt",
}

export type ContractAttachmentUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContractAttachmentUpdateFilter>>;
  or?: Maybe<Array<ContractAttachmentUpdateFilter>>;
};

export type ContractAvgAggregate = {
  __typename?: "ContractAvgAggregate";
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractConnection = {
  __typename?: "ContractConnection";
  /** Array of nodes. */
  nodes: Array<Contract>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type ContractCountAggregate = {
  __typename?: "ContractCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Description?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  Type?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
  Year?: Maybe<Scalars["Int"]>;
};

export type ContractDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Year?: Maybe<NumberFieldComparison>;
  and?: Maybe<Array<ContractDeleteFilter>>;
  or?: Maybe<Array<ContractDeleteFilter>>;
};

export type ContractDeleteResponse = {
  __typename?: "ContractDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Year?: Maybe<NumberFieldComparison>;
  and?: Maybe<Array<ContractFilter>>;
  attachments?: Maybe<ContractFilterContractAttachmentFilter>;
  createdBy?: Maybe<ContractFilterUserFilter>;
  or?: Maybe<Array<ContractFilter>>;
};

export type ContractFilterContractAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContractFilterContractAttachmentFilter>>;
  or?: Maybe<Array<ContractFilterContractAttachmentFilter>>;
};

export type ContractFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<ContractFilterUserFilter>>;
  or?: Maybe<Array<ContractFilterUserFilter>>;
};

export type ContractInput = {
  Description?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  Type: Scalars["String"];
  Year: Scalars["Float"];
  attachments?: Maybe<Array<AttachmentInput>>;
};

export type ContractMaxAggregate = {
  __typename?: "ContractMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractMinAggregate = {
  __typename?: "ContractMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractPilver = {
  __typename?: "ContractPilver";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  Type: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Year?: Maybe<Scalars["Float"]>;
  attachments: Array<ContractPilverAttachment>;
};

export type ContractPilverAttachmentsArgs = {
  filter?: Maybe<ContractPilverAttachmentFilter>;
  sorting?: Maybe<Array<ContractPilverAttachmentSort>>;
};

export type ContractPilverAggregateGroupBy = {
  __typename?: "ContractPilverAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractPilverAttachment = {
  __typename?: "ContractPilverAttachment";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContractPilverAttachmentAggregateGroupBy = {
  __typename?: "ContractPilverAttachmentAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContractPilverAttachmentConnection = {
  __typename?: "ContractPilverAttachmentConnection";
  /** Array of nodes. */
  nodes: Array<ContractPilverAttachment>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type ContractPilverAttachmentCountAggregate = {
  __typename?: "ContractPilverAttachmentCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type ContractPilverAttachmentDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContractPilverAttachmentDeleteFilter>>;
  or?: Maybe<Array<ContractPilverAttachmentDeleteFilter>>;
};

export type ContractPilverAttachmentDeleteResponse = {
  __typename?: "ContractPilverAttachmentDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContractPilverAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContractPilverAttachmentFilter>>;
  or?: Maybe<Array<ContractPilverAttachmentFilter>>;
};

export type ContractPilverAttachmentMaxAggregate = {
  __typename?: "ContractPilverAttachmentMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContractPilverAttachmentMinAggregate = {
  __typename?: "ContractPilverAttachmentMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContractPilverAttachmentSort = {
  direction: SortDirection;
  field: ContractPilverAttachmentSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum ContractPilverAttachmentSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Id = "Id",
  Name = "Name",
  UpdatedAt = "UpdatedAt",
}

export type ContractPilverAttachmentUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContractPilverAttachmentUpdateFilter>>;
  or?: Maybe<Array<ContractPilverAttachmentUpdateFilter>>;
};

export type ContractPilverAvgAggregate = {
  __typename?: "ContractPilverAvgAggregate";
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractPilverConnection = {
  __typename?: "ContractPilverConnection";
  /** Array of nodes. */
  nodes: Array<ContractPilver>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type ContractPilverCountAggregate = {
  __typename?: "ContractPilverCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Description?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  Type?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
  Year?: Maybe<Scalars["Int"]>;
};

export type ContractPilverDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Year?: Maybe<NumberFieldComparison>;
  and?: Maybe<Array<ContractPilverDeleteFilter>>;
  or?: Maybe<Array<ContractPilverDeleteFilter>>;
};

export type ContractPilverDeleteResponse = {
  __typename?: "ContractPilverDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractPilverFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Year?: Maybe<NumberFieldComparison>;
  and?: Maybe<Array<ContractPilverFilter>>;
  attachments?: Maybe<ContractPilverFilterContractPilverAttachmentFilter>;
  or?: Maybe<Array<ContractPilverFilter>>;
};

export type ContractPilverFilterContractPilverAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ContractPilverFilterContractPilverAttachmentFilter>>;
  or?: Maybe<Array<ContractPilverFilterContractPilverAttachmentFilter>>;
};

export type ContractPilverInput = {
  Description?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  Type: Scalars["String"];
  Year?: Maybe<Scalars["Float"]>;
  attachments?: Maybe<Array<AttachmentInput>>;
};

export type ContractPilverMaxAggregate = {
  __typename?: "ContractPilverMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractPilverMinAggregate = {
  __typename?: "ContractPilverMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractPilverSort = {
  direction: SortDirection;
  field: ContractPilverSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum ContractPilverSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Description = "Description",
  Id = "Id",
  Name = "Name",
  Type = "Type",
  UpdatedAt = "UpdatedAt",
  Year = "Year",
}

export type ContractPilverSumAggregate = {
  __typename?: "ContractPilverSumAggregate";
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractPilverUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Year?: Maybe<NumberFieldComparison>;
  and?: Maybe<Array<ContractPilverUpdateFilter>>;
  or?: Maybe<Array<ContractPilverUpdateFilter>>;
};

export type ContractSort = {
  direction: SortDirection;
  field: ContractSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum ContractSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Description = "Description",
  Id = "Id",
  Name = "Name",
  Type = "Type",
  UpdatedAt = "UpdatedAt",
  Year = "Year",
}

export type ContractSumAggregate = {
  __typename?: "ContractSumAggregate";
  Year?: Maybe<Scalars["Float"]>;
};

export type ContractUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Year?: Maybe<NumberFieldComparison>;
  and?: Maybe<Array<ContractUpdateFilter>>;
  or?: Maybe<Array<ContractUpdateFilter>>;
};

export type CreateCompany = {
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type CreateCompanyPilver = {
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type CreateManyAttachmentsInput = {
  /** Array of records to create */
  attachments: Array<AttachmentInput>;
};

export type CreateManyCarAttachmentsInput = {
  /** Array of records to create */
  carAttachments: Array<AttachmentInput>;
};

export type CreateManyCarsInput = {
  /** Array of records to create */
  cars: Array<CarInput>;
};

export type CreateManyCompaniesInput = {
  /** Array of records to create */
  companies: Array<CreateCompany>;
};

export type CreateManyCompanyPilversInput = {
  /** Array of records to create */
  companyPilvers: Array<CreateCompanyPilver>;
};

export type CreateManyContactPilversInput = {
  /** Array of records to create */
  contactPilvers: Array<ContactPilverInput>;
};

export type CreateManyContactsInput = {
  /** Array of records to create */
  contacts: Array<ContactInput>;
};

export type CreateManyContractAttachmentsInput = {
  /** Array of records to create */
  contractAttachments: Array<AttachmentInput>;
};

export type CreateManyContractPilverAttachmentsInput = {
  /** Array of records to create */
  contractPilverAttachments: Array<AttachmentInput>;
};

export type CreateManyContractPilversInput = {
  /** Array of records to create */
  contractPilvers: Array<ContractPilverInput>;
};

export type CreateManyContractsInput = {
  /** Array of records to create */
  contracts: Array<ContractInput>;
};

export type CreateManyMiddlemanAttachmentsInput = {
  /** Array of records to create */
  middlemanAttachments: Array<AttachmentInput>;
};

export type CreateManyMiddlemenInput = {
  /** Array of records to create */
  middlemen: Array<MiddlemanInput>;
};

export type CreateManyProjectChangesInput = {
  /** Array of records to create */
  projectChanges: Array<ProjectChangeInput>;
};

export type CreateManyProjectsInput = {
  /** Array of records to create */
  projects: Array<CreateProject>;
};

export type CreateManyUsersInput = {
  /** Array of records to create */
  users: Array<UserInput>;
};

export type CreateManyWorkersInput = {
  /** Array of records to create */
  workers: Array<WorkerInput>;
};

export type CreateOneAttachmentInput = {
  /** The record to create */
  attachment: AttachmentInput;
};

export type CreateOneCarAttachmentInput = {
  /** The record to create */
  carAttachment: AttachmentInput;
};

export type CreateOneCarInput = {
  /** The record to create */
  car: CarInput;
};

export type CreateOneCompanyInput = {
  /** The record to create */
  company: CreateCompany;
};

export type CreateOneCompanyPilverInput = {
  /** The record to create */
  companyPilver: CreateCompanyPilver;
};

export type CreateOneContactInput = {
  /** The record to create */
  contact: ContactInput;
};

export type CreateOneContactPilverInput = {
  /** The record to create */
  contactPilver: ContactPilverInput;
};

export type CreateOneContractAttachmentInput = {
  /** The record to create */
  contractAttachment: AttachmentInput;
};

export type CreateOneContractInput = {
  /** The record to create */
  contract: ContractInput;
};

export type CreateOneContractPilverAttachmentInput = {
  /** The record to create */
  contractPilverAttachment: AttachmentInput;
};

export type CreateOneContractPilverInput = {
  /** The record to create */
  contractPilver: ContractPilverInput;
};

export type CreateOneMiddlemanAttachmentInput = {
  /** The record to create */
  middlemanAttachment: AttachmentInput;
};

export type CreateOneMiddlemanInput = {
  /** The record to create */
  middleman: MiddlemanInput;
};

export type CreateOneProjectChangeInput = {
  /** The record to create */
  projectChange: ProjectChangeInput;
};

export type CreateOneProjectInput = {
  /** The record to create */
  project: CreateProject;
};

export type CreateOneUserInput = {
  /** The record to create */
  user: UserInput;
};

export type CreateOneWorkerInput = {
  /** The record to create */
  worker: WorkerInput;
};

export type CreateProject = {
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Manager?: Maybe<Scalars["String"]>;
  ManagerContact?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DateFieldComparison = {
  between?: Maybe<DateFieldComparisonBetween>;
  eq?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  neq?: Maybe<Scalars["DateTime"]>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars["DateTime"];
  upper: Scalars["DateTime"];
};

export type DeleteManyAttachmentsInput = {
  /** Filter to find records to delete */
  filter: AttachmentDeleteFilter;
};

export type DeleteManyCarAttachmentsInput = {
  /** Filter to find records to delete */
  filter: CarAttachmentDeleteFilter;
};

export type DeleteManyCarsInput = {
  /** Filter to find records to delete */
  filter: CarDeleteFilter;
};

export type DeleteManyCompaniesInput = {
  /** Filter to find records to delete */
  filter: CompanyDeleteFilter;
};

export type DeleteManyCompanyPilversInput = {
  /** Filter to find records to delete */
  filter: CompanyPilverDeleteFilter;
};

export type DeleteManyContactPilversInput = {
  /** Filter to find records to delete */
  filter: ContactPilverDeleteFilter;
};

export type DeleteManyContactsInput = {
  /** Filter to find records to delete */
  filter: ContactDeleteFilter;
};

export type DeleteManyContractAttachmentsInput = {
  /** Filter to find records to delete */
  filter: ContractAttachmentDeleteFilter;
};

export type DeleteManyContractPilverAttachmentsInput = {
  /** Filter to find records to delete */
  filter: ContractPilverAttachmentDeleteFilter;
};

export type DeleteManyContractPilversInput = {
  /** Filter to find records to delete */
  filter: ContractPilverDeleteFilter;
};

export type DeleteManyContractsInput = {
  /** Filter to find records to delete */
  filter: ContractDeleteFilter;
};

export type DeleteManyMiddlemanAttachmentsInput = {
  /** Filter to find records to delete */
  filter: MiddlemanAttachmentDeleteFilter;
};

export type DeleteManyMiddlemenInput = {
  /** Filter to find records to delete */
  filter: MiddlemanDeleteFilter;
};

export type DeleteManyProjectChangesInput = {
  /** Filter to find records to delete */
  filter: ProjectChangeDeleteFilter;
};

export type DeleteManyProjectsInput = {
  /** Filter to find records to delete */
  filter: ProjectDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: "DeleteManyResponse";
  /** The number of records deleted. */
  deletedCount: Scalars["Int"];
};

export type DeleteManyUsersInput = {
  /** Filter to find records to delete */
  filter: UserDeleteFilter;
};

export type DeleteManyWorkersInput = {
  /** Filter to find records to delete */
  filter: WorkerDeleteFilter;
};

export type DeleteOneAttachmentInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneCarAttachmentInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneCarInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneCompanyInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneCompanyPilverInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneContactInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneContactPilverInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneContractAttachmentInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneContractInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneContractPilverAttachmentInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneContractPilverInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneMiddlemanAttachmentInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneMiddlemanInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneProjectChangeInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneProjectInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneUserInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneWorkerInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeletedCompany = {
  __typename?: "DeletedCompany";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Language?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
  contact?: Maybe<Contact>;
  createdBy?: Maybe<User>;
  deletedBy?: Maybe<User>;
  histories: DeletedCompanyHistoriesConnection;
};

export type DeletedCompanyHistoriesArgs = {
  filter?: Maybe<ContactFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContactSort>>;
};

export type DeletedCompanyAggregateGroupBy = {
  __typename?: "DeletedCompanyAggregateGroupBy";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type DeletedCompanyConnection = {
  __typename?: "DeletedCompanyConnection";
  /** Array of nodes. */
  nodes: Array<DeletedCompany>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type DeletedCompanyCountAggregate = {
  __typename?: "DeletedCompanyCountAggregate";
  Address?: Maybe<Scalars["Int"]>;
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Focus?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Language?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
  Webpage?: Maybe<Scalars["Int"]>;
};

export type DeletedCompanyFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<DeletedCompanyFilter>>;
  contact?: Maybe<DeletedCompanyFilterContactFilter>;
  createdBy?: Maybe<DeletedCompanyFilterUserFilter>;
  histories?: Maybe<DeletedCompanyFilterContactFilter>;
  or?: Maybe<Array<DeletedCompanyFilter>>;
};

export type DeletedCompanyFilterContactFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<DeletedCompanyFilterContactFilter>>;
  or?: Maybe<Array<DeletedCompanyFilterContactFilter>>;
};

export type DeletedCompanyFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<DeletedCompanyFilterUserFilter>>;
  or?: Maybe<Array<DeletedCompanyFilterUserFilter>>;
};

export type DeletedCompanyHistoriesConnection = {
  __typename?: "DeletedCompanyHistoriesConnection";
  /** Array of nodes. */
  nodes: Array<Contact>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type DeletedCompanyMaxAggregate = {
  __typename?: "DeletedCompanyMaxAggregate";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type DeletedCompanyMinAggregate = {
  __typename?: "DeletedCompanyMinAggregate";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type DeletedCompanyPilver = {
  __typename?: "DeletedCompanyPilver";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Language?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
  contact?: Maybe<ContactPilver>;
  createdBy?: Maybe<User>;
  deletedBy?: Maybe<User>;
  histories: DeletedCompanyPilverHistoriesConnection;
};

export type DeletedCompanyPilverHistoriesArgs = {
  filter?: Maybe<ContactPilverFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContactPilverSort>>;
};

export type DeletedCompanyPilverAggregateGroupBy = {
  __typename?: "DeletedCompanyPilverAggregateGroupBy";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type DeletedCompanyPilverConnection = {
  __typename?: "DeletedCompanyPilverConnection";
  /** Array of nodes. */
  nodes: Array<DeletedCompanyPilver>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type DeletedCompanyPilverCountAggregate = {
  __typename?: "DeletedCompanyPilverCountAggregate";
  Address?: Maybe<Scalars["Int"]>;
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Focus?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Language?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
  Webpage?: Maybe<Scalars["Int"]>;
};

export type DeletedCompanyPilverFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<DeletedCompanyPilverFilter>>;
  contact?: Maybe<DeletedCompanyPilverFilterContactPilverFilter>;
  createdBy?: Maybe<DeletedCompanyPilverFilterUserFilter>;
  histories?: Maybe<DeletedCompanyPilverFilterContactPilverFilter>;
  or?: Maybe<Array<DeletedCompanyPilverFilter>>;
};

export type DeletedCompanyPilverFilterContactPilverFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<DeletedCompanyPilverFilterContactPilverFilter>>;
  or?: Maybe<Array<DeletedCompanyPilverFilterContactPilverFilter>>;
};

export type DeletedCompanyPilverFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<DeletedCompanyPilverFilterUserFilter>>;
  or?: Maybe<Array<DeletedCompanyPilverFilterUserFilter>>;
};

export type DeletedCompanyPilverHistoriesConnection = {
  __typename?: "DeletedCompanyPilverHistoriesConnection";
  /** Array of nodes. */
  nodes: Array<ContactPilver>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type DeletedCompanyPilverMaxAggregate = {
  __typename?: "DeletedCompanyPilverMaxAggregate";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type DeletedCompanyPilverMinAggregate = {
  __typename?: "DeletedCompanyPilverMinAggregate";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type DeletedCompanyPilverSort = {
  direction: SortDirection;
  field: DeletedCompanyPilverSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum DeletedCompanyPilverSortFields {
  Address = "Address",
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Focus = "Focus",
  Id = "Id",
  Language = "Language",
  Name = "Name",
  UpdatedAt = "UpdatedAt",
  Webpage = "Webpage",
}

export type DeletedCompanySort = {
  direction: SortDirection;
  field: DeletedCompanySortFields;
  nulls?: Maybe<SortNulls>;
};

export enum DeletedCompanySortFields {
  Address = "Address",
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Focus = "Focus",
  Id = "Id",
  Language = "Language",
  Name = "Name",
  UpdatedAt = "UpdatedAt",
  Webpage = "Webpage",
}

export type DeletedMiddleman = {
  __typename?: "DeletedMiddleman";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  attachments: Array<MiddlemanAttachment>;
  createdBy?: Maybe<User>;
  deletedBy?: Maybe<User>;
};

export type DeletedMiddlemanAttachmentsArgs = {
  filter?: Maybe<MiddlemanAttachmentFilter>;
  sorting?: Maybe<Array<MiddlemanAttachmentSort>>;
};

export type DeletedMiddlemanAggregateGroupBy = {
  __typename?: "DeletedMiddlemanAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DeletedMiddlemanConnection = {
  __typename?: "DeletedMiddlemanConnection";
  /** Array of nodes. */
  nodes: Array<DeletedMiddleman>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type DeletedMiddlemanCountAggregate = {
  __typename?: "DeletedMiddlemanCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Email?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  Note?: Maybe<Scalars["Int"]>;
  Phone?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type DeletedMiddlemanFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Note?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<DeletedMiddlemanFilter>>;
  attachments?: Maybe<DeletedMiddlemanFilterMiddlemanAttachmentFilter>;
  createdBy?: Maybe<DeletedMiddlemanFilterUserFilter>;
  or?: Maybe<Array<DeletedMiddlemanFilter>>;
};

export type DeletedMiddlemanFilterMiddlemanAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<DeletedMiddlemanFilterMiddlemanAttachmentFilter>>;
  or?: Maybe<Array<DeletedMiddlemanFilterMiddlemanAttachmentFilter>>;
};

export type DeletedMiddlemanFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<DeletedMiddlemanFilterUserFilter>>;
  or?: Maybe<Array<DeletedMiddlemanFilterUserFilter>>;
};

export type DeletedMiddlemanMaxAggregate = {
  __typename?: "DeletedMiddlemanMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DeletedMiddlemanMinAggregate = {
  __typename?: "DeletedMiddlemanMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DeletedMiddlemanSort = {
  direction: SortDirection;
  field: DeletedMiddlemanSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum DeletedMiddlemanSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Email = "Email",
  Id = "Id",
  Name = "Name",
  Note = "Note",
  Phone = "Phone",
  UpdatedAt = "UpdatedAt",
}

export type DeletedWorker = {
  __typename?: "DeletedWorker";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Profession?: Maybe<Scalars["String"]>;
  Type: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  attachments: Array<Attachment>;
  createdBy?: Maybe<User>;
  deletedBy?: Maybe<User>;
};

export type DeletedWorkerAttachmentsArgs = {
  filter?: Maybe<AttachmentFilter>;
  sorting?: Maybe<Array<AttachmentSort>>;
};

export type DeletedWorkerAggregateGroupBy = {
  __typename?: "DeletedWorkerAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Profession?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DeletedWorkerConnection = {
  __typename?: "DeletedWorkerConnection";
  /** Array of nodes. */
  nodes: Array<DeletedWorker>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type DeletedWorkerCountAggregate = {
  __typename?: "DeletedWorkerCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Email?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  Note?: Maybe<Scalars["Int"]>;
  Phone?: Maybe<Scalars["Int"]>;
  Profession?: Maybe<Scalars["Int"]>;
  Type?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type DeletedWorkerFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Note?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  Profession?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<DeletedWorkerFilter>>;
  attachments?: Maybe<DeletedWorkerFilterAttachmentFilter>;
  createdBy?: Maybe<DeletedWorkerFilterUserFilter>;
  or?: Maybe<Array<DeletedWorkerFilter>>;
};

export type DeletedWorkerFilterAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<DeletedWorkerFilterAttachmentFilter>>;
  or?: Maybe<Array<DeletedWorkerFilterAttachmentFilter>>;
};

export type DeletedWorkerFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<DeletedWorkerFilterUserFilter>>;
  or?: Maybe<Array<DeletedWorkerFilterUserFilter>>;
};

export type DeletedWorkerMaxAggregate = {
  __typename?: "DeletedWorkerMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Profession?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DeletedWorkerMinAggregate = {
  __typename?: "DeletedWorkerMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Profession?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DeletedWorkerSort = {
  direction: SortDirection;
  field: DeletedWorkerSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum DeletedWorkerSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Email = "Email",
  Id = "Id",
  Name = "Name",
  Note = "Note",
  Phone = "Phone",
  Profession = "Profession",
  Type = "Type",
  UpdatedAt = "UpdatedAt",
}

export type LoginInputDto = {
  password: Scalars["String"];
  username: Scalars["String"];
};

export type LoginResponse = {
  __typename?: "LoginResponse";
  accessToken: Scalars["String"];
};

export type Middleman = {
  __typename?: "Middleman";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  attachments: Array<MiddlemanAttachment>;
  createdBy?: Maybe<User>;
};

export type MiddlemanAttachmentsArgs = {
  filter?: Maybe<MiddlemanAttachmentFilter>;
  sorting?: Maybe<Array<MiddlemanAttachmentSort>>;
};

export type MiddlemanAggregateGroupBy = {
  __typename?: "MiddlemanAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MiddlemanAttachment = {
  __typename?: "MiddlemanAttachment";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MiddlemanAttachmentAggregateGroupBy = {
  __typename?: "MiddlemanAttachmentAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MiddlemanAttachmentConnection = {
  __typename?: "MiddlemanAttachmentConnection";
  /** Array of nodes. */
  nodes: Array<MiddlemanAttachment>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type MiddlemanAttachmentCountAggregate = {
  __typename?: "MiddlemanAttachmentCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type MiddlemanAttachmentDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<MiddlemanAttachmentDeleteFilter>>;
  or?: Maybe<Array<MiddlemanAttachmentDeleteFilter>>;
};

export type MiddlemanAttachmentDeleteResponse = {
  __typename?: "MiddlemanAttachmentDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MiddlemanAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<MiddlemanAttachmentFilter>>;
  or?: Maybe<Array<MiddlemanAttachmentFilter>>;
};

export type MiddlemanAttachmentMaxAggregate = {
  __typename?: "MiddlemanAttachmentMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MiddlemanAttachmentMinAggregate = {
  __typename?: "MiddlemanAttachmentMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MiddlemanAttachmentSort = {
  direction: SortDirection;
  field: MiddlemanAttachmentSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum MiddlemanAttachmentSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Id = "Id",
  Name = "Name",
  UpdatedAt = "UpdatedAt",
}

export type MiddlemanAttachmentUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<MiddlemanAttachmentUpdateFilter>>;
  or?: Maybe<Array<MiddlemanAttachmentUpdateFilter>>;
};

export type MiddlemanConnection = {
  __typename?: "MiddlemanConnection";
  /** Array of nodes. */
  nodes: Array<Middleman>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type MiddlemanCountAggregate = {
  __typename?: "MiddlemanCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Email?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  Note?: Maybe<Scalars["Int"]>;
  Phone?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type MiddlemanDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Note?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<MiddlemanDeleteFilter>>;
  or?: Maybe<Array<MiddlemanDeleteFilter>>;
};

export type MiddlemanDeleteResponse = {
  __typename?: "MiddlemanDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MiddlemanFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Note?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<MiddlemanFilter>>;
  attachments?: Maybe<MiddlemanFilterMiddlemanAttachmentFilter>;
  createdBy?: Maybe<MiddlemanFilterUserFilter>;
  or?: Maybe<Array<MiddlemanFilter>>;
};

export type MiddlemanFilterMiddlemanAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<MiddlemanFilterMiddlemanAttachmentFilter>>;
  or?: Maybe<Array<MiddlemanFilterMiddlemanAttachmentFilter>>;
};

export type MiddlemanFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<MiddlemanFilterUserFilter>>;
  or?: Maybe<Array<MiddlemanFilterUserFilter>>;
};

export type MiddlemanInput = {
  Email?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<AttachmentInput>>;
};

export type MiddlemanMaxAggregate = {
  __typename?: "MiddlemanMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MiddlemanMinAggregate = {
  __typename?: "MiddlemanMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MiddlemanSort = {
  direction: SortDirection;
  field: MiddlemanSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum MiddlemanSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Email = "Email",
  Id = "Id",
  Name = "Name",
  Note = "Note",
  Phone = "Phone",
  UpdatedAt = "UpdatedAt",
}

export type MiddlemanUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Note?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<MiddlemanUpdateFilter>>;
  or?: Maybe<Array<MiddlemanUpdateFilter>>;
};

export type Mutation = {
  __typename?: "Mutation";
  addAttachmentsToCar: Car;
  addAttachmentsToContract: Contract;
  addAttachmentsToContractPilver: ContractPilver;
  addAttachmentsToDeletedMiddleman: DeletedMiddleman;
  addAttachmentsToDeletedWorker: DeletedWorker;
  addAttachmentsToMiddleman: Middleman;
  addAttachmentsToWorker: Worker;
  addContactsToUser: User;
  addCreatedCompaniesToUser: User;
  addCreatedContractsToUser: User;
  addCreatedMiddlemansToUser: User;
  addCreatedProjectsToUser: User;
  addCreatedWorkersToUser: User;
  addHistoriesToCompany: Company;
  addHistoriesToCompanyPilver: CompanyPilver;
  addHistoriesToDeletedCompany: DeletedCompany;
  addHistoriesToDeletedCompanyPilver: DeletedCompanyPilver;
  addHistoriesToProject: Project;
  addProjectChangesToUser: User;
  createManyAttachments: Array<Attachment>;
  createManyCarAttachments: Array<CarAttachment>;
  createManyCars: Array<Car>;
  createManyCompanies: Array<Company>;
  createManyCompanyPilvers: Array<CompanyPilver>;
  createManyContactPilvers: Array<ContactPilver>;
  createManyContacts: Array<Contact>;
  createManyContractAttachments: Array<ContractAttachment>;
  createManyContractPilverAttachments: Array<ContractPilverAttachment>;
  createManyContractPilvers: Array<ContractPilver>;
  createManyContracts: Array<Contract>;
  createManyMiddlemanAttachments: Array<MiddlemanAttachment>;
  createManyMiddlemen: Array<Middleman>;
  createManyProjectChanges: Array<ProjectChange>;
  createManyProjects: Array<Project>;
  createManyUsers: Array<User>;
  createManyWorkers: Array<Worker>;
  createOneAttachment: Attachment;
  createOneCar: Car;
  createOneCarAttachment: CarAttachment;
  createOneCompany: Company;
  createOneCompanyPilver: CompanyPilver;
  createOneContact: Contact;
  createOneContactPilver: ContactPilver;
  createOneContract: Contract;
  createOneContractAttachment: ContractAttachment;
  createOneContractPilver: ContractPilver;
  createOneContractPilverAttachment: ContractPilverAttachment;
  createOneMiddleman: Middleman;
  createOneMiddlemanAttachment: MiddlemanAttachment;
  createOneProject: Project;
  createOneProjectChange: ProjectChange;
  createOneUser: User;
  createOneWorker: Worker;
  deleteManyAttachments: DeleteManyResponse;
  deleteManyCarAttachments: DeleteManyResponse;
  deleteManyCars: DeleteManyResponse;
  deleteManyCompanies: DeleteManyResponse;
  deleteManyCompanyPilvers: DeleteManyResponse;
  deleteManyContactPilvers: DeleteManyResponse;
  deleteManyContacts: DeleteManyResponse;
  deleteManyContractAttachments: DeleteManyResponse;
  deleteManyContractPilverAttachments: DeleteManyResponse;
  deleteManyContractPilvers: DeleteManyResponse;
  deleteManyContracts: DeleteManyResponse;
  deleteManyMiddlemanAttachments: DeleteManyResponse;
  deleteManyMiddlemen: DeleteManyResponse;
  deleteManyProjectChanges: DeleteManyResponse;
  deleteManyProjects: DeleteManyResponse;
  deleteManyUsers: DeleteManyResponse;
  deleteManyWorkers: DeleteManyResponse;
  deleteOneAttachment: AttachmentDeleteResponse;
  deleteOneCar: CarDeleteResponse;
  deleteOneCarAttachment: CarAttachmentDeleteResponse;
  deleteOneCompany: CompanyDeleteResponse;
  deleteOneCompanyPilver: CompanyPilverDeleteResponse;
  deleteOneContact: ContactDeleteResponse;
  deleteOneContactPilver: ContactPilverDeleteResponse;
  deleteOneContract: ContractDeleteResponse;
  deleteOneContractAttachment: ContractAttachmentDeleteResponse;
  deleteOneContractPilver: ContractPilverDeleteResponse;
  deleteOneContractPilverAttachment: ContractPilverAttachmentDeleteResponse;
  deleteOneMiddleman: MiddlemanDeleteResponse;
  deleteOneMiddlemanAttachment: MiddlemanAttachmentDeleteResponse;
  deleteOneProject: ProjectDeleteResponse;
  deleteOneProjectChange: ProjectChangeDeleteResponse;
  deleteOneUser: UserDeleteResponse;
  deleteOneWorker: WorkerDeleteResponse;
  login: LoginResponse;
  removeAttachmentsFromCar: Car;
  removeAttachmentsFromContract: Contract;
  removeAttachmentsFromContractPilver: ContractPilver;
  removeAttachmentsFromDeletedMiddleman: DeletedMiddleman;
  removeAttachmentsFromDeletedWorker: DeletedWorker;
  removeAttachmentsFromMiddleman: Middleman;
  removeAttachmentsFromWorker: Worker;
  removeCompanyFromContact: Contact;
  removeCompanyFromContactPilver: ContactPilver;
  removeContactFromCompany: Company;
  removeContactFromCompanyPilver: CompanyPilver;
  removeContactFromDeletedCompany: DeletedCompany;
  removeContactFromDeletedCompanyPilver: DeletedCompanyPilver;
  removeContactedByFromContact: Contact;
  removeContactedByFromContactPilver: ContactPilver;
  removeContactsFromUser: User;
  removeCreatedByFromCar: Car;
  removeCreatedByFromCompany: Company;
  removeCreatedByFromCompanyPilver: CompanyPilver;
  removeCreatedByFromContract: Contract;
  removeCreatedByFromDeletedCompany: DeletedCompany;
  removeCreatedByFromDeletedCompanyPilver: DeletedCompanyPilver;
  removeCreatedByFromDeletedMiddleman: DeletedMiddleman;
  removeCreatedByFromDeletedWorker: DeletedWorker;
  removeCreatedByFromMiddleman: Middleman;
  removeCreatedByFromProject: Project;
  removeCreatedByFromWorker: Worker;
  removeCreatedCompaniesFromUser: User;
  removeCreatedContractsFromUser: User;
  removeCreatedMiddlemansFromUser: User;
  removeCreatedProjectsFromUser: User;
  removeCreatedWorkersFromUser: User;
  removeHistoriesFromCompany: Company;
  removeHistoriesFromCompanyPilver: CompanyPilver;
  removeHistoriesFromDeletedCompany: DeletedCompany;
  removeHistoriesFromDeletedCompanyPilver: DeletedCompanyPilver;
  removeHistoriesFromProject: Project;
  removeModifiedByFromProjectChange: ProjectChange;
  removeProjectChangeFromProject: Project;
  removeProjectChangesFromUser: User;
  removeProjectFromProjectChange: ProjectChange;
  restoreOneCompany: Company;
  setAttachmentsOnCar: Car;
  setAttachmentsOnContract: Contract;
  setAttachmentsOnContractPilver: ContractPilver;
  setAttachmentsOnDeletedMiddleman: DeletedMiddleman;
  setAttachmentsOnDeletedWorker: DeletedWorker;
  setAttachmentsOnMiddleman: Middleman;
  setAttachmentsOnWorker: Worker;
  setCompanyOnContact: Contact;
  setCompanyOnContactPilver: ContactPilver;
  setContactOnCompany: Company;
  setContactOnCompanyPilver: CompanyPilver;
  setContactOnDeletedCompany: DeletedCompany;
  setContactOnDeletedCompanyPilver: DeletedCompanyPilver;
  setContactedByOnContact: Contact;
  setContactedByOnContactPilver: ContactPilver;
  setContactsOnUser: User;
  setCreatedByOnCar: Car;
  setCreatedByOnCompany: Company;
  setCreatedByOnCompanyPilver: CompanyPilver;
  setCreatedByOnContract: Contract;
  setCreatedByOnDeletedCompany: DeletedCompany;
  setCreatedByOnDeletedCompanyPilver: DeletedCompanyPilver;
  setCreatedByOnDeletedMiddleman: DeletedMiddleman;
  setCreatedByOnDeletedWorker: DeletedWorker;
  setCreatedByOnMiddleman: Middleman;
  setCreatedByOnProject: Project;
  setCreatedByOnWorker: Worker;
  setCreatedCompaniesOnUser: User;
  setCreatedContractsOnUser: User;
  setCreatedMiddlemansOnUser: User;
  setCreatedProjectsOnUser: User;
  setCreatedWorkersOnUser: User;
  setHistoriesOnCompany: Company;
  setHistoriesOnCompanyPilver: CompanyPilver;
  setHistoriesOnDeletedCompany: DeletedCompany;
  setHistoriesOnDeletedCompanyPilver: DeletedCompanyPilver;
  setHistoriesOnProject: Project;
  setModifiedByOnProjectChange: ProjectChange;
  setProjectChangeOnProject: Project;
  setProjectChangesOnUser: User;
  setProjectOnProjectChange: ProjectChange;
  updateManyAttachments: UpdateManyResponse;
  updateManyCarAttachments: UpdateManyResponse;
  updateManyCars: UpdateManyResponse;
  updateManyCompanies: UpdateManyResponse;
  updateManyCompanyPilvers: UpdateManyResponse;
  updateManyContactPilvers: UpdateManyResponse;
  updateManyContacts: UpdateManyResponse;
  updateManyContractAttachments: UpdateManyResponse;
  updateManyContractPilverAttachments: UpdateManyResponse;
  updateManyContractPilvers: UpdateManyResponse;
  updateManyContracts: UpdateManyResponse;
  updateManyMiddlemanAttachments: UpdateManyResponse;
  updateManyMiddlemen: UpdateManyResponse;
  updateManyProjectChanges: UpdateManyResponse;
  updateManyProjects: UpdateManyResponse;
  updateManyUsers: UpdateManyResponse;
  updateManyWorkers: UpdateManyResponse;
  updateOneAttachment: Attachment;
  updateOneCar: Car;
  updateOneCarAttachment: CarAttachment;
  updateOneCompany: Company;
  updateOneCompanyPilver: CompanyPilver;
  updateOneContact: Contact;
  updateOneContactPilver: ContactPilver;
  updateOneContract: Contract;
  updateOneContractAttachment: ContractAttachment;
  updateOneContractPilver: ContractPilver;
  updateOneContractPilverAttachment: ContractPilverAttachment;
  updateOneMiddleman: Middleman;
  updateOneMiddlemanAttachment: MiddlemanAttachment;
  updateOneProject: Project;
  updateOneProjectChange: ProjectChange;
  updateOneUser: User;
  updateOneWorker: Worker;
};

export type MutationAddAttachmentsToCarArgs = {
  input: AddAttachmentsToCarInput;
};

export type MutationAddAttachmentsToContractArgs = {
  input: AddAttachmentsToContractInput;
};

export type MutationAddAttachmentsToContractPilverArgs = {
  input: AddAttachmentsToContractPilverInput;
};

export type MutationAddAttachmentsToDeletedMiddlemanArgs = {
  input: AddAttachmentsToDeletedMiddlemanInput;
};

export type MutationAddAttachmentsToDeletedWorkerArgs = {
  input: AddAttachmentsToDeletedWorkerInput;
};

export type MutationAddAttachmentsToMiddlemanArgs = {
  input: AddAttachmentsToMiddlemanInput;
};

export type MutationAddAttachmentsToWorkerArgs = {
  input: AddAttachmentsToWorkerInput;
};

export type MutationAddContactsToUserArgs = {
  input: AddContactsToUserInput;
};

export type MutationAddCreatedCompaniesToUserArgs = {
  input: AddCreatedCompaniesToUserInput;
};

export type MutationAddCreatedContractsToUserArgs = {
  input: AddCreatedContractsToUserInput;
};

export type MutationAddCreatedMiddlemansToUserArgs = {
  input: AddCreatedMiddlemansToUserInput;
};

export type MutationAddCreatedProjectsToUserArgs = {
  input: AddCreatedProjectsToUserInput;
};

export type MutationAddCreatedWorkersToUserArgs = {
  input: AddCreatedWorkersToUserInput;
};

export type MutationAddHistoriesToCompanyArgs = {
  input: AddHistoriesToCompanyInput;
};

export type MutationAddHistoriesToCompanyPilverArgs = {
  input: AddHistoriesToCompanyPilverInput;
};

export type MutationAddHistoriesToDeletedCompanyArgs = {
  input: AddHistoriesToDeletedCompanyInput;
};

export type MutationAddHistoriesToDeletedCompanyPilverArgs = {
  input: AddHistoriesToDeletedCompanyPilverInput;
};

export type MutationAddHistoriesToProjectArgs = {
  input: AddHistoriesToProjectInput;
};

export type MutationAddProjectChangesToUserArgs = {
  input: AddProjectChangesToUserInput;
};

export type MutationCreateManyAttachmentsArgs = {
  input: CreateManyAttachmentsInput;
};

export type MutationCreateManyCarAttachmentsArgs = {
  input: CreateManyCarAttachmentsInput;
};

export type MutationCreateManyCarsArgs = {
  input: CreateManyCarsInput;
};

export type MutationCreateManyCompaniesArgs = {
  input: CreateManyCompaniesInput;
};

export type MutationCreateManyCompanyPilversArgs = {
  input: CreateManyCompanyPilversInput;
};

export type MutationCreateManyContactPilversArgs = {
  input: CreateManyContactPilversInput;
};

export type MutationCreateManyContactsArgs = {
  input: CreateManyContactsInput;
};

export type MutationCreateManyContractAttachmentsArgs = {
  input: CreateManyContractAttachmentsInput;
};

export type MutationCreateManyContractPilverAttachmentsArgs = {
  input: CreateManyContractPilverAttachmentsInput;
};

export type MutationCreateManyContractPilversArgs = {
  input: CreateManyContractPilversInput;
};

export type MutationCreateManyContractsArgs = {
  input: CreateManyContractsInput;
};

export type MutationCreateManyMiddlemanAttachmentsArgs = {
  input: CreateManyMiddlemanAttachmentsInput;
};

export type MutationCreateManyMiddlemenArgs = {
  input: CreateManyMiddlemenInput;
};

export type MutationCreateManyProjectChangesArgs = {
  input: CreateManyProjectChangesInput;
};

export type MutationCreateManyProjectsArgs = {
  input: CreateManyProjectsInput;
};

export type MutationCreateManyUsersArgs = {
  input: CreateManyUsersInput;
};

export type MutationCreateManyWorkersArgs = {
  input: CreateManyWorkersInput;
};

export type MutationCreateOneAttachmentArgs = {
  input: CreateOneAttachmentInput;
};

export type MutationCreateOneCarArgs = {
  input: CreateOneCarInput;
};

export type MutationCreateOneCarAttachmentArgs = {
  input: CreateOneCarAttachmentInput;
};

export type MutationCreateOneCompanyArgs = {
  input: CreateOneCompanyInput;
};

export type MutationCreateOneCompanyPilverArgs = {
  input: CreateOneCompanyPilverInput;
};

export type MutationCreateOneContactArgs = {
  input: CreateOneContactInput;
};

export type MutationCreateOneContactPilverArgs = {
  input: CreateOneContactPilverInput;
};

export type MutationCreateOneContractArgs = {
  input: CreateOneContractInput;
};

export type MutationCreateOneContractAttachmentArgs = {
  input: CreateOneContractAttachmentInput;
};

export type MutationCreateOneContractPilverArgs = {
  input: CreateOneContractPilverInput;
};

export type MutationCreateOneContractPilverAttachmentArgs = {
  input: CreateOneContractPilverAttachmentInput;
};

export type MutationCreateOneMiddlemanArgs = {
  input: CreateOneMiddlemanInput;
};

export type MutationCreateOneMiddlemanAttachmentArgs = {
  input: CreateOneMiddlemanAttachmentInput;
};

export type MutationCreateOneProjectArgs = {
  input: CreateOneProjectInput;
};

export type MutationCreateOneProjectChangeArgs = {
  input: CreateOneProjectChangeInput;
};

export type MutationCreateOneUserArgs = {
  input: CreateOneUserInput;
};

export type MutationCreateOneWorkerArgs = {
  input: CreateOneWorkerInput;
};

export type MutationDeleteManyAttachmentsArgs = {
  input: DeleteManyAttachmentsInput;
};

export type MutationDeleteManyCarAttachmentsArgs = {
  input: DeleteManyCarAttachmentsInput;
};

export type MutationDeleteManyCarsArgs = {
  input: DeleteManyCarsInput;
};

export type MutationDeleteManyCompaniesArgs = {
  input: DeleteManyCompaniesInput;
};

export type MutationDeleteManyCompanyPilversArgs = {
  input: DeleteManyCompanyPilversInput;
};

export type MutationDeleteManyContactPilversArgs = {
  input: DeleteManyContactPilversInput;
};

export type MutationDeleteManyContactsArgs = {
  input: DeleteManyContactsInput;
};

export type MutationDeleteManyContractAttachmentsArgs = {
  input: DeleteManyContractAttachmentsInput;
};

export type MutationDeleteManyContractPilverAttachmentsArgs = {
  input: DeleteManyContractPilverAttachmentsInput;
};

export type MutationDeleteManyContractPilversArgs = {
  input: DeleteManyContractPilversInput;
};

export type MutationDeleteManyContractsArgs = {
  input: DeleteManyContractsInput;
};

export type MutationDeleteManyMiddlemanAttachmentsArgs = {
  input: DeleteManyMiddlemanAttachmentsInput;
};

export type MutationDeleteManyMiddlemenArgs = {
  input: DeleteManyMiddlemenInput;
};

export type MutationDeleteManyProjectChangesArgs = {
  input: DeleteManyProjectChangesInput;
};

export type MutationDeleteManyProjectsArgs = {
  input: DeleteManyProjectsInput;
};

export type MutationDeleteManyUsersArgs = {
  input: DeleteManyUsersInput;
};

export type MutationDeleteManyWorkersArgs = {
  input: DeleteManyWorkersInput;
};

export type MutationDeleteOneAttachmentArgs = {
  input: DeleteOneAttachmentInput;
};

export type MutationDeleteOneCarArgs = {
  input: DeleteOneCarInput;
};

export type MutationDeleteOneCarAttachmentArgs = {
  input: DeleteOneCarAttachmentInput;
};

export type MutationDeleteOneCompanyArgs = {
  input: DeleteOneCompanyInput;
};

export type MutationDeleteOneCompanyPilverArgs = {
  input: DeleteOneCompanyPilverInput;
};

export type MutationDeleteOneContactArgs = {
  input: DeleteOneContactInput;
};

export type MutationDeleteOneContactPilverArgs = {
  input: DeleteOneContactPilverInput;
};

export type MutationDeleteOneContractArgs = {
  input: DeleteOneContractInput;
};

export type MutationDeleteOneContractAttachmentArgs = {
  input: DeleteOneContractAttachmentInput;
};

export type MutationDeleteOneContractPilverArgs = {
  input: DeleteOneContractPilverInput;
};

export type MutationDeleteOneContractPilverAttachmentArgs = {
  input: DeleteOneContractPilverAttachmentInput;
};

export type MutationDeleteOneMiddlemanArgs = {
  input: DeleteOneMiddlemanInput;
};

export type MutationDeleteOneMiddlemanAttachmentArgs = {
  input: DeleteOneMiddlemanAttachmentInput;
};

export type MutationDeleteOneProjectArgs = {
  input: DeleteOneProjectInput;
};

export type MutationDeleteOneProjectChangeArgs = {
  input: DeleteOneProjectChangeInput;
};

export type MutationDeleteOneUserArgs = {
  input: DeleteOneUserInput;
};

export type MutationDeleteOneWorkerArgs = {
  input: DeleteOneWorkerInput;
};

export type MutationLoginArgs = {
  input: LoginInputDto;
};

export type MutationRemoveAttachmentsFromCarArgs = {
  input: RemoveAttachmentsFromCarInput;
};

export type MutationRemoveAttachmentsFromContractArgs = {
  input: RemoveAttachmentsFromContractInput;
};

export type MutationRemoveAttachmentsFromContractPilverArgs = {
  input: RemoveAttachmentsFromContractPilverInput;
};

export type MutationRemoveAttachmentsFromDeletedMiddlemanArgs = {
  input: RemoveAttachmentsFromDeletedMiddlemanInput;
};

export type MutationRemoveAttachmentsFromDeletedWorkerArgs = {
  input: RemoveAttachmentsFromDeletedWorkerInput;
};

export type MutationRemoveAttachmentsFromMiddlemanArgs = {
  input: RemoveAttachmentsFromMiddlemanInput;
};

export type MutationRemoveAttachmentsFromWorkerArgs = {
  input: RemoveAttachmentsFromWorkerInput;
};

export type MutationRemoveCompanyFromContactArgs = {
  input: RemoveCompanyFromContactInput;
};

export type MutationRemoveCompanyFromContactPilverArgs = {
  input: RemoveCompanyFromContactPilverInput;
};

export type MutationRemoveContactFromCompanyArgs = {
  input: RemoveContactFromCompanyInput;
};

export type MutationRemoveContactFromCompanyPilverArgs = {
  input: RemoveContactFromCompanyPilverInput;
};

export type MutationRemoveContactFromDeletedCompanyArgs = {
  input: RemoveContactFromDeletedCompanyInput;
};

export type MutationRemoveContactFromDeletedCompanyPilverArgs = {
  input: RemoveContactFromDeletedCompanyPilverInput;
};

export type MutationRemoveContactedByFromContactArgs = {
  input: RemoveContactedByFromContactInput;
};

export type MutationRemoveContactedByFromContactPilverArgs = {
  input: RemoveContactedByFromContactPilverInput;
};

export type MutationRemoveContactsFromUserArgs = {
  input: RemoveContactsFromUserInput;
};

export type MutationRemoveCreatedByFromCarArgs = {
  input: RemoveCreatedByFromCarInput;
};

export type MutationRemoveCreatedByFromCompanyArgs = {
  input: RemoveCreatedByFromCompanyInput;
};

export type MutationRemoveCreatedByFromCompanyPilverArgs = {
  input: RemoveCreatedByFromCompanyPilverInput;
};

export type MutationRemoveCreatedByFromContractArgs = {
  input: RemoveCreatedByFromContractInput;
};

export type MutationRemoveCreatedByFromDeletedCompanyArgs = {
  input: RemoveCreatedByFromDeletedCompanyInput;
};

export type MutationRemoveCreatedByFromDeletedCompanyPilverArgs = {
  input: RemoveCreatedByFromDeletedCompanyPilverInput;
};

export type MutationRemoveCreatedByFromDeletedMiddlemanArgs = {
  input: RemoveCreatedByFromDeletedMiddlemanInput;
};

export type MutationRemoveCreatedByFromDeletedWorkerArgs = {
  input: RemoveCreatedByFromDeletedWorkerInput;
};

export type MutationRemoveCreatedByFromMiddlemanArgs = {
  input: RemoveCreatedByFromMiddlemanInput;
};

export type MutationRemoveCreatedByFromProjectArgs = {
  input: RemoveCreatedByFromProjectInput;
};

export type MutationRemoveCreatedByFromWorkerArgs = {
  input: RemoveCreatedByFromWorkerInput;
};

export type MutationRemoveCreatedCompaniesFromUserArgs = {
  input: RemoveCreatedCompaniesFromUserInput;
};

export type MutationRemoveCreatedContractsFromUserArgs = {
  input: RemoveCreatedContractsFromUserInput;
};

export type MutationRemoveCreatedMiddlemansFromUserArgs = {
  input: RemoveCreatedMiddlemansFromUserInput;
};

export type MutationRemoveCreatedProjectsFromUserArgs = {
  input: RemoveCreatedProjectsFromUserInput;
};

export type MutationRemoveCreatedWorkersFromUserArgs = {
  input: RemoveCreatedWorkersFromUserInput;
};

export type MutationRemoveHistoriesFromCompanyArgs = {
  input: RemoveHistoriesFromCompanyInput;
};

export type MutationRemoveHistoriesFromCompanyPilverArgs = {
  input: RemoveHistoriesFromCompanyPilverInput;
};

export type MutationRemoveHistoriesFromDeletedCompanyArgs = {
  input: RemoveHistoriesFromDeletedCompanyInput;
};

export type MutationRemoveHistoriesFromDeletedCompanyPilverArgs = {
  input: RemoveHistoriesFromDeletedCompanyPilverInput;
};

export type MutationRemoveHistoriesFromProjectArgs = {
  input: RemoveHistoriesFromProjectInput;
};

export type MutationRemoveModifiedByFromProjectChangeArgs = {
  input: RemoveModifiedByFromProjectChangeInput;
};

export type MutationRemoveProjectChangeFromProjectArgs = {
  input: RemoveProjectChangeFromProjectInput;
};

export type MutationRemoveProjectChangesFromUserArgs = {
  input: RemoveProjectChangesFromUserInput;
};

export type MutationRemoveProjectFromProjectChangeArgs = {
  input: RemoveProjectFromProjectChangeInput;
};

export type MutationRestoreOneCompanyArgs = {
  input: Scalars["String"];
};

export type MutationSetAttachmentsOnCarArgs = {
  input: SetAttachmentsOnCarInput;
};

export type MutationSetAttachmentsOnContractArgs = {
  input: SetAttachmentsOnContractInput;
};

export type MutationSetAttachmentsOnContractPilverArgs = {
  input: SetAttachmentsOnContractPilverInput;
};

export type MutationSetAttachmentsOnDeletedMiddlemanArgs = {
  input: SetAttachmentsOnDeletedMiddlemanInput;
};

export type MutationSetAttachmentsOnDeletedWorkerArgs = {
  input: SetAttachmentsOnDeletedWorkerInput;
};

export type MutationSetAttachmentsOnMiddlemanArgs = {
  input: SetAttachmentsOnMiddlemanInput;
};

export type MutationSetAttachmentsOnWorkerArgs = {
  input: SetAttachmentsOnWorkerInput;
};

export type MutationSetCompanyOnContactArgs = {
  input: SetCompanyOnContactInput;
};

export type MutationSetCompanyOnContactPilverArgs = {
  input: SetCompanyOnContactPilverInput;
};

export type MutationSetContactOnCompanyArgs = {
  input: SetContactOnCompanyInput;
};

export type MutationSetContactOnCompanyPilverArgs = {
  input: SetContactOnCompanyPilverInput;
};

export type MutationSetContactOnDeletedCompanyArgs = {
  input: SetContactOnDeletedCompanyInput;
};

export type MutationSetContactOnDeletedCompanyPilverArgs = {
  input: SetContactOnDeletedCompanyPilverInput;
};

export type MutationSetContactedByOnContactArgs = {
  input: SetContactedByOnContactInput;
};

export type MutationSetContactedByOnContactPilverArgs = {
  input: SetContactedByOnContactPilverInput;
};

export type MutationSetContactsOnUserArgs = {
  input: SetContactsOnUserInput;
};

export type MutationSetCreatedByOnCarArgs = {
  input: SetCreatedByOnCarInput;
};

export type MutationSetCreatedByOnCompanyArgs = {
  input: SetCreatedByOnCompanyInput;
};

export type MutationSetCreatedByOnCompanyPilverArgs = {
  input: SetCreatedByOnCompanyPilverInput;
};

export type MutationSetCreatedByOnContractArgs = {
  input: SetCreatedByOnContractInput;
};

export type MutationSetCreatedByOnDeletedCompanyArgs = {
  input: SetCreatedByOnDeletedCompanyInput;
};

export type MutationSetCreatedByOnDeletedCompanyPilverArgs = {
  input: SetCreatedByOnDeletedCompanyPilverInput;
};

export type MutationSetCreatedByOnDeletedMiddlemanArgs = {
  input: SetCreatedByOnDeletedMiddlemanInput;
};

export type MutationSetCreatedByOnDeletedWorkerArgs = {
  input: SetCreatedByOnDeletedWorkerInput;
};

export type MutationSetCreatedByOnMiddlemanArgs = {
  input: SetCreatedByOnMiddlemanInput;
};

export type MutationSetCreatedByOnProjectArgs = {
  input: SetCreatedByOnProjectInput;
};

export type MutationSetCreatedByOnWorkerArgs = {
  input: SetCreatedByOnWorkerInput;
};

export type MutationSetCreatedCompaniesOnUserArgs = {
  input: SetCreatedCompaniesOnUserInput;
};

export type MutationSetCreatedContractsOnUserArgs = {
  input: SetCreatedContractsOnUserInput;
};

export type MutationSetCreatedMiddlemansOnUserArgs = {
  input: SetCreatedMiddlemansOnUserInput;
};

export type MutationSetCreatedProjectsOnUserArgs = {
  input: SetCreatedProjectsOnUserInput;
};

export type MutationSetCreatedWorkersOnUserArgs = {
  input: SetCreatedWorkersOnUserInput;
};

export type MutationSetHistoriesOnCompanyArgs = {
  input: SetHistoriesOnCompanyInput;
};

export type MutationSetHistoriesOnCompanyPilverArgs = {
  input: SetHistoriesOnCompanyPilverInput;
};

export type MutationSetHistoriesOnDeletedCompanyArgs = {
  input: SetHistoriesOnDeletedCompanyInput;
};

export type MutationSetHistoriesOnDeletedCompanyPilverArgs = {
  input: SetHistoriesOnDeletedCompanyPilverInput;
};

export type MutationSetHistoriesOnProjectArgs = {
  input: SetHistoriesOnProjectInput;
};

export type MutationSetModifiedByOnProjectChangeArgs = {
  input: SetModifiedByOnProjectChangeInput;
};

export type MutationSetProjectChangeOnProjectArgs = {
  input: SetProjectChangeOnProjectInput;
};

export type MutationSetProjectChangesOnUserArgs = {
  input: SetProjectChangesOnUserInput;
};

export type MutationSetProjectOnProjectChangeArgs = {
  input: SetProjectOnProjectChangeInput;
};

export type MutationUpdateManyAttachmentsArgs = {
  input: UpdateManyAttachmentsInput;
};

export type MutationUpdateManyCarAttachmentsArgs = {
  input: UpdateManyCarAttachmentsInput;
};

export type MutationUpdateManyCarsArgs = {
  input: UpdateManyCarsInput;
};

export type MutationUpdateManyCompaniesArgs = {
  input: UpdateManyCompaniesInput;
};

export type MutationUpdateManyCompanyPilversArgs = {
  input: UpdateManyCompanyPilversInput;
};

export type MutationUpdateManyContactPilversArgs = {
  input: UpdateManyContactPilversInput;
};

export type MutationUpdateManyContactsArgs = {
  input: UpdateManyContactsInput;
};

export type MutationUpdateManyContractAttachmentsArgs = {
  input: UpdateManyContractAttachmentsInput;
};

export type MutationUpdateManyContractPilverAttachmentsArgs = {
  input: UpdateManyContractPilverAttachmentsInput;
};

export type MutationUpdateManyContractPilversArgs = {
  input: UpdateManyContractPilversInput;
};

export type MutationUpdateManyContractsArgs = {
  input: UpdateManyContractsInput;
};

export type MutationUpdateManyMiddlemanAttachmentsArgs = {
  input: UpdateManyMiddlemanAttachmentsInput;
};

export type MutationUpdateManyMiddlemenArgs = {
  input: UpdateManyMiddlemenInput;
};

export type MutationUpdateManyProjectChangesArgs = {
  input: UpdateManyProjectChangesInput;
};

export type MutationUpdateManyProjectsArgs = {
  input: UpdateManyProjectsInput;
};

export type MutationUpdateManyUsersArgs = {
  input: UpdateManyUsersInput;
};

export type MutationUpdateManyWorkersArgs = {
  input: UpdateManyWorkersInput;
};

export type MutationUpdateOneAttachmentArgs = {
  input: UpdateOneAttachmentInput;
};

export type MutationUpdateOneCarArgs = {
  input: UpdateOneCarInput;
};

export type MutationUpdateOneCarAttachmentArgs = {
  input: UpdateOneCarAttachmentInput;
};

export type MutationUpdateOneCompanyArgs = {
  input: UpdateOneCompanyInput;
};

export type MutationUpdateOneCompanyPilverArgs = {
  input: UpdateOneCompanyPilverInput;
};

export type MutationUpdateOneContactArgs = {
  input: UpdateOneContactInput;
};

export type MutationUpdateOneContactPilverArgs = {
  input: UpdateOneContactPilverInput;
};

export type MutationUpdateOneContractArgs = {
  input: UpdateOneContractInput;
};

export type MutationUpdateOneContractAttachmentArgs = {
  input: UpdateOneContractAttachmentInput;
};

export type MutationUpdateOneContractPilverArgs = {
  input: UpdateOneContractPilverInput;
};

export type MutationUpdateOneContractPilverAttachmentArgs = {
  input: UpdateOneContractPilverAttachmentInput;
};

export type MutationUpdateOneMiddlemanArgs = {
  input: UpdateOneMiddlemanInput;
};

export type MutationUpdateOneMiddlemanAttachmentArgs = {
  input: UpdateOneMiddlemanAttachmentInput;
};

export type MutationUpdateOneProjectArgs = {
  input: UpdateOneProjectInput;
};

export type MutationUpdateOneProjectChangeArgs = {
  input: UpdateOneProjectChangeInput;
};

export type MutationUpdateOneUserArgs = {
  input: UpdateOneUserInput;
};

export type MutationUpdateOneWorkerArgs = {
  input: UpdateOneWorkerInput;
};

export type NumberFieldComparison = {
  between?: Maybe<NumberFieldComparisonBetween>;
  eq?: Maybe<Scalars["Float"]>;
  gt?: Maybe<Scalars["Float"]>;
  gte?: Maybe<Scalars["Float"]>;
  in?: Maybe<Array<Scalars["Float"]>>;
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  lt?: Maybe<Scalars["Float"]>;
  lte?: Maybe<Scalars["Float"]>;
  neq?: Maybe<Scalars["Float"]>;
  notBetween?: Maybe<NumberFieldComparisonBetween>;
  notIn?: Maybe<Array<Scalars["Float"]>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars["Float"];
  upper: Scalars["Float"];
};

export type OffsetPageInfo = {
  __typename?: "OffsetPageInfo";
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars["Boolean"]>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: Maybe<Scalars["Int"]>;
  /** Offset to start returning records from */
  offset?: Maybe<Scalars["Int"]>;
};

export type Project = {
  __typename?: "Project";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id: Scalars["String"];
  Language?: Maybe<Scalars["String"]>;
  Manager?: Maybe<Scalars["String"]>;
  ManagerContact?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  createdBy?: Maybe<User>;
  histories: ProjectHistoriesConnection;
  projectChange?: Maybe<ProjectChange>;
};

export type ProjectHistoriesArgs = {
  filter?: Maybe<ProjectChangeFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ProjectChangeSort>>;
};

export type ProjectAggregateGroupBy = {
  __typename?: "ProjectAggregateGroupBy";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Manager?: Maybe<Scalars["String"]>;
  ManagerContact?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProjectChange = {
  __typename?: "ProjectChange";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  State?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  modifiedBy?: Maybe<User>;
  project?: Maybe<Project>;
};

export type ProjectChangeAggregateGroupBy = {
  __typename?: "ProjectChangeAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProjectChangeConnection = {
  __typename?: "ProjectChangeConnection";
  /** Array of nodes. */
  nodes: Array<ProjectChange>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type ProjectChangeCountAggregate = {
  __typename?: "ProjectChangeCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Description?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  State?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type ProjectChangeDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  State?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ProjectChangeDeleteFilter>>;
  or?: Maybe<Array<ProjectChangeDeleteFilter>>;
};

export type ProjectChangeDeleteResponse = {
  __typename?: "ProjectChangeDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProjectChangeFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  State?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ProjectChangeFilter>>;
  modifiedBy?: Maybe<ProjectChangeFilterUserFilter>;
  or?: Maybe<Array<ProjectChangeFilter>>;
  project?: Maybe<ProjectChangeFilterProjectFilter>;
};

export type ProjectChangeFilterProjectFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Manager?: Maybe<StringFieldComparison>;
  ManagerContact?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ProjectChangeFilterProjectFilter>>;
  or?: Maybe<Array<ProjectChangeFilterProjectFilter>>;
};

export type ProjectChangeFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<ProjectChangeFilterUserFilter>>;
  or?: Maybe<Array<ProjectChangeFilterUserFilter>>;
};

export type ProjectChangeInput = {
  Description?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
};

export type ProjectChangeMaxAggregate = {
  __typename?: "ProjectChangeMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProjectChangeMinAggregate = {
  __typename?: "ProjectChangeMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProjectChangeSort = {
  direction: SortDirection;
  field: ProjectChangeSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum ProjectChangeSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Description = "Description",
  Id = "Id",
  State = "State",
  UpdatedAt = "UpdatedAt",
}

export type ProjectChangeUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  State?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ProjectChangeUpdateFilter>>;
  or?: Maybe<Array<ProjectChangeUpdateFilter>>;
};

export type ProjectConnection = {
  __typename?: "ProjectConnection";
  /** Array of nodes. */
  nodes: Array<Project>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type ProjectCountAggregate = {
  __typename?: "ProjectCountAggregate";
  Address?: Maybe<Scalars["Int"]>;
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Language?: Maybe<Scalars["Int"]>;
  Manager?: Maybe<Scalars["Int"]>;
  ManagerContact?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  Type?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type ProjectDeleteFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Manager?: Maybe<StringFieldComparison>;
  ManagerContact?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ProjectDeleteFilter>>;
  or?: Maybe<Array<ProjectDeleteFilter>>;
};

export type ProjectDeleteResponse = {
  __typename?: "ProjectDeleteResponse";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Manager?: Maybe<Scalars["String"]>;
  ManagerContact?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProjectFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Manager?: Maybe<StringFieldComparison>;
  ManagerContact?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ProjectFilter>>;
  createdBy?: Maybe<ProjectFilterUserFilter>;
  histories?: Maybe<ProjectFilterProjectChangeFilter>;
  or?: Maybe<Array<ProjectFilter>>;
  projectChange?: Maybe<ProjectFilterProjectChangeFilter>;
};

export type ProjectFilterProjectChangeFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  State?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ProjectFilterProjectChangeFilter>>;
  or?: Maybe<Array<ProjectFilterProjectChangeFilter>>;
};

export type ProjectFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<ProjectFilterUserFilter>>;
  or?: Maybe<Array<ProjectFilterUserFilter>>;
};

export type ProjectHistoriesConnection = {
  __typename?: "ProjectHistoriesConnection";
  /** Array of nodes. */
  nodes: Array<ProjectChange>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type ProjectMaxAggregate = {
  __typename?: "ProjectMaxAggregate";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Manager?: Maybe<Scalars["String"]>;
  ManagerContact?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProjectMinAggregate = {
  __typename?: "ProjectMinAggregate";
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Manager?: Maybe<Scalars["String"]>;
  ManagerContact?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProjectSort = {
  direction: SortDirection;
  field: ProjectSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum ProjectSortFields {
  Address = "Address",
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Id = "Id",
  Language = "Language",
  Manager = "Manager",
  ManagerContact = "ManagerContact",
  Name = "Name",
  Type = "Type",
  UpdatedAt = "UpdatedAt",
}

export type ProjectUpdateFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Manager?: Maybe<StringFieldComparison>;
  ManagerContact?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<ProjectUpdateFilter>>;
  or?: Maybe<Array<ProjectUpdateFilter>>;
};

export type Query = {
  __typename?: "Query";
  attachment?: Maybe<Attachment>;
  attachments: AttachmentConnection;
  car?: Maybe<Car>;
  carAttachment?: Maybe<CarAttachment>;
  carAttachments: CarAttachmentConnection;
  cars: CarConnection;
  companies: CompanyConnection;
  company?: Maybe<Company>;
  companyFocuses: Array<Scalars["String"]>;
  companyId: Company;
  companyLanguages: Array<Scalars["String"]>;
  companyPilver?: Maybe<CompanyPilver>;
  companyPilverFocuses: Array<Scalars["String"]>;
  companyPilverId: CompanyPilver;
  companyPilverLanguages: Array<Scalars["String"]>;
  companyPilvers: CompanyPilverConnection;
  contact?: Maybe<Contact>;
  contactPilver?: Maybe<ContactPilver>;
  contactPilvers: ContactPilverConnection;
  contacts: ContactConnection;
  contract?: Maybe<Contract>;
  contractAttachment?: Maybe<ContractAttachment>;
  contractAttachments: ContractAttachmentConnection;
  contractPilver?: Maybe<ContractPilver>;
  contractPilverAttachment?: Maybe<ContractPilverAttachment>;
  contractPilverAttachments: ContractPilverAttachmentConnection;
  contractPilverYears: Array<Scalars["String"]>;
  contractPilvers: ContractPilverConnection;
  contractYears: Array<Scalars["String"]>;
  contracts: ContractConnection;
  deletedCompanies: DeletedCompanyConnection;
  deletedCompany?: Maybe<DeletedCompany>;
  deletedCompanyPilver?: Maybe<DeletedCompanyPilver>;
  deletedCompanyPilvers: DeletedCompanyPilverConnection;
  deletedMiddleman?: Maybe<DeletedMiddleman>;
  deletedMiddlemen: DeletedMiddlemanConnection;
  deletedWorker?: Maybe<DeletedWorker>;
  deletedWorkers: DeletedWorkerConnection;
  interests: Array<Scalars["String"]>;
  interestsPilver: Array<Scalars["String"]>;
  me: User;
  middleman?: Maybe<Middleman>;
  middlemanAttachment?: Maybe<MiddlemanAttachment>;
  middlemanAttachments: MiddlemanAttachmentConnection;
  middlemen: MiddlemanConnection;
  project?: Maybe<Project>;
  projectChange?: Maybe<ProjectChange>;
  projectChanges: ProjectChangeConnection;
  projectLanguages: Array<Scalars["String"]>;
  projectTypes: Array<Scalars["String"]>;
  projects: ProjectConnection;
  statuses: Array<Scalars["String"]>;
  user?: Maybe<User>;
  userRoles: Array<Scalars["String"]>;
  users: UserConnection;
  worker?: Maybe<Worker>;
  workerProfession: Array<Scalars["String"]>;
  workerTypes: Array<Scalars["String"]>;
  workers: WorkerConnection;
};

export type QueryAttachmentArgs = {
  id: Scalars["String"];
};

export type QueryAttachmentsArgs = {
  filter?: Maybe<AttachmentFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<AttachmentSort>>;
};

export type QueryCarArgs = {
  id: Scalars["String"];
};

export type QueryCarAttachmentArgs = {
  id: Scalars["String"];
};

export type QueryCarAttachmentsArgs = {
  filter?: Maybe<CarAttachmentFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<CarAttachmentSort>>;
};

export type QueryCarsArgs = {
  filter?: Maybe<CarFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<CarSort>>;
};

export type QueryCompaniesArgs = {
  filter?: Maybe<CompanyFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<CompanySort>>;
};

export type QueryCompanyArgs = {
  id: Scalars["String"];
};

export type QueryCompanyIdArgs = {
  name: Scalars["String"];
};

export type QueryCompanyPilverArgs = {
  id: Scalars["String"];
};

export type QueryCompanyPilverIdArgs = {
  name: Scalars["String"];
};

export type QueryCompanyPilversArgs = {
  filter?: Maybe<CompanyPilverFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<CompanyPilverSort>>;
};

export type QueryContactArgs = {
  id: Scalars["String"];
};

export type QueryContactPilverArgs = {
  id: Scalars["String"];
};

export type QueryContactPilversArgs = {
  filter?: Maybe<ContactPilverFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContactPilverSort>>;
};

export type QueryContactsArgs = {
  filter?: Maybe<ContactFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContactSort>>;
};

export type QueryContractArgs = {
  id: Scalars["String"];
};

export type QueryContractAttachmentArgs = {
  id: Scalars["String"];
};

export type QueryContractAttachmentsArgs = {
  filter?: Maybe<ContractAttachmentFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContractAttachmentSort>>;
};

export type QueryContractPilverArgs = {
  id: Scalars["String"];
};

export type QueryContractPilverAttachmentArgs = {
  id: Scalars["String"];
};

export type QueryContractPilverAttachmentsArgs = {
  filter?: Maybe<ContractPilverAttachmentFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContractPilverAttachmentSort>>;
};

export type QueryContractPilversArgs = {
  filter?: Maybe<ContractPilverFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContractPilverSort>>;
};

export type QueryContractsArgs = {
  filter?: Maybe<ContractFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContractSort>>;
};

export type QueryDeletedCompaniesArgs = {
  filter?: Maybe<DeletedCompanyFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<DeletedCompanySort>>;
};

export type QueryDeletedCompanyArgs = {
  id: Scalars["String"];
};

export type QueryDeletedCompanyPilverArgs = {
  id: Scalars["String"];
};

export type QueryDeletedCompanyPilversArgs = {
  filter?: Maybe<DeletedCompanyPilverFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<DeletedCompanyPilverSort>>;
};

export type QueryDeletedMiddlemanArgs = {
  id: Scalars["String"];
};

export type QueryDeletedMiddlemenArgs = {
  filter?: Maybe<DeletedMiddlemanFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<DeletedMiddlemanSort>>;
};

export type QueryDeletedWorkerArgs = {
  id: Scalars["String"];
};

export type QueryDeletedWorkersArgs = {
  filter?: Maybe<DeletedWorkerFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<DeletedWorkerSort>>;
};

export type QueryMiddlemanArgs = {
  id: Scalars["String"];
};

export type QueryMiddlemanAttachmentArgs = {
  id: Scalars["String"];
};

export type QueryMiddlemanAttachmentsArgs = {
  filter?: Maybe<MiddlemanAttachmentFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<MiddlemanAttachmentSort>>;
};

export type QueryMiddlemenArgs = {
  filter?: Maybe<MiddlemanFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<MiddlemanSort>>;
};

export type QueryProjectArgs = {
  id: Scalars["String"];
};

export type QueryProjectChangeArgs = {
  id: Scalars["String"];
};

export type QueryProjectChangesArgs = {
  filter?: Maybe<ProjectChangeFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ProjectChangeSort>>;
};

export type QueryProjectsArgs = {
  filter?: Maybe<ProjectFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ProjectSort>>;
};

export type QueryUserArgs = {
  id: Scalars["String"];
};

export type QueryUsersArgs = {
  filter?: Maybe<UserFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<UserSort>>;
};

export type QueryWorkerArgs = {
  id: Scalars["String"];
};

export type QueryWorkersArgs = {
  filter?: Maybe<WorkerFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<WorkerSort>>;
};

export type RemoveAttachmentsFromCarInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveAttachmentsFromContractInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveAttachmentsFromContractPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveAttachmentsFromDeletedMiddlemanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveAttachmentsFromDeletedWorkerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveAttachmentsFromMiddlemanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveAttachmentsFromWorkerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveCompanyFromContactInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCompanyFromContactPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveContactFromCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveContactFromCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveContactFromDeletedCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveContactFromDeletedCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveContactedByFromContactInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveContactedByFromContactPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveContactsFromUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveCreatedByFromCarInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedByFromCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedByFromCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedByFromContractInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedByFromDeletedCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedByFromDeletedCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedByFromDeletedMiddlemanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedByFromDeletedWorkerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedByFromMiddlemanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedByFromProjectInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedByFromWorkerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCreatedCompaniesFromUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveCreatedContractsFromUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveCreatedMiddlemansFromUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveCreatedProjectsFromUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveCreatedWorkersFromUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveHistoriesFromCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveHistoriesFromCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveHistoriesFromDeletedCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveHistoriesFromDeletedCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveHistoriesFromProjectInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveModifiedByFromProjectChangeInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveProjectChangeFromProjectInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveProjectChangesFromUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveProjectFromProjectChangeInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetAttachmentsOnCarInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetAttachmentsOnContractInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetAttachmentsOnContractPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetAttachmentsOnDeletedMiddlemanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetAttachmentsOnDeletedWorkerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetAttachmentsOnMiddlemanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetAttachmentsOnWorkerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetCompanyOnContactInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCompanyOnContactPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetContactOnCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetContactOnCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetContactOnDeletedCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetContactOnDeletedCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetContactedByOnContactInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetContactedByOnContactPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetContactsOnUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetCreatedByOnCarInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedByOnCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedByOnCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedByOnContractInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedByOnDeletedCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedByOnDeletedCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedByOnDeletedMiddlemanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedByOnDeletedWorkerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedByOnMiddlemanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedByOnProjectInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedByOnWorkerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCreatedCompaniesOnUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetCreatedContractsOnUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetCreatedMiddlemansOnUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetCreatedProjectsOnUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetCreatedWorkersOnUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetHistoriesOnCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetHistoriesOnCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetHistoriesOnDeletedCompanyInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetHistoriesOnDeletedCompanyPilverInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetHistoriesOnProjectInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetModifiedByOnProjectChangeInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetProjectChangeOnProjectInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetProjectChangesOnUserInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetProjectOnProjectChangeInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

/** Sort Directions */
export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = "NULLS_FIRST",
  NullsLast = "NULLS_LAST",
}

export type StringFieldComparison = {
  eq?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  iLike?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  like?: Maybe<Scalars["String"]>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  neq?: Maybe<Scalars["String"]>;
  notILike?: Maybe<Scalars["String"]>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  notLike?: Maybe<Scalars["String"]>;
};

export type UpdateCompany = {
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type UpdateCompanyPilver = {
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Focus?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Webpage?: Maybe<Scalars["String"]>;
};

export type UpdateManyAttachmentsInput = {
  /** Filter used to find fields to update */
  filter: AttachmentUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AttachmentInput;
};

export type UpdateManyCarAttachmentsInput = {
  /** Filter used to find fields to update */
  filter: CarAttachmentUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AttachmentInput;
};

export type UpdateManyCarsInput = {
  /** Filter used to find fields to update */
  filter: CarUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CarInput;
};

export type UpdateManyCompaniesInput = {
  /** Filter used to find fields to update */
  filter: CompanyUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateCompany;
};

export type UpdateManyCompanyPilversInput = {
  /** Filter used to find fields to update */
  filter: CompanyPilverUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateCompanyPilver;
};

export type UpdateManyContactPilversInput = {
  /** Filter used to find fields to update */
  filter: ContactPilverUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ContactPilverInput;
};

export type UpdateManyContactsInput = {
  /** Filter used to find fields to update */
  filter: ContactUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ContactInput;
};

export type UpdateManyContractAttachmentsInput = {
  /** Filter used to find fields to update */
  filter: ContractAttachmentUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AttachmentInput;
};

export type UpdateManyContractPilverAttachmentsInput = {
  /** Filter used to find fields to update */
  filter: ContractPilverAttachmentUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AttachmentInput;
};

export type UpdateManyContractPilversInput = {
  /** Filter used to find fields to update */
  filter: ContractPilverUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ContractPilverInput;
};

export type UpdateManyContractsInput = {
  /** Filter used to find fields to update */
  filter: ContractUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ContractInput;
};

export type UpdateManyMiddlemanAttachmentsInput = {
  /** Filter used to find fields to update */
  filter: MiddlemanAttachmentUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AttachmentInput;
};

export type UpdateManyMiddlemenInput = {
  /** Filter used to find fields to update */
  filter: MiddlemanUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: MiddlemanInput;
};

export type UpdateManyProjectChangesInput = {
  /** Filter used to find fields to update */
  filter: ProjectChangeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: ProjectChangeInput;
};

export type UpdateManyProjectsInput = {
  /** Filter used to find fields to update */
  filter: ProjectUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateProject;
};

export type UpdateManyResponse = {
  __typename?: "UpdateManyResponse";
  /** The number of records updated. */
  updatedCount: Scalars["Int"];
};

export type UpdateManyUsersInput = {
  /** Filter used to find fields to update */
  filter: UserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UserInput;
};

export type UpdateManyWorkersInput = {
  /** Filter used to find fields to update */
  filter: WorkerUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: WorkerInput;
};

export type UpdateOneAttachmentInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: AttachmentInput;
};

export type UpdateOneCarAttachmentInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: AttachmentInput;
};

export type UpdateOneCarInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: CarInput;
};

export type UpdateOneCompanyInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: UpdateCompany;
};

export type UpdateOneCompanyPilverInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: UpdateCompanyPilver;
};

export type UpdateOneContactInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: ContactInput;
};

export type UpdateOneContactPilverInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: ContactPilverInput;
};

export type UpdateOneContractAttachmentInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: AttachmentInput;
};

export type UpdateOneContractInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: ContractInput;
};

export type UpdateOneContractPilverAttachmentInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: AttachmentInput;
};

export type UpdateOneContractPilverInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: ContractPilverInput;
};

export type UpdateOneMiddlemanAttachmentInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: AttachmentInput;
};

export type UpdateOneMiddlemanInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: MiddlemanInput;
};

export type UpdateOneProjectChangeInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: ProjectChangeInput;
};

export type UpdateOneProjectInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: UpdateProject;
};

export type UpdateOneUserInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: UserInput;
};

export type UpdateOneWorkerInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: WorkerInput;
};

export type UpdateProject = {
  Address?: Maybe<Scalars["String"]>;
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Id?: Maybe<Scalars["String"]>;
  Language?: Maybe<Scalars["String"]>;
  Manager?: Maybe<Scalars["String"]>;
  ManagerContact?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type User = {
  __typename?: "User";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Role: Scalars["String"];
  Status?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Username: Scalars["String"];
  contacts: UserContactsConnection;
  createdCompanies: UserCreatedCompaniesConnection;
  createdContracts: UserCreatedContractsConnection;
  createdMiddlemans: UserCreatedMiddlemansConnection;
  createdProjects: UserCreatedProjectsConnection;
  createdWorkers: UserCreatedWorkersConnection;
  projectChanges: UserProjectChangesConnection;
};

export type UserContactsArgs = {
  filter?: Maybe<ContactFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContactSort>>;
};

export type UserCreatedCompaniesArgs = {
  filter?: Maybe<CompanyFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<CompanySort>>;
};

export type UserCreatedContractsArgs = {
  filter?: Maybe<ContractFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContractSort>>;
};

export type UserCreatedMiddlemansArgs = {
  filter?: Maybe<MiddlemanFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<MiddlemanSort>>;
};

export type UserCreatedProjectsArgs = {
  filter?: Maybe<ProjectFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ProjectSort>>;
};

export type UserCreatedWorkersArgs = {
  filter?: Maybe<WorkerFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<WorkerSort>>;
};

export type UserProjectChangesArgs = {
  filter?: Maybe<ContactFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<ContactSort>>;
};

export type UserAggregateGroupBy = {
  __typename?: "UserAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Role?: Maybe<Scalars["String"]>;
  Status?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Username?: Maybe<Scalars["String"]>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  /** Array of nodes. */
  nodes: Array<User>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type UserContactsConnection = {
  __typename?: "UserContactsConnection";
  /** Array of nodes. */
  nodes: Array<Contact>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type UserCountAggregate = {
  __typename?: "UserCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Email?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Role?: Maybe<Scalars["Int"]>;
  Status?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
  Username?: Maybe<Scalars["Int"]>;
};

export type UserCreatedCompaniesConnection = {
  __typename?: "UserCreatedCompaniesConnection";
  /** Array of nodes. */
  nodes: Array<Company>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type UserCreatedContractsConnection = {
  __typename?: "UserCreatedContractsConnection";
  /** Array of nodes. */
  nodes: Array<Contract>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type UserCreatedMiddlemansConnection = {
  __typename?: "UserCreatedMiddlemansConnection";
  /** Array of nodes. */
  nodes: Array<Middleman>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type UserCreatedProjectsConnection = {
  __typename?: "UserCreatedProjectsConnection";
  /** Array of nodes. */
  nodes: Array<Project>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type UserCreatedWorkersConnection = {
  __typename?: "UserCreatedWorkersConnection";
  /** Array of nodes. */
  nodes: Array<Worker>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type UserDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<UserDeleteFilter>>;
  or?: Maybe<Array<UserDeleteFilter>>;
};

export type UserDeleteResponse = {
  __typename?: "UserDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Role?: Maybe<Scalars["String"]>;
  Status?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Username?: Maybe<Scalars["String"]>;
};

export type UserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<UserFilter>>;
  contacts?: Maybe<UserFilterContactFilter>;
  createdCompanies?: Maybe<UserFilterCompanyFilter>;
  createdContracts?: Maybe<UserFilterContractFilter>;
  createdMiddlemans?: Maybe<UserFilterMiddlemanFilter>;
  createdProjects?: Maybe<UserFilterProjectFilter>;
  createdWorkers?: Maybe<UserFilterWorkerFilter>;
  or?: Maybe<Array<UserFilter>>;
  projectChanges?: Maybe<UserFilterContactFilter>;
};

export type UserFilterCompanyFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Focus?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Webpage?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<UserFilterCompanyFilter>>;
  or?: Maybe<Array<UserFilterCompanyFilter>>;
};

export type UserFilterContactFilter = {
  ContactPerson?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Interest?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<UserFilterContactFilter>>;
  or?: Maybe<Array<UserFilterContactFilter>>;
};

export type UserFilterContractFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Description?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Year?: Maybe<NumberFieldComparison>;
  and?: Maybe<Array<UserFilterContractFilter>>;
  or?: Maybe<Array<UserFilterContractFilter>>;
};

export type UserFilterMiddlemanFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Note?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<UserFilterMiddlemanFilter>>;
  or?: Maybe<Array<UserFilterMiddlemanFilter>>;
};

export type UserFilterProjectFilter = {
  Address?: Maybe<StringFieldComparison>;
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Language?: Maybe<StringFieldComparison>;
  Manager?: Maybe<StringFieldComparison>;
  ManagerContact?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<UserFilterProjectFilter>>;
  or?: Maybe<Array<UserFilterProjectFilter>>;
};

export type UserFilterWorkerFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Note?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  Profession?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<UserFilterWorkerFilter>>;
  or?: Maybe<Array<UserFilterWorkerFilter>>;
};

export type UserInput = {
  Email?: Maybe<Scalars["String"]>;
  Password?: Maybe<Scalars["String"]>;
  Role: Scalars["String"];
  Status?: Maybe<Scalars["String"]>;
  Username: Scalars["String"];
};

export type UserMaxAggregate = {
  __typename?: "UserMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Role?: Maybe<Scalars["String"]>;
  Status?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Username?: Maybe<Scalars["String"]>;
};

export type UserMinAggregate = {
  __typename?: "UserMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Role?: Maybe<Scalars["String"]>;
  Status?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  Username?: Maybe<Scalars["String"]>;
};

export type UserProjectChangesConnection = {
  __typename?: "UserProjectChangesConnection";
  /** Array of nodes. */
  nodes: Array<Contact>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum UserSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Email = "Email",
  Id = "Id",
  Role = "Role",
  Status = "Status",
  UpdatedAt = "UpdatedAt",
  Username = "Username",
}

export type UserUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<UserUpdateFilter>>;
  or?: Maybe<Array<UserUpdateFilter>>;
};

export type Worker = {
  __typename?: "Worker";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id: Scalars["String"];
  Name: Scalars["String"];
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Profession?: Maybe<Scalars["String"]>;
  Type: Scalars["String"];
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
  attachments: Array<Attachment>;
  createdBy?: Maybe<User>;
};

export type WorkerAttachmentsArgs = {
  filter?: Maybe<AttachmentFilter>;
  sorting?: Maybe<Array<AttachmentSort>>;
};

export type WorkerAggregateGroupBy = {
  __typename?: "WorkerAggregateGroupBy";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Profession?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type WorkerConnection = {
  __typename?: "WorkerConnection";
  /** Array of nodes. */
  nodes: Array<Worker>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type WorkerCountAggregate = {
  __typename?: "WorkerCountAggregate";
  CreatedAt?: Maybe<Scalars["Int"]>;
  DeletedAt?: Maybe<Scalars["Int"]>;
  Email?: Maybe<Scalars["Int"]>;
  Id?: Maybe<Scalars["Int"]>;
  Name?: Maybe<Scalars["Int"]>;
  Note?: Maybe<Scalars["Int"]>;
  Phone?: Maybe<Scalars["Int"]>;
  Profession?: Maybe<Scalars["Int"]>;
  Type?: Maybe<Scalars["Int"]>;
  UpdatedAt?: Maybe<Scalars["Int"]>;
};

export type WorkerDeleteFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Note?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  Profession?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<WorkerDeleteFilter>>;
  or?: Maybe<Array<WorkerDeleteFilter>>;
};

export type WorkerDeleteResponse = {
  __typename?: "WorkerDeleteResponse";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Profession?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type WorkerFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Note?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  Profession?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<WorkerFilter>>;
  attachments?: Maybe<WorkerFilterAttachmentFilter>;
  createdBy?: Maybe<WorkerFilterUserFilter>;
  or?: Maybe<Array<WorkerFilter>>;
};

export type WorkerFilterAttachmentFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<WorkerFilterAttachmentFilter>>;
  or?: Maybe<Array<WorkerFilterAttachmentFilter>>;
};

export type WorkerFilterUserFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Role?: Maybe<StringFieldComparison>;
  Status?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  Username?: Maybe<StringFieldComparison>;
  and?: Maybe<Array<WorkerFilterUserFilter>>;
  or?: Maybe<Array<WorkerFilterUserFilter>>;
};

export type WorkerInput = {
  Email?: Maybe<Scalars["String"]>;
  Name: Scalars["String"];
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Profession?: Maybe<Scalars["String"]>;
  Type: Scalars["String"];
  attachments?: Maybe<Array<AttachmentInput>>;
};

export type WorkerMaxAggregate = {
  __typename?: "WorkerMaxAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Profession?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type WorkerMinAggregate = {
  __typename?: "WorkerMinAggregate";
  CreatedAt?: Maybe<Scalars["DateTime"]>;
  DeletedAt?: Maybe<Scalars["DateTime"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Note?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Profession?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  UpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type WorkerSort = {
  direction: SortDirection;
  field: WorkerSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum WorkerSortFields {
  CreatedAt = "CreatedAt",
  DeletedAt = "DeletedAt",
  Email = "Email",
  Id = "Id",
  Name = "Name",
  Note = "Note",
  Phone = "Phone",
  Profession = "Profession",
  Type = "Type",
  UpdatedAt = "UpdatedAt",
}

export type WorkerUpdateFilter = {
  CreatedAt?: Maybe<DateFieldComparison>;
  DeletedAt?: Maybe<DateFieldComparison>;
  Email?: Maybe<StringFieldComparison>;
  Id?: Maybe<StringFieldComparison>;
  Name?: Maybe<StringFieldComparison>;
  Note?: Maybe<StringFieldComparison>;
  Phone?: Maybe<StringFieldComparison>;
  Profession?: Maybe<StringFieldComparison>;
  Type?: Maybe<StringFieldComparison>;
  UpdatedAt?: Maybe<DateFieldComparison>;
  and?: Maybe<Array<WorkerUpdateFilter>>;
  or?: Maybe<Array<WorkerUpdateFilter>>;
};

export type MeQueryVariables = Exact<{
  meCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  meCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  meCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  meCreatedProjectsPaging?: Maybe<OffsetPaging>;
  meCreatedProjectsFilter?: Maybe<ProjectFilter>;
  meCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  meCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  meCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  meCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  meContactsPaging?: Maybe<OffsetPaging>;
  meContactsFilter?: Maybe<ContactFilter>;
  meContactsSorting?: Maybe<Array<ContactSort>>;
  meContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  meContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  meContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  meProjectChangesPaging?: Maybe<OffsetPaging>;
  meProjectChangesFilter?: Maybe<ContactFilter>;
  meProjectChangesSorting?: Maybe<Array<ContactSort>>;
  meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  meCreatedWorkersPaging?: Maybe<OffsetPaging>;
  meCreatedWorkersFilter?: Maybe<WorkerFilter>;
  meCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  meCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  meCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  meCreatedContractsPaging?: Maybe<OffsetPaging>;
  meCreatedContractsFilter?: Maybe<ContractFilter>;
  meCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  meCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  meCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  meCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  meCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  meCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  meCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  meCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type MeQuery = { __typename?: "Query" } & {
  me: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type UserRolesQueryVariables = Exact<{ [key: string]: never }>;

export type UserRolesQuery = { __typename?: "Query" } & Pick<
  Query,
  "userRoles"
>;

export type UserQueryVariables = Exact<{
  id: Scalars["String"];
  userCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  userCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  userCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  userCreatedProjectsPaging?: Maybe<OffsetPaging>;
  userCreatedProjectsFilter?: Maybe<ProjectFilter>;
  userCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  userCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  userCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  userCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  userContactsPaging?: Maybe<OffsetPaging>;
  userContactsFilter?: Maybe<ContactFilter>;
  userContactsSorting?: Maybe<Array<ContactSort>>;
  userContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  userContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  userContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  userProjectChangesPaging?: Maybe<OffsetPaging>;
  userProjectChangesFilter?: Maybe<ContactFilter>;
  userProjectChangesSorting?: Maybe<Array<ContactSort>>;
  userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  userCreatedWorkersPaging?: Maybe<OffsetPaging>;
  userCreatedWorkersFilter?: Maybe<WorkerFilter>;
  userCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  userCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  userCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  userCreatedContractsPaging?: Maybe<OffsetPaging>;
  userCreatedContractsFilter?: Maybe<ContractFilter>;
  userCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  userCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  userCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  userCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  userCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  userCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  userCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  userCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type UserQuery = { __typename?: "Query" } & {
  user?: Maybe<
    { __typename?: "User" } & Pick<
      User,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Username"
      | "Email"
      | "Status"
      | "Role"
    > & {
        createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Company" } & Pick<
              Company,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Name"
              | "Webpage"
              | "Address"
              | "Focus"
              | "Language"
            > & {
                contact?: Maybe<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
                histories: { __typename?: "CompanyHistoriesConnection" } & {
                  pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                    OffsetPageInfo,
                    "hasNextPage" | "hasPreviousPage"
                  >;
                  nodes: Array<
                    { __typename?: "Contact" } & Pick<
                      Contact,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "ContactPerson"
                      | "Email"
                      | "Phone"
                      | "Description"
                      | "Interest"
                    >
                  >;
                };
              }
          >;
        };
        createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Project" } & Pick<
              Project,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Name"
              | "Address"
              | "Type"
              | "Language"
              | "Manager"
              | "ManagerContact"
            > & {
                projectChange?: Maybe<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
                histories: { __typename?: "ProjectHistoriesConnection" } & {
                  pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                    OffsetPageInfo,
                    "hasNextPage" | "hasPreviousPage"
                  >;
                  nodes: Array<
                    { __typename?: "ProjectChange" } & Pick<
                      ProjectChange,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Description"
                      | "State"
                    >
                  >;
                };
              }
          >;
        };
        contacts: { __typename?: "UserContactsConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Contact" } & Pick<
              Contact,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "ContactPerson"
              | "Email"
              | "Phone"
              | "Description"
              | "Interest"
            > & {
                company?: Maybe<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                    }
                >;
              }
          >;
        };
        projectChanges: { __typename?: "UserProjectChangesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Contact" } & Pick<
              Contact,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "ContactPerson"
              | "Email"
              | "Phone"
              | "Description"
              | "Interest"
            > & {
                company?: Maybe<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                    }
                >;
              }
          >;
        };
        createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Worker" } & Pick<
              Worker,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Name"
              | "Phone"
              | "Email"
              | "Note"
              | "Profession"
              | "Type"
            > & {
                attachments: Array<
                  { __typename?: "Attachment" } & Pick<
                    Attachment,
                    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                  >
                >;
              }
          >;
        };
        createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Contract" } & Pick<
              Contract,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Name"
              | "Description"
              | "Type"
              | "Year"
            > & {
                attachments: Array<
                  { __typename?: "ContractAttachment" } & Pick<
                    ContractAttachment,
                    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                  >
                >;
              }
          >;
        };
        createdMiddlemans: {
          __typename?: "UserCreatedMiddlemansConnection";
        } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Middleman" } & Pick<
              Middleman,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Name"
              | "Phone"
              | "Email"
              | "Note"
            > & {
                attachments: Array<
                  { __typename?: "MiddlemanAttachment" } & Pick<
                    MiddlemanAttachment,
                    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                  >
                >;
              }
          >;
        };
      }
  >;
};

export type UsersQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<UserFilter>;
  sorting?: Maybe<Array<UserSort>>;
  usersNodesNodesCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  usersNodesNodesCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  usersNodesNodesCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  usersNodesNodesCreatedProjectsPaging?: Maybe<OffsetPaging>;
  usersNodesNodesCreatedProjectsFilter?: Maybe<ProjectFilter>;
  usersNodesNodesCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  usersNodesNodesContactsPaging?: Maybe<OffsetPaging>;
  usersNodesNodesContactsFilter?: Maybe<ContactFilter>;
  usersNodesNodesContactsSorting?: Maybe<Array<ContactSort>>;
  usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  usersNodesNodesProjectChangesPaging?: Maybe<OffsetPaging>;
  usersNodesNodesProjectChangesFilter?: Maybe<ContactFilter>;
  usersNodesNodesProjectChangesSorting?: Maybe<Array<ContactSort>>;
  usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  usersNodesNodesCreatedWorkersPaging?: Maybe<OffsetPaging>;
  usersNodesNodesCreatedWorkersFilter?: Maybe<WorkerFilter>;
  usersNodesNodesCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  usersNodesNodesCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  usersNodesNodesCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  usersNodesNodesCreatedContractsPaging?: Maybe<OffsetPaging>;
  usersNodesNodesCreatedContractsFilter?: Maybe<ContractFilter>;
  usersNodesNodesCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  usersNodesNodesCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  usersNodesNodesCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  usersNodesNodesCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  usersNodesNodesCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  usersNodesNodesCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  usersNodesNodesCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  usersNodesNodesCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type UsersQuery = { __typename?: "Query" } & {
  users: { __typename?: "UserConnection" } & Pick<
    UserConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type CompanyFocusesQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyFocusesQuery = { __typename?: "Query" } & Pick<
  Query,
  "companyFocuses"
>;

export type CompanyLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyLanguagesQuery = { __typename?: "Query" } & Pick<
  Query,
  "companyLanguages"
>;

export type CompanyIdQueryVariables = Exact<{
  name: Scalars["String"];
  companyIdContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyIdContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyIdContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyIdContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyIdContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyIdContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyIdContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companyIdContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companyIdContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyIdContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyIdContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyIdContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyIdContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyIdContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyIdContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyIdContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyIdContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyIdContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyIdContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyIdContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyIdContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companyIdCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyIdCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyIdCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyIdCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyIdCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyIdCreatedByCreatedByCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyIdCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  companyIdCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  companyIdCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  companyIdCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyIdCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyIdCreatedByCreatedByProjectChangesSorting?: Maybe<Array<ContactSort>>;
  companyIdCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyIdCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyIdCreatedByCreatedByCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  companyIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyIdCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyIdCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyIdCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyIdCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyIdCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyIdCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companyIdHistoriesPaging?: Maybe<OffsetPaging>;
  companyIdHistoriesFilter?: Maybe<ContactFilter>;
  companyIdHistoriesSorting?: Maybe<Array<ContactSort>>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CompanyIdQuery = { __typename?: "Query" } & {
  companyId: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type CompanyQueryVariables = Exact<{
  id: Scalars["String"];
  companyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  companyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyCreatedByCreatedByCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  companyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  companyCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  companyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyCreatedByCreatedByProjectChangesSorting?: Maybe<Array<ContactSort>>;
  companyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyCreatedByCreatedByCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  companyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyCreatedByCreatedByCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  companyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companyHistoriesPaging?: Maybe<OffsetPaging>;
  companyHistoriesFilter?: Maybe<ContactFilter>;
  companyHistoriesSorting?: Maybe<Array<ContactSort>>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CompanyQuery = { __typename?: "Query" } & {
  company?: Maybe<
    { __typename?: "Company" } & Pick<
      Company,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Webpage"
      | "Address"
      | "Focus"
      | "Language"
    > & {
        contact?: Maybe<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        histories: { __typename?: "CompanyHistoriesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Contact" } & Pick<
              Contact,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "ContactPerson"
              | "Email"
              | "Phone"
              | "Description"
              | "Interest"
            > & {
                contactedBy?: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Username"
                    | "Email"
                    | "Status"
                    | "Role"
                  > & {
                      createdCompanies: {
                        __typename?: "UserCreatedCompaniesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                      createdProjects: {
                        __typename?: "UserCreatedProjectsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Project" } & Pick<
                            Project,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Address"
                            | "Type"
                            | "Language"
                            | "Manager"
                            | "ManagerContact"
                          > & {
                              projectChange?: Maybe<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                              histories: {
                                __typename?: "ProjectHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      contacts: { __typename?: "UserContactsConnection" } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                      projectChanges: {
                        __typename?: "UserProjectChangesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                      createdWorkers: {
                        __typename?: "UserCreatedWorkersConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Worker" } & Pick<
                            Worker,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                            | "Profession"
                            | "Type"
                          > & {
                              attachments: Array<
                                { __typename?: "Attachment" } & Pick<
                                  Attachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdContracts: {
                        __typename?: "UserCreatedContractsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contract" } & Pick<
                            Contract,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Description"
                            | "Type"
                            | "Year"
                          > & {
                              attachments: Array<
                                { __typename?: "ContractAttachment" } & Pick<
                                  ContractAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdMiddlemans: {
                        __typename?: "UserCreatedMiddlemansConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Middleman" } & Pick<
                            Middleman,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                          > & {
                              attachments: Array<
                                { __typename?: "MiddlemanAttachment" } & Pick<
                                  MiddlemanAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type CompaniesQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<CompanyFilter>;
  sorting?: Maybe<Array<CompanySort>>;
  companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companiesNodesNodesContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companiesNodesNodesContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companiesNodesNodesContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companiesNodesNodesContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companiesNodesNodesContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companiesNodesNodesContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companiesNodesNodesContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companiesNodesNodesContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companiesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companiesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companiesNodesNodesContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companiesNodesNodesContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companiesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companiesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companiesNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companiesNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companiesNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companiesNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companiesNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  companiesNodesNodesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companiesNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  companiesNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companiesNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companiesNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companiesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companiesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companiesNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companiesNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companiesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companiesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companiesNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companiesNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companiesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companiesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companiesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  companiesNodesNodesHistoriesSorting?: Maybe<Array<ContactSort>>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CompaniesQuery = { __typename?: "Query" } & {
  companies: { __typename?: "CompanyConnection" } & Pick<
    CompanyConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "Company" } & Pick<
          Company,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            contact?: Maybe<
              { __typename?: "Contact" } & Pick<
                Contact,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "ContactPerson"
                | "Email"
                | "Phone"
                | "Description"
                | "Interest"
              > & {
                  contactedBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                }
            >;
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    contactedBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type CompanyPilverFocusesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyPilverFocusesQuery = { __typename?: "Query" } & Pick<
  Query,
  "companyPilverFocuses"
>;

export type CompanyPilverLanguagesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyPilverLanguagesQuery = { __typename?: "Query" } & Pick<
  Query,
  "companyPilverLanguages"
>;

export type CompanyPilverIdQueryVariables = Exact<{
  name: Scalars["String"];
  companyPilverIdContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyPilverIdContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyPilverIdContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyPilverIdContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companyPilverIdContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyPilverIdContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyPilverIdContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyPilverIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyPilverIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyPilverIdContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyPilverIdContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyPilverIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyPilverIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyPilverIdContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyPilverIdContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyPilverIdContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyPilverIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyPilverIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companyPilverIdCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyPilverIdCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyPilverIdCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyPilverIdCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  companyPilverIdCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyPilverIdCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyPilverIdCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyPilverIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyPilverIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyPilverIdCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyPilverIdCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyPilverIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyPilverIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyPilverIdCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyPilverIdCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyPilverIdCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyPilverIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyPilverIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companyPilverIdHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesFilter?: Maybe<ContactPilverFilter>;
  companyPilverIdHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CompanyPilverIdQuery = { __typename?: "Query" } & {
  companyPilverId: { __typename?: "CompanyPilver" } & Pick<
    CompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type CompanyPilverQueryVariables = Exact<{
  id: Scalars["String"];
  companyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  companyPilverCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  companyPilverHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CompanyPilverQuery = { __typename?: "Query" } & {
  companyPilver?: Maybe<
    { __typename?: "CompanyPilver" } & Pick<
      CompanyPilver,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Webpage"
      | "Address"
      | "Focus"
      | "Language"
    > & {
        contact?: Maybe<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "ContactPilver" } & Pick<
              ContactPilver,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "ContactPerson"
              | "Email"
              | "Phone"
              | "Description"
              | "Interest"
            > & {
                contactedBy?: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Username"
                    | "Email"
                    | "Status"
                    | "Role"
                  > & {
                      createdCompanies: {
                        __typename?: "UserCreatedCompaniesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Company" } & Pick<
                            Company,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Webpage"
                            | "Address"
                            | "Focus"
                            | "Language"
                          > & {
                              contact?: Maybe<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                              histories: {
                                __typename?: "CompanyHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      createdProjects: {
                        __typename?: "UserCreatedProjectsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Project" } & Pick<
                            Project,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Address"
                            | "Type"
                            | "Language"
                            | "Manager"
                            | "ManagerContact"
                          > & {
                              projectChange?: Maybe<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                              histories: {
                                __typename?: "ProjectHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      contacts: { __typename?: "UserContactsConnection" } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          > & {
                              company?: Maybe<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                      projectChanges: {
                        __typename?: "UserProjectChangesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          > & {
                              company?: Maybe<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                      createdWorkers: {
                        __typename?: "UserCreatedWorkersConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Worker" } & Pick<
                            Worker,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                            | "Profession"
                            | "Type"
                          > & {
                              attachments: Array<
                                { __typename?: "Attachment" } & Pick<
                                  Attachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdContracts: {
                        __typename?: "UserCreatedContractsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contract" } & Pick<
                            Contract,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Description"
                            | "Type"
                            | "Year"
                          > & {
                              attachments: Array<
                                { __typename?: "ContractAttachment" } & Pick<
                                  ContractAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdMiddlemans: {
                        __typename?: "UserCreatedMiddlemansConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Middleman" } & Pick<
                            Middleman,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                          > & {
                              attachments: Array<
                                { __typename?: "MiddlemanAttachment" } & Pick<
                                  MiddlemanAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type CompanyPilversQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<CompanyPilverFilter>;
  sorting?: Maybe<Array<CompanyPilverSort>>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyPilversNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyPilversNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyPilversNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  companyPilversNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesFilter?: Maybe<ContactPilverFilter>;
  companyPilversNodesNodesHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CompanyPilversQuery = { __typename?: "Query" } & {
  companyPilvers: { __typename?: "CompanyPilverConnection" } & Pick<
    CompanyPilverConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "CompanyPilver" } & Pick<
          CompanyPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            contact?: Maybe<
              { __typename?: "ContactPilver" } & Pick<
                ContactPilver,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "ContactPerson"
                | "Email"
                | "Phone"
                | "Description"
                | "Interest"
              > & {
                  contactedBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                }
            >;
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "ContactPilver" } & Pick<
                  ContactPilver,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    contactedBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type InterestsQueryVariables = Exact<{ [key: string]: never }>;

export type InterestsQuery = { __typename?: "Query" } & Pick<
  Query,
  "interests"
>;

export type ContactQueryVariables = Exact<{
  id: Scalars["String"];
  contactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  contactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  contactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  contactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  contactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  contactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  contactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  contactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  contactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  contactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  contactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  contactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  contactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  contactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  contactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  contactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  contactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  contactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  contactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  contactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  contactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  contactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contactCompanyCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  contactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  contactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  contactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  contactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  contactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  contactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  contactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  contactContactedByContactedByContactsSorting?: Maybe<Array<ContactSort>>;
  contactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  contactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  contactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  contactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  contactContactedByContactedByCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  contactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  contactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  contactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  contactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  contactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  contactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  contactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  contactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  contactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  contactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  contactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  contactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type ContactQuery = { __typename?: "Query" } & {
  contact?: Maybe<
    { __typename?: "Contact" } & Pick<
      Contact,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "ContactPerson"
      | "Email"
      | "Phone"
      | "Description"
      | "Interest"
    > & {
        company?: Maybe<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              createdBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
            }
        >;
        contactedBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type ContactsQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ContactFilter>;
  sorting?: Maybe<Array<ContactSort>>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  contactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  contactsNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  contactsNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactsNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  contactsNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  contactsNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  contactsNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  contactsNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  contactsNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactsNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  contactsNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  contactsNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  contactsNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  contactsNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  contactsNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  contactsNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  contactsNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  contactsNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  contactsNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  contactsNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  contactsNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  contactsNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type ContactsQuery = { __typename?: "Query" } & {
  contacts: { __typename?: "ContactConnection" } & Pick<
    ContactConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "Company" } & Pick<
                Company,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: { __typename?: "CompanyHistoriesConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
    };
};

export type InterestsPilverQueryVariables = Exact<{ [key: string]: never }>;

export type InterestsPilverQuery = { __typename?: "Query" } & Pick<
  Query,
  "interestsPilver"
>;

export type ContactPilverQueryVariables = Exact<{
  id: Scalars["String"];
  contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  contactPilverCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilverCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  contactPilverCompanyCompanyHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  contactPilverContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  contactPilverContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilverContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  contactPilverContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  contactPilverContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  contactPilverContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilverContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  contactPilverContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilverContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  contactPilverContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  contactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  contactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  contactPilverContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  contactPilverContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  contactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  contactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  contactPilverContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  contactPilverContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  contactPilverContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  contactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  contactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type ContactPilverQuery = { __typename?: "Query" } & {
  contactPilver?: Maybe<
    { __typename?: "ContactPilver" } & Pick<
      ContactPilver,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "ContactPerson"
      | "Email"
      | "Phone"
      | "Description"
      | "Interest"
    > & {
        company?: Maybe<
          { __typename?: "CompanyPilver" } & Pick<
            CompanyPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              createdBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
              histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
            }
        >;
        contactedBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type ContactPilversQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ContactPilverFilter>;
  sorting?: Maybe<Array<ContactPilverSort>>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  contactPilversNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  contactPilversNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  contactPilversNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  contactPilversNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilversNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  contactPilversNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  contactPilversNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  contactPilversNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilversNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  contactPilversNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contactPilversNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  contactPilversNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  contactPilversNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  contactPilversNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  contactPilversNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  contactPilversNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  contactPilversNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  contactPilversNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  contactPilversNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  contactPilversNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  contactPilversNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  contactPilversNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  contactPilversNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type ContactPilversQuery = { __typename?: "Query" } & {
  contactPilvers: { __typename?: "ContactPilverConnection" } & Pick<
    ContactPilverConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "CompanyPilver" } & Pick<
                CompanyPilver,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: {
                    __typename?: "CompanyPilverHistoriesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
    };
};

export type ProjectTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ProjectTypesQuery = { __typename?: "Query" } & Pick<
  Query,
  "projectTypes"
>;

export type ProjectLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type ProjectLanguagesQuery = { __typename?: "Query" } & Pick<
  Query,
  "projectLanguages"
>;

export type ProjectQueryVariables = Exact<{
  id: Scalars["String"];
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  projectProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  projectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  projectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  projectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  projectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  projectCreatedByCreatedByCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  projectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  projectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  projectCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  projectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  projectCreatedByCreatedByProjectChangesSorting?: Maybe<Array<ContactSort>>;
  projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  projectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  projectCreatedByCreatedByCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  projectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  projectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  projectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  projectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  projectCreatedByCreatedByCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  projectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  projectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  projectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  projectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  projectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  projectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  projectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  projectHistoriesPaging?: Maybe<OffsetPaging>;
  projectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  projectHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type ProjectQuery = { __typename?: "Query" } & {
  project?: Maybe<
    { __typename?: "Project" } & Pick<
      Project,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Address"
      | "Type"
      | "Language"
      | "Manager"
      | "ManagerContact"
    > & {
        projectChange?: Maybe<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        histories: { __typename?: "ProjectHistoriesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "ProjectChange" } & Pick<
              ProjectChange,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Description"
              | "State"
            > & {
                modifiedBy?: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Username"
                    | "Email"
                    | "Status"
                    | "Role"
                  > & {
                      createdCompanies: {
                        __typename?: "UserCreatedCompaniesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Company" } & Pick<
                            Company,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Webpage"
                            | "Address"
                            | "Focus"
                            | "Language"
                          > & {
                              contact?: Maybe<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                              histories: {
                                __typename?: "CompanyHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      createdProjects: {
                        __typename?: "UserCreatedProjectsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                      contacts: { __typename?: "UserContactsConnection" } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          > & {
                              company?: Maybe<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                      projectChanges: {
                        __typename?: "UserProjectChangesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          > & {
                              company?: Maybe<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                      createdWorkers: {
                        __typename?: "UserCreatedWorkersConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Worker" } & Pick<
                            Worker,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                            | "Profession"
                            | "Type"
                          > & {
                              attachments: Array<
                                { __typename?: "Attachment" } & Pick<
                                  Attachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdContracts: {
                        __typename?: "UserCreatedContractsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contract" } & Pick<
                            Contract,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Description"
                            | "Type"
                            | "Year"
                          > & {
                              attachments: Array<
                                { __typename?: "ContractAttachment" } & Pick<
                                  ContractAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdMiddlemans: {
                        __typename?: "UserCreatedMiddlemansConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Middleman" } & Pick<
                            Middleman,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                          > & {
                              attachments: Array<
                                { __typename?: "MiddlemanAttachment" } & Pick<
                                  MiddlemanAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type ProjectsQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ProjectFilter>;
  sorting?: Maybe<Array<ProjectSort>>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  projectsNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  projectsNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  projectsNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  projectsNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  projectsNodesNodesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  projectsNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  projectsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  projectsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  projectsNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  projectsNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  projectsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  projectsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  projectsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  projectsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  projectsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  projectsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  projectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  projectsNodesNodesHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type ProjectsQuery = { __typename?: "Query" } & {
  projects: { __typename?: "ProjectConnection" } & Pick<
    ProjectConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "Project" } & Pick<
          Project,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Address"
          | "Type"
          | "Language"
          | "Manager"
          | "ManagerContact"
        > & {
            projectChange?: Maybe<
              { __typename?: "ProjectChange" } & Pick<
                ProjectChange,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Description"
                | "State"
              > & {
                  modifiedBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                }
            >;
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "ProjectHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                > & {
                    modifiedBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type StatusesQueryVariables = Exact<{ [key: string]: never }>;

export type StatusesQuery = { __typename?: "Query" } & Pick<Query, "statuses">;

export type ProjectChangeQueryVariables = Exact<{
  id: Scalars["String"];
  projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  projectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  projectChangeProjectProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  projectChangeProjectProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  projectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  projectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  projectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  projectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  projectChangeProjectProjectHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangeProjectProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  projectChangeProjectProjectHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  projectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  projectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  projectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  projectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  projectChangeModifiedByModifiedByContactsSorting?: Maybe<Array<ContactSort>>;
  projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  projectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  projectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  projectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  projectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  projectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  projectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  projectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  projectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  projectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  projectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  projectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  projectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  projectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type ProjectChangeQuery = { __typename?: "Query" } & {
  projectChange?: Maybe<
    { __typename?: "ProjectChange" } & Pick<
      ProjectChange,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Description" | "State"
    > & {
        project?: Maybe<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              createdBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
            }
        >;
        modifiedBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type ProjectChangesQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ProjectChangeFilter>;
  sorting?: Maybe<Array<ProjectChangeSort>>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  projectChangesNodesNodesProjectProjectHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesProjectProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  projectChangesNodesNodesProjectProjectHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  projectChangesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  projectChangesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  projectChangesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  projectChangesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  projectChangesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  projectChangesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  projectChangesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type ProjectChangesQuery = { __typename?: "Query" } & {
  projectChanges: { __typename?: "ProjectChangeConnection" } & Pick<
    ProjectChangeConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "ProjectChange" } & Pick<
          ProjectChange,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Description"
          | "State"
        > & {
            project?: Maybe<
              { __typename?: "Project" } & Pick<
                Project,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Address"
                | "Type"
                | "Language"
                | "Manager"
                | "ManagerContact"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: { __typename?: "ProjectHistoriesConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            modifiedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
    };
};

export type WorkerTypesQueryVariables = Exact<{ [key: string]: never }>;

export type WorkerTypesQuery = { __typename?: "Query" } & Pick<
  Query,
  "workerTypes"
>;

export type WorkerProfessionQueryVariables = Exact<{ [key: string]: never }>;

export type WorkerProfessionQuery = { __typename?: "Query" } & Pick<
  Query,
  "workerProfession"
>;

export type WorkerQueryVariables = Exact<{
  id: Scalars["String"];
  workerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  workerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  workerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  workerCreatedByCreatedByCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  workerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  workerCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  workerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  workerCreatedByCreatedByProjectChangesSorting?: Maybe<Array<ContactSort>>;
  workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  workerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  workerCreatedByCreatedByCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  workerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  workerCreatedByCreatedByCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  workerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  workerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  workerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  workerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  workerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  workerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  workerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  workerAttachmentsFilter?: Maybe<AttachmentFilter>;
  workerAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
}>;

export type WorkerQuery = { __typename?: "Query" } & {
  worker?: Maybe<
    { __typename?: "Worker" } & Pick<
      Worker,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Phone"
      | "Email"
      | "Note"
      | "Profession"
      | "Type"
    > & {
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        attachments: Array<
          { __typename?: "Attachment" } & Pick<
            Attachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
      }
  >;
};

export type WorkersQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<WorkerFilter>;
  sorting?: Maybe<Array<WorkerSort>>;
  workersNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  workersNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  workersNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  workersNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  workersNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  workersNodesNodesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  workersNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  workersNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  workersNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  workersNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  workersNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  workersNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  workersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  workersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  workersNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  workersNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  workersNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  workersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  workersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  workersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  workersNodesNodesAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
}>;

export type WorkersQuery = { __typename?: "Query" } & {
  workers: { __typename?: "WorkerConnection" } & Pick<
    WorkerConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "Worker" } & Pick<
          Worker,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Phone"
          | "Email"
          | "Note"
          | "Profession"
          | "Type"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            attachments: Array<
              { __typename?: "Attachment" } & Pick<
                Attachment,
                "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
              >
            >;
          }
      >;
    };
};

export type AttachmentQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type AttachmentQuery = { __typename?: "Query" } & {
  attachment?: Maybe<
    { __typename?: "Attachment" } & Pick<
      Attachment,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
    >
  >;
};

export type AttachmentsQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<AttachmentFilter>;
  sorting?: Maybe<Array<AttachmentSort>>;
}>;

export type AttachmentsQuery = { __typename?: "Query" } & {
  attachments: { __typename?: "AttachmentConnection" } & Pick<
    AttachmentConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type ContractYearsQueryVariables = Exact<{ [key: string]: never }>;

export type ContractYearsQuery = { __typename?: "Query" } & Pick<
  Query,
  "contractYears"
>;

export type ContractQueryVariables = Exact<{
  id: Scalars["String"];
  contractCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  contractCreatedByCreatedByCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contractCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  contractCreatedByCreatedByCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  contractCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  contractCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contractCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  contractCreatedByCreatedByProjectChangesSorting?: Maybe<Array<ContactSort>>;
  contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contractCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  contractCreatedByCreatedByCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  contractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  contractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  contractCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  contractCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  contractCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  contractCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  contractCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  contractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  contractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  contractAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  contractAttachmentsSorting?: Maybe<Array<ContractAttachmentSort>>;
}>;

export type ContractQuery = { __typename?: "Query" } & {
  contract?: Maybe<
    { __typename?: "Contract" } & Pick<
      Contract,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Description"
      | "Type"
      | "Year"
    > & {
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        attachments: Array<
          { __typename?: "ContractAttachment" } & Pick<
            ContractAttachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
      }
  >;
};

export type ContractsQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ContractFilter>;
  sorting?: Maybe<Array<ContractSort>>;
  contractsNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  contractsNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contractsNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  contractsNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  contractsNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  contractsNodesNodesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contractsNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  contractsNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  contractsNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  contractsNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  contractsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  contractsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  contractsNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  contractsNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  contractsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  contractsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  contractsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  contractsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  contractsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  contractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  contractsNodesNodesAttachmentsSorting?: Maybe<Array<ContractAttachmentSort>>;
}>;

export type ContractsQuery = { __typename?: "Query" } & {
  contracts: { __typename?: "ContractConnection" } & Pick<
    ContractConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "Contract" } & Pick<
          Contract,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Description"
          | "Type"
          | "Year"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            attachments: Array<
              { __typename?: "ContractAttachment" } & Pick<
                ContractAttachment,
                "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
              >
            >;
          }
      >;
    };
};

export type ContractAttachmentQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type ContractAttachmentQuery = { __typename?: "Query" } & {
  contractAttachment?: Maybe<
    { __typename?: "ContractAttachment" } & Pick<
      ContractAttachment,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
    >
  >;
};

export type ContractAttachmentsQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ContractAttachmentFilter>;
  sorting?: Maybe<Array<ContractAttachmentSort>>;
}>;

export type ContractAttachmentsQuery = { __typename?: "Query" } & {
  contractAttachments: { __typename?: "ContractAttachmentConnection" } & Pick<
    ContractAttachmentConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "ContractAttachment" } & Pick<
          ContractAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type ContractPilverYearsQueryVariables = Exact<{ [key: string]: never }>;

export type ContractPilverYearsQuery = { __typename?: "Query" } & Pick<
  Query,
  "contractPilverYears"
>;

export type ContractPilverQueryVariables = Exact<{
  id: Scalars["String"];
  contractPilverAttachmentsFilter?: Maybe<ContractPilverAttachmentFilter>;
  contractPilverAttachmentsSorting?: Maybe<Array<ContractPilverAttachmentSort>>;
}>;

export type ContractPilverQuery = { __typename?: "Query" } & {
  contractPilver?: Maybe<
    { __typename?: "ContractPilver" } & Pick<
      ContractPilver,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Description"
      | "Type"
      | "Year"
    > & {
        attachments: Array<
          { __typename?: "ContractPilverAttachment" } & Pick<
            ContractPilverAttachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
      }
  >;
};

export type ContractPilversQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ContractPilverFilter>;
  sorting?: Maybe<Array<ContractPilverSort>>;
  contractPilversNodesNodesAttachmentsFilter?: Maybe<ContractPilverAttachmentFilter>;
  contractPilversNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractPilverAttachmentSort>
  >;
}>;

export type ContractPilversQuery = { __typename?: "Query" } & {
  contractPilvers: { __typename?: "ContractPilverConnection" } & Pick<
    ContractPilverConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "ContractPilver" } & Pick<
          ContractPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Description"
          | "Type"
          | "Year"
        > & {
            attachments: Array<
              { __typename?: "ContractPilverAttachment" } & Pick<
                ContractPilverAttachment,
                "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
              >
            >;
          }
      >;
    };
};

export type ContractPilverAttachmentQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type ContractPilverAttachmentQuery = { __typename?: "Query" } & {
  contractPilverAttachment?: Maybe<
    { __typename?: "ContractPilverAttachment" } & Pick<
      ContractPilverAttachment,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
    >
  >;
};

export type ContractPilverAttachmentsQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ContractPilverAttachmentFilter>;
  sorting?: Maybe<Array<ContractPilverAttachmentSort>>;
}>;

export type ContractPilverAttachmentsQuery = { __typename?: "Query" } & {
  contractPilverAttachments: {
    __typename?: "ContractPilverAttachmentConnection";
  } & Pick<ContractPilverAttachmentConnection, "totalCount"> & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "ContractPilverAttachment" } & Pick<
          ContractPilverAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type DeletedCompanyQueryVariables = Exact<{
  id: Scalars["String"];
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyDeletedByDeletedByContactsSorting?: Maybe<Array<ContactSort>>;
  deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type DeletedCompanyQuery = { __typename?: "Query" } & {
  deletedCompany?: Maybe<
    { __typename?: "DeletedCompany" } & Pick<
      DeletedCompany,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Webpage"
      | "Address"
      | "Focus"
      | "Language"
    > & {
        contact?: Maybe<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        histories: { __typename?: "DeletedCompanyHistoriesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Contact" } & Pick<
              Contact,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "ContactPerson"
              | "Email"
              | "Phone"
              | "Description"
              | "Interest"
            > & {
                company?: Maybe<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      createdBy?: Maybe<
                        { __typename?: "User" } & Pick<
                          User,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Username"
                          | "Email"
                          | "Status"
                          | "Role"
                        > & {
                            createdCompanies: {
                              __typename?: "UserCreatedCompaniesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                            createdProjects: {
                              __typename?: "UserCreatedProjectsConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Project" } & Pick<
                                  Project,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Address"
                                  | "Type"
                                  | "Language"
                                  | "Manager"
                                  | "ManagerContact"
                                > & {
                                    projectChange?: Maybe<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                    histories: {
                                      __typename?: "ProjectHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                      nodes: Array<
                                        { __typename?: "ProjectChange" } & Pick<
                                          ProjectChange,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Description"
                                          | "State"
                                        >
                                      >;
                                    };
                                  }
                              >;
                            };
                            contacts: {
                              __typename?: "UserContactsConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                            projectChanges: {
                              __typename?: "UserProjectChangesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                            createdWorkers: {
                              __typename?: "UserCreatedWorkersConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Worker" } & Pick<
                                  Worker,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Phone"
                                  | "Email"
                                  | "Note"
                                  | "Profession"
                                  | "Type"
                                > & {
                                    attachments: Array<
                                      { __typename?: "Attachment" } & Pick<
                                        Attachment,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Name"
                                      >
                                    >;
                                  }
                              >;
                            };
                            createdContracts: {
                              __typename?: "UserCreatedContractsConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contract" } & Pick<
                                  Contract,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Description"
                                  | "Type"
                                  | "Year"
                                > & {
                                    attachments: Array<
                                      {
                                        __typename?: "ContractAttachment";
                                      } & Pick<
                                        ContractAttachment,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Name"
                                      >
                                    >;
                                  }
                              >;
                            };
                            createdMiddlemans: {
                              __typename?: "UserCreatedMiddlemansConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Middleman" } & Pick<
                                  Middleman,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Phone"
                                  | "Email"
                                  | "Note"
                                > & {
                                    attachments: Array<
                                      {
                                        __typename?: "MiddlemanAttachment";
                                      } & Pick<
                                        MiddlemanAttachment,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Name"
                                      >
                                    >;
                                  }
                              >;
                            };
                          }
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                    }
                >;
                contactedBy?: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Username"
                    | "Email"
                    | "Status"
                    | "Role"
                  > & {
                      createdCompanies: {
                        __typename?: "UserCreatedCompaniesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Company" } & Pick<
                            Company,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Webpage"
                            | "Address"
                            | "Focus"
                            | "Language"
                          > & {
                              histories: {
                                __typename?: "CompanyHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                              };
                            }
                        >;
                      };
                      createdProjects: {
                        __typename?: "UserCreatedProjectsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Project" } & Pick<
                            Project,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Address"
                            | "Type"
                            | "Language"
                            | "Manager"
                            | "ManagerContact"
                          > & {
                              projectChange?: Maybe<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                              histories: {
                                __typename?: "ProjectHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      contacts: { __typename?: "UserContactsConnection" } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                      projectChanges: {
                        __typename?: "UserProjectChangesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                      createdWorkers: {
                        __typename?: "UserCreatedWorkersConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Worker" } & Pick<
                            Worker,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                            | "Profession"
                            | "Type"
                          > & {
                              attachments: Array<
                                { __typename?: "Attachment" } & Pick<
                                  Attachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdContracts: {
                        __typename?: "UserCreatedContractsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contract" } & Pick<
                            Contract,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Description"
                            | "Type"
                            | "Year"
                          > & {
                              attachments: Array<
                                { __typename?: "ContractAttachment" } & Pick<
                                  ContractAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdMiddlemans: {
                        __typename?: "UserCreatedMiddlemansConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Middleman" } & Pick<
                            Middleman,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                          > & {
                              attachments: Array<
                                { __typename?: "MiddlemanAttachment" } & Pick<
                                  MiddlemanAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
        deletedBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type DeletedCompaniesQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<DeletedCompanyFilter>;
  sorting?: Maybe<Array<DeletedCompanySort>>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesHistoriesSorting?: Maybe<Array<ContactSort>>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type DeletedCompaniesQuery = { __typename?: "Query" } & {
  deletedCompanies: { __typename?: "DeletedCompanyConnection" } & Pick<
    DeletedCompanyConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "DeletedCompany" } & Pick<
          DeletedCompany,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            contact?: Maybe<
              { __typename?: "Contact" } & Pick<
                Contact,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "ContactPerson"
                | "Email"
                | "Phone"
                | "Description"
                | "Interest"
              > & {
                  company?: Maybe<
                    { __typename?: "Company" } & Pick<
                      Company,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Name"
                      | "Webpage"
                      | "Address"
                      | "Focus"
                      | "Language"
                    > & {
                        createdBy?: Maybe<
                          { __typename?: "User" } & Pick<
                            User,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Username"
                            | "Email"
                            | "Status"
                            | "Role"
                          > & {
                              createdCompanies: {
                                __typename?: "UserCreatedCompaniesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                              };
                              createdProjects: {
                                __typename?: "UserCreatedProjectsConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Project" } & Pick<
                                    Project,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Address"
                                    | "Type"
                                    | "Language"
                                    | "Manager"
                                    | "ManagerContact"
                                  > & {
                                      projectChange?: Maybe<
                                        { __typename?: "ProjectChange" } & Pick<
                                          ProjectChange,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Description"
                                          | "State"
                                        >
                                      >;
                                      histories: {
                                        __typename?: "ProjectHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                        nodes: Array<
                                          {
                                            __typename?: "ProjectChange";
                                          } & Pick<
                                            ProjectChange,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Description"
                                            | "State"
                                          >
                                        >;
                                      };
                                    }
                                >;
                              };
                              contacts: {
                                __typename?: "UserContactsConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                              };
                              projectChanges: {
                                __typename?: "UserProjectChangesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                              };
                              createdWorkers: {
                                __typename?: "UserCreatedWorkersConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Worker" } & Pick<
                                    Worker,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Phone"
                                    | "Email"
                                    | "Note"
                                    | "Profession"
                                    | "Type"
                                  > & {
                                      attachments: Array<
                                        { __typename?: "Attachment" } & Pick<
                                          Attachment,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Name"
                                        >
                                      >;
                                    }
                                >;
                              };
                              createdContracts: {
                                __typename?: "UserCreatedContractsConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Contract" } & Pick<
                                    Contract,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Description"
                                    | "Type"
                                    | "Year"
                                  > & {
                                      attachments: Array<
                                        {
                                          __typename?: "ContractAttachment";
                                        } & Pick<
                                          ContractAttachment,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Name"
                                        >
                                      >;
                                    }
                                >;
                              };
                              createdMiddlemans: {
                                __typename?: "UserCreatedMiddlemansConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Middleman" } & Pick<
                                    Middleman,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Phone"
                                    | "Email"
                                    | "Note"
                                  > & {
                                      attachments: Array<
                                        {
                                          __typename?: "MiddlemanAttachment";
                                        } & Pick<
                                          MiddlemanAttachment,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Name"
                                        >
                                      >;
                                    }
                                >;
                              };
                            }
                        >;
                        histories: {
                          __typename?: "CompanyHistoriesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                      }
                  >;
                  contactedBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                }
            >;
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "DeletedCompanyHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          createdBy?: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Username"
                              | "Email"
                              | "Status"
                              | "Role"
                            > & {
                                createdCompanies: {
                                  __typename?: "UserCreatedCompaniesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                                createdProjects: {
                                  __typename?: "UserCreatedProjectsConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Project" } & Pick<
                                      Project,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Address"
                                      | "Type"
                                      | "Language"
                                      | "Manager"
                                      | "ManagerContact"
                                    > & {
                                        projectChange?: Maybe<
                                          {
                                            __typename?: "ProjectChange";
                                          } & Pick<
                                            ProjectChange,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Description"
                                            | "State"
                                          >
                                        >;
                                        histories: {
                                          __typename?: "ProjectHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                          nodes: Array<
                                            {
                                              __typename?: "ProjectChange";
                                            } & Pick<
                                              ProjectChange,
                                              | "Id"
                                              | "CreatedAt"
                                              | "UpdatedAt"
                                              | "DeletedAt"
                                              | "Description"
                                              | "State"
                                            >
                                          >;
                                        };
                                      }
                                  >;
                                };
                                contacts: {
                                  __typename?: "UserContactsConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                                projectChanges: {
                                  __typename?: "UserProjectChangesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                                createdWorkers: {
                                  __typename?: "UserCreatedWorkersConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Worker" } & Pick<
                                      Worker,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Phone"
                                      | "Email"
                                      | "Note"
                                      | "Profession"
                                      | "Type"
                                    > & {
                                        attachments: Array<
                                          { __typename?: "Attachment" } & Pick<
                                            Attachment,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Name"
                                          >
                                        >;
                                      }
                                  >;
                                };
                                createdContracts: {
                                  __typename?: "UserCreatedContractsConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contract" } & Pick<
                                      Contract,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Description"
                                      | "Type"
                                      | "Year"
                                    > & {
                                        attachments: Array<
                                          {
                                            __typename?: "ContractAttachment";
                                          } & Pick<
                                            ContractAttachment,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Name"
                                          >
                                        >;
                                      }
                                  >;
                                };
                                createdMiddlemans: {
                                  __typename?: "UserCreatedMiddlemansConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Middleman" } & Pick<
                                      Middleman,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Phone"
                                      | "Email"
                                      | "Note"
                                    > & {
                                        attachments: Array<
                                          {
                                            __typename?: "MiddlemanAttachment";
                                          } & Pick<
                                            MiddlemanAttachment,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Name"
                                          >
                                        >;
                                      }
                                  >;
                                };
                              }
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                    contactedBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            deletedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
    };
};

export type DeletedCompanyPilverQueryVariables = Exact<{
  id: Scalars["String"];
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyPilverContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  deletedCompanyPilverContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  deletedCompanyPilverHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilverDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilverDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilverDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type DeletedCompanyPilverQuery = { __typename?: "Query" } & {
  deletedCompanyPilver?: Maybe<
    { __typename?: "DeletedCompanyPilver" } & Pick<
      DeletedCompanyPilver,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Webpage"
      | "Address"
      | "Focus"
      | "Language"
    > & {
        contact?: Maybe<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "CompanyPilver" } & Pick<
                  CompanyPilver,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: {
                      __typename?: "CompanyPilverHistoriesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        histories: {
          __typename?: "DeletedCompanyPilverHistoriesConnection";
        } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "ContactPilver" } & Pick<
              ContactPilver,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "ContactPerson"
              | "Email"
              | "Phone"
              | "Description"
              | "Interest"
            > & {
                company?: Maybe<
                  { __typename?: "CompanyPilver" } & Pick<
                    CompanyPilver,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      createdBy?: Maybe<
                        { __typename?: "User" } & Pick<
                          User,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Username"
                          | "Email"
                          | "Status"
                          | "Role"
                        > & {
                            createdCompanies: {
                              __typename?: "UserCreatedCompaniesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    contact?: Maybe<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                      nodes: Array<
                                        { __typename?: "Contact" } & Pick<
                                          Contact,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "ContactPerson"
                                          | "Email"
                                          | "Phone"
                                          | "Description"
                                          | "Interest"
                                        >
                                      >;
                                    };
                                  }
                              >;
                            };
                            createdProjects: {
                              __typename?: "UserCreatedProjectsConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Project" } & Pick<
                                  Project,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Address"
                                  | "Type"
                                  | "Language"
                                  | "Manager"
                                  | "ManagerContact"
                                > & {
                                    projectChange?: Maybe<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                    histories: {
                                      __typename?: "ProjectHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                      nodes: Array<
                                        { __typename?: "ProjectChange" } & Pick<
                                          ProjectChange,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Description"
                                          | "State"
                                        >
                                      >;
                                    };
                                  }
                              >;
                            };
                            contacts: {
                              __typename?: "UserContactsConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                > & {
                                    company?: Maybe<
                                      { __typename?: "Company" } & Pick<
                                        Company,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Name"
                                        | "Webpage"
                                        | "Address"
                                        | "Focus"
                                        | "Language"
                                      > & {
                                          histories: {
                                            __typename?: "CompanyHistoriesConnection";
                                          } & {
                                            pageInfo: {
                                              __typename?: "OffsetPageInfo";
                                            } & Pick<
                                              OffsetPageInfo,
                                              "hasNextPage" | "hasPreviousPage"
                                            >;
                                          };
                                        }
                                    >;
                                  }
                              >;
                            };
                            projectChanges: {
                              __typename?: "UserProjectChangesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                > & {
                                    company?: Maybe<
                                      { __typename?: "Company" } & Pick<
                                        Company,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Name"
                                        | "Webpage"
                                        | "Address"
                                        | "Focus"
                                        | "Language"
                                      > & {
                                          histories: {
                                            __typename?: "CompanyHistoriesConnection";
                                          } & {
                                            pageInfo: {
                                              __typename?: "OffsetPageInfo";
                                            } & Pick<
                                              OffsetPageInfo,
                                              "hasNextPage" | "hasPreviousPage"
                                            >;
                                          };
                                        }
                                    >;
                                  }
                              >;
                            };
                            createdWorkers: {
                              __typename?: "UserCreatedWorkersConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Worker" } & Pick<
                                  Worker,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Phone"
                                  | "Email"
                                  | "Note"
                                  | "Profession"
                                  | "Type"
                                > & {
                                    attachments: Array<
                                      { __typename?: "Attachment" } & Pick<
                                        Attachment,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Name"
                                      >
                                    >;
                                  }
                              >;
                            };
                            createdContracts: {
                              __typename?: "UserCreatedContractsConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contract" } & Pick<
                                  Contract,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Description"
                                  | "Type"
                                  | "Year"
                                > & {
                                    attachments: Array<
                                      {
                                        __typename?: "ContractAttachment";
                                      } & Pick<
                                        ContractAttachment,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Name"
                                      >
                                    >;
                                  }
                              >;
                            };
                            createdMiddlemans: {
                              __typename?: "UserCreatedMiddlemansConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Middleman" } & Pick<
                                  Middleman,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Phone"
                                  | "Email"
                                  | "Note"
                                > & {
                                    attachments: Array<
                                      {
                                        __typename?: "MiddlemanAttachment";
                                      } & Pick<
                                        MiddlemanAttachment,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Name"
                                      >
                                    >;
                                  }
                              >;
                            };
                          }
                      >;
                      histories: {
                        __typename?: "CompanyPilverHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                    }
                >;
                contactedBy?: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Username"
                    | "Email"
                    | "Status"
                    | "Role"
                  > & {
                      createdCompanies: {
                        __typename?: "UserCreatedCompaniesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Company" } & Pick<
                            Company,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Webpage"
                            | "Address"
                            | "Focus"
                            | "Language"
                          > & {
                              contact?: Maybe<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                              histories: {
                                __typename?: "CompanyHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      createdProjects: {
                        __typename?: "UserCreatedProjectsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Project" } & Pick<
                            Project,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Address"
                            | "Type"
                            | "Language"
                            | "Manager"
                            | "ManagerContact"
                          > & {
                              projectChange?: Maybe<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                              histories: {
                                __typename?: "ProjectHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      contacts: { __typename?: "UserContactsConnection" } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          > & {
                              company?: Maybe<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                      projectChanges: {
                        __typename?: "UserProjectChangesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          > & {
                              company?: Maybe<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                      createdWorkers: {
                        __typename?: "UserCreatedWorkersConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Worker" } & Pick<
                            Worker,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                            | "Profession"
                            | "Type"
                          > & {
                              attachments: Array<
                                { __typename?: "Attachment" } & Pick<
                                  Attachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdContracts: {
                        __typename?: "UserCreatedContractsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contract" } & Pick<
                            Contract,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Description"
                            | "Type"
                            | "Year"
                          > & {
                              attachments: Array<
                                { __typename?: "ContractAttachment" } & Pick<
                                  ContractAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdMiddlemans: {
                        __typename?: "UserCreatedMiddlemansConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Middleman" } & Pick<
                            Middleman,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                          > & {
                              attachments: Array<
                                { __typename?: "MiddlemanAttachment" } & Pick<
                                  MiddlemanAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
        deletedBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type DeletedCompanyPilversQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<DeletedCompanyPilverFilter>;
  sorting?: Maybe<Array<DeletedCompanyPilverSort>>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesFilter?: Maybe<ContactPilverFilter>;
  deletedCompanyPilversNodesNodesHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type DeletedCompanyPilversQuery = { __typename?: "Query" } & {
  deletedCompanyPilvers: {
    __typename?: "DeletedCompanyPilverConnection";
  } & Pick<DeletedCompanyPilverConnection, "totalCount"> & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "DeletedCompanyPilver" } & Pick<
          DeletedCompanyPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            contact?: Maybe<
              { __typename?: "ContactPilver" } & Pick<
                ContactPilver,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "ContactPerson"
                | "Email"
                | "Phone"
                | "Description"
                | "Interest"
              > & {
                  company?: Maybe<
                    { __typename?: "CompanyPilver" } & Pick<
                      CompanyPilver,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Name"
                      | "Webpage"
                      | "Address"
                      | "Focus"
                      | "Language"
                    > & {
                        createdBy?: Maybe<
                          { __typename?: "User" } & Pick<
                            User,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Username"
                            | "Email"
                            | "Status"
                            | "Role"
                          > & {
                              createdCompanies: {
                                __typename?: "UserCreatedCompaniesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      contact?: Maybe<
                                        { __typename?: "Contact" } & Pick<
                                          Contact,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "ContactPerson"
                                          | "Email"
                                          | "Phone"
                                          | "Description"
                                          | "Interest"
                                        >
                                      >;
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                        nodes: Array<
                                          { __typename?: "Contact" } & Pick<
                                            Contact,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "ContactPerson"
                                            | "Email"
                                            | "Phone"
                                            | "Description"
                                            | "Interest"
                                          >
                                        >;
                                      };
                                    }
                                >;
                              };
                              createdProjects: {
                                __typename?: "UserCreatedProjectsConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Project" } & Pick<
                                    Project,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Address"
                                    | "Type"
                                    | "Language"
                                    | "Manager"
                                    | "ManagerContact"
                                  > & {
                                      projectChange?: Maybe<
                                        { __typename?: "ProjectChange" } & Pick<
                                          ProjectChange,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Description"
                                          | "State"
                                        >
                                      >;
                                      histories: {
                                        __typename?: "ProjectHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                        nodes: Array<
                                          {
                                            __typename?: "ProjectChange";
                                          } & Pick<
                                            ProjectChange,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Description"
                                            | "State"
                                          >
                                        >;
                                      };
                                    }
                                >;
                              };
                              contacts: {
                                __typename?: "UserContactsConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  > & {
                                      company?: Maybe<
                                        { __typename?: "Company" } & Pick<
                                          Company,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Name"
                                          | "Webpage"
                                          | "Address"
                                          | "Focus"
                                          | "Language"
                                        > & {
                                            histories: {
                                              __typename?: "CompanyHistoriesConnection";
                                            } & {
                                              pageInfo: {
                                                __typename?: "OffsetPageInfo";
                                              } & Pick<
                                                OffsetPageInfo,
                                                | "hasNextPage"
                                                | "hasPreviousPage"
                                              >;
                                            };
                                          }
                                      >;
                                    }
                                >;
                              };
                              projectChanges: {
                                __typename?: "UserProjectChangesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  > & {
                                      company?: Maybe<
                                        { __typename?: "Company" } & Pick<
                                          Company,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Name"
                                          | "Webpage"
                                          | "Address"
                                          | "Focus"
                                          | "Language"
                                        > & {
                                            histories: {
                                              __typename?: "CompanyHistoriesConnection";
                                            } & {
                                              pageInfo: {
                                                __typename?: "OffsetPageInfo";
                                              } & Pick<
                                                OffsetPageInfo,
                                                | "hasNextPage"
                                                | "hasPreviousPage"
                                              >;
                                            };
                                          }
                                      >;
                                    }
                                >;
                              };
                              createdWorkers: {
                                __typename?: "UserCreatedWorkersConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Worker" } & Pick<
                                    Worker,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Phone"
                                    | "Email"
                                    | "Note"
                                    | "Profession"
                                    | "Type"
                                  > & {
                                      attachments: Array<
                                        { __typename?: "Attachment" } & Pick<
                                          Attachment,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Name"
                                        >
                                      >;
                                    }
                                >;
                              };
                              createdContracts: {
                                __typename?: "UserCreatedContractsConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Contract" } & Pick<
                                    Contract,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Description"
                                    | "Type"
                                    | "Year"
                                  > & {
                                      attachments: Array<
                                        {
                                          __typename?: "ContractAttachment";
                                        } & Pick<
                                          ContractAttachment,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Name"
                                        >
                                      >;
                                    }
                                >;
                              };
                              createdMiddlemans: {
                                __typename?: "UserCreatedMiddlemansConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Middleman" } & Pick<
                                    Middleman,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Phone"
                                    | "Email"
                                    | "Note"
                                  > & {
                                      attachments: Array<
                                        {
                                          __typename?: "MiddlemanAttachment";
                                        } & Pick<
                                          MiddlemanAttachment,
                                          | "Id"
                                          | "CreatedAt"
                                          | "UpdatedAt"
                                          | "DeletedAt"
                                          | "Name"
                                        >
                                      >;
                                    }
                                >;
                              };
                            }
                        >;
                        histories: {
                          __typename?: "CompanyPilverHistoriesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                      }
                  >;
                  contactedBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                }
            >;
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: {
              __typename?: "DeletedCompanyPilverHistoriesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "ContactPilver" } & Pick<
                  ContactPilver,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "CompanyPilver" } & Pick<
                        CompanyPilver,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          createdBy?: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Username"
                              | "Email"
                              | "Status"
                              | "Role"
                            > & {
                                createdCompanies: {
                                  __typename?: "UserCreatedCompaniesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        contact?: Maybe<
                                          { __typename?: "Contact" } & Pick<
                                            Contact,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "ContactPerson"
                                            | "Email"
                                            | "Phone"
                                            | "Description"
                                            | "Interest"
                                          >
                                        >;
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                          nodes: Array<
                                            { __typename?: "Contact" } & Pick<
                                              Contact,
                                              | "Id"
                                              | "CreatedAt"
                                              | "UpdatedAt"
                                              | "DeletedAt"
                                              | "ContactPerson"
                                              | "Email"
                                              | "Phone"
                                              | "Description"
                                              | "Interest"
                                            >
                                          >;
                                        };
                                      }
                                  >;
                                };
                                createdProjects: {
                                  __typename?: "UserCreatedProjectsConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Project" } & Pick<
                                      Project,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Address"
                                      | "Type"
                                      | "Language"
                                      | "Manager"
                                      | "ManagerContact"
                                    > & {
                                        projectChange?: Maybe<
                                          {
                                            __typename?: "ProjectChange";
                                          } & Pick<
                                            ProjectChange,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Description"
                                            | "State"
                                          >
                                        >;
                                        histories: {
                                          __typename?: "ProjectHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                          nodes: Array<
                                            {
                                              __typename?: "ProjectChange";
                                            } & Pick<
                                              ProjectChange,
                                              | "Id"
                                              | "CreatedAt"
                                              | "UpdatedAt"
                                              | "DeletedAt"
                                              | "Description"
                                              | "State"
                                            >
                                          >;
                                        };
                                      }
                                  >;
                                };
                                contacts: {
                                  __typename?: "UserContactsConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    > & {
                                        company?: Maybe<
                                          { __typename?: "Company" } & Pick<
                                            Company,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Name"
                                            | "Webpage"
                                            | "Address"
                                            | "Focus"
                                            | "Language"
                                          > & {
                                              histories: {
                                                __typename?: "CompanyHistoriesConnection";
                                              } & {
                                                pageInfo: {
                                                  __typename?: "OffsetPageInfo";
                                                } & Pick<
                                                  OffsetPageInfo,
                                                  | "hasNextPage"
                                                  | "hasPreviousPage"
                                                >;
                                              };
                                            }
                                        >;
                                      }
                                  >;
                                };
                                projectChanges: {
                                  __typename?: "UserProjectChangesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    > & {
                                        company?: Maybe<
                                          { __typename?: "Company" } & Pick<
                                            Company,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Name"
                                            | "Webpage"
                                            | "Address"
                                            | "Focus"
                                            | "Language"
                                          > & {
                                              histories: {
                                                __typename?: "CompanyHistoriesConnection";
                                              } & {
                                                pageInfo: {
                                                  __typename?: "OffsetPageInfo";
                                                } & Pick<
                                                  OffsetPageInfo,
                                                  | "hasNextPage"
                                                  | "hasPreviousPage"
                                                >;
                                              };
                                            }
                                        >;
                                      }
                                  >;
                                };
                                createdWorkers: {
                                  __typename?: "UserCreatedWorkersConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Worker" } & Pick<
                                      Worker,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Phone"
                                      | "Email"
                                      | "Note"
                                      | "Profession"
                                      | "Type"
                                    > & {
                                        attachments: Array<
                                          { __typename?: "Attachment" } & Pick<
                                            Attachment,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Name"
                                          >
                                        >;
                                      }
                                  >;
                                };
                                createdContracts: {
                                  __typename?: "UserCreatedContractsConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contract" } & Pick<
                                      Contract,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Description"
                                      | "Type"
                                      | "Year"
                                    > & {
                                        attachments: Array<
                                          {
                                            __typename?: "ContractAttachment";
                                          } & Pick<
                                            ContractAttachment,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Name"
                                          >
                                        >;
                                      }
                                  >;
                                };
                                createdMiddlemans: {
                                  __typename?: "UserCreatedMiddlemansConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Middleman" } & Pick<
                                      Middleman,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Phone"
                                      | "Email"
                                      | "Note"
                                    > & {
                                        attachments: Array<
                                          {
                                            __typename?: "MiddlemanAttachment";
                                          } & Pick<
                                            MiddlemanAttachment,
                                            | "Id"
                                            | "CreatedAt"
                                            | "UpdatedAt"
                                            | "DeletedAt"
                                            | "Name"
                                          >
                                        >;
                                      }
                                  >;
                                };
                              }
                          >;
                          histories: {
                            __typename?: "CompanyPilverHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                    contactedBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            deletedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
    };
};

export type DeletedMiddlemanQueryVariables = Exact<{
  id: Scalars["String"];
  deletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedMiddlemanCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedMiddlemanAttachmentsSorting?: Maybe<Array<MiddlemanAttachmentSort>>;
  deletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemanDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedMiddlemanDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedMiddlemanDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  deletedMiddlemanDeletedByDeletedByContactsSorting?: Maybe<Array<ContactSort>>;
  deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemanDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedMiddlemanDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemanDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedMiddlemanDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedMiddlemanDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedMiddlemanDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type DeletedMiddlemanQuery = { __typename?: "Query" } & {
  deletedMiddleman?: Maybe<
    { __typename?: "DeletedMiddleman" } & Pick<
      DeletedMiddleman,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Phone"
      | "Email"
      | "Note"
    > & {
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        attachments: Array<
          { __typename?: "MiddlemanAttachment" } & Pick<
            MiddlemanAttachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
        deletedBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type DeletedMiddlemenQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<DeletedMiddlemanFilter>;
  sorting?: Maybe<Array<DeletedMiddlemanSort>>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedMiddlemenNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedMiddlemenNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type DeletedMiddlemenQuery = { __typename?: "Query" } & {
  deletedMiddlemen: { __typename?: "DeletedMiddlemanConnection" } & Pick<
    DeletedMiddlemanConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "DeletedMiddleman" } & Pick<
          DeletedMiddleman,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Phone"
          | "Email"
          | "Note"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            attachments: Array<
              { __typename?: "MiddlemanAttachment" } & Pick<
                MiddlemanAttachment,
                "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
              >
            >;
            deletedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
    };
};

export type DeletedWorkerQueryVariables = Exact<{
  id: Scalars["String"];
  deletedWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedWorkerCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedWorkerAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
  deletedWorkerDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedWorkerDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkerDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedWorkerDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedWorkerDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  deletedWorkerDeletedByDeletedByContactsSorting?: Maybe<Array<ContactSort>>;
  deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkerDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedWorkerDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkerDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedWorkerDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedWorkerDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedWorkerDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedWorkerDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedWorkerDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedWorkerDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type DeletedWorkerQuery = { __typename?: "Query" } & {
  deletedWorker?: Maybe<
    { __typename?: "DeletedWorker" } & Pick<
      DeletedWorker,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Phone"
      | "Email"
      | "Note"
      | "Profession"
      | "Type"
    > & {
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        attachments: Array<
          { __typename?: "Attachment" } & Pick<
            Attachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
        deletedBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type DeletedWorkersQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<DeletedWorkerFilter>;
  sorting?: Maybe<Array<DeletedWorkerSort>>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  deletedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedWorkersNodesNodesAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type DeletedWorkersQuery = { __typename?: "Query" } & {
  deletedWorkers: { __typename?: "DeletedWorkerConnection" } & Pick<
    DeletedWorkerConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "DeletedWorker" } & Pick<
          DeletedWorker,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Phone"
          | "Email"
          | "Note"
          | "Profession"
          | "Type"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            attachments: Array<
              { __typename?: "Attachment" } & Pick<
                Attachment,
                "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
              >
            >;
            deletedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
    };
};

export type MiddlemanQueryVariables = Exact<{
  id: Scalars["String"];
  middlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  middlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  middlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  middlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  middlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  middlemanCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  middlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  middlemanCreatedByCreatedByProjectChangesSorting?: Maybe<Array<ContactSort>>;
  middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  middlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  middlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  middlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  middlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  middlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  middlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  middlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  middlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  middlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  middlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  middlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  middlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  middlemanAttachmentsSorting?: Maybe<Array<MiddlemanAttachmentSort>>;
}>;

export type MiddlemanQuery = { __typename?: "Query" } & {
  middleman?: Maybe<
    { __typename?: "Middleman" } & Pick<
      Middleman,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Phone"
      | "Email"
      | "Note"
    > & {
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
            }
        >;
        attachments: Array<
          { __typename?: "MiddlemanAttachment" } & Pick<
            MiddlemanAttachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
      }
  >;
};

export type MiddlemenQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<MiddlemanFilter>;
  sorting?: Maybe<Array<MiddlemanSort>>;
  middlemenNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  middlemenNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  middlemenNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  middlemenNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  middlemenNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  middlemenNodesNodesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  middlemenNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  middlemenNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  middlemenNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  middlemenNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  middlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  middlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  middlemenNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  middlemenNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  middlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  middlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  middlemenNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  middlemenNodesNodesAttachmentsSorting?: Maybe<Array<MiddlemanAttachmentSort>>;
}>;

export type MiddlemenQuery = { __typename?: "Query" } & {
  middlemen: { __typename?: "MiddlemanConnection" } & Pick<
    MiddlemanConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "Middleman" } & Pick<
          Middleman,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Phone"
          | "Email"
          | "Note"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            attachments: Array<
              { __typename?: "MiddlemanAttachment" } & Pick<
                MiddlemanAttachment,
                "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
              >
            >;
          }
      >;
    };
};

export type MiddlemanAttachmentQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type MiddlemanAttachmentQuery = { __typename?: "Query" } & {
  middlemanAttachment?: Maybe<
    { __typename?: "MiddlemanAttachment" } & Pick<
      MiddlemanAttachment,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
    >
  >;
};

export type MiddlemanAttachmentsQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<MiddlemanAttachmentFilter>;
  sorting?: Maybe<Array<MiddlemanAttachmentSort>>;
}>;

export type MiddlemanAttachmentsQuery = { __typename?: "Query" } & {
  middlemanAttachments: { __typename?: "MiddlemanAttachmentConnection" } & Pick<
    MiddlemanAttachmentConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CarQueryVariables = Exact<{
  id: Scalars["String"];
  carCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  carCreatedByCreatedByCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  carCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  carCreatedByCreatedByCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  carCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  carCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  carCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  carCreatedByCreatedByProjectChangesSorting?: Maybe<Array<ContactSort>>;
  carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  carCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  carCreatedByCreatedByCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  carCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  carCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  carCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  carCreatedByCreatedByCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  carCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  carCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  carCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  carCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  carCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  carCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  carCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  carAttachmentsFilter?: Maybe<CarAttachmentFilter>;
  carAttachmentsSorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type CarQuery = { __typename?: "Query" } & {
  car?: Maybe<
    { __typename?: "Car" } & Pick<
      Car,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Description"
      | "Evidence"
      | "Type"
    > & {
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        attachments: Array<
          { __typename?: "CarAttachment" } & Pick<
            CarAttachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
      }
  >;
};

export type CarsQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<CarFilter>;
  sorting?: Maybe<Array<CarSort>>;
  carsNodesNodesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  carsNodesNodesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  carsNodesNodesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  carsNodesNodesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  carsNodesNodesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  carsNodesNodesCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  carsNodesNodesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  carsNodesNodesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  carsNodesNodesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  carsNodesNodesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  carsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  carsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  carsNodesNodesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  carsNodesNodesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  carsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  carsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  carsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  carsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  carsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  carsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  carsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  carsNodesNodesAttachmentsFilter?: Maybe<CarAttachmentFilter>;
  carsNodesNodesAttachmentsSorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type CarsQuery = { __typename?: "Query" } & {
  cars: { __typename?: "CarConnection" } & Pick<CarConnection, "totalCount"> & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "Car" } & Pick<
          Car,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Description"
          | "Evidence"
          | "Type"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            attachments: Array<
              { __typename?: "CarAttachment" } & Pick<
                CarAttachment,
                "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
              >
            >;
          }
      >;
    };
};

export type CarAttachmentQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type CarAttachmentQuery = { __typename?: "Query" } & {
  carAttachment?: Maybe<
    { __typename?: "CarAttachment" } & Pick<
      CarAttachment,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
    >
  >;
};

export type CarAttachmentsQueryVariables = Exact<{
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<CarAttachmentFilter>;
  sorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type CarAttachmentsQuery = { __typename?: "Query" } & {
  carAttachments: { __typename?: "CarAttachmentConnection" } & Pick<
    CarAttachmentConnection,
    "totalCount"
  > & {
      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
        OffsetPageInfo,
        "hasNextPage" | "hasPreviousPage"
      >;
      nodes: Array<
        { __typename?: "CarAttachment" } & Pick<
          CarAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type LoginMutationVariables = Exact<{
  input: LoginInputDto;
}>;

export type LoginMutation = { __typename?: "Mutation" } & {
  login: { __typename?: "LoginResponse" } & Pick<LoginResponse, "accessToken">;
};

export type AddCreatedCompaniesToUserMutationVariables = Exact<{
  input: AddCreatedCompaniesToUserInput;
  addCreatedCompaniesToUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addCreatedCompaniesToUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedCompaniesToUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addCreatedCompaniesToUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addCreatedCompaniesToUserContactsPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserContactsFilter?: Maybe<ContactFilter>;
  addCreatedCompaniesToUserContactsSorting?: Maybe<Array<ContactSort>>;
  addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedCompaniesToUserProjectChangesPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserProjectChangesFilter?: Maybe<ContactFilter>;
  addCreatedCompaniesToUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedCompaniesToUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addCreatedCompaniesToUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  addCreatedCompaniesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addCreatedCompaniesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addCreatedCompaniesToUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserCreatedContractsFilter?: Maybe<ContractFilter>;
  addCreatedCompaniesToUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  addCreatedCompaniesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addCreatedCompaniesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addCreatedCompaniesToUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addCreatedCompaniesToUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addCreatedCompaniesToUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addCreatedCompaniesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addCreatedCompaniesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddCreatedCompaniesToUserMutation = { __typename?: "Mutation" } & {
  addCreatedCompaniesToUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type SetCreatedCompaniesOnUserMutationVariables = Exact<{
  input: SetCreatedCompaniesOnUserInput;
  setCreatedCompaniesOnUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedCompaniesOnUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedCompaniesOnUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedCompaniesOnUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedCompaniesOnUserContactsPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserContactsFilter?: Maybe<ContactFilter>;
  setCreatedCompaniesOnUserContactsSorting?: Maybe<Array<ContactSort>>;
  setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedCompaniesOnUserProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedCompaniesOnUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedCompaniesOnUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedCompaniesOnUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  setCreatedCompaniesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedCompaniesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedCompaniesOnUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedCompaniesOnUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  setCreatedCompaniesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedCompaniesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedCompaniesOnUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedCompaniesOnUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedCompaniesOnUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedCompaniesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedCompaniesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedCompaniesOnUserMutation = { __typename?: "Mutation" } & {
  setCreatedCompaniesOnUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type AddCreatedProjectsToUserMutationVariables = Exact<{
  input: AddCreatedProjectsToUserInput;
  addCreatedProjectsToUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addCreatedProjectsToUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedProjectsToUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addCreatedProjectsToUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addCreatedProjectsToUserContactsPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserContactsFilter?: Maybe<ContactFilter>;
  addCreatedProjectsToUserContactsSorting?: Maybe<Array<ContactSort>>;
  addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedProjectsToUserProjectChangesPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserProjectChangesFilter?: Maybe<ContactFilter>;
  addCreatedProjectsToUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedProjectsToUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addCreatedProjectsToUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  addCreatedProjectsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addCreatedProjectsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addCreatedProjectsToUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserCreatedContractsFilter?: Maybe<ContractFilter>;
  addCreatedProjectsToUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  addCreatedProjectsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addCreatedProjectsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addCreatedProjectsToUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addCreatedProjectsToUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addCreatedProjectsToUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addCreatedProjectsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addCreatedProjectsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddCreatedProjectsToUserMutation = { __typename?: "Mutation" } & {
  addCreatedProjectsToUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type SetCreatedProjectsOnUserMutationVariables = Exact<{
  input: SetCreatedProjectsOnUserInput;
  setCreatedProjectsOnUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedProjectsOnUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedProjectsOnUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedProjectsOnUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedProjectsOnUserContactsPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserContactsFilter?: Maybe<ContactFilter>;
  setCreatedProjectsOnUserContactsSorting?: Maybe<Array<ContactSort>>;
  setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedProjectsOnUserProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedProjectsOnUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedProjectsOnUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedProjectsOnUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  setCreatedProjectsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedProjectsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedProjectsOnUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedProjectsOnUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  setCreatedProjectsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedProjectsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedProjectsOnUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedProjectsOnUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedProjectsOnUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedProjectsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedProjectsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedProjectsOnUserMutation = { __typename?: "Mutation" } & {
  setCreatedProjectsOnUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type AddContactsToUserMutationVariables = Exact<{
  input: AddContactsToUserInput;
  addContactsToUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addContactsToUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addContactsToUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addContactsToUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addContactsToUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addContactsToUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addContactsToUserContactsPaging?: Maybe<OffsetPaging>;
  addContactsToUserContactsFilter?: Maybe<ContactFilter>;
  addContactsToUserContactsSorting?: Maybe<Array<ContactSort>>;
  addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addContactsToUserProjectChangesPaging?: Maybe<OffsetPaging>;
  addContactsToUserProjectChangesFilter?: Maybe<ContactFilter>;
  addContactsToUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addContactsToUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addContactsToUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addContactsToUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  addContactsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addContactsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addContactsToUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  addContactsToUserCreatedContractsFilter?: Maybe<ContractFilter>;
  addContactsToUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  addContactsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addContactsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addContactsToUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addContactsToUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addContactsToUserCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  addContactsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addContactsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddContactsToUserMutation = { __typename?: "Mutation" } & {
  addContactsToUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type SetContactsOnUserMutationVariables = Exact<{
  input: SetContactsOnUserInput;
  setContactsOnUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactsOnUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactsOnUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactsOnUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactsOnUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactsOnUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactsOnUserContactsPaging?: Maybe<OffsetPaging>;
  setContactsOnUserContactsFilter?: Maybe<ContactFilter>;
  setContactsOnUserContactsSorting?: Maybe<Array<ContactSort>>;
  setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactsOnUserProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactsOnUserProjectChangesFilter?: Maybe<ContactFilter>;
  setContactsOnUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactsOnUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactsOnUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactsOnUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  setContactsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactsOnUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactsOnUserCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactsOnUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  setContactsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactsOnUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactsOnUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactsOnUserCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  setContactsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetContactsOnUserMutation = { __typename?: "Mutation" } & {
  setContactsOnUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type AddProjectChangesToUserMutationVariables = Exact<{
  input: AddProjectChangesToUserInput;
  addProjectChangesToUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addProjectChangesToUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addProjectChangesToUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addProjectChangesToUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addProjectChangesToUserContactsPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserContactsFilter?: Maybe<ContactFilter>;
  addProjectChangesToUserContactsSorting?: Maybe<Array<ContactSort>>;
  addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addProjectChangesToUserProjectChangesPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserProjectChangesFilter?: Maybe<ContactFilter>;
  addProjectChangesToUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addProjectChangesToUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addProjectChangesToUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  addProjectChangesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addProjectChangesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addProjectChangesToUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserCreatedContractsFilter?: Maybe<ContractFilter>;
  addProjectChangesToUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  addProjectChangesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addProjectChangesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addProjectChangesToUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addProjectChangesToUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addProjectChangesToUserCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  addProjectChangesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addProjectChangesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddProjectChangesToUserMutation = { __typename?: "Mutation" } & {
  addProjectChangesToUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type SetProjectChangesOnUserMutationVariables = Exact<{
  input: SetProjectChangesOnUserInput;
  setProjectChangesOnUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setProjectChangesOnUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangesOnUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setProjectChangesOnUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setProjectChangesOnUserContactsPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserContactsFilter?: Maybe<ContactFilter>;
  setProjectChangesOnUserContactsSorting?: Maybe<Array<ContactSort>>;
  setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangesOnUserProjectChangesPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserProjectChangesFilter?: Maybe<ContactFilter>;
  setProjectChangesOnUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangesOnUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setProjectChangesOnUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  setProjectChangesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setProjectChangesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setProjectChangesOnUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserCreatedContractsFilter?: Maybe<ContractFilter>;
  setProjectChangesOnUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  setProjectChangesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setProjectChangesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setProjectChangesOnUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setProjectChangesOnUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setProjectChangesOnUserCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  setProjectChangesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setProjectChangesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetProjectChangesOnUserMutation = { __typename?: "Mutation" } & {
  setProjectChangesOnUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type AddCreatedWorkersToUserMutationVariables = Exact<{
  input: AddCreatedWorkersToUserInput;
  addCreatedWorkersToUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addCreatedWorkersToUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedWorkersToUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addCreatedWorkersToUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addCreatedWorkersToUserContactsPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserContactsFilter?: Maybe<ContactFilter>;
  addCreatedWorkersToUserContactsSorting?: Maybe<Array<ContactSort>>;
  addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedWorkersToUserProjectChangesPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserProjectChangesFilter?: Maybe<ContactFilter>;
  addCreatedWorkersToUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedWorkersToUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addCreatedWorkersToUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  addCreatedWorkersToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addCreatedWorkersToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addCreatedWorkersToUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserCreatedContractsFilter?: Maybe<ContractFilter>;
  addCreatedWorkersToUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  addCreatedWorkersToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addCreatedWorkersToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addCreatedWorkersToUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addCreatedWorkersToUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addCreatedWorkersToUserCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  addCreatedWorkersToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addCreatedWorkersToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddCreatedWorkersToUserMutation = { __typename?: "Mutation" } & {
  addCreatedWorkersToUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type SetCreatedWorkersOnUserMutationVariables = Exact<{
  input: SetCreatedWorkersOnUserInput;
  setCreatedWorkersOnUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedWorkersOnUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedWorkersOnUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedWorkersOnUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedWorkersOnUserContactsPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserContactsFilter?: Maybe<ContactFilter>;
  setCreatedWorkersOnUserContactsSorting?: Maybe<Array<ContactSort>>;
  setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedWorkersOnUserProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedWorkersOnUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedWorkersOnUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedWorkersOnUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  setCreatedWorkersOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedWorkersOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedWorkersOnUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedWorkersOnUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  setCreatedWorkersOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedWorkersOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedWorkersOnUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedWorkersOnUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedWorkersOnUserCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  setCreatedWorkersOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedWorkersOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedWorkersOnUserMutation = { __typename?: "Mutation" } & {
  setCreatedWorkersOnUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type AddCreatedContractsToUserMutationVariables = Exact<{
  input: AddCreatedContractsToUserInput;
  addCreatedContractsToUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addCreatedContractsToUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedContractsToUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addCreatedContractsToUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addCreatedContractsToUserContactsPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserContactsFilter?: Maybe<ContactFilter>;
  addCreatedContractsToUserContactsSorting?: Maybe<Array<ContactSort>>;
  addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedContractsToUserProjectChangesPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserProjectChangesFilter?: Maybe<ContactFilter>;
  addCreatedContractsToUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedContractsToUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addCreatedContractsToUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  addCreatedContractsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addCreatedContractsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addCreatedContractsToUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserCreatedContractsFilter?: Maybe<ContractFilter>;
  addCreatedContractsToUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  addCreatedContractsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addCreatedContractsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addCreatedContractsToUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addCreatedContractsToUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addCreatedContractsToUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addCreatedContractsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addCreatedContractsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddCreatedContractsToUserMutation = { __typename?: "Mutation" } & {
  addCreatedContractsToUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type SetCreatedContractsOnUserMutationVariables = Exact<{
  input: SetCreatedContractsOnUserInput;
  setCreatedContractsOnUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedContractsOnUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedContractsOnUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedContractsOnUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedContractsOnUserContactsPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserContactsFilter?: Maybe<ContactFilter>;
  setCreatedContractsOnUserContactsSorting?: Maybe<Array<ContactSort>>;
  setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedContractsOnUserProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedContractsOnUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedContractsOnUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedContractsOnUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  setCreatedContractsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedContractsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedContractsOnUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedContractsOnUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  setCreatedContractsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedContractsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedContractsOnUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedContractsOnUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedContractsOnUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedContractsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedContractsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedContractsOnUserMutation = { __typename?: "Mutation" } & {
  setCreatedContractsOnUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type AddCreatedMiddlemansToUserMutationVariables = Exact<{
  input: AddCreatedMiddlemansToUserInput;
  addCreatedMiddlemansToUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addCreatedMiddlemansToUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedMiddlemansToUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addCreatedMiddlemansToUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addCreatedMiddlemansToUserContactsPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserContactsFilter?: Maybe<ContactFilter>;
  addCreatedMiddlemansToUserContactsSorting?: Maybe<Array<ContactSort>>;
  addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedMiddlemansToUserProjectChangesPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserProjectChangesFilter?: Maybe<ContactFilter>;
  addCreatedMiddlemansToUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addCreatedMiddlemansToUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addCreatedMiddlemansToUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  addCreatedMiddlemansToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addCreatedMiddlemansToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addCreatedMiddlemansToUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserCreatedContractsFilter?: Maybe<ContractFilter>;
  addCreatedMiddlemansToUserCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addCreatedMiddlemansToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addCreatedMiddlemansToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addCreatedMiddlemansToUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addCreatedMiddlemansToUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addCreatedMiddlemansToUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addCreatedMiddlemansToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addCreatedMiddlemansToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddCreatedMiddlemansToUserMutation = { __typename?: "Mutation" } & {
  addCreatedMiddlemansToUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type SetCreatedMiddlemansOnUserMutationVariables = Exact<{
  input: SetCreatedMiddlemansOnUserInput;
  setCreatedMiddlemansOnUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedMiddlemansOnUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedMiddlemansOnUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedMiddlemansOnUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedMiddlemansOnUserContactsPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserContactsFilter?: Maybe<ContactFilter>;
  setCreatedMiddlemansOnUserContactsSorting?: Maybe<Array<ContactSort>>;
  setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedMiddlemansOnUserProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedMiddlemansOnUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedMiddlemansOnUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedMiddlemansOnUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  setCreatedMiddlemansOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedMiddlemansOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedMiddlemansOnUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedMiddlemansOnUserCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedMiddlemansOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedMiddlemansOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedMiddlemansOnUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedMiddlemansOnUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedMiddlemansOnUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedMiddlemansOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedMiddlemansOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedMiddlemansOnUserMutation = { __typename?: "Mutation" } & {
  setCreatedMiddlemansOnUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type RemoveCreatedCompaniesFromUserMutationVariables = Exact<{
  input: RemoveCreatedCompaniesFromUserInput;
  removeCreatedCompaniesFromUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedCompaniesFromUserCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedCompaniesFromUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedCompaniesFromUserCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedCompaniesFromUserContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserContactsFilter?: Maybe<ContactFilter>;
  removeCreatedCompaniesFromUserContactsSorting?: Maybe<Array<ContactSort>>;
  removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedCompaniesFromUserProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedCompaniesFromUserProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedCompaniesFromUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedCompaniesFromUserCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedCompaniesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedCompaniesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedCompaniesFromUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedCompaniesFromUserCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedCompaniesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedCompaniesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedCompaniesFromUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedCompaniesFromUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedCompaniesFromUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedCompaniesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedCompaniesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedCompaniesFromUserMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedCompaniesFromUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type RemoveCreatedProjectsFromUserMutationVariables = Exact<{
  input: RemoveCreatedProjectsFromUserInput;
  removeCreatedProjectsFromUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedProjectsFromUserCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedProjectsFromUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedProjectsFromUserCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedProjectsFromUserContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserContactsFilter?: Maybe<ContactFilter>;
  removeCreatedProjectsFromUserContactsSorting?: Maybe<Array<ContactSort>>;
  removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedProjectsFromUserProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedProjectsFromUserProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedProjectsFromUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedProjectsFromUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  removeCreatedProjectsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedProjectsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedProjectsFromUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedProjectsFromUserCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedProjectsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedProjectsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedProjectsFromUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedProjectsFromUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedProjectsFromUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedProjectsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedProjectsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedProjectsFromUserMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedProjectsFromUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type RemoveContactsFromUserMutationVariables = Exact<{
  input: RemoveContactsFromUserInput;
  removeContactsFromUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactsFromUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactsFromUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactsFromUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactsFromUserContactsPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserContactsFilter?: Maybe<ContactFilter>;
  removeContactsFromUserContactsSorting?: Maybe<Array<ContactSort>>;
  removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactsFromUserProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactsFromUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactsFromUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactsFromUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  removeContactsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactsFromUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactsFromUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  removeContactsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactsFromUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactsFromUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactsFromUserCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  removeContactsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveContactsFromUserMutation = { __typename?: "Mutation" } & {
  removeContactsFromUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type RemoveProjectChangesFromUserMutationVariables = Exact<{
  input: RemoveProjectChangesFromUserInput;
  removeProjectChangesFromUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeProjectChangesFromUserCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangesFromUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeProjectChangesFromUserCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeProjectChangesFromUserContactsPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserContactsFilter?: Maybe<ContactFilter>;
  removeProjectChangesFromUserContactsSorting?: Maybe<Array<ContactSort>>;
  removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangesFromUserProjectChangesPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserProjectChangesFilter?: Maybe<ContactFilter>;
  removeProjectChangesFromUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangesFromUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeProjectChangesFromUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  removeProjectChangesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeProjectChangesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeProjectChangesFromUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserCreatedContractsFilter?: Maybe<ContractFilter>;
  removeProjectChangesFromUserCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeProjectChangesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeProjectChangesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeProjectChangesFromUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeProjectChangesFromUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeProjectChangesFromUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeProjectChangesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeProjectChangesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveProjectChangesFromUserMutation = {
  __typename?: "Mutation";
} & {
  removeProjectChangesFromUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type RemoveCreatedWorkersFromUserMutationVariables = Exact<{
  input: RemoveCreatedWorkersFromUserInput;
  removeCreatedWorkersFromUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedWorkersFromUserCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedWorkersFromUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedWorkersFromUserCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedWorkersFromUserContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserContactsFilter?: Maybe<ContactFilter>;
  removeCreatedWorkersFromUserContactsSorting?: Maybe<Array<ContactSort>>;
  removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedWorkersFromUserProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedWorkersFromUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedWorkersFromUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedWorkersFromUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  removeCreatedWorkersFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedWorkersFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedWorkersFromUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedWorkersFromUserCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedWorkersFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedWorkersFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedWorkersFromUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedWorkersFromUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedWorkersFromUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedWorkersFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedWorkersFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedWorkersFromUserMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedWorkersFromUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type RemoveCreatedContractsFromUserMutationVariables = Exact<{
  input: RemoveCreatedContractsFromUserInput;
  removeCreatedContractsFromUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedContractsFromUserCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedContractsFromUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedContractsFromUserCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedContractsFromUserContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserContactsFilter?: Maybe<ContactFilter>;
  removeCreatedContractsFromUserContactsSorting?: Maybe<Array<ContactSort>>;
  removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedContractsFromUserProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedContractsFromUserProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedContractsFromUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedContractsFromUserCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedContractsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedContractsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedContractsFromUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedContractsFromUserCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedContractsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedContractsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedContractsFromUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedContractsFromUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedContractsFromUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedContractsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedContractsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedContractsFromUserMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedContractsFromUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type RemoveCreatedMiddlemansFromUserMutationVariables = Exact<{
  input: RemoveCreatedMiddlemansFromUserInput;
  removeCreatedMiddlemansFromUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedMiddlemansFromUserCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedMiddlemansFromUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedMiddlemansFromUserCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedMiddlemansFromUserContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserContactsFilter?: Maybe<ContactFilter>;
  removeCreatedMiddlemansFromUserContactsSorting?: Maybe<Array<ContactSort>>;
  removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedMiddlemansFromUserProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedMiddlemansFromUserProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedMiddlemansFromUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedMiddlemansFromUserCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedMiddlemansFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedMiddlemansFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedMiddlemansFromUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedMiddlemansFromUserCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedMiddlemansFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedMiddlemansFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedMiddlemansFromUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedMiddlemansFromUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedMiddlemansFromUserCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedMiddlemansFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedMiddlemansFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedMiddlemansFromUserMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedMiddlemansFromUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type CreateOneUserMutationVariables = Exact<{
  input: CreateOneUserInput;
  createOneUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneUserContactsPaging?: Maybe<OffsetPaging>;
  createOneUserContactsFilter?: Maybe<ContactFilter>;
  createOneUserContactsSorting?: Maybe<Array<ContactSort>>;
  createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneUserProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneUserProjectChangesFilter?: Maybe<ContactFilter>;
  createOneUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  createOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneUserCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  createOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneUserCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  createOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateOneUserMutation = { __typename?: "Mutation" } & {
  createOneUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type CreateManyUsersMutationVariables = Exact<{
  input: CreateManyUsersInput;
  createManyUsersCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyUsersCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyUsersCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyUsersCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyUsersCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyUsersCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyUsersContactsPaging?: Maybe<OffsetPaging>;
  createManyUsersContactsFilter?: Maybe<ContactFilter>;
  createManyUsersContactsSorting?: Maybe<Array<ContactSort>>;
  createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyUsersProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyUsersProjectChangesFilter?: Maybe<ContactFilter>;
  createManyUsersProjectChangesSorting?: Maybe<Array<ContactSort>>;
  createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyUsersCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyUsersCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyUsersCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  createManyUsersCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyUsersCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyUsersCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyUsersCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyUsersCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  createManyUsersCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyUsersCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyUsersCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyUsersCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyUsersCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  createManyUsersCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyUsersCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateManyUsersMutation = { __typename?: "Mutation" } & {
  createManyUsers: Array<
    { __typename?: "User" } & Pick<
      User,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Username"
      | "Email"
      | "Status"
      | "Role"
    > & {
        createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Company" } & Pick<
              Company,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Name"
              | "Webpage"
              | "Address"
              | "Focus"
              | "Language"
            > & {
                contact?: Maybe<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
                histories: { __typename?: "CompanyHistoriesConnection" } & {
                  pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                    OffsetPageInfo,
                    "hasNextPage" | "hasPreviousPage"
                  >;
                  nodes: Array<
                    { __typename?: "Contact" } & Pick<
                      Contact,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "ContactPerson"
                      | "Email"
                      | "Phone"
                      | "Description"
                      | "Interest"
                    >
                  >;
                };
              }
          >;
        };
        createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Project" } & Pick<
              Project,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Name"
              | "Address"
              | "Type"
              | "Language"
              | "Manager"
              | "ManagerContact"
            > & {
                projectChange?: Maybe<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
                histories: { __typename?: "ProjectHistoriesConnection" } & {
                  pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                    OffsetPageInfo,
                    "hasNextPage" | "hasPreviousPage"
                  >;
                  nodes: Array<
                    { __typename?: "ProjectChange" } & Pick<
                      ProjectChange,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Description"
                      | "State"
                    >
                  >;
                };
              }
          >;
        };
        contacts: { __typename?: "UserContactsConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Contact" } & Pick<
              Contact,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "ContactPerson"
              | "Email"
              | "Phone"
              | "Description"
              | "Interest"
            > & {
                company?: Maybe<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                    }
                >;
              }
          >;
        };
        projectChanges: { __typename?: "UserProjectChangesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Contact" } & Pick<
              Contact,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "ContactPerson"
              | "Email"
              | "Phone"
              | "Description"
              | "Interest"
            > & {
                company?: Maybe<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                    }
                >;
              }
          >;
        };
        createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Worker" } & Pick<
              Worker,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Name"
              | "Phone"
              | "Email"
              | "Note"
              | "Profession"
              | "Type"
            > & {
                attachments: Array<
                  { __typename?: "Attachment" } & Pick<
                    Attachment,
                    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                  >
                >;
              }
          >;
        };
        createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Contract" } & Pick<
              Contract,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Name"
              | "Description"
              | "Type"
              | "Year"
            > & {
                attachments: Array<
                  { __typename?: "ContractAttachment" } & Pick<
                    ContractAttachment,
                    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                  >
                >;
              }
          >;
        };
        createdMiddlemans: {
          __typename?: "UserCreatedMiddlemansConnection";
        } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Middleman" } & Pick<
              Middleman,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Name"
              | "Phone"
              | "Email"
              | "Note"
            > & {
                attachments: Array<
                  { __typename?: "MiddlemanAttachment" } & Pick<
                    MiddlemanAttachment,
                    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                  >
                >;
              }
          >;
        };
      }
  >;
};

export type UpdateOneUserMutationVariables = Exact<{
  input: UpdateOneUserInput;
  updateOneUserCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneUserCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneUserCreatedCompaniesSorting?: Maybe<Array<CompanySort>>;
  updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneUserCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneUserCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneUserCreatedProjectsSorting?: Maybe<Array<ProjectSort>>;
  updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneUserContactsPaging?: Maybe<OffsetPaging>;
  updateOneUserContactsFilter?: Maybe<ContactFilter>;
  updateOneUserContactsSorting?: Maybe<Array<ContactSort>>;
  updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneUserProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneUserProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneUserProjectChangesSorting?: Maybe<Array<ContactSort>>;
  updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneUserCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneUserCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneUserCreatedWorkersSorting?: Maybe<Array<WorkerSort>>;
  updateOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneUserCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneUserCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneUserCreatedContractsSorting?: Maybe<Array<ContractSort>>;
  updateOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneUserCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneUserCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneUserCreatedMiddlemansSorting?: Maybe<Array<MiddlemanSort>>;
  updateOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type UpdateOneUserMutation = { __typename?: "Mutation" } & {
  updateOneUser: { __typename?: "User" } & Pick<
    User,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  > & {
      createdCompanies: { __typename?: "UserCreatedCompaniesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              contact?: Maybe<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
            }
        >;
      };
      createdProjects: { __typename?: "UserCreatedProjectsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              projectChange?: Maybe<
                { __typename?: "ProjectChange" } & Pick<
                  ProjectChange,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Description"
                  | "State"
                >
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "ProjectChange" } & Pick<
                    ProjectChange,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Description"
                    | "State"
                  >
                >;
              };
            }
        >;
      };
      contacts: { __typename?: "UserContactsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      projectChanges: { __typename?: "UserProjectChangesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            }
        >;
      };
      createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Worker" } & Pick<
            Worker,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
            | "Profession"
            | "Type"
          > & {
              attachments: Array<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdContracts: { __typename?: "UserCreatedContractsConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contract" } & Pick<
            Contract,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Description"
            | "Type"
            | "Year"
          > & {
              attachments: Array<
                { __typename?: "ContractAttachment" } & Pick<
                  ContractAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
      createdMiddlemans: { __typename?: "UserCreatedMiddlemansConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Middleman" } & Pick<
            Middleman,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Phone"
            | "Email"
            | "Note"
          > & {
              attachments: Array<
                { __typename?: "MiddlemanAttachment" } & Pick<
                  MiddlemanAttachment,
                  "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                >
              >;
            }
        >;
      };
    };
};

export type UpdateManyUsersMutationVariables = Exact<{
  input: UpdateManyUsersInput;
}>;

export type UpdateManyUsersMutation = { __typename?: "Mutation" } & {
  updateManyUsers: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneUserMutationVariables = Exact<{
  input: DeleteOneUserInput;
}>;

export type DeleteOneUserMutation = { __typename?: "Mutation" } & {
  deleteOneUser: { __typename?: "UserDeleteResponse" } & Pick<
    UserDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Username"
    | "Email"
    | "Status"
    | "Role"
  >;
};

export type DeleteManyUsersMutationVariables = Exact<{
  input: DeleteManyUsersInput;
}>;

export type DeleteManyUsersMutation = { __typename?: "Mutation" } & {
  deleteManyUsers: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type RestoreOneCompanyMutationVariables = Exact<{
  input: Scalars["String"];
  restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  restoreOneCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  restoreOneCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  restoreOneCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  restoreOneCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  restoreOneCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  restoreOneCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  restoreOneCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  restoreOneCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  restoreOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  restoreOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  restoreOneCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  restoreOneCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  restoreOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  restoreOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  restoreOneCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  restoreOneCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  restoreOneCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  restoreOneCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  restoreOneCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  restoreOneCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  restoreOneCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  restoreOneCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  restoreOneCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  restoreOneCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  restoreOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  restoreOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  restoreOneCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  restoreOneCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  restoreOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  restoreOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  restoreOneCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  restoreOneCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  restoreOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  restoreOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  restoreOneCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyHistoriesFilter?: Maybe<ContactFilter>;
  restoreOneCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RestoreOneCompanyMutation = { __typename?: "Mutation" } & {
  restoreOneCompany: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type SetContactOnCompanyMutationVariables = Exact<{
  input: SetContactOnCompanyInput;
  setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetContactOnCompanyMutation = { __typename?: "Mutation" } & {
  setContactOnCompany: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type SetCreatedByOnCompanyMutationVariables = Exact<{
  input: SetCreatedByOnCompanyInput;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedByOnCompanyMutation = { __typename?: "Mutation" } & {
  setCreatedByOnCompany: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type AddHistoriesToCompanyMutationVariables = Exact<{
  input: AddHistoriesToCompanyInput;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddHistoriesToCompanyMutation = { __typename?: "Mutation" } & {
  addHistoriesToCompany: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type SetHistoriesOnCompanyMutationVariables = Exact<{
  input: SetHistoriesOnCompanyInput;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetHistoriesOnCompanyMutation = { __typename?: "Mutation" } & {
  setHistoriesOnCompany: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type RemoveContactFromCompanyMutationVariables = Exact<{
  input: RemoveContactFromCompanyInput;
  removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveContactFromCompanyMutation = { __typename?: "Mutation" } & {
  removeContactFromCompany: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type RemoveCreatedByFromCompanyMutationVariables = Exact<{
  input: RemoveCreatedByFromCompanyInput;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedByFromCompanyMutation = { __typename?: "Mutation" } & {
  removeCreatedByFromCompany: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type RemoveHistoriesFromCompanyMutationVariables = Exact<{
  input: RemoveHistoriesFromCompanyInput;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveHistoriesFromCompanyMutation = { __typename?: "Mutation" } & {
  removeHistoriesFromCompany: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type CreateOneCompanyMutationVariables = Exact<{
  input: CreateOneCompanyInput;
  createOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createOneCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createOneCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createOneCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createOneCompanyCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  createOneCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateOneCompanyMutation = { __typename?: "Mutation" } & {
  createOneCompany: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type CreateManyCompaniesMutationVariables = Exact<{
  input: CreateManyCompaniesInput;
  createManyCompaniesContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyCompaniesContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyCompaniesContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyCompaniesContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyCompaniesContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyCompaniesContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyCompaniesContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createManyCompaniesContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createManyCompaniesContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompaniesContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyCompaniesContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyCompaniesContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompaniesContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyCompaniesContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyCompaniesContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyCompaniesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyCompaniesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyCompaniesContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyCompaniesContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyCompaniesContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyCompaniesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyCompaniesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyCompaniesCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyCompaniesCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyCompaniesCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyCompaniesCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyCompaniesCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyCompaniesCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyCompaniesCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createManyCompaniesCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createManyCompaniesCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompaniesCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyCompaniesCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyCompaniesCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompaniesCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyCompaniesCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyCompaniesCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyCompaniesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyCompaniesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyCompaniesCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyCompaniesCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyCompaniesCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyCompaniesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyCompaniesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyCompaniesCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyCompaniesCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyCompaniesCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyCompaniesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyCompaniesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyCompaniesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompaniesHistoriesFilter?: Maybe<ContactFilter>;
  createManyCompaniesHistoriesSorting?: Maybe<Array<ContactSort>>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateManyCompaniesMutation = { __typename?: "Mutation" } & {
  createManyCompanies: Array<
    { __typename?: "Company" } & Pick<
      Company,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Webpage"
      | "Address"
      | "Focus"
      | "Language"
    > & {
        contact?: Maybe<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  >
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        histories: { __typename?: "CompanyHistoriesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "Contact" } & Pick<
              Contact,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "ContactPerson"
              | "Email"
              | "Phone"
              | "Description"
              | "Interest"
            > & {
                contactedBy?: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Username"
                    | "Email"
                    | "Status"
                    | "Role"
                  > & {
                      createdCompanies: {
                        __typename?: "UserCreatedCompaniesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                      createdProjects: {
                        __typename?: "UserCreatedProjectsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Project" } & Pick<
                            Project,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Address"
                            | "Type"
                            | "Language"
                            | "Manager"
                            | "ManagerContact"
                          > & {
                              projectChange?: Maybe<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                              histories: {
                                __typename?: "ProjectHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      contacts: { __typename?: "UserContactsConnection" } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                      projectChanges: {
                        __typename?: "UserProjectChangesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                      createdWorkers: {
                        __typename?: "UserCreatedWorkersConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Worker" } & Pick<
                            Worker,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                            | "Profession"
                            | "Type"
                          > & {
                              attachments: Array<
                                { __typename?: "Attachment" } & Pick<
                                  Attachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdContracts: {
                        __typename?: "UserCreatedContractsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contract" } & Pick<
                            Contract,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Description"
                            | "Type"
                            | "Year"
                          > & {
                              attachments: Array<
                                { __typename?: "ContractAttachment" } & Pick<
                                  ContractAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdMiddlemans: {
                        __typename?: "UserCreatedMiddlemansConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Middleman" } & Pick<
                            Middleman,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                          > & {
                              attachments: Array<
                                { __typename?: "MiddlemanAttachment" } & Pick<
                                  MiddlemanAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type UpdateOneCompanyMutationVariables = Exact<{
  input: UpdateOneCompanyInput;
  updateOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  updateOneCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  updateOneCompanyCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  updateOneCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type UpdateOneCompanyMutation = { __typename?: "Mutation" } & {
  updateOneCompany: { __typename?: "Company" } & Pick<
    Company,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                >
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type UpdateManyCompaniesMutationVariables = Exact<{
  input: UpdateManyCompaniesInput;
}>;

export type UpdateManyCompaniesMutation = { __typename?: "Mutation" } & {
  updateManyCompanies: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneCompanyMutationVariables = Exact<{
  input: DeleteOneCompanyInput;
}>;

export type DeleteOneCompanyMutation = { __typename?: "Mutation" } & {
  deleteOneCompany: { __typename?: "CompanyDeleteResponse" } & Pick<
    CompanyDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  >;
};

export type DeleteManyCompaniesMutationVariables = Exact<{
  input: DeleteManyCompaniesInput;
}>;

export type DeleteManyCompaniesMutation = { __typename?: "Mutation" } & {
  deleteManyCompanies: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type SetContactOnCompanyPilverMutationVariables = Exact<{
  input: SetContactOnCompanyPilverInput;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  setContactOnCompanyPilverHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetContactOnCompanyPilverMutation = { __typename?: "Mutation" } & {
  setContactOnCompanyPilver: { __typename?: "CompanyPilver" } & Pick<
    CompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type SetCreatedByOnCompanyPilverMutationVariables = Exact<{
  input: SetCreatedByOnCompanyPilverInput;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  setCreatedByOnCompanyPilverHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedByOnCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  setCreatedByOnCompanyPilver: { __typename?: "CompanyPilver" } & Pick<
    CompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type AddHistoriesToCompanyPilverMutationVariables = Exact<{
  input: AddHistoriesToCompanyPilverInput;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  addHistoriesToCompanyPilverHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddHistoriesToCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  addHistoriesToCompanyPilver: { __typename?: "CompanyPilver" } & Pick<
    CompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type SetHistoriesOnCompanyPilverMutationVariables = Exact<{
  input: SetHistoriesOnCompanyPilverInput;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  setHistoriesOnCompanyPilverHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetHistoriesOnCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  setHistoriesOnCompanyPilver: { __typename?: "CompanyPilver" } & Pick<
    CompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type RemoveContactFromCompanyPilverMutationVariables = Exact<{
  input: RemoveContactFromCompanyPilverInput;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeContactFromCompanyPilverHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveContactFromCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  removeContactFromCompanyPilver: { __typename?: "CompanyPilver" } & Pick<
    CompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type RemoveCreatedByFromCompanyPilverMutationVariables = Exact<{
  input: RemoveCreatedByFromCompanyPilverInput;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeCreatedByFromCompanyPilverHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedByFromCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedByFromCompanyPilver: { __typename?: "CompanyPilver" } & Pick<
    CompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type RemoveHistoriesFromCompanyPilverMutationVariables = Exact<{
  input: RemoveHistoriesFromCompanyPilverInput;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeHistoriesFromCompanyPilverHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveHistoriesFromCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  removeHistoriesFromCompanyPilver: { __typename?: "CompanyPilver" } & Pick<
    CompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type CreateOneCompanyPilverMutationVariables = Exact<{
  input: CreateOneCompanyPilverInput;
  createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  createOneCompanyPilverHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateOneCompanyPilverMutation = { __typename?: "Mutation" } & {
  createOneCompanyPilver: { __typename?: "CompanyPilver" } & Pick<
    CompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type CreateManyCompanyPilversMutationVariables = Exact<{
  input: CreateManyCompanyPilversInput;
  createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyCompanyPilversCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyCompanyPilversCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyCompanyPilversCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyCompanyPilversCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyCompanyPilversCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyCompanyPilversCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyCompanyPilversCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyCompanyPilversCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyCompanyPilversCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyCompanyPilversCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyCompanyPilversCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyCompanyPilversHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesFilter?: Maybe<ContactPilverFilter>;
  createManyCompanyPilversHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateManyCompanyPilversMutation = { __typename?: "Mutation" } & {
  createManyCompanyPilvers: Array<
    { __typename?: "CompanyPilver" } & Pick<
      CompanyPilver,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Webpage"
      | "Address"
      | "Focus"
      | "Language"
    > & {
        contact?: Maybe<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "ContactPilver" } & Pick<
              ContactPilver,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "ContactPerson"
              | "Email"
              | "Phone"
              | "Description"
              | "Interest"
            > & {
                contactedBy?: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Username"
                    | "Email"
                    | "Status"
                    | "Role"
                  > & {
                      createdCompanies: {
                        __typename?: "UserCreatedCompaniesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Company" } & Pick<
                            Company,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Webpage"
                            | "Address"
                            | "Focus"
                            | "Language"
                          > & {
                              contact?: Maybe<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                              histories: {
                                __typename?: "CompanyHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      createdProjects: {
                        __typename?: "UserCreatedProjectsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Project" } & Pick<
                            Project,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Address"
                            | "Type"
                            | "Language"
                            | "Manager"
                            | "ManagerContact"
                          > & {
                              projectChange?: Maybe<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                              histories: {
                                __typename?: "ProjectHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      contacts: { __typename?: "UserContactsConnection" } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          > & {
                              company?: Maybe<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                      projectChanges: {
                        __typename?: "UserProjectChangesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          > & {
                              company?: Maybe<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                      createdWorkers: {
                        __typename?: "UserCreatedWorkersConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Worker" } & Pick<
                            Worker,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                            | "Profession"
                            | "Type"
                          > & {
                              attachments: Array<
                                { __typename?: "Attachment" } & Pick<
                                  Attachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdContracts: {
                        __typename?: "UserCreatedContractsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contract" } & Pick<
                            Contract,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Description"
                            | "Type"
                            | "Year"
                          > & {
                              attachments: Array<
                                { __typename?: "ContractAttachment" } & Pick<
                                  ContractAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdMiddlemans: {
                        __typename?: "UserCreatedMiddlemansConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Middleman" } & Pick<
                            Middleman,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                          > & {
                              attachments: Array<
                                { __typename?: "MiddlemanAttachment" } & Pick<
                                  MiddlemanAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type UpdateOneCompanyPilverMutationVariables = Exact<{
  input: UpdateOneCompanyPilverInput;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  updateOneCompanyPilverHistoriesSorting?: Maybe<Array<ContactPilverSort>>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type UpdateOneCompanyPilverMutation = { __typename?: "Mutation" } & {
  updateOneCompanyPilver: { __typename?: "CompanyPilver" } & Pick<
    CompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type UpdateManyCompanyPilversMutationVariables = Exact<{
  input: UpdateManyCompanyPilversInput;
}>;

export type UpdateManyCompanyPilversMutation = { __typename?: "Mutation" } & {
  updateManyCompanyPilvers: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneCompanyPilverMutationVariables = Exact<{
  input: DeleteOneCompanyPilverInput;
}>;

export type DeleteOneCompanyPilverMutation = { __typename?: "Mutation" } & {
  deleteOneCompanyPilver: { __typename?: "CompanyPilverDeleteResponse" } & Pick<
    CompanyPilverDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  >;
};

export type DeleteManyCompanyPilversMutationVariables = Exact<{
  input: DeleteManyCompanyPilversInput;
}>;

export type DeleteManyCompanyPilversMutation = { __typename?: "Mutation" } & {
  deleteManyCompanyPilvers: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type SetCompanyOnContactMutationVariables = Exact<{
  input: SetCompanyOnContactInput;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCompanyOnContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactCompanyCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  setCompanyOnContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCompanyOnContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCompanyOnContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCompanyOnContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setCompanyOnContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCompanyOnContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCompanyOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCompanyOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCompanyOnContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCompanyOnContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCompanyOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCompanyOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCompanyOnContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCompanyOnContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCompanyOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCompanyOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCompanyOnContactMutation = { __typename?: "Mutation" } & {
  setCompanyOnContact: { __typename?: "Contact" } & Pick<
    Contact,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "Company" } & Pick<
          Company,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetContactedByOnContactMutationVariables = Exact<{
  input: SetContactedByOnContactInput;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactedByOnContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactedByOnContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactedByOnContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactedByOnContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setContactedByOnContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactedByOnContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactedByOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactedByOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactedByOnContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactedByOnContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactedByOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactedByOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactedByOnContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactedByOnContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactedByOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactedByOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetContactedByOnContactMutation = { __typename?: "Mutation" } & {
  setContactedByOnContact: { __typename?: "Contact" } & Pick<
    Contact,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "Company" } & Pick<
          Company,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveCompanyFromContactMutationVariables = Exact<{
  input: RemoveCompanyFromContactInput;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCompanyFromContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCompanyFromContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCompanyFromContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCompanyFromContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCompanyFromContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCompanyFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCompanyFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCompanyFromContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCompanyFromContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCompanyFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCompanyFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCompanyFromContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCompanyFromContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCompanyFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCompanyFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCompanyFromContactMutation = { __typename?: "Mutation" } & {
  removeCompanyFromContact: { __typename?: "Contact" } & Pick<
    Contact,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "Company" } & Pick<
          Company,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveContactedByFromContactMutationVariables = Exact<{
  input: RemoveContactedByFromContactInput;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactedByFromContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactedByFromContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactedByFromContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactedByFromContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactedByFromContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactedByFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactedByFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactedByFromContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactedByFromContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactedByFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactedByFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactedByFromContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactedByFromContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactedByFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactedByFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveContactedByFromContactMutation = {
  __typename?: "Mutation";
} & {
  removeContactedByFromContact: { __typename?: "Contact" } & Pick<
    Contact,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "Company" } & Pick<
          Company,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type CreateOneContactMutationVariables = Exact<{
  input: CreateOneContactInput;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createOneContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneContactCompanyCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  createOneContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createOneContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createOneContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateOneContactMutation = { __typename?: "Mutation" } & {
  createOneContact: { __typename?: "Contact" } & Pick<
    Contact,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "Company" } & Pick<
          Company,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type CreateManyContactsMutationVariables = Exact<{
  input: CreateManyContactsInput;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyContactsCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createManyContactsCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyContactsCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactsCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyContactsCompanyCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  createManyContactsContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyContactsContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyContactsContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactsContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyContactsContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyContactsContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyContactsContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createManyContactsContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createManyContactsContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactsContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyContactsContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyContactsContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactsContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyContactsContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyContactsContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyContactsContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyContactsContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyContactsContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyContactsContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyContactsContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyContactsContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyContactsContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyContactsContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyContactsContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyContactsContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyContactsContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyContactsContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateManyContactsMutation = { __typename?: "Mutation" } & {
  createManyContacts: Array<
    { __typename?: "Contact" } & Pick<
      Contact,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "ContactPerson"
      | "Email"
      | "Phone"
      | "Description"
      | "Interest"
    > & {
        company?: Maybe<
          { __typename?: "Company" } & Pick<
            Company,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              createdBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
              histories: { __typename?: "CompanyHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
            }
        >;
        contactedBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type UpdateOneContactMutationVariables = Exact<{
  input: UpdateOneContactInput;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContactCompanyCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  updateOneContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  updateOneContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type UpdateOneContactMutation = { __typename?: "Mutation" } & {
  updateOneContact: { __typename?: "Contact" } & Pick<
    Contact,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "Company" } & Pick<
          Company,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type UpdateManyContactsMutationVariables = Exact<{
  input: UpdateManyContactsInput;
}>;

export type UpdateManyContactsMutation = { __typename?: "Mutation" } & {
  updateManyContacts: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneContactMutationVariables = Exact<{
  input: DeleteOneContactInput;
}>;

export type DeleteOneContactMutation = { __typename?: "Mutation" } & {
  deleteOneContact: { __typename?: "ContactDeleteResponse" } & Pick<
    ContactDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  >;
};

export type DeleteManyContactsMutationVariables = Exact<{
  input: DeleteManyContactsInput;
}>;

export type DeleteManyContactsMutation = { __typename?: "Mutation" } & {
  deleteManyContacts: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type SetCompanyOnContactPilverMutationVariables = Exact<{
  input: SetCompanyOnContactPilverInput;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCompanyOnContactPilverCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  setCompanyOnContactPilverCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setCompanyOnContactPilverContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCompanyOnContactPilverContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactPilverContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCompanyOnContactPilverContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCompanyOnContactPilverContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setCompanyOnContactPilverContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactPilverContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactPilverContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCompanyOnContactPilverContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCompanyOnContactPilverContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCompanyOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCompanyOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCompanyOnContactPilverContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCompanyOnContactPilverContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCompanyOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCompanyOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCompanyOnContactPilverMutation = { __typename?: "Mutation" } & {
  setCompanyOnContactPilver: { __typename?: "ContactPilver" } & Pick<
    ContactPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "CompanyPilver" } & Pick<
          CompanyPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetContactedByOnContactPilverMutationVariables = Exact<{
  input: SetContactedByOnContactPilverInput;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactedByOnContactPilverCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  setContactedByOnContactPilverCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setContactedByOnContactPilverContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactedByOnContactPilverContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactPilverContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactedByOnContactPilverContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactedByOnContactPilverContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setContactedByOnContactPilverContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactPilverContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactPilverContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactedByOnContactPilverContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactedByOnContactPilverContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactedByOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactedByOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactedByOnContactPilverContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactedByOnContactPilverContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactedByOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactedByOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetContactedByOnContactPilverMutation = {
  __typename?: "Mutation";
} & {
  setContactedByOnContactPilver: { __typename?: "ContactPilver" } & Pick<
    ContactPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "CompanyPilver" } & Pick<
          CompanyPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveCompanyFromContactPilverMutationVariables = Exact<{
  input: RemoveCompanyFromContactPilverInput;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCompanyFromContactPilverCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeCompanyFromContactPilverCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCompanyFromContactPilverContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactPilverContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactPilverContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCompanyFromContactPilverContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCompanyFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCompanyFromContactPilverContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCompanyFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCompanyFromContactPilverMutation = {
  __typename?: "Mutation";
} & {
  removeCompanyFromContactPilver: { __typename?: "ContactPilver" } & Pick<
    ContactPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "CompanyPilver" } & Pick<
          CompanyPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveContactedByFromContactPilverMutationVariables = Exact<{
  input: RemoveContactedByFromContactPilverInput;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactedByFromContactPilverCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeContactedByFromContactPilverCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactedByFromContactPilverContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactPilverContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactPilverContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactedByFromContactPilverContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactedByFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactedByFromContactPilverContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactedByFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveContactedByFromContactPilverMutation = {
  __typename?: "Mutation";
} & {
  removeContactedByFromContactPilver: { __typename?: "ContactPilver" } & Pick<
    ContactPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "CompanyPilver" } & Pick<
          CompanyPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type CreateOneContactPilverMutationVariables = Exact<{
  input: CreateOneContactPilverInput;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneContactPilverCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  createOneContactPilverCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  createOneContactPilverContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneContactPilverContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactPilverContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneContactPilverContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneContactPilverContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createOneContactPilverContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactPilverContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneContactPilverContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContactPilverContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneContactPilverContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneContactPilverContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneContactPilverContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneContactPilverContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneContactPilverContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneContactPilverContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateOneContactPilverMutation = { __typename?: "Mutation" } & {
  createOneContactPilver: { __typename?: "ContactPilver" } & Pick<
    ContactPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "CompanyPilver" } & Pick<
          CompanyPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type CreateManyContactPilversMutationVariables = Exact<{
  input: CreateManyContactPilversInput;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyContactPilversCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  createManyContactPilversCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  createManyContactPilversContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyContactPilversContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactPilversContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyContactPilversContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyContactPilversContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  createManyContactPilversContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactPilversContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyContactPilversContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContactPilversContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyContactPilversContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyContactPilversContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyContactPilversContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyContactPilversContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyContactPilversContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyContactPilversContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyContactPilversContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyContactPilversContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyContactPilversContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyContactPilversContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyContactPilversContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyContactPilversContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateManyContactPilversMutation = { __typename?: "Mutation" } & {
  createManyContactPilvers: Array<
    { __typename?: "ContactPilver" } & Pick<
      ContactPilver,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "ContactPerson"
      | "Email"
      | "Phone"
      | "Description"
      | "Interest"
    > & {
        company?: Maybe<
          { __typename?: "CompanyPilver" } & Pick<
            CompanyPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Webpage"
            | "Address"
            | "Focus"
            | "Language"
          > & {
              createdBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
              histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
            }
        >;
        contactedBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type UpdateOneContactPilverMutationVariables = Exact<{
  input: UpdateOneContactPilverInput;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneContactPilverCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  updateOneContactPilverCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  updateOneContactPilverContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneContactPilverContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactPilverContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneContactPilverContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneContactPilverContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  updateOneContactPilverContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactPilverContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneContactPilverContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContactPilverContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneContactPilverContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneContactPilverContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneContactPilverContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneContactPilverContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneContactPilverContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneContactPilverContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type UpdateOneContactPilverMutation = { __typename?: "Mutation" } & {
  updateOneContactPilver: { __typename?: "ContactPilver" } & Pick<
    ContactPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  > & {
      company?: Maybe<
        { __typename?: "CompanyPilver" } & Pick<
          CompanyPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Webpage"
          | "Address"
          | "Focus"
          | "Language"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "CompanyPilverHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      contactedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type UpdateManyContactPilversMutationVariables = Exact<{
  input: UpdateManyContactPilversInput;
}>;

export type UpdateManyContactPilversMutation = { __typename?: "Mutation" } & {
  updateManyContactPilvers: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneContactPilverMutationVariables = Exact<{
  input: DeleteOneContactPilverInput;
}>;

export type DeleteOneContactPilverMutation = { __typename?: "Mutation" } & {
  deleteOneContactPilver: { __typename?: "ContactPilverDeleteResponse" } & Pick<
    ContactPilverDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "ContactPerson"
    | "Email"
    | "Phone"
    | "Description"
    | "Interest"
  >;
};

export type DeleteManyContactPilversMutationVariables = Exact<{
  input: DeleteManyContactPilversInput;
}>;

export type DeleteManyContactPilversMutation = { __typename?: "Mutation" } & {
  deleteManyContactPilvers: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type SetProjectChangeOnProjectMutationVariables = Exact<{
  input: SetProjectChangeOnProjectInput;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setProjectChangeOnProjectHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setProjectChangeOnProjectHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetProjectChangeOnProjectMutation = { __typename?: "Mutation" } & {
  setProjectChangeOnProject: { __typename?: "Project" } & Pick<
    Project,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Address"
    | "Type"
    | "Language"
    | "Manager"
    | "ManagerContact"
  > & {
      projectChange?: Maybe<
        { __typename?: "ProjectChange" } & Pick<
          ProjectChange,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Description"
          | "State"
        > & {
            modifiedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "ProjectHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type SetCreatedByOnProjectMutationVariables = Exact<{
  input: SetCreatedByOnProjectInput;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnProjectHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnProjectHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedByOnProjectMutation = { __typename?: "Mutation" } & {
  setCreatedByOnProject: { __typename?: "Project" } & Pick<
    Project,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Address"
    | "Type"
    | "Language"
    | "Manager"
    | "ManagerContact"
  > & {
      projectChange?: Maybe<
        { __typename?: "ProjectChange" } & Pick<
          ProjectChange,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Description"
          | "State"
        > & {
            modifiedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "ProjectHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type AddHistoriesToProjectMutationVariables = Exact<{
  input: AddHistoriesToProjectInput;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToProjectHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToProjectHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddHistoriesToProjectMutation = { __typename?: "Mutation" } & {
  addHistoriesToProject: { __typename?: "Project" } & Pick<
    Project,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Address"
    | "Type"
    | "Language"
    | "Manager"
    | "ManagerContact"
  > & {
      projectChange?: Maybe<
        { __typename?: "ProjectChange" } & Pick<
          ProjectChange,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Description"
          | "State"
        > & {
            modifiedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "ProjectHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type SetHistoriesOnProjectMutationVariables = Exact<{
  input: SetHistoriesOnProjectInput;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnProjectHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnProjectHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetHistoriesOnProjectMutation = { __typename?: "Mutation" } & {
  setHistoriesOnProject: { __typename?: "Project" } & Pick<
    Project,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Address"
    | "Type"
    | "Language"
    | "Manager"
    | "ManagerContact"
  > & {
      projectChange?: Maybe<
        { __typename?: "ProjectChange" } & Pick<
          ProjectChange,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Description"
          | "State"
        > & {
            modifiedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "ProjectHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type RemoveProjectChangeFromProjectMutationVariables = Exact<{
  input: RemoveProjectChangeFromProjectInput;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeProjectChangeFromProjectHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeProjectChangeFromProjectHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveProjectChangeFromProjectMutation = {
  __typename?: "Mutation";
} & {
  removeProjectChangeFromProject: { __typename?: "Project" } & Pick<
    Project,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Address"
    | "Type"
    | "Language"
    | "Manager"
    | "ManagerContact"
  > & {
      projectChange?: Maybe<
        { __typename?: "ProjectChange" } & Pick<
          ProjectChange,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Description"
          | "State"
        > & {
            modifiedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "ProjectHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type RemoveCreatedByFromProjectMutationVariables = Exact<{
  input: RemoveCreatedByFromProjectInput;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromProjectHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromProjectHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedByFromProjectMutation = { __typename?: "Mutation" } & {
  removeCreatedByFromProject: { __typename?: "Project" } & Pick<
    Project,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Address"
    | "Type"
    | "Language"
    | "Manager"
    | "ManagerContact"
  > & {
      projectChange?: Maybe<
        { __typename?: "ProjectChange" } & Pick<
          ProjectChange,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Description"
          | "State"
        > & {
            modifiedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "ProjectHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type RemoveHistoriesFromProjectMutationVariables = Exact<{
  input: RemoveHistoriesFromProjectInput;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromProjectHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromProjectHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveHistoriesFromProjectMutation = { __typename?: "Mutation" } & {
  removeHistoriesFromProject: { __typename?: "Project" } & Pick<
    Project,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Address"
    | "Type"
    | "Language"
    | "Manager"
    | "ManagerContact"
  > & {
      projectChange?: Maybe<
        { __typename?: "ProjectChange" } & Pick<
          ProjectChange,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Description"
          | "State"
        > & {
            modifiedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "ProjectHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type CreateOneProjectMutationVariables = Exact<{
  input: CreateOneProjectInput;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createOneProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createOneProjectCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneProjectHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneProjectHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateOneProjectMutation = { __typename?: "Mutation" } & {
  createOneProject: { __typename?: "Project" } & Pick<
    Project,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Address"
    | "Type"
    | "Language"
    | "Manager"
    | "ManagerContact"
  > & {
      projectChange?: Maybe<
        { __typename?: "ProjectChange" } & Pick<
          ProjectChange,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Description"
          | "State"
        > & {
            modifiedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "ProjectHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type CreateManyProjectsMutationVariables = Exact<{
  input: CreateManyProjectsInput;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyProjectsCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyProjectsCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyProjectsCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyProjectsCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyProjectsCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyProjectsCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createManyProjectsCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createManyProjectsCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyProjectsCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyProjectsCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyProjectsCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyProjectsCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyProjectsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyProjectsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyProjectsCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyProjectsCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyProjectsCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyProjectsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyProjectsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyProjectsCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyProjectsCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyProjectsCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyProjectsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyProjectsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyProjectsHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyProjectsHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateManyProjectsMutation = { __typename?: "Mutation" } & {
  createManyProjects: Array<
    { __typename?: "Project" } & Pick<
      Project,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Address"
      | "Type"
      | "Language"
      | "Manager"
      | "ManagerContact"
    > & {
        projectChange?: Maybe<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        histories: { __typename?: "ProjectHistoriesConnection" } & {
          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
            OffsetPageInfo,
            "hasNextPage" | "hasPreviousPage"
          >;
          nodes: Array<
            { __typename?: "ProjectChange" } & Pick<
              ProjectChange,
              | "Id"
              | "CreatedAt"
              | "UpdatedAt"
              | "DeletedAt"
              | "Description"
              | "State"
            > & {
                modifiedBy?: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Username"
                    | "Email"
                    | "Status"
                    | "Role"
                  > & {
                      createdCompanies: {
                        __typename?: "UserCreatedCompaniesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Company" } & Pick<
                            Company,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Webpage"
                            | "Address"
                            | "Focus"
                            | "Language"
                          > & {
                              contact?: Maybe<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                              histories: {
                                __typename?: "CompanyHistoriesConnection";
                              } & {
                                pageInfo: {
                                  __typename?: "OffsetPageInfo";
                                } & Pick<
                                  OffsetPageInfo,
                                  "hasNextPage" | "hasPreviousPage"
                                >;
                                nodes: Array<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                      createdProjects: {
                        __typename?: "UserCreatedProjectsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                      contacts: { __typename?: "UserContactsConnection" } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          > & {
                              company?: Maybe<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                      projectChanges: {
                        __typename?: "UserProjectChangesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          > & {
                              company?: Maybe<
                                { __typename?: "Company" } & Pick<
                                  Company,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                  | "Webpage"
                                  | "Address"
                                  | "Focus"
                                  | "Language"
                                > & {
                                    histories: {
                                      __typename?: "CompanyHistoriesConnection";
                                    } & {
                                      pageInfo: {
                                        __typename?: "OffsetPageInfo";
                                      } & Pick<
                                        OffsetPageInfo,
                                        "hasNextPage" | "hasPreviousPage"
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                      createdWorkers: {
                        __typename?: "UserCreatedWorkersConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Worker" } & Pick<
                            Worker,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                            | "Profession"
                            | "Type"
                          > & {
                              attachments: Array<
                                { __typename?: "Attachment" } & Pick<
                                  Attachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdContracts: {
                        __typename?: "UserCreatedContractsConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contract" } & Pick<
                            Contract,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Description"
                            | "Type"
                            | "Year"
                          > & {
                              attachments: Array<
                                { __typename?: "ContractAttachment" } & Pick<
                                  ContractAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                      createdMiddlemans: {
                        __typename?: "UserCreatedMiddlemansConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Middleman" } & Pick<
                            Middleman,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Name"
                            | "Phone"
                            | "Email"
                            | "Note"
                          > & {
                              attachments: Array<
                                { __typename?: "MiddlemanAttachment" } & Pick<
                                  MiddlemanAttachment,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Name"
                                >
                              >;
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type UpdateOneProjectMutationVariables = Exact<{
  input: UpdateOneProjectInput;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  updateOneProjectCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneProjectHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneProjectHistoriesSorting?: Maybe<Array<ProjectChangeSort>>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type UpdateOneProjectMutation = { __typename?: "Mutation" } & {
  updateOneProject: { __typename?: "Project" } & Pick<
    Project,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Address"
    | "Type"
    | "Language"
    | "Manager"
    | "ManagerContact"
  > & {
      projectChange?: Maybe<
        { __typename?: "ProjectChange" } & Pick<
          ProjectChange,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Description"
          | "State"
        > & {
            modifiedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "ProjectHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ProjectChange" } & Pick<
            ProjectChange,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Description"
            | "State"
          > & {
              modifiedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
    };
};

export type UpdateManyProjectsMutationVariables = Exact<{
  input: UpdateManyProjectsInput;
}>;

export type UpdateManyProjectsMutation = { __typename?: "Mutation" } & {
  updateManyProjects: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneProjectMutationVariables = Exact<{
  input: DeleteOneProjectInput;
}>;

export type DeleteOneProjectMutation = { __typename?: "Mutation" } & {
  deleteOneProject: { __typename?: "ProjectDeleteResponse" } & Pick<
    ProjectDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Address"
    | "Type"
    | "Language"
    | "Manager"
    | "ManagerContact"
  >;
};

export type DeleteManyProjectsMutationVariables = Exact<{
  input: DeleteManyProjectsInput;
}>;

export type DeleteManyProjectsMutation = { __typename?: "Mutation" } & {
  deleteManyProjects: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type SetProjectOnProjectChangeMutationVariables = Exact<{
  input: SetProjectOnProjectChangeInput;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setProjectOnProjectChangeProjectProjectHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeProjectProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setProjectOnProjectChangeProjectProjectHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  setProjectOnProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  setProjectOnProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetProjectOnProjectChangeMutation = { __typename?: "Mutation" } & {
  setProjectOnProjectChange: { __typename?: "ProjectChange" } & Pick<
    ProjectChange,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Description" | "State"
  > & {
      project?: Maybe<
        { __typename?: "Project" } & Pick<
          Project,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Address"
          | "Type"
          | "Language"
          | "Manager"
          | "ManagerContact"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "ProjectHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      modifiedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetModifiedByOnProjectChangeMutationVariables = Exact<{
  input: SetModifiedByOnProjectChangeInput;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setModifiedByOnProjectChangeProjectProjectHistoriesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeProjectProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setModifiedByOnProjectChangeProjectProjectHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetModifiedByOnProjectChangeMutation = {
  __typename?: "Mutation";
} & {
  setModifiedByOnProjectChange: { __typename?: "ProjectChange" } & Pick<
    ProjectChange,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Description" | "State"
  > & {
      project?: Maybe<
        { __typename?: "Project" } & Pick<
          Project,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Address"
          | "Type"
          | "Language"
          | "Manager"
          | "ManagerContact"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "ProjectHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      modifiedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveProjectFromProjectChangeMutationVariables = Exact<{
  input: RemoveProjectFromProjectChangeInput;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeProjectFromProjectChangeProjectProjectHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeProjectProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeProjectFromProjectChangeProjectProjectHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveProjectFromProjectChangeMutation = {
  __typename?: "Mutation";
} & {
  removeProjectFromProjectChange: { __typename?: "ProjectChange" } & Pick<
    ProjectChange,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Description" | "State"
  > & {
      project?: Maybe<
        { __typename?: "Project" } & Pick<
          Project,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Address"
          | "Type"
          | "Language"
          | "Manager"
          | "ManagerContact"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "ProjectHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      modifiedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveModifiedByFromProjectChangeMutationVariables = Exact<{
  input: RemoveModifiedByFromProjectChangeInput;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeModifiedByFromProjectChangeProjectProjectHistoriesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeProjectProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeModifiedByFromProjectChangeProjectProjectHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveModifiedByFromProjectChangeMutation = {
  __typename?: "Mutation";
} & {
  removeModifiedByFromProjectChange: { __typename?: "ProjectChange" } & Pick<
    ProjectChange,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Description" | "State"
  > & {
      project?: Maybe<
        { __typename?: "Project" } & Pick<
          Project,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Address"
          | "Type"
          | "Language"
          | "Manager"
          | "ManagerContact"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "ProjectHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      modifiedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type CreateOneProjectChangeMutationVariables = Exact<{
  input: CreateOneProjectChangeInput;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneProjectChangeProjectProjectHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeProjectProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneProjectChangeProjectProjectHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  createOneProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateOneProjectChangeMutation = { __typename?: "Mutation" } & {
  createOneProjectChange: { __typename?: "ProjectChange" } & Pick<
    ProjectChange,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Description" | "State"
  > & {
      project?: Maybe<
        { __typename?: "Project" } & Pick<
          Project,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Address"
          | "Type"
          | "Language"
          | "Manager"
          | "ManagerContact"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "ProjectHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      modifiedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type CreateManyProjectChangesMutationVariables = Exact<{
  input: CreateManyProjectChangesInput;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyProjectChangesProjectProjectHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesProjectProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyProjectChangesProjectProjectHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyProjectChangesModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyProjectChangesModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectChangesModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyProjectChangesModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyProjectChangesModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  createManyProjectChangesModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectChangesModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyProjectChangesModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyProjectChangesModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyProjectChangesModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyProjectChangesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyProjectChangesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyProjectChangesModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyProjectChangesModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyProjectChangesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyProjectChangesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyProjectChangesModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyProjectChangesModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyProjectChangesModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyProjectChangesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyProjectChangesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type CreateManyProjectChangesMutation = { __typename?: "Mutation" } & {
  createManyProjectChanges: Array<
    { __typename?: "ProjectChange" } & Pick<
      ProjectChange,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Description" | "State"
    > & {
        project?: Maybe<
          { __typename?: "Project" } & Pick<
            Project,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Name"
            | "Address"
            | "Type"
            | "Language"
            | "Manager"
            | "ManagerContact"
          > & {
              createdBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
              histories: { __typename?: "ProjectHistoriesConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
            }
        >;
        modifiedBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type UpdateOneProjectChangeMutationVariables = Exact<{
  input: UpdateOneProjectChangeInput;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneProjectChangeProjectProjectHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeProjectProjectHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneProjectChangeProjectProjectHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneProjectChangeModifiedByModifiedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneProjectChangeModifiedByModifiedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectChangeModifiedByModifiedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneProjectChangeModifiedByModifiedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneProjectChangeModifiedByModifiedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByContactsFilter?: Maybe<ContactFilter>;
  updateOneProjectChangeModifiedByModifiedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectChangeModifiedByModifiedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneProjectChangeModifiedByModifiedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneProjectChangeModifiedByModifiedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneProjectChangeModifiedByModifiedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneProjectChangeModifiedByModifiedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneProjectChangeModifiedByModifiedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type UpdateOneProjectChangeMutation = { __typename?: "Mutation" } & {
  updateOneProjectChange: { __typename?: "ProjectChange" } & Pick<
    ProjectChange,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Description" | "State"
  > & {
      project?: Maybe<
        { __typename?: "Project" } & Pick<
          Project,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Name"
          | "Address"
          | "Type"
          | "Language"
          | "Manager"
          | "ManagerContact"
        > & {
            createdBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
            histories: { __typename?: "ProjectHistoriesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      modifiedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type UpdateManyProjectChangesMutationVariables = Exact<{
  input: UpdateManyProjectChangesInput;
}>;

export type UpdateManyProjectChangesMutation = { __typename?: "Mutation" } & {
  updateManyProjectChanges: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneProjectChangeMutationVariables = Exact<{
  input: DeleteOneProjectChangeInput;
}>;

export type DeleteOneProjectChangeMutation = { __typename?: "Mutation" } & {
  deleteOneProjectChange: { __typename?: "ProjectChangeDeleteResponse" } & Pick<
    ProjectChangeDeleteResponse,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Description" | "State"
  >;
};

export type DeleteManyProjectChangesMutationVariables = Exact<{
  input: DeleteManyProjectChangesInput;
}>;

export type DeleteManyProjectChangesMutation = { __typename?: "Mutation" } & {
  deleteManyProjectChanges: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type SetCreatedByOnWorkerMutationVariables = Exact<{
  input: SetCreatedByOnWorkerInput;
  setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnWorkerCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnWorkerAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
}>;

export type SetCreatedByOnWorkerMutation = { __typename?: "Mutation" } & {
  setCreatedByOnWorker: { __typename?: "Worker" } & Pick<
    Worker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type AddAttachmentsToWorkerMutationVariables = Exact<{
  input: AddAttachmentsToWorkerInput;
  addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addAttachmentsToWorkerCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addAttachmentsToWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addAttachmentsToWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  addAttachmentsToWorkerAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
}>;

export type AddAttachmentsToWorkerMutation = { __typename?: "Mutation" } & {
  addAttachmentsToWorker: { __typename?: "Worker" } & Pick<
    Worker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type SetAttachmentsOnWorkerMutationVariables = Exact<{
  input: SetAttachmentsOnWorkerInput;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setAttachmentsOnWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  setAttachmentsOnWorkerAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
}>;

export type SetAttachmentsOnWorkerMutation = { __typename?: "Mutation" } & {
  setAttachmentsOnWorker: { __typename?: "Worker" } & Pick<
    Worker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type RemoveCreatedByFromWorkerMutationVariables = Exact<{
  input: RemoveCreatedByFromWorkerInput;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromWorkerAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
}>;

export type RemoveCreatedByFromWorkerMutation = { __typename?: "Mutation" } & {
  removeCreatedByFromWorker: { __typename?: "Worker" } & Pick<
    Worker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type RemoveAttachmentsFromWorkerMutationVariables = Exact<{
  input: RemoveAttachmentsFromWorkerInput;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeAttachmentsFromWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeAttachmentsFromWorkerAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
}>;

export type RemoveAttachmentsFromWorkerMutation = {
  __typename?: "Mutation";
} & {
  removeAttachmentsFromWorker: { __typename?: "Worker" } & Pick<
    Worker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CreateOneWorkerMutationVariables = Exact<{
  input: CreateOneWorkerInput;
  createOneWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createOneWorkerCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneWorkerAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
}>;

export type CreateOneWorkerMutation = { __typename?: "Mutation" } & {
  createOneWorker: { __typename?: "Worker" } & Pick<
    Worker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CreateManyWorkersMutationVariables = Exact<{
  input: CreateManyWorkersInput;
  createManyWorkersCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyWorkersCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyWorkersCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyWorkersCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyWorkersCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createManyWorkersCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyWorkersCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyWorkersCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyWorkersCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyWorkersCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyWorkersCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyWorkersCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyWorkersCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyWorkersCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyWorkersCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyWorkersCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyWorkersCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyWorkersCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyWorkersCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyWorkersAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyWorkersAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
}>;

export type CreateManyWorkersMutation = { __typename?: "Mutation" } & {
  createManyWorkers: Array<
    { __typename?: "Worker" } & Pick<
      Worker,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Phone"
      | "Email"
      | "Note"
      | "Profession"
      | "Type"
    > & {
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        attachments: Array<
          { __typename?: "Attachment" } & Pick<
            Attachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
      }
  >;
};

export type UpdateOneWorkerMutationVariables = Exact<{
  input: UpdateOneWorkerInput;
  updateOneWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  updateOneWorkerCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneWorkerAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
}>;

export type UpdateOneWorkerMutation = { __typename?: "Mutation" } & {
  updateOneWorker: { __typename?: "Worker" } & Pick<
    Worker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type UpdateManyWorkersMutationVariables = Exact<{
  input: UpdateManyWorkersInput;
}>;

export type UpdateManyWorkersMutation = { __typename?: "Mutation" } & {
  updateManyWorkers: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneWorkerMutationVariables = Exact<{
  input: DeleteOneWorkerInput;
}>;

export type DeleteOneWorkerMutation = { __typename?: "Mutation" } & {
  deleteOneWorker: { __typename?: "WorkerDeleteResponse" } & Pick<
    WorkerDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  >;
};

export type DeleteManyWorkersMutationVariables = Exact<{
  input: DeleteManyWorkersInput;
}>;

export type DeleteManyWorkersMutation = { __typename?: "Mutation" } & {
  deleteManyWorkers: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type CreateOneAttachmentMutationVariables = Exact<{
  input: CreateOneAttachmentInput;
}>;

export type CreateOneAttachmentMutation = { __typename?: "Mutation" } & {
  createOneAttachment: { __typename?: "Attachment" } & Pick<
    Attachment,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type CreateManyAttachmentsMutationVariables = Exact<{
  input: CreateManyAttachmentsInput;
}>;

export type CreateManyAttachmentsMutation = { __typename?: "Mutation" } & {
  createManyAttachments: Array<
    { __typename?: "Attachment" } & Pick<
      Attachment,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
    >
  >;
};

export type UpdateOneAttachmentMutationVariables = Exact<{
  input: UpdateOneAttachmentInput;
}>;

export type UpdateOneAttachmentMutation = { __typename?: "Mutation" } & {
  updateOneAttachment: { __typename?: "Attachment" } & Pick<
    Attachment,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type UpdateManyAttachmentsMutationVariables = Exact<{
  input: UpdateManyAttachmentsInput;
}>;

export type UpdateManyAttachmentsMutation = { __typename?: "Mutation" } & {
  updateManyAttachments: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneAttachmentMutationVariables = Exact<{
  input: DeleteOneAttachmentInput;
}>;

export type DeleteOneAttachmentMutation = { __typename?: "Mutation" } & {
  deleteOneAttachment: { __typename?: "AttachmentDeleteResponse" } & Pick<
    AttachmentDeleteResponse,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type DeleteManyAttachmentsMutationVariables = Exact<{
  input: DeleteManyAttachmentsInput;
}>;

export type DeleteManyAttachmentsMutation = { __typename?: "Mutation" } & {
  deleteManyAttachments: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type SetCreatedByOnContractMutationVariables = Exact<{
  input: SetCreatedByOnContractInput;
  setCreatedByOnContractCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnContractCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnContractCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnContractCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnContractCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnContractCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnContractCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnContractCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnContractCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnContractCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnContractCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnContractCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnContractAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnContractAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
}>;

export type SetCreatedByOnContractMutation = { __typename?: "Mutation" } & {
  setCreatedByOnContract: { __typename?: "Contract" } & Pick<
    Contract,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "ContractAttachment" } & Pick<
          ContractAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type AddAttachmentsToContractMutationVariables = Exact<{
  input: AddAttachmentsToContractInput;
  addAttachmentsToContractCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addAttachmentsToContractCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToContractCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addAttachmentsToContractCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addAttachmentsToContractCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addAttachmentsToContractCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToContractCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addAttachmentsToContractCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToContractCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addAttachmentsToContractCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addAttachmentsToContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addAttachmentsToContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addAttachmentsToContractCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addAttachmentsToContractCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addAttachmentsToContractAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addAttachmentsToContractAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
}>;

export type AddAttachmentsToContractMutation = { __typename?: "Mutation" } & {
  addAttachmentsToContract: { __typename?: "Contract" } & Pick<
    Contract,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "ContractAttachment" } & Pick<
          ContractAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type SetAttachmentsOnContractMutationVariables = Exact<{
  input: SetAttachmentsOnContractInput;
  setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setAttachmentsOnContractCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setAttachmentsOnContractCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnContractCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setAttachmentsOnContractCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setAttachmentsOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setAttachmentsOnContractCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setAttachmentsOnContractAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setAttachmentsOnContractAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
}>;

export type SetAttachmentsOnContractMutation = { __typename?: "Mutation" } & {
  setAttachmentsOnContract: { __typename?: "Contract" } & Pick<
    Contract,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "ContractAttachment" } & Pick<
          ContractAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type RemoveCreatedByFromContractMutationVariables = Exact<{
  input: RemoveCreatedByFromContractInput;
  removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromContractCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromContractCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromContractCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromContractCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromContractCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromContractAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromContractAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
}>;

export type RemoveCreatedByFromContractMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedByFromContract: { __typename?: "Contract" } & Pick<
    Contract,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "ContractAttachment" } & Pick<
          ContractAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type RemoveAttachmentsFromContractMutationVariables = Exact<{
  input: RemoveAttachmentsFromContractInput;
  removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromContractCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromContractCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeAttachmentsFromContractAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeAttachmentsFromContractAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
}>;

export type RemoveAttachmentsFromContractMutation = {
  __typename?: "Mutation";
} & {
  removeAttachmentsFromContract: { __typename?: "Contract" } & Pick<
    Contract,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "ContractAttachment" } & Pick<
          ContractAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CreateOneContractMutationVariables = Exact<{
  input: CreateOneContractInput;
  createOneContractCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneContractCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContractCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneContractCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneContractCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createOneContractCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContractCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneContractCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneContractCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneContractCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneContractCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneContractCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneContractCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneContractCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneContractCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneContractAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneContractAttachmentsSorting?: Maybe<Array<ContractAttachmentSort>>;
}>;

export type CreateOneContractMutation = { __typename?: "Mutation" } & {
  createOneContract: { __typename?: "Contract" } & Pick<
    Contract,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "ContractAttachment" } & Pick<
          ContractAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CreateManyContractsMutationVariables = Exact<{
  input: CreateManyContractsInput;
  createManyContractsCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyContractsCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContractsCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyContractsCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyContractsCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createManyContractsCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContractsCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyContractsCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyContractsCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyContractsCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyContractsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyContractsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyContractsCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyContractsCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyContractsCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyContractsCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyContractsCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyContractsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyContractsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyContractsAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyContractsAttachmentsSorting?: Maybe<Array<ContractAttachmentSort>>;
}>;

export type CreateManyContractsMutation = { __typename?: "Mutation" } & {
  createManyContracts: Array<
    { __typename?: "Contract" } & Pick<
      Contract,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Description"
      | "Type"
      | "Year"
    > & {
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        attachments: Array<
          { __typename?: "ContractAttachment" } & Pick<
            ContractAttachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
      }
  >;
};

export type UpdateOneContractMutationVariables = Exact<{
  input: UpdateOneContractInput;
  updateOneContractCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneContractCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContractCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneContractCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneContractCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  updateOneContractCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContractCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneContractCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneContractCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneContractCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneContractCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneContractCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneContractCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneContractCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneContractCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneContractAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneContractAttachmentsSorting?: Maybe<Array<ContractAttachmentSort>>;
}>;

export type UpdateOneContractMutation = { __typename?: "Mutation" } & {
  updateOneContract: { __typename?: "Contract" } & Pick<
    Contract,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "ContractAttachment" } & Pick<
          ContractAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type UpdateManyContractsMutationVariables = Exact<{
  input: UpdateManyContractsInput;
}>;

export type UpdateManyContractsMutation = { __typename?: "Mutation" } & {
  updateManyContracts: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneContractMutationVariables = Exact<{
  input: DeleteOneContractInput;
}>;

export type DeleteOneContractMutation = { __typename?: "Mutation" } & {
  deleteOneContract: { __typename?: "ContractDeleteResponse" } & Pick<
    ContractDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  >;
};

export type DeleteManyContractsMutationVariables = Exact<{
  input: DeleteManyContractsInput;
}>;

export type DeleteManyContractsMutation = { __typename?: "Mutation" } & {
  deleteManyContracts: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type CreateOneContractAttachmentMutationVariables = Exact<{
  input: CreateOneContractAttachmentInput;
}>;

export type CreateOneContractAttachmentMutation = {
  __typename?: "Mutation";
} & {
  createOneContractAttachment: { __typename?: "ContractAttachment" } & Pick<
    ContractAttachment,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type CreateManyContractAttachmentsMutationVariables = Exact<{
  input: CreateManyContractAttachmentsInput;
}>;

export type CreateManyContractAttachmentsMutation = {
  __typename?: "Mutation";
} & {
  createManyContractAttachments: Array<
    { __typename?: "ContractAttachment" } & Pick<
      ContractAttachment,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
    >
  >;
};

export type UpdateOneContractAttachmentMutationVariables = Exact<{
  input: UpdateOneContractAttachmentInput;
}>;

export type UpdateOneContractAttachmentMutation = {
  __typename?: "Mutation";
} & {
  updateOneContractAttachment: { __typename?: "ContractAttachment" } & Pick<
    ContractAttachment,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type UpdateManyContractAttachmentsMutationVariables = Exact<{
  input: UpdateManyContractAttachmentsInput;
}>;

export type UpdateManyContractAttachmentsMutation = {
  __typename?: "Mutation";
} & {
  updateManyContractAttachments: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneContractAttachmentMutationVariables = Exact<{
  input: DeleteOneContractAttachmentInput;
}>;

export type DeleteOneContractAttachmentMutation = {
  __typename?: "Mutation";
} & {
  deleteOneContractAttachment: {
    __typename?: "ContractAttachmentDeleteResponse";
  } & Pick<
    ContractAttachmentDeleteResponse,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type DeleteManyContractAttachmentsMutationVariables = Exact<{
  input: DeleteManyContractAttachmentsInput;
}>;

export type DeleteManyContractAttachmentsMutation = {
  __typename?: "Mutation";
} & {
  deleteManyContractAttachments: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type AddAttachmentsToContractPilverMutationVariables = Exact<{
  input: AddAttachmentsToContractPilverInput;
  addAttachmentsToContractPilverAttachmentsFilter?: Maybe<ContractPilverAttachmentFilter>;
  addAttachmentsToContractPilverAttachmentsSorting?: Maybe<
    Array<ContractPilverAttachmentSort>
  >;
}>;

export type AddAttachmentsToContractPilverMutation = {
  __typename?: "Mutation";
} & {
  addAttachmentsToContractPilver: { __typename?: "ContractPilver" } & Pick<
    ContractPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      attachments: Array<
        { __typename?: "ContractPilverAttachment" } & Pick<
          ContractPilverAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type SetAttachmentsOnContractPilverMutationVariables = Exact<{
  input: SetAttachmentsOnContractPilverInput;
  setAttachmentsOnContractPilverAttachmentsFilter?: Maybe<ContractPilverAttachmentFilter>;
  setAttachmentsOnContractPilverAttachmentsSorting?: Maybe<
    Array<ContractPilverAttachmentSort>
  >;
}>;

export type SetAttachmentsOnContractPilverMutation = {
  __typename?: "Mutation";
} & {
  setAttachmentsOnContractPilver: { __typename?: "ContractPilver" } & Pick<
    ContractPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      attachments: Array<
        { __typename?: "ContractPilverAttachment" } & Pick<
          ContractPilverAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type RemoveAttachmentsFromContractPilverMutationVariables = Exact<{
  input: RemoveAttachmentsFromContractPilverInput;
  removeAttachmentsFromContractPilverAttachmentsFilter?: Maybe<ContractPilverAttachmentFilter>;
  removeAttachmentsFromContractPilverAttachmentsSorting?: Maybe<
    Array<ContractPilverAttachmentSort>
  >;
}>;

export type RemoveAttachmentsFromContractPilverMutation = {
  __typename?: "Mutation";
} & {
  removeAttachmentsFromContractPilver: { __typename?: "ContractPilver" } & Pick<
    ContractPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      attachments: Array<
        { __typename?: "ContractPilverAttachment" } & Pick<
          ContractPilverAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CreateOneContractPilverMutationVariables = Exact<{
  input: CreateOneContractPilverInput;
  createOneContractPilverAttachmentsFilter?: Maybe<ContractPilverAttachmentFilter>;
  createOneContractPilverAttachmentsSorting?: Maybe<
    Array<ContractPilverAttachmentSort>
  >;
}>;

export type CreateOneContractPilverMutation = { __typename?: "Mutation" } & {
  createOneContractPilver: { __typename?: "ContractPilver" } & Pick<
    ContractPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      attachments: Array<
        { __typename?: "ContractPilverAttachment" } & Pick<
          ContractPilverAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CreateManyContractPilversMutationVariables = Exact<{
  input: CreateManyContractPilversInput;
  createManyContractPilversAttachmentsFilter?: Maybe<ContractPilverAttachmentFilter>;
  createManyContractPilversAttachmentsSorting?: Maybe<
    Array<ContractPilverAttachmentSort>
  >;
}>;

export type CreateManyContractPilversMutation = { __typename?: "Mutation" } & {
  createManyContractPilvers: Array<
    { __typename?: "ContractPilver" } & Pick<
      ContractPilver,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Description"
      | "Type"
      | "Year"
    > & {
        attachments: Array<
          { __typename?: "ContractPilverAttachment" } & Pick<
            ContractPilverAttachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
      }
  >;
};

export type UpdateOneContractPilverMutationVariables = Exact<{
  input: UpdateOneContractPilverInput;
  updateOneContractPilverAttachmentsFilter?: Maybe<ContractPilverAttachmentFilter>;
  updateOneContractPilverAttachmentsSorting?: Maybe<
    Array<ContractPilverAttachmentSort>
  >;
}>;

export type UpdateOneContractPilverMutation = { __typename?: "Mutation" } & {
  updateOneContractPilver: { __typename?: "ContractPilver" } & Pick<
    ContractPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  > & {
      attachments: Array<
        { __typename?: "ContractPilverAttachment" } & Pick<
          ContractPilverAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type UpdateManyContractPilversMutationVariables = Exact<{
  input: UpdateManyContractPilversInput;
}>;

export type UpdateManyContractPilversMutation = { __typename?: "Mutation" } & {
  updateManyContractPilvers: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneContractPilverMutationVariables = Exact<{
  input: DeleteOneContractPilverInput;
}>;

export type DeleteOneContractPilverMutation = { __typename?: "Mutation" } & {
  deleteOneContractPilver: {
    __typename?: "ContractPilverDeleteResponse";
  } & Pick<
    ContractPilverDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Type"
    | "Year"
  >;
};

export type DeleteManyContractPilversMutationVariables = Exact<{
  input: DeleteManyContractPilversInput;
}>;

export type DeleteManyContractPilversMutation = { __typename?: "Mutation" } & {
  deleteManyContractPilvers: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type CreateOneContractPilverAttachmentMutationVariables = Exact<{
  input: CreateOneContractPilverAttachmentInput;
}>;

export type CreateOneContractPilverAttachmentMutation = {
  __typename?: "Mutation";
} & {
  createOneContractPilverAttachment: {
    __typename?: "ContractPilverAttachment";
  } & Pick<
    ContractPilverAttachment,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type CreateManyContractPilverAttachmentsMutationVariables = Exact<{
  input: CreateManyContractPilverAttachmentsInput;
}>;

export type CreateManyContractPilverAttachmentsMutation = {
  __typename?: "Mutation";
} & {
  createManyContractPilverAttachments: Array<
    { __typename?: "ContractPilverAttachment" } & Pick<
      ContractPilverAttachment,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
    >
  >;
};

export type UpdateOneContractPilverAttachmentMutationVariables = Exact<{
  input: UpdateOneContractPilverAttachmentInput;
}>;

export type UpdateOneContractPilverAttachmentMutation = {
  __typename?: "Mutation";
} & {
  updateOneContractPilverAttachment: {
    __typename?: "ContractPilverAttachment";
  } & Pick<
    ContractPilverAttachment,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type UpdateManyContractPilverAttachmentsMutationVariables = Exact<{
  input: UpdateManyContractPilverAttachmentsInput;
}>;

export type UpdateManyContractPilverAttachmentsMutation = {
  __typename?: "Mutation";
} & {
  updateManyContractPilverAttachments: {
    __typename?: "UpdateManyResponse";
  } & Pick<UpdateManyResponse, "updatedCount">;
};

export type DeleteOneContractPilverAttachmentMutationVariables = Exact<{
  input: DeleteOneContractPilverAttachmentInput;
}>;

export type DeleteOneContractPilverAttachmentMutation = {
  __typename?: "Mutation";
} & {
  deleteOneContractPilverAttachment: {
    __typename?: "ContractPilverAttachmentDeleteResponse";
  } & Pick<
    ContractPilverAttachmentDeleteResponse,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type DeleteManyContractPilverAttachmentsMutationVariables = Exact<{
  input: DeleteManyContractPilverAttachmentsInput;
}>;

export type DeleteManyContractPilverAttachmentsMutation = {
  __typename?: "Mutation";
} & {
  deleteManyContractPilverAttachments: {
    __typename?: "DeleteManyResponse";
  } & Pick<DeleteManyResponse, "deletedCount">;
};

export type SetContactOnDeletedCompanyMutationVariables = Exact<{
  input: SetContactOnDeletedCompanyInput;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnDeletedCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetContactOnDeletedCompanyMutation = { __typename?: "Mutation" } & {
  setContactOnDeletedCompany: { __typename?: "DeletedCompany" } & Pick<
    DeletedCompany,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "Company" } & Pick<
                Company,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: { __typename?: "CompanyHistoriesConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetCreatedByOnDeletedCompanyMutationVariables = Exact<{
  input: SetCreatedByOnDeletedCompanyInput;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedByOnDeletedCompanyMutation = {
  __typename?: "Mutation";
} & {
  setCreatedByOnDeletedCompany: { __typename?: "DeletedCompany" } & Pick<
    DeletedCompany,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "Company" } & Pick<
                Company,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: { __typename?: "CompanyHistoriesConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type AddHistoriesToDeletedCompanyMutationVariables = Exact<{
  input: AddHistoriesToDeletedCompanyInput;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToDeletedCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddHistoriesToDeletedCompanyMutation = {
  __typename?: "Mutation";
} & {
  addHistoriesToDeletedCompany: { __typename?: "DeletedCompany" } & Pick<
    DeletedCompany,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "Company" } & Pick<
                Company,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: { __typename?: "CompanyHistoriesConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetHistoriesOnDeletedCompanyMutationVariables = Exact<{
  input: SetHistoriesOnDeletedCompanyInput;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetHistoriesOnDeletedCompanyMutation = {
  __typename?: "Mutation";
} & {
  setHistoriesOnDeletedCompany: { __typename?: "DeletedCompany" } & Pick<
    DeletedCompany,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "Company" } & Pick<
                Company,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: { __typename?: "CompanyHistoriesConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveContactFromDeletedCompanyMutationVariables = Exact<{
  input: RemoveContactFromDeletedCompanyInput;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromDeletedCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveContactFromDeletedCompanyMutation = {
  __typename?: "Mutation";
} & {
  removeContactFromDeletedCompany: { __typename?: "DeletedCompany" } & Pick<
    DeletedCompany,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "Company" } & Pick<
                Company,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: { __typename?: "CompanyHistoriesConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveCreatedByFromDeletedCompanyMutationVariables = Exact<{
  input: RemoveCreatedByFromDeletedCompanyInput;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedByFromDeletedCompanyMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedByFromDeletedCompany: { __typename?: "DeletedCompany" } & Pick<
    DeletedCompany,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "Company" } & Pick<
                Company,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: { __typename?: "CompanyHistoriesConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveHistoriesFromDeletedCompanyMutationVariables = Exact<{
  input: RemoveHistoriesFromDeletedCompanyInput;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyHistoriesSorting?: Maybe<Array<ContactSort>>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveHistoriesFromDeletedCompanyMutation = {
  __typename?: "Mutation";
} & {
  removeHistoriesFromDeletedCompany: { __typename?: "DeletedCompany" } & Pick<
    DeletedCompany,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "Company" } & Pick<
                Company,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: { __typename?: "CompanyHistoriesConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetContactOnDeletedCompanyPilverMutationVariables = Exact<{
  input: SetContactOnDeletedCompanyPilverInput;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  setContactOnDeletedCompanyPilverHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetContactOnDeletedCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  setContactOnDeletedCompanyPilver: {
    __typename?: "DeletedCompanyPilver";
  } & Pick<
    DeletedCompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "CompanyPilver" } & Pick<
                CompanyPilver,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: {
                    __typename?: "CompanyPilverHistoriesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "CompanyPilver" } & Pick<
                  CompanyPilver,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: {
                      __typename?: "CompanyPilverHistoriesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetCreatedByOnDeletedCompanyPilverMutationVariables = Exact<{
  input: SetCreatedByOnDeletedCompanyPilverInput;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedByOnDeletedCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  setCreatedByOnDeletedCompanyPilver: {
    __typename?: "DeletedCompanyPilver";
  } & Pick<
    DeletedCompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "CompanyPilver" } & Pick<
                CompanyPilver,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: {
                    __typename?: "CompanyPilverHistoriesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "CompanyPilver" } & Pick<
                  CompanyPilver,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: {
                      __typename?: "CompanyPilverHistoriesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type AddHistoriesToDeletedCompanyPilverMutationVariables = Exact<{
  input: AddHistoriesToDeletedCompanyPilverInput;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddHistoriesToDeletedCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  addHistoriesToDeletedCompanyPilver: {
    __typename?: "DeletedCompanyPilver";
  } & Pick<
    DeletedCompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "CompanyPilver" } & Pick<
                CompanyPilver,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: {
                    __typename?: "CompanyPilverHistoriesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "CompanyPilver" } & Pick<
                  CompanyPilver,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: {
                      __typename?: "CompanyPilverHistoriesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetHistoriesOnDeletedCompanyPilverMutationVariables = Exact<{
  input: SetHistoriesOnDeletedCompanyPilverInput;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetHistoriesOnDeletedCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  setHistoriesOnDeletedCompanyPilver: {
    __typename?: "DeletedCompanyPilver";
  } & Pick<
    DeletedCompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "CompanyPilver" } & Pick<
                CompanyPilver,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: {
                    __typename?: "CompanyPilverHistoriesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "CompanyPilver" } & Pick<
                  CompanyPilver,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: {
                      __typename?: "CompanyPilverHistoriesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveContactFromDeletedCompanyPilverMutationVariables = Exact<{
  input: RemoveContactFromDeletedCompanyPilverInput;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeContactFromDeletedCompanyPilverHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveContactFromDeletedCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  removeContactFromDeletedCompanyPilver: {
    __typename?: "DeletedCompanyPilver";
  } & Pick<
    DeletedCompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "CompanyPilver" } & Pick<
                CompanyPilver,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: {
                    __typename?: "CompanyPilverHistoriesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "CompanyPilver" } & Pick<
                  CompanyPilver,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: {
                      __typename?: "CompanyPilverHistoriesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveCreatedByFromDeletedCompanyPilverMutationVariables = Exact<{
  input: RemoveCreatedByFromDeletedCompanyPilverInput;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedByFromDeletedCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedByFromDeletedCompanyPilver: {
    __typename?: "DeletedCompanyPilver";
  } & Pick<
    DeletedCompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "CompanyPilver" } & Pick<
                CompanyPilver,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: {
                    __typename?: "CompanyPilverHistoriesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "CompanyPilver" } & Pick<
                  CompanyPilver,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: {
                      __typename?: "CompanyPilverHistoriesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveHistoriesFromDeletedCompanyPilverMutationVariables = Exact<{
  input: RemoveHistoriesFromDeletedCompanyPilverInput;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactPilverFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactPilverSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveHistoriesFromDeletedCompanyPilverMutation = {
  __typename?: "Mutation";
} & {
  removeHistoriesFromDeletedCompanyPilver: {
    __typename?: "DeletedCompanyPilver";
  } & Pick<
    DeletedCompanyPilver,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Webpage"
    | "Address"
    | "Focus"
    | "Language"
  > & {
      contact?: Maybe<
        { __typename?: "ContactPilver" } & Pick<
          ContactPilver,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "ContactPerson"
          | "Email"
          | "Phone"
          | "Description"
          | "Interest"
        > & {
            company?: Maybe<
              { __typename?: "CompanyPilver" } & Pick<
                CompanyPilver,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Name"
                | "Webpage"
                | "Address"
                | "Focus"
                | "Language"
              > & {
                  createdBy?: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      | "Id"
                      | "CreatedAt"
                      | "UpdatedAt"
                      | "DeletedAt"
                      | "Username"
                      | "Email"
                      | "Status"
                      | "Role"
                    > & {
                        createdCompanies: {
                          __typename?: "UserCreatedCompaniesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                contact?: Maybe<
                                  { __typename?: "Contact" } & Pick<
                                    Contact,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "ContactPerson"
                                    | "Email"
                                    | "Phone"
                                    | "Description"
                                    | "Interest"
                                  >
                                >;
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        createdProjects: {
                          __typename?: "UserCreatedProjectsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Project" } & Pick<
                              Project,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Address"
                              | "Type"
                              | "Language"
                              | "Manager"
                              | "ManagerContact"
                            > & {
                                projectChange?: Maybe<
                                  { __typename?: "ProjectChange" } & Pick<
                                    ProjectChange,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Description"
                                    | "State"
                                  >
                                >;
                                histories: {
                                  __typename?: "ProjectHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                  nodes: Array<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                };
                              }
                          >;
                        };
                        contacts: { __typename?: "UserContactsConnection" } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        projectChanges: {
                          __typename?: "UserProjectChangesConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            > & {
                                company?: Maybe<
                                  { __typename?: "Company" } & Pick<
                                    Company,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                    | "Webpage"
                                    | "Address"
                                    | "Focus"
                                    | "Language"
                                  > & {
                                      histories: {
                                        __typename?: "CompanyHistoriesConnection";
                                      } & {
                                        pageInfo: {
                                          __typename?: "OffsetPageInfo";
                                        } & Pick<
                                          OffsetPageInfo,
                                          "hasNextPage" | "hasPreviousPage"
                                        >;
                                      };
                                    }
                                >;
                              }
                          >;
                        };
                        createdWorkers: {
                          __typename?: "UserCreatedWorkersConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Worker" } & Pick<
                              Worker,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                              | "Profession"
                              | "Type"
                            > & {
                                attachments: Array<
                                  { __typename?: "Attachment" } & Pick<
                                    Attachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdContracts: {
                          __typename?: "UserCreatedContractsConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Contract" } & Pick<
                              Contract,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Description"
                              | "Type"
                              | "Year"
                            > & {
                                attachments: Array<
                                  { __typename?: "ContractAttachment" } & Pick<
                                    ContractAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                        createdMiddlemans: {
                          __typename?: "UserCreatedMiddlemansConnection";
                        } & {
                          pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                            OffsetPageInfo,
                            "hasNextPage" | "hasPreviousPage"
                          >;
                          nodes: Array<
                            { __typename?: "Middleman" } & Pick<
                              Middleman,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Phone"
                              | "Email"
                              | "Note"
                            > & {
                                attachments: Array<
                                  { __typename?: "MiddlemanAttachment" } & Pick<
                                    MiddlemanAttachment,
                                    | "Id"
                                    | "CreatedAt"
                                    | "UpdatedAt"
                                    | "DeletedAt"
                                    | "Name"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                  histories: {
                    __typename?: "CompanyPilverHistoriesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                  };
                }
            >;
            contactedBy?: Maybe<
              { __typename?: "User" } & Pick<
                User,
                | "Id"
                | "CreatedAt"
                | "UpdatedAt"
                | "DeletedAt"
                | "Username"
                | "Email"
                | "Status"
                | "Role"
              > & {
                  createdCompanies: {
                    __typename?: "UserCreatedCompaniesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          contact?: Maybe<
                            { __typename?: "Contact" } & Pick<
                              Contact,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "ContactPerson"
                              | "Email"
                              | "Phone"
                              | "Description"
                              | "Interest"
                            >
                          >;
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  createdProjects: {
                    __typename?: "UserCreatedProjectsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Project" } & Pick<
                        Project,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Address"
                        | "Type"
                        | "Language"
                        | "Manager"
                        | "ManagerContact"
                      > & {
                          projectChange?: Maybe<
                            { __typename?: "ProjectChange" } & Pick<
                              ProjectChange,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Description"
                              | "State"
                            >
                          >;
                          histories: {
                            __typename?: "ProjectHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                          };
                        }
                    >;
                  };
                  contacts: { __typename?: "UserContactsConnection" } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  projectChanges: {
                    __typename?: "UserProjectChangesConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      > & {
                          company?: Maybe<
                            { __typename?: "Company" } & Pick<
                              Company,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                              | "Webpage"
                              | "Address"
                              | "Focus"
                              | "Language"
                            > & {
                                histories: {
                                  __typename?: "CompanyHistoriesConnection";
                                } & {
                                  pageInfo: {
                                    __typename?: "OffsetPageInfo";
                                  } & Pick<
                                    OffsetPageInfo,
                                    "hasNextPage" | "hasPreviousPage"
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  createdWorkers: {
                    __typename?: "UserCreatedWorkersConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Worker" } & Pick<
                        Worker,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                        | "Profession"
                        | "Type"
                      > & {
                          attachments: Array<
                            { __typename?: "Attachment" } & Pick<
                              Attachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdContracts: {
                    __typename?: "UserCreatedContractsConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Contract" } & Pick<
                        Contract,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Description"
                        | "Type"
                        | "Year"
                      > & {
                          attachments: Array<
                            { __typename?: "ContractAttachment" } & Pick<
                              ContractAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                  createdMiddlemans: {
                    __typename?: "UserCreatedMiddlemansConnection";
                  } & {
                    pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                      OffsetPageInfo,
                      "hasNextPage" | "hasPreviousPage"
                    >;
                    nodes: Array<
                      { __typename?: "Middleman" } & Pick<
                        Middleman,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Phone"
                        | "Email"
                        | "Note"
                      > & {
                          attachments: Array<
                            { __typename?: "MiddlemanAttachment" } & Pick<
                              MiddlemanAttachment,
                              | "Id"
                              | "CreatedAt"
                              | "UpdatedAt"
                              | "DeletedAt"
                              | "Name"
                            >
                          >;
                        }
                    >;
                  };
                }
            >;
          }
      >;
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      histories: { __typename?: "DeletedCompanyPilverHistoriesConnection" } & {
        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
          OffsetPageInfo,
          "hasNextPage" | "hasPreviousPage"
        >;
        nodes: Array<
          { __typename?: "ContactPilver" } & Pick<
            ContactPilver,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "ContactPerson"
            | "Email"
            | "Phone"
            | "Description"
            | "Interest"
          > & {
              company?: Maybe<
                { __typename?: "CompanyPilver" } & Pick<
                  CompanyPilver,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    createdBy?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Username"
                        | "Email"
                        | "Status"
                        | "Role"
                      > & {
                          createdCompanies: {
                            __typename?: "UserCreatedCompaniesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  contact?: Maybe<
                                    { __typename?: "Contact" } & Pick<
                                      Contact,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "ContactPerson"
                                      | "Email"
                                      | "Phone"
                                      | "Description"
                                      | "Interest"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "Contact" } & Pick<
                                        Contact,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "ContactPerson"
                                        | "Email"
                                        | "Phone"
                                        | "Description"
                                        | "Interest"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          createdProjects: {
                            __typename?: "UserCreatedProjectsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Project" } & Pick<
                                Project,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Address"
                                | "Type"
                                | "Language"
                                | "Manager"
                                | "ManagerContact"
                              > & {
                                  projectChange?: Maybe<
                                    { __typename?: "ProjectChange" } & Pick<
                                      ProjectChange,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Description"
                                      | "State"
                                    >
                                  >;
                                  histories: {
                                    __typename?: "ProjectHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                    nodes: Array<
                                      { __typename?: "ProjectChange" } & Pick<
                                        ProjectChange,
                                        | "Id"
                                        | "CreatedAt"
                                        | "UpdatedAt"
                                        | "DeletedAt"
                                        | "Description"
                                        | "State"
                                      >
                                    >;
                                  };
                                }
                            >;
                          };
                          contacts: {
                            __typename?: "UserContactsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          projectChanges: {
                            __typename?: "UserProjectChangesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              > & {
                                  company?: Maybe<
                                    { __typename?: "Company" } & Pick<
                                      Company,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                      | "Webpage"
                                      | "Address"
                                      | "Focus"
                                      | "Language"
                                    > & {
                                        histories: {
                                          __typename?: "CompanyHistoriesConnection";
                                        } & {
                                          pageInfo: {
                                            __typename?: "OffsetPageInfo";
                                          } & Pick<
                                            OffsetPageInfo,
                                            "hasNextPage" | "hasPreviousPage"
                                          >;
                                        };
                                      }
                                  >;
                                }
                            >;
                          };
                          createdWorkers: {
                            __typename?: "UserCreatedWorkersConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Worker" } & Pick<
                                Worker,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                                | "Profession"
                                | "Type"
                              > & {
                                  attachments: Array<
                                    { __typename?: "Attachment" } & Pick<
                                      Attachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdContracts: {
                            __typename?: "UserCreatedContractsConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Contract" } & Pick<
                                Contract,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Description"
                                | "Type"
                                | "Year"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "ContractAttachment";
                                    } & Pick<
                                      ContractAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                          createdMiddlemans: {
                            __typename?: "UserCreatedMiddlemansConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                            nodes: Array<
                              { __typename?: "Middleman" } & Pick<
                                Middleman,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Phone"
                                | "Email"
                                | "Note"
                              > & {
                                  attachments: Array<
                                    {
                                      __typename?: "MiddlemanAttachment";
                                    } & Pick<
                                      MiddlemanAttachment,
                                      | "Id"
                                      | "CreatedAt"
                                      | "UpdatedAt"
                                      | "DeletedAt"
                                      | "Name"
                                    >
                                  >;
                                }
                            >;
                          };
                        }
                    >;
                    histories: {
                      __typename?: "CompanyPilverHistoriesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                    };
                  }
              >;
              contactedBy?: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Username"
                  | "Email"
                  | "Status"
                  | "Role"
                > & {
                    createdCompanies: {
                      __typename?: "UserCreatedCompaniesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            contact?: Maybe<
                              { __typename?: "Contact" } & Pick<
                                Contact,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "ContactPerson"
                                | "Email"
                                | "Phone"
                                | "Description"
                                | "Interest"
                              >
                            >;
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "Contact" } & Pick<
                                  Contact,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "ContactPerson"
                                  | "Email"
                                  | "Phone"
                                  | "Description"
                                  | "Interest"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    createdProjects: {
                      __typename?: "UserCreatedProjectsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Project" } & Pick<
                          Project,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Address"
                          | "Type"
                          | "Language"
                          | "Manager"
                          | "ManagerContact"
                        > & {
                            projectChange?: Maybe<
                              { __typename?: "ProjectChange" } & Pick<
                                ProjectChange,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Description"
                                | "State"
                              >
                            >;
                            histories: {
                              __typename?: "ProjectHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                              nodes: Array<
                                { __typename?: "ProjectChange" } & Pick<
                                  ProjectChange,
                                  | "Id"
                                  | "CreatedAt"
                                  | "UpdatedAt"
                                  | "DeletedAt"
                                  | "Description"
                                  | "State"
                                >
                              >;
                            };
                          }
                      >;
                    };
                    contacts: { __typename?: "UserContactsConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    projectChanges: {
                      __typename?: "UserProjectChangesConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        > & {
                            company?: Maybe<
                              { __typename?: "Company" } & Pick<
                                Company,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                                | "Webpage"
                                | "Address"
                                | "Focus"
                                | "Language"
                              > & {
                                  histories: {
                                    __typename?: "CompanyHistoriesConnection";
                                  } & {
                                    pageInfo: {
                                      __typename?: "OffsetPageInfo";
                                    } & Pick<
                                      OffsetPageInfo,
                                      "hasNextPage" | "hasPreviousPage"
                                    >;
                                  };
                                }
                            >;
                          }
                      >;
                    };
                    createdWorkers: {
                      __typename?: "UserCreatedWorkersConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Worker" } & Pick<
                          Worker,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                          | "Profession"
                          | "Type"
                        > & {
                            attachments: Array<
                              { __typename?: "Attachment" } & Pick<
                                Attachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdContracts: {
                      __typename?: "UserCreatedContractsConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contract" } & Pick<
                          Contract,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Description"
                          | "Type"
                          | "Year"
                        > & {
                            attachments: Array<
                              { __typename?: "ContractAttachment" } & Pick<
                                ContractAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                    createdMiddlemans: {
                      __typename?: "UserCreatedMiddlemansConnection";
                    } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Middleman" } & Pick<
                          Middleman,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Phone"
                          | "Email"
                          | "Note"
                        > & {
                            attachments: Array<
                              { __typename?: "MiddlemanAttachment" } & Pick<
                                MiddlemanAttachment,
                                | "Id"
                                | "CreatedAt"
                                | "UpdatedAt"
                                | "DeletedAt"
                                | "Name"
                              >
                            >;
                          }
                      >;
                    };
                  }
              >;
            }
        >;
      };
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetCreatedByOnDeletedMiddlemanMutationVariables = Exact<{
  input: SetCreatedByOnDeletedMiddlemanInput;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedMiddlemanAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedByOnDeletedMiddlemanMutation = {
  __typename?: "Mutation";
} & {
  setCreatedByOnDeletedMiddleman: { __typename?: "DeletedMiddleman" } & Pick<
    DeletedMiddleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type AddAttachmentsToDeletedMiddlemanMutationVariables = Exact<{
  input: AddAttachmentsToDeletedMiddlemanInput;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addAttachmentsToDeletedMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addAttachmentsToDeletedMiddlemanAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddAttachmentsToDeletedMiddlemanMutation = {
  __typename?: "Mutation";
} & {
  addAttachmentsToDeletedMiddleman: { __typename?: "DeletedMiddleman" } & Pick<
    DeletedMiddleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetAttachmentsOnDeletedMiddlemanMutationVariables = Exact<{
  input: SetAttachmentsOnDeletedMiddlemanInput;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setAttachmentsOnDeletedMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setAttachmentsOnDeletedMiddlemanAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetAttachmentsOnDeletedMiddlemanMutation = {
  __typename?: "Mutation";
} & {
  setAttachmentsOnDeletedMiddleman: { __typename?: "DeletedMiddleman" } & Pick<
    DeletedMiddleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveCreatedByFromDeletedMiddlemanMutationVariables = Exact<{
  input: RemoveCreatedByFromDeletedMiddlemanInput;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedMiddlemanAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedByFromDeletedMiddlemanMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedByFromDeletedMiddleman: {
    __typename?: "DeletedMiddleman";
  } & Pick<
    DeletedMiddleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveAttachmentsFromDeletedMiddlemanMutationVariables = Exact<{
  input: RemoveAttachmentsFromDeletedMiddlemanInput;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeAttachmentsFromDeletedMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeAttachmentsFromDeletedMiddlemanAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveAttachmentsFromDeletedMiddlemanMutation = {
  __typename?: "Mutation";
} & {
  removeAttachmentsFromDeletedMiddleman: {
    __typename?: "DeletedMiddleman";
  } & Pick<
    DeletedMiddleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetCreatedByOnDeletedWorkerMutationVariables = Exact<{
  input: SetCreatedByOnDeletedWorkerInput;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnDeletedWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedWorkerAttachmentsSorting?: Maybe<Array<AttachmentSort>>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedByOnDeletedWorkerMutation = {
  __typename?: "Mutation";
} & {
  setCreatedByOnDeletedWorker: { __typename?: "DeletedWorker" } & Pick<
    DeletedWorker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type AddAttachmentsToDeletedWorkerMutationVariables = Exact<{
  input: AddAttachmentsToDeletedWorkerInput;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addAttachmentsToDeletedWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  addAttachmentsToDeletedWorkerAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddAttachmentsToDeletedWorkerMutation = {
  __typename?: "Mutation";
} & {
  addAttachmentsToDeletedWorker: { __typename?: "DeletedWorker" } & Pick<
    DeletedWorker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetAttachmentsOnDeletedWorkerMutationVariables = Exact<{
  input: SetAttachmentsOnDeletedWorkerInput;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setAttachmentsOnDeletedWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  setAttachmentsOnDeletedWorkerAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetAttachmentsOnDeletedWorkerMutation = {
  __typename?: "Mutation";
} & {
  setAttachmentsOnDeletedWorker: { __typename?: "DeletedWorker" } & Pick<
    DeletedWorker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveCreatedByFromDeletedWorkerMutationVariables = Exact<{
  input: RemoveCreatedByFromDeletedWorkerInput;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromDeletedWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedWorkerAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedByFromDeletedWorkerMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedByFromDeletedWorker: { __typename?: "DeletedWorker" } & Pick<
    DeletedWorker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type RemoveAttachmentsFromDeletedWorkerMutationVariables = Exact<{
  input: RemoveAttachmentsFromDeletedWorkerInput;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeAttachmentsFromDeletedWorkerAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeAttachmentsFromDeletedWorkerAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveAttachmentsFromDeletedWorkerMutation = {
  __typename?: "Mutation";
} & {
  removeAttachmentsFromDeletedWorker: { __typename?: "DeletedWorker" } & Pick<
    DeletedWorker,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
    | "Profession"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
      deletedBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    };
};

export type SetCreatedByOnMiddlemanMutationVariables = Exact<{
  input: SetCreatedByOnMiddlemanInput;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnMiddlemanAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetCreatedByOnMiddlemanMutation = { __typename?: "Mutation" } & {
  setCreatedByOnMiddleman: { __typename?: "Middleman" } & Pick<
    Middleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type AddAttachmentsToMiddlemanMutationVariables = Exact<{
  input: AddAttachmentsToMiddlemanInput;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addAttachmentsToMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addAttachmentsToMiddlemanAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type AddAttachmentsToMiddlemanMutation = { __typename?: "Mutation" } & {
  addAttachmentsToMiddleman: { __typename?: "Middleman" } & Pick<
    Middleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type SetAttachmentsOnMiddlemanMutationVariables = Exact<{
  input: SetAttachmentsOnMiddlemanInput;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setAttachmentsOnMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setAttachmentsOnMiddlemanAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type SetAttachmentsOnMiddlemanMutation = { __typename?: "Mutation" } & {
  setAttachmentsOnMiddleman: { __typename?: "Middleman" } & Pick<
    Middleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type RemoveCreatedByFromMiddlemanMutationVariables = Exact<{
  input: RemoveCreatedByFromMiddlemanInput;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromMiddlemanAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveCreatedByFromMiddlemanMutation = {
  __typename?: "Mutation";
} & {
  removeCreatedByFromMiddleman: { __typename?: "Middleman" } & Pick<
    Middleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type RemoveAttachmentsFromMiddlemanMutationVariables = Exact<{
  input: RemoveAttachmentsFromMiddlemanInput;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeAttachmentsFromMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeAttachmentsFromMiddlemanAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
}>;

export type RemoveAttachmentsFromMiddlemanMutation = {
  __typename?: "Mutation";
} & {
  removeAttachmentsFromMiddleman: { __typename?: "Middleman" } & Pick<
    Middleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CreateOneMiddlemanMutationVariables = Exact<{
  input: CreateOneMiddlemanInput;
  createOneMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createOneMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneMiddlemanAttachmentsSorting?: Maybe<Array<MiddlemanAttachmentSort>>;
}>;

export type CreateOneMiddlemanMutation = { __typename?: "Mutation" } & {
  createOneMiddleman: { __typename?: "Middleman" } & Pick<
    Middleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CreateManyMiddlemenMutationVariables = Exact<{
  input: CreateManyMiddlemenInput;
  createManyMiddlemenCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyMiddlemenCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyMiddlemenCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyMiddlemenCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyMiddlemenCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createManyMiddlemenCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyMiddlemenCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyMiddlemenCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyMiddlemenCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyMiddlemenCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyMiddlemenCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyMiddlemenCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyMiddlemenCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyMiddlemenCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyMiddlemenCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyMiddlemenCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyMiddlemenCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyMiddlemenCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyMiddlemenCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyMiddlemenAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyMiddlemenAttachmentsSorting?: Maybe<Array<MiddlemanAttachmentSort>>;
}>;

export type CreateManyMiddlemenMutation = { __typename?: "Mutation" } & {
  createManyMiddlemen: Array<
    { __typename?: "Middleman" } & Pick<
      Middleman,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Phone"
      | "Email"
      | "Note"
    > & {
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
              };
            }
        >;
        attachments: Array<
          { __typename?: "MiddlemanAttachment" } & Pick<
            MiddlemanAttachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
      }
  >;
};

export type UpdateOneMiddlemanMutationVariables = Exact<{
  input: UpdateOneMiddlemanInput;
  updateOneMiddlemanCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneMiddlemanCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneMiddlemanCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneMiddlemanCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneMiddlemanCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  updateOneMiddlemanCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneMiddlemanCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneMiddlemanCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneMiddlemanCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneMiddlemanCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneMiddlemanCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneMiddlemanCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneMiddlemanAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneMiddlemanAttachmentsSorting?: Maybe<Array<MiddlemanAttachmentSort>>;
}>;

export type UpdateOneMiddlemanMutation = { __typename?: "Mutation" } & {
  updateOneMiddleman: { __typename?: "Middleman" } & Pick<
    Middleman,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "MiddlemanAttachment" } & Pick<
          MiddlemanAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type UpdateManyMiddlemenMutationVariables = Exact<{
  input: UpdateManyMiddlemenInput;
}>;

export type UpdateManyMiddlemenMutation = { __typename?: "Mutation" } & {
  updateManyMiddlemen: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneMiddlemanMutationVariables = Exact<{
  input: DeleteOneMiddlemanInput;
}>;

export type DeleteOneMiddlemanMutation = { __typename?: "Mutation" } & {
  deleteOneMiddleman: { __typename?: "MiddlemanDeleteResponse" } & Pick<
    MiddlemanDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Phone"
    | "Email"
    | "Note"
  >;
};

export type DeleteManyMiddlemenMutationVariables = Exact<{
  input: DeleteManyMiddlemenInput;
}>;

export type DeleteManyMiddlemenMutation = { __typename?: "Mutation" } & {
  deleteManyMiddlemen: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type CreateOneMiddlemanAttachmentMutationVariables = Exact<{
  input: CreateOneMiddlemanAttachmentInput;
}>;

export type CreateOneMiddlemanAttachmentMutation = {
  __typename?: "Mutation";
} & {
  createOneMiddlemanAttachment: { __typename?: "MiddlemanAttachment" } & Pick<
    MiddlemanAttachment,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type CreateManyMiddlemanAttachmentsMutationVariables = Exact<{
  input: CreateManyMiddlemanAttachmentsInput;
}>;

export type CreateManyMiddlemanAttachmentsMutation = {
  __typename?: "Mutation";
} & {
  createManyMiddlemanAttachments: Array<
    { __typename?: "MiddlemanAttachment" } & Pick<
      MiddlemanAttachment,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
    >
  >;
};

export type UpdateOneMiddlemanAttachmentMutationVariables = Exact<{
  input: UpdateOneMiddlemanAttachmentInput;
}>;

export type UpdateOneMiddlemanAttachmentMutation = {
  __typename?: "Mutation";
} & {
  updateOneMiddlemanAttachment: { __typename?: "MiddlemanAttachment" } & Pick<
    MiddlemanAttachment,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type UpdateManyMiddlemanAttachmentsMutationVariables = Exact<{
  input: UpdateManyMiddlemanAttachmentsInput;
}>;

export type UpdateManyMiddlemanAttachmentsMutation = {
  __typename?: "Mutation";
} & {
  updateManyMiddlemanAttachments: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneMiddlemanAttachmentMutationVariables = Exact<{
  input: DeleteOneMiddlemanAttachmentInput;
}>;

export type DeleteOneMiddlemanAttachmentMutation = {
  __typename?: "Mutation";
} & {
  deleteOneMiddlemanAttachment: {
    __typename?: "MiddlemanAttachmentDeleteResponse";
  } & Pick<
    MiddlemanAttachmentDeleteResponse,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type DeleteManyMiddlemanAttachmentsMutationVariables = Exact<{
  input: DeleteManyMiddlemanAttachmentsInput;
}>;

export type DeleteManyMiddlemanAttachmentsMutation = {
  __typename?: "Mutation";
} & {
  deleteManyMiddlemanAttachments: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type SetCreatedByOnCarMutationVariables = Exact<{
  input: SetCreatedByOnCarInput;
  setCreatedByOnCarCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setCreatedByOnCarCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCarCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setCreatedByOnCarCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setCreatedByOnCarCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setCreatedByOnCarCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCarCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCarCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setCreatedByOnCarCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setCreatedByOnCarCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setCreatedByOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setCreatedByOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setCreatedByOnCarCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setCreatedByOnCarCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setCreatedByOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setCreatedByOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setCreatedByOnCarAttachmentsFilter?: Maybe<CarAttachmentFilter>;
  setCreatedByOnCarAttachmentsSorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type SetCreatedByOnCarMutation = { __typename?: "Mutation" } & {
  setCreatedByOnCar: { __typename?: "Car" } & Pick<
    Car,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Evidence"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "CarAttachment" } & Pick<
          CarAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type AddAttachmentsToCarMutationVariables = Exact<{
  input: AddAttachmentsToCarInput;
  addAttachmentsToCarCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  addAttachmentsToCarCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToCarCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  addAttachmentsToCarCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  addAttachmentsToCarCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  addAttachmentsToCarCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToCarCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  addAttachmentsToCarCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  addAttachmentsToCarCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  addAttachmentsToCarCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  addAttachmentsToCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  addAttachmentsToCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  addAttachmentsToCarCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  addAttachmentsToCarCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  addAttachmentsToCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  addAttachmentsToCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  addAttachmentsToCarAttachmentsFilter?: Maybe<CarAttachmentFilter>;
  addAttachmentsToCarAttachmentsSorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type AddAttachmentsToCarMutation = { __typename?: "Mutation" } & {
  addAttachmentsToCar: { __typename?: "Car" } & Pick<
    Car,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Evidence"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "CarAttachment" } & Pick<
          CarAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type SetAttachmentsOnCarMutationVariables = Exact<{
  input: SetAttachmentsOnCarInput;
  setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  setAttachmentsOnCarCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  setAttachmentsOnCarCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnCarCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  setAttachmentsOnCarCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  setAttachmentsOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  setAttachmentsOnCarCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  setAttachmentsOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  setAttachmentsOnCarAttachmentsFilter?: Maybe<CarAttachmentFilter>;
  setAttachmentsOnCarAttachmentsSorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type SetAttachmentsOnCarMutation = { __typename?: "Mutation" } & {
  setAttachmentsOnCar: { __typename?: "Car" } & Pick<
    Car,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Evidence"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "CarAttachment" } & Pick<
          CarAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type RemoveCreatedByFromCarMutationVariables = Exact<{
  input: RemoveCreatedByFromCarInput;
  removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeCreatedByFromCarCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCarCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCarCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeCreatedByFromCarCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeCreatedByFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeCreatedByFromCarCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeCreatedByFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeCreatedByFromCarAttachmentsFilter?: Maybe<CarAttachmentFilter>;
  removeCreatedByFromCarAttachmentsSorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type RemoveCreatedByFromCarMutation = { __typename?: "Mutation" } & {
  removeCreatedByFromCar: { __typename?: "Car" } & Pick<
    Car,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Evidence"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "CarAttachment" } & Pick<
          CarAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type RemoveAttachmentsFromCarMutationVariables = Exact<{
  input: RemoveAttachmentsFromCarInput;
  removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromCarCreatedByCreatedByContactsSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromCarCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  removeAttachmentsFromCarAttachmentsFilter?: Maybe<CarAttachmentFilter>;
  removeAttachmentsFromCarAttachmentsSorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type RemoveAttachmentsFromCarMutation = { __typename?: "Mutation" } & {
  removeAttachmentsFromCar: { __typename?: "Car" } & Pick<
    Car,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Evidence"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "CarAttachment" } & Pick<
          CarAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CreateOneCarMutationVariables = Exact<{
  input: CreateOneCarInput;
  createOneCarCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createOneCarCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCarCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createOneCarCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createOneCarCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createOneCarCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCarCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createOneCarCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createOneCarCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createOneCarCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createOneCarCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createOneCarCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createOneCarCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createOneCarCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createOneCarCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createOneCarAttachmentsFilter?: Maybe<CarAttachmentFilter>;
  createOneCarAttachmentsSorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type CreateOneCarMutation = { __typename?: "Mutation" } & {
  createOneCar: { __typename?: "Car" } & Pick<
    Car,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Evidence"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "CarAttachment" } & Pick<
          CarAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type CreateManyCarsMutationVariables = Exact<{
  input: CreateManyCarsInput;
  createManyCarsCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  createManyCarsCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCarsCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  createManyCarsCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  createManyCarsCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  createManyCarsCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCarsCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  createManyCarsCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  createManyCarsCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  createManyCarsCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  createManyCarsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  createManyCarsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  createManyCarsCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  createManyCarsCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  createManyCarsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  createManyCarsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  createManyCarsCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  createManyCarsCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  createManyCarsCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  createManyCarsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  createManyCarsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  createManyCarsAttachmentsFilter?: Maybe<CarAttachmentFilter>;
  createManyCarsAttachmentsSorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type CreateManyCarsMutation = { __typename?: "Mutation" } & {
  createManyCars: Array<
    { __typename?: "Car" } & Pick<
      Car,
      | "Id"
      | "CreatedAt"
      | "UpdatedAt"
      | "DeletedAt"
      | "Name"
      | "Description"
      | "Evidence"
      | "Type"
    > & {
        createdBy?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            | "Id"
            | "CreatedAt"
            | "UpdatedAt"
            | "DeletedAt"
            | "Username"
            | "Email"
            | "Status"
            | "Role"
          > & {
              createdCompanies: {
                __typename?: "UserCreatedCompaniesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Company" } & Pick<
                    Company,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Webpage"
                    | "Address"
                    | "Focus"
                    | "Language"
                  > & {
                      contact?: Maybe<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                      histories: {
                        __typename?: "CompanyHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "Contact" } & Pick<
                            Contact,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "ContactPerson"
                            | "Email"
                            | "Phone"
                            | "Description"
                            | "Interest"
                          >
                        >;
                      };
                    }
                >;
              };
              createdProjects: {
                __typename?: "UserCreatedProjectsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Project" } & Pick<
                    Project,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Address"
                    | "Type"
                    | "Language"
                    | "Manager"
                    | "ManagerContact"
                  > & {
                      projectChange?: Maybe<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                      histories: {
                        __typename?: "ProjectHistoriesConnection";
                      } & {
                        pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                          OffsetPageInfo,
                          "hasNextPage" | "hasPreviousPage"
                        >;
                        nodes: Array<
                          { __typename?: "ProjectChange" } & Pick<
                            ProjectChange,
                            | "Id"
                            | "CreatedAt"
                            | "UpdatedAt"
                            | "DeletedAt"
                            | "Description"
                            | "State"
                          >
                        >;
                      };
                    }
                >;
              };
              contacts: { __typename?: "UserContactsConnection" } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              projectChanges: {
                __typename?: "UserProjectChangesConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contact" } & Pick<
                    Contact,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "ContactPerson"
                    | "Email"
                    | "Phone"
                    | "Description"
                    | "Interest"
                  > & {
                      company?: Maybe<
                        { __typename?: "Company" } & Pick<
                          Company,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                          | "Webpage"
                          | "Address"
                          | "Focus"
                          | "Language"
                        > & {
                            histories: {
                              __typename?: "CompanyHistoriesConnection";
                            } & {
                              pageInfo: {
                                __typename?: "OffsetPageInfo";
                              } & Pick<
                                OffsetPageInfo,
                                "hasNextPage" | "hasPreviousPage"
                              >;
                            };
                          }
                      >;
                    }
                >;
              };
              createdWorkers: {
                __typename?: "UserCreatedWorkersConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Worker" } & Pick<
                    Worker,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                    | "Profession"
                    | "Type"
                  > & {
                      attachments: Array<
                        { __typename?: "Attachment" } & Pick<
                          Attachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdContracts: {
                __typename?: "UserCreatedContractsConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Contract" } & Pick<
                    Contract,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Description"
                    | "Type"
                    | "Year"
                  > & {
                      attachments: Array<
                        { __typename?: "ContractAttachment" } & Pick<
                          ContractAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
              createdMiddlemans: {
                __typename?: "UserCreatedMiddlemansConnection";
              } & {
                pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                  OffsetPageInfo,
                  "hasNextPage" | "hasPreviousPage"
                >;
                nodes: Array<
                  { __typename?: "Middleman" } & Pick<
                    Middleman,
                    | "Id"
                    | "CreatedAt"
                    | "UpdatedAt"
                    | "DeletedAt"
                    | "Name"
                    | "Phone"
                    | "Email"
                    | "Note"
                  > & {
                      attachments: Array<
                        { __typename?: "MiddlemanAttachment" } & Pick<
                          MiddlemanAttachment,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Name"
                        >
                      >;
                    }
                >;
              };
            }
        >;
        attachments: Array<
          { __typename?: "CarAttachment" } & Pick<
            CarAttachment,
            "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
          >
        >;
      }
  >;
};

export type UpdateOneCarMutationVariables = Exact<{
  input: UpdateOneCarInput;
  updateOneCarCreatedByCreatedByCreatedCompaniesPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByCreatedCompaniesFilter?: Maybe<CompanyFilter>;
  updateOneCarCreatedByCreatedByCreatedCompaniesSorting?: Maybe<
    Array<CompanySort>
  >;
  updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCarCreatedByCreatedByCreatedProjectsPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByCreatedProjectsFilter?: Maybe<ProjectFilter>;
  updateOneCarCreatedByCreatedByCreatedProjectsSorting?: Maybe<
    Array<ProjectSort>
  >;
  updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter?: Maybe<ProjectChangeFilter>;
  updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting?: Maybe<
    Array<ProjectChangeSort>
  >;
  updateOneCarCreatedByCreatedByContactsPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByContactsFilter?: Maybe<ContactFilter>;
  updateOneCarCreatedByCreatedByContactsSorting?: Maybe<Array<ContactSort>>;
  updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCarCreatedByCreatedByProjectChangesPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByProjectChangesFilter?: Maybe<ContactFilter>;
  updateOneCarCreatedByCreatedByProjectChangesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter?: Maybe<ContactFilter>;
  updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting?: Maybe<
    Array<ContactSort>
  >;
  updateOneCarCreatedByCreatedByCreatedWorkersPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByCreatedWorkersFilter?: Maybe<WorkerFilter>;
  updateOneCarCreatedByCreatedByCreatedWorkersSorting?: Maybe<
    Array<WorkerSort>
  >;
  updateOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter?: Maybe<AttachmentFilter>;
  updateOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting?: Maybe<
    Array<AttachmentSort>
  >;
  updateOneCarCreatedByCreatedByCreatedContractsPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByCreatedContractsFilter?: Maybe<ContractFilter>;
  updateOneCarCreatedByCreatedByCreatedContractsSorting?: Maybe<
    Array<ContractSort>
  >;
  updateOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter?: Maybe<ContractAttachmentFilter>;
  updateOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting?: Maybe<
    Array<ContractAttachmentSort>
  >;
  updateOneCarCreatedByCreatedByCreatedMiddlemansPaging?: Maybe<OffsetPaging>;
  updateOneCarCreatedByCreatedByCreatedMiddlemansFilter?: Maybe<MiddlemanFilter>;
  updateOneCarCreatedByCreatedByCreatedMiddlemansSorting?: Maybe<
    Array<MiddlemanSort>
  >;
  updateOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter?: Maybe<MiddlemanAttachmentFilter>;
  updateOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting?: Maybe<
    Array<MiddlemanAttachmentSort>
  >;
  updateOneCarAttachmentsFilter?: Maybe<CarAttachmentFilter>;
  updateOneCarAttachmentsSorting?: Maybe<Array<CarAttachmentSort>>;
}>;

export type UpdateOneCarMutation = { __typename?: "Mutation" } & {
  updateOneCar: { __typename?: "Car" } & Pick<
    Car,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Evidence"
    | "Type"
  > & {
      createdBy?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "Id"
          | "CreatedAt"
          | "UpdatedAt"
          | "DeletedAt"
          | "Username"
          | "Email"
          | "Status"
          | "Role"
        > & {
            createdCompanies: {
              __typename?: "UserCreatedCompaniesConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Company" } & Pick<
                  Company,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Webpage"
                  | "Address"
                  | "Focus"
                  | "Language"
                > & {
                    contact?: Maybe<
                      { __typename?: "Contact" } & Pick<
                        Contact,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "ContactPerson"
                        | "Email"
                        | "Phone"
                        | "Description"
                        | "Interest"
                      >
                    >;
                    histories: { __typename?: "CompanyHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "Contact" } & Pick<
                          Contact,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "ContactPerson"
                          | "Email"
                          | "Phone"
                          | "Description"
                          | "Interest"
                        >
                      >;
                    };
                  }
              >;
            };
            createdProjects: {
              __typename?: "UserCreatedProjectsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Project" } & Pick<
                  Project,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Address"
                  | "Type"
                  | "Language"
                  | "Manager"
                  | "ManagerContact"
                > & {
                    projectChange?: Maybe<
                      { __typename?: "ProjectChange" } & Pick<
                        ProjectChange,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Description"
                        | "State"
                      >
                    >;
                    histories: { __typename?: "ProjectHistoriesConnection" } & {
                      pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                        OffsetPageInfo,
                        "hasNextPage" | "hasPreviousPage"
                      >;
                      nodes: Array<
                        { __typename?: "ProjectChange" } & Pick<
                          ProjectChange,
                          | "Id"
                          | "CreatedAt"
                          | "UpdatedAt"
                          | "DeletedAt"
                          | "Description"
                          | "State"
                        >
                      >;
                    };
                  }
              >;
            };
            contacts: { __typename?: "UserContactsConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            projectChanges: { __typename?: "UserProjectChangesConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contact" } & Pick<
                  Contact,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "ContactPerson"
                  | "Email"
                  | "Phone"
                  | "Description"
                  | "Interest"
                > & {
                    company?: Maybe<
                      { __typename?: "Company" } & Pick<
                        Company,
                        | "Id"
                        | "CreatedAt"
                        | "UpdatedAt"
                        | "DeletedAt"
                        | "Name"
                        | "Webpage"
                        | "Address"
                        | "Focus"
                        | "Language"
                      > & {
                          histories: {
                            __typename?: "CompanyHistoriesConnection";
                          } & {
                            pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                              OffsetPageInfo,
                              "hasNextPage" | "hasPreviousPage"
                            >;
                          };
                        }
                    >;
                  }
              >;
            };
            createdWorkers: { __typename?: "UserCreatedWorkersConnection" } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Worker" } & Pick<
                  Worker,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                  | "Profession"
                  | "Type"
                > & {
                    attachments: Array<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdContracts: {
              __typename?: "UserCreatedContractsConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Contract" } & Pick<
                  Contract,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Description"
                  | "Type"
                  | "Year"
                > & {
                    attachments: Array<
                      { __typename?: "ContractAttachment" } & Pick<
                        ContractAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
            createdMiddlemans: {
              __typename?: "UserCreatedMiddlemansConnection";
            } & {
              pageInfo: { __typename?: "OffsetPageInfo" } & Pick<
                OffsetPageInfo,
                "hasNextPage" | "hasPreviousPage"
              >;
              nodes: Array<
                { __typename?: "Middleman" } & Pick<
                  Middleman,
                  | "Id"
                  | "CreatedAt"
                  | "UpdatedAt"
                  | "DeletedAt"
                  | "Name"
                  | "Phone"
                  | "Email"
                  | "Note"
                > & {
                    attachments: Array<
                      { __typename?: "MiddlemanAttachment" } & Pick<
                        MiddlemanAttachment,
                        "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
                      >
                    >;
                  }
              >;
            };
          }
      >;
      attachments: Array<
        { __typename?: "CarAttachment" } & Pick<
          CarAttachment,
          "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
        >
      >;
    };
};

export type UpdateManyCarsMutationVariables = Exact<{
  input: UpdateManyCarsInput;
}>;

export type UpdateManyCarsMutation = { __typename?: "Mutation" } & {
  updateManyCars: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneCarMutationVariables = Exact<{
  input: DeleteOneCarInput;
}>;

export type DeleteOneCarMutation = { __typename?: "Mutation" } & {
  deleteOneCar: { __typename?: "CarDeleteResponse" } & Pick<
    CarDeleteResponse,
    | "Id"
    | "CreatedAt"
    | "UpdatedAt"
    | "DeletedAt"
    | "Name"
    | "Description"
    | "Evidence"
    | "Type"
  >;
};

export type DeleteManyCarsMutationVariables = Exact<{
  input: DeleteManyCarsInput;
}>;

export type DeleteManyCarsMutation = { __typename?: "Mutation" } & {
  deleteManyCars: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export type CreateOneCarAttachmentMutationVariables = Exact<{
  input: CreateOneCarAttachmentInput;
}>;

export type CreateOneCarAttachmentMutation = { __typename?: "Mutation" } & {
  createOneCarAttachment: { __typename?: "CarAttachment" } & Pick<
    CarAttachment,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type CreateManyCarAttachmentsMutationVariables = Exact<{
  input: CreateManyCarAttachmentsInput;
}>;

export type CreateManyCarAttachmentsMutation = { __typename?: "Mutation" } & {
  createManyCarAttachments: Array<
    { __typename?: "CarAttachment" } & Pick<
      CarAttachment,
      "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
    >
  >;
};

export type UpdateOneCarAttachmentMutationVariables = Exact<{
  input: UpdateOneCarAttachmentInput;
}>;

export type UpdateOneCarAttachmentMutation = { __typename?: "Mutation" } & {
  updateOneCarAttachment: { __typename?: "CarAttachment" } & Pick<
    CarAttachment,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type UpdateManyCarAttachmentsMutationVariables = Exact<{
  input: UpdateManyCarAttachmentsInput;
}>;

export type UpdateManyCarAttachmentsMutation = { __typename?: "Mutation" } & {
  updateManyCarAttachments: { __typename?: "UpdateManyResponse" } & Pick<
    UpdateManyResponse,
    "updatedCount"
  >;
};

export type DeleteOneCarAttachmentMutationVariables = Exact<{
  input: DeleteOneCarAttachmentInput;
}>;

export type DeleteOneCarAttachmentMutation = { __typename?: "Mutation" } & {
  deleteOneCarAttachment: { __typename?: "CarAttachmentDeleteResponse" } & Pick<
    CarAttachmentDeleteResponse,
    "Id" | "CreatedAt" | "UpdatedAt" | "DeletedAt" | "Name"
  >;
};

export type DeleteManyCarAttachmentsMutationVariables = Exact<{
  input: DeleteManyCarAttachmentsInput;
}>;

export type DeleteManyCarAttachmentsMutation = { __typename?: "Mutation" } & {
  deleteManyCarAttachments: { __typename?: "DeleteManyResponse" } & Pick<
    DeleteManyResponse,
    "deletedCount"
  >;
};

export const MeQueryDocument = gql`
  query meQuery(
    $meCreatedCompaniesPaging: OffsetPaging
    $meCreatedCompaniesFilter: CompanyFilter
    $meCreatedCompaniesSorting: [CompanySort!]
    $meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $meCreatedProjectsPaging: OffsetPaging
    $meCreatedProjectsFilter: ProjectFilter
    $meCreatedProjectsSorting: [ProjectSort!]
    $meCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $meCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $meCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $meContactsPaging: OffsetPaging
    $meContactsFilter: ContactFilter
    $meContactsSorting: [ContactSort!]
    $meContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $meContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $meContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $meProjectChangesPaging: OffsetPaging
    $meProjectChangesFilter: ContactFilter
    $meProjectChangesSorting: [ContactSort!]
    $meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $meCreatedWorkersPaging: OffsetPaging
    $meCreatedWorkersFilter: WorkerFilter
    $meCreatedWorkersSorting: [WorkerSort!]
    $meCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $meCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $meCreatedContractsPaging: OffsetPaging
    $meCreatedContractsFilter: ContractFilter
    $meCreatedContractsSorting: [ContractSort!]
    $meCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $meCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $meCreatedMiddlemansPaging: OffsetPaging
    $meCreatedMiddlemansFilter: MiddlemanFilter
    $meCreatedMiddlemansSorting: [MiddlemanSort!]
    $meCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $meCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    me {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $meCreatedCompaniesPaging
        filter: $meCreatedCompaniesFilter
        sorting: $meCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $meCreatedProjectsPaging
        filter: $meCreatedProjectsFilter
        sorting: $meCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $meCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $meCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $meCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $meContactsPaging
        filter: $meContactsFilter
        sorting: $meContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $meContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $meContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $meContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $meProjectChangesPaging
        filter: $meProjectChangesFilter
        sorting: $meProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $meCreatedWorkersPaging
        filter: $meCreatedWorkersFilter
        sorting: $meCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $meCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $meCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $meCreatedContractsPaging
        filter: $meCreatedContractsFilter
        sorting: $meCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $meCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $meCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $meCreatedMiddlemansPaging
        filter: $meCreatedMiddlemansFilter
        sorting: $meCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $meCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $meCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery({
 *   meCreatedCompaniesPaging: // value for 'meCreatedCompaniesPaging'
 *   meCreatedCompaniesFilter: // value for 'meCreatedCompaniesFilter'
 *   meCreatedCompaniesSorting: // value for 'meCreatedCompaniesSorting'
 *   meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'meCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   meCreatedProjectsPaging: // value for 'meCreatedProjectsPaging'
 *   meCreatedProjectsFilter: // value for 'meCreatedProjectsFilter'
 *   meCreatedProjectsSorting: // value for 'meCreatedProjectsSorting'
 *   meCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'meCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   meCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'meCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   meCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'meCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   meContactsPaging: // value for 'meContactsPaging'
 *   meContactsFilter: // value for 'meContactsFilter'
 *   meContactsSorting: // value for 'meContactsSorting'
 *   meContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'meContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   meContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'meContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   meContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'meContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   meProjectChangesPaging: // value for 'meProjectChangesPaging'
 *   meProjectChangesFilter: // value for 'meProjectChangesFilter'
 *   meProjectChangesSorting: // value for 'meProjectChangesSorting'
 *   meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'meProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   meCreatedWorkersPaging: // value for 'meCreatedWorkersPaging'
 *   meCreatedWorkersFilter: // value for 'meCreatedWorkersFilter'
 *   meCreatedWorkersSorting: // value for 'meCreatedWorkersSorting'
 *   meCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'meCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   meCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'meCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   meCreatedContractsPaging: // value for 'meCreatedContractsPaging'
 *   meCreatedContractsFilter: // value for 'meCreatedContractsFilter'
 *   meCreatedContractsSorting: // value for 'meCreatedContractsSorting'
 *   meCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'meCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   meCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'meCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   meCreatedMiddlemansPaging: // value for 'meCreatedMiddlemansPaging'
 *   meCreatedMiddlemansFilter: // value for 'meCreatedMiddlemansFilter'
 *   meCreatedMiddlemansSorting: // value for 'meCreatedMiddlemansSorting'
 *   meCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'meCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   meCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'meCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useMeQuery(
  variables:
    | MeQueryVariables
    | VueCompositionApi.Ref<MeQueryVariables>
    | ReactiveFunction<MeQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(
    MeQueryDocument,
    variables,
    options
  );
}
export type MeQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const UserRolesQueryDocument = gql`
  query userRolesQuery {
    userRoles
  }
`;

/**
 * __useUserRolesQuery__
 *
 * To run a query within a Vue component, call `useUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRolesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserRolesQuery();
 */
export function useUserRolesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        UserRolesQuery,
        UserRolesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          UserRolesQuery,
          UserRolesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          UserRolesQuery,
          UserRolesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<UserRolesQuery, UserRolesQueryVariables>(
    UserRolesQueryDocument,
    {},
    options
  );
}
export type UserRolesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<UserRolesQuery, UserRolesQueryVariables>;
export const UserQueryDocument = gql`
  query userQuery(
    $id: String!
    $userCreatedCompaniesPaging: OffsetPaging
    $userCreatedCompaniesFilter: CompanyFilter
    $userCreatedCompaniesSorting: [CompanySort!]
    $userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $userCreatedProjectsPaging: OffsetPaging
    $userCreatedProjectsFilter: ProjectFilter
    $userCreatedProjectsSorting: [ProjectSort!]
    $userCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $userCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $userCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $userContactsPaging: OffsetPaging
    $userContactsFilter: ContactFilter
    $userContactsSorting: [ContactSort!]
    $userContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $userContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $userContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $userProjectChangesPaging: OffsetPaging
    $userProjectChangesFilter: ContactFilter
    $userProjectChangesSorting: [ContactSort!]
    $userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $userCreatedWorkersPaging: OffsetPaging
    $userCreatedWorkersFilter: WorkerFilter
    $userCreatedWorkersSorting: [WorkerSort!]
    $userCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $userCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $userCreatedContractsPaging: OffsetPaging
    $userCreatedContractsFilter: ContractFilter
    $userCreatedContractsSorting: [ContractSort!]
    $userCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $userCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $userCreatedMiddlemansPaging: OffsetPaging
    $userCreatedMiddlemansFilter: MiddlemanFilter
    $userCreatedMiddlemansSorting: [MiddlemanSort!]
    $userCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $userCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    user(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $userCreatedCompaniesPaging
        filter: $userCreatedCompaniesFilter
        sorting: $userCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $userCreatedProjectsPaging
        filter: $userCreatedProjectsFilter
        sorting: $userCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $userCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $userCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $userCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $userContactsPaging
        filter: $userContactsFilter
        sorting: $userContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $userContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $userContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $userContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $userProjectChangesPaging
        filter: $userProjectChangesFilter
        sorting: $userProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $userCreatedWorkersPaging
        filter: $userCreatedWorkersFilter
        sorting: $userCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $userCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $userCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $userCreatedContractsPaging
        filter: $userCreatedContractsFilter
        sorting: $userCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $userCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $userCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $userCreatedMiddlemansPaging
        filter: $userCreatedMiddlemansFilter
        sorting: $userCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $userCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $userCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a Vue component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserQuery({
 *   id: // value for 'id'
 *   userCreatedCompaniesPaging: // value for 'userCreatedCompaniesPaging'
 *   userCreatedCompaniesFilter: // value for 'userCreatedCompaniesFilter'
 *   userCreatedCompaniesSorting: // value for 'userCreatedCompaniesSorting'
 *   userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'userCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   userCreatedProjectsPaging: // value for 'userCreatedProjectsPaging'
 *   userCreatedProjectsFilter: // value for 'userCreatedProjectsFilter'
 *   userCreatedProjectsSorting: // value for 'userCreatedProjectsSorting'
 *   userCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'userCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   userCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'userCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   userCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'userCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   userContactsPaging: // value for 'userContactsPaging'
 *   userContactsFilter: // value for 'userContactsFilter'
 *   userContactsSorting: // value for 'userContactsSorting'
 *   userContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'userContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   userContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'userContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   userContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'userContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   userProjectChangesPaging: // value for 'userProjectChangesPaging'
 *   userProjectChangesFilter: // value for 'userProjectChangesFilter'
 *   userProjectChangesSorting: // value for 'userProjectChangesSorting'
 *   userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'userProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   userCreatedWorkersPaging: // value for 'userCreatedWorkersPaging'
 *   userCreatedWorkersFilter: // value for 'userCreatedWorkersFilter'
 *   userCreatedWorkersSorting: // value for 'userCreatedWorkersSorting'
 *   userCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'userCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   userCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'userCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   userCreatedContractsPaging: // value for 'userCreatedContractsPaging'
 *   userCreatedContractsFilter: // value for 'userCreatedContractsFilter'
 *   userCreatedContractsSorting: // value for 'userCreatedContractsSorting'
 *   userCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'userCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   userCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'userCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   userCreatedMiddlemansPaging: // value for 'userCreatedMiddlemansPaging'
 *   userCreatedMiddlemansFilter: // value for 'userCreatedMiddlemansFilter'
 *   userCreatedMiddlemansSorting: // value for 'userCreatedMiddlemansSorting'
 *   userCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'userCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   userCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'userCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useUserQuery(
  variables:
    | UserQueryVariables
    | VueCompositionApi.Ref<UserQueryVariables>
    | ReactiveFunction<UserQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<UserQuery, UserQueryVariables>(
    UserQueryDocument,
    variables,
    options
  );
}
export type UserQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<UserQuery, UserQueryVariables>;
export const UsersQueryDocument = gql`
  query usersQuery(
    $paging: OffsetPaging
    $filter: UserFilter
    $sorting: [UserSort!]
    $usersNodesNodesCreatedCompaniesPaging: OffsetPaging
    $usersNodesNodesCreatedCompaniesFilter: CompanyFilter
    $usersNodesNodesCreatedCompaniesSorting: [CompanySort!]
    $usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $usersNodesNodesCreatedProjectsPaging: OffsetPaging
    $usersNodesNodesCreatedProjectsFilter: ProjectFilter
    $usersNodesNodesCreatedProjectsSorting: [ProjectSort!]
    $usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $usersNodesNodesContactsPaging: OffsetPaging
    $usersNodesNodesContactsFilter: ContactFilter
    $usersNodesNodesContactsSorting: [ContactSort!]
    $usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $usersNodesNodesProjectChangesPaging: OffsetPaging
    $usersNodesNodesProjectChangesFilter: ContactFilter
    $usersNodesNodesProjectChangesSorting: [ContactSort!]
    $usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $usersNodesNodesCreatedWorkersPaging: OffsetPaging
    $usersNodesNodesCreatedWorkersFilter: WorkerFilter
    $usersNodesNodesCreatedWorkersSorting: [WorkerSort!]
    $usersNodesNodesCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $usersNodesNodesCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $usersNodesNodesCreatedContractsPaging: OffsetPaging
    $usersNodesNodesCreatedContractsFilter: ContractFilter
    $usersNodesNodesCreatedContractsSorting: [ContractSort!]
    $usersNodesNodesCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $usersNodesNodesCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $usersNodesNodesCreatedMiddlemansPaging: OffsetPaging
    $usersNodesNodesCreatedMiddlemansFilter: MiddlemanFilter
    $usersNodesNodesCreatedMiddlemansSorting: [MiddlemanSort!]
    $usersNodesNodesCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $usersNodesNodesCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    users(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $usersNodesNodesCreatedCompaniesPaging
          filter: $usersNodesNodesCreatedCompaniesFilter
          sorting: $usersNodesNodesCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $usersNodesNodesCreatedProjectsPaging
          filter: $usersNodesNodesCreatedProjectsFilter
          sorting: $usersNodesNodesCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $usersNodesNodesContactsPaging
          filter: $usersNodesNodesContactsFilter
          sorting: $usersNodesNodesContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $usersNodesNodesProjectChangesPaging
          filter: $usersNodesNodesProjectChangesFilter
          sorting: $usersNodesNodesProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $usersNodesNodesCreatedWorkersPaging
          filter: $usersNodesNodesCreatedWorkersFilter
          sorting: $usersNodesNodesCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $usersNodesNodesCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $usersNodesNodesCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $usersNodesNodesCreatedContractsPaging
          filter: $usersNodesNodesCreatedContractsFilter
          sorting: $usersNodesNodesCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $usersNodesNodesCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $usersNodesNodesCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $usersNodesNodesCreatedMiddlemansPaging
          filter: $usersNodesNodesCreatedMiddlemansFilter
          sorting: $usersNodesNodesCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $usersNodesNodesCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $usersNodesNodesCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a Vue component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUsersQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   usersNodesNodesCreatedCompaniesPaging: // value for 'usersNodesNodesCreatedCompaniesPaging'
 *   usersNodesNodesCreatedCompaniesFilter: // value for 'usersNodesNodesCreatedCompaniesFilter'
 *   usersNodesNodesCreatedCompaniesSorting: // value for 'usersNodesNodesCreatedCompaniesSorting'
 *   usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'usersNodesNodesCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   usersNodesNodesCreatedProjectsPaging: // value for 'usersNodesNodesCreatedProjectsPaging'
 *   usersNodesNodesCreatedProjectsFilter: // value for 'usersNodesNodesCreatedProjectsFilter'
 *   usersNodesNodesCreatedProjectsSorting: // value for 'usersNodesNodesCreatedProjectsSorting'
 *   usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'usersNodesNodesCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   usersNodesNodesContactsPaging: // value for 'usersNodesNodesContactsPaging'
 *   usersNodesNodesContactsFilter: // value for 'usersNodesNodesContactsFilter'
 *   usersNodesNodesContactsSorting: // value for 'usersNodesNodesContactsSorting'
 *   usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'usersNodesNodesContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   usersNodesNodesProjectChangesPaging: // value for 'usersNodesNodesProjectChangesPaging'
 *   usersNodesNodesProjectChangesFilter: // value for 'usersNodesNodesProjectChangesFilter'
 *   usersNodesNodesProjectChangesSorting: // value for 'usersNodesNodesProjectChangesSorting'
 *   usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'usersNodesNodesProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   usersNodesNodesCreatedWorkersPaging: // value for 'usersNodesNodesCreatedWorkersPaging'
 *   usersNodesNodesCreatedWorkersFilter: // value for 'usersNodesNodesCreatedWorkersFilter'
 *   usersNodesNodesCreatedWorkersSorting: // value for 'usersNodesNodesCreatedWorkersSorting'
 *   usersNodesNodesCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'usersNodesNodesCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   usersNodesNodesCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'usersNodesNodesCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   usersNodesNodesCreatedContractsPaging: // value for 'usersNodesNodesCreatedContractsPaging'
 *   usersNodesNodesCreatedContractsFilter: // value for 'usersNodesNodesCreatedContractsFilter'
 *   usersNodesNodesCreatedContractsSorting: // value for 'usersNodesNodesCreatedContractsSorting'
 *   usersNodesNodesCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'usersNodesNodesCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   usersNodesNodesCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'usersNodesNodesCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   usersNodesNodesCreatedMiddlemansPaging: // value for 'usersNodesNodesCreatedMiddlemansPaging'
 *   usersNodesNodesCreatedMiddlemansFilter: // value for 'usersNodesNodesCreatedMiddlemansFilter'
 *   usersNodesNodesCreatedMiddlemansSorting: // value for 'usersNodesNodesCreatedMiddlemansSorting'
 *   usersNodesNodesCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'usersNodesNodesCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   usersNodesNodesCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'usersNodesNodesCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useUsersQuery(
  variables:
    | UsersQueryVariables
    | VueCompositionApi.Ref<UsersQueryVariables>
    | ReactiveFunction<UsersQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<UsersQuery, UsersQueryVariables>(
    UsersQueryDocument,
    variables,
    options
  );
}
export type UsersQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<UsersQuery, UsersQueryVariables>;
export const CompanyFocusesQueryDocument = gql`
  query companyFocusesQuery {
    companyFocuses
  }
`;

/**
 * __useCompanyFocusesQuery__
 *
 * To run a query within a Vue component, call `useCompanyFocusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFocusesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyFocusesQuery();
 */
export function useCompanyFocusesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        CompanyFocusesQuery,
        CompanyFocusesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CompanyFocusesQuery,
          CompanyFocusesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CompanyFocusesQuery,
          CompanyFocusesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    CompanyFocusesQuery,
    CompanyFocusesQueryVariables
  >(CompanyFocusesQueryDocument, {}, options);
}
export type CompanyFocusesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    CompanyFocusesQuery,
    CompanyFocusesQueryVariables
  >;
export const CompanyLanguagesQueryDocument = gql`
  query companyLanguagesQuery {
    companyLanguages
  }
`;

/**
 * __useCompanyLanguagesQuery__
 *
 * To run a query within a Vue component, call `useCompanyLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyLanguagesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyLanguagesQuery();
 */
export function useCompanyLanguagesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        CompanyLanguagesQuery,
        CompanyLanguagesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CompanyLanguagesQuery,
          CompanyLanguagesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CompanyLanguagesQuery,
          CompanyLanguagesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    CompanyLanguagesQuery,
    CompanyLanguagesQueryVariables
  >(CompanyLanguagesQueryDocument, {}, options);
}
export type CompanyLanguagesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    CompanyLanguagesQuery,
    CompanyLanguagesQueryVariables
  >;
export const CompanyIdQueryDocument = gql`
  query companyIdQuery(
    $name: String!
    $companyIdContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companyIdContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companyIdContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companyIdContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companyIdContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companyIdContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyIdContactContactContactedByContactedByContactsPaging: OffsetPaging
    $companyIdContactContactContactedByContactedByContactsFilter: ContactFilter
    $companyIdContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $companyIdContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $companyIdContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $companyIdContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companyIdContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companyIdContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companyIdContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companyIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyIdContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companyIdContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $companyIdContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companyIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyIdContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companyIdContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyIdContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companyIdCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $companyIdCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $companyIdCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $companyIdCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $companyIdCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $companyIdCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyIdCreatedByCreatedByContactsPaging: OffsetPaging
    $companyIdCreatedByCreatedByContactsFilter: ContactFilter
    $companyIdCreatedByCreatedByContactsSorting: [ContactSort!]
    $companyIdCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $companyIdCreatedByCreatedByProjectChangesFilter: ContactFilter
    $companyIdCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $companyIdCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $companyIdCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $companyIdCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $companyIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyIdCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $companyIdCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $companyIdCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $companyIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyIdCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $companyIdCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyIdCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companyIdHistoriesPaging: OffsetPaging
    $companyIdHistoriesFilter: ContactFilter
    $companyIdHistoriesSorting: [ContactSort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    companyId(name: $name) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $companyIdContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $companyIdContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $companyIdContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $companyIdContactContactContactedByContactedByCreatedProjectsPaging
            filter: $companyIdContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $companyIdContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $companyIdContactContactContactedByContactedByContactsPaging
            filter: $companyIdContactContactContactedByContactedByContactsFilter
            sorting: $companyIdContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $companyIdContactContactContactedByContactedByProjectChangesPaging
            filter: $companyIdContactContactContactedByContactedByProjectChangesFilter
            sorting: $companyIdContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $companyIdContactContactContactedByContactedByCreatedWorkersPaging
            filter: $companyIdContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $companyIdContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $companyIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $companyIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $companyIdContactContactContactedByContactedByCreatedContractsPaging
            filter: $companyIdContactContactContactedByContactedByCreatedContractsFilter
            sorting: $companyIdContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $companyIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $companyIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $companyIdContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $companyIdContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $companyIdContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $companyIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $companyIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $companyIdCreatedByCreatedByCreatedCompaniesPaging
          filter: $companyIdCreatedByCreatedByCreatedCompaniesFilter
          sorting: $companyIdCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $companyIdCreatedByCreatedByCreatedProjectsPaging
          filter: $companyIdCreatedByCreatedByCreatedProjectsFilter
          sorting: $companyIdCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $companyIdCreatedByCreatedByContactsPaging
          filter: $companyIdCreatedByCreatedByContactsFilter
          sorting: $companyIdCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $companyIdCreatedByCreatedByProjectChangesPaging
          filter: $companyIdCreatedByCreatedByProjectChangesFilter
          sorting: $companyIdCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $companyIdCreatedByCreatedByCreatedWorkersPaging
          filter: $companyIdCreatedByCreatedByCreatedWorkersFilter
          sorting: $companyIdCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $companyIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $companyIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $companyIdCreatedByCreatedByCreatedContractsPaging
          filter: $companyIdCreatedByCreatedByCreatedContractsFilter
          sorting: $companyIdCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $companyIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $companyIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $companyIdCreatedByCreatedByCreatedMiddlemansPaging
          filter: $companyIdCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $companyIdCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $companyIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $companyIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $companyIdHistoriesPaging
        filter: $companyIdHistoriesFilter
        sorting: $companyIdHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCompanyIdQuery__
 *
 * To run a query within a Vue component, call `useCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyIdQuery({
 *   name: // value for 'name'
 *   companyIdContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'companyIdContactContactContactedByContactedByCreatedCompaniesPaging'
 *   companyIdContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'companyIdContactContactContactedByContactedByCreatedCompaniesFilter'
 *   companyIdContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'companyIdContactContactContactedByContactedByCreatedCompaniesSorting'
 *   companyIdContactContactContactedByContactedByCreatedProjectsPaging: // value for 'companyIdContactContactContactedByContactedByCreatedProjectsPaging'
 *   companyIdContactContactContactedByContactedByCreatedProjectsFilter: // value for 'companyIdContactContactContactedByContactedByCreatedProjectsFilter'
 *   companyIdContactContactContactedByContactedByCreatedProjectsSorting: // value for 'companyIdContactContactContactedByContactedByCreatedProjectsSorting'
 *   companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyIdContactContactContactedByContactedByContactsPaging: // value for 'companyIdContactContactContactedByContactedByContactsPaging'
 *   companyIdContactContactContactedByContactedByContactsFilter: // value for 'companyIdContactContactContactedByContactedByContactsFilter'
 *   companyIdContactContactContactedByContactedByContactsSorting: // value for 'companyIdContactContactContactedByContactedByContactsSorting'
 *   companyIdContactContactContactedByContactedByProjectChangesPaging: // value for 'companyIdContactContactContactedByContactedByProjectChangesPaging'
 *   companyIdContactContactContactedByContactedByProjectChangesFilter: // value for 'companyIdContactContactContactedByContactedByProjectChangesFilter'
 *   companyIdContactContactContactedByContactedByProjectChangesSorting: // value for 'companyIdContactContactContactedByContactedByProjectChangesSorting'
 *   companyIdContactContactContactedByContactedByCreatedWorkersPaging: // value for 'companyIdContactContactContactedByContactedByCreatedWorkersPaging'
 *   companyIdContactContactContactedByContactedByCreatedWorkersFilter: // value for 'companyIdContactContactContactedByContactedByCreatedWorkersFilter'
 *   companyIdContactContactContactedByContactedByCreatedWorkersSorting: // value for 'companyIdContactContactContactedByContactedByCreatedWorkersSorting'
 *   companyIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyIdContactContactContactedByContactedByCreatedContractsPaging: // value for 'companyIdContactContactContactedByContactedByCreatedContractsPaging'
 *   companyIdContactContactContactedByContactedByCreatedContractsFilter: // value for 'companyIdContactContactContactedByContactedByCreatedContractsFilter'
 *   companyIdContactContactContactedByContactedByCreatedContractsSorting: // value for 'companyIdContactContactContactedByContactedByCreatedContractsSorting'
 *   companyIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyIdContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'companyIdContactContactContactedByContactedByCreatedMiddlemansPaging'
 *   companyIdContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'companyIdContactContactContactedByContactedByCreatedMiddlemansFilter'
 *   companyIdContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'companyIdContactContactContactedByContactedByCreatedMiddlemansSorting'
 *   companyIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companyIdCreatedByCreatedByCreatedCompaniesPaging: // value for 'companyIdCreatedByCreatedByCreatedCompaniesPaging'
 *   companyIdCreatedByCreatedByCreatedCompaniesFilter: // value for 'companyIdCreatedByCreatedByCreatedCompaniesFilter'
 *   companyIdCreatedByCreatedByCreatedCompaniesSorting: // value for 'companyIdCreatedByCreatedByCreatedCompaniesSorting'
 *   companyIdCreatedByCreatedByCreatedProjectsPaging: // value for 'companyIdCreatedByCreatedByCreatedProjectsPaging'
 *   companyIdCreatedByCreatedByCreatedProjectsFilter: // value for 'companyIdCreatedByCreatedByCreatedProjectsFilter'
 *   companyIdCreatedByCreatedByCreatedProjectsSorting: // value for 'companyIdCreatedByCreatedByCreatedProjectsSorting'
 *   companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyIdCreatedByCreatedByContactsPaging: // value for 'companyIdCreatedByCreatedByContactsPaging'
 *   companyIdCreatedByCreatedByContactsFilter: // value for 'companyIdCreatedByCreatedByContactsFilter'
 *   companyIdCreatedByCreatedByContactsSorting: // value for 'companyIdCreatedByCreatedByContactsSorting'
 *   companyIdCreatedByCreatedByProjectChangesPaging: // value for 'companyIdCreatedByCreatedByProjectChangesPaging'
 *   companyIdCreatedByCreatedByProjectChangesFilter: // value for 'companyIdCreatedByCreatedByProjectChangesFilter'
 *   companyIdCreatedByCreatedByProjectChangesSorting: // value for 'companyIdCreatedByCreatedByProjectChangesSorting'
 *   companyIdCreatedByCreatedByCreatedWorkersPaging: // value for 'companyIdCreatedByCreatedByCreatedWorkersPaging'
 *   companyIdCreatedByCreatedByCreatedWorkersFilter: // value for 'companyIdCreatedByCreatedByCreatedWorkersFilter'
 *   companyIdCreatedByCreatedByCreatedWorkersSorting: // value for 'companyIdCreatedByCreatedByCreatedWorkersSorting'
 *   companyIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyIdCreatedByCreatedByCreatedContractsPaging: // value for 'companyIdCreatedByCreatedByCreatedContractsPaging'
 *   companyIdCreatedByCreatedByCreatedContractsFilter: // value for 'companyIdCreatedByCreatedByCreatedContractsFilter'
 *   companyIdCreatedByCreatedByCreatedContractsSorting: // value for 'companyIdCreatedByCreatedByCreatedContractsSorting'
 *   companyIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyIdCreatedByCreatedByCreatedMiddlemansPaging: // value for 'companyIdCreatedByCreatedByCreatedMiddlemansPaging'
 *   companyIdCreatedByCreatedByCreatedMiddlemansFilter: // value for 'companyIdCreatedByCreatedByCreatedMiddlemansFilter'
 *   companyIdCreatedByCreatedByCreatedMiddlemansSorting: // value for 'companyIdCreatedByCreatedByCreatedMiddlemansSorting'
 *   companyIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companyIdHistoriesPaging: // value for 'companyIdHistoriesPaging'
 *   companyIdHistoriesFilter: // value for 'companyIdHistoriesFilter'
 *   companyIdHistoriesSorting: // value for 'companyIdHistoriesSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useCompanyIdQuery(
  variables:
    | CompanyIdQueryVariables
    | VueCompositionApi.Ref<CompanyIdQueryVariables>
    | ReactiveFunction<CompanyIdQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        CompanyIdQuery,
        CompanyIdQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CompanyIdQuery,
          CompanyIdQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CompanyIdQuery,
          CompanyIdQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<CompanyIdQuery, CompanyIdQueryVariables>(
    CompanyIdQueryDocument,
    variables,
    options
  );
}
export type CompanyIdQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<CompanyIdQuery, CompanyIdQueryVariables>;
export const CompanyQueryDocument = gql`
  query companyQuery(
    $id: String!
    $companyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $companyContactContactContactedByContactedByContactsFilter: ContactFilter
    $companyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $companyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $companyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $companyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $companyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $companyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $companyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $companyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $companyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $companyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyCreatedByCreatedByContactsPaging: OffsetPaging
    $companyCreatedByCreatedByContactsFilter: ContactFilter
    $companyCreatedByCreatedByContactsSorting: [ContactSort!]
    $companyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $companyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $companyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $companyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $companyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $companyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $companyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $companyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $companyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $companyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $companyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companyHistoriesPaging: OffsetPaging
    $companyHistoriesFilter: ContactFilter
    $companyHistoriesSorting: [ContactSort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $companyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    company(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $companyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $companyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $companyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $companyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $companyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $companyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $companyContactContactContactedByContactedByContactsPaging
            filter: $companyContactContactContactedByContactedByContactsFilter
            sorting: $companyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $companyContactContactContactedByContactedByProjectChangesPaging
            filter: $companyContactContactContactedByContactedByProjectChangesFilter
            sorting: $companyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $companyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $companyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $companyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $companyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $companyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $companyContactContactContactedByContactedByCreatedContractsPaging
            filter: $companyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $companyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $companyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $companyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $companyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $companyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $companyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $companyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $companyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $companyCreatedByCreatedByCreatedCompaniesPaging
          filter: $companyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $companyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $companyCreatedByCreatedByCreatedProjectsPaging
          filter: $companyCreatedByCreatedByCreatedProjectsFilter
          sorting: $companyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $companyCreatedByCreatedByContactsPaging
          filter: $companyCreatedByCreatedByContactsFilter
          sorting: $companyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $companyCreatedByCreatedByProjectChangesPaging
          filter: $companyCreatedByCreatedByProjectChangesFilter
          sorting: $companyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $companyCreatedByCreatedByCreatedWorkersPaging
          filter: $companyCreatedByCreatedByCreatedWorkersFilter
          sorting: $companyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $companyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $companyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $companyCreatedByCreatedByCreatedContractsPaging
          filter: $companyCreatedByCreatedByCreatedContractsFilter
          sorting: $companyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $companyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $companyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $companyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $companyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $companyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $companyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $companyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $companyHistoriesPaging
        filter: $companyHistoriesFilter
        sorting: $companyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $companyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a Vue component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyQuery({
 *   id: // value for 'id'
 *   companyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'companyContactContactContactedByContactedByCreatedCompaniesPaging'
 *   companyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'companyContactContactContactedByContactedByCreatedCompaniesFilter'
 *   companyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'companyContactContactContactedByContactedByCreatedCompaniesSorting'
 *   companyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'companyContactContactContactedByContactedByCreatedProjectsPaging'
 *   companyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'companyContactContactContactedByContactedByCreatedProjectsFilter'
 *   companyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'companyContactContactContactedByContactedByCreatedProjectsSorting'
 *   companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyContactContactContactedByContactedByContactsPaging: // value for 'companyContactContactContactedByContactedByContactsPaging'
 *   companyContactContactContactedByContactedByContactsFilter: // value for 'companyContactContactContactedByContactedByContactsFilter'
 *   companyContactContactContactedByContactedByContactsSorting: // value for 'companyContactContactContactedByContactedByContactsSorting'
 *   companyContactContactContactedByContactedByProjectChangesPaging: // value for 'companyContactContactContactedByContactedByProjectChangesPaging'
 *   companyContactContactContactedByContactedByProjectChangesFilter: // value for 'companyContactContactContactedByContactedByProjectChangesFilter'
 *   companyContactContactContactedByContactedByProjectChangesSorting: // value for 'companyContactContactContactedByContactedByProjectChangesSorting'
 *   companyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'companyContactContactContactedByContactedByCreatedWorkersPaging'
 *   companyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'companyContactContactContactedByContactedByCreatedWorkersFilter'
 *   companyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'companyContactContactContactedByContactedByCreatedWorkersSorting'
 *   companyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyContactContactContactedByContactedByCreatedContractsPaging: // value for 'companyContactContactContactedByContactedByCreatedContractsPaging'
 *   companyContactContactContactedByContactedByCreatedContractsFilter: // value for 'companyContactContactContactedByContactedByCreatedContractsFilter'
 *   companyContactContactContactedByContactedByCreatedContractsSorting: // value for 'companyContactContactContactedByContactedByCreatedContractsSorting'
 *   companyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'companyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *   companyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'companyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *   companyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'companyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *   companyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companyCreatedByCreatedByCreatedCompaniesPaging: // value for 'companyCreatedByCreatedByCreatedCompaniesPaging'
 *   companyCreatedByCreatedByCreatedCompaniesFilter: // value for 'companyCreatedByCreatedByCreatedCompaniesFilter'
 *   companyCreatedByCreatedByCreatedCompaniesSorting: // value for 'companyCreatedByCreatedByCreatedCompaniesSorting'
 *   companyCreatedByCreatedByCreatedProjectsPaging: // value for 'companyCreatedByCreatedByCreatedProjectsPaging'
 *   companyCreatedByCreatedByCreatedProjectsFilter: // value for 'companyCreatedByCreatedByCreatedProjectsFilter'
 *   companyCreatedByCreatedByCreatedProjectsSorting: // value for 'companyCreatedByCreatedByCreatedProjectsSorting'
 *   companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyCreatedByCreatedByContactsPaging: // value for 'companyCreatedByCreatedByContactsPaging'
 *   companyCreatedByCreatedByContactsFilter: // value for 'companyCreatedByCreatedByContactsFilter'
 *   companyCreatedByCreatedByContactsSorting: // value for 'companyCreatedByCreatedByContactsSorting'
 *   companyCreatedByCreatedByProjectChangesPaging: // value for 'companyCreatedByCreatedByProjectChangesPaging'
 *   companyCreatedByCreatedByProjectChangesFilter: // value for 'companyCreatedByCreatedByProjectChangesFilter'
 *   companyCreatedByCreatedByProjectChangesSorting: // value for 'companyCreatedByCreatedByProjectChangesSorting'
 *   companyCreatedByCreatedByCreatedWorkersPaging: // value for 'companyCreatedByCreatedByCreatedWorkersPaging'
 *   companyCreatedByCreatedByCreatedWorkersFilter: // value for 'companyCreatedByCreatedByCreatedWorkersFilter'
 *   companyCreatedByCreatedByCreatedWorkersSorting: // value for 'companyCreatedByCreatedByCreatedWorkersSorting'
 *   companyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyCreatedByCreatedByCreatedContractsPaging: // value for 'companyCreatedByCreatedByCreatedContractsPaging'
 *   companyCreatedByCreatedByCreatedContractsFilter: // value for 'companyCreatedByCreatedByCreatedContractsFilter'
 *   companyCreatedByCreatedByCreatedContractsSorting: // value for 'companyCreatedByCreatedByCreatedContractsSorting'
 *   companyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'companyCreatedByCreatedByCreatedMiddlemansPaging'
 *   companyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'companyCreatedByCreatedByCreatedMiddlemansFilter'
 *   companyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'companyCreatedByCreatedByCreatedMiddlemansSorting'
 *   companyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companyHistoriesPaging: // value for 'companyHistoriesPaging'
 *   companyHistoriesFilter: // value for 'companyHistoriesFilter'
 *   companyHistoriesSorting: // value for 'companyHistoriesSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useCompanyQuery(
  variables:
    | CompanyQueryVariables
    | VueCompositionApi.Ref<CompanyQueryVariables>
    | ReactiveFunction<CompanyQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<CompanyQuery, CompanyQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<CompanyQuery, CompanyQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<CompanyQuery, CompanyQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<CompanyQuery, CompanyQueryVariables>(
    CompanyQueryDocument,
    variables,
    options
  );
}
export type CompanyQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<CompanyQuery, CompanyQueryVariables>;
export const CompaniesQueryDocument = gql`
  query companiesQuery(
    $paging: OffsetPaging
    $filter: CompanyFilter
    $sorting: [CompanySort!]
    $companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companiesNodesNodesContactContactContactedByContactedByContactsPaging: OffsetPaging
    $companiesNodesNodesContactContactContactedByContactedByContactsFilter: ContactFilter
    $companiesNodesNodesContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $companiesNodesNodesContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $companiesNodesNodesContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $companiesNodesNodesContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companiesNodesNodesContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companiesNodesNodesContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companiesNodesNodesContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companiesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companiesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companiesNodesNodesContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companiesNodesNodesContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $companiesNodesNodesContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companiesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companiesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companiesNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $companiesNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $companiesNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $companiesNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $companiesNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $companiesNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companiesNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $companiesNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $companiesNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $companiesNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $companiesNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $companiesNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $companiesNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $companiesNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $companiesNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $companiesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companiesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companiesNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $companiesNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $companiesNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $companiesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companiesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companiesNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $companiesNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $companiesNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companiesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companiesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companiesNodesNodesHistoriesPaging: OffsetPaging
    $companiesNodesNodesHistoriesFilter: ContactFilter
    $companiesNodesNodesHistoriesSorting: [ContactSort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    companies(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        contact {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging
              filter: $companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter
              sorting: $companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsPaging
              filter: $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsFilter
              sorting: $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $companiesNodesNodesContactContactContactedByContactedByContactsPaging
              filter: $companiesNodesNodesContactContactContactedByContactedByContactsFilter
              sorting: $companiesNodesNodesContactContactContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $companiesNodesNodesContactContactContactedByContactedByProjectChangesPaging
              filter: $companiesNodesNodesContactContactContactedByContactedByProjectChangesFilter
              sorting: $companiesNodesNodesContactContactContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $companiesNodesNodesContactContactContactedByContactedByCreatedWorkersPaging
              filter: $companiesNodesNodesContactContactContactedByContactedByCreatedWorkersFilter
              sorting: $companiesNodesNodesContactContactContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $companiesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $companiesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $companiesNodesNodesContactContactContactedByContactedByCreatedContractsPaging
              filter: $companiesNodesNodesContactContactContactedByContactedByCreatedContractsFilter
              sorting: $companiesNodesNodesContactContactContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $companiesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $companiesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging
              filter: $companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter
              sorting: $companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $companiesNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $companiesNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $companiesNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $companiesNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $companiesNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $companiesNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $companiesNodesNodesCreatedByCreatedByContactsPaging
            filter: $companiesNodesNodesCreatedByCreatedByContactsFilter
            sorting: $companiesNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
          projectChanges(
            paging: $companiesNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $companiesNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $companiesNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
          createdWorkers(
            paging: $companiesNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $companiesNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $companiesNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $companiesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $companiesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $companiesNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $companiesNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $companiesNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $companiesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $companiesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $companiesNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $companiesNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $companiesNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $companiesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $companiesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $companiesNodesNodesHistoriesPaging
          filter: $companiesNodesNodesHistoriesFilter
          sorting: $companiesNodesNodesHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            contactedBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
                filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
                sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdProjects(
                paging: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
                filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
                sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
                filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
                sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
                filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
                sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
                filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
                sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
                filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
                sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
                filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
                sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a Vue component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompaniesQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedProjectsPaging: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedProjectsPaging'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedProjectsFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedProjectsFilter'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedProjectsSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedProjectsSorting'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companiesNodesNodesContactContactContactedByContactedByContactsPaging: // value for 'companiesNodesNodesContactContactContactedByContactedByContactsPaging'
 *   companiesNodesNodesContactContactContactedByContactedByContactsFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByContactsFilter'
 *   companiesNodesNodesContactContactContactedByContactedByContactsSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByContactsSorting'
 *   companiesNodesNodesContactContactContactedByContactedByProjectChangesPaging: // value for 'companiesNodesNodesContactContactContactedByContactedByProjectChangesPaging'
 *   companiesNodesNodesContactContactContactedByContactedByProjectChangesFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByProjectChangesFilter'
 *   companiesNodesNodesContactContactContactedByContactedByProjectChangesSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByProjectChangesSorting'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedWorkersPaging: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedWorkersPaging'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedWorkersFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedWorkersFilter'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedWorkersSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedWorkersSorting'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedContractsPaging: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedContractsPaging'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedContractsFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedContractsFilter'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedContractsSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedContractsSorting'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companiesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companiesNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'companiesNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   companiesNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'companiesNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   companiesNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'companiesNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   companiesNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'companiesNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   companiesNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'companiesNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   companiesNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'companiesNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companiesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companiesNodesNodesCreatedByCreatedByContactsPaging: // value for 'companiesNodesNodesCreatedByCreatedByContactsPaging'
 *   companiesNodesNodesCreatedByCreatedByContactsFilter: // value for 'companiesNodesNodesCreatedByCreatedByContactsFilter'
 *   companiesNodesNodesCreatedByCreatedByContactsSorting: // value for 'companiesNodesNodesCreatedByCreatedByContactsSorting'
 *   companiesNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'companiesNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   companiesNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'companiesNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   companiesNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'companiesNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   companiesNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'companiesNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   companiesNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'companiesNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   companiesNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'companiesNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   companiesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companiesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companiesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companiesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companiesNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'companiesNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   companiesNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'companiesNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   companiesNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'companiesNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   companiesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companiesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companiesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companiesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companiesNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'companiesNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   companiesNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'companiesNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   companiesNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'companiesNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   companiesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companiesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companiesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companiesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companiesNodesNodesHistoriesPaging: // value for 'companiesNodesNodesHistoriesPaging'
 *   companiesNodesNodesHistoriesFilter: // value for 'companiesNodesNodesHistoriesFilter'
 *   companiesNodesNodesHistoriesSorting: // value for 'companiesNodesNodesHistoriesSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companiesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useCompaniesQuery(
  variables:
    | CompaniesQueryVariables
    | VueCompositionApi.Ref<CompaniesQueryVariables>
    | ReactiveFunction<CompaniesQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        CompaniesQuery,
        CompaniesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CompaniesQuery,
          CompaniesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CompaniesQuery,
          CompaniesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<CompaniesQuery, CompaniesQueryVariables>(
    CompaniesQueryDocument,
    variables,
    options
  );
}
export type CompaniesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyPilverFocusesQueryDocument = gql`
  query companyPilverFocusesQuery {
    companyPilverFocuses
  }
`;

/**
 * __useCompanyPilverFocusesQuery__
 *
 * To run a query within a Vue component, call `useCompanyPilverFocusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPilverFocusesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyPilverFocusesQuery();
 */
export function useCompanyPilverFocusesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        CompanyPilverFocusesQuery,
        CompanyPilverFocusesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CompanyPilverFocusesQuery,
          CompanyPilverFocusesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CompanyPilverFocusesQuery,
          CompanyPilverFocusesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    CompanyPilverFocusesQuery,
    CompanyPilverFocusesQueryVariables
  >(CompanyPilverFocusesQueryDocument, {}, options);
}
export type CompanyPilverFocusesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    CompanyPilverFocusesQuery,
    CompanyPilverFocusesQueryVariables
  >;
export const CompanyPilverLanguagesQueryDocument = gql`
  query companyPilverLanguagesQuery {
    companyPilverLanguages
  }
`;

/**
 * __useCompanyPilverLanguagesQuery__
 *
 * To run a query within a Vue component, call `useCompanyPilverLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPilverLanguagesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyPilverLanguagesQuery();
 */
export function useCompanyPilverLanguagesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        CompanyPilverLanguagesQuery,
        CompanyPilverLanguagesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CompanyPilverLanguagesQuery,
          CompanyPilverLanguagesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CompanyPilverLanguagesQuery,
          CompanyPilverLanguagesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    CompanyPilverLanguagesQuery,
    CompanyPilverLanguagesQueryVariables
  >(CompanyPilverLanguagesQueryDocument, {}, options);
}
export type CompanyPilverLanguagesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    CompanyPilverLanguagesQuery,
    CompanyPilverLanguagesQueryVariables
  >;
export const CompanyPilverIdQueryDocument = gql`
  query companyPilverIdQuery(
    $name: String!
    $companyPilverIdContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companyPilverIdContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $companyPilverIdContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companyPilverIdContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyPilverIdContactContactContactedByContactedByContactsPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByContactsFilter: ContactFilter
    $companyPilverIdContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverIdContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $companyPilverIdContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverIdContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companyPilverIdContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companyPilverIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyPilverIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyPilverIdContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $companyPilverIdContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companyPilverIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyPilverIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyPilverIdContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companyPilverIdContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyPilverIdContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyPilverIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyPilverIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companyPilverIdCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $companyPilverIdCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $companyPilverIdCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $companyPilverIdCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyPilverIdCreatedByCreatedByContactsPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByContactsFilter: ContactFilter
    $companyPilverIdCreatedByCreatedByContactsSorting: [ContactSort!]
    $companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverIdCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByProjectChangesFilter: ContactFilter
    $companyPilverIdCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverIdCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $companyPilverIdCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $companyPilverIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyPilverIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyPilverIdCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $companyPilverIdCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $companyPilverIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyPilverIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyPilverIdCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $companyPilverIdCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyPilverIdCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyPilverIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyPilverIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companyPilverIdHistoriesPaging: OffsetPaging
    $companyPilverIdHistoriesFilter: ContactPilverFilter
    $companyPilverIdHistoriesSorting: [ContactPilverSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    companyPilverId(name: $name) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $companyPilverIdContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $companyPilverIdContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $companyPilverIdContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $companyPilverIdContactContactContactedByContactedByCreatedProjectsPaging
            filter: $companyPilverIdContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $companyPilverIdContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $companyPilverIdContactContactContactedByContactedByContactsPaging
            filter: $companyPilverIdContactContactContactedByContactedByContactsFilter
            sorting: $companyPilverIdContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $companyPilverIdContactContactContactedByContactedByProjectChangesPaging
            filter: $companyPilverIdContactContactContactedByContactedByProjectChangesFilter
            sorting: $companyPilverIdContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $companyPilverIdContactContactContactedByContactedByCreatedWorkersPaging
            filter: $companyPilverIdContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $companyPilverIdContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $companyPilverIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $companyPilverIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $companyPilverIdContactContactContactedByContactedByCreatedContractsPaging
            filter: $companyPilverIdContactContactContactedByContactedByCreatedContractsFilter
            sorting: $companyPilverIdContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $companyPilverIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $companyPilverIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $companyPilverIdContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $companyPilverIdContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $companyPilverIdContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $companyPilverIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $companyPilverIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $companyPilverIdCreatedByCreatedByCreatedCompaniesPaging
          filter: $companyPilverIdCreatedByCreatedByCreatedCompaniesFilter
          sorting: $companyPilverIdCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $companyPilverIdCreatedByCreatedByCreatedProjectsPaging
          filter: $companyPilverIdCreatedByCreatedByCreatedProjectsFilter
          sorting: $companyPilverIdCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $companyPilverIdCreatedByCreatedByContactsPaging
          filter: $companyPilverIdCreatedByCreatedByContactsFilter
          sorting: $companyPilverIdCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $companyPilverIdCreatedByCreatedByProjectChangesPaging
          filter: $companyPilverIdCreatedByCreatedByProjectChangesFilter
          sorting: $companyPilverIdCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $companyPilverIdCreatedByCreatedByCreatedWorkersPaging
          filter: $companyPilverIdCreatedByCreatedByCreatedWorkersFilter
          sorting: $companyPilverIdCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $companyPilverIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $companyPilverIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $companyPilverIdCreatedByCreatedByCreatedContractsPaging
          filter: $companyPilverIdCreatedByCreatedByCreatedContractsFilter
          sorting: $companyPilverIdCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $companyPilverIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $companyPilverIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $companyPilverIdCreatedByCreatedByCreatedMiddlemansPaging
          filter: $companyPilverIdCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $companyPilverIdCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $companyPilverIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $companyPilverIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $companyPilverIdHistoriesPaging
        filter: $companyPilverIdHistoriesFilter
        sorting: $companyPilverIdHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCompanyPilverIdQuery__
 *
 * To run a query within a Vue component, call `useCompanyPilverIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPilverIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyPilverIdQuery({
 *   name: // value for 'name'
 *   companyPilverIdContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'companyPilverIdContactContactContactedByContactedByCreatedCompaniesPaging'
 *   companyPilverIdContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'companyPilverIdContactContactContactedByContactedByCreatedCompaniesFilter'
 *   companyPilverIdContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'companyPilverIdContactContactContactedByContactedByCreatedCompaniesSorting'
 *   companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'companyPilverIdContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   companyPilverIdContactContactContactedByContactedByCreatedProjectsPaging: // value for 'companyPilverIdContactContactContactedByContactedByCreatedProjectsPaging'
 *   companyPilverIdContactContactContactedByContactedByCreatedProjectsFilter: // value for 'companyPilverIdContactContactContactedByContactedByCreatedProjectsFilter'
 *   companyPilverIdContactContactContactedByContactedByCreatedProjectsSorting: // value for 'companyPilverIdContactContactContactedByContactedByCreatedProjectsSorting'
 *   companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyPilverIdContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyPilverIdContactContactContactedByContactedByContactsPaging: // value for 'companyPilverIdContactContactContactedByContactedByContactsPaging'
 *   companyPilverIdContactContactContactedByContactedByContactsFilter: // value for 'companyPilverIdContactContactContactedByContactedByContactsFilter'
 *   companyPilverIdContactContactContactedByContactedByContactsSorting: // value for 'companyPilverIdContactContactContactedByContactedByContactsSorting'
 *   companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverIdContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverIdContactContactContactedByContactedByProjectChangesPaging: // value for 'companyPilverIdContactContactContactedByContactedByProjectChangesPaging'
 *   companyPilverIdContactContactContactedByContactedByProjectChangesFilter: // value for 'companyPilverIdContactContactContactedByContactedByProjectChangesFilter'
 *   companyPilverIdContactContactContactedByContactedByProjectChangesSorting: // value for 'companyPilverIdContactContactContactedByContactedByProjectChangesSorting'
 *   companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverIdContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverIdContactContactContactedByContactedByCreatedWorkersPaging: // value for 'companyPilverIdContactContactContactedByContactedByCreatedWorkersPaging'
 *   companyPilverIdContactContactContactedByContactedByCreatedWorkersFilter: // value for 'companyPilverIdContactContactContactedByContactedByCreatedWorkersFilter'
 *   companyPilverIdContactContactContactedByContactedByCreatedWorkersSorting: // value for 'companyPilverIdContactContactContactedByContactedByCreatedWorkersSorting'
 *   companyPilverIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyPilverIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyPilverIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyPilverIdContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyPilverIdContactContactContactedByContactedByCreatedContractsPaging: // value for 'companyPilverIdContactContactContactedByContactedByCreatedContractsPaging'
 *   companyPilverIdContactContactContactedByContactedByCreatedContractsFilter: // value for 'companyPilverIdContactContactContactedByContactedByCreatedContractsFilter'
 *   companyPilverIdContactContactContactedByContactedByCreatedContractsSorting: // value for 'companyPilverIdContactContactContactedByContactedByCreatedContractsSorting'
 *   companyPilverIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyPilverIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyPilverIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyPilverIdContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyPilverIdContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'companyPilverIdContactContactContactedByContactedByCreatedMiddlemansPaging'
 *   companyPilverIdContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'companyPilverIdContactContactContactedByContactedByCreatedMiddlemansFilter'
 *   companyPilverIdContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'companyPilverIdContactContactContactedByContactedByCreatedMiddlemansSorting'
 *   companyPilverIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyPilverIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyPilverIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyPilverIdContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companyPilverIdCreatedByCreatedByCreatedCompaniesPaging: // value for 'companyPilverIdCreatedByCreatedByCreatedCompaniesPaging'
 *   companyPilverIdCreatedByCreatedByCreatedCompaniesFilter: // value for 'companyPilverIdCreatedByCreatedByCreatedCompaniesFilter'
 *   companyPilverIdCreatedByCreatedByCreatedCompaniesSorting: // value for 'companyPilverIdCreatedByCreatedByCreatedCompaniesSorting'
 *   companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'companyPilverIdCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   companyPilverIdCreatedByCreatedByCreatedProjectsPaging: // value for 'companyPilverIdCreatedByCreatedByCreatedProjectsPaging'
 *   companyPilverIdCreatedByCreatedByCreatedProjectsFilter: // value for 'companyPilverIdCreatedByCreatedByCreatedProjectsFilter'
 *   companyPilverIdCreatedByCreatedByCreatedProjectsSorting: // value for 'companyPilverIdCreatedByCreatedByCreatedProjectsSorting'
 *   companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyPilverIdCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyPilverIdCreatedByCreatedByContactsPaging: // value for 'companyPilverIdCreatedByCreatedByContactsPaging'
 *   companyPilverIdCreatedByCreatedByContactsFilter: // value for 'companyPilverIdCreatedByCreatedByContactsFilter'
 *   companyPilverIdCreatedByCreatedByContactsSorting: // value for 'companyPilverIdCreatedByCreatedByContactsSorting'
 *   companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverIdCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverIdCreatedByCreatedByProjectChangesPaging: // value for 'companyPilverIdCreatedByCreatedByProjectChangesPaging'
 *   companyPilverIdCreatedByCreatedByProjectChangesFilter: // value for 'companyPilverIdCreatedByCreatedByProjectChangesFilter'
 *   companyPilverIdCreatedByCreatedByProjectChangesSorting: // value for 'companyPilverIdCreatedByCreatedByProjectChangesSorting'
 *   companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverIdCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverIdCreatedByCreatedByCreatedWorkersPaging: // value for 'companyPilverIdCreatedByCreatedByCreatedWorkersPaging'
 *   companyPilverIdCreatedByCreatedByCreatedWorkersFilter: // value for 'companyPilverIdCreatedByCreatedByCreatedWorkersFilter'
 *   companyPilverIdCreatedByCreatedByCreatedWorkersSorting: // value for 'companyPilverIdCreatedByCreatedByCreatedWorkersSorting'
 *   companyPilverIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyPilverIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyPilverIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyPilverIdCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyPilverIdCreatedByCreatedByCreatedContractsPaging: // value for 'companyPilverIdCreatedByCreatedByCreatedContractsPaging'
 *   companyPilverIdCreatedByCreatedByCreatedContractsFilter: // value for 'companyPilverIdCreatedByCreatedByCreatedContractsFilter'
 *   companyPilverIdCreatedByCreatedByCreatedContractsSorting: // value for 'companyPilverIdCreatedByCreatedByCreatedContractsSorting'
 *   companyPilverIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyPilverIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyPilverIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyPilverIdCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyPilverIdCreatedByCreatedByCreatedMiddlemansPaging: // value for 'companyPilverIdCreatedByCreatedByCreatedMiddlemansPaging'
 *   companyPilverIdCreatedByCreatedByCreatedMiddlemansFilter: // value for 'companyPilverIdCreatedByCreatedByCreatedMiddlemansFilter'
 *   companyPilverIdCreatedByCreatedByCreatedMiddlemansSorting: // value for 'companyPilverIdCreatedByCreatedByCreatedMiddlemansSorting'
 *   companyPilverIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyPilverIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyPilverIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyPilverIdCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companyPilverIdHistoriesPaging: // value for 'companyPilverIdHistoriesPaging'
 *   companyPilverIdHistoriesFilter: // value for 'companyPilverIdHistoriesFilter'
 *   companyPilverIdHistoriesSorting: // value for 'companyPilverIdHistoriesSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyPilverIdHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useCompanyPilverIdQuery(
  variables:
    | CompanyPilverIdQueryVariables
    | VueCompositionApi.Ref<CompanyPilverIdQueryVariables>
    | ReactiveFunction<CompanyPilverIdQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        CompanyPilverIdQuery,
        CompanyPilverIdQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CompanyPilverIdQuery,
          CompanyPilverIdQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CompanyPilverIdQuery,
          CompanyPilverIdQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    CompanyPilverIdQuery,
    CompanyPilverIdQueryVariables
  >(CompanyPilverIdQueryDocument, variables, options);
}
export type CompanyPilverIdQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    CompanyPilverIdQuery,
    CompanyPilverIdQueryVariables
  >;
export const CompanyPilverQueryDocument = gql`
  query companyPilverQuery(
    $id: String!
    $companyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $companyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $companyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $companyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $companyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $companyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $companyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $companyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $companyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $companyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $companyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $companyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $companyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $companyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $companyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $companyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $companyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $companyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $companyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $companyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $companyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companyPilverHistoriesPaging: OffsetPaging
    $companyPilverHistoriesFilter: ContactPilverFilter
    $companyPilverHistoriesSorting: [ContactPilverSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    companyPilver(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $companyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $companyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $companyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $companyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $companyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $companyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $companyPilverContactContactContactedByContactedByContactsPaging
            filter: $companyPilverContactContactContactedByContactedByContactsFilter
            sorting: $companyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $companyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $companyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $companyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $companyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $companyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $companyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $companyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $companyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $companyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $companyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $companyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $companyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $companyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $companyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $companyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $companyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $companyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $companyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $companyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $companyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $companyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $companyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $companyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $companyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $companyPilverCreatedByCreatedByContactsPaging
          filter: $companyPilverCreatedByCreatedByContactsFilter
          sorting: $companyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $companyPilverCreatedByCreatedByProjectChangesPaging
          filter: $companyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $companyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $companyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $companyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $companyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $companyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $companyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $companyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $companyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $companyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $companyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $companyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $companyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $companyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $companyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $companyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $companyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $companyPilverHistoriesPaging
        filter: $companyPilverHistoriesFilter
        sorting: $companyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCompanyPilverQuery__
 *
 * To run a query within a Vue component, call `useCompanyPilverQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPilverQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyPilverQuery({
 *   id: // value for 'id'
 *   companyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'companyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *   companyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'companyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *   companyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'companyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *   companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'companyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   companyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'companyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *   companyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'companyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *   companyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'companyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *   companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyPilverContactContactContactedByContactedByContactsPaging: // value for 'companyPilverContactContactContactedByContactedByContactsPaging'
 *   companyPilverContactContactContactedByContactedByContactsFilter: // value for 'companyPilverContactContactContactedByContactedByContactsFilter'
 *   companyPilverContactContactContactedByContactedByContactsSorting: // value for 'companyPilverContactContactContactedByContactedByContactsSorting'
 *   companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'companyPilverContactContactContactedByContactedByProjectChangesPaging'
 *   companyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'companyPilverContactContactContactedByContactedByProjectChangesFilter'
 *   companyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'companyPilverContactContactContactedByContactedByProjectChangesSorting'
 *   companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'companyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *   companyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'companyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *   companyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'companyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *   companyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'companyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *   companyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'companyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *   companyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'companyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *   companyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'companyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *   companyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'companyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *   companyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'companyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *   companyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'companyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *   companyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'companyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *   companyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'companyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *   companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'companyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   companyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'companyPilverCreatedByCreatedByCreatedProjectsPaging'
 *   companyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'companyPilverCreatedByCreatedByCreatedProjectsFilter'
 *   companyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'companyPilverCreatedByCreatedByCreatedProjectsSorting'
 *   companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyPilverCreatedByCreatedByContactsPaging: // value for 'companyPilverCreatedByCreatedByContactsPaging'
 *   companyPilverCreatedByCreatedByContactsFilter: // value for 'companyPilverCreatedByCreatedByContactsFilter'
 *   companyPilverCreatedByCreatedByContactsSorting: // value for 'companyPilverCreatedByCreatedByContactsSorting'
 *   companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverCreatedByCreatedByProjectChangesPaging: // value for 'companyPilverCreatedByCreatedByProjectChangesPaging'
 *   companyPilverCreatedByCreatedByProjectChangesFilter: // value for 'companyPilverCreatedByCreatedByProjectChangesFilter'
 *   companyPilverCreatedByCreatedByProjectChangesSorting: // value for 'companyPilverCreatedByCreatedByProjectChangesSorting'
 *   companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'companyPilverCreatedByCreatedByCreatedWorkersPaging'
 *   companyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'companyPilverCreatedByCreatedByCreatedWorkersFilter'
 *   companyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'companyPilverCreatedByCreatedByCreatedWorkersSorting'
 *   companyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'companyPilverCreatedByCreatedByCreatedContractsPaging'
 *   companyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'companyPilverCreatedByCreatedByCreatedContractsFilter'
 *   companyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'companyPilverCreatedByCreatedByCreatedContractsSorting'
 *   companyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'companyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *   companyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'companyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *   companyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'companyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *   companyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companyPilverHistoriesPaging: // value for 'companyPilverHistoriesPaging'
 *   companyPilverHistoriesFilter: // value for 'companyPilverHistoriesFilter'
 *   companyPilverHistoriesSorting: // value for 'companyPilverHistoriesSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useCompanyPilverQuery(
  variables:
    | CompanyPilverQueryVariables
    | VueCompositionApi.Ref<CompanyPilverQueryVariables>
    | ReactiveFunction<CompanyPilverQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        CompanyPilverQuery,
        CompanyPilverQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CompanyPilverQuery,
          CompanyPilverQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CompanyPilverQuery,
          CompanyPilverQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    CompanyPilverQuery,
    CompanyPilverQueryVariables
  >(CompanyPilverQueryDocument, variables, options);
}
export type CompanyPilverQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    CompanyPilverQuery,
    CompanyPilverQueryVariables
  >;
export const CompanyPilversQueryDocument = gql`
  query companyPilversQuery(
    $paging: OffsetPaging
    $filter: CompanyPilverFilter
    $sorting: [CompanyPilverSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByContactsPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByContactsFilter: ContactFilter
    $companyPilversNodesNodesContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyPilversNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $companyPilversNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilversNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $companyPilversNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilversNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $companyPilversNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $companyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyPilversNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $companyPilversNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $companyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $companyPilversNodesNodesHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesFilter: ContactPilverFilter
    $companyPilversNodesNodesHistoriesSorting: [ContactPilverSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    companyPilvers(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        contact {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging
              filter: $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter
              sorting: $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsPaging
              filter: $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsFilter
              sorting: $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $companyPilversNodesNodesContactContactContactedByContactedByContactsPaging
              filter: $companyPilversNodesNodesContactContactContactedByContactedByContactsFilter
              sorting: $companyPilversNodesNodesContactContactContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesPaging
              filter: $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesFilter
              sorting: $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersPaging
              filter: $companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersFilter
              sorting: $companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsPaging
              filter: $companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsFilter
              sorting: $companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging
              filter: $companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter
              sorting: $companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $companyPilversNodesNodesCreatedByCreatedByContactsPaging
            filter: $companyPilversNodesNodesCreatedByCreatedByContactsFilter
            sorting: $companyPilversNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $companyPilversNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $companyPilversNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $companyPilversNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $companyPilversNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $companyPilversNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $companyPilversNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $companyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $companyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $companyPilversNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $companyPilversNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $companyPilversNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $companyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $companyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $companyPilversNodesNodesHistoriesPaging
          filter: $companyPilversNodesNodesHistoriesFilter
          sorting: $companyPilversNodesNodesHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            contactedBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
                filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
                sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
                filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
                sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
                filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
                sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
                filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
                sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
                filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
                sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
                filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
                sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
                filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
                sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useCompanyPilversQuery__
 *
 * To run a query within a Vue component, call `useCompanyPilversQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPilversQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyPilversQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByContactsPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByContactsPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByContactsFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByContactsFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByContactsSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByContactsSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByProjectChangesPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByProjectChangesPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByProjectChangesFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByProjectChangesFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByProjectChangesSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByProjectChangesSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyPilversNodesNodesCreatedByCreatedByContactsPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByContactsPaging'
 *   companyPilversNodesNodesCreatedByCreatedByContactsFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByContactsFilter'
 *   companyPilversNodesNodesCreatedByCreatedByContactsSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByContactsSorting'
 *   companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilversNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   companyPilversNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   companyPilversNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   companyPilversNodesNodesHistoriesPaging: // value for 'companyPilversNodesNodesHistoriesPaging'
 *   companyPilversNodesNodesHistoriesFilter: // value for 'companyPilversNodesNodesHistoriesFilter'
 *   companyPilversNodesNodesHistoriesSorting: // value for 'companyPilversNodesNodesHistoriesSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'companyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useCompanyPilversQuery(
  variables:
    | CompanyPilversQueryVariables
    | VueCompositionApi.Ref<CompanyPilversQueryVariables>
    | ReactiveFunction<CompanyPilversQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        CompanyPilversQuery,
        CompanyPilversQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CompanyPilversQuery,
          CompanyPilversQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CompanyPilversQuery,
          CompanyPilversQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    CompanyPilversQuery,
    CompanyPilversQueryVariables
  >(CompanyPilversQueryDocument, variables, options);
}
export type CompanyPilversQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    CompanyPilversQuery,
    CompanyPilversQueryVariables
  >;
export const InterestsQueryDocument = gql`
  query interestsQuery {
    interests
  }
`;

/**
 * __useInterestsQuery__
 *
 * To run a query within a Vue component, call `useInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterestsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useInterestsQuery();
 */
export function useInterestsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        InterestsQuery,
        InterestsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          InterestsQuery,
          InterestsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          InterestsQuery,
          InterestsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<InterestsQuery, InterestsQueryVariables>(
    InterestsQueryDocument,
    {},
    options
  );
}
export type InterestsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<InterestsQuery, InterestsQueryVariables>;
export const ContactQueryDocument = gql`
  query contactQuery(
    $id: String!
    $contactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $contactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $contactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $contactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $contactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $contactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $contactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $contactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $contactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $contactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $contactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $contactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $contactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $contactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $contactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $contactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $contactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $contactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $contactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $contactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $contactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $contactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $contactCompanyCompanyHistoriesPaging: OffsetPaging
    $contactCompanyCompanyHistoriesFilter: ContactFilter
    $contactCompanyCompanyHistoriesSorting: [ContactSort!]
    $contactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $contactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $contactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $contactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $contactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $contactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $contactContactedByContactedByContactsPaging: OffsetPaging
    $contactContactedByContactedByContactsFilter: ContactFilter
    $contactContactedByContactedByContactsSorting: [ContactSort!]
    $contactContactedByContactedByProjectChangesPaging: OffsetPaging
    $contactContactedByContactedByProjectChangesFilter: ContactFilter
    $contactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $contactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $contactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $contactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $contactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $contactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $contactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $contactContactedByContactedByCreatedContractsFilter: ContractFilter
    $contactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $contactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $contactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $contactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $contactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $contactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $contactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $contactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    contact(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $contactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $contactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $contactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $contactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $contactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $contactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $contactCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $contactCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $contactCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $contactCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $contactCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $contactCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $contactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $contactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $contactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $contactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $contactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $contactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $contactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $contactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $contactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $contactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $contactCompanyCompanyHistoriesPaging
          filter: $contactCompanyCompanyHistoriesFilter
          sorting: $contactCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $contactContactedByContactedByCreatedCompaniesPaging
          filter: $contactContactedByContactedByCreatedCompaniesFilter
          sorting: $contactContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        createdProjects(
          paging: $contactContactedByContactedByCreatedProjectsPaging
          filter: $contactContactedByContactedByCreatedProjectsFilter
          sorting: $contactContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $contactContactedByContactedByContactsPaging
          filter: $contactContactedByContactedByContactsFilter
          sorting: $contactContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        projectChanges(
          paging: $contactContactedByContactedByProjectChangesPaging
          filter: $contactContactedByContactedByProjectChangesFilter
          sorting: $contactContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdWorkers(
          paging: $contactContactedByContactedByCreatedWorkersPaging
          filter: $contactContactedByContactedByCreatedWorkersFilter
          sorting: $contactContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $contactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $contactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $contactContactedByContactedByCreatedContractsPaging
          filter: $contactContactedByContactedByCreatedContractsFilter
          sorting: $contactContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $contactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $contactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $contactContactedByContactedByCreatedMiddlemansPaging
          filter: $contactContactedByContactedByCreatedMiddlemansFilter
          sorting: $contactContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $contactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $contactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useContactQuery__
 *
 * To run a query within a Vue component, call `useContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContactQuery({
 *   id: // value for 'id'
 *   contactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   contactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   contactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   contactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   contactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   contactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   contactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'contactCompanyCompanyCreatedByCreatedByContactsPaging'
 *   contactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'contactCompanyCompanyCreatedByCreatedByContactsFilter'
 *   contactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'contactCompanyCompanyCreatedByCreatedByContactsSorting'
 *   contactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'contactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   contactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'contactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   contactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'contactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   contactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   contactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   contactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   contactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   contactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   contactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   contactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   contactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   contactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   contactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'contactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   contactCompanyCompanyHistoriesPaging: // value for 'contactCompanyCompanyHistoriesPaging'
 *   contactCompanyCompanyHistoriesFilter: // value for 'contactCompanyCompanyHistoriesFilter'
 *   contactCompanyCompanyHistoriesSorting: // value for 'contactCompanyCompanyHistoriesSorting'
 *   contactContactedByContactedByCreatedCompaniesPaging: // value for 'contactContactedByContactedByCreatedCompaniesPaging'
 *   contactContactedByContactedByCreatedCompaniesFilter: // value for 'contactContactedByContactedByCreatedCompaniesFilter'
 *   contactContactedByContactedByCreatedCompaniesSorting: // value for 'contactContactedByContactedByCreatedCompaniesSorting'
 *   contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'contactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   contactContactedByContactedByCreatedProjectsPaging: // value for 'contactContactedByContactedByCreatedProjectsPaging'
 *   contactContactedByContactedByCreatedProjectsFilter: // value for 'contactContactedByContactedByCreatedProjectsFilter'
 *   contactContactedByContactedByCreatedProjectsSorting: // value for 'contactContactedByContactedByCreatedProjectsSorting'
 *   contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'contactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   contactContactedByContactedByContactsPaging: // value for 'contactContactedByContactedByContactsPaging'
 *   contactContactedByContactedByContactsFilter: // value for 'contactContactedByContactedByContactsFilter'
 *   contactContactedByContactedByContactsSorting: // value for 'contactContactedByContactedByContactsSorting'
 *   contactContactedByContactedByProjectChangesPaging: // value for 'contactContactedByContactedByProjectChangesPaging'
 *   contactContactedByContactedByProjectChangesFilter: // value for 'contactContactedByContactedByProjectChangesFilter'
 *   contactContactedByContactedByProjectChangesSorting: // value for 'contactContactedByContactedByProjectChangesSorting'
 *   contactContactedByContactedByCreatedWorkersPaging: // value for 'contactContactedByContactedByCreatedWorkersPaging'
 *   contactContactedByContactedByCreatedWorkersFilter: // value for 'contactContactedByContactedByCreatedWorkersFilter'
 *   contactContactedByContactedByCreatedWorkersSorting: // value for 'contactContactedByContactedByCreatedWorkersSorting'
 *   contactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'contactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   contactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'contactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   contactContactedByContactedByCreatedContractsPaging: // value for 'contactContactedByContactedByCreatedContractsPaging'
 *   contactContactedByContactedByCreatedContractsFilter: // value for 'contactContactedByContactedByCreatedContractsFilter'
 *   contactContactedByContactedByCreatedContractsSorting: // value for 'contactContactedByContactedByCreatedContractsSorting'
 *   contactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'contactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   contactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'contactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   contactContactedByContactedByCreatedMiddlemansPaging: // value for 'contactContactedByContactedByCreatedMiddlemansPaging'
 *   contactContactedByContactedByCreatedMiddlemansFilter: // value for 'contactContactedByContactedByCreatedMiddlemansFilter'
 *   contactContactedByContactedByCreatedMiddlemansSorting: // value for 'contactContactedByContactedByCreatedMiddlemansSorting'
 *   contactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'contactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   contactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'contactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useContactQuery(
  variables:
    | ContactQueryVariables
    | VueCompositionApi.Ref<ContactQueryVariables>
    | ReactiveFunction<ContactQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ContactQuery, ContactQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ContactQuery, ContactQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ContactQuery, ContactQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<ContactQuery, ContactQueryVariables>(
    ContactQueryDocument,
    variables,
    options
  );
}
export type ContactQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<ContactQuery, ContactQueryVariables>;
export const ContactsQueryDocument = gql`
  query contactsQuery(
    $paging: OffsetPaging
    $filter: ContactFilter
    $sorting: [ContactSort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $contactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contactsNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $contactsNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $contactsNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $contactsNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $contactsNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $contactsNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $contactsNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $contactsNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $contactsNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $contactsNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $contactsNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $contactsNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $contactsNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $contactsNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $contactsNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $contactsNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $contactsNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $contactsNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $contactsNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $contactsNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $contactsNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $contactsNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $contactsNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $contactsNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $contactsNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $contactsNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $contactsNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    contacts(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $contactsNodesNodesCompanyCompanyHistoriesPaging
            filter: $contactsNodesNodesCompanyCompanyHistoriesFilter
            sorting: $contactsNodesNodesCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $contactsNodesNodesContactedByContactedByCreatedCompaniesPaging
            filter: $contactsNodesNodesContactedByContactedByCreatedCompaniesFilter
            sorting: $contactsNodesNodesContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
          createdProjects(
            paging: $contactsNodesNodesContactedByContactedByCreatedProjectsPaging
            filter: $contactsNodesNodesContactedByContactedByCreatedProjectsFilter
            sorting: $contactsNodesNodesContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $contactsNodesNodesContactedByContactedByContactsPaging
            filter: $contactsNodesNodesContactedByContactedByContactsFilter
            sorting: $contactsNodesNodesContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $contactsNodesNodesContactedByContactedByProjectChangesPaging
            filter: $contactsNodesNodesContactedByContactedByProjectChangesFilter
            sorting: $contactsNodesNodesContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $contactsNodesNodesContactedByContactedByCreatedWorkersPaging
            filter: $contactsNodesNodesContactedByContactedByCreatedWorkersFilter
            sorting: $contactsNodesNodesContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $contactsNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $contactsNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $contactsNodesNodesContactedByContactedByCreatedContractsPaging
            filter: $contactsNodesNodesContactedByContactedByCreatedContractsFilter
            sorting: $contactsNodesNodesContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $contactsNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $contactsNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $contactsNodesNodesContactedByContactedByCreatedMiddlemansPaging
            filter: $contactsNodesNodesContactedByContactedByCreatedMiddlemansFilter
            sorting: $contactsNodesNodesContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $contactsNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $contactsNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useContactsQuery__
 *
 * To run a query within a Vue component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContactsQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'contactsNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   contactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'contactsNodesNodesCompanyCompanyHistoriesPaging'
 *   contactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'contactsNodesNodesCompanyCompanyHistoriesFilter'
 *   contactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'contactsNodesNodesCompanyCompanyHistoriesSorting'
 *   contactsNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'contactsNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   contactsNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'contactsNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   contactsNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'contactsNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'contactsNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   contactsNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'contactsNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   contactsNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'contactsNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   contactsNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'contactsNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'contactsNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   contactsNodesNodesContactedByContactedByContactsPaging: // value for 'contactsNodesNodesContactedByContactedByContactsPaging'
 *   contactsNodesNodesContactedByContactedByContactsFilter: // value for 'contactsNodesNodesContactedByContactedByContactsFilter'
 *   contactsNodesNodesContactedByContactedByContactsSorting: // value for 'contactsNodesNodesContactedByContactedByContactsSorting'
 *   contactsNodesNodesContactedByContactedByProjectChangesPaging: // value for 'contactsNodesNodesContactedByContactedByProjectChangesPaging'
 *   contactsNodesNodesContactedByContactedByProjectChangesFilter: // value for 'contactsNodesNodesContactedByContactedByProjectChangesFilter'
 *   contactsNodesNodesContactedByContactedByProjectChangesSorting: // value for 'contactsNodesNodesContactedByContactedByProjectChangesSorting'
 *   contactsNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'contactsNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   contactsNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'contactsNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   contactsNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'contactsNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   contactsNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'contactsNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   contactsNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'contactsNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   contactsNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'contactsNodesNodesContactedByContactedByCreatedContractsPaging'
 *   contactsNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'contactsNodesNodesContactedByContactedByCreatedContractsFilter'
 *   contactsNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'contactsNodesNodesContactedByContactedByCreatedContractsSorting'
 *   contactsNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'contactsNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   contactsNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'contactsNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   contactsNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'contactsNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   contactsNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'contactsNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   contactsNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'contactsNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   contactsNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'contactsNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   contactsNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'contactsNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useContactsQuery(
  variables:
    | ContactsQueryVariables
    | VueCompositionApi.Ref<ContactsQueryVariables>
    | ReactiveFunction<ContactsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<ContactsQuery, ContactsQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContactsQuery,
          ContactsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContactsQuery,
          ContactsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsQueryDocument,
    variables,
    options
  );
}
export type ContactsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<ContactsQuery, ContactsQueryVariables>;
export const InterestsPilverQueryDocument = gql`
  query interestsPilverQuery {
    interestsPilver
  }
`;

/**
 * __useInterestsPilverQuery__
 *
 * To run a query within a Vue component, call `useInterestsPilverQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterestsPilverQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useInterestsPilverQuery();
 */
export function useInterestsPilverQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        InterestsPilverQuery,
        InterestsPilverQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          InterestsPilverQuery,
          InterestsPilverQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          InterestsPilverQuery,
          InterestsPilverQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    InterestsPilverQuery,
    InterestsPilverQueryVariables
  >(InterestsPilverQueryDocument, {}, options);
}
export type InterestsPilverQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    InterestsPilverQuery,
    InterestsPilverQueryVariables
  >;
export const ContactPilverQueryDocument = gql`
  query contactPilverQuery(
    $id: String!
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $contactPilverCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $contactPilverCompanyCompanyHistoriesPaging: OffsetPaging
    $contactPilverCompanyCompanyHistoriesFilter: ContactPilverFilter
    $contactPilverCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $contactPilverContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $contactPilverContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $contactPilverContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $contactPilverContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $contactPilverContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $contactPilverContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $contactPilverContactedByContactedByContactsPaging: OffsetPaging
    $contactPilverContactedByContactedByContactsFilter: ContactFilter
    $contactPilverContactedByContactedByContactsSorting: [ContactSort!]
    $contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contactPilverContactedByContactedByProjectChangesPaging: OffsetPaging
    $contactPilverContactedByContactedByProjectChangesFilter: ContactFilter
    $contactPilverContactedByContactedByProjectChangesSorting: [ContactSort!]
    $contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contactPilverContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $contactPilverContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $contactPilverContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $contactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $contactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $contactPilverContactedByContactedByCreatedContractsPaging: OffsetPaging
    $contactPilverContactedByContactedByCreatedContractsFilter: ContractFilter
    $contactPilverContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $contactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $contactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $contactPilverContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $contactPilverContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $contactPilverContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $contactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $contactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    contactPilver(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $contactPilverCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $contactPilverCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $contactPilverCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $contactPilverCompanyCompanyHistoriesPaging
          filter: $contactPilverCompanyCompanyHistoriesFilter
          sorting: $contactPilverCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $contactPilverContactedByContactedByCreatedCompaniesPaging
          filter: $contactPilverContactedByContactedByCreatedCompaniesFilter
          sorting: $contactPilverContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $contactPilverContactedByContactedByCreatedProjectsPaging
          filter: $contactPilverContactedByContactedByCreatedProjectsFilter
          sorting: $contactPilverContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $contactPilverContactedByContactedByContactsPaging
          filter: $contactPilverContactedByContactedByContactsFilter
          sorting: $contactPilverContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $contactPilverContactedByContactedByProjectChangesPaging
          filter: $contactPilverContactedByContactedByProjectChangesFilter
          sorting: $contactPilverContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $contactPilverContactedByContactedByCreatedWorkersPaging
          filter: $contactPilverContactedByContactedByCreatedWorkersFilter
          sorting: $contactPilverContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $contactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $contactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $contactPilverContactedByContactedByCreatedContractsPaging
          filter: $contactPilverContactedByContactedByCreatedContractsFilter
          sorting: $contactPilverContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $contactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $contactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $contactPilverContactedByContactedByCreatedMiddlemansPaging
          filter: $contactPilverContactedByContactedByCreatedMiddlemansFilter
          sorting: $contactPilverContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $contactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $contactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useContactPilverQuery__
 *
 * To run a query within a Vue component, call `useContactPilverQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPilverQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContactPilverQuery({
 *   id: // value for 'id'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByContactsPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByContactsFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByContactsSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'contactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   contactPilverCompanyCompanyHistoriesPaging: // value for 'contactPilverCompanyCompanyHistoriesPaging'
 *   contactPilverCompanyCompanyHistoriesFilter: // value for 'contactPilverCompanyCompanyHistoriesFilter'
 *   contactPilverCompanyCompanyHistoriesSorting: // value for 'contactPilverCompanyCompanyHistoriesSorting'
 *   contactPilverContactedByContactedByCreatedCompaniesPaging: // value for 'contactPilverContactedByContactedByCreatedCompaniesPaging'
 *   contactPilverContactedByContactedByCreatedCompaniesFilter: // value for 'contactPilverContactedByContactedByCreatedCompaniesFilter'
 *   contactPilverContactedByContactedByCreatedCompaniesSorting: // value for 'contactPilverContactedByContactedByCreatedCompaniesSorting'
 *   contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'contactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   contactPilverContactedByContactedByCreatedProjectsPaging: // value for 'contactPilverContactedByContactedByCreatedProjectsPaging'
 *   contactPilverContactedByContactedByCreatedProjectsFilter: // value for 'contactPilverContactedByContactedByCreatedProjectsFilter'
 *   contactPilverContactedByContactedByCreatedProjectsSorting: // value for 'contactPilverContactedByContactedByCreatedProjectsSorting'
 *   contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'contactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   contactPilverContactedByContactedByContactsPaging: // value for 'contactPilverContactedByContactedByContactsPaging'
 *   contactPilverContactedByContactedByContactsFilter: // value for 'contactPilverContactedByContactedByContactsFilter'
 *   contactPilverContactedByContactedByContactsSorting: // value for 'contactPilverContactedByContactedByContactsSorting'
 *   contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'contactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   contactPilverContactedByContactedByProjectChangesPaging: // value for 'contactPilverContactedByContactedByProjectChangesPaging'
 *   contactPilverContactedByContactedByProjectChangesFilter: // value for 'contactPilverContactedByContactedByProjectChangesFilter'
 *   contactPilverContactedByContactedByProjectChangesSorting: // value for 'contactPilverContactedByContactedByProjectChangesSorting'
 *   contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'contactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   contactPilverContactedByContactedByCreatedWorkersPaging: // value for 'contactPilverContactedByContactedByCreatedWorkersPaging'
 *   contactPilverContactedByContactedByCreatedWorkersFilter: // value for 'contactPilverContactedByContactedByCreatedWorkersFilter'
 *   contactPilverContactedByContactedByCreatedWorkersSorting: // value for 'contactPilverContactedByContactedByCreatedWorkersSorting'
 *   contactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'contactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   contactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'contactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   contactPilverContactedByContactedByCreatedContractsPaging: // value for 'contactPilverContactedByContactedByCreatedContractsPaging'
 *   contactPilverContactedByContactedByCreatedContractsFilter: // value for 'contactPilverContactedByContactedByCreatedContractsFilter'
 *   contactPilverContactedByContactedByCreatedContractsSorting: // value for 'contactPilverContactedByContactedByCreatedContractsSorting'
 *   contactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'contactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   contactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'contactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   contactPilverContactedByContactedByCreatedMiddlemansPaging: // value for 'contactPilverContactedByContactedByCreatedMiddlemansPaging'
 *   contactPilverContactedByContactedByCreatedMiddlemansFilter: // value for 'contactPilverContactedByContactedByCreatedMiddlemansFilter'
 *   contactPilverContactedByContactedByCreatedMiddlemansSorting: // value for 'contactPilverContactedByContactedByCreatedMiddlemansSorting'
 *   contactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'contactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   contactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'contactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useContactPilverQuery(
  variables:
    | ContactPilverQueryVariables
    | VueCompositionApi.Ref<ContactPilverQueryVariables>
    | ReactiveFunction<ContactPilverQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ContactPilverQuery,
        ContactPilverQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContactPilverQuery,
          ContactPilverQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContactPilverQuery,
          ContactPilverQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ContactPilverQuery,
    ContactPilverQueryVariables
  >(ContactPilverQueryDocument, variables, options);
}
export type ContactPilverQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ContactPilverQuery,
    ContactPilverQueryVariables
  >;
export const ContactPilversQueryDocument = gql`
  query contactPilversQuery(
    $paging: OffsetPaging
    $filter: ContactPilverFilter
    $sorting: [ContactPilverSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $contactPilversNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contactPilversNodesNodesCompanyCompanyHistoriesFilter: ContactPilverFilter
    $contactPilversNodesNodesCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $contactPilversNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $contactPilversNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $contactPilversNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $contactPilversNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $contactPilversNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $contactPilversNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contactPilversNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $contactPilversNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contactPilversNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $contactPilversNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $contactPilversNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $contactPilversNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $contactPilversNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $contactPilversNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $contactPilversNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $contactPilversNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $contactPilversNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $contactPilversNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $contactPilversNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $contactPilversNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $contactPilversNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    contactPilvers(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $contactPilversNodesNodesCompanyCompanyHistoriesPaging
            filter: $contactPilversNodesNodesCompanyCompanyHistoriesFilter
            sorting: $contactPilversNodesNodesCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $contactPilversNodesNodesContactedByContactedByCreatedCompaniesPaging
            filter: $contactPilversNodesNodesContactedByContactedByCreatedCompaniesFilter
            sorting: $contactPilversNodesNodesContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $contactPilversNodesNodesContactedByContactedByCreatedProjectsPaging
            filter: $contactPilversNodesNodesContactedByContactedByCreatedProjectsFilter
            sorting: $contactPilversNodesNodesContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $contactPilversNodesNodesContactedByContactedByContactsPaging
            filter: $contactPilversNodesNodesContactedByContactedByContactsFilter
            sorting: $contactPilversNodesNodesContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $contactPilversNodesNodesContactedByContactedByProjectChangesPaging
            filter: $contactPilversNodesNodesContactedByContactedByProjectChangesFilter
            sorting: $contactPilversNodesNodesContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $contactPilversNodesNodesContactedByContactedByCreatedWorkersPaging
            filter: $contactPilversNodesNodesContactedByContactedByCreatedWorkersFilter
            sorting: $contactPilversNodesNodesContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $contactPilversNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $contactPilversNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $contactPilversNodesNodesContactedByContactedByCreatedContractsPaging
            filter: $contactPilversNodesNodesContactedByContactedByCreatedContractsFilter
            sorting: $contactPilversNodesNodesContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $contactPilversNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $contactPilversNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $contactPilversNodesNodesContactedByContactedByCreatedMiddlemansPaging
            filter: $contactPilversNodesNodesContactedByContactedByCreatedMiddlemansFilter
            sorting: $contactPilversNodesNodesContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $contactPilversNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $contactPilversNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useContactPilversQuery__
 *
 * To run a query within a Vue component, call `useContactPilversQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPilversQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContactPilversQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'contactPilversNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   contactPilversNodesNodesCompanyCompanyHistoriesPaging: // value for 'contactPilversNodesNodesCompanyCompanyHistoriesPaging'
 *   contactPilversNodesNodesCompanyCompanyHistoriesFilter: // value for 'contactPilversNodesNodesCompanyCompanyHistoriesFilter'
 *   contactPilversNodesNodesCompanyCompanyHistoriesSorting: // value for 'contactPilversNodesNodesCompanyCompanyHistoriesSorting'
 *   contactPilversNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'contactPilversNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   contactPilversNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'contactPilversNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   contactPilversNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'contactPilversNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'contactPilversNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   contactPilversNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'contactPilversNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   contactPilversNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'contactPilversNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   contactPilversNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'contactPilversNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'contactPilversNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   contactPilversNodesNodesContactedByContactedByContactsPaging: // value for 'contactPilversNodesNodesContactedByContactedByContactsPaging'
 *   contactPilversNodesNodesContactedByContactedByContactsFilter: // value for 'contactPilversNodesNodesContactedByContactedByContactsFilter'
 *   contactPilversNodesNodesContactedByContactedByContactsSorting: // value for 'contactPilversNodesNodesContactedByContactedByContactsSorting'
 *   contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'contactPilversNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   contactPilversNodesNodesContactedByContactedByProjectChangesPaging: // value for 'contactPilversNodesNodesContactedByContactedByProjectChangesPaging'
 *   contactPilversNodesNodesContactedByContactedByProjectChangesFilter: // value for 'contactPilversNodesNodesContactedByContactedByProjectChangesFilter'
 *   contactPilversNodesNodesContactedByContactedByProjectChangesSorting: // value for 'contactPilversNodesNodesContactedByContactedByProjectChangesSorting'
 *   contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'contactPilversNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   contactPilversNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'contactPilversNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   contactPilversNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'contactPilversNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   contactPilversNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'contactPilversNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   contactPilversNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'contactPilversNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   contactPilversNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'contactPilversNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   contactPilversNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'contactPilversNodesNodesContactedByContactedByCreatedContractsPaging'
 *   contactPilversNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'contactPilversNodesNodesContactedByContactedByCreatedContractsFilter'
 *   contactPilversNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'contactPilversNodesNodesContactedByContactedByCreatedContractsSorting'
 *   contactPilversNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'contactPilversNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   contactPilversNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'contactPilversNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   contactPilversNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'contactPilversNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   contactPilversNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'contactPilversNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   contactPilversNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'contactPilversNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   contactPilversNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'contactPilversNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   contactPilversNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'contactPilversNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useContactPilversQuery(
  variables:
    | ContactPilversQueryVariables
    | VueCompositionApi.Ref<ContactPilversQueryVariables>
    | ReactiveFunction<ContactPilversQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        ContactPilversQuery,
        ContactPilversQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContactPilversQuery,
          ContactPilversQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContactPilversQuery,
          ContactPilversQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ContactPilversQuery,
    ContactPilversQueryVariables
  >(ContactPilversQueryDocument, variables, options);
}
export type ContactPilversQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ContactPilversQuery,
    ContactPilversQueryVariables
  >;
export const ProjectTypesQueryDocument = gql`
  query projectTypesQuery {
    projectTypes
  }
`;

/**
 * __useProjectTypesQuery__
 *
 * To run a query within a Vue component, call `useProjectTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTypesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProjectTypesQuery();
 */
export function useProjectTypesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        ProjectTypesQuery,
        ProjectTypesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ProjectTypesQuery,
          ProjectTypesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ProjectTypesQuery,
          ProjectTypesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ProjectTypesQuery,
    ProjectTypesQueryVariables
  >(ProjectTypesQueryDocument, {}, options);
}
export type ProjectTypesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ProjectTypesQuery,
    ProjectTypesQueryVariables
  >;
export const ProjectLanguagesQueryDocument = gql`
  query projectLanguagesQuery {
    projectLanguages
  }
`;

/**
 * __useProjectLanguagesQuery__
 *
 * To run a query within a Vue component, call `useProjectLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectLanguagesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProjectLanguagesQuery();
 */
export function useProjectLanguagesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        ProjectLanguagesQuery,
        ProjectLanguagesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ProjectLanguagesQuery,
          ProjectLanguagesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ProjectLanguagesQuery,
          ProjectLanguagesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ProjectLanguagesQuery,
    ProjectLanguagesQueryVariables
  >(ProjectLanguagesQueryDocument, {}, options);
}
export type ProjectLanguagesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ProjectLanguagesQuery,
    ProjectLanguagesQueryVariables
  >;
export const ProjectQueryDocument = gql`
  query projectQuery(
    $id: String!
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $projectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $projectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $projectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $projectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $projectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $projectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $projectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $projectCreatedByCreatedByContactsPaging: OffsetPaging
    $projectCreatedByCreatedByContactsFilter: ContactFilter
    $projectCreatedByCreatedByContactsSorting: [ContactSort!]
    $projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $projectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $projectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $projectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $projectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $projectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $projectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $projectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $projectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $projectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $projectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $projectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $projectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $projectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $projectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $projectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $projectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $projectHistoriesPaging: OffsetPaging
    $projectHistoriesFilter: ProjectChangeFilter
    $projectHistoriesSorting: [ProjectChangeSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    project(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $projectProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $projectProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $projectProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $projectCreatedByCreatedByCreatedCompaniesPaging
          filter: $projectCreatedByCreatedByCreatedCompaniesFilter
          sorting: $projectCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $projectCreatedByCreatedByCreatedProjectsPaging
          filter: $projectCreatedByCreatedByCreatedProjectsFilter
          sorting: $projectCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $projectCreatedByCreatedByContactsPaging
          filter: $projectCreatedByCreatedByContactsFilter
          sorting: $projectCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $projectCreatedByCreatedByProjectChangesPaging
          filter: $projectCreatedByCreatedByProjectChangesFilter
          sorting: $projectCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $projectCreatedByCreatedByCreatedWorkersPaging
          filter: $projectCreatedByCreatedByCreatedWorkersFilter
          sorting: $projectCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $projectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $projectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $projectCreatedByCreatedByCreatedContractsPaging
          filter: $projectCreatedByCreatedByCreatedContractsFilter
          sorting: $projectCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $projectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $projectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $projectCreatedByCreatedByCreatedMiddlemansPaging
          filter: $projectCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $projectCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $projectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $projectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $projectHistoriesPaging
        filter: $projectHistoriesFilter
        sorting: $projectHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useProjectQuery__
 *
 * To run a query within a Vue component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProjectQuery({
 *   id: // value for 'id'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *   projectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *   projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'projectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   projectCreatedByCreatedByCreatedCompaniesPaging: // value for 'projectCreatedByCreatedByCreatedCompaniesPaging'
 *   projectCreatedByCreatedByCreatedCompaniesFilter: // value for 'projectCreatedByCreatedByCreatedCompaniesFilter'
 *   projectCreatedByCreatedByCreatedCompaniesSorting: // value for 'projectCreatedByCreatedByCreatedCompaniesSorting'
 *   projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'projectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   projectCreatedByCreatedByCreatedProjectsPaging: // value for 'projectCreatedByCreatedByCreatedProjectsPaging'
 *   projectCreatedByCreatedByCreatedProjectsFilter: // value for 'projectCreatedByCreatedByCreatedProjectsFilter'
 *   projectCreatedByCreatedByCreatedProjectsSorting: // value for 'projectCreatedByCreatedByCreatedProjectsSorting'
 *   projectCreatedByCreatedByContactsPaging: // value for 'projectCreatedByCreatedByContactsPaging'
 *   projectCreatedByCreatedByContactsFilter: // value for 'projectCreatedByCreatedByContactsFilter'
 *   projectCreatedByCreatedByContactsSorting: // value for 'projectCreatedByCreatedByContactsSorting'
 *   projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   projectCreatedByCreatedByProjectChangesPaging: // value for 'projectCreatedByCreatedByProjectChangesPaging'
 *   projectCreatedByCreatedByProjectChangesFilter: // value for 'projectCreatedByCreatedByProjectChangesFilter'
 *   projectCreatedByCreatedByProjectChangesSorting: // value for 'projectCreatedByCreatedByProjectChangesSorting'
 *   projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   projectCreatedByCreatedByCreatedWorkersPaging: // value for 'projectCreatedByCreatedByCreatedWorkersPaging'
 *   projectCreatedByCreatedByCreatedWorkersFilter: // value for 'projectCreatedByCreatedByCreatedWorkersFilter'
 *   projectCreatedByCreatedByCreatedWorkersSorting: // value for 'projectCreatedByCreatedByCreatedWorkersSorting'
 *   projectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'projectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   projectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'projectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   projectCreatedByCreatedByCreatedContractsPaging: // value for 'projectCreatedByCreatedByCreatedContractsPaging'
 *   projectCreatedByCreatedByCreatedContractsFilter: // value for 'projectCreatedByCreatedByCreatedContractsFilter'
 *   projectCreatedByCreatedByCreatedContractsSorting: // value for 'projectCreatedByCreatedByCreatedContractsSorting'
 *   projectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'projectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   projectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'projectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   projectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'projectCreatedByCreatedByCreatedMiddlemansPaging'
 *   projectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'projectCreatedByCreatedByCreatedMiddlemansFilter'
 *   projectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'projectCreatedByCreatedByCreatedMiddlemansSorting'
 *   projectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'projectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   projectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'projectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   projectHistoriesPaging: // value for 'projectHistoriesPaging'
 *   projectHistoriesFilter: // value for 'projectHistoriesFilter'
 *   projectHistoriesSorting: // value for 'projectHistoriesSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'projectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useProjectQuery(
  variables:
    | ProjectQueryVariables
    | VueCompositionApi.Ref<ProjectQueryVariables>
    | ReactiveFunction<ProjectQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ProjectQuery, ProjectQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ProjectQuery, ProjectQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ProjectQuery, ProjectQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectQueryDocument,
    variables,
    options
  );
}
export type ProjectQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<ProjectQuery, ProjectQueryVariables>;
export const ProjectsQueryDocument = gql`
  query projectsQuery(
    $paging: OffsetPaging
    $filter: ProjectFilter
    $sorting: [ProjectSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $projectsNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $projectsNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $projectsNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $projectsNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $projectsNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $projectsNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $projectsNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $projectsNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $projectsNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectsNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $projectsNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $projectsNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectsNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $projectsNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $projectsNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $projectsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $projectsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $projectsNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $projectsNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $projectsNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $projectsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $projectsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $projectsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $projectsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $projectsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $projectsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $projectsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $projectsNodesNodesHistoriesPaging: OffsetPaging
    $projectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $projectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    projects(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Address
        Type
        Language
        Manager
        ManagerContact
        projectChange {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
              filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
              sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
              filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
              sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsPaging
              filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsFilter
              sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
              filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
              sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
              filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
              sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
              filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
              sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
              filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $projectsNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $projectsNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $projectsNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $projectsNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $projectsNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $projectsNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $projectsNodesNodesCreatedByCreatedByContactsPaging
            filter: $projectsNodesNodesCreatedByCreatedByContactsFilter
            sorting: $projectsNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $projectsNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $projectsNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $projectsNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $projectsNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $projectsNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $projectsNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $projectsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $projectsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $projectsNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $projectsNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $projectsNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $projectsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $projectsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $projectsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $projectsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $projectsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $projectsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $projectsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $projectsNodesNodesHistoriesPaging
          filter: $projectsNodesNodesHistoriesFilter
          sorting: $projectsNodesNodesHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
            modifiedBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
                filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
                sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
                filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
                sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              contacts(
                paging: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
                filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
                sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
                filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
                sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
                filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
                sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
                filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
                sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
                filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
                sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a Vue component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProjectsQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'projectsNodesNodesProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   projectsNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'projectsNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   projectsNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'projectsNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   projectsNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'projectsNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'projectsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   projectsNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'projectsNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   projectsNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'projectsNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   projectsNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'projectsNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   projectsNodesNodesCreatedByCreatedByContactsPaging: // value for 'projectsNodesNodesCreatedByCreatedByContactsPaging'
 *   projectsNodesNodesCreatedByCreatedByContactsFilter: // value for 'projectsNodesNodesCreatedByCreatedByContactsFilter'
 *   projectsNodesNodesCreatedByCreatedByContactsSorting: // value for 'projectsNodesNodesCreatedByCreatedByContactsSorting'
 *   projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   projectsNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'projectsNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   projectsNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'projectsNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   projectsNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'projectsNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   projectsNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'projectsNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   projectsNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'projectsNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   projectsNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'projectsNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   projectsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'projectsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   projectsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'projectsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   projectsNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'projectsNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   projectsNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'projectsNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   projectsNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'projectsNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   projectsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'projectsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   projectsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'projectsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   projectsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'projectsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   projectsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'projectsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   projectsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'projectsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   projectsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'projectsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   projectsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'projectsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   projectsNodesNodesHistoriesPaging: // value for 'projectsNodesNodesHistoriesPaging'
 *   projectsNodesNodesHistoriesFilter: // value for 'projectsNodesNodesHistoriesFilter'
 *   projectsNodesNodesHistoriesSorting: // value for 'projectsNodesNodesHistoriesSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'projectsNodesNodesHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useProjectsQuery(
  variables:
    | ProjectsQueryVariables
    | VueCompositionApi.Ref<ProjectsQueryVariables>
    | ReactiveFunction<ProjectsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<ProjectsQuery, ProjectsQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ProjectsQuery,
          ProjectsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ProjectsQuery,
          ProjectsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsQueryDocument,
    variables,
    options
  );
}
export type ProjectsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<ProjectsQuery, ProjectsQueryVariables>;
export const StatusesQueryDocument = gql`
  query statusesQuery {
    statuses
  }
`;

/**
 * __useStatusesQuery__
 *
 * To run a query within a Vue component, call `useStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useStatusesQuery();
 */
export function useStatusesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<StatusesQuery, StatusesQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          StatusesQuery,
          StatusesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          StatusesQuery,
          StatusesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<StatusesQuery, StatusesQueryVariables>(
    StatusesQueryDocument,
    {},
    options
  );
}
export type StatusesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<StatusesQuery, StatusesQueryVariables>;
export const ProjectChangeQueryDocument = gql`
  query projectChangeQuery(
    $id: String!
    $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $projectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $projectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $projectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $projectChangeProjectProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $projectChangeProjectProjectCreatedByCreatedByContactsFilter: ContactFilter
    $projectChangeProjectProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $projectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $projectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $projectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $projectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $projectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $projectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $projectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $projectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $projectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $projectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $projectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $projectChangeProjectProjectHistoriesPaging: OffsetPaging
    $projectChangeProjectProjectHistoriesFilter: ProjectChangeFilter
    $projectChangeProjectProjectHistoriesSorting: [ProjectChangeSort!]
    $projectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $projectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $projectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $projectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $projectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $projectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $projectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $projectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $projectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $projectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $projectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $projectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $projectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $projectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $projectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $projectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $projectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $projectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $projectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $projectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $projectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $projectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $projectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $projectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $projectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    projectChange(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Description
      State
      project {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Address
        Type
        Language
        Manager
        ManagerContact
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging
            filter: $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter
            sorting: $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $projectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging
            filter: $projectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter
            sorting: $projectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $projectChangeProjectProjectCreatedByCreatedByContactsPaging
            filter: $projectChangeProjectProjectCreatedByCreatedByContactsFilter
            sorting: $projectChangeProjectProjectCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $projectChangeProjectProjectCreatedByCreatedByProjectChangesPaging
            filter: $projectChangeProjectProjectCreatedByCreatedByProjectChangesFilter
            sorting: $projectChangeProjectProjectCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $projectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging
            filter: $projectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter
            sorting: $projectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $projectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $projectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $projectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging
            filter: $projectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter
            sorting: $projectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $projectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $projectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging
            filter: $projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $projectChangeProjectProjectHistoriesPaging
          filter: $projectChangeProjectProjectHistoriesFilter
          sorting: $projectChangeProjectProjectHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      modifiedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $projectChangeModifiedByModifiedByCreatedCompaniesPaging
          filter: $projectChangeModifiedByModifiedByCreatedCompaniesFilter
          sorting: $projectChangeModifiedByModifiedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $projectChangeModifiedByModifiedByCreatedProjectsPaging
          filter: $projectChangeModifiedByModifiedByCreatedProjectsFilter
          sorting: $projectChangeModifiedByModifiedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            histories(
              paging: $projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        contacts(
          paging: $projectChangeModifiedByModifiedByContactsPaging
          filter: $projectChangeModifiedByModifiedByContactsFilter
          sorting: $projectChangeModifiedByModifiedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $projectChangeModifiedByModifiedByProjectChangesPaging
          filter: $projectChangeModifiedByModifiedByProjectChangesFilter
          sorting: $projectChangeModifiedByModifiedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $projectChangeModifiedByModifiedByCreatedWorkersPaging
          filter: $projectChangeModifiedByModifiedByCreatedWorkersFilter
          sorting: $projectChangeModifiedByModifiedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $projectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $projectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $projectChangeModifiedByModifiedByCreatedContractsPaging
          filter: $projectChangeModifiedByModifiedByCreatedContractsFilter
          sorting: $projectChangeModifiedByModifiedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $projectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $projectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $projectChangeModifiedByModifiedByCreatedMiddlemansPaging
          filter: $projectChangeModifiedByModifiedByCreatedMiddlemansFilter
          sorting: $projectChangeModifiedByModifiedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $projectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $projectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useProjectChangeQuery__
 *
 * To run a query within a Vue component, call `useProjectChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectChangeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProjectChangeQuery({
 *   id: // value for 'id'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting'
 *   projectChangeProjectProjectCreatedByCreatedByContactsPaging: // value for 'projectChangeProjectProjectCreatedByCreatedByContactsPaging'
 *   projectChangeProjectProjectCreatedByCreatedByContactsFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByContactsFilter'
 *   projectChangeProjectProjectCreatedByCreatedByContactsSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByContactsSorting'
 *   projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   projectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: // value for 'projectChangeProjectProjectCreatedByCreatedByProjectChangesPaging'
 *   projectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByProjectChangesFilter'
 *   projectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByProjectChangesSorting'
 *   projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'projectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   projectChangeProjectProjectHistoriesPaging: // value for 'projectChangeProjectProjectHistoriesPaging'
 *   projectChangeProjectProjectHistoriesFilter: // value for 'projectChangeProjectProjectHistoriesFilter'
 *   projectChangeProjectProjectHistoriesSorting: // value for 'projectChangeProjectProjectHistoriesSorting'
 *   projectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'projectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *   projectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'projectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *   projectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'projectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *   projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'projectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   projectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'projectChangeModifiedByModifiedByCreatedProjectsPaging'
 *   projectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'projectChangeModifiedByModifiedByCreatedProjectsFilter'
 *   projectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'projectChangeModifiedByModifiedByCreatedProjectsSorting'
 *   projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'projectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   projectChangeModifiedByModifiedByContactsPaging: // value for 'projectChangeModifiedByModifiedByContactsPaging'
 *   projectChangeModifiedByModifiedByContactsFilter: // value for 'projectChangeModifiedByModifiedByContactsFilter'
 *   projectChangeModifiedByModifiedByContactsSorting: // value for 'projectChangeModifiedByModifiedByContactsSorting'
 *   projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   projectChangeModifiedByModifiedByProjectChangesPaging: // value for 'projectChangeModifiedByModifiedByProjectChangesPaging'
 *   projectChangeModifiedByModifiedByProjectChangesFilter: // value for 'projectChangeModifiedByModifiedByProjectChangesFilter'
 *   projectChangeModifiedByModifiedByProjectChangesSorting: // value for 'projectChangeModifiedByModifiedByProjectChangesSorting'
 *   projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   projectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'projectChangeModifiedByModifiedByCreatedWorkersPaging'
 *   projectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'projectChangeModifiedByModifiedByCreatedWorkersFilter'
 *   projectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'projectChangeModifiedByModifiedByCreatedWorkersSorting'
 *   projectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'projectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   projectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'projectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   projectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'projectChangeModifiedByModifiedByCreatedContractsPaging'
 *   projectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'projectChangeModifiedByModifiedByCreatedContractsFilter'
 *   projectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'projectChangeModifiedByModifiedByCreatedContractsSorting'
 *   projectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'projectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   projectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'projectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   projectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'projectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *   projectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'projectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *   projectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'projectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *   projectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'projectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   projectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'projectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useProjectChangeQuery(
  variables:
    | ProjectChangeQueryVariables
    | VueCompositionApi.Ref<ProjectChangeQueryVariables>
    | ReactiveFunction<ProjectChangeQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ProjectChangeQuery,
        ProjectChangeQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ProjectChangeQuery,
          ProjectChangeQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ProjectChangeQuery,
          ProjectChangeQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ProjectChangeQuery,
    ProjectChangeQueryVariables
  >(ProjectChangeQueryDocument, variables, options);
}
export type ProjectChangeQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ProjectChangeQuery,
    ProjectChangeQueryVariables
  >;
export const ProjectChangesQueryDocument = gql`
  query projectChangesQuery(
    $paging: OffsetPaging
    $filter: ProjectChangeFilter
    $sorting: [ProjectChangeSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsFilter: ContactFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $projectChangesNodesNodesProjectProjectHistoriesPaging: OffsetPaging
    $projectChangesNodesNodesProjectProjectHistoriesFilter: ProjectChangeFilter
    $projectChangesNodesNodesProjectProjectHistoriesSorting: [ProjectChangeSort!]
    $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $projectChangesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $projectChangesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectChangesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $projectChangesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $projectChangesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $projectChangesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $projectChangesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $projectChangesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $projectChangesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $projectChangesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $projectChangesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $projectChangesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    projectChanges(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        project {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesPaging
              filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesFilter
              sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsPaging
              filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsFilter
              sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsPaging
              filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsFilter
              sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesPaging
              filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesFilter
              sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersPaging
              filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersFilter
              sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsPaging
              filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsFilter
              sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansPaging
              filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $projectChangesNodesNodesProjectProjectHistoriesPaging
            filter: $projectChangesNodesNodesProjectProjectHistoriesFilter
            sorting: $projectChangesNodesNodesProjectProjectHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
            filter: $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
            sorting: $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsPaging
            filter: $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsFilter
            sorting: $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              histories(
                paging: $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
          contacts(
            paging: $projectChangesNodesNodesModifiedByModifiedByContactsPaging
            filter: $projectChangesNodesNodesModifiedByModifiedByContactsFilter
            sorting: $projectChangesNodesNodesModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $projectChangesNodesNodesModifiedByModifiedByProjectChangesPaging
            filter: $projectChangesNodesNodesModifiedByModifiedByProjectChangesFilter
            sorting: $projectChangesNodesNodesModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $projectChangesNodesNodesModifiedByModifiedByCreatedWorkersPaging
            filter: $projectChangesNodesNodesModifiedByModifiedByCreatedWorkersFilter
            sorting: $projectChangesNodesNodesModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $projectChangesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $projectChangesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $projectChangesNodesNodesModifiedByModifiedByCreatedContractsPaging
            filter: $projectChangesNodesNodesModifiedByModifiedByCreatedContractsFilter
            sorting: $projectChangesNodesNodesModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $projectChangesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $projectChangesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
            filter: $projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useProjectChangesQuery__
 *
 * To run a query within a Vue component, call `useProjectChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectChangesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProjectChangesQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesPaging'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsPaging'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedProjectsSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsPaging: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsPaging'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesPaging: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesPaging'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersPaging'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsPaging: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsPaging'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'projectChangesNodesNodesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   projectChangesNodesNodesProjectProjectHistoriesPaging: // value for 'projectChangesNodesNodesProjectProjectHistoriesPaging'
 *   projectChangesNodesNodesProjectProjectHistoriesFilter: // value for 'projectChangesNodesNodesProjectProjectHistoriesFilter'
 *   projectChangesNodesNodesProjectProjectHistoriesSorting: // value for 'projectChangesNodesNodesProjectProjectHistoriesSorting'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   projectChangesNodesNodesModifiedByModifiedByContactsPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByContactsPaging'
 *   projectChangesNodesNodesModifiedByModifiedByContactsFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByContactsFilter'
 *   projectChangesNodesNodesModifiedByModifiedByContactsSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByContactsSorting'
 *   projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   projectChangesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *   projectChangesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *   projectChangesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *   projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'projectChangesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useProjectChangesQuery(
  variables:
    | ProjectChangesQueryVariables
    | VueCompositionApi.Ref<ProjectChangesQueryVariables>
    | ReactiveFunction<ProjectChangesQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        ProjectChangesQuery,
        ProjectChangesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ProjectChangesQuery,
          ProjectChangesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ProjectChangesQuery,
          ProjectChangesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ProjectChangesQuery,
    ProjectChangesQueryVariables
  >(ProjectChangesQueryDocument, variables, options);
}
export type ProjectChangesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ProjectChangesQuery,
    ProjectChangesQueryVariables
  >;
export const WorkerTypesQueryDocument = gql`
  query workerTypesQuery {
    workerTypes
  }
`;

/**
 * __useWorkerTypesQuery__
 *
 * To run a query within a Vue component, call `useWorkerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkerTypesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWorkerTypesQuery();
 */
export function useWorkerTypesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        WorkerTypesQuery,
        WorkerTypesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          WorkerTypesQuery,
          WorkerTypesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          WorkerTypesQuery,
          WorkerTypesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    WorkerTypesQuery,
    WorkerTypesQueryVariables
  >(WorkerTypesQueryDocument, {}, options);
}
export type WorkerTypesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    WorkerTypesQuery,
    WorkerTypesQueryVariables
  >;
export const WorkerProfessionQueryDocument = gql`
  query workerProfessionQuery {
    workerProfession
  }
`;

/**
 * __useWorkerProfessionQuery__
 *
 * To run a query within a Vue component, call `useWorkerProfessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkerProfessionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWorkerProfessionQuery();
 */
export function useWorkerProfessionQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        WorkerProfessionQuery,
        WorkerProfessionQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          WorkerProfessionQuery,
          WorkerProfessionQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          WorkerProfessionQuery,
          WorkerProfessionQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    WorkerProfessionQuery,
    WorkerProfessionQueryVariables
  >(WorkerProfessionQueryDocument, {}, options);
}
export type WorkerProfessionQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    WorkerProfessionQuery,
    WorkerProfessionQueryVariables
  >;
export const WorkerQueryDocument = gql`
  query workerQuery(
    $id: String!
    $workerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $workerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $workerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $workerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $workerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $workerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $workerCreatedByCreatedByContactsPaging: OffsetPaging
    $workerCreatedByCreatedByContactsFilter: ContactFilter
    $workerCreatedByCreatedByContactsSorting: [ContactSort!]
    $workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $workerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $workerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $workerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $workerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $workerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $workerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $workerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $workerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $workerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $workerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $workerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $workerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $workerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $workerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $workerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $workerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $workerAttachmentsFilter: AttachmentFilter
    $workerAttachmentsSorting: [AttachmentSort!]
  ) {
    worker(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $workerCreatedByCreatedByCreatedCompaniesPaging
          filter: $workerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $workerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $workerCreatedByCreatedByCreatedProjectsPaging
          filter: $workerCreatedByCreatedByCreatedProjectsFilter
          sorting: $workerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $workerCreatedByCreatedByContactsPaging
          filter: $workerCreatedByCreatedByContactsFilter
          sorting: $workerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $workerCreatedByCreatedByProjectChangesPaging
          filter: $workerCreatedByCreatedByProjectChangesFilter
          sorting: $workerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $workerCreatedByCreatedByCreatedWorkersPaging
          filter: $workerCreatedByCreatedByCreatedWorkersFilter
          sorting: $workerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdContracts(
          paging: $workerCreatedByCreatedByCreatedContractsPaging
          filter: $workerCreatedByCreatedByCreatedContractsFilter
          sorting: $workerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $workerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $workerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $workerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $workerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $workerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $workerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $workerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $workerAttachmentsFilter
        sorting: $workerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useWorkerQuery__
 *
 * To run a query within a Vue component, call `useWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkerQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWorkerQuery({
 *   id: // value for 'id'
 *   workerCreatedByCreatedByCreatedCompaniesPaging: // value for 'workerCreatedByCreatedByCreatedCompaniesPaging'
 *   workerCreatedByCreatedByCreatedCompaniesFilter: // value for 'workerCreatedByCreatedByCreatedCompaniesFilter'
 *   workerCreatedByCreatedByCreatedCompaniesSorting: // value for 'workerCreatedByCreatedByCreatedCompaniesSorting'
 *   workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'workerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   workerCreatedByCreatedByCreatedProjectsPaging: // value for 'workerCreatedByCreatedByCreatedProjectsPaging'
 *   workerCreatedByCreatedByCreatedProjectsFilter: // value for 'workerCreatedByCreatedByCreatedProjectsFilter'
 *   workerCreatedByCreatedByCreatedProjectsSorting: // value for 'workerCreatedByCreatedByCreatedProjectsSorting'
 *   workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'workerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   workerCreatedByCreatedByContactsPaging: // value for 'workerCreatedByCreatedByContactsPaging'
 *   workerCreatedByCreatedByContactsFilter: // value for 'workerCreatedByCreatedByContactsFilter'
 *   workerCreatedByCreatedByContactsSorting: // value for 'workerCreatedByCreatedByContactsSorting'
 *   workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'workerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   workerCreatedByCreatedByProjectChangesPaging: // value for 'workerCreatedByCreatedByProjectChangesPaging'
 *   workerCreatedByCreatedByProjectChangesFilter: // value for 'workerCreatedByCreatedByProjectChangesFilter'
 *   workerCreatedByCreatedByProjectChangesSorting: // value for 'workerCreatedByCreatedByProjectChangesSorting'
 *   workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'workerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   workerCreatedByCreatedByCreatedWorkersPaging: // value for 'workerCreatedByCreatedByCreatedWorkersPaging'
 *   workerCreatedByCreatedByCreatedWorkersFilter: // value for 'workerCreatedByCreatedByCreatedWorkersFilter'
 *   workerCreatedByCreatedByCreatedWorkersSorting: // value for 'workerCreatedByCreatedByCreatedWorkersSorting'
 *   workerCreatedByCreatedByCreatedContractsPaging: // value for 'workerCreatedByCreatedByCreatedContractsPaging'
 *   workerCreatedByCreatedByCreatedContractsFilter: // value for 'workerCreatedByCreatedByCreatedContractsFilter'
 *   workerCreatedByCreatedByCreatedContractsSorting: // value for 'workerCreatedByCreatedByCreatedContractsSorting'
 *   workerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'workerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   workerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'workerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   workerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'workerCreatedByCreatedByCreatedMiddlemansPaging'
 *   workerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'workerCreatedByCreatedByCreatedMiddlemansFilter'
 *   workerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'workerCreatedByCreatedByCreatedMiddlemansSorting'
 *   workerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'workerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   workerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'workerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   workerAttachmentsFilter: // value for 'workerAttachmentsFilter'
 *   workerAttachmentsSorting: // value for 'workerAttachmentsSorting'
 * });
 */
export function useWorkerQuery(
  variables:
    | WorkerQueryVariables
    | VueCompositionApi.Ref<WorkerQueryVariables>
    | ReactiveFunction<WorkerQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<WorkerQuery, WorkerQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<WorkerQuery, WorkerQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<WorkerQuery, WorkerQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<WorkerQuery, WorkerQueryVariables>(
    WorkerQueryDocument,
    variables,
    options
  );
}
export type WorkerQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<WorkerQuery, WorkerQueryVariables>;
export const WorkersQueryDocument = gql`
  query workersQuery(
    $paging: OffsetPaging
    $filter: WorkerFilter
    $sorting: [WorkerSort!]
    $workersNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $workersNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $workersNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $workersNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $workersNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $workersNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $workersNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $workersNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $workersNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $workersNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $workersNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $workersNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $workersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $workersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $workersNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $workersNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $workersNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $workersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $workersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $workersNodesNodesAttachmentsFilter: AttachmentFilter
    $workersNodesNodesAttachmentsSorting: [AttachmentSort!]
  ) {
    workers(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Phone
        Email
        Note
        Profession
        Type
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $workersNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $workersNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $workersNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $workersNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $workersNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $workersNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $workersNodesNodesCreatedByCreatedByContactsPaging
            filter: $workersNodesNodesCreatedByCreatedByContactsFilter
            sorting: $workersNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $workersNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $workersNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $workersNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $workersNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $workersNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $workersNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdContracts(
            paging: $workersNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $workersNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $workersNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $workersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $workersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $workersNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $workersNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $workersNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $workersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $workersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        attachments(
          filter: $workersNodesNodesAttachmentsFilter
          sorting: $workersNodesNodesAttachmentsSorting
        ) {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
        }
      }
      totalCount
    }
  }
`;

/**
 * __useWorkersQuery__
 *
 * To run a query within a Vue component, call `useWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWorkersQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   workersNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'workersNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   workersNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'workersNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   workersNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'workersNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'workersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   workersNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'workersNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   workersNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'workersNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   workersNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'workersNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'workersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   workersNodesNodesCreatedByCreatedByContactsPaging: // value for 'workersNodesNodesCreatedByCreatedByContactsPaging'
 *   workersNodesNodesCreatedByCreatedByContactsFilter: // value for 'workersNodesNodesCreatedByCreatedByContactsFilter'
 *   workersNodesNodesCreatedByCreatedByContactsSorting: // value for 'workersNodesNodesCreatedByCreatedByContactsSorting'
 *   workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'workersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   workersNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'workersNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   workersNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'workersNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   workersNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'workersNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'workersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   workersNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'workersNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   workersNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'workersNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   workersNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'workersNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   workersNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'workersNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   workersNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'workersNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   workersNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'workersNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   workersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'workersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   workersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'workersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   workersNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'workersNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   workersNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'workersNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   workersNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'workersNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   workersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'workersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   workersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'workersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   workersNodesNodesAttachmentsFilter: // value for 'workersNodesNodesAttachmentsFilter'
 *   workersNodesNodesAttachmentsSorting: // value for 'workersNodesNodesAttachmentsSorting'
 * });
 */
export function useWorkersQuery(
  variables:
    | WorkersQueryVariables
    | VueCompositionApi.Ref<WorkersQueryVariables>
    | ReactiveFunction<WorkersQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<WorkersQuery, WorkersQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<WorkersQuery, WorkersQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<WorkersQuery, WorkersQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<WorkersQuery, WorkersQueryVariables>(
    WorkersQueryDocument,
    variables,
    options
  );
}
export type WorkersQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<WorkersQuery, WorkersQueryVariables>;
export const AttachmentQueryDocument = gql`
  query attachmentQuery($id: String!) {
    attachment(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useAttachmentQuery__
 *
 * To run a query within a Vue component, call `useAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAttachmentQuery({
 *   id: // value for 'id'
 * });
 */
export function useAttachmentQuery(
  variables:
    | AttachmentQueryVariables
    | VueCompositionApi.Ref<AttachmentQueryVariables>
    | ReactiveFunction<AttachmentQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        AttachmentQuery,
        AttachmentQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          AttachmentQuery,
          AttachmentQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          AttachmentQuery,
          AttachmentQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    AttachmentQuery,
    AttachmentQueryVariables
  >(AttachmentQueryDocument, variables, options);
}
export type AttachmentQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<AttachmentQuery, AttachmentQueryVariables>;
export const AttachmentsQueryDocument = gql`
  query attachmentsQuery(
    $paging: OffsetPaging
    $filter: AttachmentFilter
    $sorting: [AttachmentSort!]
  ) {
    attachments(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      totalCount
    }
  }
`;

/**
 * __useAttachmentsQuery__
 *
 * To run a query within a Vue component, call `useAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAttachmentsQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 * });
 */
export function useAttachmentsQuery(
  variables:
    | AttachmentsQueryVariables
    | VueCompositionApi.Ref<AttachmentsQueryVariables>
    | ReactiveFunction<AttachmentsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        AttachmentsQuery,
        AttachmentsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          AttachmentsQuery,
          AttachmentsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          AttachmentsQuery,
          AttachmentsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    AttachmentsQuery,
    AttachmentsQueryVariables
  >(AttachmentsQueryDocument, variables, options);
}
export type AttachmentsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    AttachmentsQuery,
    AttachmentsQueryVariables
  >;
export const ContractYearsQueryDocument = gql`
  query contractYearsQuery {
    contractYears
  }
`;

/**
 * __useContractYearsQuery__
 *
 * To run a query within a Vue component, call `useContractYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractYearsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContractYearsQuery();
 */
export function useContractYearsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        ContractYearsQuery,
        ContractYearsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContractYearsQuery,
          ContractYearsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContractYearsQuery,
          ContractYearsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ContractYearsQuery,
    ContractYearsQueryVariables
  >(ContractYearsQueryDocument, {}, options);
}
export type ContractYearsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ContractYearsQuery,
    ContractYearsQueryVariables
  >;
export const ContractQueryDocument = gql`
  query contractQuery(
    $id: String!
    $contractCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $contractCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $contractCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $contractCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $contractCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $contractCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $contractCreatedByCreatedByContactsPaging: OffsetPaging
    $contractCreatedByCreatedByContactsFilter: ContactFilter
    $contractCreatedByCreatedByContactsSorting: [ContactSort!]
    $contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contractCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $contractCreatedByCreatedByProjectChangesFilter: ContactFilter
    $contractCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contractCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $contractCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $contractCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $contractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $contractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $contractCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $contractCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $contractCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $contractCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $contractCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $contractCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $contractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $contractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $contractAttachmentsFilter: ContractAttachmentFilter
    $contractAttachmentsSorting: [ContractAttachmentSort!]
  ) {
    contract(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $contractCreatedByCreatedByCreatedCompaniesPaging
          filter: $contractCreatedByCreatedByCreatedCompaniesFilter
          sorting: $contractCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $contractCreatedByCreatedByCreatedProjectsPaging
          filter: $contractCreatedByCreatedByCreatedProjectsFilter
          sorting: $contractCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $contractCreatedByCreatedByContactsPaging
          filter: $contractCreatedByCreatedByContactsFilter
          sorting: $contractCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $contractCreatedByCreatedByProjectChangesPaging
          filter: $contractCreatedByCreatedByProjectChangesFilter
          sorting: $contractCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $contractCreatedByCreatedByCreatedWorkersPaging
          filter: $contractCreatedByCreatedByCreatedWorkersFilter
          sorting: $contractCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $contractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $contractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $contractCreatedByCreatedByCreatedContractsPaging
          filter: $contractCreatedByCreatedByCreatedContractsFilter
          sorting: $contractCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdMiddlemans(
          paging: $contractCreatedByCreatedByCreatedMiddlemansPaging
          filter: $contractCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $contractCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $contractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $contractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $contractAttachmentsFilter
        sorting: $contractAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useContractQuery__
 *
 * To run a query within a Vue component, call `useContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContractQuery({
 *   id: // value for 'id'
 *   contractCreatedByCreatedByCreatedCompaniesPaging: // value for 'contractCreatedByCreatedByCreatedCompaniesPaging'
 *   contractCreatedByCreatedByCreatedCompaniesFilter: // value for 'contractCreatedByCreatedByCreatedCompaniesFilter'
 *   contractCreatedByCreatedByCreatedCompaniesSorting: // value for 'contractCreatedByCreatedByCreatedCompaniesSorting'
 *   contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'contractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   contractCreatedByCreatedByCreatedProjectsPaging: // value for 'contractCreatedByCreatedByCreatedProjectsPaging'
 *   contractCreatedByCreatedByCreatedProjectsFilter: // value for 'contractCreatedByCreatedByCreatedProjectsFilter'
 *   contractCreatedByCreatedByCreatedProjectsSorting: // value for 'contractCreatedByCreatedByCreatedProjectsSorting'
 *   contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'contractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   contractCreatedByCreatedByContactsPaging: // value for 'contractCreatedByCreatedByContactsPaging'
 *   contractCreatedByCreatedByContactsFilter: // value for 'contractCreatedByCreatedByContactsFilter'
 *   contractCreatedByCreatedByContactsSorting: // value for 'contractCreatedByCreatedByContactsSorting'
 *   contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'contractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   contractCreatedByCreatedByProjectChangesPaging: // value for 'contractCreatedByCreatedByProjectChangesPaging'
 *   contractCreatedByCreatedByProjectChangesFilter: // value for 'contractCreatedByCreatedByProjectChangesFilter'
 *   contractCreatedByCreatedByProjectChangesSorting: // value for 'contractCreatedByCreatedByProjectChangesSorting'
 *   contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'contractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   contractCreatedByCreatedByCreatedWorkersPaging: // value for 'contractCreatedByCreatedByCreatedWorkersPaging'
 *   contractCreatedByCreatedByCreatedWorkersFilter: // value for 'contractCreatedByCreatedByCreatedWorkersFilter'
 *   contractCreatedByCreatedByCreatedWorkersSorting: // value for 'contractCreatedByCreatedByCreatedWorkersSorting'
 *   contractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'contractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   contractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'contractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   contractCreatedByCreatedByCreatedContractsPaging: // value for 'contractCreatedByCreatedByCreatedContractsPaging'
 *   contractCreatedByCreatedByCreatedContractsFilter: // value for 'contractCreatedByCreatedByCreatedContractsFilter'
 *   contractCreatedByCreatedByCreatedContractsSorting: // value for 'contractCreatedByCreatedByCreatedContractsSorting'
 *   contractCreatedByCreatedByCreatedMiddlemansPaging: // value for 'contractCreatedByCreatedByCreatedMiddlemansPaging'
 *   contractCreatedByCreatedByCreatedMiddlemansFilter: // value for 'contractCreatedByCreatedByCreatedMiddlemansFilter'
 *   contractCreatedByCreatedByCreatedMiddlemansSorting: // value for 'contractCreatedByCreatedByCreatedMiddlemansSorting'
 *   contractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'contractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   contractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'contractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   contractAttachmentsFilter: // value for 'contractAttachmentsFilter'
 *   contractAttachmentsSorting: // value for 'contractAttachmentsSorting'
 * });
 */
export function useContractQuery(
  variables:
    | ContractQueryVariables
    | VueCompositionApi.Ref<ContractQueryVariables>
    | ReactiveFunction<ContractQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ContractQuery, ContractQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContractQuery,
          ContractQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContractQuery,
          ContractQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<ContractQuery, ContractQueryVariables>(
    ContractQueryDocument,
    variables,
    options
  );
}
export type ContractQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<ContractQuery, ContractQueryVariables>;
export const ContractsQueryDocument = gql`
  query contractsQuery(
    $paging: OffsetPaging
    $filter: ContractFilter
    $sorting: [ContractSort!]
    $contractsNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $contractsNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $contractsNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $contractsNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $contractsNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $contractsNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contractsNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $contractsNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $contractsNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $contractsNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $contractsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $contractsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $contractsNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $contractsNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $contractsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $contractsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $contractsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $contractsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $contractsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $contractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $contractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
  ) {
    contracts(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Description
        Type
        Year
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $contractsNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $contractsNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $contractsNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $contractsNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $contractsNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $contractsNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $contractsNodesNodesCreatedByCreatedByContactsPaging
            filter: $contractsNodesNodesCreatedByCreatedByContactsFilter
            sorting: $contractsNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $contractsNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $contractsNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $contractsNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $contractsNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $contractsNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $contractsNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $contractsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $contractsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $contractsNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $contractsNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $contractsNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdMiddlemans(
            paging: $contractsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $contractsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $contractsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $contractsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $contractsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        attachments(
          filter: $contractsNodesNodesAttachmentsFilter
          sorting: $contractsNodesNodesAttachmentsSorting
        ) {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
        }
      }
      totalCount
    }
  }
`;

/**
 * __useContractsQuery__
 *
 * To run a query within a Vue component, call `useContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContractsQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   contractsNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'contractsNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   contractsNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'contractsNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   contractsNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'contractsNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'contractsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   contractsNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'contractsNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   contractsNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'contractsNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   contractsNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'contractsNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'contractsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   contractsNodesNodesCreatedByCreatedByContactsPaging: // value for 'contractsNodesNodesCreatedByCreatedByContactsPaging'
 *   contractsNodesNodesCreatedByCreatedByContactsFilter: // value for 'contractsNodesNodesCreatedByCreatedByContactsFilter'
 *   contractsNodesNodesCreatedByCreatedByContactsSorting: // value for 'contractsNodesNodesCreatedByCreatedByContactsSorting'
 *   contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'contractsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   contractsNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'contractsNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   contractsNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'contractsNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   contractsNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'contractsNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'contractsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   contractsNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'contractsNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   contractsNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'contractsNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   contractsNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'contractsNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   contractsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'contractsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   contractsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'contractsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   contractsNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'contractsNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   contractsNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'contractsNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   contractsNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'contractsNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   contractsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'contractsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   contractsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'contractsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   contractsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'contractsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   contractsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'contractsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   contractsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'contractsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   contractsNodesNodesAttachmentsFilter: // value for 'contractsNodesNodesAttachmentsFilter'
 *   contractsNodesNodesAttachmentsSorting: // value for 'contractsNodesNodesAttachmentsSorting'
 * });
 */
export function useContractsQuery(
  variables:
    | ContractsQueryVariables
    | VueCompositionApi.Ref<ContractsQueryVariables>
    | ReactiveFunction<ContractsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        ContractsQuery,
        ContractsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContractsQuery,
          ContractsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContractsQuery,
          ContractsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<ContractsQuery, ContractsQueryVariables>(
    ContractsQueryDocument,
    variables,
    options
  );
}
export type ContractsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<ContractsQuery, ContractsQueryVariables>;
export const ContractAttachmentQueryDocument = gql`
  query contractAttachmentQuery($id: String!) {
    contractAttachment(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useContractAttachmentQuery__
 *
 * To run a query within a Vue component, call `useContractAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractAttachmentQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContractAttachmentQuery({
 *   id: // value for 'id'
 * });
 */
export function useContractAttachmentQuery(
  variables:
    | ContractAttachmentQueryVariables
    | VueCompositionApi.Ref<ContractAttachmentQueryVariables>
    | ReactiveFunction<ContractAttachmentQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ContractAttachmentQuery,
        ContractAttachmentQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContractAttachmentQuery,
          ContractAttachmentQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContractAttachmentQuery,
          ContractAttachmentQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ContractAttachmentQuery,
    ContractAttachmentQueryVariables
  >(ContractAttachmentQueryDocument, variables, options);
}
export type ContractAttachmentQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ContractAttachmentQuery,
    ContractAttachmentQueryVariables
  >;
export const ContractAttachmentsQueryDocument = gql`
  query contractAttachmentsQuery(
    $paging: OffsetPaging
    $filter: ContractAttachmentFilter
    $sorting: [ContractAttachmentSort!]
  ) {
    contractAttachments(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      totalCount
    }
  }
`;

/**
 * __useContractAttachmentsQuery__
 *
 * To run a query within a Vue component, call `useContractAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractAttachmentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContractAttachmentsQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 * });
 */
export function useContractAttachmentsQuery(
  variables:
    | ContractAttachmentsQueryVariables
    | VueCompositionApi.Ref<ContractAttachmentsQueryVariables>
    | ReactiveFunction<ContractAttachmentsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        ContractAttachmentsQuery,
        ContractAttachmentsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContractAttachmentsQuery,
          ContractAttachmentsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContractAttachmentsQuery,
          ContractAttachmentsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ContractAttachmentsQuery,
    ContractAttachmentsQueryVariables
  >(ContractAttachmentsQueryDocument, variables, options);
}
export type ContractAttachmentsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ContractAttachmentsQuery,
    ContractAttachmentsQueryVariables
  >;
export const ContractPilverYearsQueryDocument = gql`
  query contractPilverYearsQuery {
    contractPilverYears
  }
`;

/**
 * __useContractPilverYearsQuery__
 *
 * To run a query within a Vue component, call `useContractPilverYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractPilverYearsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContractPilverYearsQuery();
 */
export function useContractPilverYearsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        ContractPilverYearsQuery,
        ContractPilverYearsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContractPilverYearsQuery,
          ContractPilverYearsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContractPilverYearsQuery,
          ContractPilverYearsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ContractPilverYearsQuery,
    ContractPilverYearsQueryVariables
  >(ContractPilverYearsQueryDocument, {}, options);
}
export type ContractPilverYearsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ContractPilverYearsQuery,
    ContractPilverYearsQueryVariables
  >;
export const ContractPilverQueryDocument = gql`
  query contractPilverQuery(
    $id: String!
    $contractPilverAttachmentsFilter: ContractPilverAttachmentFilter
    $contractPilverAttachmentsSorting: [ContractPilverAttachmentSort!]
  ) {
    contractPilver(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      attachments(
        filter: $contractPilverAttachmentsFilter
        sorting: $contractPilverAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useContractPilverQuery__
 *
 * To run a query within a Vue component, call `useContractPilverQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractPilverQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContractPilverQuery({
 *   id: // value for 'id'
 *   contractPilverAttachmentsFilter: // value for 'contractPilverAttachmentsFilter'
 *   contractPilverAttachmentsSorting: // value for 'contractPilverAttachmentsSorting'
 * });
 */
export function useContractPilverQuery(
  variables:
    | ContractPilverQueryVariables
    | VueCompositionApi.Ref<ContractPilverQueryVariables>
    | ReactiveFunction<ContractPilverQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ContractPilverQuery,
        ContractPilverQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContractPilverQuery,
          ContractPilverQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContractPilverQuery,
          ContractPilverQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ContractPilverQuery,
    ContractPilverQueryVariables
  >(ContractPilverQueryDocument, variables, options);
}
export type ContractPilverQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ContractPilverQuery,
    ContractPilverQueryVariables
  >;
export const ContractPilversQueryDocument = gql`
  query contractPilversQuery(
    $paging: OffsetPaging
    $filter: ContractPilverFilter
    $sorting: [ContractPilverSort!]
    $contractPilversNodesNodesAttachmentsFilter: ContractPilverAttachmentFilter
    $contractPilversNodesNodesAttachmentsSorting: [ContractPilverAttachmentSort!]
  ) {
    contractPilvers(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Description
        Type
        Year
        attachments(
          filter: $contractPilversNodesNodesAttachmentsFilter
          sorting: $contractPilversNodesNodesAttachmentsSorting
        ) {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
        }
      }
      totalCount
    }
  }
`;

/**
 * __useContractPilversQuery__
 *
 * To run a query within a Vue component, call `useContractPilversQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractPilversQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContractPilversQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   contractPilversNodesNodesAttachmentsFilter: // value for 'contractPilversNodesNodesAttachmentsFilter'
 *   contractPilversNodesNodesAttachmentsSorting: // value for 'contractPilversNodesNodesAttachmentsSorting'
 * });
 */
export function useContractPilversQuery(
  variables:
    | ContractPilversQueryVariables
    | VueCompositionApi.Ref<ContractPilversQueryVariables>
    | ReactiveFunction<ContractPilversQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        ContractPilversQuery,
        ContractPilversQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContractPilversQuery,
          ContractPilversQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContractPilversQuery,
          ContractPilversQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ContractPilversQuery,
    ContractPilversQueryVariables
  >(ContractPilversQueryDocument, variables, options);
}
export type ContractPilversQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ContractPilversQuery,
    ContractPilversQueryVariables
  >;
export const ContractPilverAttachmentQueryDocument = gql`
  query contractPilverAttachmentQuery($id: String!) {
    contractPilverAttachment(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useContractPilverAttachmentQuery__
 *
 * To run a query within a Vue component, call `useContractPilverAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractPilverAttachmentQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContractPilverAttachmentQuery({
 *   id: // value for 'id'
 * });
 */
export function useContractPilverAttachmentQuery(
  variables:
    | ContractPilverAttachmentQueryVariables
    | VueCompositionApi.Ref<ContractPilverAttachmentQueryVariables>
    | ReactiveFunction<ContractPilverAttachmentQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ContractPilverAttachmentQuery,
        ContractPilverAttachmentQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContractPilverAttachmentQuery,
          ContractPilverAttachmentQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContractPilverAttachmentQuery,
          ContractPilverAttachmentQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ContractPilverAttachmentQuery,
    ContractPilverAttachmentQueryVariables
  >(ContractPilverAttachmentQueryDocument, variables, options);
}
export type ContractPilverAttachmentQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ContractPilverAttachmentQuery,
    ContractPilverAttachmentQueryVariables
  >;
export const ContractPilverAttachmentsQueryDocument = gql`
  query contractPilverAttachmentsQuery(
    $paging: OffsetPaging
    $filter: ContractPilverAttachmentFilter
    $sorting: [ContractPilverAttachmentSort!]
  ) {
    contractPilverAttachments(
      paging: $paging
      filter: $filter
      sorting: $sorting
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      totalCount
    }
  }
`;

/**
 * __useContractPilverAttachmentsQuery__
 *
 * To run a query within a Vue component, call `useContractPilverAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractPilverAttachmentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useContractPilverAttachmentsQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 * });
 */
export function useContractPilverAttachmentsQuery(
  variables:
    | ContractPilverAttachmentsQueryVariables
    | VueCompositionApi.Ref<ContractPilverAttachmentsQueryVariables>
    | ReactiveFunction<ContractPilverAttachmentsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        ContractPilverAttachmentsQuery,
        ContractPilverAttachmentsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ContractPilverAttachmentsQuery,
          ContractPilverAttachmentsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ContractPilverAttachmentsQuery,
          ContractPilverAttachmentsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ContractPilverAttachmentsQuery,
    ContractPilverAttachmentsQueryVariables
  >(ContractPilverAttachmentsQueryDocument, variables, options);
}
export type ContractPilverAttachmentsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ContractPilverAttachmentsQuery,
    ContractPilverAttachmentsQueryVariables
  >;
export const DeletedCompanyQueryDocument = gql`
  query deletedCompanyQuery(
    $id: String!
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyContactContactCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyContactContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $deletedCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $deletedCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $deletedCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $deletedCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $deletedCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $deletedCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyHistoriesFilter: ContactFilter
    $deletedCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyDeletedByDeletedByContactsPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByContactsFilter: ContactFilter
    $deletedCompanyDeletedByDeletedByContactsSorting: [ContactSort!]
    $deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByProjectChangesFilter: ContactFilter
    $deletedCompanyDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $deletedCompanyDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    deletedCompany(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $deletedCompanyContactContactCompanyCompanyHistoriesPaging
            filter: $deletedCompanyContactContactCompanyCompanyHistoriesFilter
            sorting: $deletedCompanyContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $deletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $deletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $deletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
          createdProjects(
            paging: $deletedCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $deletedCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $deletedCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $deletedCompanyContactContactContactedByContactedByContactsPaging
            filter: $deletedCompanyContactContactContactedByContactedByContactsFilter
            sorting: $deletedCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $deletedCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $deletedCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $deletedCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $deletedCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $deletedCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $deletedCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $deletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $deletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $deletedCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $deletedCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $deletedCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $deletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $deletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $deletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $deletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $deletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $deletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $deletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $deletedCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $deletedCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $deletedCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $deletedCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $deletedCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $deletedCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $deletedCompanyCreatedByCreatedByContactsPaging
          filter: $deletedCompanyCreatedByCreatedByContactsFilter
          sorting: $deletedCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $deletedCompanyCreatedByCreatedByProjectChangesPaging
          filter: $deletedCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $deletedCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $deletedCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $deletedCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $deletedCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $deletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $deletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $deletedCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $deletedCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $deletedCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $deletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $deletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $deletedCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $deletedCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $deletedCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $deletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $deletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $deletedCompanyHistoriesPaging
        filter: $deletedCompanyHistoriesFilter
        sorting: $deletedCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdProjects(
                paging: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
            createdProjects(
              paging: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $deletedCompanyDeletedByDeletedByCreatedCompaniesPaging
          filter: $deletedCompanyDeletedByDeletedByCreatedCompaniesFilter
          sorting: $deletedCompanyDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $deletedCompanyDeletedByDeletedByCreatedProjectsPaging
          filter: $deletedCompanyDeletedByDeletedByCreatedProjectsFilter
          sorting: $deletedCompanyDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $deletedCompanyDeletedByDeletedByContactsPaging
          filter: $deletedCompanyDeletedByDeletedByContactsFilter
          sorting: $deletedCompanyDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $deletedCompanyDeletedByDeletedByProjectChangesPaging
          filter: $deletedCompanyDeletedByDeletedByProjectChangesFilter
          sorting: $deletedCompanyDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $deletedCompanyDeletedByDeletedByCreatedWorkersPaging
          filter: $deletedCompanyDeletedByDeletedByCreatedWorkersFilter
          sorting: $deletedCompanyDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $deletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $deletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $deletedCompanyDeletedByDeletedByCreatedContractsPaging
          filter: $deletedCompanyDeletedByDeletedByCreatedContractsFilter
          sorting: $deletedCompanyDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $deletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $deletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $deletedCompanyDeletedByDeletedByCreatedMiddlemansPaging
          filter: $deletedCompanyDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $deletedCompanyDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $deletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $deletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDeletedCompanyQuery__
 *
 * To run a query within a Vue component, call `useDeletedCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedCompanyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDeletedCompanyQuery({
 *   id: // value for 'id'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyContactContactCompanyCompanyHistoriesPaging: // value for 'deletedCompanyContactContactCompanyCompanyHistoriesPaging'
 *   deletedCompanyContactContactCompanyCompanyHistoriesFilter: // value for 'deletedCompanyContactContactCompanyCompanyHistoriesFilter'
 *   deletedCompanyContactContactCompanyCompanyHistoriesSorting: // value for 'deletedCompanyContactContactCompanyCompanyHistoriesSorting'
 *   deletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'deletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *   deletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'deletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *   deletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'deletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *   deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'deletedCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *   deletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'deletedCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *   deletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'deletedCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *   deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyContactContactContactedByContactedByContactsPaging: // value for 'deletedCompanyContactContactContactedByContactedByContactsPaging'
 *   deletedCompanyContactContactContactedByContactedByContactsFilter: // value for 'deletedCompanyContactContactContactedByContactedByContactsFilter'
 *   deletedCompanyContactContactContactedByContactedByContactsSorting: // value for 'deletedCompanyContactContactContactedByContactedByContactsSorting'
 *   deletedCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'deletedCompanyContactContactContactedByContactedByProjectChangesPaging'
 *   deletedCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'deletedCompanyContactContactContactedByContactedByProjectChangesFilter'
 *   deletedCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'deletedCompanyContactContactContactedByContactedByProjectChangesSorting'
 *   deletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'deletedCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *   deletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'deletedCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *   deletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'deletedCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *   deletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'deletedCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *   deletedCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'deletedCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *   deletedCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'deletedCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *   deletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'deletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *   deletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'deletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *   deletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'deletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *   deletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyCreatedByCreatedByContactsPaging: // value for 'deletedCompanyCreatedByCreatedByContactsPaging'
 *   deletedCompanyCreatedByCreatedByContactsFilter: // value for 'deletedCompanyCreatedByCreatedByContactsFilter'
 *   deletedCompanyCreatedByCreatedByContactsSorting: // value for 'deletedCompanyCreatedByCreatedByContactsSorting'
 *   deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompanyCreatedByCreatedByProjectChangesPaging'
 *   deletedCompanyCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompanyCreatedByCreatedByProjectChangesFilter'
 *   deletedCompanyCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompanyCreatedByCreatedByProjectChangesSorting'
 *   deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompanyCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompanyCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompanyCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyHistoriesPaging: // value for 'deletedCompanyHistoriesPaging'
 *   deletedCompanyHistoriesFilter: // value for 'deletedCompanyHistoriesFilter'
 *   deletedCompanyHistoriesSorting: // value for 'deletedCompanyHistoriesSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyDeletedByDeletedByCreatedCompaniesPaging: // value for 'deletedCompanyDeletedByDeletedByCreatedCompaniesPaging'
 *   deletedCompanyDeletedByDeletedByCreatedCompaniesFilter: // value for 'deletedCompanyDeletedByDeletedByCreatedCompaniesFilter'
 *   deletedCompanyDeletedByDeletedByCreatedCompaniesSorting: // value for 'deletedCompanyDeletedByDeletedByCreatedCompaniesSorting'
 *   deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyDeletedByDeletedByCreatedProjectsPaging: // value for 'deletedCompanyDeletedByDeletedByCreatedProjectsPaging'
 *   deletedCompanyDeletedByDeletedByCreatedProjectsFilter: // value for 'deletedCompanyDeletedByDeletedByCreatedProjectsFilter'
 *   deletedCompanyDeletedByDeletedByCreatedProjectsSorting: // value for 'deletedCompanyDeletedByDeletedByCreatedProjectsSorting'
 *   deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyDeletedByDeletedByContactsPaging: // value for 'deletedCompanyDeletedByDeletedByContactsPaging'
 *   deletedCompanyDeletedByDeletedByContactsFilter: // value for 'deletedCompanyDeletedByDeletedByContactsFilter'
 *   deletedCompanyDeletedByDeletedByContactsSorting: // value for 'deletedCompanyDeletedByDeletedByContactsSorting'
 *   deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyDeletedByDeletedByProjectChangesPaging: // value for 'deletedCompanyDeletedByDeletedByProjectChangesPaging'
 *   deletedCompanyDeletedByDeletedByProjectChangesFilter: // value for 'deletedCompanyDeletedByDeletedByProjectChangesFilter'
 *   deletedCompanyDeletedByDeletedByProjectChangesSorting: // value for 'deletedCompanyDeletedByDeletedByProjectChangesSorting'
 *   deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyDeletedByDeletedByCreatedWorkersPaging: // value for 'deletedCompanyDeletedByDeletedByCreatedWorkersPaging'
 *   deletedCompanyDeletedByDeletedByCreatedWorkersFilter: // value for 'deletedCompanyDeletedByDeletedByCreatedWorkersFilter'
 *   deletedCompanyDeletedByDeletedByCreatedWorkersSorting: // value for 'deletedCompanyDeletedByDeletedByCreatedWorkersSorting'
 *   deletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyDeletedByDeletedByCreatedContractsPaging: // value for 'deletedCompanyDeletedByDeletedByCreatedContractsPaging'
 *   deletedCompanyDeletedByDeletedByCreatedContractsFilter: // value for 'deletedCompanyDeletedByDeletedByCreatedContractsFilter'
 *   deletedCompanyDeletedByDeletedByCreatedContractsSorting: // value for 'deletedCompanyDeletedByDeletedByCreatedContractsSorting'
 *   deletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: // value for 'deletedCompanyDeletedByDeletedByCreatedMiddlemansPaging'
 *   deletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: // value for 'deletedCompanyDeletedByDeletedByCreatedMiddlemansFilter'
 *   deletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: // value for 'deletedCompanyDeletedByDeletedByCreatedMiddlemansSorting'
 *   deletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useDeletedCompanyQuery(
  variables:
    | DeletedCompanyQueryVariables
    | VueCompositionApi.Ref<DeletedCompanyQueryVariables>
    | ReactiveFunction<DeletedCompanyQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        DeletedCompanyQuery,
        DeletedCompanyQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          DeletedCompanyQuery,
          DeletedCompanyQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          DeletedCompanyQuery,
          DeletedCompanyQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    DeletedCompanyQuery,
    DeletedCompanyQueryVariables
  >(DeletedCompanyQueryDocument, variables, options);
}
export type DeletedCompanyQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    DeletedCompanyQuery,
    DeletedCompanyQueryVariables
  >;
export const DeletedCompaniesQueryDocument = gql`
  query deletedCompaniesQuery(
    $paging: OffsetPaging
    $filter: DeletedCompanyFilter
    $sorting: [DeletedCompanySort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByContactsPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactContactedByContactedByContactsFilter: ContactFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByContactsPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByContactsFilter: ContactFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByContactsSorting: [ContactSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesFilter: ContactFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    deletedCompanies(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        contact {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdProjects(
                paging: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesPaging
              filter: $deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesFilter
              sorting: $deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging
              filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter
              sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
            createdProjects(
              paging: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsPaging
              filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsFilter
              sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $deletedCompaniesNodesNodesContactContactContactedByContactedByContactsPaging
              filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByContactsFilter
              sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesPaging
              filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesFilter
              sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersPaging
              filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersFilter
              sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsPaging
              filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsFilter
              sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging
              filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter
              sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $deletedCompaniesNodesNodesCreatedByCreatedByContactsPaging
            filter: $deletedCompaniesNodesNodesCreatedByCreatedByContactsFilter
            sorting: $deletedCompaniesNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $deletedCompaniesNodesNodesHistoriesPaging
          filter: $deletedCompaniesNodesNodesHistoriesFilter
          sorting: $deletedCompaniesNodesNodesHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              createdBy {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Username
                Email
                Status
                Role
                createdCompanies(
                  paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                  filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                  sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
                createdProjects(
                  paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                  filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                  sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Address
                    Type
                    Language
                    Manager
                    ManagerContact
                    projectChange {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                    histories(
                      paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                      filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                      sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                      nodes {
                        Id
                        CreatedAt
                        UpdatedAt
                        DeletedAt
                        Description
                        State
                      }
                    }
                  }
                }
                contacts(
                  paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                  filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                  sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
                projectChanges(
                  paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                  filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                  sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
                createdWorkers(
                  paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                  filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                  sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Phone
                    Email
                    Note
                    Profession
                    Type
                    attachments(
                      filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                      sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                    ) {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Name
                    }
                  }
                }
                createdContracts(
                  paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                  filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                  sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Description
                    Type
                    Year
                    attachments(
                      filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                      sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                    ) {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Name
                    }
                  }
                }
                createdMiddlemans(
                  paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                  filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                  sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Phone
                    Email
                    Note
                    attachments(
                      filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                      sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                    ) {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Name
                    }
                  }
                }
              }
              histories(
                paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
            contactedBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
                filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
                sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
              createdProjects(
                paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
                filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
                sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
                filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
                sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
                filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
                sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
                filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
                sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
                filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
                sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
                filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
                sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
          }
        }
        deletedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesPaging
            filter: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesFilter
            sorting: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsPaging
            filter: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsFilter
            sorting: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $deletedCompaniesNodesNodesDeletedByDeletedByContactsPaging
            filter: $deletedCompaniesNodesNodesDeletedByDeletedByContactsFilter
            sorting: $deletedCompaniesNodesNodesDeletedByDeletedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesPaging
            filter: $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesFilter
            sorting: $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersPaging
            filter: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersFilter
            sorting: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsPaging
            filter: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsFilter
            sorting: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansPaging
            filter: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansFilter
            sorting: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useDeletedCompaniesQuery__
 *
 * To run a query within a Vue component, call `useDeletedCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedCompaniesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDeletedCompaniesQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesPaging: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesPaging'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesFilter: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesFilter'
 *   deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesSorting: // value for 'deletedCompaniesNodesNodesContactContactCompanyCompanyHistoriesSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsPaging: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsPaging'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByContactsPaging: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByContactsPaging'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByContactsFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByContactsFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByContactsSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByContactsSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesPaging: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesPaging'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByProjectChangesSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersPaging: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersPaging'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsPaging: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsPaging'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByContactsPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByContactsPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByContactsFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByContactsFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByContactsSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByContactsSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByContactsPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByContactsPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByContactsFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByContactsFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByContactsSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByContactsSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansPaging: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansPaging'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansSorting'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompaniesNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useDeletedCompaniesQuery(
  variables:
    | DeletedCompaniesQueryVariables
    | VueCompositionApi.Ref<DeletedCompaniesQueryVariables>
    | ReactiveFunction<DeletedCompaniesQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        DeletedCompaniesQuery,
        DeletedCompaniesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          DeletedCompaniesQuery,
          DeletedCompaniesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          DeletedCompaniesQuery,
          DeletedCompaniesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    DeletedCompaniesQuery,
    DeletedCompaniesQueryVariables
  >(DeletedCompaniesQueryDocument, variables, options);
}
export type DeletedCompaniesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    DeletedCompaniesQuery,
    DeletedCompaniesQueryVariables
  >;
export const DeletedCompanyPilverQueryDocument = gql`
  query deletedCompanyPilverQuery(
    $id: String!
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: ContactPilverFilter
    $deletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $deletedCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyPilverHistoriesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesFilter: ContactPilverFilter
    $deletedCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactPilverFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilverDeletedByDeletedByContactsPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByContactsFilter: ContactFilter
    $deletedCompanyPilverDeletedByDeletedByContactsSorting: [ContactSort!]
    $deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilverDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    deletedCompanyPilver(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $deletedCompanyPilverContactContactCompanyCompanyHistoriesPaging
            filter: $deletedCompanyPilverContactContactCompanyCompanyHistoriesFilter
            sorting: $deletedCompanyPilverContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $deletedCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $deletedCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $deletedCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $deletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $deletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $deletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $deletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $deletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $deletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $deletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $deletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $deletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $deletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $deletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $deletedCompanyPilverCreatedByCreatedByContactsPaging
          filter: $deletedCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $deletedCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $deletedCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $deletedCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $deletedCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $deletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $deletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $deletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $deletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $deletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $deletedCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $deletedCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $deletedCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $deletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $deletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $deletedCompanyPilverHistoriesPaging
        filter: $deletedCompanyPilverHistoriesFilter
        sorting: $deletedCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging
          filter: $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter
          sorting: $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $deletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging
          filter: $deletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter
          sorting: $deletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $deletedCompanyPilverDeletedByDeletedByContactsPaging
          filter: $deletedCompanyPilverDeletedByDeletedByContactsFilter
          sorting: $deletedCompanyPilverDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $deletedCompanyPilverDeletedByDeletedByProjectChangesPaging
          filter: $deletedCompanyPilverDeletedByDeletedByProjectChangesFilter
          sorting: $deletedCompanyPilverDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $deletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging
          filter: $deletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter
          sorting: $deletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $deletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $deletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $deletedCompanyPilverDeletedByDeletedByCreatedContractsPaging
          filter: $deletedCompanyPilverDeletedByDeletedByCreatedContractsFilter
          sorting: $deletedCompanyPilverDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $deletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $deletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging
          filter: $deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDeletedCompanyPilverQuery__
 *
 * To run a query within a Vue component, call `useDeletedCompanyPilverQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedCompanyPilverQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDeletedCompanyPilverQuery({
 *   id: // value for 'id'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverContactContactCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverContactContactCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverContactContactCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByContactsPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByContactsFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByContactsSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilverCreatedByCreatedByContactsPaging: // value for 'deletedCompanyPilverCreatedByCreatedByContactsPaging'
 *   deletedCompanyPilverCreatedByCreatedByContactsFilter: // value for 'deletedCompanyPilverCreatedByCreatedByContactsFilter'
 *   deletedCompanyPilverCreatedByCreatedByContactsSorting: // value for 'deletedCompanyPilverCreatedByCreatedByContactsSorting'
 *   deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *   deletedCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *   deletedCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *   deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverHistoriesPaging: // value for 'deletedCompanyPilverHistoriesPaging'
 *   deletedCompanyPilverHistoriesFilter: // value for 'deletedCompanyPilverHistoriesFilter'
 *   deletedCompanyPilverHistoriesSorting: // value for 'deletedCompanyPilverHistoriesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging'
 *   deletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter'
 *   deletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting'
 *   deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging'
 *   deletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter'
 *   deletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting'
 *   deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilverDeletedByDeletedByContactsPaging: // value for 'deletedCompanyPilverDeletedByDeletedByContactsPaging'
 *   deletedCompanyPilverDeletedByDeletedByContactsFilter: // value for 'deletedCompanyPilverDeletedByDeletedByContactsFilter'
 *   deletedCompanyPilverDeletedByDeletedByContactsSorting: // value for 'deletedCompanyPilverDeletedByDeletedByContactsSorting'
 *   deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverDeletedByDeletedByProjectChangesPaging: // value for 'deletedCompanyPilverDeletedByDeletedByProjectChangesPaging'
 *   deletedCompanyPilverDeletedByDeletedByProjectChangesFilter: // value for 'deletedCompanyPilverDeletedByDeletedByProjectChangesFilter'
 *   deletedCompanyPilverDeletedByDeletedByProjectChangesSorting: // value for 'deletedCompanyPilverDeletedByDeletedByProjectChangesSorting'
 *   deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging'
 *   deletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter'
 *   deletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting'
 *   deletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedContractsPaging'
 *   deletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedContractsFilter'
 *   deletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedContractsSorting'
 *   deletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging'
 *   deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter'
 *   deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting'
 *   deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useDeletedCompanyPilverQuery(
  variables:
    | DeletedCompanyPilverQueryVariables
    | VueCompositionApi.Ref<DeletedCompanyPilverQueryVariables>
    | ReactiveFunction<DeletedCompanyPilverQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        DeletedCompanyPilverQuery,
        DeletedCompanyPilverQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          DeletedCompanyPilverQuery,
          DeletedCompanyPilverQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          DeletedCompanyPilverQuery,
          DeletedCompanyPilverQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    DeletedCompanyPilverQuery,
    DeletedCompanyPilverQueryVariables
  >(DeletedCompanyPilverQueryDocument, variables, options);
}
export type DeletedCompanyPilverQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    DeletedCompanyPilverQuery,
    DeletedCompanyPilverQueryVariables
  >;
export const DeletedCompanyPilversQueryDocument = gql`
  query deletedCompanyPilversQuery(
    $paging: OffsetPaging
    $filter: DeletedCompanyPilverFilter
    $sorting: [DeletedCompanyPilverSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesFilter: ContactPilverFilter
    $deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsFilter: ContactFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyPilversNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesFilter: ContactPilverFilter
    $deletedCompanyPilversNodesNodesHistoriesSorting: [ContactPilverSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactPilverFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsFilter: ContactFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    deletedCompanyPilvers(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        contact {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesPaging
              filter: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesFilter
              sorting: $deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging
              filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter
              sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsPaging
              filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsFilter
              sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsPaging
              filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsFilter
              sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesPaging
              filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesFilter
              sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersPaging
              filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersFilter
              sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsPaging
              filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsFilter
              sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging
              filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter
              sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsPaging
            filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsFilter
            sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $deletedCompanyPilversNodesNodesHistoriesPaging
          filter: $deletedCompanyPilversNodesNodesHistoriesFilter
          sorting: $deletedCompanyPilversNodesNodesHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              createdBy {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Username
                Email
                Status
                Role
                createdCompanies(
                  paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                  filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                  sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    contact {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                    histories(
                      paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                      filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                      sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                      nodes {
                        Id
                        CreatedAt
                        UpdatedAt
                        DeletedAt
                        ContactPerson
                        Email
                        Phone
                        Description
                        Interest
                      }
                    }
                  }
                }
                createdProjects(
                  paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                  filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                  sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Address
                    Type
                    Language
                    Manager
                    ManagerContact
                    projectChange {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                    histories(
                      paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                      filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                      sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                      nodes {
                        Id
                        CreatedAt
                        UpdatedAt
                        DeletedAt
                        Description
                        State
                      }
                    }
                  }
                }
                contacts(
                  paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                  filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                  sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                    company {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Name
                      Webpage
                      Address
                      Focus
                      Language
                      histories(
                        paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                        filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                        sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                      ) {
                        pageInfo {
                          hasNextPage
                          hasPreviousPage
                        }
                      }
                    }
                  }
                }
                projectChanges(
                  paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                  filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                  sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                    company {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Name
                      Webpage
                      Address
                      Focus
                      Language
                      histories(
                        paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                        filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                        sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                      ) {
                        pageInfo {
                          hasNextPage
                          hasPreviousPage
                        }
                      }
                    }
                  }
                }
                createdWorkers(
                  paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                  filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                  sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Phone
                    Email
                    Note
                    Profession
                    Type
                    attachments(
                      filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                      sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                    ) {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Name
                    }
                  }
                }
                createdContracts(
                  paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                  filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                  sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Description
                    Type
                    Year
                    attachments(
                      filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                      sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                    ) {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Name
                    }
                  }
                }
                createdMiddlemans(
                  paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                  filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                  sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Phone
                    Email
                    Note
                    attachments(
                      filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                      sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                    ) {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Name
                    }
                  }
                }
              }
              histories(
                paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
            contactedBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
                filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
                sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
                filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
                sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
                filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
                sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
                filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
                sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
                filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
                sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
                filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
                sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
                filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
                sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
          }
        }
        deletedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesPaging
            filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesFilter
            sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsPaging
            filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsFilter
            sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsPaging
            filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsFilter
            sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesPaging
            filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesFilter
            sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersPaging
            filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersFilter
            sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsPaging
            filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsFilter
            sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansPaging
            filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansFilter
            sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useDeletedCompanyPilversQuery__
 *
 * To run a query within a Vue component, call `useDeletedCompanyPilversQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedCompanyPilversQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDeletedCompanyPilversQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansPaging'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansSorting'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByContactsPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByContactsPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByContactsFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByContactsFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByContactsSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByContactsSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByContactsPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByContactsPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByContactsFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByContactsFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByContactsSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByContactsSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansPaging: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansPaging'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansSorting'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedCompanyPilversNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useDeletedCompanyPilversQuery(
  variables:
    | DeletedCompanyPilversQueryVariables
    | VueCompositionApi.Ref<DeletedCompanyPilversQueryVariables>
    | ReactiveFunction<DeletedCompanyPilversQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        DeletedCompanyPilversQuery,
        DeletedCompanyPilversQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          DeletedCompanyPilversQuery,
          DeletedCompanyPilversQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          DeletedCompanyPilversQuery,
          DeletedCompanyPilversQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    DeletedCompanyPilversQuery,
    DeletedCompanyPilversQueryVariables
  >(DeletedCompanyPilversQueryDocument, variables, options);
}
export type DeletedCompanyPilversQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    DeletedCompanyPilversQuery,
    DeletedCompanyPilversQueryVariables
  >;
export const DeletedMiddlemanQueryDocument = gql`
  query deletedMiddlemanQuery(
    $id: String!
    $deletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $deletedMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $deletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $deletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedMiddlemanDeletedByDeletedByContactsPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByContactsFilter: ContactFilter
    $deletedMiddlemanDeletedByDeletedByContactsSorting: [ContactSort!]
    $deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedMiddlemanDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByProjectChangesFilter: ContactFilter
    $deletedMiddlemanDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $deletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $deletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedMiddlemanDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $deletedMiddlemanDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $deletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $deletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    deletedMiddleman(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $deletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $deletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $deletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $deletedMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $deletedMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $deletedMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $deletedMiddlemanCreatedByCreatedByContactsPaging
          filter: $deletedMiddlemanCreatedByCreatedByContactsFilter
          sorting: $deletedMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $deletedMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $deletedMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $deletedMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $deletedMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $deletedMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $deletedMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $deletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $deletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $deletedMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $deletedMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $deletedMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $deletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $deletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $deletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $deletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $deletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $deletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $deletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $deletedMiddlemanAttachmentsFilter
        sorting: $deletedMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $deletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging
          filter: $deletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter
          sorting: $deletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $deletedMiddlemanDeletedByDeletedByCreatedProjectsPaging
          filter: $deletedMiddlemanDeletedByDeletedByCreatedProjectsFilter
          sorting: $deletedMiddlemanDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $deletedMiddlemanDeletedByDeletedByContactsPaging
          filter: $deletedMiddlemanDeletedByDeletedByContactsFilter
          sorting: $deletedMiddlemanDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $deletedMiddlemanDeletedByDeletedByProjectChangesPaging
          filter: $deletedMiddlemanDeletedByDeletedByProjectChangesFilter
          sorting: $deletedMiddlemanDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $deletedMiddlemanDeletedByDeletedByCreatedWorkersPaging
          filter: $deletedMiddlemanDeletedByDeletedByCreatedWorkersFilter
          sorting: $deletedMiddlemanDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $deletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $deletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $deletedMiddlemanDeletedByDeletedByCreatedContractsPaging
          filter: $deletedMiddlemanDeletedByDeletedByCreatedContractsFilter
          sorting: $deletedMiddlemanDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $deletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $deletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $deletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging
          filter: $deletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $deletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $deletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $deletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDeletedMiddlemanQuery__
 *
 * To run a query within a Vue component, call `useDeletedMiddlemanQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedMiddlemanQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDeletedMiddlemanQuery({
 *   id: // value for 'id'
 *   deletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *   deletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *   deletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *   deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedMiddlemanCreatedByCreatedByContactsPaging: // value for 'deletedMiddlemanCreatedByCreatedByContactsPaging'
 *   deletedMiddlemanCreatedByCreatedByContactsFilter: // value for 'deletedMiddlemanCreatedByCreatedByContactsFilter'
 *   deletedMiddlemanCreatedByCreatedByContactsSorting: // value for 'deletedMiddlemanCreatedByCreatedByContactsSorting'
 *   deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'deletedMiddlemanCreatedByCreatedByProjectChangesPaging'
 *   deletedMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'deletedMiddlemanCreatedByCreatedByProjectChangesFilter'
 *   deletedMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'deletedMiddlemanCreatedByCreatedByProjectChangesSorting'
 *   deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *   deletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *   deletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *   deletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'deletedMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *   deletedMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'deletedMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *   deletedMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'deletedMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *   deletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedMiddlemanAttachmentsFilter: // value for 'deletedMiddlemanAttachmentsFilter'
 *   deletedMiddlemanAttachmentsSorting: // value for 'deletedMiddlemanAttachmentsSorting'
 *   deletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: // value for 'deletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging'
 *   deletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: // value for 'deletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter'
 *   deletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: // value for 'deletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting'
 *   deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: // value for 'deletedMiddlemanDeletedByDeletedByCreatedProjectsPaging'
 *   deletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: // value for 'deletedMiddlemanDeletedByDeletedByCreatedProjectsFilter'
 *   deletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: // value for 'deletedMiddlemanDeletedByDeletedByCreatedProjectsSorting'
 *   deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedMiddlemanDeletedByDeletedByContactsPaging: // value for 'deletedMiddlemanDeletedByDeletedByContactsPaging'
 *   deletedMiddlemanDeletedByDeletedByContactsFilter: // value for 'deletedMiddlemanDeletedByDeletedByContactsFilter'
 *   deletedMiddlemanDeletedByDeletedByContactsSorting: // value for 'deletedMiddlemanDeletedByDeletedByContactsSorting'
 *   deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedMiddlemanDeletedByDeletedByProjectChangesPaging: // value for 'deletedMiddlemanDeletedByDeletedByProjectChangesPaging'
 *   deletedMiddlemanDeletedByDeletedByProjectChangesFilter: // value for 'deletedMiddlemanDeletedByDeletedByProjectChangesFilter'
 *   deletedMiddlemanDeletedByDeletedByProjectChangesSorting: // value for 'deletedMiddlemanDeletedByDeletedByProjectChangesSorting'
 *   deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: // value for 'deletedMiddlemanDeletedByDeletedByCreatedWorkersPaging'
 *   deletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: // value for 'deletedMiddlemanDeletedByDeletedByCreatedWorkersFilter'
 *   deletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: // value for 'deletedMiddlemanDeletedByDeletedByCreatedWorkersSorting'
 *   deletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedMiddlemanDeletedByDeletedByCreatedContractsPaging: // value for 'deletedMiddlemanDeletedByDeletedByCreatedContractsPaging'
 *   deletedMiddlemanDeletedByDeletedByCreatedContractsFilter: // value for 'deletedMiddlemanDeletedByDeletedByCreatedContractsFilter'
 *   deletedMiddlemanDeletedByDeletedByCreatedContractsSorting: // value for 'deletedMiddlemanDeletedByDeletedByCreatedContractsSorting'
 *   deletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: // value for 'deletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging'
 *   deletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: // value for 'deletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter'
 *   deletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: // value for 'deletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting'
 *   deletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useDeletedMiddlemanQuery(
  variables:
    | DeletedMiddlemanQueryVariables
    | VueCompositionApi.Ref<DeletedMiddlemanQueryVariables>
    | ReactiveFunction<DeletedMiddlemanQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        DeletedMiddlemanQuery,
        DeletedMiddlemanQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          DeletedMiddlemanQuery,
          DeletedMiddlemanQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          DeletedMiddlemanQuery,
          DeletedMiddlemanQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    DeletedMiddlemanQuery,
    DeletedMiddlemanQueryVariables
  >(DeletedMiddlemanQueryDocument, variables, options);
}
export type DeletedMiddlemanQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    DeletedMiddlemanQuery,
    DeletedMiddlemanQueryVariables
  >;
export const DeletedMiddlemenQueryDocument = gql`
  query deletedMiddlemenQuery(
    $paging: OffsetPaging
    $filter: DeletedMiddlemanFilter
    $sorting: [DeletedMiddlemanSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedMiddlemenNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedMiddlemenNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByContactsPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByContactsFilter: ContactFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByContactsSorting: [ContactSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesFilter: ContactFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    deletedMiddlemen(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Phone
        Email
        Note
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $deletedMiddlemenNodesNodesCreatedByCreatedByContactsPaging
            filter: $deletedMiddlemenNodesNodesCreatedByCreatedByContactsFilter
            sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        attachments(
          filter: $deletedMiddlemenNodesNodesAttachmentsFilter
          sorting: $deletedMiddlemenNodesNodesAttachmentsSorting
        ) {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
        }
        deletedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesPaging
            filter: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesFilter
            sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsPaging
            filter: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsFilter
            sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $deletedMiddlemenNodesNodesDeletedByDeletedByContactsPaging
            filter: $deletedMiddlemenNodesNodesDeletedByDeletedByContactsFilter
            sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesPaging
            filter: $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesFilter
            sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersPaging
            filter: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersFilter
            sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsPaging
            filter: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsFilter
            sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansPaging
            filter: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansFilter
            sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useDeletedMiddlemenQuery__
 *
 * To run a query within a Vue component, call `useDeletedMiddlemenQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedMiddlemenQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDeletedMiddlemenQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByContactsPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByContactsPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByContactsFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByContactsFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByContactsSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByContactsSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedMiddlemenNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedMiddlemenNodesNodesAttachmentsFilter: // value for 'deletedMiddlemenNodesNodesAttachmentsFilter'
 *   deletedMiddlemenNodesNodesAttachmentsSorting: // value for 'deletedMiddlemenNodesNodesAttachmentsSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByContactsPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByContactsPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByContactsFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByContactsFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByContactsSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByContactsSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansPaging: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansPaging'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansSorting'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedMiddlemenNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useDeletedMiddlemenQuery(
  variables:
    | DeletedMiddlemenQueryVariables
    | VueCompositionApi.Ref<DeletedMiddlemenQueryVariables>
    | ReactiveFunction<DeletedMiddlemenQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        DeletedMiddlemenQuery,
        DeletedMiddlemenQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          DeletedMiddlemenQuery,
          DeletedMiddlemenQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          DeletedMiddlemenQuery,
          DeletedMiddlemenQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    DeletedMiddlemenQuery,
    DeletedMiddlemenQueryVariables
  >(DeletedMiddlemenQueryDocument, variables, options);
}
export type DeletedMiddlemenQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    DeletedMiddlemenQuery,
    DeletedMiddlemenQueryVariables
  >;
export const DeletedWorkerQueryDocument = gql`
  query deletedWorkerQuery(
    $id: String!
    $deletedWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $deletedWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedWorkerAttachmentsFilter: AttachmentFilter
    $deletedWorkerAttachmentsSorting: [AttachmentSort!]
    $deletedWorkerDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $deletedWorkerDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedWorkerDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $deletedWorkerDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedWorkerDeletedByDeletedByContactsPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByContactsFilter: ContactFilter
    $deletedWorkerDeletedByDeletedByContactsSorting: [ContactSort!]
    $deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedWorkerDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByProjectChangesFilter: ContactFilter
    $deletedWorkerDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedWorkerDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $deletedWorkerDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $deletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedWorkerDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $deletedWorkerDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $deletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $deletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    deletedWorker(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $deletedWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $deletedWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $deletedWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $deletedWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $deletedWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $deletedWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $deletedWorkerCreatedByCreatedByContactsPaging
          filter: $deletedWorkerCreatedByCreatedByContactsFilter
          sorting: $deletedWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $deletedWorkerCreatedByCreatedByProjectChangesPaging
          filter: $deletedWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $deletedWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $deletedWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $deletedWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $deletedWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $deletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $deletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $deletedWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $deletedWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $deletedWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $deletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $deletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $deletedWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $deletedWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $deletedWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $deletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $deletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $deletedWorkerAttachmentsFilter
        sorting: $deletedWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $deletedWorkerDeletedByDeletedByCreatedCompaniesPaging
          filter: $deletedWorkerDeletedByDeletedByCreatedCompaniesFilter
          sorting: $deletedWorkerDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $deletedWorkerDeletedByDeletedByCreatedProjectsPaging
          filter: $deletedWorkerDeletedByDeletedByCreatedProjectsFilter
          sorting: $deletedWorkerDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $deletedWorkerDeletedByDeletedByContactsPaging
          filter: $deletedWorkerDeletedByDeletedByContactsFilter
          sorting: $deletedWorkerDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $deletedWorkerDeletedByDeletedByProjectChangesPaging
          filter: $deletedWorkerDeletedByDeletedByProjectChangesFilter
          sorting: $deletedWorkerDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $deletedWorkerDeletedByDeletedByCreatedWorkersPaging
          filter: $deletedWorkerDeletedByDeletedByCreatedWorkersFilter
          sorting: $deletedWorkerDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $deletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $deletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $deletedWorkerDeletedByDeletedByCreatedContractsPaging
          filter: $deletedWorkerDeletedByDeletedByCreatedContractsFilter
          sorting: $deletedWorkerDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $deletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $deletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $deletedWorkerDeletedByDeletedByCreatedMiddlemansPaging
          filter: $deletedWorkerDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $deletedWorkerDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $deletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $deletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDeletedWorkerQuery__
 *
 * To run a query within a Vue component, call `useDeletedWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedWorkerQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDeletedWorkerQuery({
 *   id: // value for 'id'
 *   deletedWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedWorkerCreatedByCreatedByCreatedProjectsPaging'
 *   deletedWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedWorkerCreatedByCreatedByCreatedProjectsFilter'
 *   deletedWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedWorkerCreatedByCreatedByCreatedProjectsSorting'
 *   deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedWorkerCreatedByCreatedByContactsPaging: // value for 'deletedWorkerCreatedByCreatedByContactsPaging'
 *   deletedWorkerCreatedByCreatedByContactsFilter: // value for 'deletedWorkerCreatedByCreatedByContactsFilter'
 *   deletedWorkerCreatedByCreatedByContactsSorting: // value for 'deletedWorkerCreatedByCreatedByContactsSorting'
 *   deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedWorkerCreatedByCreatedByProjectChangesPaging: // value for 'deletedWorkerCreatedByCreatedByProjectChangesPaging'
 *   deletedWorkerCreatedByCreatedByProjectChangesFilter: // value for 'deletedWorkerCreatedByCreatedByProjectChangesFilter'
 *   deletedWorkerCreatedByCreatedByProjectChangesSorting: // value for 'deletedWorkerCreatedByCreatedByProjectChangesSorting'
 *   deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedWorkerCreatedByCreatedByCreatedWorkersPaging'
 *   deletedWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedWorkerCreatedByCreatedByCreatedWorkersFilter'
 *   deletedWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedWorkerCreatedByCreatedByCreatedWorkersSorting'
 *   deletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'deletedWorkerCreatedByCreatedByCreatedContractsPaging'
 *   deletedWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'deletedWorkerCreatedByCreatedByCreatedContractsFilter'
 *   deletedWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'deletedWorkerCreatedByCreatedByCreatedContractsSorting'
 *   deletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedWorkerAttachmentsFilter: // value for 'deletedWorkerAttachmentsFilter'
 *   deletedWorkerAttachmentsSorting: // value for 'deletedWorkerAttachmentsSorting'
 *   deletedWorkerDeletedByDeletedByCreatedCompaniesPaging: // value for 'deletedWorkerDeletedByDeletedByCreatedCompaniesPaging'
 *   deletedWorkerDeletedByDeletedByCreatedCompaniesFilter: // value for 'deletedWorkerDeletedByDeletedByCreatedCompaniesFilter'
 *   deletedWorkerDeletedByDeletedByCreatedCompaniesSorting: // value for 'deletedWorkerDeletedByDeletedByCreatedCompaniesSorting'
 *   deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedWorkerDeletedByDeletedByCreatedProjectsPaging: // value for 'deletedWorkerDeletedByDeletedByCreatedProjectsPaging'
 *   deletedWorkerDeletedByDeletedByCreatedProjectsFilter: // value for 'deletedWorkerDeletedByDeletedByCreatedProjectsFilter'
 *   deletedWorkerDeletedByDeletedByCreatedProjectsSorting: // value for 'deletedWorkerDeletedByDeletedByCreatedProjectsSorting'
 *   deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedWorkerDeletedByDeletedByContactsPaging: // value for 'deletedWorkerDeletedByDeletedByContactsPaging'
 *   deletedWorkerDeletedByDeletedByContactsFilter: // value for 'deletedWorkerDeletedByDeletedByContactsFilter'
 *   deletedWorkerDeletedByDeletedByContactsSorting: // value for 'deletedWorkerDeletedByDeletedByContactsSorting'
 *   deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedWorkerDeletedByDeletedByProjectChangesPaging: // value for 'deletedWorkerDeletedByDeletedByProjectChangesPaging'
 *   deletedWorkerDeletedByDeletedByProjectChangesFilter: // value for 'deletedWorkerDeletedByDeletedByProjectChangesFilter'
 *   deletedWorkerDeletedByDeletedByProjectChangesSorting: // value for 'deletedWorkerDeletedByDeletedByProjectChangesSorting'
 *   deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedWorkerDeletedByDeletedByCreatedWorkersPaging: // value for 'deletedWorkerDeletedByDeletedByCreatedWorkersPaging'
 *   deletedWorkerDeletedByDeletedByCreatedWorkersFilter: // value for 'deletedWorkerDeletedByDeletedByCreatedWorkersFilter'
 *   deletedWorkerDeletedByDeletedByCreatedWorkersSorting: // value for 'deletedWorkerDeletedByDeletedByCreatedWorkersSorting'
 *   deletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedWorkerDeletedByDeletedByCreatedContractsPaging: // value for 'deletedWorkerDeletedByDeletedByCreatedContractsPaging'
 *   deletedWorkerDeletedByDeletedByCreatedContractsFilter: // value for 'deletedWorkerDeletedByDeletedByCreatedContractsFilter'
 *   deletedWorkerDeletedByDeletedByCreatedContractsSorting: // value for 'deletedWorkerDeletedByDeletedByCreatedContractsSorting'
 *   deletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: // value for 'deletedWorkerDeletedByDeletedByCreatedMiddlemansPaging'
 *   deletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: // value for 'deletedWorkerDeletedByDeletedByCreatedMiddlemansFilter'
 *   deletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: // value for 'deletedWorkerDeletedByDeletedByCreatedMiddlemansSorting'
 *   deletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useDeletedWorkerQuery(
  variables:
    | DeletedWorkerQueryVariables
    | VueCompositionApi.Ref<DeletedWorkerQueryVariables>
    | ReactiveFunction<DeletedWorkerQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        DeletedWorkerQuery,
        DeletedWorkerQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          DeletedWorkerQuery,
          DeletedWorkerQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          DeletedWorkerQuery,
          DeletedWorkerQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    DeletedWorkerQuery,
    DeletedWorkerQueryVariables
  >(DeletedWorkerQueryDocument, variables, options);
}
export type DeletedWorkerQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    DeletedWorkerQuery,
    DeletedWorkerQueryVariables
  >;
export const DeletedWorkersQueryDocument = gql`
  query deletedWorkersQuery(
    $paging: OffsetPaging
    $filter: DeletedWorkerFilter
    $sorting: [DeletedWorkerSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $deletedWorkersNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $deletedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByContactsPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByContactsFilter: ContactFilter
    $deletedWorkersNodesNodesDeletedByDeletedByContactsSorting: [ContactSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesFilter: ContactFilter
    $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    deletedWorkers(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Phone
        Email
        Note
        Profession
        Type
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $deletedWorkersNodesNodesCreatedByCreatedByContactsPaging
            filter: $deletedWorkersNodesNodesCreatedByCreatedByContactsFilter
            sorting: $deletedWorkersNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        attachments(
          filter: $deletedWorkersNodesNodesAttachmentsFilter
          sorting: $deletedWorkersNodesNodesAttachmentsSorting
        ) {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
        }
        deletedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesPaging
            filter: $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesFilter
            sorting: $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsPaging
            filter: $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsFilter
            sorting: $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $deletedWorkersNodesNodesDeletedByDeletedByContactsPaging
            filter: $deletedWorkersNodesNodesDeletedByDeletedByContactsFilter
            sorting: $deletedWorkersNodesNodesDeletedByDeletedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesPaging
            filter: $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesFilter
            sorting: $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersPaging
            filter: $deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersFilter
            sorting: $deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsPaging
            filter: $deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsFilter
            sorting: $deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansPaging
            filter: $deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansFilter
            sorting: $deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useDeletedWorkersQuery__
 *
 * To run a query within a Vue component, call `useDeletedWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedWorkersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDeletedWorkersQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByContactsPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByContactsPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByContactsFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByContactsFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByContactsSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByContactsSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedWorkersNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   deletedWorkersNodesNodesAttachmentsFilter: // value for 'deletedWorkersNodesNodesAttachmentsFilter'
 *   deletedWorkersNodesNodesAttachmentsSorting: // value for 'deletedWorkersNodesNodesAttachmentsSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByContactsPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByContactsPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByContactsFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByContactsFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByContactsSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByContactsSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByProjectChangesPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByProjectChangesPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByProjectChangesFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByProjectChangesFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByProjectChangesSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByProjectChangesSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansPaging: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansPaging'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansSorting'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'deletedWorkersNodesNodesDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 * });
 */
export function useDeletedWorkersQuery(
  variables:
    | DeletedWorkersQueryVariables
    | VueCompositionApi.Ref<DeletedWorkersQueryVariables>
    | ReactiveFunction<DeletedWorkersQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        DeletedWorkersQuery,
        DeletedWorkersQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          DeletedWorkersQuery,
          DeletedWorkersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          DeletedWorkersQuery,
          DeletedWorkersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    DeletedWorkersQuery,
    DeletedWorkersQueryVariables
  >(DeletedWorkersQueryDocument, variables, options);
}
export type DeletedWorkersQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    DeletedWorkersQuery,
    DeletedWorkersQueryVariables
  >;
export const MiddlemanQueryDocument = gql`
  query middlemanQuery(
    $id: String!
    $middlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $middlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $middlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $middlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $middlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $middlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $middlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $middlemanCreatedByCreatedByContactsFilter: ContactFilter
    $middlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $middlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $middlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $middlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $middlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $middlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $middlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $middlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $middlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $middlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $middlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $middlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $middlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $middlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $middlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $middlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $middlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $middlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $middlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    middleman(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $middlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $middlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $middlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $middlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $middlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $middlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $middlemanCreatedByCreatedByContactsPaging
          filter: $middlemanCreatedByCreatedByContactsFilter
          sorting: $middlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $middlemanCreatedByCreatedByProjectChangesPaging
          filter: $middlemanCreatedByCreatedByProjectChangesFilter
          sorting: $middlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $middlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $middlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $middlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $middlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $middlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $middlemanCreatedByCreatedByCreatedContractsPaging
          filter: $middlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $middlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $middlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $middlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $middlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $middlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $middlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      attachments(
        filter: $middlemanAttachmentsFilter
        sorting: $middlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useMiddlemanQuery__
 *
 * To run a query within a Vue component, call `useMiddlemanQuery` and pass it any options that fit your needs.
 * When your component renders, `useMiddlemanQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMiddlemanQuery({
 *   id: // value for 'id'
 *   middlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'middlemanCreatedByCreatedByCreatedCompaniesPaging'
 *   middlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'middlemanCreatedByCreatedByCreatedCompaniesFilter'
 *   middlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'middlemanCreatedByCreatedByCreatedCompaniesSorting'
 *   middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'middlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   middlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'middlemanCreatedByCreatedByCreatedProjectsPaging'
 *   middlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'middlemanCreatedByCreatedByCreatedProjectsFilter'
 *   middlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'middlemanCreatedByCreatedByCreatedProjectsSorting'
 *   middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'middlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   middlemanCreatedByCreatedByContactsPaging: // value for 'middlemanCreatedByCreatedByContactsPaging'
 *   middlemanCreatedByCreatedByContactsFilter: // value for 'middlemanCreatedByCreatedByContactsFilter'
 *   middlemanCreatedByCreatedByContactsSorting: // value for 'middlemanCreatedByCreatedByContactsSorting'
 *   middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'middlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   middlemanCreatedByCreatedByProjectChangesPaging: // value for 'middlemanCreatedByCreatedByProjectChangesPaging'
 *   middlemanCreatedByCreatedByProjectChangesFilter: // value for 'middlemanCreatedByCreatedByProjectChangesFilter'
 *   middlemanCreatedByCreatedByProjectChangesSorting: // value for 'middlemanCreatedByCreatedByProjectChangesSorting'
 *   middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'middlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   middlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'middlemanCreatedByCreatedByCreatedWorkersPaging'
 *   middlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'middlemanCreatedByCreatedByCreatedWorkersFilter'
 *   middlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'middlemanCreatedByCreatedByCreatedWorkersSorting'
 *   middlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'middlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   middlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'middlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   middlemanCreatedByCreatedByCreatedContractsPaging: // value for 'middlemanCreatedByCreatedByCreatedContractsPaging'
 *   middlemanCreatedByCreatedByCreatedContractsFilter: // value for 'middlemanCreatedByCreatedByCreatedContractsFilter'
 *   middlemanCreatedByCreatedByCreatedContractsSorting: // value for 'middlemanCreatedByCreatedByCreatedContractsSorting'
 *   middlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'middlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   middlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'middlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   middlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'middlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *   middlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'middlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *   middlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'middlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *   middlemanAttachmentsFilter: // value for 'middlemanAttachmentsFilter'
 *   middlemanAttachmentsSorting: // value for 'middlemanAttachmentsSorting'
 * });
 */
export function useMiddlemanQuery(
  variables:
    | MiddlemanQueryVariables
    | VueCompositionApi.Ref<MiddlemanQueryVariables>
    | ReactiveFunction<MiddlemanQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        MiddlemanQuery,
        MiddlemanQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          MiddlemanQuery,
          MiddlemanQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          MiddlemanQuery,
          MiddlemanQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<MiddlemanQuery, MiddlemanQueryVariables>(
    MiddlemanQueryDocument,
    variables,
    options
  );
}
export type MiddlemanQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<MiddlemanQuery, MiddlemanQueryVariables>;
export const MiddlemenQueryDocument = gql`
  query middlemenQuery(
    $paging: OffsetPaging
    $filter: MiddlemanFilter
    $sorting: [MiddlemanSort!]
    $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $middlemenNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $middlemenNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $middlemenNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $middlemenNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $middlemenNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $middlemenNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $middlemenNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $middlemenNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $middlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $middlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $middlemenNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $middlemenNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $middlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $middlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $middlemenNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $middlemenNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    middlemen(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Phone
        Email
        Note
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $middlemenNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $middlemenNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $middlemenNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $middlemenNodesNodesCreatedByCreatedByContactsPaging
            filter: $middlemenNodesNodesCreatedByCreatedByContactsFilter
            sorting: $middlemenNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $middlemenNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $middlemenNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $middlemenNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $middlemenNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $middlemenNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $middlemenNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $middlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $middlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $middlemenNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $middlemenNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $middlemenNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $middlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $middlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        attachments(
          filter: $middlemenNodesNodesAttachmentsFilter
          sorting: $middlemenNodesNodesAttachmentsSorting
        ) {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
        }
      }
      totalCount
    }
  }
`;

/**
 * __useMiddlemenQuery__
 *
 * To run a query within a Vue component, call `useMiddlemenQuery` and pass it any options that fit your needs.
 * When your component renders, `useMiddlemenQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMiddlemenQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   middlemenNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   middlemenNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   middlemenNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   middlemenNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   middlemenNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   middlemenNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   middlemenNodesNodesCreatedByCreatedByContactsPaging: // value for 'middlemenNodesNodesCreatedByCreatedByContactsPaging'
 *   middlemenNodesNodesCreatedByCreatedByContactsFilter: // value for 'middlemenNodesNodesCreatedByCreatedByContactsFilter'
 *   middlemenNodesNodesCreatedByCreatedByContactsSorting: // value for 'middlemenNodesNodesCreatedByCreatedByContactsSorting'
 *   middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'middlemenNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   middlemenNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'middlemenNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   middlemenNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'middlemenNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   middlemenNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'middlemenNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'middlemenNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   middlemenNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   middlemenNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   middlemenNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   middlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   middlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   middlemenNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   middlemenNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   middlemenNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   middlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   middlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'middlemenNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   middlemenNodesNodesAttachmentsFilter: // value for 'middlemenNodesNodesAttachmentsFilter'
 *   middlemenNodesNodesAttachmentsSorting: // value for 'middlemenNodesNodesAttachmentsSorting'
 * });
 */
export function useMiddlemenQuery(
  variables:
    | MiddlemenQueryVariables
    | VueCompositionApi.Ref<MiddlemenQueryVariables>
    | ReactiveFunction<MiddlemenQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        MiddlemenQuery,
        MiddlemenQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          MiddlemenQuery,
          MiddlemenQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          MiddlemenQuery,
          MiddlemenQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<MiddlemenQuery, MiddlemenQueryVariables>(
    MiddlemenQueryDocument,
    variables,
    options
  );
}
export type MiddlemenQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<MiddlemenQuery, MiddlemenQueryVariables>;
export const MiddlemanAttachmentQueryDocument = gql`
  query middlemanAttachmentQuery($id: String!) {
    middlemanAttachment(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useMiddlemanAttachmentQuery__
 *
 * To run a query within a Vue component, call `useMiddlemanAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useMiddlemanAttachmentQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMiddlemanAttachmentQuery({
 *   id: // value for 'id'
 * });
 */
export function useMiddlemanAttachmentQuery(
  variables:
    | MiddlemanAttachmentQueryVariables
    | VueCompositionApi.Ref<MiddlemanAttachmentQueryVariables>
    | ReactiveFunction<MiddlemanAttachmentQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        MiddlemanAttachmentQuery,
        MiddlemanAttachmentQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          MiddlemanAttachmentQuery,
          MiddlemanAttachmentQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          MiddlemanAttachmentQuery,
          MiddlemanAttachmentQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    MiddlemanAttachmentQuery,
    MiddlemanAttachmentQueryVariables
  >(MiddlemanAttachmentQueryDocument, variables, options);
}
export type MiddlemanAttachmentQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    MiddlemanAttachmentQuery,
    MiddlemanAttachmentQueryVariables
  >;
export const MiddlemanAttachmentsQueryDocument = gql`
  query middlemanAttachmentsQuery(
    $paging: OffsetPaging
    $filter: MiddlemanAttachmentFilter
    $sorting: [MiddlemanAttachmentSort!]
  ) {
    middlemanAttachments(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      totalCount
    }
  }
`;

/**
 * __useMiddlemanAttachmentsQuery__
 *
 * To run a query within a Vue component, call `useMiddlemanAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMiddlemanAttachmentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMiddlemanAttachmentsQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 * });
 */
export function useMiddlemanAttachmentsQuery(
  variables:
    | MiddlemanAttachmentsQueryVariables
    | VueCompositionApi.Ref<MiddlemanAttachmentsQueryVariables>
    | ReactiveFunction<MiddlemanAttachmentsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        MiddlemanAttachmentsQuery,
        MiddlemanAttachmentsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          MiddlemanAttachmentsQuery,
          MiddlemanAttachmentsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          MiddlemanAttachmentsQuery,
          MiddlemanAttachmentsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    MiddlemanAttachmentsQuery,
    MiddlemanAttachmentsQueryVariables
  >(MiddlemanAttachmentsQueryDocument, variables, options);
}
export type MiddlemanAttachmentsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    MiddlemanAttachmentsQuery,
    MiddlemanAttachmentsQueryVariables
  >;
export const CarQueryDocument = gql`
  query carQuery(
    $id: String!
    $carCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $carCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $carCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $carCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $carCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $carCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $carCreatedByCreatedByContactsPaging: OffsetPaging
    $carCreatedByCreatedByContactsFilter: ContactFilter
    $carCreatedByCreatedByContactsSorting: [ContactSort!]
    $carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $carCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $carCreatedByCreatedByProjectChangesFilter: ContactFilter
    $carCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $carCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $carCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $carCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $carCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $carCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $carCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $carCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $carCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $carCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $carCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $carCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $carCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $carCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $carCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $carCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $carAttachmentsFilter: CarAttachmentFilter
    $carAttachmentsSorting: [CarAttachmentSort!]
  ) {
    car(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Evidence
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $carCreatedByCreatedByCreatedCompaniesPaging
          filter: $carCreatedByCreatedByCreatedCompaniesFilter
          sorting: $carCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $carCreatedByCreatedByCreatedProjectsPaging
          filter: $carCreatedByCreatedByCreatedProjectsFilter
          sorting: $carCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $carCreatedByCreatedByContactsPaging
          filter: $carCreatedByCreatedByContactsFilter
          sorting: $carCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $carCreatedByCreatedByProjectChangesPaging
          filter: $carCreatedByCreatedByProjectChangesFilter
          sorting: $carCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $carCreatedByCreatedByCreatedWorkersPaging
          filter: $carCreatedByCreatedByCreatedWorkersFilter
          sorting: $carCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $carCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $carCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $carCreatedByCreatedByCreatedContractsPaging
          filter: $carCreatedByCreatedByCreatedContractsFilter
          sorting: $carCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $carCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $carCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $carCreatedByCreatedByCreatedMiddlemansPaging
          filter: $carCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $carCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $carCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $carCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $carAttachmentsFilter
        sorting: $carAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCarQuery__
 *
 * To run a query within a Vue component, call `useCarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCarQuery({
 *   id: // value for 'id'
 *   carCreatedByCreatedByCreatedCompaniesPaging: // value for 'carCreatedByCreatedByCreatedCompaniesPaging'
 *   carCreatedByCreatedByCreatedCompaniesFilter: // value for 'carCreatedByCreatedByCreatedCompaniesFilter'
 *   carCreatedByCreatedByCreatedCompaniesSorting: // value for 'carCreatedByCreatedByCreatedCompaniesSorting'
 *   carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'carCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   carCreatedByCreatedByCreatedProjectsPaging: // value for 'carCreatedByCreatedByCreatedProjectsPaging'
 *   carCreatedByCreatedByCreatedProjectsFilter: // value for 'carCreatedByCreatedByCreatedProjectsFilter'
 *   carCreatedByCreatedByCreatedProjectsSorting: // value for 'carCreatedByCreatedByCreatedProjectsSorting'
 *   carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'carCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   carCreatedByCreatedByContactsPaging: // value for 'carCreatedByCreatedByContactsPaging'
 *   carCreatedByCreatedByContactsFilter: // value for 'carCreatedByCreatedByContactsFilter'
 *   carCreatedByCreatedByContactsSorting: // value for 'carCreatedByCreatedByContactsSorting'
 *   carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'carCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   carCreatedByCreatedByProjectChangesPaging: // value for 'carCreatedByCreatedByProjectChangesPaging'
 *   carCreatedByCreatedByProjectChangesFilter: // value for 'carCreatedByCreatedByProjectChangesFilter'
 *   carCreatedByCreatedByProjectChangesSorting: // value for 'carCreatedByCreatedByProjectChangesSorting'
 *   carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'carCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   carCreatedByCreatedByCreatedWorkersPaging: // value for 'carCreatedByCreatedByCreatedWorkersPaging'
 *   carCreatedByCreatedByCreatedWorkersFilter: // value for 'carCreatedByCreatedByCreatedWorkersFilter'
 *   carCreatedByCreatedByCreatedWorkersSorting: // value for 'carCreatedByCreatedByCreatedWorkersSorting'
 *   carCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'carCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   carCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'carCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   carCreatedByCreatedByCreatedContractsPaging: // value for 'carCreatedByCreatedByCreatedContractsPaging'
 *   carCreatedByCreatedByCreatedContractsFilter: // value for 'carCreatedByCreatedByCreatedContractsFilter'
 *   carCreatedByCreatedByCreatedContractsSorting: // value for 'carCreatedByCreatedByCreatedContractsSorting'
 *   carCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'carCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   carCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'carCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   carCreatedByCreatedByCreatedMiddlemansPaging: // value for 'carCreatedByCreatedByCreatedMiddlemansPaging'
 *   carCreatedByCreatedByCreatedMiddlemansFilter: // value for 'carCreatedByCreatedByCreatedMiddlemansFilter'
 *   carCreatedByCreatedByCreatedMiddlemansSorting: // value for 'carCreatedByCreatedByCreatedMiddlemansSorting'
 *   carCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'carCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   carCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'carCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   carAttachmentsFilter: // value for 'carAttachmentsFilter'
 *   carAttachmentsSorting: // value for 'carAttachmentsSorting'
 * });
 */
export function useCarQuery(
  variables:
    | CarQueryVariables
    | VueCompositionApi.Ref<CarQueryVariables>
    | ReactiveFunction<CarQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<CarQuery, CarQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<CarQuery, CarQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<CarQuery, CarQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<CarQuery, CarQueryVariables>(
    CarQueryDocument,
    variables,
    options
  );
}
export type CarQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<CarQuery, CarQueryVariables>;
export const CarsQueryDocument = gql`
  query carsQuery(
    $paging: OffsetPaging
    $filter: CarFilter
    $sorting: [CarSort!]
    $carsNodesNodesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $carsNodesNodesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $carsNodesNodesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $carsNodesNodesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $carsNodesNodesCreatedByCreatedByContactsPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByContactsFilter: ContactFilter
    $carsNodesNodesCreatedByCreatedByContactsSorting: [ContactSort!]
    $carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $carsNodesNodesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $carsNodesNodesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $carsNodesNodesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $carsNodesNodesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $carsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $carsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $carsNodesNodesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $carsNodesNodesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $carsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $carsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $carsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $carsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $carsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $carsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $carsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $carsNodesNodesAttachmentsFilter: CarAttachmentFilter
    $carsNodesNodesAttachmentsSorting: [CarAttachmentSort!]
  ) {
    cars(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Description
        Evidence
        Type
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $carsNodesNodesCreatedByCreatedByCreatedCompaniesPaging
            filter: $carsNodesNodesCreatedByCreatedByCreatedCompaniesFilter
            sorting: $carsNodesNodesCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $carsNodesNodesCreatedByCreatedByCreatedProjectsPaging
            filter: $carsNodesNodesCreatedByCreatedByCreatedProjectsFilter
            sorting: $carsNodesNodesCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $carsNodesNodesCreatedByCreatedByContactsPaging
            filter: $carsNodesNodesCreatedByCreatedByContactsFilter
            sorting: $carsNodesNodesCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $carsNodesNodesCreatedByCreatedByProjectChangesPaging
            filter: $carsNodesNodesCreatedByCreatedByProjectChangesFilter
            sorting: $carsNodesNodesCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $carsNodesNodesCreatedByCreatedByCreatedWorkersPaging
            filter: $carsNodesNodesCreatedByCreatedByCreatedWorkersFilter
            sorting: $carsNodesNodesCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $carsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $carsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $carsNodesNodesCreatedByCreatedByCreatedContractsPaging
            filter: $carsNodesNodesCreatedByCreatedByCreatedContractsFilter
            sorting: $carsNodesNodesCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $carsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $carsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $carsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging
            filter: $carsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $carsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $carsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $carsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        attachments(
          filter: $carsNodesNodesAttachmentsFilter
          sorting: $carsNodesNodesAttachmentsSorting
        ) {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
        }
      }
      totalCount
    }
  }
`;

/**
 * __useCarsQuery__
 *
 * To run a query within a Vue component, call `useCarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCarsQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 *   carsNodesNodesCreatedByCreatedByCreatedCompaniesPaging: // value for 'carsNodesNodesCreatedByCreatedByCreatedCompaniesPaging'
 *   carsNodesNodesCreatedByCreatedByCreatedCompaniesFilter: // value for 'carsNodesNodesCreatedByCreatedByCreatedCompaniesFilter'
 *   carsNodesNodesCreatedByCreatedByCreatedCompaniesSorting: // value for 'carsNodesNodesCreatedByCreatedByCreatedCompaniesSorting'
 *   carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *   carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *   carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'carsNodesNodesCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *   carsNodesNodesCreatedByCreatedByCreatedProjectsPaging: // value for 'carsNodesNodesCreatedByCreatedByCreatedProjectsPaging'
 *   carsNodesNodesCreatedByCreatedByCreatedProjectsFilter: // value for 'carsNodesNodesCreatedByCreatedByCreatedProjectsFilter'
 *   carsNodesNodesCreatedByCreatedByCreatedProjectsSorting: // value for 'carsNodesNodesCreatedByCreatedByCreatedProjectsSorting'
 *   carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *   carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *   carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'carsNodesNodesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *   carsNodesNodesCreatedByCreatedByContactsPaging: // value for 'carsNodesNodesCreatedByCreatedByContactsPaging'
 *   carsNodesNodesCreatedByCreatedByContactsFilter: // value for 'carsNodesNodesCreatedByCreatedByContactsFilter'
 *   carsNodesNodesCreatedByCreatedByContactsSorting: // value for 'carsNodesNodesCreatedByCreatedByContactsSorting'
 *   carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *   carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *   carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'carsNodesNodesCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *   carsNodesNodesCreatedByCreatedByProjectChangesPaging: // value for 'carsNodesNodesCreatedByCreatedByProjectChangesPaging'
 *   carsNodesNodesCreatedByCreatedByProjectChangesFilter: // value for 'carsNodesNodesCreatedByCreatedByProjectChangesFilter'
 *   carsNodesNodesCreatedByCreatedByProjectChangesSorting: // value for 'carsNodesNodesCreatedByCreatedByProjectChangesSorting'
 *   carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *   carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *   carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'carsNodesNodesCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *   carsNodesNodesCreatedByCreatedByCreatedWorkersPaging: // value for 'carsNodesNodesCreatedByCreatedByCreatedWorkersPaging'
 *   carsNodesNodesCreatedByCreatedByCreatedWorkersFilter: // value for 'carsNodesNodesCreatedByCreatedByCreatedWorkersFilter'
 *   carsNodesNodesCreatedByCreatedByCreatedWorkersSorting: // value for 'carsNodesNodesCreatedByCreatedByCreatedWorkersSorting'
 *   carsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'carsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *   carsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'carsNodesNodesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *   carsNodesNodesCreatedByCreatedByCreatedContractsPaging: // value for 'carsNodesNodesCreatedByCreatedByCreatedContractsPaging'
 *   carsNodesNodesCreatedByCreatedByCreatedContractsFilter: // value for 'carsNodesNodesCreatedByCreatedByCreatedContractsFilter'
 *   carsNodesNodesCreatedByCreatedByCreatedContractsSorting: // value for 'carsNodesNodesCreatedByCreatedByCreatedContractsSorting'
 *   carsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'carsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *   carsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'carsNodesNodesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *   carsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'carsNodesNodesCreatedByCreatedByCreatedMiddlemansPaging'
 *   carsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'carsNodesNodesCreatedByCreatedByCreatedMiddlemansFilter'
 *   carsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'carsNodesNodesCreatedByCreatedByCreatedMiddlemansSorting'
 *   carsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'carsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *   carsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'carsNodesNodesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   carsNodesNodesAttachmentsFilter: // value for 'carsNodesNodesAttachmentsFilter'
 *   carsNodesNodesAttachmentsSorting: // value for 'carsNodesNodesAttachmentsSorting'
 * });
 */
export function useCarsQuery(
  variables:
    | CarsQueryVariables
    | VueCompositionApi.Ref<CarsQueryVariables>
    | ReactiveFunction<CarsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<CarsQuery, CarsQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<CarsQuery, CarsQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<CarsQuery, CarsQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<CarsQuery, CarsQueryVariables>(
    CarsQueryDocument,
    variables,
    options
  );
}
export type CarsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<CarsQuery, CarsQueryVariables>;
export const CarAttachmentQueryDocument = gql`
  query carAttachmentQuery($id: String!) {
    carAttachment(id: $id) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCarAttachmentQuery__
 *
 * To run a query within a Vue component, call `useCarAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarAttachmentQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCarAttachmentQuery({
 *   id: // value for 'id'
 * });
 */
export function useCarAttachmentQuery(
  variables:
    | CarAttachmentQueryVariables
    | VueCompositionApi.Ref<CarAttachmentQueryVariables>
    | ReactiveFunction<CarAttachmentQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        CarAttachmentQuery,
        CarAttachmentQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CarAttachmentQuery,
          CarAttachmentQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CarAttachmentQuery,
          CarAttachmentQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    CarAttachmentQuery,
    CarAttachmentQueryVariables
  >(CarAttachmentQueryDocument, variables, options);
}
export type CarAttachmentQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    CarAttachmentQuery,
    CarAttachmentQueryVariables
  >;
export const CarAttachmentsQueryDocument = gql`
  query carAttachmentsQuery(
    $paging: OffsetPaging
    $filter: CarAttachmentFilter
    $sorting: [CarAttachmentSort!]
  ) {
    carAttachments(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      totalCount
    }
  }
`;

/**
 * __useCarAttachmentsQuery__
 *
 * To run a query within a Vue component, call `useCarAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarAttachmentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCarAttachmentsQuery({
 *   paging: // value for 'paging'
 *   filter: // value for 'filter'
 *   sorting: // value for 'sorting'
 * });
 */
export function useCarAttachmentsQuery(
  variables:
    | CarAttachmentsQueryVariables
    | VueCompositionApi.Ref<CarAttachmentsQueryVariables>
    | ReactiveFunction<CarAttachmentsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        CarAttachmentsQuery,
        CarAttachmentsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          CarAttachmentsQuery,
          CarAttachmentsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          CarAttachmentsQuery,
          CarAttachmentsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    CarAttachmentsQuery,
    CarAttachmentsQueryVariables
  >(CarAttachmentsQueryDocument, variables, options);
}
export type CarAttachmentsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    CarAttachmentsQuery,
    CarAttachmentsQueryVariables
  >;
export const LoginMutationDocument = gql`
  mutation loginMutation($input: LoginInputDTO!) {
    login(input: $input) {
      accessToken
    }
  }
`;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLoginMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        LoginMutation,
        LoginMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          LoginMutation,
          LoginMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<LoginMutation, LoginMutationVariables>(
    LoginMutationDocument,
    options
  );
}
export type LoginMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<LoginMutation, LoginMutationVariables>;
export const AddCreatedCompaniesToUserMutationDocument = gql`
  mutation addCreatedCompaniesToUserMutation(
    $input: AddCreatedCompaniesToUserInput!
    $addCreatedCompaniesToUserCreatedCompaniesPaging: OffsetPaging
    $addCreatedCompaniesToUserCreatedCompaniesFilter: CompanyFilter
    $addCreatedCompaniesToUserCreatedCompaniesSorting: [CompanySort!]
    $addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addCreatedCompaniesToUserCreatedProjectsPaging: OffsetPaging
    $addCreatedCompaniesToUserCreatedProjectsFilter: ProjectFilter
    $addCreatedCompaniesToUserCreatedProjectsSorting: [ProjectSort!]
    $addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addCreatedCompaniesToUserContactsPaging: OffsetPaging
    $addCreatedCompaniesToUserContactsFilter: ContactFilter
    $addCreatedCompaniesToUserContactsSorting: [ContactSort!]
    $addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addCreatedCompaniesToUserProjectChangesPaging: OffsetPaging
    $addCreatedCompaniesToUserProjectChangesFilter: ContactFilter
    $addCreatedCompaniesToUserProjectChangesSorting: [ContactSort!]
    $addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addCreatedCompaniesToUserCreatedWorkersPaging: OffsetPaging
    $addCreatedCompaniesToUserCreatedWorkersFilter: WorkerFilter
    $addCreatedCompaniesToUserCreatedWorkersSorting: [WorkerSort!]
    $addCreatedCompaniesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addCreatedCompaniesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addCreatedCompaniesToUserCreatedContractsPaging: OffsetPaging
    $addCreatedCompaniesToUserCreatedContractsFilter: ContractFilter
    $addCreatedCompaniesToUserCreatedContractsSorting: [ContractSort!]
    $addCreatedCompaniesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addCreatedCompaniesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addCreatedCompaniesToUserCreatedMiddlemansPaging: OffsetPaging
    $addCreatedCompaniesToUserCreatedMiddlemansFilter: MiddlemanFilter
    $addCreatedCompaniesToUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $addCreatedCompaniesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addCreatedCompaniesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addCreatedCompaniesToUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $addCreatedCompaniesToUserCreatedCompaniesPaging
        filter: $addCreatedCompaniesToUserCreatedCompaniesFilter
        sorting: $addCreatedCompaniesToUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $addCreatedCompaniesToUserCreatedProjectsPaging
        filter: $addCreatedCompaniesToUserCreatedProjectsFilter
        sorting: $addCreatedCompaniesToUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $addCreatedCompaniesToUserContactsPaging
        filter: $addCreatedCompaniesToUserContactsFilter
        sorting: $addCreatedCompaniesToUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $addCreatedCompaniesToUserProjectChangesPaging
        filter: $addCreatedCompaniesToUserProjectChangesFilter
        sorting: $addCreatedCompaniesToUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $addCreatedCompaniesToUserCreatedWorkersPaging
        filter: $addCreatedCompaniesToUserCreatedWorkersFilter
        sorting: $addCreatedCompaniesToUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $addCreatedCompaniesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $addCreatedCompaniesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $addCreatedCompaniesToUserCreatedContractsPaging
        filter: $addCreatedCompaniesToUserCreatedContractsFilter
        sorting: $addCreatedCompaniesToUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $addCreatedCompaniesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $addCreatedCompaniesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $addCreatedCompaniesToUserCreatedMiddlemansPaging
        filter: $addCreatedCompaniesToUserCreatedMiddlemansFilter
        sorting: $addCreatedCompaniesToUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $addCreatedCompaniesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $addCreatedCompaniesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useAddCreatedCompaniesToUserMutation__
 *
 * To run a mutation, you first call `useAddCreatedCompaniesToUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddCreatedCompaniesToUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddCreatedCompaniesToUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addCreatedCompaniesToUserCreatedCompaniesPaging: // value for 'addCreatedCompaniesToUserCreatedCompaniesPaging'
 *     addCreatedCompaniesToUserCreatedCompaniesFilter: // value for 'addCreatedCompaniesToUserCreatedCompaniesFilter'
 *     addCreatedCompaniesToUserCreatedCompaniesSorting: // value for 'addCreatedCompaniesToUserCreatedCompaniesSorting'
 *     addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addCreatedCompaniesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addCreatedCompaniesToUserCreatedProjectsPaging: // value for 'addCreatedCompaniesToUserCreatedProjectsPaging'
 *     addCreatedCompaniesToUserCreatedProjectsFilter: // value for 'addCreatedCompaniesToUserCreatedProjectsFilter'
 *     addCreatedCompaniesToUserCreatedProjectsSorting: // value for 'addCreatedCompaniesToUserCreatedProjectsSorting'
 *     addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addCreatedCompaniesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addCreatedCompaniesToUserContactsPaging: // value for 'addCreatedCompaniesToUserContactsPaging'
 *     addCreatedCompaniesToUserContactsFilter: // value for 'addCreatedCompaniesToUserContactsFilter'
 *     addCreatedCompaniesToUserContactsSorting: // value for 'addCreatedCompaniesToUserContactsSorting'
 *     addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addCreatedCompaniesToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addCreatedCompaniesToUserProjectChangesPaging: // value for 'addCreatedCompaniesToUserProjectChangesPaging'
 *     addCreatedCompaniesToUserProjectChangesFilter: // value for 'addCreatedCompaniesToUserProjectChangesFilter'
 *     addCreatedCompaniesToUserProjectChangesSorting: // value for 'addCreatedCompaniesToUserProjectChangesSorting'
 *     addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addCreatedCompaniesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addCreatedCompaniesToUserCreatedWorkersPaging: // value for 'addCreatedCompaniesToUserCreatedWorkersPaging'
 *     addCreatedCompaniesToUserCreatedWorkersFilter: // value for 'addCreatedCompaniesToUserCreatedWorkersFilter'
 *     addCreatedCompaniesToUserCreatedWorkersSorting: // value for 'addCreatedCompaniesToUserCreatedWorkersSorting'
 *     addCreatedCompaniesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addCreatedCompaniesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addCreatedCompaniesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addCreatedCompaniesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addCreatedCompaniesToUserCreatedContractsPaging: // value for 'addCreatedCompaniesToUserCreatedContractsPaging'
 *     addCreatedCompaniesToUserCreatedContractsFilter: // value for 'addCreatedCompaniesToUserCreatedContractsFilter'
 *     addCreatedCompaniesToUserCreatedContractsSorting: // value for 'addCreatedCompaniesToUserCreatedContractsSorting'
 *     addCreatedCompaniesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addCreatedCompaniesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addCreatedCompaniesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addCreatedCompaniesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addCreatedCompaniesToUserCreatedMiddlemansPaging: // value for 'addCreatedCompaniesToUserCreatedMiddlemansPaging'
 *     addCreatedCompaniesToUserCreatedMiddlemansFilter: // value for 'addCreatedCompaniesToUserCreatedMiddlemansFilter'
 *     addCreatedCompaniesToUserCreatedMiddlemansSorting: // value for 'addCreatedCompaniesToUserCreatedMiddlemansSorting'
 *     addCreatedCompaniesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addCreatedCompaniesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addCreatedCompaniesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addCreatedCompaniesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddCreatedCompaniesToUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddCreatedCompaniesToUserMutation,
        AddCreatedCompaniesToUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddCreatedCompaniesToUserMutation,
          AddCreatedCompaniesToUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddCreatedCompaniesToUserMutation,
    AddCreatedCompaniesToUserMutationVariables
  >(AddCreatedCompaniesToUserMutationDocument, options);
}
export type AddCreatedCompaniesToUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddCreatedCompaniesToUserMutation,
    AddCreatedCompaniesToUserMutationVariables
  >;
export const SetCreatedCompaniesOnUserMutationDocument = gql`
  mutation setCreatedCompaniesOnUserMutation(
    $input: SetCreatedCompaniesOnUserInput!
    $setCreatedCompaniesOnUserCreatedCompaniesPaging: OffsetPaging
    $setCreatedCompaniesOnUserCreatedCompaniesFilter: CompanyFilter
    $setCreatedCompaniesOnUserCreatedCompaniesSorting: [CompanySort!]
    $setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedCompaniesOnUserCreatedProjectsPaging: OffsetPaging
    $setCreatedCompaniesOnUserCreatedProjectsFilter: ProjectFilter
    $setCreatedCompaniesOnUserCreatedProjectsSorting: [ProjectSort!]
    $setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedCompaniesOnUserContactsPaging: OffsetPaging
    $setCreatedCompaniesOnUserContactsFilter: ContactFilter
    $setCreatedCompaniesOnUserContactsSorting: [ContactSort!]
    $setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedCompaniesOnUserProjectChangesPaging: OffsetPaging
    $setCreatedCompaniesOnUserProjectChangesFilter: ContactFilter
    $setCreatedCompaniesOnUserProjectChangesSorting: [ContactSort!]
    $setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedCompaniesOnUserCreatedWorkersPaging: OffsetPaging
    $setCreatedCompaniesOnUserCreatedWorkersFilter: WorkerFilter
    $setCreatedCompaniesOnUserCreatedWorkersSorting: [WorkerSort!]
    $setCreatedCompaniesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedCompaniesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedCompaniesOnUserCreatedContractsPaging: OffsetPaging
    $setCreatedCompaniesOnUserCreatedContractsFilter: ContractFilter
    $setCreatedCompaniesOnUserCreatedContractsSorting: [ContractSort!]
    $setCreatedCompaniesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedCompaniesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedCompaniesOnUserCreatedMiddlemansPaging: OffsetPaging
    $setCreatedCompaniesOnUserCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedCompaniesOnUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedCompaniesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedCompaniesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedCompaniesOnUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $setCreatedCompaniesOnUserCreatedCompaniesPaging
        filter: $setCreatedCompaniesOnUserCreatedCompaniesFilter
        sorting: $setCreatedCompaniesOnUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $setCreatedCompaniesOnUserCreatedProjectsPaging
        filter: $setCreatedCompaniesOnUserCreatedProjectsFilter
        sorting: $setCreatedCompaniesOnUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $setCreatedCompaniesOnUserContactsPaging
        filter: $setCreatedCompaniesOnUserContactsFilter
        sorting: $setCreatedCompaniesOnUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $setCreatedCompaniesOnUserProjectChangesPaging
        filter: $setCreatedCompaniesOnUserProjectChangesFilter
        sorting: $setCreatedCompaniesOnUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $setCreatedCompaniesOnUserCreatedWorkersPaging
        filter: $setCreatedCompaniesOnUserCreatedWorkersFilter
        sorting: $setCreatedCompaniesOnUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $setCreatedCompaniesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $setCreatedCompaniesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $setCreatedCompaniesOnUserCreatedContractsPaging
        filter: $setCreatedCompaniesOnUserCreatedContractsFilter
        sorting: $setCreatedCompaniesOnUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $setCreatedCompaniesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $setCreatedCompaniesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $setCreatedCompaniesOnUserCreatedMiddlemansPaging
        filter: $setCreatedCompaniesOnUserCreatedMiddlemansFilter
        sorting: $setCreatedCompaniesOnUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $setCreatedCompaniesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $setCreatedCompaniesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedCompaniesOnUserMutation__
 *
 * To run a mutation, you first call `useSetCreatedCompaniesOnUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedCompaniesOnUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedCompaniesOnUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedCompaniesOnUserCreatedCompaniesPaging: // value for 'setCreatedCompaniesOnUserCreatedCompaniesPaging'
 *     setCreatedCompaniesOnUserCreatedCompaniesFilter: // value for 'setCreatedCompaniesOnUserCreatedCompaniesFilter'
 *     setCreatedCompaniesOnUserCreatedCompaniesSorting: // value for 'setCreatedCompaniesOnUserCreatedCompaniesSorting'
 *     setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedCompaniesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedCompaniesOnUserCreatedProjectsPaging: // value for 'setCreatedCompaniesOnUserCreatedProjectsPaging'
 *     setCreatedCompaniesOnUserCreatedProjectsFilter: // value for 'setCreatedCompaniesOnUserCreatedProjectsFilter'
 *     setCreatedCompaniesOnUserCreatedProjectsSorting: // value for 'setCreatedCompaniesOnUserCreatedProjectsSorting'
 *     setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedCompaniesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedCompaniesOnUserContactsPaging: // value for 'setCreatedCompaniesOnUserContactsPaging'
 *     setCreatedCompaniesOnUserContactsFilter: // value for 'setCreatedCompaniesOnUserContactsFilter'
 *     setCreatedCompaniesOnUserContactsSorting: // value for 'setCreatedCompaniesOnUserContactsSorting'
 *     setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedCompaniesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedCompaniesOnUserProjectChangesPaging: // value for 'setCreatedCompaniesOnUserProjectChangesPaging'
 *     setCreatedCompaniesOnUserProjectChangesFilter: // value for 'setCreatedCompaniesOnUserProjectChangesFilter'
 *     setCreatedCompaniesOnUserProjectChangesSorting: // value for 'setCreatedCompaniesOnUserProjectChangesSorting'
 *     setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedCompaniesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedCompaniesOnUserCreatedWorkersPaging: // value for 'setCreatedCompaniesOnUserCreatedWorkersPaging'
 *     setCreatedCompaniesOnUserCreatedWorkersFilter: // value for 'setCreatedCompaniesOnUserCreatedWorkersFilter'
 *     setCreatedCompaniesOnUserCreatedWorkersSorting: // value for 'setCreatedCompaniesOnUserCreatedWorkersSorting'
 *     setCreatedCompaniesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedCompaniesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedCompaniesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedCompaniesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedCompaniesOnUserCreatedContractsPaging: // value for 'setCreatedCompaniesOnUserCreatedContractsPaging'
 *     setCreatedCompaniesOnUserCreatedContractsFilter: // value for 'setCreatedCompaniesOnUserCreatedContractsFilter'
 *     setCreatedCompaniesOnUserCreatedContractsSorting: // value for 'setCreatedCompaniesOnUserCreatedContractsSorting'
 *     setCreatedCompaniesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedCompaniesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedCompaniesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedCompaniesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedCompaniesOnUserCreatedMiddlemansPaging: // value for 'setCreatedCompaniesOnUserCreatedMiddlemansPaging'
 *     setCreatedCompaniesOnUserCreatedMiddlemansFilter: // value for 'setCreatedCompaniesOnUserCreatedMiddlemansFilter'
 *     setCreatedCompaniesOnUserCreatedMiddlemansSorting: // value for 'setCreatedCompaniesOnUserCreatedMiddlemansSorting'
 *     setCreatedCompaniesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedCompaniesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedCompaniesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedCompaniesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedCompaniesOnUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedCompaniesOnUserMutation,
        SetCreatedCompaniesOnUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedCompaniesOnUserMutation,
          SetCreatedCompaniesOnUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedCompaniesOnUserMutation,
    SetCreatedCompaniesOnUserMutationVariables
  >(SetCreatedCompaniesOnUserMutationDocument, options);
}
export type SetCreatedCompaniesOnUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedCompaniesOnUserMutation,
    SetCreatedCompaniesOnUserMutationVariables
  >;
export const AddCreatedProjectsToUserMutationDocument = gql`
  mutation addCreatedProjectsToUserMutation(
    $input: AddCreatedProjectsToUserInput!
    $addCreatedProjectsToUserCreatedCompaniesPaging: OffsetPaging
    $addCreatedProjectsToUserCreatedCompaniesFilter: CompanyFilter
    $addCreatedProjectsToUserCreatedCompaniesSorting: [CompanySort!]
    $addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addCreatedProjectsToUserCreatedProjectsPaging: OffsetPaging
    $addCreatedProjectsToUserCreatedProjectsFilter: ProjectFilter
    $addCreatedProjectsToUserCreatedProjectsSorting: [ProjectSort!]
    $addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addCreatedProjectsToUserContactsPaging: OffsetPaging
    $addCreatedProjectsToUserContactsFilter: ContactFilter
    $addCreatedProjectsToUserContactsSorting: [ContactSort!]
    $addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addCreatedProjectsToUserProjectChangesPaging: OffsetPaging
    $addCreatedProjectsToUserProjectChangesFilter: ContactFilter
    $addCreatedProjectsToUserProjectChangesSorting: [ContactSort!]
    $addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addCreatedProjectsToUserCreatedWorkersPaging: OffsetPaging
    $addCreatedProjectsToUserCreatedWorkersFilter: WorkerFilter
    $addCreatedProjectsToUserCreatedWorkersSorting: [WorkerSort!]
    $addCreatedProjectsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addCreatedProjectsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addCreatedProjectsToUserCreatedContractsPaging: OffsetPaging
    $addCreatedProjectsToUserCreatedContractsFilter: ContractFilter
    $addCreatedProjectsToUserCreatedContractsSorting: [ContractSort!]
    $addCreatedProjectsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addCreatedProjectsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addCreatedProjectsToUserCreatedMiddlemansPaging: OffsetPaging
    $addCreatedProjectsToUserCreatedMiddlemansFilter: MiddlemanFilter
    $addCreatedProjectsToUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $addCreatedProjectsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addCreatedProjectsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addCreatedProjectsToUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $addCreatedProjectsToUserCreatedCompaniesPaging
        filter: $addCreatedProjectsToUserCreatedCompaniesFilter
        sorting: $addCreatedProjectsToUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $addCreatedProjectsToUserCreatedProjectsPaging
        filter: $addCreatedProjectsToUserCreatedProjectsFilter
        sorting: $addCreatedProjectsToUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $addCreatedProjectsToUserContactsPaging
        filter: $addCreatedProjectsToUserContactsFilter
        sorting: $addCreatedProjectsToUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $addCreatedProjectsToUserProjectChangesPaging
        filter: $addCreatedProjectsToUserProjectChangesFilter
        sorting: $addCreatedProjectsToUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $addCreatedProjectsToUserCreatedWorkersPaging
        filter: $addCreatedProjectsToUserCreatedWorkersFilter
        sorting: $addCreatedProjectsToUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $addCreatedProjectsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $addCreatedProjectsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $addCreatedProjectsToUserCreatedContractsPaging
        filter: $addCreatedProjectsToUserCreatedContractsFilter
        sorting: $addCreatedProjectsToUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $addCreatedProjectsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $addCreatedProjectsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $addCreatedProjectsToUserCreatedMiddlemansPaging
        filter: $addCreatedProjectsToUserCreatedMiddlemansFilter
        sorting: $addCreatedProjectsToUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $addCreatedProjectsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $addCreatedProjectsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useAddCreatedProjectsToUserMutation__
 *
 * To run a mutation, you first call `useAddCreatedProjectsToUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddCreatedProjectsToUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddCreatedProjectsToUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addCreatedProjectsToUserCreatedCompaniesPaging: // value for 'addCreatedProjectsToUserCreatedCompaniesPaging'
 *     addCreatedProjectsToUserCreatedCompaniesFilter: // value for 'addCreatedProjectsToUserCreatedCompaniesFilter'
 *     addCreatedProjectsToUserCreatedCompaniesSorting: // value for 'addCreatedProjectsToUserCreatedCompaniesSorting'
 *     addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addCreatedProjectsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addCreatedProjectsToUserCreatedProjectsPaging: // value for 'addCreatedProjectsToUserCreatedProjectsPaging'
 *     addCreatedProjectsToUserCreatedProjectsFilter: // value for 'addCreatedProjectsToUserCreatedProjectsFilter'
 *     addCreatedProjectsToUserCreatedProjectsSorting: // value for 'addCreatedProjectsToUserCreatedProjectsSorting'
 *     addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addCreatedProjectsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addCreatedProjectsToUserContactsPaging: // value for 'addCreatedProjectsToUserContactsPaging'
 *     addCreatedProjectsToUserContactsFilter: // value for 'addCreatedProjectsToUserContactsFilter'
 *     addCreatedProjectsToUserContactsSorting: // value for 'addCreatedProjectsToUserContactsSorting'
 *     addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addCreatedProjectsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addCreatedProjectsToUserProjectChangesPaging: // value for 'addCreatedProjectsToUserProjectChangesPaging'
 *     addCreatedProjectsToUserProjectChangesFilter: // value for 'addCreatedProjectsToUserProjectChangesFilter'
 *     addCreatedProjectsToUserProjectChangesSorting: // value for 'addCreatedProjectsToUserProjectChangesSorting'
 *     addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addCreatedProjectsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addCreatedProjectsToUserCreatedWorkersPaging: // value for 'addCreatedProjectsToUserCreatedWorkersPaging'
 *     addCreatedProjectsToUserCreatedWorkersFilter: // value for 'addCreatedProjectsToUserCreatedWorkersFilter'
 *     addCreatedProjectsToUserCreatedWorkersSorting: // value for 'addCreatedProjectsToUserCreatedWorkersSorting'
 *     addCreatedProjectsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addCreatedProjectsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addCreatedProjectsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addCreatedProjectsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addCreatedProjectsToUserCreatedContractsPaging: // value for 'addCreatedProjectsToUserCreatedContractsPaging'
 *     addCreatedProjectsToUserCreatedContractsFilter: // value for 'addCreatedProjectsToUserCreatedContractsFilter'
 *     addCreatedProjectsToUserCreatedContractsSorting: // value for 'addCreatedProjectsToUserCreatedContractsSorting'
 *     addCreatedProjectsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addCreatedProjectsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addCreatedProjectsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addCreatedProjectsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addCreatedProjectsToUserCreatedMiddlemansPaging: // value for 'addCreatedProjectsToUserCreatedMiddlemansPaging'
 *     addCreatedProjectsToUserCreatedMiddlemansFilter: // value for 'addCreatedProjectsToUserCreatedMiddlemansFilter'
 *     addCreatedProjectsToUserCreatedMiddlemansSorting: // value for 'addCreatedProjectsToUserCreatedMiddlemansSorting'
 *     addCreatedProjectsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addCreatedProjectsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addCreatedProjectsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addCreatedProjectsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddCreatedProjectsToUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddCreatedProjectsToUserMutation,
        AddCreatedProjectsToUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddCreatedProjectsToUserMutation,
          AddCreatedProjectsToUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddCreatedProjectsToUserMutation,
    AddCreatedProjectsToUserMutationVariables
  >(AddCreatedProjectsToUserMutationDocument, options);
}
export type AddCreatedProjectsToUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddCreatedProjectsToUserMutation,
    AddCreatedProjectsToUserMutationVariables
  >;
export const SetCreatedProjectsOnUserMutationDocument = gql`
  mutation setCreatedProjectsOnUserMutation(
    $input: SetCreatedProjectsOnUserInput!
    $setCreatedProjectsOnUserCreatedCompaniesPaging: OffsetPaging
    $setCreatedProjectsOnUserCreatedCompaniesFilter: CompanyFilter
    $setCreatedProjectsOnUserCreatedCompaniesSorting: [CompanySort!]
    $setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedProjectsOnUserCreatedProjectsPaging: OffsetPaging
    $setCreatedProjectsOnUserCreatedProjectsFilter: ProjectFilter
    $setCreatedProjectsOnUserCreatedProjectsSorting: [ProjectSort!]
    $setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedProjectsOnUserContactsPaging: OffsetPaging
    $setCreatedProjectsOnUserContactsFilter: ContactFilter
    $setCreatedProjectsOnUserContactsSorting: [ContactSort!]
    $setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedProjectsOnUserProjectChangesPaging: OffsetPaging
    $setCreatedProjectsOnUserProjectChangesFilter: ContactFilter
    $setCreatedProjectsOnUserProjectChangesSorting: [ContactSort!]
    $setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedProjectsOnUserCreatedWorkersPaging: OffsetPaging
    $setCreatedProjectsOnUserCreatedWorkersFilter: WorkerFilter
    $setCreatedProjectsOnUserCreatedWorkersSorting: [WorkerSort!]
    $setCreatedProjectsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedProjectsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedProjectsOnUserCreatedContractsPaging: OffsetPaging
    $setCreatedProjectsOnUserCreatedContractsFilter: ContractFilter
    $setCreatedProjectsOnUserCreatedContractsSorting: [ContractSort!]
    $setCreatedProjectsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedProjectsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedProjectsOnUserCreatedMiddlemansPaging: OffsetPaging
    $setCreatedProjectsOnUserCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedProjectsOnUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedProjectsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedProjectsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedProjectsOnUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $setCreatedProjectsOnUserCreatedCompaniesPaging
        filter: $setCreatedProjectsOnUserCreatedCompaniesFilter
        sorting: $setCreatedProjectsOnUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $setCreatedProjectsOnUserCreatedProjectsPaging
        filter: $setCreatedProjectsOnUserCreatedProjectsFilter
        sorting: $setCreatedProjectsOnUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $setCreatedProjectsOnUserContactsPaging
        filter: $setCreatedProjectsOnUserContactsFilter
        sorting: $setCreatedProjectsOnUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $setCreatedProjectsOnUserProjectChangesPaging
        filter: $setCreatedProjectsOnUserProjectChangesFilter
        sorting: $setCreatedProjectsOnUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $setCreatedProjectsOnUserCreatedWorkersPaging
        filter: $setCreatedProjectsOnUserCreatedWorkersFilter
        sorting: $setCreatedProjectsOnUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $setCreatedProjectsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $setCreatedProjectsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $setCreatedProjectsOnUserCreatedContractsPaging
        filter: $setCreatedProjectsOnUserCreatedContractsFilter
        sorting: $setCreatedProjectsOnUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $setCreatedProjectsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $setCreatedProjectsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $setCreatedProjectsOnUserCreatedMiddlemansPaging
        filter: $setCreatedProjectsOnUserCreatedMiddlemansFilter
        sorting: $setCreatedProjectsOnUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $setCreatedProjectsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $setCreatedProjectsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedProjectsOnUserMutation__
 *
 * To run a mutation, you first call `useSetCreatedProjectsOnUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedProjectsOnUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedProjectsOnUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedProjectsOnUserCreatedCompaniesPaging: // value for 'setCreatedProjectsOnUserCreatedCompaniesPaging'
 *     setCreatedProjectsOnUserCreatedCompaniesFilter: // value for 'setCreatedProjectsOnUserCreatedCompaniesFilter'
 *     setCreatedProjectsOnUserCreatedCompaniesSorting: // value for 'setCreatedProjectsOnUserCreatedCompaniesSorting'
 *     setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedProjectsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedProjectsOnUserCreatedProjectsPaging: // value for 'setCreatedProjectsOnUserCreatedProjectsPaging'
 *     setCreatedProjectsOnUserCreatedProjectsFilter: // value for 'setCreatedProjectsOnUserCreatedProjectsFilter'
 *     setCreatedProjectsOnUserCreatedProjectsSorting: // value for 'setCreatedProjectsOnUserCreatedProjectsSorting'
 *     setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedProjectsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedProjectsOnUserContactsPaging: // value for 'setCreatedProjectsOnUserContactsPaging'
 *     setCreatedProjectsOnUserContactsFilter: // value for 'setCreatedProjectsOnUserContactsFilter'
 *     setCreatedProjectsOnUserContactsSorting: // value for 'setCreatedProjectsOnUserContactsSorting'
 *     setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedProjectsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedProjectsOnUserProjectChangesPaging: // value for 'setCreatedProjectsOnUserProjectChangesPaging'
 *     setCreatedProjectsOnUserProjectChangesFilter: // value for 'setCreatedProjectsOnUserProjectChangesFilter'
 *     setCreatedProjectsOnUserProjectChangesSorting: // value for 'setCreatedProjectsOnUserProjectChangesSorting'
 *     setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedProjectsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedProjectsOnUserCreatedWorkersPaging: // value for 'setCreatedProjectsOnUserCreatedWorkersPaging'
 *     setCreatedProjectsOnUserCreatedWorkersFilter: // value for 'setCreatedProjectsOnUserCreatedWorkersFilter'
 *     setCreatedProjectsOnUserCreatedWorkersSorting: // value for 'setCreatedProjectsOnUserCreatedWorkersSorting'
 *     setCreatedProjectsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedProjectsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedProjectsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedProjectsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedProjectsOnUserCreatedContractsPaging: // value for 'setCreatedProjectsOnUserCreatedContractsPaging'
 *     setCreatedProjectsOnUserCreatedContractsFilter: // value for 'setCreatedProjectsOnUserCreatedContractsFilter'
 *     setCreatedProjectsOnUserCreatedContractsSorting: // value for 'setCreatedProjectsOnUserCreatedContractsSorting'
 *     setCreatedProjectsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedProjectsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedProjectsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedProjectsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedProjectsOnUserCreatedMiddlemansPaging: // value for 'setCreatedProjectsOnUserCreatedMiddlemansPaging'
 *     setCreatedProjectsOnUserCreatedMiddlemansFilter: // value for 'setCreatedProjectsOnUserCreatedMiddlemansFilter'
 *     setCreatedProjectsOnUserCreatedMiddlemansSorting: // value for 'setCreatedProjectsOnUserCreatedMiddlemansSorting'
 *     setCreatedProjectsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedProjectsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedProjectsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedProjectsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedProjectsOnUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedProjectsOnUserMutation,
        SetCreatedProjectsOnUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedProjectsOnUserMutation,
          SetCreatedProjectsOnUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedProjectsOnUserMutation,
    SetCreatedProjectsOnUserMutationVariables
  >(SetCreatedProjectsOnUserMutationDocument, options);
}
export type SetCreatedProjectsOnUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedProjectsOnUserMutation,
    SetCreatedProjectsOnUserMutationVariables
  >;
export const AddContactsToUserMutationDocument = gql`
  mutation addContactsToUserMutation(
    $input: AddContactsToUserInput!
    $addContactsToUserCreatedCompaniesPaging: OffsetPaging
    $addContactsToUserCreatedCompaniesFilter: CompanyFilter
    $addContactsToUserCreatedCompaniesSorting: [CompanySort!]
    $addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addContactsToUserCreatedProjectsPaging: OffsetPaging
    $addContactsToUserCreatedProjectsFilter: ProjectFilter
    $addContactsToUserCreatedProjectsSorting: [ProjectSort!]
    $addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addContactsToUserContactsPaging: OffsetPaging
    $addContactsToUserContactsFilter: ContactFilter
    $addContactsToUserContactsSorting: [ContactSort!]
    $addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addContactsToUserProjectChangesPaging: OffsetPaging
    $addContactsToUserProjectChangesFilter: ContactFilter
    $addContactsToUserProjectChangesSorting: [ContactSort!]
    $addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addContactsToUserCreatedWorkersPaging: OffsetPaging
    $addContactsToUserCreatedWorkersFilter: WorkerFilter
    $addContactsToUserCreatedWorkersSorting: [WorkerSort!]
    $addContactsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addContactsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addContactsToUserCreatedContractsPaging: OffsetPaging
    $addContactsToUserCreatedContractsFilter: ContractFilter
    $addContactsToUserCreatedContractsSorting: [ContractSort!]
    $addContactsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addContactsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addContactsToUserCreatedMiddlemansPaging: OffsetPaging
    $addContactsToUserCreatedMiddlemansFilter: MiddlemanFilter
    $addContactsToUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $addContactsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addContactsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addContactsToUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $addContactsToUserCreatedCompaniesPaging
        filter: $addContactsToUserCreatedCompaniesFilter
        sorting: $addContactsToUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $addContactsToUserCreatedProjectsPaging
        filter: $addContactsToUserCreatedProjectsFilter
        sorting: $addContactsToUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $addContactsToUserContactsPaging
        filter: $addContactsToUserContactsFilter
        sorting: $addContactsToUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $addContactsToUserProjectChangesPaging
        filter: $addContactsToUserProjectChangesFilter
        sorting: $addContactsToUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $addContactsToUserCreatedWorkersPaging
        filter: $addContactsToUserCreatedWorkersFilter
        sorting: $addContactsToUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $addContactsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $addContactsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $addContactsToUserCreatedContractsPaging
        filter: $addContactsToUserCreatedContractsFilter
        sorting: $addContactsToUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $addContactsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $addContactsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $addContactsToUserCreatedMiddlemansPaging
        filter: $addContactsToUserCreatedMiddlemansFilter
        sorting: $addContactsToUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $addContactsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $addContactsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useAddContactsToUserMutation__
 *
 * To run a mutation, you first call `useAddContactsToUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddContactsToUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddContactsToUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addContactsToUserCreatedCompaniesPaging: // value for 'addContactsToUserCreatedCompaniesPaging'
 *     addContactsToUserCreatedCompaniesFilter: // value for 'addContactsToUserCreatedCompaniesFilter'
 *     addContactsToUserCreatedCompaniesSorting: // value for 'addContactsToUserCreatedCompaniesSorting'
 *     addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addContactsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addContactsToUserCreatedProjectsPaging: // value for 'addContactsToUserCreatedProjectsPaging'
 *     addContactsToUserCreatedProjectsFilter: // value for 'addContactsToUserCreatedProjectsFilter'
 *     addContactsToUserCreatedProjectsSorting: // value for 'addContactsToUserCreatedProjectsSorting'
 *     addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addContactsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addContactsToUserContactsPaging: // value for 'addContactsToUserContactsPaging'
 *     addContactsToUserContactsFilter: // value for 'addContactsToUserContactsFilter'
 *     addContactsToUserContactsSorting: // value for 'addContactsToUserContactsSorting'
 *     addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addContactsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addContactsToUserProjectChangesPaging: // value for 'addContactsToUserProjectChangesPaging'
 *     addContactsToUserProjectChangesFilter: // value for 'addContactsToUserProjectChangesFilter'
 *     addContactsToUserProjectChangesSorting: // value for 'addContactsToUserProjectChangesSorting'
 *     addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addContactsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addContactsToUserCreatedWorkersPaging: // value for 'addContactsToUserCreatedWorkersPaging'
 *     addContactsToUserCreatedWorkersFilter: // value for 'addContactsToUserCreatedWorkersFilter'
 *     addContactsToUserCreatedWorkersSorting: // value for 'addContactsToUserCreatedWorkersSorting'
 *     addContactsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addContactsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addContactsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addContactsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addContactsToUserCreatedContractsPaging: // value for 'addContactsToUserCreatedContractsPaging'
 *     addContactsToUserCreatedContractsFilter: // value for 'addContactsToUserCreatedContractsFilter'
 *     addContactsToUserCreatedContractsSorting: // value for 'addContactsToUserCreatedContractsSorting'
 *     addContactsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addContactsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addContactsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addContactsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addContactsToUserCreatedMiddlemansPaging: // value for 'addContactsToUserCreatedMiddlemansPaging'
 *     addContactsToUserCreatedMiddlemansFilter: // value for 'addContactsToUserCreatedMiddlemansFilter'
 *     addContactsToUserCreatedMiddlemansSorting: // value for 'addContactsToUserCreatedMiddlemansSorting'
 *     addContactsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addContactsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addContactsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addContactsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddContactsToUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddContactsToUserMutation,
        AddContactsToUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddContactsToUserMutation,
          AddContactsToUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddContactsToUserMutation,
    AddContactsToUserMutationVariables
  >(AddContactsToUserMutationDocument, options);
}
export type AddContactsToUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddContactsToUserMutation,
    AddContactsToUserMutationVariables
  >;
export const SetContactsOnUserMutationDocument = gql`
  mutation setContactsOnUserMutation(
    $input: SetContactsOnUserInput!
    $setContactsOnUserCreatedCompaniesPaging: OffsetPaging
    $setContactsOnUserCreatedCompaniesFilter: CompanyFilter
    $setContactsOnUserCreatedCompaniesSorting: [CompanySort!]
    $setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactsOnUserCreatedProjectsPaging: OffsetPaging
    $setContactsOnUserCreatedProjectsFilter: ProjectFilter
    $setContactsOnUserCreatedProjectsSorting: [ProjectSort!]
    $setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactsOnUserContactsPaging: OffsetPaging
    $setContactsOnUserContactsFilter: ContactFilter
    $setContactsOnUserContactsSorting: [ContactSort!]
    $setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactsOnUserProjectChangesPaging: OffsetPaging
    $setContactsOnUserProjectChangesFilter: ContactFilter
    $setContactsOnUserProjectChangesSorting: [ContactSort!]
    $setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactsOnUserCreatedWorkersPaging: OffsetPaging
    $setContactsOnUserCreatedWorkersFilter: WorkerFilter
    $setContactsOnUserCreatedWorkersSorting: [WorkerSort!]
    $setContactsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactsOnUserCreatedContractsPaging: OffsetPaging
    $setContactsOnUserCreatedContractsFilter: ContractFilter
    $setContactsOnUserCreatedContractsSorting: [ContractSort!]
    $setContactsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactsOnUserCreatedMiddlemansPaging: OffsetPaging
    $setContactsOnUserCreatedMiddlemansFilter: MiddlemanFilter
    $setContactsOnUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setContactsOnUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $setContactsOnUserCreatedCompaniesPaging
        filter: $setContactsOnUserCreatedCompaniesFilter
        sorting: $setContactsOnUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $setContactsOnUserCreatedProjectsPaging
        filter: $setContactsOnUserCreatedProjectsFilter
        sorting: $setContactsOnUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $setContactsOnUserContactsPaging
        filter: $setContactsOnUserContactsFilter
        sorting: $setContactsOnUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $setContactsOnUserProjectChangesPaging
        filter: $setContactsOnUserProjectChangesFilter
        sorting: $setContactsOnUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $setContactsOnUserCreatedWorkersPaging
        filter: $setContactsOnUserCreatedWorkersFilter
        sorting: $setContactsOnUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $setContactsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $setContactsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $setContactsOnUserCreatedContractsPaging
        filter: $setContactsOnUserCreatedContractsFilter
        sorting: $setContactsOnUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $setContactsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $setContactsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $setContactsOnUserCreatedMiddlemansPaging
        filter: $setContactsOnUserCreatedMiddlemansFilter
        sorting: $setContactsOnUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $setContactsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $setContactsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useSetContactsOnUserMutation__
 *
 * To run a mutation, you first call `useSetContactsOnUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetContactsOnUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetContactsOnUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setContactsOnUserCreatedCompaniesPaging: // value for 'setContactsOnUserCreatedCompaniesPaging'
 *     setContactsOnUserCreatedCompaniesFilter: // value for 'setContactsOnUserCreatedCompaniesFilter'
 *     setContactsOnUserCreatedCompaniesSorting: // value for 'setContactsOnUserCreatedCompaniesSorting'
 *     setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactsOnUserCreatedProjectsPaging: // value for 'setContactsOnUserCreatedProjectsPaging'
 *     setContactsOnUserCreatedProjectsFilter: // value for 'setContactsOnUserCreatedProjectsFilter'
 *     setContactsOnUserCreatedProjectsSorting: // value for 'setContactsOnUserCreatedProjectsSorting'
 *     setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactsOnUserContactsPaging: // value for 'setContactsOnUserContactsPaging'
 *     setContactsOnUserContactsFilter: // value for 'setContactsOnUserContactsFilter'
 *     setContactsOnUserContactsSorting: // value for 'setContactsOnUserContactsSorting'
 *     setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactsOnUserProjectChangesPaging: // value for 'setContactsOnUserProjectChangesPaging'
 *     setContactsOnUserProjectChangesFilter: // value for 'setContactsOnUserProjectChangesFilter'
 *     setContactsOnUserProjectChangesSorting: // value for 'setContactsOnUserProjectChangesSorting'
 *     setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactsOnUserCreatedWorkersPaging: // value for 'setContactsOnUserCreatedWorkersPaging'
 *     setContactsOnUserCreatedWorkersFilter: // value for 'setContactsOnUserCreatedWorkersFilter'
 *     setContactsOnUserCreatedWorkersSorting: // value for 'setContactsOnUserCreatedWorkersSorting'
 *     setContactsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactsOnUserCreatedContractsPaging: // value for 'setContactsOnUserCreatedContractsPaging'
 *     setContactsOnUserCreatedContractsFilter: // value for 'setContactsOnUserCreatedContractsFilter'
 *     setContactsOnUserCreatedContractsSorting: // value for 'setContactsOnUserCreatedContractsSorting'
 *     setContactsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactsOnUserCreatedMiddlemansPaging: // value for 'setContactsOnUserCreatedMiddlemansPaging'
 *     setContactsOnUserCreatedMiddlemansFilter: // value for 'setContactsOnUserCreatedMiddlemansFilter'
 *     setContactsOnUserCreatedMiddlemansSorting: // value for 'setContactsOnUserCreatedMiddlemansSorting'
 *     setContactsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetContactsOnUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetContactsOnUserMutation,
        SetContactsOnUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetContactsOnUserMutation,
          SetContactsOnUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetContactsOnUserMutation,
    SetContactsOnUserMutationVariables
  >(SetContactsOnUserMutationDocument, options);
}
export type SetContactsOnUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetContactsOnUserMutation,
    SetContactsOnUserMutationVariables
  >;
export const AddProjectChangesToUserMutationDocument = gql`
  mutation addProjectChangesToUserMutation(
    $input: AddProjectChangesToUserInput!
    $addProjectChangesToUserCreatedCompaniesPaging: OffsetPaging
    $addProjectChangesToUserCreatedCompaniesFilter: CompanyFilter
    $addProjectChangesToUserCreatedCompaniesSorting: [CompanySort!]
    $addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addProjectChangesToUserCreatedProjectsPaging: OffsetPaging
    $addProjectChangesToUserCreatedProjectsFilter: ProjectFilter
    $addProjectChangesToUserCreatedProjectsSorting: [ProjectSort!]
    $addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addProjectChangesToUserContactsPaging: OffsetPaging
    $addProjectChangesToUserContactsFilter: ContactFilter
    $addProjectChangesToUserContactsSorting: [ContactSort!]
    $addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addProjectChangesToUserProjectChangesPaging: OffsetPaging
    $addProjectChangesToUserProjectChangesFilter: ContactFilter
    $addProjectChangesToUserProjectChangesSorting: [ContactSort!]
    $addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addProjectChangesToUserCreatedWorkersPaging: OffsetPaging
    $addProjectChangesToUserCreatedWorkersFilter: WorkerFilter
    $addProjectChangesToUserCreatedWorkersSorting: [WorkerSort!]
    $addProjectChangesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addProjectChangesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addProjectChangesToUserCreatedContractsPaging: OffsetPaging
    $addProjectChangesToUserCreatedContractsFilter: ContractFilter
    $addProjectChangesToUserCreatedContractsSorting: [ContractSort!]
    $addProjectChangesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addProjectChangesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addProjectChangesToUserCreatedMiddlemansPaging: OffsetPaging
    $addProjectChangesToUserCreatedMiddlemansFilter: MiddlemanFilter
    $addProjectChangesToUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $addProjectChangesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addProjectChangesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addProjectChangesToUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $addProjectChangesToUserCreatedCompaniesPaging
        filter: $addProjectChangesToUserCreatedCompaniesFilter
        sorting: $addProjectChangesToUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $addProjectChangesToUserCreatedProjectsPaging
        filter: $addProjectChangesToUserCreatedProjectsFilter
        sorting: $addProjectChangesToUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $addProjectChangesToUserContactsPaging
        filter: $addProjectChangesToUserContactsFilter
        sorting: $addProjectChangesToUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $addProjectChangesToUserProjectChangesPaging
        filter: $addProjectChangesToUserProjectChangesFilter
        sorting: $addProjectChangesToUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $addProjectChangesToUserCreatedWorkersPaging
        filter: $addProjectChangesToUserCreatedWorkersFilter
        sorting: $addProjectChangesToUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $addProjectChangesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $addProjectChangesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $addProjectChangesToUserCreatedContractsPaging
        filter: $addProjectChangesToUserCreatedContractsFilter
        sorting: $addProjectChangesToUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $addProjectChangesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $addProjectChangesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $addProjectChangesToUserCreatedMiddlemansPaging
        filter: $addProjectChangesToUserCreatedMiddlemansFilter
        sorting: $addProjectChangesToUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $addProjectChangesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $addProjectChangesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useAddProjectChangesToUserMutation__
 *
 * To run a mutation, you first call `useAddProjectChangesToUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectChangesToUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddProjectChangesToUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addProjectChangesToUserCreatedCompaniesPaging: // value for 'addProjectChangesToUserCreatedCompaniesPaging'
 *     addProjectChangesToUserCreatedCompaniesFilter: // value for 'addProjectChangesToUserCreatedCompaniesFilter'
 *     addProjectChangesToUserCreatedCompaniesSorting: // value for 'addProjectChangesToUserCreatedCompaniesSorting'
 *     addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addProjectChangesToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addProjectChangesToUserCreatedProjectsPaging: // value for 'addProjectChangesToUserCreatedProjectsPaging'
 *     addProjectChangesToUserCreatedProjectsFilter: // value for 'addProjectChangesToUserCreatedProjectsFilter'
 *     addProjectChangesToUserCreatedProjectsSorting: // value for 'addProjectChangesToUserCreatedProjectsSorting'
 *     addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addProjectChangesToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addProjectChangesToUserContactsPaging: // value for 'addProjectChangesToUserContactsPaging'
 *     addProjectChangesToUserContactsFilter: // value for 'addProjectChangesToUserContactsFilter'
 *     addProjectChangesToUserContactsSorting: // value for 'addProjectChangesToUserContactsSorting'
 *     addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addProjectChangesToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addProjectChangesToUserProjectChangesPaging: // value for 'addProjectChangesToUserProjectChangesPaging'
 *     addProjectChangesToUserProjectChangesFilter: // value for 'addProjectChangesToUserProjectChangesFilter'
 *     addProjectChangesToUserProjectChangesSorting: // value for 'addProjectChangesToUserProjectChangesSorting'
 *     addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addProjectChangesToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addProjectChangesToUserCreatedWorkersPaging: // value for 'addProjectChangesToUserCreatedWorkersPaging'
 *     addProjectChangesToUserCreatedWorkersFilter: // value for 'addProjectChangesToUserCreatedWorkersFilter'
 *     addProjectChangesToUserCreatedWorkersSorting: // value for 'addProjectChangesToUserCreatedWorkersSorting'
 *     addProjectChangesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addProjectChangesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addProjectChangesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addProjectChangesToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addProjectChangesToUserCreatedContractsPaging: // value for 'addProjectChangesToUserCreatedContractsPaging'
 *     addProjectChangesToUserCreatedContractsFilter: // value for 'addProjectChangesToUserCreatedContractsFilter'
 *     addProjectChangesToUserCreatedContractsSorting: // value for 'addProjectChangesToUserCreatedContractsSorting'
 *     addProjectChangesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addProjectChangesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addProjectChangesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addProjectChangesToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addProjectChangesToUserCreatedMiddlemansPaging: // value for 'addProjectChangesToUserCreatedMiddlemansPaging'
 *     addProjectChangesToUserCreatedMiddlemansFilter: // value for 'addProjectChangesToUserCreatedMiddlemansFilter'
 *     addProjectChangesToUserCreatedMiddlemansSorting: // value for 'addProjectChangesToUserCreatedMiddlemansSorting'
 *     addProjectChangesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addProjectChangesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addProjectChangesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addProjectChangesToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddProjectChangesToUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddProjectChangesToUserMutation,
        AddProjectChangesToUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddProjectChangesToUserMutation,
          AddProjectChangesToUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddProjectChangesToUserMutation,
    AddProjectChangesToUserMutationVariables
  >(AddProjectChangesToUserMutationDocument, options);
}
export type AddProjectChangesToUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddProjectChangesToUserMutation,
    AddProjectChangesToUserMutationVariables
  >;
export const SetProjectChangesOnUserMutationDocument = gql`
  mutation setProjectChangesOnUserMutation(
    $input: SetProjectChangesOnUserInput!
    $setProjectChangesOnUserCreatedCompaniesPaging: OffsetPaging
    $setProjectChangesOnUserCreatedCompaniesFilter: CompanyFilter
    $setProjectChangesOnUserCreatedCompaniesSorting: [CompanySort!]
    $setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setProjectChangesOnUserCreatedProjectsPaging: OffsetPaging
    $setProjectChangesOnUserCreatedProjectsFilter: ProjectFilter
    $setProjectChangesOnUserCreatedProjectsSorting: [ProjectSort!]
    $setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setProjectChangesOnUserContactsPaging: OffsetPaging
    $setProjectChangesOnUserContactsFilter: ContactFilter
    $setProjectChangesOnUserContactsSorting: [ContactSort!]
    $setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectChangesOnUserProjectChangesPaging: OffsetPaging
    $setProjectChangesOnUserProjectChangesFilter: ContactFilter
    $setProjectChangesOnUserProjectChangesSorting: [ContactSort!]
    $setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectChangesOnUserCreatedWorkersPaging: OffsetPaging
    $setProjectChangesOnUserCreatedWorkersFilter: WorkerFilter
    $setProjectChangesOnUserCreatedWorkersSorting: [WorkerSort!]
    $setProjectChangesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setProjectChangesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setProjectChangesOnUserCreatedContractsPaging: OffsetPaging
    $setProjectChangesOnUserCreatedContractsFilter: ContractFilter
    $setProjectChangesOnUserCreatedContractsSorting: [ContractSort!]
    $setProjectChangesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setProjectChangesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setProjectChangesOnUserCreatedMiddlemansPaging: OffsetPaging
    $setProjectChangesOnUserCreatedMiddlemansFilter: MiddlemanFilter
    $setProjectChangesOnUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $setProjectChangesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setProjectChangesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setProjectChangesOnUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $setProjectChangesOnUserCreatedCompaniesPaging
        filter: $setProjectChangesOnUserCreatedCompaniesFilter
        sorting: $setProjectChangesOnUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $setProjectChangesOnUserCreatedProjectsPaging
        filter: $setProjectChangesOnUserCreatedProjectsFilter
        sorting: $setProjectChangesOnUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $setProjectChangesOnUserContactsPaging
        filter: $setProjectChangesOnUserContactsFilter
        sorting: $setProjectChangesOnUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $setProjectChangesOnUserProjectChangesPaging
        filter: $setProjectChangesOnUserProjectChangesFilter
        sorting: $setProjectChangesOnUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $setProjectChangesOnUserCreatedWorkersPaging
        filter: $setProjectChangesOnUserCreatedWorkersFilter
        sorting: $setProjectChangesOnUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $setProjectChangesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $setProjectChangesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $setProjectChangesOnUserCreatedContractsPaging
        filter: $setProjectChangesOnUserCreatedContractsFilter
        sorting: $setProjectChangesOnUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $setProjectChangesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $setProjectChangesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $setProjectChangesOnUserCreatedMiddlemansPaging
        filter: $setProjectChangesOnUserCreatedMiddlemansFilter
        sorting: $setProjectChangesOnUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $setProjectChangesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $setProjectChangesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useSetProjectChangesOnUserMutation__
 *
 * To run a mutation, you first call `useSetProjectChangesOnUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectChangesOnUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetProjectChangesOnUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setProjectChangesOnUserCreatedCompaniesPaging: // value for 'setProjectChangesOnUserCreatedCompaniesPaging'
 *     setProjectChangesOnUserCreatedCompaniesFilter: // value for 'setProjectChangesOnUserCreatedCompaniesFilter'
 *     setProjectChangesOnUserCreatedCompaniesSorting: // value for 'setProjectChangesOnUserCreatedCompaniesSorting'
 *     setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setProjectChangesOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setProjectChangesOnUserCreatedProjectsPaging: // value for 'setProjectChangesOnUserCreatedProjectsPaging'
 *     setProjectChangesOnUserCreatedProjectsFilter: // value for 'setProjectChangesOnUserCreatedProjectsFilter'
 *     setProjectChangesOnUserCreatedProjectsSorting: // value for 'setProjectChangesOnUserCreatedProjectsSorting'
 *     setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setProjectChangesOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setProjectChangesOnUserContactsPaging: // value for 'setProjectChangesOnUserContactsPaging'
 *     setProjectChangesOnUserContactsFilter: // value for 'setProjectChangesOnUserContactsFilter'
 *     setProjectChangesOnUserContactsSorting: // value for 'setProjectChangesOnUserContactsSorting'
 *     setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectChangesOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectChangesOnUserProjectChangesPaging: // value for 'setProjectChangesOnUserProjectChangesPaging'
 *     setProjectChangesOnUserProjectChangesFilter: // value for 'setProjectChangesOnUserProjectChangesFilter'
 *     setProjectChangesOnUserProjectChangesSorting: // value for 'setProjectChangesOnUserProjectChangesSorting'
 *     setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectChangesOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectChangesOnUserCreatedWorkersPaging: // value for 'setProjectChangesOnUserCreatedWorkersPaging'
 *     setProjectChangesOnUserCreatedWorkersFilter: // value for 'setProjectChangesOnUserCreatedWorkersFilter'
 *     setProjectChangesOnUserCreatedWorkersSorting: // value for 'setProjectChangesOnUserCreatedWorkersSorting'
 *     setProjectChangesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setProjectChangesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setProjectChangesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setProjectChangesOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setProjectChangesOnUserCreatedContractsPaging: // value for 'setProjectChangesOnUserCreatedContractsPaging'
 *     setProjectChangesOnUserCreatedContractsFilter: // value for 'setProjectChangesOnUserCreatedContractsFilter'
 *     setProjectChangesOnUserCreatedContractsSorting: // value for 'setProjectChangesOnUserCreatedContractsSorting'
 *     setProjectChangesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setProjectChangesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setProjectChangesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setProjectChangesOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setProjectChangesOnUserCreatedMiddlemansPaging: // value for 'setProjectChangesOnUserCreatedMiddlemansPaging'
 *     setProjectChangesOnUserCreatedMiddlemansFilter: // value for 'setProjectChangesOnUserCreatedMiddlemansFilter'
 *     setProjectChangesOnUserCreatedMiddlemansSorting: // value for 'setProjectChangesOnUserCreatedMiddlemansSorting'
 *     setProjectChangesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setProjectChangesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setProjectChangesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setProjectChangesOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetProjectChangesOnUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetProjectChangesOnUserMutation,
        SetProjectChangesOnUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetProjectChangesOnUserMutation,
          SetProjectChangesOnUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetProjectChangesOnUserMutation,
    SetProjectChangesOnUserMutationVariables
  >(SetProjectChangesOnUserMutationDocument, options);
}
export type SetProjectChangesOnUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetProjectChangesOnUserMutation,
    SetProjectChangesOnUserMutationVariables
  >;
export const AddCreatedWorkersToUserMutationDocument = gql`
  mutation addCreatedWorkersToUserMutation(
    $input: AddCreatedWorkersToUserInput!
    $addCreatedWorkersToUserCreatedCompaniesPaging: OffsetPaging
    $addCreatedWorkersToUserCreatedCompaniesFilter: CompanyFilter
    $addCreatedWorkersToUserCreatedCompaniesSorting: [CompanySort!]
    $addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addCreatedWorkersToUserCreatedProjectsPaging: OffsetPaging
    $addCreatedWorkersToUserCreatedProjectsFilter: ProjectFilter
    $addCreatedWorkersToUserCreatedProjectsSorting: [ProjectSort!]
    $addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addCreatedWorkersToUserContactsPaging: OffsetPaging
    $addCreatedWorkersToUserContactsFilter: ContactFilter
    $addCreatedWorkersToUserContactsSorting: [ContactSort!]
    $addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addCreatedWorkersToUserProjectChangesPaging: OffsetPaging
    $addCreatedWorkersToUserProjectChangesFilter: ContactFilter
    $addCreatedWorkersToUserProjectChangesSorting: [ContactSort!]
    $addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addCreatedWorkersToUserCreatedWorkersPaging: OffsetPaging
    $addCreatedWorkersToUserCreatedWorkersFilter: WorkerFilter
    $addCreatedWorkersToUserCreatedWorkersSorting: [WorkerSort!]
    $addCreatedWorkersToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addCreatedWorkersToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addCreatedWorkersToUserCreatedContractsPaging: OffsetPaging
    $addCreatedWorkersToUserCreatedContractsFilter: ContractFilter
    $addCreatedWorkersToUserCreatedContractsSorting: [ContractSort!]
    $addCreatedWorkersToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addCreatedWorkersToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addCreatedWorkersToUserCreatedMiddlemansPaging: OffsetPaging
    $addCreatedWorkersToUserCreatedMiddlemansFilter: MiddlemanFilter
    $addCreatedWorkersToUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $addCreatedWorkersToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addCreatedWorkersToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addCreatedWorkersToUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $addCreatedWorkersToUserCreatedCompaniesPaging
        filter: $addCreatedWorkersToUserCreatedCompaniesFilter
        sorting: $addCreatedWorkersToUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $addCreatedWorkersToUserCreatedProjectsPaging
        filter: $addCreatedWorkersToUserCreatedProjectsFilter
        sorting: $addCreatedWorkersToUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $addCreatedWorkersToUserContactsPaging
        filter: $addCreatedWorkersToUserContactsFilter
        sorting: $addCreatedWorkersToUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $addCreatedWorkersToUserProjectChangesPaging
        filter: $addCreatedWorkersToUserProjectChangesFilter
        sorting: $addCreatedWorkersToUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $addCreatedWorkersToUserCreatedWorkersPaging
        filter: $addCreatedWorkersToUserCreatedWorkersFilter
        sorting: $addCreatedWorkersToUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $addCreatedWorkersToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $addCreatedWorkersToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $addCreatedWorkersToUserCreatedContractsPaging
        filter: $addCreatedWorkersToUserCreatedContractsFilter
        sorting: $addCreatedWorkersToUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $addCreatedWorkersToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $addCreatedWorkersToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $addCreatedWorkersToUserCreatedMiddlemansPaging
        filter: $addCreatedWorkersToUserCreatedMiddlemansFilter
        sorting: $addCreatedWorkersToUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $addCreatedWorkersToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $addCreatedWorkersToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useAddCreatedWorkersToUserMutation__
 *
 * To run a mutation, you first call `useAddCreatedWorkersToUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddCreatedWorkersToUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddCreatedWorkersToUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addCreatedWorkersToUserCreatedCompaniesPaging: // value for 'addCreatedWorkersToUserCreatedCompaniesPaging'
 *     addCreatedWorkersToUserCreatedCompaniesFilter: // value for 'addCreatedWorkersToUserCreatedCompaniesFilter'
 *     addCreatedWorkersToUserCreatedCompaniesSorting: // value for 'addCreatedWorkersToUserCreatedCompaniesSorting'
 *     addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addCreatedWorkersToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addCreatedWorkersToUserCreatedProjectsPaging: // value for 'addCreatedWorkersToUserCreatedProjectsPaging'
 *     addCreatedWorkersToUserCreatedProjectsFilter: // value for 'addCreatedWorkersToUserCreatedProjectsFilter'
 *     addCreatedWorkersToUserCreatedProjectsSorting: // value for 'addCreatedWorkersToUserCreatedProjectsSorting'
 *     addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addCreatedWorkersToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addCreatedWorkersToUserContactsPaging: // value for 'addCreatedWorkersToUserContactsPaging'
 *     addCreatedWorkersToUserContactsFilter: // value for 'addCreatedWorkersToUserContactsFilter'
 *     addCreatedWorkersToUserContactsSorting: // value for 'addCreatedWorkersToUserContactsSorting'
 *     addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addCreatedWorkersToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addCreatedWorkersToUserProjectChangesPaging: // value for 'addCreatedWorkersToUserProjectChangesPaging'
 *     addCreatedWorkersToUserProjectChangesFilter: // value for 'addCreatedWorkersToUserProjectChangesFilter'
 *     addCreatedWorkersToUserProjectChangesSorting: // value for 'addCreatedWorkersToUserProjectChangesSorting'
 *     addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addCreatedWorkersToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addCreatedWorkersToUserCreatedWorkersPaging: // value for 'addCreatedWorkersToUserCreatedWorkersPaging'
 *     addCreatedWorkersToUserCreatedWorkersFilter: // value for 'addCreatedWorkersToUserCreatedWorkersFilter'
 *     addCreatedWorkersToUserCreatedWorkersSorting: // value for 'addCreatedWorkersToUserCreatedWorkersSorting'
 *     addCreatedWorkersToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addCreatedWorkersToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addCreatedWorkersToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addCreatedWorkersToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addCreatedWorkersToUserCreatedContractsPaging: // value for 'addCreatedWorkersToUserCreatedContractsPaging'
 *     addCreatedWorkersToUserCreatedContractsFilter: // value for 'addCreatedWorkersToUserCreatedContractsFilter'
 *     addCreatedWorkersToUserCreatedContractsSorting: // value for 'addCreatedWorkersToUserCreatedContractsSorting'
 *     addCreatedWorkersToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addCreatedWorkersToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addCreatedWorkersToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addCreatedWorkersToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addCreatedWorkersToUserCreatedMiddlemansPaging: // value for 'addCreatedWorkersToUserCreatedMiddlemansPaging'
 *     addCreatedWorkersToUserCreatedMiddlemansFilter: // value for 'addCreatedWorkersToUserCreatedMiddlemansFilter'
 *     addCreatedWorkersToUserCreatedMiddlemansSorting: // value for 'addCreatedWorkersToUserCreatedMiddlemansSorting'
 *     addCreatedWorkersToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addCreatedWorkersToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addCreatedWorkersToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addCreatedWorkersToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddCreatedWorkersToUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddCreatedWorkersToUserMutation,
        AddCreatedWorkersToUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddCreatedWorkersToUserMutation,
          AddCreatedWorkersToUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddCreatedWorkersToUserMutation,
    AddCreatedWorkersToUserMutationVariables
  >(AddCreatedWorkersToUserMutationDocument, options);
}
export type AddCreatedWorkersToUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddCreatedWorkersToUserMutation,
    AddCreatedWorkersToUserMutationVariables
  >;
export const SetCreatedWorkersOnUserMutationDocument = gql`
  mutation setCreatedWorkersOnUserMutation(
    $input: SetCreatedWorkersOnUserInput!
    $setCreatedWorkersOnUserCreatedCompaniesPaging: OffsetPaging
    $setCreatedWorkersOnUserCreatedCompaniesFilter: CompanyFilter
    $setCreatedWorkersOnUserCreatedCompaniesSorting: [CompanySort!]
    $setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedWorkersOnUserCreatedProjectsPaging: OffsetPaging
    $setCreatedWorkersOnUserCreatedProjectsFilter: ProjectFilter
    $setCreatedWorkersOnUserCreatedProjectsSorting: [ProjectSort!]
    $setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedWorkersOnUserContactsPaging: OffsetPaging
    $setCreatedWorkersOnUserContactsFilter: ContactFilter
    $setCreatedWorkersOnUserContactsSorting: [ContactSort!]
    $setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedWorkersOnUserProjectChangesPaging: OffsetPaging
    $setCreatedWorkersOnUserProjectChangesFilter: ContactFilter
    $setCreatedWorkersOnUserProjectChangesSorting: [ContactSort!]
    $setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedWorkersOnUserCreatedWorkersPaging: OffsetPaging
    $setCreatedWorkersOnUserCreatedWorkersFilter: WorkerFilter
    $setCreatedWorkersOnUserCreatedWorkersSorting: [WorkerSort!]
    $setCreatedWorkersOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedWorkersOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedWorkersOnUserCreatedContractsPaging: OffsetPaging
    $setCreatedWorkersOnUserCreatedContractsFilter: ContractFilter
    $setCreatedWorkersOnUserCreatedContractsSorting: [ContractSort!]
    $setCreatedWorkersOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedWorkersOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedWorkersOnUserCreatedMiddlemansPaging: OffsetPaging
    $setCreatedWorkersOnUserCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedWorkersOnUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedWorkersOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedWorkersOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedWorkersOnUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $setCreatedWorkersOnUserCreatedCompaniesPaging
        filter: $setCreatedWorkersOnUserCreatedCompaniesFilter
        sorting: $setCreatedWorkersOnUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $setCreatedWorkersOnUserCreatedProjectsPaging
        filter: $setCreatedWorkersOnUserCreatedProjectsFilter
        sorting: $setCreatedWorkersOnUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $setCreatedWorkersOnUserContactsPaging
        filter: $setCreatedWorkersOnUserContactsFilter
        sorting: $setCreatedWorkersOnUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $setCreatedWorkersOnUserProjectChangesPaging
        filter: $setCreatedWorkersOnUserProjectChangesFilter
        sorting: $setCreatedWorkersOnUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $setCreatedWorkersOnUserCreatedWorkersPaging
        filter: $setCreatedWorkersOnUserCreatedWorkersFilter
        sorting: $setCreatedWorkersOnUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $setCreatedWorkersOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $setCreatedWorkersOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $setCreatedWorkersOnUserCreatedContractsPaging
        filter: $setCreatedWorkersOnUserCreatedContractsFilter
        sorting: $setCreatedWorkersOnUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $setCreatedWorkersOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $setCreatedWorkersOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $setCreatedWorkersOnUserCreatedMiddlemansPaging
        filter: $setCreatedWorkersOnUserCreatedMiddlemansFilter
        sorting: $setCreatedWorkersOnUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $setCreatedWorkersOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $setCreatedWorkersOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedWorkersOnUserMutation__
 *
 * To run a mutation, you first call `useSetCreatedWorkersOnUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedWorkersOnUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedWorkersOnUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedWorkersOnUserCreatedCompaniesPaging: // value for 'setCreatedWorkersOnUserCreatedCompaniesPaging'
 *     setCreatedWorkersOnUserCreatedCompaniesFilter: // value for 'setCreatedWorkersOnUserCreatedCompaniesFilter'
 *     setCreatedWorkersOnUserCreatedCompaniesSorting: // value for 'setCreatedWorkersOnUserCreatedCompaniesSorting'
 *     setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedWorkersOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedWorkersOnUserCreatedProjectsPaging: // value for 'setCreatedWorkersOnUserCreatedProjectsPaging'
 *     setCreatedWorkersOnUserCreatedProjectsFilter: // value for 'setCreatedWorkersOnUserCreatedProjectsFilter'
 *     setCreatedWorkersOnUserCreatedProjectsSorting: // value for 'setCreatedWorkersOnUserCreatedProjectsSorting'
 *     setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedWorkersOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedWorkersOnUserContactsPaging: // value for 'setCreatedWorkersOnUserContactsPaging'
 *     setCreatedWorkersOnUserContactsFilter: // value for 'setCreatedWorkersOnUserContactsFilter'
 *     setCreatedWorkersOnUserContactsSorting: // value for 'setCreatedWorkersOnUserContactsSorting'
 *     setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedWorkersOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedWorkersOnUserProjectChangesPaging: // value for 'setCreatedWorkersOnUserProjectChangesPaging'
 *     setCreatedWorkersOnUserProjectChangesFilter: // value for 'setCreatedWorkersOnUserProjectChangesFilter'
 *     setCreatedWorkersOnUserProjectChangesSorting: // value for 'setCreatedWorkersOnUserProjectChangesSorting'
 *     setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedWorkersOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedWorkersOnUserCreatedWorkersPaging: // value for 'setCreatedWorkersOnUserCreatedWorkersPaging'
 *     setCreatedWorkersOnUserCreatedWorkersFilter: // value for 'setCreatedWorkersOnUserCreatedWorkersFilter'
 *     setCreatedWorkersOnUserCreatedWorkersSorting: // value for 'setCreatedWorkersOnUserCreatedWorkersSorting'
 *     setCreatedWorkersOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedWorkersOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedWorkersOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedWorkersOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedWorkersOnUserCreatedContractsPaging: // value for 'setCreatedWorkersOnUserCreatedContractsPaging'
 *     setCreatedWorkersOnUserCreatedContractsFilter: // value for 'setCreatedWorkersOnUserCreatedContractsFilter'
 *     setCreatedWorkersOnUserCreatedContractsSorting: // value for 'setCreatedWorkersOnUserCreatedContractsSorting'
 *     setCreatedWorkersOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedWorkersOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedWorkersOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedWorkersOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedWorkersOnUserCreatedMiddlemansPaging: // value for 'setCreatedWorkersOnUserCreatedMiddlemansPaging'
 *     setCreatedWorkersOnUserCreatedMiddlemansFilter: // value for 'setCreatedWorkersOnUserCreatedMiddlemansFilter'
 *     setCreatedWorkersOnUserCreatedMiddlemansSorting: // value for 'setCreatedWorkersOnUserCreatedMiddlemansSorting'
 *     setCreatedWorkersOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedWorkersOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedWorkersOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedWorkersOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedWorkersOnUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedWorkersOnUserMutation,
        SetCreatedWorkersOnUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedWorkersOnUserMutation,
          SetCreatedWorkersOnUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedWorkersOnUserMutation,
    SetCreatedWorkersOnUserMutationVariables
  >(SetCreatedWorkersOnUserMutationDocument, options);
}
export type SetCreatedWorkersOnUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedWorkersOnUserMutation,
    SetCreatedWorkersOnUserMutationVariables
  >;
export const AddCreatedContractsToUserMutationDocument = gql`
  mutation addCreatedContractsToUserMutation(
    $input: AddCreatedContractsToUserInput!
    $addCreatedContractsToUserCreatedCompaniesPaging: OffsetPaging
    $addCreatedContractsToUserCreatedCompaniesFilter: CompanyFilter
    $addCreatedContractsToUserCreatedCompaniesSorting: [CompanySort!]
    $addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addCreatedContractsToUserCreatedProjectsPaging: OffsetPaging
    $addCreatedContractsToUserCreatedProjectsFilter: ProjectFilter
    $addCreatedContractsToUserCreatedProjectsSorting: [ProjectSort!]
    $addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addCreatedContractsToUserContactsPaging: OffsetPaging
    $addCreatedContractsToUserContactsFilter: ContactFilter
    $addCreatedContractsToUserContactsSorting: [ContactSort!]
    $addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addCreatedContractsToUserProjectChangesPaging: OffsetPaging
    $addCreatedContractsToUserProjectChangesFilter: ContactFilter
    $addCreatedContractsToUserProjectChangesSorting: [ContactSort!]
    $addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addCreatedContractsToUserCreatedWorkersPaging: OffsetPaging
    $addCreatedContractsToUserCreatedWorkersFilter: WorkerFilter
    $addCreatedContractsToUserCreatedWorkersSorting: [WorkerSort!]
    $addCreatedContractsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addCreatedContractsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addCreatedContractsToUserCreatedContractsPaging: OffsetPaging
    $addCreatedContractsToUserCreatedContractsFilter: ContractFilter
    $addCreatedContractsToUserCreatedContractsSorting: [ContractSort!]
    $addCreatedContractsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addCreatedContractsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addCreatedContractsToUserCreatedMiddlemansPaging: OffsetPaging
    $addCreatedContractsToUserCreatedMiddlemansFilter: MiddlemanFilter
    $addCreatedContractsToUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $addCreatedContractsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addCreatedContractsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addCreatedContractsToUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $addCreatedContractsToUserCreatedCompaniesPaging
        filter: $addCreatedContractsToUserCreatedCompaniesFilter
        sorting: $addCreatedContractsToUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $addCreatedContractsToUserCreatedProjectsPaging
        filter: $addCreatedContractsToUserCreatedProjectsFilter
        sorting: $addCreatedContractsToUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $addCreatedContractsToUserContactsPaging
        filter: $addCreatedContractsToUserContactsFilter
        sorting: $addCreatedContractsToUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $addCreatedContractsToUserProjectChangesPaging
        filter: $addCreatedContractsToUserProjectChangesFilter
        sorting: $addCreatedContractsToUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $addCreatedContractsToUserCreatedWorkersPaging
        filter: $addCreatedContractsToUserCreatedWorkersFilter
        sorting: $addCreatedContractsToUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $addCreatedContractsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $addCreatedContractsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $addCreatedContractsToUserCreatedContractsPaging
        filter: $addCreatedContractsToUserCreatedContractsFilter
        sorting: $addCreatedContractsToUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $addCreatedContractsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $addCreatedContractsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $addCreatedContractsToUserCreatedMiddlemansPaging
        filter: $addCreatedContractsToUserCreatedMiddlemansFilter
        sorting: $addCreatedContractsToUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $addCreatedContractsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $addCreatedContractsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useAddCreatedContractsToUserMutation__
 *
 * To run a mutation, you first call `useAddCreatedContractsToUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddCreatedContractsToUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddCreatedContractsToUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addCreatedContractsToUserCreatedCompaniesPaging: // value for 'addCreatedContractsToUserCreatedCompaniesPaging'
 *     addCreatedContractsToUserCreatedCompaniesFilter: // value for 'addCreatedContractsToUserCreatedCompaniesFilter'
 *     addCreatedContractsToUserCreatedCompaniesSorting: // value for 'addCreatedContractsToUserCreatedCompaniesSorting'
 *     addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addCreatedContractsToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addCreatedContractsToUserCreatedProjectsPaging: // value for 'addCreatedContractsToUserCreatedProjectsPaging'
 *     addCreatedContractsToUserCreatedProjectsFilter: // value for 'addCreatedContractsToUserCreatedProjectsFilter'
 *     addCreatedContractsToUserCreatedProjectsSorting: // value for 'addCreatedContractsToUserCreatedProjectsSorting'
 *     addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addCreatedContractsToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addCreatedContractsToUserContactsPaging: // value for 'addCreatedContractsToUserContactsPaging'
 *     addCreatedContractsToUserContactsFilter: // value for 'addCreatedContractsToUserContactsFilter'
 *     addCreatedContractsToUserContactsSorting: // value for 'addCreatedContractsToUserContactsSorting'
 *     addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addCreatedContractsToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addCreatedContractsToUserProjectChangesPaging: // value for 'addCreatedContractsToUserProjectChangesPaging'
 *     addCreatedContractsToUserProjectChangesFilter: // value for 'addCreatedContractsToUserProjectChangesFilter'
 *     addCreatedContractsToUserProjectChangesSorting: // value for 'addCreatedContractsToUserProjectChangesSorting'
 *     addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addCreatedContractsToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addCreatedContractsToUserCreatedWorkersPaging: // value for 'addCreatedContractsToUserCreatedWorkersPaging'
 *     addCreatedContractsToUserCreatedWorkersFilter: // value for 'addCreatedContractsToUserCreatedWorkersFilter'
 *     addCreatedContractsToUserCreatedWorkersSorting: // value for 'addCreatedContractsToUserCreatedWorkersSorting'
 *     addCreatedContractsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addCreatedContractsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addCreatedContractsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addCreatedContractsToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addCreatedContractsToUserCreatedContractsPaging: // value for 'addCreatedContractsToUserCreatedContractsPaging'
 *     addCreatedContractsToUserCreatedContractsFilter: // value for 'addCreatedContractsToUserCreatedContractsFilter'
 *     addCreatedContractsToUserCreatedContractsSorting: // value for 'addCreatedContractsToUserCreatedContractsSorting'
 *     addCreatedContractsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addCreatedContractsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addCreatedContractsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addCreatedContractsToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addCreatedContractsToUserCreatedMiddlemansPaging: // value for 'addCreatedContractsToUserCreatedMiddlemansPaging'
 *     addCreatedContractsToUserCreatedMiddlemansFilter: // value for 'addCreatedContractsToUserCreatedMiddlemansFilter'
 *     addCreatedContractsToUserCreatedMiddlemansSorting: // value for 'addCreatedContractsToUserCreatedMiddlemansSorting'
 *     addCreatedContractsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addCreatedContractsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addCreatedContractsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addCreatedContractsToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddCreatedContractsToUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddCreatedContractsToUserMutation,
        AddCreatedContractsToUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddCreatedContractsToUserMutation,
          AddCreatedContractsToUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddCreatedContractsToUserMutation,
    AddCreatedContractsToUserMutationVariables
  >(AddCreatedContractsToUserMutationDocument, options);
}
export type AddCreatedContractsToUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddCreatedContractsToUserMutation,
    AddCreatedContractsToUserMutationVariables
  >;
export const SetCreatedContractsOnUserMutationDocument = gql`
  mutation setCreatedContractsOnUserMutation(
    $input: SetCreatedContractsOnUserInput!
    $setCreatedContractsOnUserCreatedCompaniesPaging: OffsetPaging
    $setCreatedContractsOnUserCreatedCompaniesFilter: CompanyFilter
    $setCreatedContractsOnUserCreatedCompaniesSorting: [CompanySort!]
    $setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedContractsOnUserCreatedProjectsPaging: OffsetPaging
    $setCreatedContractsOnUserCreatedProjectsFilter: ProjectFilter
    $setCreatedContractsOnUserCreatedProjectsSorting: [ProjectSort!]
    $setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedContractsOnUserContactsPaging: OffsetPaging
    $setCreatedContractsOnUserContactsFilter: ContactFilter
    $setCreatedContractsOnUserContactsSorting: [ContactSort!]
    $setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedContractsOnUserProjectChangesPaging: OffsetPaging
    $setCreatedContractsOnUserProjectChangesFilter: ContactFilter
    $setCreatedContractsOnUserProjectChangesSorting: [ContactSort!]
    $setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedContractsOnUserCreatedWorkersPaging: OffsetPaging
    $setCreatedContractsOnUserCreatedWorkersFilter: WorkerFilter
    $setCreatedContractsOnUserCreatedWorkersSorting: [WorkerSort!]
    $setCreatedContractsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedContractsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedContractsOnUserCreatedContractsPaging: OffsetPaging
    $setCreatedContractsOnUserCreatedContractsFilter: ContractFilter
    $setCreatedContractsOnUserCreatedContractsSorting: [ContractSort!]
    $setCreatedContractsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedContractsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedContractsOnUserCreatedMiddlemansPaging: OffsetPaging
    $setCreatedContractsOnUserCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedContractsOnUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedContractsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedContractsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedContractsOnUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $setCreatedContractsOnUserCreatedCompaniesPaging
        filter: $setCreatedContractsOnUserCreatedCompaniesFilter
        sorting: $setCreatedContractsOnUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $setCreatedContractsOnUserCreatedProjectsPaging
        filter: $setCreatedContractsOnUserCreatedProjectsFilter
        sorting: $setCreatedContractsOnUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $setCreatedContractsOnUserContactsPaging
        filter: $setCreatedContractsOnUserContactsFilter
        sorting: $setCreatedContractsOnUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $setCreatedContractsOnUserProjectChangesPaging
        filter: $setCreatedContractsOnUserProjectChangesFilter
        sorting: $setCreatedContractsOnUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $setCreatedContractsOnUserCreatedWorkersPaging
        filter: $setCreatedContractsOnUserCreatedWorkersFilter
        sorting: $setCreatedContractsOnUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $setCreatedContractsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $setCreatedContractsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $setCreatedContractsOnUserCreatedContractsPaging
        filter: $setCreatedContractsOnUserCreatedContractsFilter
        sorting: $setCreatedContractsOnUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $setCreatedContractsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $setCreatedContractsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $setCreatedContractsOnUserCreatedMiddlemansPaging
        filter: $setCreatedContractsOnUserCreatedMiddlemansFilter
        sorting: $setCreatedContractsOnUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $setCreatedContractsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $setCreatedContractsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedContractsOnUserMutation__
 *
 * To run a mutation, you first call `useSetCreatedContractsOnUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedContractsOnUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedContractsOnUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedContractsOnUserCreatedCompaniesPaging: // value for 'setCreatedContractsOnUserCreatedCompaniesPaging'
 *     setCreatedContractsOnUserCreatedCompaniesFilter: // value for 'setCreatedContractsOnUserCreatedCompaniesFilter'
 *     setCreatedContractsOnUserCreatedCompaniesSorting: // value for 'setCreatedContractsOnUserCreatedCompaniesSorting'
 *     setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedContractsOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedContractsOnUserCreatedProjectsPaging: // value for 'setCreatedContractsOnUserCreatedProjectsPaging'
 *     setCreatedContractsOnUserCreatedProjectsFilter: // value for 'setCreatedContractsOnUserCreatedProjectsFilter'
 *     setCreatedContractsOnUserCreatedProjectsSorting: // value for 'setCreatedContractsOnUserCreatedProjectsSorting'
 *     setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedContractsOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedContractsOnUserContactsPaging: // value for 'setCreatedContractsOnUserContactsPaging'
 *     setCreatedContractsOnUserContactsFilter: // value for 'setCreatedContractsOnUserContactsFilter'
 *     setCreatedContractsOnUserContactsSorting: // value for 'setCreatedContractsOnUserContactsSorting'
 *     setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedContractsOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedContractsOnUserProjectChangesPaging: // value for 'setCreatedContractsOnUserProjectChangesPaging'
 *     setCreatedContractsOnUserProjectChangesFilter: // value for 'setCreatedContractsOnUserProjectChangesFilter'
 *     setCreatedContractsOnUserProjectChangesSorting: // value for 'setCreatedContractsOnUserProjectChangesSorting'
 *     setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedContractsOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedContractsOnUserCreatedWorkersPaging: // value for 'setCreatedContractsOnUserCreatedWorkersPaging'
 *     setCreatedContractsOnUserCreatedWorkersFilter: // value for 'setCreatedContractsOnUserCreatedWorkersFilter'
 *     setCreatedContractsOnUserCreatedWorkersSorting: // value for 'setCreatedContractsOnUserCreatedWorkersSorting'
 *     setCreatedContractsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedContractsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedContractsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedContractsOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedContractsOnUserCreatedContractsPaging: // value for 'setCreatedContractsOnUserCreatedContractsPaging'
 *     setCreatedContractsOnUserCreatedContractsFilter: // value for 'setCreatedContractsOnUserCreatedContractsFilter'
 *     setCreatedContractsOnUserCreatedContractsSorting: // value for 'setCreatedContractsOnUserCreatedContractsSorting'
 *     setCreatedContractsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedContractsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedContractsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedContractsOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedContractsOnUserCreatedMiddlemansPaging: // value for 'setCreatedContractsOnUserCreatedMiddlemansPaging'
 *     setCreatedContractsOnUserCreatedMiddlemansFilter: // value for 'setCreatedContractsOnUserCreatedMiddlemansFilter'
 *     setCreatedContractsOnUserCreatedMiddlemansSorting: // value for 'setCreatedContractsOnUserCreatedMiddlemansSorting'
 *     setCreatedContractsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedContractsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedContractsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedContractsOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedContractsOnUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedContractsOnUserMutation,
        SetCreatedContractsOnUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedContractsOnUserMutation,
          SetCreatedContractsOnUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedContractsOnUserMutation,
    SetCreatedContractsOnUserMutationVariables
  >(SetCreatedContractsOnUserMutationDocument, options);
}
export type SetCreatedContractsOnUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedContractsOnUserMutation,
    SetCreatedContractsOnUserMutationVariables
  >;
export const AddCreatedMiddlemansToUserMutationDocument = gql`
  mutation addCreatedMiddlemansToUserMutation(
    $input: AddCreatedMiddlemansToUserInput!
    $addCreatedMiddlemansToUserCreatedCompaniesPaging: OffsetPaging
    $addCreatedMiddlemansToUserCreatedCompaniesFilter: CompanyFilter
    $addCreatedMiddlemansToUserCreatedCompaniesSorting: [CompanySort!]
    $addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addCreatedMiddlemansToUserCreatedProjectsPaging: OffsetPaging
    $addCreatedMiddlemansToUserCreatedProjectsFilter: ProjectFilter
    $addCreatedMiddlemansToUserCreatedProjectsSorting: [ProjectSort!]
    $addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addCreatedMiddlemansToUserContactsPaging: OffsetPaging
    $addCreatedMiddlemansToUserContactsFilter: ContactFilter
    $addCreatedMiddlemansToUserContactsSorting: [ContactSort!]
    $addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addCreatedMiddlemansToUserProjectChangesPaging: OffsetPaging
    $addCreatedMiddlemansToUserProjectChangesFilter: ContactFilter
    $addCreatedMiddlemansToUserProjectChangesSorting: [ContactSort!]
    $addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addCreatedMiddlemansToUserCreatedWorkersPaging: OffsetPaging
    $addCreatedMiddlemansToUserCreatedWorkersFilter: WorkerFilter
    $addCreatedMiddlemansToUserCreatedWorkersSorting: [WorkerSort!]
    $addCreatedMiddlemansToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addCreatedMiddlemansToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addCreatedMiddlemansToUserCreatedContractsPaging: OffsetPaging
    $addCreatedMiddlemansToUserCreatedContractsFilter: ContractFilter
    $addCreatedMiddlemansToUserCreatedContractsSorting: [ContractSort!]
    $addCreatedMiddlemansToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addCreatedMiddlemansToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addCreatedMiddlemansToUserCreatedMiddlemansPaging: OffsetPaging
    $addCreatedMiddlemansToUserCreatedMiddlemansFilter: MiddlemanFilter
    $addCreatedMiddlemansToUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $addCreatedMiddlemansToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addCreatedMiddlemansToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addCreatedMiddlemansToUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $addCreatedMiddlemansToUserCreatedCompaniesPaging
        filter: $addCreatedMiddlemansToUserCreatedCompaniesFilter
        sorting: $addCreatedMiddlemansToUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $addCreatedMiddlemansToUserCreatedProjectsPaging
        filter: $addCreatedMiddlemansToUserCreatedProjectsFilter
        sorting: $addCreatedMiddlemansToUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $addCreatedMiddlemansToUserContactsPaging
        filter: $addCreatedMiddlemansToUserContactsFilter
        sorting: $addCreatedMiddlemansToUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $addCreatedMiddlemansToUserProjectChangesPaging
        filter: $addCreatedMiddlemansToUserProjectChangesFilter
        sorting: $addCreatedMiddlemansToUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $addCreatedMiddlemansToUserCreatedWorkersPaging
        filter: $addCreatedMiddlemansToUserCreatedWorkersFilter
        sorting: $addCreatedMiddlemansToUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $addCreatedMiddlemansToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $addCreatedMiddlemansToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $addCreatedMiddlemansToUserCreatedContractsPaging
        filter: $addCreatedMiddlemansToUserCreatedContractsFilter
        sorting: $addCreatedMiddlemansToUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $addCreatedMiddlemansToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $addCreatedMiddlemansToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $addCreatedMiddlemansToUserCreatedMiddlemansPaging
        filter: $addCreatedMiddlemansToUserCreatedMiddlemansFilter
        sorting: $addCreatedMiddlemansToUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $addCreatedMiddlemansToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $addCreatedMiddlemansToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useAddCreatedMiddlemansToUserMutation__
 *
 * To run a mutation, you first call `useAddCreatedMiddlemansToUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddCreatedMiddlemansToUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddCreatedMiddlemansToUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addCreatedMiddlemansToUserCreatedCompaniesPaging: // value for 'addCreatedMiddlemansToUserCreatedCompaniesPaging'
 *     addCreatedMiddlemansToUserCreatedCompaniesFilter: // value for 'addCreatedMiddlemansToUserCreatedCompaniesFilter'
 *     addCreatedMiddlemansToUserCreatedCompaniesSorting: // value for 'addCreatedMiddlemansToUserCreatedCompaniesSorting'
 *     addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addCreatedMiddlemansToUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addCreatedMiddlemansToUserCreatedProjectsPaging: // value for 'addCreatedMiddlemansToUserCreatedProjectsPaging'
 *     addCreatedMiddlemansToUserCreatedProjectsFilter: // value for 'addCreatedMiddlemansToUserCreatedProjectsFilter'
 *     addCreatedMiddlemansToUserCreatedProjectsSorting: // value for 'addCreatedMiddlemansToUserCreatedProjectsSorting'
 *     addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addCreatedMiddlemansToUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addCreatedMiddlemansToUserContactsPaging: // value for 'addCreatedMiddlemansToUserContactsPaging'
 *     addCreatedMiddlemansToUserContactsFilter: // value for 'addCreatedMiddlemansToUserContactsFilter'
 *     addCreatedMiddlemansToUserContactsSorting: // value for 'addCreatedMiddlemansToUserContactsSorting'
 *     addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addCreatedMiddlemansToUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addCreatedMiddlemansToUserProjectChangesPaging: // value for 'addCreatedMiddlemansToUserProjectChangesPaging'
 *     addCreatedMiddlemansToUserProjectChangesFilter: // value for 'addCreatedMiddlemansToUserProjectChangesFilter'
 *     addCreatedMiddlemansToUserProjectChangesSorting: // value for 'addCreatedMiddlemansToUserProjectChangesSorting'
 *     addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addCreatedMiddlemansToUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addCreatedMiddlemansToUserCreatedWorkersPaging: // value for 'addCreatedMiddlemansToUserCreatedWorkersPaging'
 *     addCreatedMiddlemansToUserCreatedWorkersFilter: // value for 'addCreatedMiddlemansToUserCreatedWorkersFilter'
 *     addCreatedMiddlemansToUserCreatedWorkersSorting: // value for 'addCreatedMiddlemansToUserCreatedWorkersSorting'
 *     addCreatedMiddlemansToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addCreatedMiddlemansToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addCreatedMiddlemansToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addCreatedMiddlemansToUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addCreatedMiddlemansToUserCreatedContractsPaging: // value for 'addCreatedMiddlemansToUserCreatedContractsPaging'
 *     addCreatedMiddlemansToUserCreatedContractsFilter: // value for 'addCreatedMiddlemansToUserCreatedContractsFilter'
 *     addCreatedMiddlemansToUserCreatedContractsSorting: // value for 'addCreatedMiddlemansToUserCreatedContractsSorting'
 *     addCreatedMiddlemansToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addCreatedMiddlemansToUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addCreatedMiddlemansToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addCreatedMiddlemansToUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addCreatedMiddlemansToUserCreatedMiddlemansPaging: // value for 'addCreatedMiddlemansToUserCreatedMiddlemansPaging'
 *     addCreatedMiddlemansToUserCreatedMiddlemansFilter: // value for 'addCreatedMiddlemansToUserCreatedMiddlemansFilter'
 *     addCreatedMiddlemansToUserCreatedMiddlemansSorting: // value for 'addCreatedMiddlemansToUserCreatedMiddlemansSorting'
 *     addCreatedMiddlemansToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addCreatedMiddlemansToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addCreatedMiddlemansToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addCreatedMiddlemansToUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddCreatedMiddlemansToUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddCreatedMiddlemansToUserMutation,
        AddCreatedMiddlemansToUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddCreatedMiddlemansToUserMutation,
          AddCreatedMiddlemansToUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddCreatedMiddlemansToUserMutation,
    AddCreatedMiddlemansToUserMutationVariables
  >(AddCreatedMiddlemansToUserMutationDocument, options);
}
export type AddCreatedMiddlemansToUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddCreatedMiddlemansToUserMutation,
    AddCreatedMiddlemansToUserMutationVariables
  >;
export const SetCreatedMiddlemansOnUserMutationDocument = gql`
  mutation setCreatedMiddlemansOnUserMutation(
    $input: SetCreatedMiddlemansOnUserInput!
    $setCreatedMiddlemansOnUserCreatedCompaniesPaging: OffsetPaging
    $setCreatedMiddlemansOnUserCreatedCompaniesFilter: CompanyFilter
    $setCreatedMiddlemansOnUserCreatedCompaniesSorting: [CompanySort!]
    $setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedMiddlemansOnUserCreatedProjectsPaging: OffsetPaging
    $setCreatedMiddlemansOnUserCreatedProjectsFilter: ProjectFilter
    $setCreatedMiddlemansOnUserCreatedProjectsSorting: [ProjectSort!]
    $setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedMiddlemansOnUserContactsPaging: OffsetPaging
    $setCreatedMiddlemansOnUserContactsFilter: ContactFilter
    $setCreatedMiddlemansOnUserContactsSorting: [ContactSort!]
    $setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedMiddlemansOnUserProjectChangesPaging: OffsetPaging
    $setCreatedMiddlemansOnUserProjectChangesFilter: ContactFilter
    $setCreatedMiddlemansOnUserProjectChangesSorting: [ContactSort!]
    $setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedMiddlemansOnUserCreatedWorkersPaging: OffsetPaging
    $setCreatedMiddlemansOnUserCreatedWorkersFilter: WorkerFilter
    $setCreatedMiddlemansOnUserCreatedWorkersSorting: [WorkerSort!]
    $setCreatedMiddlemansOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedMiddlemansOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedMiddlemansOnUserCreatedContractsPaging: OffsetPaging
    $setCreatedMiddlemansOnUserCreatedContractsFilter: ContractFilter
    $setCreatedMiddlemansOnUserCreatedContractsSorting: [ContractSort!]
    $setCreatedMiddlemansOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedMiddlemansOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedMiddlemansOnUserCreatedMiddlemansPaging: OffsetPaging
    $setCreatedMiddlemansOnUserCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedMiddlemansOnUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedMiddlemansOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedMiddlemansOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedMiddlemansOnUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $setCreatedMiddlemansOnUserCreatedCompaniesPaging
        filter: $setCreatedMiddlemansOnUserCreatedCompaniesFilter
        sorting: $setCreatedMiddlemansOnUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $setCreatedMiddlemansOnUserCreatedProjectsPaging
        filter: $setCreatedMiddlemansOnUserCreatedProjectsFilter
        sorting: $setCreatedMiddlemansOnUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $setCreatedMiddlemansOnUserContactsPaging
        filter: $setCreatedMiddlemansOnUserContactsFilter
        sorting: $setCreatedMiddlemansOnUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $setCreatedMiddlemansOnUserProjectChangesPaging
        filter: $setCreatedMiddlemansOnUserProjectChangesFilter
        sorting: $setCreatedMiddlemansOnUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $setCreatedMiddlemansOnUserCreatedWorkersPaging
        filter: $setCreatedMiddlemansOnUserCreatedWorkersFilter
        sorting: $setCreatedMiddlemansOnUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $setCreatedMiddlemansOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $setCreatedMiddlemansOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $setCreatedMiddlemansOnUserCreatedContractsPaging
        filter: $setCreatedMiddlemansOnUserCreatedContractsFilter
        sorting: $setCreatedMiddlemansOnUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $setCreatedMiddlemansOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $setCreatedMiddlemansOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $setCreatedMiddlemansOnUserCreatedMiddlemansPaging
        filter: $setCreatedMiddlemansOnUserCreatedMiddlemansFilter
        sorting: $setCreatedMiddlemansOnUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $setCreatedMiddlemansOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $setCreatedMiddlemansOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedMiddlemansOnUserMutation__
 *
 * To run a mutation, you first call `useSetCreatedMiddlemansOnUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedMiddlemansOnUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedMiddlemansOnUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedMiddlemansOnUserCreatedCompaniesPaging: // value for 'setCreatedMiddlemansOnUserCreatedCompaniesPaging'
 *     setCreatedMiddlemansOnUserCreatedCompaniesFilter: // value for 'setCreatedMiddlemansOnUserCreatedCompaniesFilter'
 *     setCreatedMiddlemansOnUserCreatedCompaniesSorting: // value for 'setCreatedMiddlemansOnUserCreatedCompaniesSorting'
 *     setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedMiddlemansOnUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedMiddlemansOnUserCreatedProjectsPaging: // value for 'setCreatedMiddlemansOnUserCreatedProjectsPaging'
 *     setCreatedMiddlemansOnUserCreatedProjectsFilter: // value for 'setCreatedMiddlemansOnUserCreatedProjectsFilter'
 *     setCreatedMiddlemansOnUserCreatedProjectsSorting: // value for 'setCreatedMiddlemansOnUserCreatedProjectsSorting'
 *     setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedMiddlemansOnUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedMiddlemansOnUserContactsPaging: // value for 'setCreatedMiddlemansOnUserContactsPaging'
 *     setCreatedMiddlemansOnUserContactsFilter: // value for 'setCreatedMiddlemansOnUserContactsFilter'
 *     setCreatedMiddlemansOnUserContactsSorting: // value for 'setCreatedMiddlemansOnUserContactsSorting'
 *     setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedMiddlemansOnUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedMiddlemansOnUserProjectChangesPaging: // value for 'setCreatedMiddlemansOnUserProjectChangesPaging'
 *     setCreatedMiddlemansOnUserProjectChangesFilter: // value for 'setCreatedMiddlemansOnUserProjectChangesFilter'
 *     setCreatedMiddlemansOnUserProjectChangesSorting: // value for 'setCreatedMiddlemansOnUserProjectChangesSorting'
 *     setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedMiddlemansOnUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedMiddlemansOnUserCreatedWorkersPaging: // value for 'setCreatedMiddlemansOnUserCreatedWorkersPaging'
 *     setCreatedMiddlemansOnUserCreatedWorkersFilter: // value for 'setCreatedMiddlemansOnUserCreatedWorkersFilter'
 *     setCreatedMiddlemansOnUserCreatedWorkersSorting: // value for 'setCreatedMiddlemansOnUserCreatedWorkersSorting'
 *     setCreatedMiddlemansOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedMiddlemansOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedMiddlemansOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedMiddlemansOnUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedMiddlemansOnUserCreatedContractsPaging: // value for 'setCreatedMiddlemansOnUserCreatedContractsPaging'
 *     setCreatedMiddlemansOnUserCreatedContractsFilter: // value for 'setCreatedMiddlemansOnUserCreatedContractsFilter'
 *     setCreatedMiddlemansOnUserCreatedContractsSorting: // value for 'setCreatedMiddlemansOnUserCreatedContractsSorting'
 *     setCreatedMiddlemansOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedMiddlemansOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedMiddlemansOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedMiddlemansOnUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedMiddlemansOnUserCreatedMiddlemansPaging: // value for 'setCreatedMiddlemansOnUserCreatedMiddlemansPaging'
 *     setCreatedMiddlemansOnUserCreatedMiddlemansFilter: // value for 'setCreatedMiddlemansOnUserCreatedMiddlemansFilter'
 *     setCreatedMiddlemansOnUserCreatedMiddlemansSorting: // value for 'setCreatedMiddlemansOnUserCreatedMiddlemansSorting'
 *     setCreatedMiddlemansOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedMiddlemansOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedMiddlemansOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedMiddlemansOnUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedMiddlemansOnUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedMiddlemansOnUserMutation,
        SetCreatedMiddlemansOnUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedMiddlemansOnUserMutation,
          SetCreatedMiddlemansOnUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedMiddlemansOnUserMutation,
    SetCreatedMiddlemansOnUserMutationVariables
  >(SetCreatedMiddlemansOnUserMutationDocument, options);
}
export type SetCreatedMiddlemansOnUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedMiddlemansOnUserMutation,
    SetCreatedMiddlemansOnUserMutationVariables
  >;
export const RemoveCreatedCompaniesFromUserMutationDocument = gql`
  mutation removeCreatedCompaniesFromUserMutation(
    $input: RemoveCreatedCompaniesFromUserInput!
    $removeCreatedCompaniesFromUserCreatedCompaniesPaging: OffsetPaging
    $removeCreatedCompaniesFromUserCreatedCompaniesFilter: CompanyFilter
    $removeCreatedCompaniesFromUserCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedCompaniesFromUserCreatedProjectsPaging: OffsetPaging
    $removeCreatedCompaniesFromUserCreatedProjectsFilter: ProjectFilter
    $removeCreatedCompaniesFromUserCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedCompaniesFromUserContactsPaging: OffsetPaging
    $removeCreatedCompaniesFromUserContactsFilter: ContactFilter
    $removeCreatedCompaniesFromUserContactsSorting: [ContactSort!]
    $removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedCompaniesFromUserProjectChangesPaging: OffsetPaging
    $removeCreatedCompaniesFromUserProjectChangesFilter: ContactFilter
    $removeCreatedCompaniesFromUserProjectChangesSorting: [ContactSort!]
    $removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedCompaniesFromUserCreatedWorkersPaging: OffsetPaging
    $removeCreatedCompaniesFromUserCreatedWorkersFilter: WorkerFilter
    $removeCreatedCompaniesFromUserCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedCompaniesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedCompaniesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedCompaniesFromUserCreatedContractsPaging: OffsetPaging
    $removeCreatedCompaniesFromUserCreatedContractsFilter: ContractFilter
    $removeCreatedCompaniesFromUserCreatedContractsSorting: [ContractSort!]
    $removeCreatedCompaniesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedCompaniesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedCompaniesFromUserCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedCompaniesFromUserCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedCompaniesFromUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedCompaniesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedCompaniesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedCompaniesFromUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $removeCreatedCompaniesFromUserCreatedCompaniesPaging
        filter: $removeCreatedCompaniesFromUserCreatedCompaniesFilter
        sorting: $removeCreatedCompaniesFromUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $removeCreatedCompaniesFromUserCreatedProjectsPaging
        filter: $removeCreatedCompaniesFromUserCreatedProjectsFilter
        sorting: $removeCreatedCompaniesFromUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $removeCreatedCompaniesFromUserContactsPaging
        filter: $removeCreatedCompaniesFromUserContactsFilter
        sorting: $removeCreatedCompaniesFromUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $removeCreatedCompaniesFromUserProjectChangesPaging
        filter: $removeCreatedCompaniesFromUserProjectChangesFilter
        sorting: $removeCreatedCompaniesFromUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $removeCreatedCompaniesFromUserCreatedWorkersPaging
        filter: $removeCreatedCompaniesFromUserCreatedWorkersFilter
        sorting: $removeCreatedCompaniesFromUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $removeCreatedCompaniesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $removeCreatedCompaniesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $removeCreatedCompaniesFromUserCreatedContractsPaging
        filter: $removeCreatedCompaniesFromUserCreatedContractsFilter
        sorting: $removeCreatedCompaniesFromUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $removeCreatedCompaniesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $removeCreatedCompaniesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $removeCreatedCompaniesFromUserCreatedMiddlemansPaging
        filter: $removeCreatedCompaniesFromUserCreatedMiddlemansFilter
        sorting: $removeCreatedCompaniesFromUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $removeCreatedCompaniesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $removeCreatedCompaniesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedCompaniesFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedCompaniesFromUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedCompaniesFromUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedCompaniesFromUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedCompaniesFromUserCreatedCompaniesPaging: // value for 'removeCreatedCompaniesFromUserCreatedCompaniesPaging'
 *     removeCreatedCompaniesFromUserCreatedCompaniesFilter: // value for 'removeCreatedCompaniesFromUserCreatedCompaniesFilter'
 *     removeCreatedCompaniesFromUserCreatedCompaniesSorting: // value for 'removeCreatedCompaniesFromUserCreatedCompaniesSorting'
 *     removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedCompaniesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedCompaniesFromUserCreatedProjectsPaging: // value for 'removeCreatedCompaniesFromUserCreatedProjectsPaging'
 *     removeCreatedCompaniesFromUserCreatedProjectsFilter: // value for 'removeCreatedCompaniesFromUserCreatedProjectsFilter'
 *     removeCreatedCompaniesFromUserCreatedProjectsSorting: // value for 'removeCreatedCompaniesFromUserCreatedProjectsSorting'
 *     removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedCompaniesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedCompaniesFromUserContactsPaging: // value for 'removeCreatedCompaniesFromUserContactsPaging'
 *     removeCreatedCompaniesFromUserContactsFilter: // value for 'removeCreatedCompaniesFromUserContactsFilter'
 *     removeCreatedCompaniesFromUserContactsSorting: // value for 'removeCreatedCompaniesFromUserContactsSorting'
 *     removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedCompaniesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedCompaniesFromUserProjectChangesPaging: // value for 'removeCreatedCompaniesFromUserProjectChangesPaging'
 *     removeCreatedCompaniesFromUserProjectChangesFilter: // value for 'removeCreatedCompaniesFromUserProjectChangesFilter'
 *     removeCreatedCompaniesFromUserProjectChangesSorting: // value for 'removeCreatedCompaniesFromUserProjectChangesSorting'
 *     removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedCompaniesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedCompaniesFromUserCreatedWorkersPaging: // value for 'removeCreatedCompaniesFromUserCreatedWorkersPaging'
 *     removeCreatedCompaniesFromUserCreatedWorkersFilter: // value for 'removeCreatedCompaniesFromUserCreatedWorkersFilter'
 *     removeCreatedCompaniesFromUserCreatedWorkersSorting: // value for 'removeCreatedCompaniesFromUserCreatedWorkersSorting'
 *     removeCreatedCompaniesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedCompaniesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedCompaniesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedCompaniesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedCompaniesFromUserCreatedContractsPaging: // value for 'removeCreatedCompaniesFromUserCreatedContractsPaging'
 *     removeCreatedCompaniesFromUserCreatedContractsFilter: // value for 'removeCreatedCompaniesFromUserCreatedContractsFilter'
 *     removeCreatedCompaniesFromUserCreatedContractsSorting: // value for 'removeCreatedCompaniesFromUserCreatedContractsSorting'
 *     removeCreatedCompaniesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedCompaniesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedCompaniesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedCompaniesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedCompaniesFromUserCreatedMiddlemansPaging: // value for 'removeCreatedCompaniesFromUserCreatedMiddlemansPaging'
 *     removeCreatedCompaniesFromUserCreatedMiddlemansFilter: // value for 'removeCreatedCompaniesFromUserCreatedMiddlemansFilter'
 *     removeCreatedCompaniesFromUserCreatedMiddlemansSorting: // value for 'removeCreatedCompaniesFromUserCreatedMiddlemansSorting'
 *     removeCreatedCompaniesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedCompaniesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedCompaniesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedCompaniesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedCompaniesFromUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedCompaniesFromUserMutation,
        RemoveCreatedCompaniesFromUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedCompaniesFromUserMutation,
          RemoveCreatedCompaniesFromUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedCompaniesFromUserMutation,
    RemoveCreatedCompaniesFromUserMutationVariables
  >(RemoveCreatedCompaniesFromUserMutationDocument, options);
}
export type RemoveCreatedCompaniesFromUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedCompaniesFromUserMutation,
    RemoveCreatedCompaniesFromUserMutationVariables
  >;
export const RemoveCreatedProjectsFromUserMutationDocument = gql`
  mutation removeCreatedProjectsFromUserMutation(
    $input: RemoveCreatedProjectsFromUserInput!
    $removeCreatedProjectsFromUserCreatedCompaniesPaging: OffsetPaging
    $removeCreatedProjectsFromUserCreatedCompaniesFilter: CompanyFilter
    $removeCreatedProjectsFromUserCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedProjectsFromUserCreatedProjectsPaging: OffsetPaging
    $removeCreatedProjectsFromUserCreatedProjectsFilter: ProjectFilter
    $removeCreatedProjectsFromUserCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedProjectsFromUserContactsPaging: OffsetPaging
    $removeCreatedProjectsFromUserContactsFilter: ContactFilter
    $removeCreatedProjectsFromUserContactsSorting: [ContactSort!]
    $removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedProjectsFromUserProjectChangesPaging: OffsetPaging
    $removeCreatedProjectsFromUserProjectChangesFilter: ContactFilter
    $removeCreatedProjectsFromUserProjectChangesSorting: [ContactSort!]
    $removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedProjectsFromUserCreatedWorkersPaging: OffsetPaging
    $removeCreatedProjectsFromUserCreatedWorkersFilter: WorkerFilter
    $removeCreatedProjectsFromUserCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedProjectsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedProjectsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedProjectsFromUserCreatedContractsPaging: OffsetPaging
    $removeCreatedProjectsFromUserCreatedContractsFilter: ContractFilter
    $removeCreatedProjectsFromUserCreatedContractsSorting: [ContractSort!]
    $removeCreatedProjectsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedProjectsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedProjectsFromUserCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedProjectsFromUserCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedProjectsFromUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedProjectsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedProjectsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedProjectsFromUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $removeCreatedProjectsFromUserCreatedCompaniesPaging
        filter: $removeCreatedProjectsFromUserCreatedCompaniesFilter
        sorting: $removeCreatedProjectsFromUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $removeCreatedProjectsFromUserCreatedProjectsPaging
        filter: $removeCreatedProjectsFromUserCreatedProjectsFilter
        sorting: $removeCreatedProjectsFromUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $removeCreatedProjectsFromUserContactsPaging
        filter: $removeCreatedProjectsFromUserContactsFilter
        sorting: $removeCreatedProjectsFromUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $removeCreatedProjectsFromUserProjectChangesPaging
        filter: $removeCreatedProjectsFromUserProjectChangesFilter
        sorting: $removeCreatedProjectsFromUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $removeCreatedProjectsFromUserCreatedWorkersPaging
        filter: $removeCreatedProjectsFromUserCreatedWorkersFilter
        sorting: $removeCreatedProjectsFromUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $removeCreatedProjectsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $removeCreatedProjectsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $removeCreatedProjectsFromUserCreatedContractsPaging
        filter: $removeCreatedProjectsFromUserCreatedContractsFilter
        sorting: $removeCreatedProjectsFromUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $removeCreatedProjectsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $removeCreatedProjectsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $removeCreatedProjectsFromUserCreatedMiddlemansPaging
        filter: $removeCreatedProjectsFromUserCreatedMiddlemansFilter
        sorting: $removeCreatedProjectsFromUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $removeCreatedProjectsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $removeCreatedProjectsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedProjectsFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedProjectsFromUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedProjectsFromUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedProjectsFromUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedProjectsFromUserCreatedCompaniesPaging: // value for 'removeCreatedProjectsFromUserCreatedCompaniesPaging'
 *     removeCreatedProjectsFromUserCreatedCompaniesFilter: // value for 'removeCreatedProjectsFromUserCreatedCompaniesFilter'
 *     removeCreatedProjectsFromUserCreatedCompaniesSorting: // value for 'removeCreatedProjectsFromUserCreatedCompaniesSorting'
 *     removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedProjectsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedProjectsFromUserCreatedProjectsPaging: // value for 'removeCreatedProjectsFromUserCreatedProjectsPaging'
 *     removeCreatedProjectsFromUserCreatedProjectsFilter: // value for 'removeCreatedProjectsFromUserCreatedProjectsFilter'
 *     removeCreatedProjectsFromUserCreatedProjectsSorting: // value for 'removeCreatedProjectsFromUserCreatedProjectsSorting'
 *     removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedProjectsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedProjectsFromUserContactsPaging: // value for 'removeCreatedProjectsFromUserContactsPaging'
 *     removeCreatedProjectsFromUserContactsFilter: // value for 'removeCreatedProjectsFromUserContactsFilter'
 *     removeCreatedProjectsFromUserContactsSorting: // value for 'removeCreatedProjectsFromUserContactsSorting'
 *     removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedProjectsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedProjectsFromUserProjectChangesPaging: // value for 'removeCreatedProjectsFromUserProjectChangesPaging'
 *     removeCreatedProjectsFromUserProjectChangesFilter: // value for 'removeCreatedProjectsFromUserProjectChangesFilter'
 *     removeCreatedProjectsFromUserProjectChangesSorting: // value for 'removeCreatedProjectsFromUserProjectChangesSorting'
 *     removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedProjectsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedProjectsFromUserCreatedWorkersPaging: // value for 'removeCreatedProjectsFromUserCreatedWorkersPaging'
 *     removeCreatedProjectsFromUserCreatedWorkersFilter: // value for 'removeCreatedProjectsFromUserCreatedWorkersFilter'
 *     removeCreatedProjectsFromUserCreatedWorkersSorting: // value for 'removeCreatedProjectsFromUserCreatedWorkersSorting'
 *     removeCreatedProjectsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedProjectsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedProjectsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedProjectsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedProjectsFromUserCreatedContractsPaging: // value for 'removeCreatedProjectsFromUserCreatedContractsPaging'
 *     removeCreatedProjectsFromUserCreatedContractsFilter: // value for 'removeCreatedProjectsFromUserCreatedContractsFilter'
 *     removeCreatedProjectsFromUserCreatedContractsSorting: // value for 'removeCreatedProjectsFromUserCreatedContractsSorting'
 *     removeCreatedProjectsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedProjectsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedProjectsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedProjectsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedProjectsFromUserCreatedMiddlemansPaging: // value for 'removeCreatedProjectsFromUserCreatedMiddlemansPaging'
 *     removeCreatedProjectsFromUserCreatedMiddlemansFilter: // value for 'removeCreatedProjectsFromUserCreatedMiddlemansFilter'
 *     removeCreatedProjectsFromUserCreatedMiddlemansSorting: // value for 'removeCreatedProjectsFromUserCreatedMiddlemansSorting'
 *     removeCreatedProjectsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedProjectsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedProjectsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedProjectsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedProjectsFromUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedProjectsFromUserMutation,
        RemoveCreatedProjectsFromUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedProjectsFromUserMutation,
          RemoveCreatedProjectsFromUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedProjectsFromUserMutation,
    RemoveCreatedProjectsFromUserMutationVariables
  >(RemoveCreatedProjectsFromUserMutationDocument, options);
}
export type RemoveCreatedProjectsFromUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedProjectsFromUserMutation,
    RemoveCreatedProjectsFromUserMutationVariables
  >;
export const RemoveContactsFromUserMutationDocument = gql`
  mutation removeContactsFromUserMutation(
    $input: RemoveContactsFromUserInput!
    $removeContactsFromUserCreatedCompaniesPaging: OffsetPaging
    $removeContactsFromUserCreatedCompaniesFilter: CompanyFilter
    $removeContactsFromUserCreatedCompaniesSorting: [CompanySort!]
    $removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactsFromUserCreatedProjectsPaging: OffsetPaging
    $removeContactsFromUserCreatedProjectsFilter: ProjectFilter
    $removeContactsFromUserCreatedProjectsSorting: [ProjectSort!]
    $removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactsFromUserContactsPaging: OffsetPaging
    $removeContactsFromUserContactsFilter: ContactFilter
    $removeContactsFromUserContactsSorting: [ContactSort!]
    $removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactsFromUserProjectChangesPaging: OffsetPaging
    $removeContactsFromUserProjectChangesFilter: ContactFilter
    $removeContactsFromUserProjectChangesSorting: [ContactSort!]
    $removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactsFromUserCreatedWorkersPaging: OffsetPaging
    $removeContactsFromUserCreatedWorkersFilter: WorkerFilter
    $removeContactsFromUserCreatedWorkersSorting: [WorkerSort!]
    $removeContactsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactsFromUserCreatedContractsPaging: OffsetPaging
    $removeContactsFromUserCreatedContractsFilter: ContractFilter
    $removeContactsFromUserCreatedContractsSorting: [ContractSort!]
    $removeContactsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactsFromUserCreatedMiddlemansPaging: OffsetPaging
    $removeContactsFromUserCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactsFromUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeContactsFromUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $removeContactsFromUserCreatedCompaniesPaging
        filter: $removeContactsFromUserCreatedCompaniesFilter
        sorting: $removeContactsFromUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $removeContactsFromUserCreatedProjectsPaging
        filter: $removeContactsFromUserCreatedProjectsFilter
        sorting: $removeContactsFromUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $removeContactsFromUserContactsPaging
        filter: $removeContactsFromUserContactsFilter
        sorting: $removeContactsFromUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $removeContactsFromUserProjectChangesPaging
        filter: $removeContactsFromUserProjectChangesFilter
        sorting: $removeContactsFromUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $removeContactsFromUserCreatedWorkersPaging
        filter: $removeContactsFromUserCreatedWorkersFilter
        sorting: $removeContactsFromUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $removeContactsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $removeContactsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $removeContactsFromUserCreatedContractsPaging
        filter: $removeContactsFromUserCreatedContractsFilter
        sorting: $removeContactsFromUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $removeContactsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $removeContactsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $removeContactsFromUserCreatedMiddlemansPaging
        filter: $removeContactsFromUserCreatedMiddlemansFilter
        sorting: $removeContactsFromUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $removeContactsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $removeContactsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveContactsFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveContactsFromUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactsFromUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveContactsFromUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeContactsFromUserCreatedCompaniesPaging: // value for 'removeContactsFromUserCreatedCompaniesPaging'
 *     removeContactsFromUserCreatedCompaniesFilter: // value for 'removeContactsFromUserCreatedCompaniesFilter'
 *     removeContactsFromUserCreatedCompaniesSorting: // value for 'removeContactsFromUserCreatedCompaniesSorting'
 *     removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactsFromUserCreatedProjectsPaging: // value for 'removeContactsFromUserCreatedProjectsPaging'
 *     removeContactsFromUserCreatedProjectsFilter: // value for 'removeContactsFromUserCreatedProjectsFilter'
 *     removeContactsFromUserCreatedProjectsSorting: // value for 'removeContactsFromUserCreatedProjectsSorting'
 *     removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactsFromUserContactsPaging: // value for 'removeContactsFromUserContactsPaging'
 *     removeContactsFromUserContactsFilter: // value for 'removeContactsFromUserContactsFilter'
 *     removeContactsFromUserContactsSorting: // value for 'removeContactsFromUserContactsSorting'
 *     removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactsFromUserProjectChangesPaging: // value for 'removeContactsFromUserProjectChangesPaging'
 *     removeContactsFromUserProjectChangesFilter: // value for 'removeContactsFromUserProjectChangesFilter'
 *     removeContactsFromUserProjectChangesSorting: // value for 'removeContactsFromUserProjectChangesSorting'
 *     removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactsFromUserCreatedWorkersPaging: // value for 'removeContactsFromUserCreatedWorkersPaging'
 *     removeContactsFromUserCreatedWorkersFilter: // value for 'removeContactsFromUserCreatedWorkersFilter'
 *     removeContactsFromUserCreatedWorkersSorting: // value for 'removeContactsFromUserCreatedWorkersSorting'
 *     removeContactsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactsFromUserCreatedContractsPaging: // value for 'removeContactsFromUserCreatedContractsPaging'
 *     removeContactsFromUserCreatedContractsFilter: // value for 'removeContactsFromUserCreatedContractsFilter'
 *     removeContactsFromUserCreatedContractsSorting: // value for 'removeContactsFromUserCreatedContractsSorting'
 *     removeContactsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactsFromUserCreatedMiddlemansPaging: // value for 'removeContactsFromUserCreatedMiddlemansPaging'
 *     removeContactsFromUserCreatedMiddlemansFilter: // value for 'removeContactsFromUserCreatedMiddlemansFilter'
 *     removeContactsFromUserCreatedMiddlemansSorting: // value for 'removeContactsFromUserCreatedMiddlemansSorting'
 *     removeContactsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveContactsFromUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveContactsFromUserMutation,
        RemoveContactsFromUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveContactsFromUserMutation,
          RemoveContactsFromUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveContactsFromUserMutation,
    RemoveContactsFromUserMutationVariables
  >(RemoveContactsFromUserMutationDocument, options);
}
export type RemoveContactsFromUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveContactsFromUserMutation,
    RemoveContactsFromUserMutationVariables
  >;
export const RemoveProjectChangesFromUserMutationDocument = gql`
  mutation removeProjectChangesFromUserMutation(
    $input: RemoveProjectChangesFromUserInput!
    $removeProjectChangesFromUserCreatedCompaniesPaging: OffsetPaging
    $removeProjectChangesFromUserCreatedCompaniesFilter: CompanyFilter
    $removeProjectChangesFromUserCreatedCompaniesSorting: [CompanySort!]
    $removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeProjectChangesFromUserCreatedProjectsPaging: OffsetPaging
    $removeProjectChangesFromUserCreatedProjectsFilter: ProjectFilter
    $removeProjectChangesFromUserCreatedProjectsSorting: [ProjectSort!]
    $removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeProjectChangesFromUserContactsPaging: OffsetPaging
    $removeProjectChangesFromUserContactsFilter: ContactFilter
    $removeProjectChangesFromUserContactsSorting: [ContactSort!]
    $removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectChangesFromUserProjectChangesPaging: OffsetPaging
    $removeProjectChangesFromUserProjectChangesFilter: ContactFilter
    $removeProjectChangesFromUserProjectChangesSorting: [ContactSort!]
    $removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectChangesFromUserCreatedWorkersPaging: OffsetPaging
    $removeProjectChangesFromUserCreatedWorkersFilter: WorkerFilter
    $removeProjectChangesFromUserCreatedWorkersSorting: [WorkerSort!]
    $removeProjectChangesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeProjectChangesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeProjectChangesFromUserCreatedContractsPaging: OffsetPaging
    $removeProjectChangesFromUserCreatedContractsFilter: ContractFilter
    $removeProjectChangesFromUserCreatedContractsSorting: [ContractSort!]
    $removeProjectChangesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeProjectChangesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeProjectChangesFromUserCreatedMiddlemansPaging: OffsetPaging
    $removeProjectChangesFromUserCreatedMiddlemansFilter: MiddlemanFilter
    $removeProjectChangesFromUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeProjectChangesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeProjectChangesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeProjectChangesFromUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $removeProjectChangesFromUserCreatedCompaniesPaging
        filter: $removeProjectChangesFromUserCreatedCompaniesFilter
        sorting: $removeProjectChangesFromUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $removeProjectChangesFromUserCreatedProjectsPaging
        filter: $removeProjectChangesFromUserCreatedProjectsFilter
        sorting: $removeProjectChangesFromUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $removeProjectChangesFromUserContactsPaging
        filter: $removeProjectChangesFromUserContactsFilter
        sorting: $removeProjectChangesFromUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $removeProjectChangesFromUserProjectChangesPaging
        filter: $removeProjectChangesFromUserProjectChangesFilter
        sorting: $removeProjectChangesFromUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $removeProjectChangesFromUserCreatedWorkersPaging
        filter: $removeProjectChangesFromUserCreatedWorkersFilter
        sorting: $removeProjectChangesFromUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $removeProjectChangesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $removeProjectChangesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $removeProjectChangesFromUserCreatedContractsPaging
        filter: $removeProjectChangesFromUserCreatedContractsFilter
        sorting: $removeProjectChangesFromUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $removeProjectChangesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $removeProjectChangesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $removeProjectChangesFromUserCreatedMiddlemansPaging
        filter: $removeProjectChangesFromUserCreatedMiddlemansFilter
        sorting: $removeProjectChangesFromUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $removeProjectChangesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $removeProjectChangesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveProjectChangesFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveProjectChangesFromUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectChangesFromUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveProjectChangesFromUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeProjectChangesFromUserCreatedCompaniesPaging: // value for 'removeProjectChangesFromUserCreatedCompaniesPaging'
 *     removeProjectChangesFromUserCreatedCompaniesFilter: // value for 'removeProjectChangesFromUserCreatedCompaniesFilter'
 *     removeProjectChangesFromUserCreatedCompaniesSorting: // value for 'removeProjectChangesFromUserCreatedCompaniesSorting'
 *     removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeProjectChangesFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeProjectChangesFromUserCreatedProjectsPaging: // value for 'removeProjectChangesFromUserCreatedProjectsPaging'
 *     removeProjectChangesFromUserCreatedProjectsFilter: // value for 'removeProjectChangesFromUserCreatedProjectsFilter'
 *     removeProjectChangesFromUserCreatedProjectsSorting: // value for 'removeProjectChangesFromUserCreatedProjectsSorting'
 *     removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeProjectChangesFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeProjectChangesFromUserContactsPaging: // value for 'removeProjectChangesFromUserContactsPaging'
 *     removeProjectChangesFromUserContactsFilter: // value for 'removeProjectChangesFromUserContactsFilter'
 *     removeProjectChangesFromUserContactsSorting: // value for 'removeProjectChangesFromUserContactsSorting'
 *     removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectChangesFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectChangesFromUserProjectChangesPaging: // value for 'removeProjectChangesFromUserProjectChangesPaging'
 *     removeProjectChangesFromUserProjectChangesFilter: // value for 'removeProjectChangesFromUserProjectChangesFilter'
 *     removeProjectChangesFromUserProjectChangesSorting: // value for 'removeProjectChangesFromUserProjectChangesSorting'
 *     removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectChangesFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectChangesFromUserCreatedWorkersPaging: // value for 'removeProjectChangesFromUserCreatedWorkersPaging'
 *     removeProjectChangesFromUserCreatedWorkersFilter: // value for 'removeProjectChangesFromUserCreatedWorkersFilter'
 *     removeProjectChangesFromUserCreatedWorkersSorting: // value for 'removeProjectChangesFromUserCreatedWorkersSorting'
 *     removeProjectChangesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeProjectChangesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeProjectChangesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeProjectChangesFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeProjectChangesFromUserCreatedContractsPaging: // value for 'removeProjectChangesFromUserCreatedContractsPaging'
 *     removeProjectChangesFromUserCreatedContractsFilter: // value for 'removeProjectChangesFromUserCreatedContractsFilter'
 *     removeProjectChangesFromUserCreatedContractsSorting: // value for 'removeProjectChangesFromUserCreatedContractsSorting'
 *     removeProjectChangesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeProjectChangesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeProjectChangesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeProjectChangesFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeProjectChangesFromUserCreatedMiddlemansPaging: // value for 'removeProjectChangesFromUserCreatedMiddlemansPaging'
 *     removeProjectChangesFromUserCreatedMiddlemansFilter: // value for 'removeProjectChangesFromUserCreatedMiddlemansFilter'
 *     removeProjectChangesFromUserCreatedMiddlemansSorting: // value for 'removeProjectChangesFromUserCreatedMiddlemansSorting'
 *     removeProjectChangesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeProjectChangesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeProjectChangesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeProjectChangesFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveProjectChangesFromUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveProjectChangesFromUserMutation,
        RemoveProjectChangesFromUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveProjectChangesFromUserMutation,
          RemoveProjectChangesFromUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveProjectChangesFromUserMutation,
    RemoveProjectChangesFromUserMutationVariables
  >(RemoveProjectChangesFromUserMutationDocument, options);
}
export type RemoveProjectChangesFromUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveProjectChangesFromUserMutation,
    RemoveProjectChangesFromUserMutationVariables
  >;
export const RemoveCreatedWorkersFromUserMutationDocument = gql`
  mutation removeCreatedWorkersFromUserMutation(
    $input: RemoveCreatedWorkersFromUserInput!
    $removeCreatedWorkersFromUserCreatedCompaniesPaging: OffsetPaging
    $removeCreatedWorkersFromUserCreatedCompaniesFilter: CompanyFilter
    $removeCreatedWorkersFromUserCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedWorkersFromUserCreatedProjectsPaging: OffsetPaging
    $removeCreatedWorkersFromUserCreatedProjectsFilter: ProjectFilter
    $removeCreatedWorkersFromUserCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedWorkersFromUserContactsPaging: OffsetPaging
    $removeCreatedWorkersFromUserContactsFilter: ContactFilter
    $removeCreatedWorkersFromUserContactsSorting: [ContactSort!]
    $removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedWorkersFromUserProjectChangesPaging: OffsetPaging
    $removeCreatedWorkersFromUserProjectChangesFilter: ContactFilter
    $removeCreatedWorkersFromUserProjectChangesSorting: [ContactSort!]
    $removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedWorkersFromUserCreatedWorkersPaging: OffsetPaging
    $removeCreatedWorkersFromUserCreatedWorkersFilter: WorkerFilter
    $removeCreatedWorkersFromUserCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedWorkersFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedWorkersFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedWorkersFromUserCreatedContractsPaging: OffsetPaging
    $removeCreatedWorkersFromUserCreatedContractsFilter: ContractFilter
    $removeCreatedWorkersFromUserCreatedContractsSorting: [ContractSort!]
    $removeCreatedWorkersFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedWorkersFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedWorkersFromUserCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedWorkersFromUserCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedWorkersFromUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedWorkersFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedWorkersFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedWorkersFromUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $removeCreatedWorkersFromUserCreatedCompaniesPaging
        filter: $removeCreatedWorkersFromUserCreatedCompaniesFilter
        sorting: $removeCreatedWorkersFromUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $removeCreatedWorkersFromUserCreatedProjectsPaging
        filter: $removeCreatedWorkersFromUserCreatedProjectsFilter
        sorting: $removeCreatedWorkersFromUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $removeCreatedWorkersFromUserContactsPaging
        filter: $removeCreatedWorkersFromUserContactsFilter
        sorting: $removeCreatedWorkersFromUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $removeCreatedWorkersFromUserProjectChangesPaging
        filter: $removeCreatedWorkersFromUserProjectChangesFilter
        sorting: $removeCreatedWorkersFromUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $removeCreatedWorkersFromUserCreatedWorkersPaging
        filter: $removeCreatedWorkersFromUserCreatedWorkersFilter
        sorting: $removeCreatedWorkersFromUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $removeCreatedWorkersFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $removeCreatedWorkersFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $removeCreatedWorkersFromUserCreatedContractsPaging
        filter: $removeCreatedWorkersFromUserCreatedContractsFilter
        sorting: $removeCreatedWorkersFromUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $removeCreatedWorkersFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $removeCreatedWorkersFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $removeCreatedWorkersFromUserCreatedMiddlemansPaging
        filter: $removeCreatedWorkersFromUserCreatedMiddlemansFilter
        sorting: $removeCreatedWorkersFromUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $removeCreatedWorkersFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $removeCreatedWorkersFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedWorkersFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedWorkersFromUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedWorkersFromUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedWorkersFromUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedWorkersFromUserCreatedCompaniesPaging: // value for 'removeCreatedWorkersFromUserCreatedCompaniesPaging'
 *     removeCreatedWorkersFromUserCreatedCompaniesFilter: // value for 'removeCreatedWorkersFromUserCreatedCompaniesFilter'
 *     removeCreatedWorkersFromUserCreatedCompaniesSorting: // value for 'removeCreatedWorkersFromUserCreatedCompaniesSorting'
 *     removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedWorkersFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedWorkersFromUserCreatedProjectsPaging: // value for 'removeCreatedWorkersFromUserCreatedProjectsPaging'
 *     removeCreatedWorkersFromUserCreatedProjectsFilter: // value for 'removeCreatedWorkersFromUserCreatedProjectsFilter'
 *     removeCreatedWorkersFromUserCreatedProjectsSorting: // value for 'removeCreatedWorkersFromUserCreatedProjectsSorting'
 *     removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedWorkersFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedWorkersFromUserContactsPaging: // value for 'removeCreatedWorkersFromUserContactsPaging'
 *     removeCreatedWorkersFromUserContactsFilter: // value for 'removeCreatedWorkersFromUserContactsFilter'
 *     removeCreatedWorkersFromUserContactsSorting: // value for 'removeCreatedWorkersFromUserContactsSorting'
 *     removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedWorkersFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedWorkersFromUserProjectChangesPaging: // value for 'removeCreatedWorkersFromUserProjectChangesPaging'
 *     removeCreatedWorkersFromUserProjectChangesFilter: // value for 'removeCreatedWorkersFromUserProjectChangesFilter'
 *     removeCreatedWorkersFromUserProjectChangesSorting: // value for 'removeCreatedWorkersFromUserProjectChangesSorting'
 *     removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedWorkersFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedWorkersFromUserCreatedWorkersPaging: // value for 'removeCreatedWorkersFromUserCreatedWorkersPaging'
 *     removeCreatedWorkersFromUserCreatedWorkersFilter: // value for 'removeCreatedWorkersFromUserCreatedWorkersFilter'
 *     removeCreatedWorkersFromUserCreatedWorkersSorting: // value for 'removeCreatedWorkersFromUserCreatedWorkersSorting'
 *     removeCreatedWorkersFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedWorkersFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedWorkersFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedWorkersFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedWorkersFromUserCreatedContractsPaging: // value for 'removeCreatedWorkersFromUserCreatedContractsPaging'
 *     removeCreatedWorkersFromUserCreatedContractsFilter: // value for 'removeCreatedWorkersFromUserCreatedContractsFilter'
 *     removeCreatedWorkersFromUserCreatedContractsSorting: // value for 'removeCreatedWorkersFromUserCreatedContractsSorting'
 *     removeCreatedWorkersFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedWorkersFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedWorkersFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedWorkersFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedWorkersFromUserCreatedMiddlemansPaging: // value for 'removeCreatedWorkersFromUserCreatedMiddlemansPaging'
 *     removeCreatedWorkersFromUserCreatedMiddlemansFilter: // value for 'removeCreatedWorkersFromUserCreatedMiddlemansFilter'
 *     removeCreatedWorkersFromUserCreatedMiddlemansSorting: // value for 'removeCreatedWorkersFromUserCreatedMiddlemansSorting'
 *     removeCreatedWorkersFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedWorkersFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedWorkersFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedWorkersFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedWorkersFromUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedWorkersFromUserMutation,
        RemoveCreatedWorkersFromUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedWorkersFromUserMutation,
          RemoveCreatedWorkersFromUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedWorkersFromUserMutation,
    RemoveCreatedWorkersFromUserMutationVariables
  >(RemoveCreatedWorkersFromUserMutationDocument, options);
}
export type RemoveCreatedWorkersFromUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedWorkersFromUserMutation,
    RemoveCreatedWorkersFromUserMutationVariables
  >;
export const RemoveCreatedContractsFromUserMutationDocument = gql`
  mutation removeCreatedContractsFromUserMutation(
    $input: RemoveCreatedContractsFromUserInput!
    $removeCreatedContractsFromUserCreatedCompaniesPaging: OffsetPaging
    $removeCreatedContractsFromUserCreatedCompaniesFilter: CompanyFilter
    $removeCreatedContractsFromUserCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedContractsFromUserCreatedProjectsPaging: OffsetPaging
    $removeCreatedContractsFromUserCreatedProjectsFilter: ProjectFilter
    $removeCreatedContractsFromUserCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedContractsFromUserContactsPaging: OffsetPaging
    $removeCreatedContractsFromUserContactsFilter: ContactFilter
    $removeCreatedContractsFromUserContactsSorting: [ContactSort!]
    $removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedContractsFromUserProjectChangesPaging: OffsetPaging
    $removeCreatedContractsFromUserProjectChangesFilter: ContactFilter
    $removeCreatedContractsFromUserProjectChangesSorting: [ContactSort!]
    $removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedContractsFromUserCreatedWorkersPaging: OffsetPaging
    $removeCreatedContractsFromUserCreatedWorkersFilter: WorkerFilter
    $removeCreatedContractsFromUserCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedContractsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedContractsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedContractsFromUserCreatedContractsPaging: OffsetPaging
    $removeCreatedContractsFromUserCreatedContractsFilter: ContractFilter
    $removeCreatedContractsFromUserCreatedContractsSorting: [ContractSort!]
    $removeCreatedContractsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedContractsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedContractsFromUserCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedContractsFromUserCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedContractsFromUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedContractsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedContractsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedContractsFromUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $removeCreatedContractsFromUserCreatedCompaniesPaging
        filter: $removeCreatedContractsFromUserCreatedCompaniesFilter
        sorting: $removeCreatedContractsFromUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $removeCreatedContractsFromUserCreatedProjectsPaging
        filter: $removeCreatedContractsFromUserCreatedProjectsFilter
        sorting: $removeCreatedContractsFromUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $removeCreatedContractsFromUserContactsPaging
        filter: $removeCreatedContractsFromUserContactsFilter
        sorting: $removeCreatedContractsFromUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $removeCreatedContractsFromUserProjectChangesPaging
        filter: $removeCreatedContractsFromUserProjectChangesFilter
        sorting: $removeCreatedContractsFromUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $removeCreatedContractsFromUserCreatedWorkersPaging
        filter: $removeCreatedContractsFromUserCreatedWorkersFilter
        sorting: $removeCreatedContractsFromUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $removeCreatedContractsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $removeCreatedContractsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $removeCreatedContractsFromUserCreatedContractsPaging
        filter: $removeCreatedContractsFromUserCreatedContractsFilter
        sorting: $removeCreatedContractsFromUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $removeCreatedContractsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $removeCreatedContractsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $removeCreatedContractsFromUserCreatedMiddlemansPaging
        filter: $removeCreatedContractsFromUserCreatedMiddlemansFilter
        sorting: $removeCreatedContractsFromUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $removeCreatedContractsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $removeCreatedContractsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedContractsFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedContractsFromUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedContractsFromUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedContractsFromUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedContractsFromUserCreatedCompaniesPaging: // value for 'removeCreatedContractsFromUserCreatedCompaniesPaging'
 *     removeCreatedContractsFromUserCreatedCompaniesFilter: // value for 'removeCreatedContractsFromUserCreatedCompaniesFilter'
 *     removeCreatedContractsFromUserCreatedCompaniesSorting: // value for 'removeCreatedContractsFromUserCreatedCompaniesSorting'
 *     removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedContractsFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedContractsFromUserCreatedProjectsPaging: // value for 'removeCreatedContractsFromUserCreatedProjectsPaging'
 *     removeCreatedContractsFromUserCreatedProjectsFilter: // value for 'removeCreatedContractsFromUserCreatedProjectsFilter'
 *     removeCreatedContractsFromUserCreatedProjectsSorting: // value for 'removeCreatedContractsFromUserCreatedProjectsSorting'
 *     removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedContractsFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedContractsFromUserContactsPaging: // value for 'removeCreatedContractsFromUserContactsPaging'
 *     removeCreatedContractsFromUserContactsFilter: // value for 'removeCreatedContractsFromUserContactsFilter'
 *     removeCreatedContractsFromUserContactsSorting: // value for 'removeCreatedContractsFromUserContactsSorting'
 *     removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedContractsFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedContractsFromUserProjectChangesPaging: // value for 'removeCreatedContractsFromUserProjectChangesPaging'
 *     removeCreatedContractsFromUserProjectChangesFilter: // value for 'removeCreatedContractsFromUserProjectChangesFilter'
 *     removeCreatedContractsFromUserProjectChangesSorting: // value for 'removeCreatedContractsFromUserProjectChangesSorting'
 *     removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedContractsFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedContractsFromUserCreatedWorkersPaging: // value for 'removeCreatedContractsFromUserCreatedWorkersPaging'
 *     removeCreatedContractsFromUserCreatedWorkersFilter: // value for 'removeCreatedContractsFromUserCreatedWorkersFilter'
 *     removeCreatedContractsFromUserCreatedWorkersSorting: // value for 'removeCreatedContractsFromUserCreatedWorkersSorting'
 *     removeCreatedContractsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedContractsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedContractsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedContractsFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedContractsFromUserCreatedContractsPaging: // value for 'removeCreatedContractsFromUserCreatedContractsPaging'
 *     removeCreatedContractsFromUserCreatedContractsFilter: // value for 'removeCreatedContractsFromUserCreatedContractsFilter'
 *     removeCreatedContractsFromUserCreatedContractsSorting: // value for 'removeCreatedContractsFromUserCreatedContractsSorting'
 *     removeCreatedContractsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedContractsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedContractsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedContractsFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedContractsFromUserCreatedMiddlemansPaging: // value for 'removeCreatedContractsFromUserCreatedMiddlemansPaging'
 *     removeCreatedContractsFromUserCreatedMiddlemansFilter: // value for 'removeCreatedContractsFromUserCreatedMiddlemansFilter'
 *     removeCreatedContractsFromUserCreatedMiddlemansSorting: // value for 'removeCreatedContractsFromUserCreatedMiddlemansSorting'
 *     removeCreatedContractsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedContractsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedContractsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedContractsFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedContractsFromUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedContractsFromUserMutation,
        RemoveCreatedContractsFromUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedContractsFromUserMutation,
          RemoveCreatedContractsFromUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedContractsFromUserMutation,
    RemoveCreatedContractsFromUserMutationVariables
  >(RemoveCreatedContractsFromUserMutationDocument, options);
}
export type RemoveCreatedContractsFromUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedContractsFromUserMutation,
    RemoveCreatedContractsFromUserMutationVariables
  >;
export const RemoveCreatedMiddlemansFromUserMutationDocument = gql`
  mutation removeCreatedMiddlemansFromUserMutation(
    $input: RemoveCreatedMiddlemansFromUserInput!
    $removeCreatedMiddlemansFromUserCreatedCompaniesPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserCreatedCompaniesFilter: CompanyFilter
    $removeCreatedMiddlemansFromUserCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedMiddlemansFromUserCreatedProjectsPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserCreatedProjectsFilter: ProjectFilter
    $removeCreatedMiddlemansFromUserCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedMiddlemansFromUserContactsPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserContactsFilter: ContactFilter
    $removeCreatedMiddlemansFromUserContactsSorting: [ContactSort!]
    $removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedMiddlemansFromUserProjectChangesPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserProjectChangesFilter: ContactFilter
    $removeCreatedMiddlemansFromUserProjectChangesSorting: [ContactSort!]
    $removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedMiddlemansFromUserCreatedWorkersPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserCreatedWorkersFilter: WorkerFilter
    $removeCreatedMiddlemansFromUserCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedMiddlemansFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedMiddlemansFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedMiddlemansFromUserCreatedContractsPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserCreatedContractsFilter: ContractFilter
    $removeCreatedMiddlemansFromUserCreatedContractsSorting: [ContractSort!]
    $removeCreatedMiddlemansFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedMiddlemansFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedMiddlemansFromUserCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedMiddlemansFromUserCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedMiddlemansFromUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedMiddlemansFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedMiddlemansFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedMiddlemansFromUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $removeCreatedMiddlemansFromUserCreatedCompaniesPaging
        filter: $removeCreatedMiddlemansFromUserCreatedCompaniesFilter
        sorting: $removeCreatedMiddlemansFromUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $removeCreatedMiddlemansFromUserCreatedProjectsPaging
        filter: $removeCreatedMiddlemansFromUserCreatedProjectsFilter
        sorting: $removeCreatedMiddlemansFromUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $removeCreatedMiddlemansFromUserContactsPaging
        filter: $removeCreatedMiddlemansFromUserContactsFilter
        sorting: $removeCreatedMiddlemansFromUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $removeCreatedMiddlemansFromUserProjectChangesPaging
        filter: $removeCreatedMiddlemansFromUserProjectChangesFilter
        sorting: $removeCreatedMiddlemansFromUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $removeCreatedMiddlemansFromUserCreatedWorkersPaging
        filter: $removeCreatedMiddlemansFromUserCreatedWorkersFilter
        sorting: $removeCreatedMiddlemansFromUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $removeCreatedMiddlemansFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $removeCreatedMiddlemansFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $removeCreatedMiddlemansFromUserCreatedContractsPaging
        filter: $removeCreatedMiddlemansFromUserCreatedContractsFilter
        sorting: $removeCreatedMiddlemansFromUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $removeCreatedMiddlemansFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $removeCreatedMiddlemansFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $removeCreatedMiddlemansFromUserCreatedMiddlemansPaging
        filter: $removeCreatedMiddlemansFromUserCreatedMiddlemansFilter
        sorting: $removeCreatedMiddlemansFromUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $removeCreatedMiddlemansFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $removeCreatedMiddlemansFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedMiddlemansFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedMiddlemansFromUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedMiddlemansFromUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedMiddlemansFromUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedMiddlemansFromUserCreatedCompaniesPaging: // value for 'removeCreatedMiddlemansFromUserCreatedCompaniesPaging'
 *     removeCreatedMiddlemansFromUserCreatedCompaniesFilter: // value for 'removeCreatedMiddlemansFromUserCreatedCompaniesFilter'
 *     removeCreatedMiddlemansFromUserCreatedCompaniesSorting: // value for 'removeCreatedMiddlemansFromUserCreatedCompaniesSorting'
 *     removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedMiddlemansFromUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedMiddlemansFromUserCreatedProjectsPaging: // value for 'removeCreatedMiddlemansFromUserCreatedProjectsPaging'
 *     removeCreatedMiddlemansFromUserCreatedProjectsFilter: // value for 'removeCreatedMiddlemansFromUserCreatedProjectsFilter'
 *     removeCreatedMiddlemansFromUserCreatedProjectsSorting: // value for 'removeCreatedMiddlemansFromUserCreatedProjectsSorting'
 *     removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedMiddlemansFromUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedMiddlemansFromUserContactsPaging: // value for 'removeCreatedMiddlemansFromUserContactsPaging'
 *     removeCreatedMiddlemansFromUserContactsFilter: // value for 'removeCreatedMiddlemansFromUserContactsFilter'
 *     removeCreatedMiddlemansFromUserContactsSorting: // value for 'removeCreatedMiddlemansFromUserContactsSorting'
 *     removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedMiddlemansFromUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedMiddlemansFromUserProjectChangesPaging: // value for 'removeCreatedMiddlemansFromUserProjectChangesPaging'
 *     removeCreatedMiddlemansFromUserProjectChangesFilter: // value for 'removeCreatedMiddlemansFromUserProjectChangesFilter'
 *     removeCreatedMiddlemansFromUserProjectChangesSorting: // value for 'removeCreatedMiddlemansFromUserProjectChangesSorting'
 *     removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedMiddlemansFromUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedMiddlemansFromUserCreatedWorkersPaging: // value for 'removeCreatedMiddlemansFromUserCreatedWorkersPaging'
 *     removeCreatedMiddlemansFromUserCreatedWorkersFilter: // value for 'removeCreatedMiddlemansFromUserCreatedWorkersFilter'
 *     removeCreatedMiddlemansFromUserCreatedWorkersSorting: // value for 'removeCreatedMiddlemansFromUserCreatedWorkersSorting'
 *     removeCreatedMiddlemansFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedMiddlemansFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedMiddlemansFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedMiddlemansFromUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedMiddlemansFromUserCreatedContractsPaging: // value for 'removeCreatedMiddlemansFromUserCreatedContractsPaging'
 *     removeCreatedMiddlemansFromUserCreatedContractsFilter: // value for 'removeCreatedMiddlemansFromUserCreatedContractsFilter'
 *     removeCreatedMiddlemansFromUserCreatedContractsSorting: // value for 'removeCreatedMiddlemansFromUserCreatedContractsSorting'
 *     removeCreatedMiddlemansFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedMiddlemansFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedMiddlemansFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedMiddlemansFromUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedMiddlemansFromUserCreatedMiddlemansPaging: // value for 'removeCreatedMiddlemansFromUserCreatedMiddlemansPaging'
 *     removeCreatedMiddlemansFromUserCreatedMiddlemansFilter: // value for 'removeCreatedMiddlemansFromUserCreatedMiddlemansFilter'
 *     removeCreatedMiddlemansFromUserCreatedMiddlemansSorting: // value for 'removeCreatedMiddlemansFromUserCreatedMiddlemansSorting'
 *     removeCreatedMiddlemansFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedMiddlemansFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedMiddlemansFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedMiddlemansFromUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedMiddlemansFromUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedMiddlemansFromUserMutation,
        RemoveCreatedMiddlemansFromUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedMiddlemansFromUserMutation,
          RemoveCreatedMiddlemansFromUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedMiddlemansFromUserMutation,
    RemoveCreatedMiddlemansFromUserMutationVariables
  >(RemoveCreatedMiddlemansFromUserMutationDocument, options);
}
export type RemoveCreatedMiddlemansFromUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedMiddlemansFromUserMutation,
    RemoveCreatedMiddlemansFromUserMutationVariables
  >;
export const CreateOneUserMutationDocument = gql`
  mutation createOneUserMutation(
    $input: CreateOneUserInput!
    $createOneUserCreatedCompaniesPaging: OffsetPaging
    $createOneUserCreatedCompaniesFilter: CompanyFilter
    $createOneUserCreatedCompaniesSorting: [CompanySort!]
    $createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneUserCreatedProjectsPaging: OffsetPaging
    $createOneUserCreatedProjectsFilter: ProjectFilter
    $createOneUserCreatedProjectsSorting: [ProjectSort!]
    $createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneUserContactsPaging: OffsetPaging
    $createOneUserContactsFilter: ContactFilter
    $createOneUserContactsSorting: [ContactSort!]
    $createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneUserProjectChangesPaging: OffsetPaging
    $createOneUserProjectChangesFilter: ContactFilter
    $createOneUserProjectChangesSorting: [ContactSort!]
    $createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneUserCreatedWorkersPaging: OffsetPaging
    $createOneUserCreatedWorkersFilter: WorkerFilter
    $createOneUserCreatedWorkersSorting: [WorkerSort!]
    $createOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneUserCreatedContractsPaging: OffsetPaging
    $createOneUserCreatedContractsFilter: ContractFilter
    $createOneUserCreatedContractsSorting: [ContractSort!]
    $createOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneUserCreatedMiddlemansPaging: OffsetPaging
    $createOneUserCreatedMiddlemansFilter: MiddlemanFilter
    $createOneUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createOneUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $createOneUserCreatedCompaniesPaging
        filter: $createOneUserCreatedCompaniesFilter
        sorting: $createOneUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $createOneUserCreatedProjectsPaging
        filter: $createOneUserCreatedProjectsFilter
        sorting: $createOneUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $createOneUserContactsPaging
        filter: $createOneUserContactsFilter
        sorting: $createOneUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $createOneUserProjectChangesPaging
        filter: $createOneUserProjectChangesFilter
        sorting: $createOneUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $createOneUserCreatedWorkersPaging
        filter: $createOneUserCreatedWorkersFilter
        sorting: $createOneUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $createOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $createOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $createOneUserCreatedContractsPaging
        filter: $createOneUserCreatedContractsFilter
        sorting: $createOneUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $createOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $createOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $createOneUserCreatedMiddlemansPaging
        filter: $createOneUserCreatedMiddlemansFilter
        sorting: $createOneUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $createOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $createOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useCreateOneUserMutation__
 *
 * To run a mutation, you first call `useCreateOneUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneUserCreatedCompaniesPaging: // value for 'createOneUserCreatedCompaniesPaging'
 *     createOneUserCreatedCompaniesFilter: // value for 'createOneUserCreatedCompaniesFilter'
 *     createOneUserCreatedCompaniesSorting: // value for 'createOneUserCreatedCompaniesSorting'
 *     createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneUserCreatedProjectsPaging: // value for 'createOneUserCreatedProjectsPaging'
 *     createOneUserCreatedProjectsFilter: // value for 'createOneUserCreatedProjectsFilter'
 *     createOneUserCreatedProjectsSorting: // value for 'createOneUserCreatedProjectsSorting'
 *     createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneUserContactsPaging: // value for 'createOneUserContactsPaging'
 *     createOneUserContactsFilter: // value for 'createOneUserContactsFilter'
 *     createOneUserContactsSorting: // value for 'createOneUserContactsSorting'
 *     createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneUserProjectChangesPaging: // value for 'createOneUserProjectChangesPaging'
 *     createOneUserProjectChangesFilter: // value for 'createOneUserProjectChangesFilter'
 *     createOneUserProjectChangesSorting: // value for 'createOneUserProjectChangesSorting'
 *     createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneUserCreatedWorkersPaging: // value for 'createOneUserCreatedWorkersPaging'
 *     createOneUserCreatedWorkersFilter: // value for 'createOneUserCreatedWorkersFilter'
 *     createOneUserCreatedWorkersSorting: // value for 'createOneUserCreatedWorkersSorting'
 *     createOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneUserCreatedContractsPaging: // value for 'createOneUserCreatedContractsPaging'
 *     createOneUserCreatedContractsFilter: // value for 'createOneUserCreatedContractsFilter'
 *     createOneUserCreatedContractsSorting: // value for 'createOneUserCreatedContractsSorting'
 *     createOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneUserCreatedMiddlemansPaging: // value for 'createOneUserCreatedMiddlemansPaging'
 *     createOneUserCreatedMiddlemansFilter: // value for 'createOneUserCreatedMiddlemansFilter'
 *     createOneUserCreatedMiddlemansSorting: // value for 'createOneUserCreatedMiddlemansSorting'
 *     createOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneUserMutation,
        CreateOneUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneUserMutation,
          CreateOneUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneUserMutation,
    CreateOneUserMutationVariables
  >(CreateOneUserMutationDocument, options);
}
export type CreateOneUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneUserMutation,
    CreateOneUserMutationVariables
  >;
export const CreateManyUsersMutationDocument = gql`
  mutation createManyUsersMutation(
    $input: CreateManyUsersInput!
    $createManyUsersCreatedCompaniesPaging: OffsetPaging
    $createManyUsersCreatedCompaniesFilter: CompanyFilter
    $createManyUsersCreatedCompaniesSorting: [CompanySort!]
    $createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyUsersCreatedProjectsPaging: OffsetPaging
    $createManyUsersCreatedProjectsFilter: ProjectFilter
    $createManyUsersCreatedProjectsSorting: [ProjectSort!]
    $createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyUsersContactsPaging: OffsetPaging
    $createManyUsersContactsFilter: ContactFilter
    $createManyUsersContactsSorting: [ContactSort!]
    $createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyUsersProjectChangesPaging: OffsetPaging
    $createManyUsersProjectChangesFilter: ContactFilter
    $createManyUsersProjectChangesSorting: [ContactSort!]
    $createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyUsersCreatedWorkersPaging: OffsetPaging
    $createManyUsersCreatedWorkersFilter: WorkerFilter
    $createManyUsersCreatedWorkersSorting: [WorkerSort!]
    $createManyUsersCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyUsersCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyUsersCreatedContractsPaging: OffsetPaging
    $createManyUsersCreatedContractsFilter: ContractFilter
    $createManyUsersCreatedContractsSorting: [ContractSort!]
    $createManyUsersCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyUsersCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyUsersCreatedMiddlemansPaging: OffsetPaging
    $createManyUsersCreatedMiddlemansFilter: MiddlemanFilter
    $createManyUsersCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyUsersCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyUsersCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createManyUsers(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $createManyUsersCreatedCompaniesPaging
        filter: $createManyUsersCreatedCompaniesFilter
        sorting: $createManyUsersCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $createManyUsersCreatedProjectsPaging
        filter: $createManyUsersCreatedProjectsFilter
        sorting: $createManyUsersCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $createManyUsersContactsPaging
        filter: $createManyUsersContactsFilter
        sorting: $createManyUsersContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $createManyUsersProjectChangesPaging
        filter: $createManyUsersProjectChangesFilter
        sorting: $createManyUsersProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $createManyUsersCreatedWorkersPaging
        filter: $createManyUsersCreatedWorkersFilter
        sorting: $createManyUsersCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $createManyUsersCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $createManyUsersCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $createManyUsersCreatedContractsPaging
        filter: $createManyUsersCreatedContractsFilter
        sorting: $createManyUsersCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $createManyUsersCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $createManyUsersCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $createManyUsersCreatedMiddlemansPaging
        filter: $createManyUsersCreatedMiddlemansFilter
        sorting: $createManyUsersCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $createManyUsersCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $createManyUsersCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useCreateManyUsersMutation__
 *
 * To run a mutation, you first call `useCreateManyUsersMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyUsersMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyUsersMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyUsersCreatedCompaniesPaging: // value for 'createManyUsersCreatedCompaniesPaging'
 *     createManyUsersCreatedCompaniesFilter: // value for 'createManyUsersCreatedCompaniesFilter'
 *     createManyUsersCreatedCompaniesSorting: // value for 'createManyUsersCreatedCompaniesSorting'
 *     createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyUsersCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyUsersCreatedProjectsPaging: // value for 'createManyUsersCreatedProjectsPaging'
 *     createManyUsersCreatedProjectsFilter: // value for 'createManyUsersCreatedProjectsFilter'
 *     createManyUsersCreatedProjectsSorting: // value for 'createManyUsersCreatedProjectsSorting'
 *     createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyUsersCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyUsersContactsPaging: // value for 'createManyUsersContactsPaging'
 *     createManyUsersContactsFilter: // value for 'createManyUsersContactsFilter'
 *     createManyUsersContactsSorting: // value for 'createManyUsersContactsSorting'
 *     createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyUsersContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyUsersProjectChangesPaging: // value for 'createManyUsersProjectChangesPaging'
 *     createManyUsersProjectChangesFilter: // value for 'createManyUsersProjectChangesFilter'
 *     createManyUsersProjectChangesSorting: // value for 'createManyUsersProjectChangesSorting'
 *     createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyUsersProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyUsersCreatedWorkersPaging: // value for 'createManyUsersCreatedWorkersPaging'
 *     createManyUsersCreatedWorkersFilter: // value for 'createManyUsersCreatedWorkersFilter'
 *     createManyUsersCreatedWorkersSorting: // value for 'createManyUsersCreatedWorkersSorting'
 *     createManyUsersCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyUsersCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyUsersCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyUsersCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyUsersCreatedContractsPaging: // value for 'createManyUsersCreatedContractsPaging'
 *     createManyUsersCreatedContractsFilter: // value for 'createManyUsersCreatedContractsFilter'
 *     createManyUsersCreatedContractsSorting: // value for 'createManyUsersCreatedContractsSorting'
 *     createManyUsersCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyUsersCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyUsersCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyUsersCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyUsersCreatedMiddlemansPaging: // value for 'createManyUsersCreatedMiddlemansPaging'
 *     createManyUsersCreatedMiddlemansFilter: // value for 'createManyUsersCreatedMiddlemansFilter'
 *     createManyUsersCreatedMiddlemansSorting: // value for 'createManyUsersCreatedMiddlemansSorting'
 *     createManyUsersCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyUsersCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyUsersCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyUsersCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyUsersMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyUsersMutation,
        CreateManyUsersMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyUsersMutation,
          CreateManyUsersMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyUsersMutation,
    CreateManyUsersMutationVariables
  >(CreateManyUsersMutationDocument, options);
}
export type CreateManyUsersMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyUsersMutation,
    CreateManyUsersMutationVariables
  >;
export const UpdateOneUserMutationDocument = gql`
  mutation updateOneUserMutation(
    $input: UpdateOneUserInput!
    $updateOneUserCreatedCompaniesPaging: OffsetPaging
    $updateOneUserCreatedCompaniesFilter: CompanyFilter
    $updateOneUserCreatedCompaniesSorting: [CompanySort!]
    $updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneUserCreatedProjectsPaging: OffsetPaging
    $updateOneUserCreatedProjectsFilter: ProjectFilter
    $updateOneUserCreatedProjectsSorting: [ProjectSort!]
    $updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneUserContactsPaging: OffsetPaging
    $updateOneUserContactsFilter: ContactFilter
    $updateOneUserContactsSorting: [ContactSort!]
    $updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneUserProjectChangesPaging: OffsetPaging
    $updateOneUserProjectChangesFilter: ContactFilter
    $updateOneUserProjectChangesSorting: [ContactSort!]
    $updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneUserCreatedWorkersPaging: OffsetPaging
    $updateOneUserCreatedWorkersFilter: WorkerFilter
    $updateOneUserCreatedWorkersSorting: [WorkerSort!]
    $updateOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneUserCreatedContractsPaging: OffsetPaging
    $updateOneUserCreatedContractsFilter: ContractFilter
    $updateOneUserCreatedContractsSorting: [ContractSort!]
    $updateOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneUserCreatedMiddlemansPaging: OffsetPaging
    $updateOneUserCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneUserCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    updateOneUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
      createdCompanies(
        paging: $updateOneUserCreatedCompaniesPaging
        filter: $updateOneUserCreatedCompaniesFilter
        sorting: $updateOneUserCreatedCompaniesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          contact {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
          histories(
            paging: $updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
            filter: $updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
            sorting: $updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
          }
        }
      }
      createdProjects(
        paging: $updateOneUserCreatedProjectsPaging
        filter: $updateOneUserCreatedProjectsFilter
        sorting: $updateOneUserCreatedProjectsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Address
          Type
          Language
          Manager
          ManagerContact
          projectChange {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Description
            State
          }
          histories(
            paging: $updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
            filter: $updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
            sorting: $updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
          }
        }
      }
      contacts(
        paging: $updateOneUserContactsPaging
        filter: $updateOneUserContactsFilter
        sorting: $updateOneUserContactsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging
              filter: $updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter
              sorting: $updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      projectChanges(
        paging: $updateOneUserProjectChangesPaging
        filter: $updateOneUserProjectChangesFilter
        sorting: $updateOneUserProjectChangesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
              filter: $updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
      createdWorkers(
        paging: $updateOneUserCreatedWorkersPaging
        filter: $updateOneUserCreatedWorkersFilter
        sorting: $updateOneUserCreatedWorkersSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          Profession
          Type
          attachments(
            filter: $updateOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
            sorting: $updateOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdContracts(
        paging: $updateOneUserCreatedContractsPaging
        filter: $updateOneUserCreatedContractsFilter
        sorting: $updateOneUserCreatedContractsSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Description
          Type
          Year
          attachments(
            filter: $updateOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
            sorting: $updateOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
      createdMiddlemans(
        paging: $updateOneUserCreatedMiddlemansPaging
        filter: $updateOneUserCreatedMiddlemansFilter
        sorting: $updateOneUserCreatedMiddlemansSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Phone
          Email
          Note
          attachments(
            filter: $updateOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
            sorting: $updateOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
          ) {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
          }
        }
      }
    }
  }
`;

/**
 * __useUpdateOneUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneUserCreatedCompaniesPaging: // value for 'updateOneUserCreatedCompaniesPaging'
 *     updateOneUserCreatedCompaniesFilter: // value for 'updateOneUserCreatedCompaniesFilter'
 *     updateOneUserCreatedCompaniesSorting: // value for 'updateOneUserCreatedCompaniesSorting'
 *     updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneUserCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneUserCreatedProjectsPaging: // value for 'updateOneUserCreatedProjectsPaging'
 *     updateOneUserCreatedProjectsFilter: // value for 'updateOneUserCreatedProjectsFilter'
 *     updateOneUserCreatedProjectsSorting: // value for 'updateOneUserCreatedProjectsSorting'
 *     updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneUserCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneUserContactsPaging: // value for 'updateOneUserContactsPaging'
 *     updateOneUserContactsFilter: // value for 'updateOneUserContactsFilter'
 *     updateOneUserContactsSorting: // value for 'updateOneUserContactsSorting'
 *     updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneUserContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneUserProjectChangesPaging: // value for 'updateOneUserProjectChangesPaging'
 *     updateOneUserProjectChangesFilter: // value for 'updateOneUserProjectChangesFilter'
 *     updateOneUserProjectChangesSorting: // value for 'updateOneUserProjectChangesSorting'
 *     updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneUserProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneUserCreatedWorkersPaging: // value for 'updateOneUserCreatedWorkersPaging'
 *     updateOneUserCreatedWorkersFilter: // value for 'updateOneUserCreatedWorkersFilter'
 *     updateOneUserCreatedWorkersSorting: // value for 'updateOneUserCreatedWorkersSorting'
 *     updateOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneUserCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneUserCreatedContractsPaging: // value for 'updateOneUserCreatedContractsPaging'
 *     updateOneUserCreatedContractsFilter: // value for 'updateOneUserCreatedContractsFilter'
 *     updateOneUserCreatedContractsSorting: // value for 'updateOneUserCreatedContractsSorting'
 *     updateOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneUserCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneUserCreatedMiddlemansPaging: // value for 'updateOneUserCreatedMiddlemansPaging'
 *     updateOneUserCreatedMiddlemansFilter: // value for 'updateOneUserCreatedMiddlemansFilter'
 *     updateOneUserCreatedMiddlemansSorting: // value for 'updateOneUserCreatedMiddlemansSorting'
 *     updateOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneUserCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneUserMutation,
        UpdateOneUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneUserMutation,
          UpdateOneUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneUserMutation,
    UpdateOneUserMutationVariables
  >(UpdateOneUserMutationDocument, options);
}
export type UpdateOneUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneUserMutation,
    UpdateOneUserMutationVariables
  >;
export const UpdateManyUsersMutationDocument = gql`
  mutation updateManyUsersMutation($input: UpdateManyUsersInput!) {
    updateManyUsers(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyUsersMutation__
 *
 * To run a mutation, you first call `useUpdateManyUsersMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyUsersMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyUsersMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyUsersMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyUsersMutation,
        UpdateManyUsersMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyUsersMutation,
          UpdateManyUsersMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyUsersMutation,
    UpdateManyUsersMutationVariables
  >(UpdateManyUsersMutationDocument, options);
}
export type UpdateManyUsersMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyUsersMutation,
    UpdateManyUsersMutationVariables
  >;
export const DeleteOneUserMutationDocument = gql`
  mutation deleteOneUserMutation($input: DeleteOneUserInput!) {
    deleteOneUser(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Username
      Email
      Status
      Role
    }
  }
`;

/**
 * __useDeleteOneUserMutation__
 *
 * To run a mutation, you first call `useDeleteOneUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneUserMutation,
        DeleteOneUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneUserMutation,
          DeleteOneUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneUserMutation,
    DeleteOneUserMutationVariables
  >(DeleteOneUserMutationDocument, options);
}
export type DeleteOneUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneUserMutation,
    DeleteOneUserMutationVariables
  >;
export const DeleteManyUsersMutationDocument = gql`
  mutation deleteManyUsersMutation($input: DeleteManyUsersInput!) {
    deleteManyUsers(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyUsersMutation__
 *
 * To run a mutation, you first call `useDeleteManyUsersMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyUsersMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyUsersMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyUsersMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyUsersMutation,
        DeleteManyUsersMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyUsersMutation,
          DeleteManyUsersMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyUsersMutation,
    DeleteManyUsersMutationVariables
  >(DeleteManyUsersMutationDocument, options);
}
export type DeleteManyUsersMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyUsersMutation,
    DeleteManyUsersMutationVariables
  >;
export const RestoreOneCompanyMutationDocument = gql`
  mutation restoreOneCompanyMutation(
    $input: String!
    $restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $restoreOneCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $restoreOneCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $restoreOneCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $restoreOneCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $restoreOneCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $restoreOneCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $restoreOneCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $restoreOneCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $restoreOneCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $restoreOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $restoreOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $restoreOneCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $restoreOneCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $restoreOneCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $restoreOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $restoreOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $restoreOneCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $restoreOneCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $restoreOneCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $restoreOneCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $restoreOneCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $restoreOneCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $restoreOneCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $restoreOneCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $restoreOneCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $restoreOneCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $restoreOneCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $restoreOneCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $restoreOneCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $restoreOneCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $restoreOneCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $restoreOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $restoreOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $restoreOneCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $restoreOneCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $restoreOneCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $restoreOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $restoreOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $restoreOneCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $restoreOneCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $restoreOneCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $restoreOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $restoreOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $restoreOneCompanyHistoriesPaging: OffsetPaging
    $restoreOneCompanyHistoriesFilter: ContactFilter
    $restoreOneCompanyHistoriesSorting: [ContactSort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    restoreOneCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $restoreOneCompanyContactContactContactedByContactedByContactsPaging
            filter: $restoreOneCompanyContactContactContactedByContactedByContactsFilter
            sorting: $restoreOneCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $restoreOneCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $restoreOneCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $restoreOneCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $restoreOneCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $restoreOneCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $restoreOneCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $restoreOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $restoreOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $restoreOneCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $restoreOneCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $restoreOneCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $restoreOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $restoreOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $restoreOneCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $restoreOneCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $restoreOneCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $restoreOneCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $restoreOneCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $restoreOneCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $restoreOneCompanyCreatedByCreatedByContactsPaging
          filter: $restoreOneCompanyCreatedByCreatedByContactsFilter
          sorting: $restoreOneCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $restoreOneCompanyCreatedByCreatedByProjectChangesPaging
          filter: $restoreOneCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $restoreOneCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $restoreOneCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $restoreOneCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $restoreOneCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $restoreOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $restoreOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $restoreOneCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $restoreOneCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $restoreOneCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $restoreOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $restoreOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $restoreOneCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $restoreOneCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $restoreOneCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $restoreOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $restoreOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $restoreOneCompanyHistoriesPaging
        filter: $restoreOneCompanyHistoriesFilter
        sorting: $restoreOneCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRestoreOneCompanyMutation__
 *
 * To run a mutation, you first call `useRestoreOneCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRestoreOneCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRestoreOneCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     restoreOneCompanyContactContactContactedByContactedByContactsPaging: // value for 'restoreOneCompanyContactContactContactedByContactedByContactsPaging'
 *     restoreOneCompanyContactContactContactedByContactedByContactsFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByContactsFilter'
 *     restoreOneCompanyContactContactContactedByContactedByContactsSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByContactsSorting'
 *     restoreOneCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'restoreOneCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     restoreOneCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     restoreOneCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'restoreOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     restoreOneCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'restoreOneCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     restoreOneCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'restoreOneCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     restoreOneCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'restoreOneCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     restoreOneCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'restoreOneCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     restoreOneCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'restoreOneCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     restoreOneCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'restoreOneCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'restoreOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     restoreOneCompanyCreatedByCreatedByContactsPaging: // value for 'restoreOneCompanyCreatedByCreatedByContactsPaging'
 *     restoreOneCompanyCreatedByCreatedByContactsFilter: // value for 'restoreOneCompanyCreatedByCreatedByContactsFilter'
 *     restoreOneCompanyCreatedByCreatedByContactsSorting: // value for 'restoreOneCompanyCreatedByCreatedByContactsSorting'
 *     restoreOneCompanyCreatedByCreatedByProjectChangesPaging: // value for 'restoreOneCompanyCreatedByCreatedByProjectChangesPaging'
 *     restoreOneCompanyCreatedByCreatedByProjectChangesFilter: // value for 'restoreOneCompanyCreatedByCreatedByProjectChangesFilter'
 *     restoreOneCompanyCreatedByCreatedByProjectChangesSorting: // value for 'restoreOneCompanyCreatedByCreatedByProjectChangesSorting'
 *     restoreOneCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'restoreOneCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     restoreOneCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'restoreOneCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     restoreOneCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'restoreOneCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     restoreOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'restoreOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     restoreOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'restoreOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     restoreOneCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'restoreOneCompanyCreatedByCreatedByCreatedContractsPaging'
 *     restoreOneCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'restoreOneCompanyCreatedByCreatedByCreatedContractsFilter'
 *     restoreOneCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'restoreOneCompanyCreatedByCreatedByCreatedContractsSorting'
 *     restoreOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'restoreOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     restoreOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'restoreOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     restoreOneCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'restoreOneCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     restoreOneCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'restoreOneCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     restoreOneCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'restoreOneCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     restoreOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'restoreOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     restoreOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'restoreOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     restoreOneCompanyHistoriesPaging: // value for 'restoreOneCompanyHistoriesPaging'
 *     restoreOneCompanyHistoriesFilter: // value for 'restoreOneCompanyHistoriesFilter'
 *     restoreOneCompanyHistoriesSorting: // value for 'restoreOneCompanyHistoriesSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'restoreOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRestoreOneCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RestoreOneCompanyMutation,
        RestoreOneCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RestoreOneCompanyMutation,
          RestoreOneCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RestoreOneCompanyMutation,
    RestoreOneCompanyMutationVariables
  >(RestoreOneCompanyMutationDocument, options);
}
export type RestoreOneCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RestoreOneCompanyMutation,
    RestoreOneCompanyMutationVariables
  >;
export const SetContactOnCompanyMutationDocument = gql`
  mutation setContactOnCompanyMutation(
    $input: SetContactOnCompanyInput!
    $setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setContactOnCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $setContactOnCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setContactOnCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setContactOnCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setContactOnCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setContactOnCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setContactOnCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setContactOnCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setContactOnCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setContactOnCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setContactOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setContactOnCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setContactOnCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setContactOnCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setContactOnCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setContactOnCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setContactOnCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setContactOnCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setContactOnCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setContactOnCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setContactOnCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setContactOnCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setContactOnCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setContactOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnCompanyHistoriesPaging: OffsetPaging
    $setContactOnCompanyHistoriesFilter: ContactFilter
    $setContactOnCompanyHistoriesSorting: [ContactSort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setContactOnCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setContactOnCompanyContactContactContactedByContactedByContactsPaging
            filter: $setContactOnCompanyContactContactContactedByContactedByContactsFilter
            sorting: $setContactOnCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $setContactOnCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $setContactOnCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $setContactOnCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $setContactOnCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setContactOnCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setContactOnCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setContactOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setContactOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setContactOnCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $setContactOnCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setContactOnCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setContactOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setContactOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setContactOnCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $setContactOnCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setContactOnCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $setContactOnCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $setContactOnCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $setContactOnCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setContactOnCompanyCreatedByCreatedByContactsPaging
          filter: $setContactOnCompanyCreatedByCreatedByContactsFilter
          sorting: $setContactOnCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $setContactOnCompanyCreatedByCreatedByProjectChangesPaging
          filter: $setContactOnCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $setContactOnCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $setContactOnCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $setContactOnCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $setContactOnCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setContactOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setContactOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setContactOnCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $setContactOnCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $setContactOnCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setContactOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setContactOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setContactOnCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setContactOnCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setContactOnCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setContactOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setContactOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setContactOnCompanyHistoriesPaging
        filter: $setContactOnCompanyHistoriesFilter
        sorting: $setContactOnCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetContactOnCompanyMutation__
 *
 * To run a mutation, you first call `useSetContactOnCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetContactOnCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetContactOnCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnCompanyContactContactContactedByContactedByContactsPaging: // value for 'setContactOnCompanyContactContactContactedByContactedByContactsPaging'
 *     setContactOnCompanyContactContactContactedByContactedByContactsFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByContactsFilter'
 *     setContactOnCompanyContactContactContactedByContactedByContactsSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByContactsSorting'
 *     setContactOnCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'setContactOnCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     setContactOnCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     setContactOnCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setContactOnCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setContactOnCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setContactOnCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setContactOnCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setContactOnCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setContactOnCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setContactOnCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setContactOnCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setContactOnCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setContactOnCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setContactOnCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnCompanyCreatedByCreatedByContactsPaging: // value for 'setContactOnCompanyCreatedByCreatedByContactsPaging'
 *     setContactOnCompanyCreatedByCreatedByContactsFilter: // value for 'setContactOnCompanyCreatedByCreatedByContactsFilter'
 *     setContactOnCompanyCreatedByCreatedByContactsSorting: // value for 'setContactOnCompanyCreatedByCreatedByContactsSorting'
 *     setContactOnCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setContactOnCompanyCreatedByCreatedByProjectChangesPaging'
 *     setContactOnCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setContactOnCompanyCreatedByCreatedByProjectChangesFilter'
 *     setContactOnCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setContactOnCompanyCreatedByCreatedByProjectChangesSorting'
 *     setContactOnCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setContactOnCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setContactOnCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setContactOnCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setContactOnCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setContactOnCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setContactOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setContactOnCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setContactOnCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setContactOnCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setContactOnCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setContactOnCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setContactOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setContactOnCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setContactOnCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setContactOnCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setContactOnCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setContactOnCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setContactOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnCompanyHistoriesPaging: // value for 'setContactOnCompanyHistoriesPaging'
 *     setContactOnCompanyHistoriesFilter: // value for 'setContactOnCompanyHistoriesFilter'
 *     setContactOnCompanyHistoriesSorting: // value for 'setContactOnCompanyHistoriesSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetContactOnCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetContactOnCompanyMutation,
        SetContactOnCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetContactOnCompanyMutation,
          SetContactOnCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetContactOnCompanyMutation,
    SetContactOnCompanyMutationVariables
  >(SetContactOnCompanyMutationDocument, options);
}
export type SetContactOnCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetContactOnCompanyMutation,
    SetContactOnCompanyMutationVariables
  >;
export const SetCreatedByOnCompanyMutationDocument = gql`
  mutation setCreatedByOnCompanyMutation(
    $input: SetCreatedByOnCompanyInput!
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setCreatedByOnCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedByOnCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setCreatedByOnCompanyContactContactContactedByContactedByContactsPaging
            filter: $setCreatedByOnCompanyContactContactContactedByContactedByContactsFilter
            sorting: $setCreatedByOnCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnCompanyCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnCompanyCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $setCreatedByOnCompanyCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setCreatedByOnCompanyHistoriesPaging
        filter: $setCreatedByOnCompanyHistoriesFilter
        sorting: $setCreatedByOnCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedByOnCompanyMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnCompanyContactContactContactedByContactedByContactsPaging: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByContactsPaging'
 *     setCreatedByOnCompanyContactContactContactedByContactedByContactsFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByContactsFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByContactsSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByContactsSorting'
 *     setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnCompanyCreatedByCreatedByContactsPaging'
 *     setCreatedByOnCompanyCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByContactsFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByContactsSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnCompanyCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyHistoriesPaging: // value for 'setCreatedByOnCompanyHistoriesPaging'
 *     setCreatedByOnCompanyHistoriesFilter: // value for 'setCreatedByOnCompanyHistoriesFilter'
 *     setCreatedByOnCompanyHistoriesSorting: // value for 'setCreatedByOnCompanyHistoriesSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnCompanyMutation,
        SetCreatedByOnCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnCompanyMutation,
          SetCreatedByOnCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnCompanyMutation,
    SetCreatedByOnCompanyMutationVariables
  >(SetCreatedByOnCompanyMutationDocument, options);
}
export type SetCreatedByOnCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnCompanyMutation,
    SetCreatedByOnCompanyMutationVariables
  >;
export const AddHistoriesToCompanyMutationDocument = gql`
  mutation addHistoriesToCompanyMutation(
    $input: AddHistoriesToCompanyInput!
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $addHistoriesToCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $addHistoriesToCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $addHistoriesToCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $addHistoriesToCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $addHistoriesToCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $addHistoriesToCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $addHistoriesToCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $addHistoriesToCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addHistoriesToCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addHistoriesToCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addHistoriesToCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyHistoriesFilter: ContactFilter
    $addHistoriesToCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addHistoriesToCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $addHistoriesToCompanyContactContactContactedByContactedByContactsPaging
            filter: $addHistoriesToCompanyContactContactContactedByContactedByContactsFilter
            sorting: $addHistoriesToCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $addHistoriesToCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $addHistoriesToCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $addHistoriesToCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addHistoriesToCompanyCreatedByCreatedByContactsPaging
          filter: $addHistoriesToCompanyCreatedByCreatedByContactsFilter
          sorting: $addHistoriesToCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $addHistoriesToCompanyCreatedByCreatedByProjectChangesPaging
          filter: $addHistoriesToCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $addHistoriesToCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $addHistoriesToCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $addHistoriesToCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $addHistoriesToCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addHistoriesToCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addHistoriesToCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addHistoriesToCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $addHistoriesToCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $addHistoriesToCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addHistoriesToCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addHistoriesToCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $addHistoriesToCompanyHistoriesPaging
        filter: $addHistoriesToCompanyHistoriesFilter
        sorting: $addHistoriesToCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAddHistoriesToCompanyMutation__
 *
 * To run a mutation, you first call `useAddHistoriesToCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddHistoriesToCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddHistoriesToCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToCompanyContactContactContactedByContactedByContactsPaging: // value for 'addHistoriesToCompanyContactContactContactedByContactedByContactsPaging'
 *     addHistoriesToCompanyContactContactContactedByContactedByContactsFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByContactsFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByContactsSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByContactsSorting'
 *     addHistoriesToCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'addHistoriesToCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     addHistoriesToCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToCompanyCreatedByCreatedByContactsPaging: // value for 'addHistoriesToCompanyCreatedByCreatedByContactsPaging'
 *     addHistoriesToCompanyCreatedByCreatedByContactsFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByContactsFilter'
 *     addHistoriesToCompanyCreatedByCreatedByContactsSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByContactsSorting'
 *     addHistoriesToCompanyCreatedByCreatedByProjectChangesPaging: // value for 'addHistoriesToCompanyCreatedByCreatedByProjectChangesPaging'
 *     addHistoriesToCompanyCreatedByCreatedByProjectChangesFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByProjectChangesFilter'
 *     addHistoriesToCompanyCreatedByCreatedByProjectChangesSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByProjectChangesSorting'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedContractsPaging'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedContractsFilter'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedContractsSorting'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyHistoriesPaging: // value for 'addHistoriesToCompanyHistoriesPaging'
 *     addHistoriesToCompanyHistoriesFilter: // value for 'addHistoriesToCompanyHistoriesFilter'
 *     addHistoriesToCompanyHistoriesSorting: // value for 'addHistoriesToCompanyHistoriesSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddHistoriesToCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddHistoriesToCompanyMutation,
        AddHistoriesToCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddHistoriesToCompanyMutation,
          AddHistoriesToCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddHistoriesToCompanyMutation,
    AddHistoriesToCompanyMutationVariables
  >(AddHistoriesToCompanyMutationDocument, options);
}
export type AddHistoriesToCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddHistoriesToCompanyMutation,
    AddHistoriesToCompanyMutationVariables
  >;
export const SetHistoriesOnCompanyMutationDocument = gql`
  mutation setHistoriesOnCompanyMutation(
    $input: SetHistoriesOnCompanyInput!
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setHistoriesOnCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setHistoriesOnCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setHistoriesOnCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setHistoriesOnCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setHistoriesOnCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setHistoriesOnCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setHistoriesOnCompanyContactContactContactedByContactedByContactsPaging
            filter: $setHistoriesOnCompanyContactContactContactedByContactedByContactsFilter
            sorting: $setHistoriesOnCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setHistoriesOnCompanyCreatedByCreatedByContactsPaging
          filter: $setHistoriesOnCompanyCreatedByCreatedByContactsFilter
          sorting: $setHistoriesOnCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $setHistoriesOnCompanyCreatedByCreatedByProjectChangesPaging
          filter: $setHistoriesOnCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $setHistoriesOnCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setHistoriesOnCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $setHistoriesOnCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $setHistoriesOnCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setHistoriesOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setHistoriesOnCompanyHistoriesPaging
        filter: $setHistoriesOnCompanyHistoriesFilter
        sorting: $setHistoriesOnCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetHistoriesOnCompanyMutation__
 *
 * To run a mutation, you first call `useSetHistoriesOnCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetHistoriesOnCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetHistoriesOnCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnCompanyContactContactContactedByContactedByContactsPaging: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByContactsPaging'
 *     setHistoriesOnCompanyContactContactContactedByContactedByContactsFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByContactsFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByContactsSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByContactsSorting'
 *     setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByContactsPaging: // value for 'setHistoriesOnCompanyCreatedByCreatedByContactsPaging'
 *     setHistoriesOnCompanyCreatedByCreatedByContactsFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByContactsFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByContactsSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByContactsSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setHistoriesOnCompanyCreatedByCreatedByProjectChangesPaging'
 *     setHistoriesOnCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByProjectChangesFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByProjectChangesSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyHistoriesPaging: // value for 'setHistoriesOnCompanyHistoriesPaging'
 *     setHistoriesOnCompanyHistoriesFilter: // value for 'setHistoriesOnCompanyHistoriesFilter'
 *     setHistoriesOnCompanyHistoriesSorting: // value for 'setHistoriesOnCompanyHistoriesSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetHistoriesOnCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetHistoriesOnCompanyMutation,
        SetHistoriesOnCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetHistoriesOnCompanyMutation,
          SetHistoriesOnCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetHistoriesOnCompanyMutation,
    SetHistoriesOnCompanyMutationVariables
  >(SetHistoriesOnCompanyMutationDocument, options);
}
export type SetHistoriesOnCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetHistoriesOnCompanyMutation,
    SetHistoriesOnCompanyMutationVariables
  >;
export const RemoveContactFromCompanyMutationDocument = gql`
  mutation removeContactFromCompanyMutation(
    $input: RemoveContactFromCompanyInput!
    $removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeContactFromCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeContactFromCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeContactFromCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeContactFromCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeContactFromCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeContactFromCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeContactFromCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeContactFromCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeContactFromCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeContactFromCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeContactFromCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeContactFromCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeContactFromCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeContactFromCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeContactFromCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromCompanyHistoriesPaging: OffsetPaging
    $removeContactFromCompanyHistoriesFilter: ContactFilter
    $removeContactFromCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeContactFromCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeContactFromCompanyContactContactContactedByContactedByContactsPaging
            filter: $removeContactFromCompanyContactContactContactedByContactedByContactsFilter
            sorting: $removeContactFromCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $removeContactFromCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $removeContactFromCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeContactFromCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeContactFromCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeContactFromCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeContactFromCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeContactFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeContactFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeContactFromCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeContactFromCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeContactFromCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $removeContactFromCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $removeContactFromCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeContactFromCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeContactFromCompanyCreatedByCreatedByContactsPaging
          filter: $removeContactFromCompanyCreatedByCreatedByContactsFilter
          sorting: $removeContactFromCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $removeContactFromCompanyCreatedByCreatedByProjectChangesPaging
          filter: $removeContactFromCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $removeContactFromCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $removeContactFromCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $removeContactFromCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeContactFromCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeContactFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeContactFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeContactFromCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $removeContactFromCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $removeContactFromCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeContactFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeContactFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeContactFromCompanyHistoriesPaging
        filter: $removeContactFromCompanyHistoriesFilter
        sorting: $removeContactFromCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveContactFromCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveContactFromCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactFromCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveContactFromCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromCompanyContactContactContactedByContactedByContactsPaging: // value for 'removeContactFromCompanyContactContactContactedByContactedByContactsPaging'
 *     removeContactFromCompanyContactContactContactedByContactedByContactsFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByContactsFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByContactsSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByContactsSorting'
 *     removeContactFromCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'removeContactFromCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     removeContactFromCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeContactFromCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeContactFromCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeContactFromCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeContactFromCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeContactFromCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromCompanyCreatedByCreatedByContactsPaging: // value for 'removeContactFromCompanyCreatedByCreatedByContactsPaging'
 *     removeContactFromCompanyCreatedByCreatedByContactsFilter: // value for 'removeContactFromCompanyCreatedByCreatedByContactsFilter'
 *     removeContactFromCompanyCreatedByCreatedByContactsSorting: // value for 'removeContactFromCompanyCreatedByCreatedByContactsSorting'
 *     removeContactFromCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeContactFromCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeContactFromCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeContactFromCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeContactFromCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeContactFromCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeContactFromCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeContactFromCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeContactFromCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeContactFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeContactFromCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeContactFromCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeContactFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyHistoriesPaging: // value for 'removeContactFromCompanyHistoriesPaging'
 *     removeContactFromCompanyHistoriesFilter: // value for 'removeContactFromCompanyHistoriesFilter'
 *     removeContactFromCompanyHistoriesSorting: // value for 'removeContactFromCompanyHistoriesSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveContactFromCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveContactFromCompanyMutation,
        RemoveContactFromCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveContactFromCompanyMutation,
          RemoveContactFromCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveContactFromCompanyMutation,
    RemoveContactFromCompanyMutationVariables
  >(RemoveContactFromCompanyMutationDocument, options);
}
export type RemoveContactFromCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveContactFromCompanyMutation,
    RemoveContactFromCompanyMutationVariables
  >;
export const RemoveCreatedByFromCompanyMutationDocument = gql`
  mutation removeCreatedByFromCompanyMutation(
    $input: RemoveCreatedByFromCompanyInput!
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeCreatedByFromCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedByFromCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeCreatedByFromCompanyContactContactContactedByContactedByContactsPaging
            filter: $removeCreatedByFromCompanyContactContactContactedByContactedByContactsFilter
            sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromCompanyCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromCompanyCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $removeCreatedByFromCompanyCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeCreatedByFromCompanyHistoriesPaging
        filter: $removeCreatedByFromCompanyHistoriesFilter
        sorting: $removeCreatedByFromCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByContactsPaging: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByContactsPaging'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByContactsFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByContactsFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByContactsSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByContactsSorting'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromCompanyCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromCompanyCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyHistoriesPaging: // value for 'removeCreatedByFromCompanyHistoriesPaging'
 *     removeCreatedByFromCompanyHistoriesFilter: // value for 'removeCreatedByFromCompanyHistoriesFilter'
 *     removeCreatedByFromCompanyHistoriesSorting: // value for 'removeCreatedByFromCompanyHistoriesSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromCompanyMutation,
        RemoveCreatedByFromCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromCompanyMutation,
          RemoveCreatedByFromCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromCompanyMutation,
    RemoveCreatedByFromCompanyMutationVariables
  >(RemoveCreatedByFromCompanyMutationDocument, options);
}
export type RemoveCreatedByFromCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromCompanyMutation,
    RemoveCreatedByFromCompanyMutationVariables
  >;
export const RemoveHistoriesFromCompanyMutationDocument = gql`
  mutation removeHistoriesFromCompanyMutation(
    $input: RemoveHistoriesFromCompanyInput!
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeHistoriesFromCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeHistoriesFromCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeHistoriesFromCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeHistoriesFromCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeHistoriesFromCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeHistoriesFromCompanyContactContactContactedByContactedByContactsPaging
            filter: $removeHistoriesFromCompanyContactContactContactedByContactedByContactsFilter
            sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeHistoriesFromCompanyCreatedByCreatedByContactsPaging
          filter: $removeHistoriesFromCompanyCreatedByCreatedByContactsFilter
          sorting: $removeHistoriesFromCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $removeHistoriesFromCompanyCreatedByCreatedByProjectChangesPaging
          filter: $removeHistoriesFromCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $removeHistoriesFromCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeHistoriesFromCompanyHistoriesPaging
        filter: $removeHistoriesFromCompanyHistoriesFilter
        sorting: $removeHistoriesFromCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveHistoriesFromCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveHistoriesFromCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHistoriesFromCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveHistoriesFromCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByContactsPaging: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByContactsPaging'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByContactsFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByContactsFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByContactsSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByContactsSorting'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByContactsPaging: // value for 'removeHistoriesFromCompanyCreatedByCreatedByContactsPaging'
 *     removeHistoriesFromCompanyCreatedByCreatedByContactsFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByContactsFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByContactsSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByContactsSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeHistoriesFromCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeHistoriesFromCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyHistoriesPaging: // value for 'removeHistoriesFromCompanyHistoriesPaging'
 *     removeHistoriesFromCompanyHistoriesFilter: // value for 'removeHistoriesFromCompanyHistoriesFilter'
 *     removeHistoriesFromCompanyHistoriesSorting: // value for 'removeHistoriesFromCompanyHistoriesSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveHistoriesFromCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveHistoriesFromCompanyMutation,
        RemoveHistoriesFromCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveHistoriesFromCompanyMutation,
          RemoveHistoriesFromCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveHistoriesFromCompanyMutation,
    RemoveHistoriesFromCompanyMutationVariables
  >(RemoveHistoriesFromCompanyMutationDocument, options);
}
export type RemoveHistoriesFromCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveHistoriesFromCompanyMutation,
    RemoveHistoriesFromCompanyMutationVariables
  >;
export const CreateOneCompanyMutationDocument = gql`
  mutation createOneCompanyMutation(
    $input: CreateOneCompanyInput!
    $createOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createOneCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createOneCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createOneCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $createOneCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $createOneCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $createOneCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $createOneCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $createOneCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createOneCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createOneCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createOneCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createOneCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $createOneCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createOneCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createOneCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createOneCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createOneCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createOneCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $createOneCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $createOneCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $createOneCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createOneCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createOneCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createOneCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createOneCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createOneCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createOneCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createOneCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createOneCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneCompanyHistoriesPaging: OffsetPaging
    $createOneCompanyHistoriesFilter: ContactFilter
    $createOneCompanyHistoriesSorting: [ContactSort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createOneCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $createOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $createOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $createOneCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $createOneCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $createOneCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $createOneCompanyContactContactContactedByContactedByContactsPaging
            filter: $createOneCompanyContactContactContactedByContactedByContactsFilter
            sorting: $createOneCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $createOneCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $createOneCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $createOneCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $createOneCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $createOneCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $createOneCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createOneCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $createOneCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $createOneCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $createOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $createOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createOneCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $createOneCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createOneCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $createOneCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $createOneCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $createOneCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createOneCompanyCreatedByCreatedByContactsPaging
          filter: $createOneCompanyCreatedByCreatedByContactsFilter
          sorting: $createOneCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $createOneCompanyCreatedByCreatedByProjectChangesPaging
          filter: $createOneCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $createOneCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $createOneCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $createOneCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $createOneCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createOneCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $createOneCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $createOneCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createOneCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createOneCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createOneCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $createOneCompanyHistoriesPaging
        filter: $createOneCompanyHistoriesFilter
        sorting: $createOneCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateOneCompanyMutation__
 *
 * To run a mutation, you first call `useCreateOneCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'createOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     createOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'createOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     createOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'createOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     createOneCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'createOneCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     createOneCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'createOneCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     createOneCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'createOneCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneCompanyContactContactContactedByContactedByContactsPaging: // value for 'createOneCompanyContactContactContactedByContactedByContactsPaging'
 *     createOneCompanyContactContactContactedByContactedByContactsFilter: // value for 'createOneCompanyContactContactContactedByContactedByContactsFilter'
 *     createOneCompanyContactContactContactedByContactedByContactsSorting: // value for 'createOneCompanyContactContactContactedByContactedByContactsSorting'
 *     createOneCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'createOneCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     createOneCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'createOneCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     createOneCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'createOneCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     createOneCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'createOneCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     createOneCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'createOneCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     createOneCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'createOneCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     createOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'createOneCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     createOneCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'createOneCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     createOneCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'createOneCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     createOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'createOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     createOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'createOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     createOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'createOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     createOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'createOneCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     createOneCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'createOneCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     createOneCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'createOneCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     createOneCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'createOneCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     createOneCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'createOneCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     createOneCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'createOneCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneCompanyCreatedByCreatedByContactsPaging: // value for 'createOneCompanyCreatedByCreatedByContactsPaging'
 *     createOneCompanyCreatedByCreatedByContactsFilter: // value for 'createOneCompanyCreatedByCreatedByContactsFilter'
 *     createOneCompanyCreatedByCreatedByContactsSorting: // value for 'createOneCompanyCreatedByCreatedByContactsSorting'
 *     createOneCompanyCreatedByCreatedByProjectChangesPaging: // value for 'createOneCompanyCreatedByCreatedByProjectChangesPaging'
 *     createOneCompanyCreatedByCreatedByProjectChangesFilter: // value for 'createOneCompanyCreatedByCreatedByProjectChangesFilter'
 *     createOneCompanyCreatedByCreatedByProjectChangesSorting: // value for 'createOneCompanyCreatedByCreatedByProjectChangesSorting'
 *     createOneCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'createOneCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     createOneCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'createOneCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     createOneCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'createOneCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     createOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'createOneCompanyCreatedByCreatedByCreatedContractsPaging'
 *     createOneCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'createOneCompanyCreatedByCreatedByCreatedContractsFilter'
 *     createOneCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'createOneCompanyCreatedByCreatedByCreatedContractsSorting'
 *     createOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createOneCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     createOneCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createOneCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     createOneCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createOneCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     createOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneCompanyHistoriesPaging: // value for 'createOneCompanyHistoriesPaging'
 *     createOneCompanyHistoriesFilter: // value for 'createOneCompanyHistoriesFilter'
 *     createOneCompanyHistoriesSorting: // value for 'createOneCompanyHistoriesSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneCompanyMutation,
        CreateOneCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneCompanyMutation,
          CreateOneCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneCompanyMutation,
    CreateOneCompanyMutationVariables
  >(CreateOneCompanyMutationDocument, options);
}
export type CreateOneCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneCompanyMutation,
    CreateOneCompanyMutationVariables
  >;
export const CreateManyCompaniesMutationDocument = gql`
  mutation createManyCompaniesMutation(
    $input: CreateManyCompaniesInput!
    $createManyCompaniesContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createManyCompaniesContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createManyCompaniesContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createManyCompaniesContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createManyCompaniesContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createManyCompaniesContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyCompaniesContactContactContactedByContactedByContactsPaging: OffsetPaging
    $createManyCompaniesContactContactContactedByContactedByContactsFilter: ContactFilter
    $createManyCompaniesContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $createManyCompaniesContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $createManyCompaniesContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $createManyCompaniesContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createManyCompaniesContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createManyCompaniesContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createManyCompaniesContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createManyCompaniesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyCompaniesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyCompaniesContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createManyCompaniesContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $createManyCompaniesContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createManyCompaniesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyCompaniesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyCompaniesCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createManyCompaniesCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createManyCompaniesCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createManyCompaniesCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createManyCompaniesCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createManyCompaniesCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyCompaniesCreatedByCreatedByContactsPaging: OffsetPaging
    $createManyCompaniesCreatedByCreatedByContactsFilter: ContactFilter
    $createManyCompaniesCreatedByCreatedByContactsSorting: [ContactSort!]
    $createManyCompaniesCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createManyCompaniesCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createManyCompaniesCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createManyCompaniesCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createManyCompaniesCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createManyCompaniesCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createManyCompaniesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyCompaniesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyCompaniesCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createManyCompaniesCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createManyCompaniesCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createManyCompaniesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyCompaniesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyCompaniesCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createManyCompaniesCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyCompaniesCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyCompaniesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyCompaniesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyCompaniesHistoriesPaging: OffsetPaging
    $createManyCompaniesHistoriesFilter: ContactFilter
    $createManyCompaniesHistoriesSorting: [ContactSort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createManyCompanies(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createManyCompaniesContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $createManyCompaniesContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $createManyCompaniesContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $createManyCompaniesContactContactContactedByContactedByCreatedProjectsPaging
            filter: $createManyCompaniesContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $createManyCompaniesContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $createManyCompaniesContactContactContactedByContactedByContactsPaging
            filter: $createManyCompaniesContactContactContactedByContactedByContactsFilter
            sorting: $createManyCompaniesContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $createManyCompaniesContactContactContactedByContactedByProjectChangesPaging
            filter: $createManyCompaniesContactContactContactedByContactedByProjectChangesFilter
            sorting: $createManyCompaniesContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $createManyCompaniesContactContactContactedByContactedByCreatedWorkersPaging
            filter: $createManyCompaniesContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $createManyCompaniesContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createManyCompaniesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createManyCompaniesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createManyCompaniesContactContactContactedByContactedByCreatedContractsPaging
            filter: $createManyCompaniesContactContactContactedByContactedByCreatedContractsFilter
            sorting: $createManyCompaniesContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createManyCompaniesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createManyCompaniesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createManyCompaniesCreatedByCreatedByCreatedCompaniesPaging
          filter: $createManyCompaniesCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createManyCompaniesCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $createManyCompaniesCreatedByCreatedByCreatedProjectsPaging
          filter: $createManyCompaniesCreatedByCreatedByCreatedProjectsFilter
          sorting: $createManyCompaniesCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createManyCompaniesCreatedByCreatedByContactsPaging
          filter: $createManyCompaniesCreatedByCreatedByContactsFilter
          sorting: $createManyCompaniesCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $createManyCompaniesCreatedByCreatedByProjectChangesPaging
          filter: $createManyCompaniesCreatedByCreatedByProjectChangesFilter
          sorting: $createManyCompaniesCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $createManyCompaniesCreatedByCreatedByCreatedWorkersPaging
          filter: $createManyCompaniesCreatedByCreatedByCreatedWorkersFilter
          sorting: $createManyCompaniesCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createManyCompaniesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createManyCompaniesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createManyCompaniesCreatedByCreatedByCreatedContractsPaging
          filter: $createManyCompaniesCreatedByCreatedByCreatedContractsFilter
          sorting: $createManyCompaniesCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createManyCompaniesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createManyCompaniesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createManyCompaniesCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createManyCompaniesCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createManyCompaniesCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createManyCompaniesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createManyCompaniesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $createManyCompaniesHistoriesPaging
        filter: $createManyCompaniesHistoriesFilter
        sorting: $createManyCompaniesHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateManyCompaniesMutation__
 *
 * To run a mutation, you first call `useCreateManyCompaniesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyCompaniesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyCompaniesMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyCompaniesContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedCompaniesPaging'
 *     createManyCompaniesContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedCompaniesFilter'
 *     createManyCompaniesContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedCompaniesSorting'
 *     createManyCompaniesContactContactContactedByContactedByCreatedProjectsPaging: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedProjectsPaging'
 *     createManyCompaniesContactContactContactedByContactedByCreatedProjectsFilter: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedProjectsFilter'
 *     createManyCompaniesContactContactContactedByContactedByCreatedProjectsSorting: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedProjectsSorting'
 *     createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyCompaniesContactContactContactedByContactedByContactsPaging: // value for 'createManyCompaniesContactContactContactedByContactedByContactsPaging'
 *     createManyCompaniesContactContactContactedByContactedByContactsFilter: // value for 'createManyCompaniesContactContactContactedByContactedByContactsFilter'
 *     createManyCompaniesContactContactContactedByContactedByContactsSorting: // value for 'createManyCompaniesContactContactContactedByContactedByContactsSorting'
 *     createManyCompaniesContactContactContactedByContactedByProjectChangesPaging: // value for 'createManyCompaniesContactContactContactedByContactedByProjectChangesPaging'
 *     createManyCompaniesContactContactContactedByContactedByProjectChangesFilter: // value for 'createManyCompaniesContactContactContactedByContactedByProjectChangesFilter'
 *     createManyCompaniesContactContactContactedByContactedByProjectChangesSorting: // value for 'createManyCompaniesContactContactContactedByContactedByProjectChangesSorting'
 *     createManyCompaniesContactContactContactedByContactedByCreatedWorkersPaging: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedWorkersPaging'
 *     createManyCompaniesContactContactContactedByContactedByCreatedWorkersFilter: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedWorkersFilter'
 *     createManyCompaniesContactContactContactedByContactedByCreatedWorkersSorting: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedWorkersSorting'
 *     createManyCompaniesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyCompaniesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyCompaniesContactContactContactedByContactedByCreatedContractsPaging: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedContractsPaging'
 *     createManyCompaniesContactContactContactedByContactedByCreatedContractsFilter: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedContractsFilter'
 *     createManyCompaniesContactContactContactedByContactedByCreatedContractsSorting: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedContractsSorting'
 *     createManyCompaniesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyCompaniesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyCompaniesContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyCompaniesCreatedByCreatedByCreatedCompaniesPaging: // value for 'createManyCompaniesCreatedByCreatedByCreatedCompaniesPaging'
 *     createManyCompaniesCreatedByCreatedByCreatedCompaniesFilter: // value for 'createManyCompaniesCreatedByCreatedByCreatedCompaniesFilter'
 *     createManyCompaniesCreatedByCreatedByCreatedCompaniesSorting: // value for 'createManyCompaniesCreatedByCreatedByCreatedCompaniesSorting'
 *     createManyCompaniesCreatedByCreatedByCreatedProjectsPaging: // value for 'createManyCompaniesCreatedByCreatedByCreatedProjectsPaging'
 *     createManyCompaniesCreatedByCreatedByCreatedProjectsFilter: // value for 'createManyCompaniesCreatedByCreatedByCreatedProjectsFilter'
 *     createManyCompaniesCreatedByCreatedByCreatedProjectsSorting: // value for 'createManyCompaniesCreatedByCreatedByCreatedProjectsSorting'
 *     createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyCompaniesCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyCompaniesCreatedByCreatedByContactsPaging: // value for 'createManyCompaniesCreatedByCreatedByContactsPaging'
 *     createManyCompaniesCreatedByCreatedByContactsFilter: // value for 'createManyCompaniesCreatedByCreatedByContactsFilter'
 *     createManyCompaniesCreatedByCreatedByContactsSorting: // value for 'createManyCompaniesCreatedByCreatedByContactsSorting'
 *     createManyCompaniesCreatedByCreatedByProjectChangesPaging: // value for 'createManyCompaniesCreatedByCreatedByProjectChangesPaging'
 *     createManyCompaniesCreatedByCreatedByProjectChangesFilter: // value for 'createManyCompaniesCreatedByCreatedByProjectChangesFilter'
 *     createManyCompaniesCreatedByCreatedByProjectChangesSorting: // value for 'createManyCompaniesCreatedByCreatedByProjectChangesSorting'
 *     createManyCompaniesCreatedByCreatedByCreatedWorkersPaging: // value for 'createManyCompaniesCreatedByCreatedByCreatedWorkersPaging'
 *     createManyCompaniesCreatedByCreatedByCreatedWorkersFilter: // value for 'createManyCompaniesCreatedByCreatedByCreatedWorkersFilter'
 *     createManyCompaniesCreatedByCreatedByCreatedWorkersSorting: // value for 'createManyCompaniesCreatedByCreatedByCreatedWorkersSorting'
 *     createManyCompaniesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyCompaniesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyCompaniesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyCompaniesCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyCompaniesCreatedByCreatedByCreatedContractsPaging: // value for 'createManyCompaniesCreatedByCreatedByCreatedContractsPaging'
 *     createManyCompaniesCreatedByCreatedByCreatedContractsFilter: // value for 'createManyCompaniesCreatedByCreatedByCreatedContractsFilter'
 *     createManyCompaniesCreatedByCreatedByCreatedContractsSorting: // value for 'createManyCompaniesCreatedByCreatedByCreatedContractsSorting'
 *     createManyCompaniesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyCompaniesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyCompaniesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyCompaniesCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyCompaniesCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createManyCompaniesCreatedByCreatedByCreatedMiddlemansPaging'
 *     createManyCompaniesCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createManyCompaniesCreatedByCreatedByCreatedMiddlemansFilter'
 *     createManyCompaniesCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createManyCompaniesCreatedByCreatedByCreatedMiddlemansSorting'
 *     createManyCompaniesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyCompaniesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyCompaniesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyCompaniesCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyCompaniesHistoriesPaging: // value for 'createManyCompaniesHistoriesPaging'
 *     createManyCompaniesHistoriesFilter: // value for 'createManyCompaniesHistoriesFilter'
 *     createManyCompaniesHistoriesSorting: // value for 'createManyCompaniesHistoriesSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyCompaniesHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyCompaniesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyCompaniesMutation,
        CreateManyCompaniesMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyCompaniesMutation,
          CreateManyCompaniesMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyCompaniesMutation,
    CreateManyCompaniesMutationVariables
  >(CreateManyCompaniesMutationDocument, options);
}
export type CreateManyCompaniesMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyCompaniesMutation,
    CreateManyCompaniesMutationVariables
  >;
export const UpdateOneCompanyMutationDocument = gql`
  mutation updateOneCompanyMutation(
    $input: UpdateOneCompanyInput!
    $updateOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $updateOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $updateOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $updateOneCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $updateOneCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $updateOneCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $updateOneCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $updateOneCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $updateOneCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $updateOneCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $updateOneCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $updateOneCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $updateOneCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $updateOneCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $updateOneCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $updateOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $updateOneCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $updateOneCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $updateOneCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $updateOneCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $updateOneCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $updateOneCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $updateOneCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $updateOneCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $updateOneCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $updateOneCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $updateOneCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $updateOneCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $updateOneCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $updateOneCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $updateOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneCompanyHistoriesPaging: OffsetPaging
    $updateOneCompanyHistoriesFilter: ContactFilter
    $updateOneCompanyHistoriesSorting: [ContactSort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    updateOneCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $updateOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $updateOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $updateOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $updateOneCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $updateOneCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $updateOneCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $updateOneCompanyContactContactContactedByContactedByContactsPaging
            filter: $updateOneCompanyContactContactContactedByContactedByContactsFilter
            sorting: $updateOneCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $updateOneCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $updateOneCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $updateOneCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $updateOneCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $updateOneCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $updateOneCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $updateOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $updateOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $updateOneCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $updateOneCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $updateOneCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $updateOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $updateOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $updateOneCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $updateOneCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $updateOneCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdProjects(
          paging: $updateOneCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $updateOneCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $updateOneCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $updateOneCompanyCreatedByCreatedByContactsPaging
          filter: $updateOneCompanyCreatedByCreatedByContactsFilter
          sorting: $updateOneCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        projectChanges(
          paging: $updateOneCompanyCreatedByCreatedByProjectChangesPaging
          filter: $updateOneCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $updateOneCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
          }
        }
        createdWorkers(
          paging: $updateOneCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $updateOneCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $updateOneCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $updateOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $updateOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $updateOneCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $updateOneCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $updateOneCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $updateOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $updateOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $updateOneCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $updateOneCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $updateOneCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $updateOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $updateOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $updateOneCompanyHistoriesPaging
        filter: $updateOneCompanyHistoriesFilter
        sorting: $updateOneCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useUpdateOneCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateOneCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     updateOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     updateOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     updateOneCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     updateOneCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     updateOneCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneCompanyContactContactContactedByContactedByContactsPaging: // value for 'updateOneCompanyContactContactContactedByContactedByContactsPaging'
 *     updateOneCompanyContactContactContactedByContactedByContactsFilter: // value for 'updateOneCompanyContactContactContactedByContactedByContactsFilter'
 *     updateOneCompanyContactContactContactedByContactedByContactsSorting: // value for 'updateOneCompanyContactContactContactedByContactedByContactsSorting'
 *     updateOneCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'updateOneCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     updateOneCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'updateOneCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     updateOneCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'updateOneCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     updateOneCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     updateOneCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     updateOneCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     updateOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     updateOneCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     updateOneCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     updateOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'updateOneCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     updateOneCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'updateOneCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     updateOneCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'updateOneCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     updateOneCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'updateOneCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     updateOneCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'updateOneCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     updateOneCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'updateOneCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneCompanyCreatedByCreatedByContactsPaging: // value for 'updateOneCompanyCreatedByCreatedByContactsPaging'
 *     updateOneCompanyCreatedByCreatedByContactsFilter: // value for 'updateOneCompanyCreatedByCreatedByContactsFilter'
 *     updateOneCompanyCreatedByCreatedByContactsSorting: // value for 'updateOneCompanyCreatedByCreatedByContactsSorting'
 *     updateOneCompanyCreatedByCreatedByProjectChangesPaging: // value for 'updateOneCompanyCreatedByCreatedByProjectChangesPaging'
 *     updateOneCompanyCreatedByCreatedByProjectChangesFilter: // value for 'updateOneCompanyCreatedByCreatedByProjectChangesFilter'
 *     updateOneCompanyCreatedByCreatedByProjectChangesSorting: // value for 'updateOneCompanyCreatedByCreatedByProjectChangesSorting'
 *     updateOneCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'updateOneCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     updateOneCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'updateOneCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     updateOneCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'updateOneCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     updateOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'updateOneCompanyCreatedByCreatedByCreatedContractsPaging'
 *     updateOneCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'updateOneCompanyCreatedByCreatedByCreatedContractsFilter'
 *     updateOneCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'updateOneCompanyCreatedByCreatedByCreatedContractsSorting'
 *     updateOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'updateOneCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     updateOneCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'updateOneCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     updateOneCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'updateOneCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     updateOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneCompanyHistoriesPaging: // value for 'updateOneCompanyHistoriesPaging'
 *     updateOneCompanyHistoriesFilter: // value for 'updateOneCompanyHistoriesFilter'
 *     updateOneCompanyHistoriesSorting: // value for 'updateOneCompanyHistoriesSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneCompanyMutation,
        UpdateOneCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneCompanyMutation,
          UpdateOneCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneCompanyMutation,
    UpdateOneCompanyMutationVariables
  >(UpdateOneCompanyMutationDocument, options);
}
export type UpdateOneCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneCompanyMutation,
    UpdateOneCompanyMutationVariables
  >;
export const UpdateManyCompaniesMutationDocument = gql`
  mutation updateManyCompaniesMutation($input: UpdateManyCompaniesInput!) {
    updateManyCompanies(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyCompaniesMutation__
 *
 * To run a mutation, you first call `useUpdateManyCompaniesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyCompaniesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyCompaniesMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyCompaniesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyCompaniesMutation,
        UpdateManyCompaniesMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyCompaniesMutation,
          UpdateManyCompaniesMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyCompaniesMutation,
    UpdateManyCompaniesMutationVariables
  >(UpdateManyCompaniesMutationDocument, options);
}
export type UpdateManyCompaniesMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyCompaniesMutation,
    UpdateManyCompaniesMutationVariables
  >;
export const DeleteOneCompanyMutationDocument = gql`
  mutation deleteOneCompanyMutation($input: DeleteOneCompanyInput!) {
    deleteOneCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
    }
  }
`;

/**
 * __useDeleteOneCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteOneCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneCompanyMutation,
        DeleteOneCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneCompanyMutation,
          DeleteOneCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneCompanyMutation,
    DeleteOneCompanyMutationVariables
  >(DeleteOneCompanyMutationDocument, options);
}
export type DeleteOneCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneCompanyMutation,
    DeleteOneCompanyMutationVariables
  >;
export const DeleteManyCompaniesMutationDocument = gql`
  mutation deleteManyCompaniesMutation($input: DeleteManyCompaniesInput!) {
    deleteManyCompanies(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyCompaniesMutation__
 *
 * To run a mutation, you first call `useDeleteManyCompaniesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCompaniesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyCompaniesMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyCompaniesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyCompaniesMutation,
        DeleteManyCompaniesMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyCompaniesMutation,
          DeleteManyCompaniesMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyCompaniesMutation,
    DeleteManyCompaniesMutationVariables
  >(DeleteManyCompaniesMutationDocument, options);
}
export type DeleteManyCompaniesMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyCompaniesMutation,
    DeleteManyCompaniesMutationVariables
  >;
export const SetContactOnCompanyPilverMutationDocument = gql`
  mutation setContactOnCompanyPilverMutation(
    $input: SetContactOnCompanyPilverInput!
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $setContactOnCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setContactOnCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setContactOnCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setContactOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnCompanyPilverHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesFilter: ContactPilverFilter
    $setContactOnCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setContactOnCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setContactOnCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $setContactOnCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $setContactOnCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setContactOnCompanyPilverCreatedByCreatedByContactsPaging
          filter: $setContactOnCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $setContactOnCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setContactOnCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $setContactOnCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $setContactOnCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setContactOnCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $setContactOnCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $setContactOnCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setContactOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setContactOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setContactOnCompanyPilverHistoriesPaging
        filter: $setContactOnCompanyPilverHistoriesFilter
        sorting: $setContactOnCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetContactOnCompanyPilverMutation__
 *
 * To run a mutation, you first call `useSetContactOnCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetContactOnCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetContactOnCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByContactsPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByContactsPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByContactsFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByContactsFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByContactsSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByContactsSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnCompanyPilverHistoriesPaging: // value for 'setContactOnCompanyPilverHistoriesPaging'
 *     setContactOnCompanyPilverHistoriesFilter: // value for 'setContactOnCompanyPilverHistoriesFilter'
 *     setContactOnCompanyPilverHistoriesSorting: // value for 'setContactOnCompanyPilverHistoriesSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetContactOnCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetContactOnCompanyPilverMutation,
        SetContactOnCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetContactOnCompanyPilverMutation,
          SetContactOnCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetContactOnCompanyPilverMutation,
    SetContactOnCompanyPilverMutationVariables
  >(SetContactOnCompanyPilverMutationDocument, options);
}
export type SetContactOnCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetContactOnCompanyPilverMutation,
    SetContactOnCompanyPilverMutationVariables
  >;
export const SetCreatedByOnCompanyPilverMutationDocument = gql`
  mutation setCreatedByOnCompanyPilverMutation(
    $input: SetCreatedByOnCompanyPilverInput!
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnCompanyPilverHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesFilter: ContactPilverFilter
    $setCreatedByOnCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedByOnCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnCompanyPilverCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setCreatedByOnCompanyPilverHistoriesPaging
        filter: $setCreatedByOnCompanyPilverHistoriesFilter
        sorting: $setCreatedByOnCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedByOnCompanyPilverMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByContactsPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByContactsFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByContactsSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyPilverHistoriesPaging: // value for 'setCreatedByOnCompanyPilverHistoriesPaging'
 *     setCreatedByOnCompanyPilverHistoriesFilter: // value for 'setCreatedByOnCompanyPilverHistoriesFilter'
 *     setCreatedByOnCompanyPilverHistoriesSorting: // value for 'setCreatedByOnCompanyPilverHistoriesSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnCompanyPilverMutation,
        SetCreatedByOnCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnCompanyPilverMutation,
          SetCreatedByOnCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnCompanyPilverMutation,
    SetCreatedByOnCompanyPilverMutationVariables
  >(SetCreatedByOnCompanyPilverMutationDocument, options);
}
export type SetCreatedByOnCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnCompanyPilverMutation,
    SetCreatedByOnCompanyPilverMutationVariables
  >;
export const AddHistoriesToCompanyPilverMutationDocument = gql`
  mutation addHistoriesToCompanyPilverMutation(
    $input: AddHistoriesToCompanyPilverInput!
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToCompanyPilverHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesFilter: ContactPilverFilter
    $addHistoriesToCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addHistoriesToCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addHistoriesToCompanyPilverCreatedByCreatedByContactsPaging
          filter: $addHistoriesToCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $addHistoriesToCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $addHistoriesToCompanyPilverHistoriesPaging
        filter: $addHistoriesToCompanyPilverHistoriesFilter
        sorting: $addHistoriesToCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAddHistoriesToCompanyPilverMutation__
 *
 * To run a mutation, you first call `useAddHistoriesToCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddHistoriesToCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddHistoriesToCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByContactsPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByContactsPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByContactsFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByContactsFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByContactsSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByContactsSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyPilverHistoriesPaging: // value for 'addHistoriesToCompanyPilverHistoriesPaging'
 *     addHistoriesToCompanyPilverHistoriesFilter: // value for 'addHistoriesToCompanyPilverHistoriesFilter'
 *     addHistoriesToCompanyPilverHistoriesSorting: // value for 'addHistoriesToCompanyPilverHistoriesSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddHistoriesToCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddHistoriesToCompanyPilverMutation,
        AddHistoriesToCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddHistoriesToCompanyPilverMutation,
          AddHistoriesToCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddHistoriesToCompanyPilverMutation,
    AddHistoriesToCompanyPilverMutationVariables
  >(AddHistoriesToCompanyPilverMutationDocument, options);
}
export type AddHistoriesToCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddHistoriesToCompanyPilverMutation,
    AddHistoriesToCompanyPilverMutationVariables
  >;
export const SetHistoriesOnCompanyPilverMutationDocument = gql`
  mutation setHistoriesOnCompanyPilverMutation(
    $input: SetHistoriesOnCompanyPilverInput!
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnCompanyPilverHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesFilter: ContactPilverFilter
    $setHistoriesOnCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setHistoriesOnCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setHistoriesOnCompanyPilverCreatedByCreatedByContactsPaging
          filter: $setHistoriesOnCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setHistoriesOnCompanyPilverHistoriesPaging
        filter: $setHistoriesOnCompanyPilverHistoriesFilter
        sorting: $setHistoriesOnCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetHistoriesOnCompanyPilverMutation__
 *
 * To run a mutation, you first call `useSetHistoriesOnCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetHistoriesOnCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetHistoriesOnCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByContactsPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByContactsPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByContactsFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByContactsFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByContactsSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByContactsSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyPilverHistoriesPaging: // value for 'setHistoriesOnCompanyPilverHistoriesPaging'
 *     setHistoriesOnCompanyPilverHistoriesFilter: // value for 'setHistoriesOnCompanyPilverHistoriesFilter'
 *     setHistoriesOnCompanyPilverHistoriesSorting: // value for 'setHistoriesOnCompanyPilverHistoriesSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetHistoriesOnCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetHistoriesOnCompanyPilverMutation,
        SetHistoriesOnCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetHistoriesOnCompanyPilverMutation,
          SetHistoriesOnCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetHistoriesOnCompanyPilverMutation,
    SetHistoriesOnCompanyPilverMutationVariables
  >(SetHistoriesOnCompanyPilverMutationDocument, options);
}
export type SetHistoriesOnCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetHistoriesOnCompanyPilverMutation,
    SetHistoriesOnCompanyPilverMutationVariables
  >;
export const RemoveContactFromCompanyPilverMutationDocument = gql`
  mutation removeContactFromCompanyPilverMutation(
    $input: RemoveContactFromCompanyPilverInput!
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $removeContactFromCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromCompanyPilverHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesFilter: ContactPilverFilter
    $removeContactFromCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeContactFromCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeContactFromCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $removeContactFromCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeContactFromCompanyPilverCreatedByCreatedByContactsPaging
          filter: $removeContactFromCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $removeContactFromCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeContactFromCompanyPilverHistoriesPaging
        filter: $removeContactFromCompanyPilverHistoriesFilter
        sorting: $removeContactFromCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveContactFromCompanyPilverMutation__
 *
 * To run a mutation, you first call `useRemoveContactFromCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactFromCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveContactFromCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByContactsPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByContactsPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByContactsFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByContactsFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByContactsSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByContactsSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyPilverHistoriesPaging: // value for 'removeContactFromCompanyPilverHistoriesPaging'
 *     removeContactFromCompanyPilverHistoriesFilter: // value for 'removeContactFromCompanyPilverHistoriesFilter'
 *     removeContactFromCompanyPilverHistoriesSorting: // value for 'removeContactFromCompanyPilverHistoriesSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveContactFromCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveContactFromCompanyPilverMutation,
        RemoveContactFromCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveContactFromCompanyPilverMutation,
          RemoveContactFromCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveContactFromCompanyPilverMutation,
    RemoveContactFromCompanyPilverMutationVariables
  >(RemoveContactFromCompanyPilverMutationDocument, options);
}
export type RemoveContactFromCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveContactFromCompanyPilverMutation,
    RemoveContactFromCompanyPilverMutationVariables
  >;
export const RemoveCreatedByFromCompanyPilverMutationDocument = gql`
  mutation removeCreatedByFromCompanyPilverMutation(
    $input: RemoveCreatedByFromCompanyPilverInput!
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromCompanyPilverHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesFilter: ContactPilverFilter
    $removeCreatedByFromCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedByFromCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeCreatedByFromCompanyPilverHistoriesPaging
        filter: $removeCreatedByFromCompanyPilverHistoriesFilter
        sorting: $removeCreatedByFromCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromCompanyPilverMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyPilverHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesPaging'
 *     removeCreatedByFromCompanyPilverHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesFilter'
 *     removeCreatedByFromCompanyPilverHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromCompanyPilverMutation,
        RemoveCreatedByFromCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromCompanyPilverMutation,
          RemoveCreatedByFromCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromCompanyPilverMutation,
    RemoveCreatedByFromCompanyPilverMutationVariables
  >(RemoveCreatedByFromCompanyPilverMutationDocument, options);
}
export type RemoveCreatedByFromCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromCompanyPilverMutation,
    RemoveCreatedByFromCompanyPilverMutationVariables
  >;
export const RemoveHistoriesFromCompanyPilverMutationDocument = gql`
  mutation removeHistoriesFromCompanyPilverMutation(
    $input: RemoveHistoriesFromCompanyPilverInput!
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromCompanyPilverHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesFilter: ContactPilverFilter
    $removeHistoriesFromCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeHistoriesFromCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsPaging
          filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeHistoriesFromCompanyPilverHistoriesPaging
        filter: $removeHistoriesFromCompanyPilverHistoriesFilter
        sorting: $removeHistoriesFromCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveHistoriesFromCompanyPilverMutation__
 *
 * To run a mutation, you first call `useRemoveHistoriesFromCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHistoriesFromCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveHistoriesFromCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByContactsPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByContactsPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByContactsFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByContactsFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByContactsSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByContactsSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyPilverHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesPaging'
 *     removeHistoriesFromCompanyPilverHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesFilter'
 *     removeHistoriesFromCompanyPilverHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveHistoriesFromCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveHistoriesFromCompanyPilverMutation,
        RemoveHistoriesFromCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveHistoriesFromCompanyPilverMutation,
          RemoveHistoriesFromCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveHistoriesFromCompanyPilverMutation,
    RemoveHistoriesFromCompanyPilverMutationVariables
  >(RemoveHistoriesFromCompanyPilverMutationDocument, options);
}
export type RemoveHistoriesFromCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveHistoriesFromCompanyPilverMutation,
    RemoveHistoriesFromCompanyPilverMutationVariables
  >;
export const CreateOneCompanyPilverMutationDocument = gql`
  mutation createOneCompanyPilverMutation(
    $input: CreateOneCompanyPilverInput!
    $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $createOneCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $createOneCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $createOneCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createOneCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $createOneCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createOneCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createOneCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createOneCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneCompanyPilverHistoriesPaging: OffsetPaging
    $createOneCompanyPilverHistoriesFilter: ContactPilverFilter
    $createOneCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createOneCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $createOneCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $createOneCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $createOneCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $createOneCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $createOneCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $createOneCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createOneCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $createOneCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $createOneCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createOneCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $createOneCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $createOneCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createOneCompanyPilverCreatedByCreatedByContactsPaging
          filter: $createOneCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $createOneCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createOneCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $createOneCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $createOneCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createOneCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $createOneCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $createOneCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createOneCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $createOneCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $createOneCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $createOneCompanyPilverHistoriesPaging
        filter: $createOneCompanyPilverHistoriesFilter
        sorting: $createOneCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateOneCompanyPilverMutation__
 *
 * To run a mutation, you first call `useCreateOneCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     createOneCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     createOneCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     createOneCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     createOneCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneCompanyPilverCreatedByCreatedByContactsPaging: // value for 'createOneCompanyPilverCreatedByCreatedByContactsPaging'
 *     createOneCompanyPilverCreatedByCreatedByContactsFilter: // value for 'createOneCompanyPilverCreatedByCreatedByContactsFilter'
 *     createOneCompanyPilverCreatedByCreatedByContactsSorting: // value for 'createOneCompanyPilverCreatedByCreatedByContactsSorting'
 *     createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'createOneCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     createOneCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'createOneCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     createOneCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'createOneCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     createOneCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     createOneCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     createOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     createOneCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     createOneCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     createOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneCompanyPilverHistoriesPaging: // value for 'createOneCompanyPilverHistoriesPaging'
 *     createOneCompanyPilverHistoriesFilter: // value for 'createOneCompanyPilverHistoriesFilter'
 *     createOneCompanyPilverHistoriesSorting: // value for 'createOneCompanyPilverHistoriesSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneCompanyPilverMutation,
        CreateOneCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneCompanyPilverMutation,
          CreateOneCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneCompanyPilverMutation,
    CreateOneCompanyPilverMutationVariables
  >(CreateOneCompanyPilverMutationDocument, options);
}
export type CreateOneCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneCompanyPilverMutation,
    CreateOneCompanyPilverMutationVariables
  >;
export const CreateManyCompanyPilversMutationDocument = gql`
  mutation createManyCompanyPilversMutation(
    $input: CreateManyCompanyPilversInput!
    $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyCompanyPilversContactContactContactedByContactedByContactsPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByContactsFilter: ContactFilter
    $createManyCompanyPilversContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyCompanyPilversContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $createManyCompanyPilversContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyCompanyPilversContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $createManyCompanyPilversContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createManyCompanyPilversContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyCompanyPilversContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyCompanyPilversCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createManyCompanyPilversCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyCompanyPilversCreatedByCreatedByContactsPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByContactsFilter: ContactFilter
    $createManyCompanyPilversCreatedByCreatedByContactsSorting: [ContactSort!]
    $createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyCompanyPilversCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createManyCompanyPilversCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyCompanyPilversCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createManyCompanyPilversCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createManyCompanyPilversCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyCompanyPilversCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyCompanyPilversCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createManyCompanyPilversCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createManyCompanyPilversCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyCompanyPilversCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyCompanyPilversHistoriesPaging: OffsetPaging
    $createManyCompanyPilversHistoriesFilter: ContactPilverFilter
    $createManyCompanyPilversHistoriesSorting: [ContactPilverSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createManyCompanyPilvers(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsPaging
            filter: $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $createManyCompanyPilversContactContactContactedByContactedByContactsPaging
            filter: $createManyCompanyPilversContactContactContactedByContactedByContactsFilter
            sorting: $createManyCompanyPilversContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $createManyCompanyPilversContactContactContactedByContactedByProjectChangesPaging
            filter: $createManyCompanyPilversContactContactContactedByContactedByProjectChangesFilter
            sorting: $createManyCompanyPilversContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersPaging
            filter: $createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createManyCompanyPilversContactContactContactedByContactedByCreatedContractsPaging
            filter: $createManyCompanyPilversContactContactContactedByContactedByCreatedContractsFilter
            sorting: $createManyCompanyPilversContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createManyCompanyPilversContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createManyCompanyPilversContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesPaging
          filter: $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createManyCompanyPilversCreatedByCreatedByCreatedProjectsPaging
          filter: $createManyCompanyPilversCreatedByCreatedByCreatedProjectsFilter
          sorting: $createManyCompanyPilversCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createManyCompanyPilversCreatedByCreatedByContactsPaging
          filter: $createManyCompanyPilversCreatedByCreatedByContactsFilter
          sorting: $createManyCompanyPilversCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createManyCompanyPilversCreatedByCreatedByProjectChangesPaging
          filter: $createManyCompanyPilversCreatedByCreatedByProjectChangesFilter
          sorting: $createManyCompanyPilversCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createManyCompanyPilversCreatedByCreatedByCreatedWorkersPaging
          filter: $createManyCompanyPilversCreatedByCreatedByCreatedWorkersFilter
          sorting: $createManyCompanyPilversCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createManyCompanyPilversCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createManyCompanyPilversCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createManyCompanyPilversCreatedByCreatedByCreatedContractsPaging
          filter: $createManyCompanyPilversCreatedByCreatedByCreatedContractsFilter
          sorting: $createManyCompanyPilversCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createManyCompanyPilversCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createManyCompanyPilversCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $createManyCompanyPilversHistoriesPaging
        filter: $createManyCompanyPilversHistoriesFilter
        sorting: $createManyCompanyPilversHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateManyCompanyPilversMutation__
 *
 * To run a mutation, you first call `useCreateManyCompanyPilversMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyCompanyPilversMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyCompanyPilversMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByContactsPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByContactsPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByContactsFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByContactsFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByContactsSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByContactsSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByProjectChangesPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByProjectChangesPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByProjectChangesFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByProjectChangesFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByProjectChangesSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByProjectChangesSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedContractsPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedContractsPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedContractsFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedContractsFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedContractsSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedContractsSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyCompanyPilversContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyCompanyPilversCreatedByCreatedByCreatedCompaniesPaging: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedCompaniesPaging'
 *     createManyCompanyPilversCreatedByCreatedByCreatedCompaniesFilter: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedCompaniesFilter'
 *     createManyCompanyPilversCreatedByCreatedByCreatedCompaniesSorting: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedCompaniesSorting'
 *     createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyCompanyPilversCreatedByCreatedByCreatedProjectsPaging: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedProjectsPaging'
 *     createManyCompanyPilversCreatedByCreatedByCreatedProjectsFilter: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedProjectsFilter'
 *     createManyCompanyPilversCreatedByCreatedByCreatedProjectsSorting: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedProjectsSorting'
 *     createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyCompanyPilversCreatedByCreatedByContactsPaging: // value for 'createManyCompanyPilversCreatedByCreatedByContactsPaging'
 *     createManyCompanyPilversCreatedByCreatedByContactsFilter: // value for 'createManyCompanyPilversCreatedByCreatedByContactsFilter'
 *     createManyCompanyPilversCreatedByCreatedByContactsSorting: // value for 'createManyCompanyPilversCreatedByCreatedByContactsSorting'
 *     createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyCompanyPilversCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyCompanyPilversCreatedByCreatedByProjectChangesPaging: // value for 'createManyCompanyPilversCreatedByCreatedByProjectChangesPaging'
 *     createManyCompanyPilversCreatedByCreatedByProjectChangesFilter: // value for 'createManyCompanyPilversCreatedByCreatedByProjectChangesFilter'
 *     createManyCompanyPilversCreatedByCreatedByProjectChangesSorting: // value for 'createManyCompanyPilversCreatedByCreatedByProjectChangesSorting'
 *     createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyCompanyPilversCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyCompanyPilversCreatedByCreatedByCreatedWorkersPaging: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedWorkersPaging'
 *     createManyCompanyPilversCreatedByCreatedByCreatedWorkersFilter: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedWorkersFilter'
 *     createManyCompanyPilversCreatedByCreatedByCreatedWorkersSorting: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedWorkersSorting'
 *     createManyCompanyPilversCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyCompanyPilversCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyCompanyPilversCreatedByCreatedByCreatedContractsPaging: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedContractsPaging'
 *     createManyCompanyPilversCreatedByCreatedByCreatedContractsFilter: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedContractsFilter'
 *     createManyCompanyPilversCreatedByCreatedByCreatedContractsSorting: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedContractsSorting'
 *     createManyCompanyPilversCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyCompanyPilversCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansPaging'
 *     createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansFilter'
 *     createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansSorting'
 *     createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyCompanyPilversCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyCompanyPilversHistoriesPaging: // value for 'createManyCompanyPilversHistoriesPaging'
 *     createManyCompanyPilversHistoriesFilter: // value for 'createManyCompanyPilversHistoriesFilter'
 *     createManyCompanyPilversHistoriesSorting: // value for 'createManyCompanyPilversHistoriesSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyCompanyPilversHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyCompanyPilversMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyCompanyPilversMutation,
        CreateManyCompanyPilversMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyCompanyPilversMutation,
          CreateManyCompanyPilversMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyCompanyPilversMutation,
    CreateManyCompanyPilversMutationVariables
  >(CreateManyCompanyPilversMutationDocument, options);
}
export type CreateManyCompanyPilversMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyCompanyPilversMutation,
    CreateManyCompanyPilversMutationVariables
  >;
export const UpdateOneCompanyPilverMutationDocument = gql`
  mutation updateOneCompanyPilverMutation(
    $input: UpdateOneCompanyPilverInput!
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $updateOneCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $updateOneCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $updateOneCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $updateOneCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $updateOneCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $updateOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneCompanyPilverHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesFilter: ContactPilverFilter
    $updateOneCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    updateOneCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $updateOneCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $updateOneCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $updateOneCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $updateOneCompanyPilverCreatedByCreatedByContactsPaging
          filter: $updateOneCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $updateOneCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $updateOneCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $updateOneCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $updateOneCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $updateOneCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $updateOneCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $updateOneCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $updateOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $updateOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $updateOneCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $updateOneCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $updateOneCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $updateOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $updateOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $updateOneCompanyPilverHistoriesPaging
        filter: $updateOneCompanyPilverHistoriesFilter
        sorting: $updateOneCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useUpdateOneCompanyPilverMutation__
 *
 * To run a mutation, you first call `useUpdateOneCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneCompanyPilverCreatedByCreatedByContactsPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByContactsPaging'
 *     updateOneCompanyPilverCreatedByCreatedByContactsFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByContactsFilter'
 *     updateOneCompanyPilverCreatedByCreatedByContactsSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByContactsSorting'
 *     updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     updateOneCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     updateOneCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneCompanyPilverHistoriesPaging: // value for 'updateOneCompanyPilverHistoriesPaging'
 *     updateOneCompanyPilverHistoriesFilter: // value for 'updateOneCompanyPilverHistoriesFilter'
 *     updateOneCompanyPilverHistoriesSorting: // value for 'updateOneCompanyPilverHistoriesSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneCompanyPilverMutation,
        UpdateOneCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneCompanyPilverMutation,
          UpdateOneCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneCompanyPilverMutation,
    UpdateOneCompanyPilverMutationVariables
  >(UpdateOneCompanyPilverMutationDocument, options);
}
export type UpdateOneCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneCompanyPilverMutation,
    UpdateOneCompanyPilverMutationVariables
  >;
export const UpdateManyCompanyPilversMutationDocument = gql`
  mutation updateManyCompanyPilversMutation(
    $input: UpdateManyCompanyPilversInput!
  ) {
    updateManyCompanyPilvers(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyCompanyPilversMutation__
 *
 * To run a mutation, you first call `useUpdateManyCompanyPilversMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyCompanyPilversMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyCompanyPilversMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyCompanyPilversMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyCompanyPilversMutation,
        UpdateManyCompanyPilversMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyCompanyPilversMutation,
          UpdateManyCompanyPilversMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyCompanyPilversMutation,
    UpdateManyCompanyPilversMutationVariables
  >(UpdateManyCompanyPilversMutationDocument, options);
}
export type UpdateManyCompanyPilversMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyCompanyPilversMutation,
    UpdateManyCompanyPilversMutationVariables
  >;
export const DeleteOneCompanyPilverMutationDocument = gql`
  mutation deleteOneCompanyPilverMutation(
    $input: DeleteOneCompanyPilverInput!
  ) {
    deleteOneCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
    }
  }
`;

/**
 * __useDeleteOneCompanyPilverMutation__
 *
 * To run a mutation, you first call `useDeleteOneCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneCompanyPilverMutation,
        DeleteOneCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneCompanyPilverMutation,
          DeleteOneCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneCompanyPilverMutation,
    DeleteOneCompanyPilverMutationVariables
  >(DeleteOneCompanyPilverMutationDocument, options);
}
export type DeleteOneCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneCompanyPilverMutation,
    DeleteOneCompanyPilverMutationVariables
  >;
export const DeleteManyCompanyPilversMutationDocument = gql`
  mutation deleteManyCompanyPilversMutation(
    $input: DeleteManyCompanyPilversInput!
  ) {
    deleteManyCompanyPilvers(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyCompanyPilversMutation__
 *
 * To run a mutation, you first call `useDeleteManyCompanyPilversMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCompanyPilversMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyCompanyPilversMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyCompanyPilversMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyCompanyPilversMutation,
        DeleteManyCompanyPilversMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyCompanyPilversMutation,
          DeleteManyCompanyPilversMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyCompanyPilversMutation,
    DeleteManyCompanyPilversMutationVariables
  >(DeleteManyCompanyPilversMutationDocument, options);
}
export type DeleteManyCompanyPilversMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyCompanyPilversMutation,
    DeleteManyCompanyPilversMutationVariables
  >;
export const SetCompanyOnContactMutationDocument = gql`
  mutation setCompanyOnContactMutation(
    $input: SetCompanyOnContactInput!
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCompanyOnContactCompanyCompanyHistoriesPaging: OffsetPaging
    $setCompanyOnContactCompanyCompanyHistoriesFilter: ContactFilter
    $setCompanyOnContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCompanyOnContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setCompanyOnContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setCompanyOnContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCompanyOnContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setCompanyOnContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setCompanyOnContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCompanyOnContactContactedByContactedByContactsPaging: OffsetPaging
    $setCompanyOnContactContactedByContactedByContactsFilter: ContactFilter
    $setCompanyOnContactContactedByContactedByContactsSorting: [ContactSort!]
    $setCompanyOnContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setCompanyOnContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setCompanyOnContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setCompanyOnContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setCompanyOnContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setCompanyOnContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setCompanyOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCompanyOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCompanyOnContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setCompanyOnContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setCompanyOnContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setCompanyOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCompanyOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCompanyOnContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setCompanyOnContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCompanyOnContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCompanyOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCompanyOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCompanyOnContact(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $setCompanyOnContactCompanyCompanyHistoriesPaging
          filter: $setCompanyOnContactCompanyCompanyHistoriesFilter
          sorting: $setCompanyOnContactCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCompanyOnContactContactedByContactedByCreatedCompaniesPaging
          filter: $setCompanyOnContactContactedByContactedByCreatedCompaniesFilter
          sorting: $setCompanyOnContactContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        createdProjects(
          paging: $setCompanyOnContactContactedByContactedByCreatedProjectsPaging
          filter: $setCompanyOnContactContactedByContactedByCreatedProjectsFilter
          sorting: $setCompanyOnContactContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCompanyOnContactContactedByContactedByContactsPaging
          filter: $setCompanyOnContactContactedByContactedByContactsFilter
          sorting: $setCompanyOnContactContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        projectChanges(
          paging: $setCompanyOnContactContactedByContactedByProjectChangesPaging
          filter: $setCompanyOnContactContactedByContactedByProjectChangesFilter
          sorting: $setCompanyOnContactContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdWorkers(
          paging: $setCompanyOnContactContactedByContactedByCreatedWorkersPaging
          filter: $setCompanyOnContactContactedByContactedByCreatedWorkersFilter
          sorting: $setCompanyOnContactContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCompanyOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCompanyOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCompanyOnContactContactedByContactedByCreatedContractsPaging
          filter: $setCompanyOnContactContactedByContactedByCreatedContractsFilter
          sorting: $setCompanyOnContactContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCompanyOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCompanyOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCompanyOnContactContactedByContactedByCreatedMiddlemansPaging
          filter: $setCompanyOnContactContactedByContactedByCreatedMiddlemansFilter
          sorting: $setCompanyOnContactContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCompanyOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCompanyOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetCompanyOnContactMutation__
 *
 * To run a mutation, you first call `useSetCompanyOnContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCompanyOnContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCompanyOnContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCompanyOnContactCompanyCompanyHistoriesPaging: // value for 'setCompanyOnContactCompanyCompanyHistoriesPaging'
 *     setCompanyOnContactCompanyCompanyHistoriesFilter: // value for 'setCompanyOnContactCompanyCompanyHistoriesFilter'
 *     setCompanyOnContactCompanyCompanyHistoriesSorting: // value for 'setCompanyOnContactCompanyCompanyHistoriesSorting'
 *     setCompanyOnContactContactedByContactedByCreatedCompaniesPaging: // value for 'setCompanyOnContactContactedByContactedByCreatedCompaniesPaging'
 *     setCompanyOnContactContactedByContactedByCreatedCompaniesFilter: // value for 'setCompanyOnContactContactedByContactedByCreatedCompaniesFilter'
 *     setCompanyOnContactContactedByContactedByCreatedCompaniesSorting: // value for 'setCompanyOnContactContactedByContactedByCreatedCompaniesSorting'
 *     setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCompanyOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCompanyOnContactContactedByContactedByCreatedProjectsPaging: // value for 'setCompanyOnContactContactedByContactedByCreatedProjectsPaging'
 *     setCompanyOnContactContactedByContactedByCreatedProjectsFilter: // value for 'setCompanyOnContactContactedByContactedByCreatedProjectsFilter'
 *     setCompanyOnContactContactedByContactedByCreatedProjectsSorting: // value for 'setCompanyOnContactContactedByContactedByCreatedProjectsSorting'
 *     setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCompanyOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCompanyOnContactContactedByContactedByContactsPaging: // value for 'setCompanyOnContactContactedByContactedByContactsPaging'
 *     setCompanyOnContactContactedByContactedByContactsFilter: // value for 'setCompanyOnContactContactedByContactedByContactsFilter'
 *     setCompanyOnContactContactedByContactedByContactsSorting: // value for 'setCompanyOnContactContactedByContactedByContactsSorting'
 *     setCompanyOnContactContactedByContactedByProjectChangesPaging: // value for 'setCompanyOnContactContactedByContactedByProjectChangesPaging'
 *     setCompanyOnContactContactedByContactedByProjectChangesFilter: // value for 'setCompanyOnContactContactedByContactedByProjectChangesFilter'
 *     setCompanyOnContactContactedByContactedByProjectChangesSorting: // value for 'setCompanyOnContactContactedByContactedByProjectChangesSorting'
 *     setCompanyOnContactContactedByContactedByCreatedWorkersPaging: // value for 'setCompanyOnContactContactedByContactedByCreatedWorkersPaging'
 *     setCompanyOnContactContactedByContactedByCreatedWorkersFilter: // value for 'setCompanyOnContactContactedByContactedByCreatedWorkersFilter'
 *     setCompanyOnContactContactedByContactedByCreatedWorkersSorting: // value for 'setCompanyOnContactContactedByContactedByCreatedWorkersSorting'
 *     setCompanyOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCompanyOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCompanyOnContactContactedByContactedByCreatedContractsPaging: // value for 'setCompanyOnContactContactedByContactedByCreatedContractsPaging'
 *     setCompanyOnContactContactedByContactedByCreatedContractsFilter: // value for 'setCompanyOnContactContactedByContactedByCreatedContractsFilter'
 *     setCompanyOnContactContactedByContactedByCreatedContractsSorting: // value for 'setCompanyOnContactContactedByContactedByCreatedContractsSorting'
 *     setCompanyOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCompanyOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCompanyOnContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setCompanyOnContactContactedByContactedByCreatedMiddlemansPaging'
 *     setCompanyOnContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setCompanyOnContactContactedByContactedByCreatedMiddlemansFilter'
 *     setCompanyOnContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setCompanyOnContactContactedByContactedByCreatedMiddlemansSorting'
 *     setCompanyOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCompanyOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCompanyOnContactMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCompanyOnContactMutation,
        SetCompanyOnContactMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCompanyOnContactMutation,
          SetCompanyOnContactMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCompanyOnContactMutation,
    SetCompanyOnContactMutationVariables
  >(SetCompanyOnContactMutationDocument, options);
}
export type SetCompanyOnContactMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCompanyOnContactMutation,
    SetCompanyOnContactMutationVariables
  >;
export const SetContactedByOnContactMutationDocument = gql`
  mutation setContactedByOnContactMutation(
    $input: SetContactedByOnContactInput!
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactedByOnContactCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactedByOnContactCompanyCompanyHistoriesFilter: ContactFilter
    $setContactedByOnContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactedByOnContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setContactedByOnContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setContactedByOnContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactedByOnContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setContactedByOnContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setContactedByOnContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactedByOnContactContactedByContactedByContactsPaging: OffsetPaging
    $setContactedByOnContactContactedByContactedByContactsFilter: ContactFilter
    $setContactedByOnContactContactedByContactedByContactsSorting: [ContactSort!]
    $setContactedByOnContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setContactedByOnContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setContactedByOnContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setContactedByOnContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setContactedByOnContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setContactedByOnContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setContactedByOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactedByOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactedByOnContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setContactedByOnContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setContactedByOnContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setContactedByOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactedByOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactedByOnContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setContactedByOnContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactedByOnContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactedByOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactedByOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setContactedByOnContact(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $setContactedByOnContactCompanyCompanyHistoriesPaging
          filter: $setContactedByOnContactCompanyCompanyHistoriesFilter
          sorting: $setContactedByOnContactCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setContactedByOnContactContactedByContactedByCreatedCompaniesPaging
          filter: $setContactedByOnContactContactedByContactedByCreatedCompaniesFilter
          sorting: $setContactedByOnContactContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        createdProjects(
          paging: $setContactedByOnContactContactedByContactedByCreatedProjectsPaging
          filter: $setContactedByOnContactContactedByContactedByCreatedProjectsFilter
          sorting: $setContactedByOnContactContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setContactedByOnContactContactedByContactedByContactsPaging
          filter: $setContactedByOnContactContactedByContactedByContactsFilter
          sorting: $setContactedByOnContactContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        projectChanges(
          paging: $setContactedByOnContactContactedByContactedByProjectChangesPaging
          filter: $setContactedByOnContactContactedByContactedByProjectChangesFilter
          sorting: $setContactedByOnContactContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdWorkers(
          paging: $setContactedByOnContactContactedByContactedByCreatedWorkersPaging
          filter: $setContactedByOnContactContactedByContactedByCreatedWorkersFilter
          sorting: $setContactedByOnContactContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setContactedByOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setContactedByOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setContactedByOnContactContactedByContactedByCreatedContractsPaging
          filter: $setContactedByOnContactContactedByContactedByCreatedContractsFilter
          sorting: $setContactedByOnContactContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setContactedByOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setContactedByOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setContactedByOnContactContactedByContactedByCreatedMiddlemansPaging
          filter: $setContactedByOnContactContactedByContactedByCreatedMiddlemansFilter
          sorting: $setContactedByOnContactContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setContactedByOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setContactedByOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetContactedByOnContactMutation__
 *
 * To run a mutation, you first call `useSetContactedByOnContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetContactedByOnContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetContactedByOnContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactedByOnContactCompanyCompanyHistoriesPaging: // value for 'setContactedByOnContactCompanyCompanyHistoriesPaging'
 *     setContactedByOnContactCompanyCompanyHistoriesFilter: // value for 'setContactedByOnContactCompanyCompanyHistoriesFilter'
 *     setContactedByOnContactCompanyCompanyHistoriesSorting: // value for 'setContactedByOnContactCompanyCompanyHistoriesSorting'
 *     setContactedByOnContactContactedByContactedByCreatedCompaniesPaging: // value for 'setContactedByOnContactContactedByContactedByCreatedCompaniesPaging'
 *     setContactedByOnContactContactedByContactedByCreatedCompaniesFilter: // value for 'setContactedByOnContactContactedByContactedByCreatedCompaniesFilter'
 *     setContactedByOnContactContactedByContactedByCreatedCompaniesSorting: // value for 'setContactedByOnContactContactedByContactedByCreatedCompaniesSorting'
 *     setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactedByOnContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactedByOnContactContactedByContactedByCreatedProjectsPaging: // value for 'setContactedByOnContactContactedByContactedByCreatedProjectsPaging'
 *     setContactedByOnContactContactedByContactedByCreatedProjectsFilter: // value for 'setContactedByOnContactContactedByContactedByCreatedProjectsFilter'
 *     setContactedByOnContactContactedByContactedByCreatedProjectsSorting: // value for 'setContactedByOnContactContactedByContactedByCreatedProjectsSorting'
 *     setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactedByOnContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactedByOnContactContactedByContactedByContactsPaging: // value for 'setContactedByOnContactContactedByContactedByContactsPaging'
 *     setContactedByOnContactContactedByContactedByContactsFilter: // value for 'setContactedByOnContactContactedByContactedByContactsFilter'
 *     setContactedByOnContactContactedByContactedByContactsSorting: // value for 'setContactedByOnContactContactedByContactedByContactsSorting'
 *     setContactedByOnContactContactedByContactedByProjectChangesPaging: // value for 'setContactedByOnContactContactedByContactedByProjectChangesPaging'
 *     setContactedByOnContactContactedByContactedByProjectChangesFilter: // value for 'setContactedByOnContactContactedByContactedByProjectChangesFilter'
 *     setContactedByOnContactContactedByContactedByProjectChangesSorting: // value for 'setContactedByOnContactContactedByContactedByProjectChangesSorting'
 *     setContactedByOnContactContactedByContactedByCreatedWorkersPaging: // value for 'setContactedByOnContactContactedByContactedByCreatedWorkersPaging'
 *     setContactedByOnContactContactedByContactedByCreatedWorkersFilter: // value for 'setContactedByOnContactContactedByContactedByCreatedWorkersFilter'
 *     setContactedByOnContactContactedByContactedByCreatedWorkersSorting: // value for 'setContactedByOnContactContactedByContactedByCreatedWorkersSorting'
 *     setContactedByOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactedByOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactedByOnContactContactedByContactedByCreatedContractsPaging: // value for 'setContactedByOnContactContactedByContactedByCreatedContractsPaging'
 *     setContactedByOnContactContactedByContactedByCreatedContractsFilter: // value for 'setContactedByOnContactContactedByContactedByCreatedContractsFilter'
 *     setContactedByOnContactContactedByContactedByCreatedContractsSorting: // value for 'setContactedByOnContactContactedByContactedByCreatedContractsSorting'
 *     setContactedByOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactedByOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactedByOnContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setContactedByOnContactContactedByContactedByCreatedMiddlemansPaging'
 *     setContactedByOnContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setContactedByOnContactContactedByContactedByCreatedMiddlemansFilter'
 *     setContactedByOnContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setContactedByOnContactContactedByContactedByCreatedMiddlemansSorting'
 *     setContactedByOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactedByOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetContactedByOnContactMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetContactedByOnContactMutation,
        SetContactedByOnContactMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetContactedByOnContactMutation,
          SetContactedByOnContactMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetContactedByOnContactMutation,
    SetContactedByOnContactMutationVariables
  >(SetContactedByOnContactMutationDocument, options);
}
export type SetContactedByOnContactMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetContactedByOnContactMutation,
    SetContactedByOnContactMutationVariables
  >;
export const RemoveCompanyFromContactMutationDocument = gql`
  mutation removeCompanyFromContactMutation(
    $input: RemoveCompanyFromContactInput!
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCompanyFromContactCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCompanyFromContactCompanyCompanyHistoriesFilter: ContactFilter
    $removeCompanyFromContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCompanyFromContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeCompanyFromContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeCompanyFromContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCompanyFromContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeCompanyFromContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeCompanyFromContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCompanyFromContactContactedByContactedByContactsPaging: OffsetPaging
    $removeCompanyFromContactContactedByContactedByContactsFilter: ContactFilter
    $removeCompanyFromContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeCompanyFromContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeCompanyFromContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeCompanyFromContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeCompanyFromContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeCompanyFromContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeCompanyFromContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeCompanyFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCompanyFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCompanyFromContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeCompanyFromContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeCompanyFromContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeCompanyFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCompanyFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCompanyFromContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeCompanyFromContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCompanyFromContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCompanyFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCompanyFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCompanyFromContact(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $removeCompanyFromContactCompanyCompanyHistoriesPaging
          filter: $removeCompanyFromContactCompanyCompanyHistoriesFilter
          sorting: $removeCompanyFromContactCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCompanyFromContactContactedByContactedByCreatedCompaniesPaging
          filter: $removeCompanyFromContactContactedByContactedByCreatedCompaniesFilter
          sorting: $removeCompanyFromContactContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        createdProjects(
          paging: $removeCompanyFromContactContactedByContactedByCreatedProjectsPaging
          filter: $removeCompanyFromContactContactedByContactedByCreatedProjectsFilter
          sorting: $removeCompanyFromContactContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCompanyFromContactContactedByContactedByContactsPaging
          filter: $removeCompanyFromContactContactedByContactedByContactsFilter
          sorting: $removeCompanyFromContactContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        projectChanges(
          paging: $removeCompanyFromContactContactedByContactedByProjectChangesPaging
          filter: $removeCompanyFromContactContactedByContactedByProjectChangesFilter
          sorting: $removeCompanyFromContactContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdWorkers(
          paging: $removeCompanyFromContactContactedByContactedByCreatedWorkersPaging
          filter: $removeCompanyFromContactContactedByContactedByCreatedWorkersFilter
          sorting: $removeCompanyFromContactContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCompanyFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCompanyFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCompanyFromContactContactedByContactedByCreatedContractsPaging
          filter: $removeCompanyFromContactContactedByContactedByCreatedContractsFilter
          sorting: $removeCompanyFromContactContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCompanyFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCompanyFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCompanyFromContactContactedByContactedByCreatedMiddlemansPaging
          filter: $removeCompanyFromContactContactedByContactedByCreatedMiddlemansFilter
          sorting: $removeCompanyFromContactContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCompanyFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCompanyFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCompanyFromContactMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyFromContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyFromContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCompanyFromContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCompanyFromContactCompanyCompanyHistoriesPaging: // value for 'removeCompanyFromContactCompanyCompanyHistoriesPaging'
 *     removeCompanyFromContactCompanyCompanyHistoriesFilter: // value for 'removeCompanyFromContactCompanyCompanyHistoriesFilter'
 *     removeCompanyFromContactCompanyCompanyHistoriesSorting: // value for 'removeCompanyFromContactCompanyCompanyHistoriesSorting'
 *     removeCompanyFromContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeCompanyFromContactContactedByContactedByCreatedCompaniesPaging'
 *     removeCompanyFromContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeCompanyFromContactContactedByContactedByCreatedCompaniesFilter'
 *     removeCompanyFromContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeCompanyFromContactContactedByContactedByCreatedCompaniesSorting'
 *     removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCompanyFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCompanyFromContactContactedByContactedByCreatedProjectsPaging: // value for 'removeCompanyFromContactContactedByContactedByCreatedProjectsPaging'
 *     removeCompanyFromContactContactedByContactedByCreatedProjectsFilter: // value for 'removeCompanyFromContactContactedByContactedByCreatedProjectsFilter'
 *     removeCompanyFromContactContactedByContactedByCreatedProjectsSorting: // value for 'removeCompanyFromContactContactedByContactedByCreatedProjectsSorting'
 *     removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCompanyFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCompanyFromContactContactedByContactedByContactsPaging: // value for 'removeCompanyFromContactContactedByContactedByContactsPaging'
 *     removeCompanyFromContactContactedByContactedByContactsFilter: // value for 'removeCompanyFromContactContactedByContactedByContactsFilter'
 *     removeCompanyFromContactContactedByContactedByContactsSorting: // value for 'removeCompanyFromContactContactedByContactedByContactsSorting'
 *     removeCompanyFromContactContactedByContactedByProjectChangesPaging: // value for 'removeCompanyFromContactContactedByContactedByProjectChangesPaging'
 *     removeCompanyFromContactContactedByContactedByProjectChangesFilter: // value for 'removeCompanyFromContactContactedByContactedByProjectChangesFilter'
 *     removeCompanyFromContactContactedByContactedByProjectChangesSorting: // value for 'removeCompanyFromContactContactedByContactedByProjectChangesSorting'
 *     removeCompanyFromContactContactedByContactedByCreatedWorkersPaging: // value for 'removeCompanyFromContactContactedByContactedByCreatedWorkersPaging'
 *     removeCompanyFromContactContactedByContactedByCreatedWorkersFilter: // value for 'removeCompanyFromContactContactedByContactedByCreatedWorkersFilter'
 *     removeCompanyFromContactContactedByContactedByCreatedWorkersSorting: // value for 'removeCompanyFromContactContactedByContactedByCreatedWorkersSorting'
 *     removeCompanyFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCompanyFromContactContactedByContactedByCreatedContractsPaging: // value for 'removeCompanyFromContactContactedByContactedByCreatedContractsPaging'
 *     removeCompanyFromContactContactedByContactedByCreatedContractsFilter: // value for 'removeCompanyFromContactContactedByContactedByCreatedContractsFilter'
 *     removeCompanyFromContactContactedByContactedByCreatedContractsSorting: // value for 'removeCompanyFromContactContactedByContactedByCreatedContractsSorting'
 *     removeCompanyFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCompanyFromContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeCompanyFromContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeCompanyFromContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeCompanyFromContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeCompanyFromContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeCompanyFromContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeCompanyFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCompanyFromContactMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCompanyFromContactMutation,
        RemoveCompanyFromContactMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCompanyFromContactMutation,
          RemoveCompanyFromContactMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCompanyFromContactMutation,
    RemoveCompanyFromContactMutationVariables
  >(RemoveCompanyFromContactMutationDocument, options);
}
export type RemoveCompanyFromContactMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCompanyFromContactMutation,
    RemoveCompanyFromContactMutationVariables
  >;
export const RemoveContactedByFromContactMutationDocument = gql`
  mutation removeContactedByFromContactMutation(
    $input: RemoveContactedByFromContactInput!
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactedByFromContactCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactedByFromContactCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactedByFromContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactedByFromContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeContactedByFromContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeContactedByFromContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactedByFromContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeContactedByFromContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeContactedByFromContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactedByFromContactContactedByContactedByContactsPaging: OffsetPaging
    $removeContactedByFromContactContactedByContactedByContactsFilter: ContactFilter
    $removeContactedByFromContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeContactedByFromContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeContactedByFromContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeContactedByFromContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeContactedByFromContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeContactedByFromContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeContactedByFromContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactedByFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactedByFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactedByFromContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeContactedByFromContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeContactedByFromContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeContactedByFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactedByFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactedByFromContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactedByFromContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactedByFromContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactedByFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactedByFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeContactedByFromContact(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $removeContactedByFromContactCompanyCompanyHistoriesPaging
          filter: $removeContactedByFromContactCompanyCompanyHistoriesFilter
          sorting: $removeContactedByFromContactCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeContactedByFromContactContactedByContactedByCreatedCompaniesPaging
          filter: $removeContactedByFromContactContactedByContactedByCreatedCompaniesFilter
          sorting: $removeContactedByFromContactContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        createdProjects(
          paging: $removeContactedByFromContactContactedByContactedByCreatedProjectsPaging
          filter: $removeContactedByFromContactContactedByContactedByCreatedProjectsFilter
          sorting: $removeContactedByFromContactContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeContactedByFromContactContactedByContactedByContactsPaging
          filter: $removeContactedByFromContactContactedByContactedByContactsFilter
          sorting: $removeContactedByFromContactContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        projectChanges(
          paging: $removeContactedByFromContactContactedByContactedByProjectChangesPaging
          filter: $removeContactedByFromContactContactedByContactedByProjectChangesFilter
          sorting: $removeContactedByFromContactContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdWorkers(
          paging: $removeContactedByFromContactContactedByContactedByCreatedWorkersPaging
          filter: $removeContactedByFromContactContactedByContactedByCreatedWorkersFilter
          sorting: $removeContactedByFromContactContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeContactedByFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeContactedByFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeContactedByFromContactContactedByContactedByCreatedContractsPaging
          filter: $removeContactedByFromContactContactedByContactedByCreatedContractsFilter
          sorting: $removeContactedByFromContactContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeContactedByFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeContactedByFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeContactedByFromContactContactedByContactedByCreatedMiddlemansPaging
          filter: $removeContactedByFromContactContactedByContactedByCreatedMiddlemansFilter
          sorting: $removeContactedByFromContactContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeContactedByFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeContactedByFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveContactedByFromContactMutation__
 *
 * To run a mutation, you first call `useRemoveContactedByFromContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactedByFromContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveContactedByFromContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactedByFromContactCompanyCompanyHistoriesPaging: // value for 'removeContactedByFromContactCompanyCompanyHistoriesPaging'
 *     removeContactedByFromContactCompanyCompanyHistoriesFilter: // value for 'removeContactedByFromContactCompanyCompanyHistoriesFilter'
 *     removeContactedByFromContactCompanyCompanyHistoriesSorting: // value for 'removeContactedByFromContactCompanyCompanyHistoriesSorting'
 *     removeContactedByFromContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeContactedByFromContactContactedByContactedByCreatedCompaniesPaging'
 *     removeContactedByFromContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeContactedByFromContactContactedByContactedByCreatedCompaniesFilter'
 *     removeContactedByFromContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeContactedByFromContactContactedByContactedByCreatedCompaniesSorting'
 *     removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactedByFromContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactedByFromContactContactedByContactedByCreatedProjectsPaging: // value for 'removeContactedByFromContactContactedByContactedByCreatedProjectsPaging'
 *     removeContactedByFromContactContactedByContactedByCreatedProjectsFilter: // value for 'removeContactedByFromContactContactedByContactedByCreatedProjectsFilter'
 *     removeContactedByFromContactContactedByContactedByCreatedProjectsSorting: // value for 'removeContactedByFromContactContactedByContactedByCreatedProjectsSorting'
 *     removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactedByFromContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactedByFromContactContactedByContactedByContactsPaging: // value for 'removeContactedByFromContactContactedByContactedByContactsPaging'
 *     removeContactedByFromContactContactedByContactedByContactsFilter: // value for 'removeContactedByFromContactContactedByContactedByContactsFilter'
 *     removeContactedByFromContactContactedByContactedByContactsSorting: // value for 'removeContactedByFromContactContactedByContactedByContactsSorting'
 *     removeContactedByFromContactContactedByContactedByProjectChangesPaging: // value for 'removeContactedByFromContactContactedByContactedByProjectChangesPaging'
 *     removeContactedByFromContactContactedByContactedByProjectChangesFilter: // value for 'removeContactedByFromContactContactedByContactedByProjectChangesFilter'
 *     removeContactedByFromContactContactedByContactedByProjectChangesSorting: // value for 'removeContactedByFromContactContactedByContactedByProjectChangesSorting'
 *     removeContactedByFromContactContactedByContactedByCreatedWorkersPaging: // value for 'removeContactedByFromContactContactedByContactedByCreatedWorkersPaging'
 *     removeContactedByFromContactContactedByContactedByCreatedWorkersFilter: // value for 'removeContactedByFromContactContactedByContactedByCreatedWorkersFilter'
 *     removeContactedByFromContactContactedByContactedByCreatedWorkersSorting: // value for 'removeContactedByFromContactContactedByContactedByCreatedWorkersSorting'
 *     removeContactedByFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactedByFromContactContactedByContactedByCreatedContractsPaging: // value for 'removeContactedByFromContactContactedByContactedByCreatedContractsPaging'
 *     removeContactedByFromContactContactedByContactedByCreatedContractsFilter: // value for 'removeContactedByFromContactContactedByContactedByCreatedContractsFilter'
 *     removeContactedByFromContactContactedByContactedByCreatedContractsSorting: // value for 'removeContactedByFromContactContactedByContactedByCreatedContractsSorting'
 *     removeContactedByFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactedByFromContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeContactedByFromContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeContactedByFromContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeContactedByFromContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeContactedByFromContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeContactedByFromContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeContactedByFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveContactedByFromContactMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveContactedByFromContactMutation,
        RemoveContactedByFromContactMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveContactedByFromContactMutation,
          RemoveContactedByFromContactMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveContactedByFromContactMutation,
    RemoveContactedByFromContactMutationVariables
  >(RemoveContactedByFromContactMutationDocument, options);
}
export type RemoveContactedByFromContactMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveContactedByFromContactMutation,
    RemoveContactedByFromContactMutationVariables
  >;
export const CreateOneContactMutationDocument = gql`
  mutation createOneContactMutation(
    $input: CreateOneContactInput!
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $createOneContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $createOneContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $createOneContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createOneContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createOneContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneContactCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneContactCompanyCompanyHistoriesFilter: ContactFilter
    $createOneContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createOneContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createOneContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createOneContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createOneContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneContactContactedByContactedByContactsPaging: OffsetPaging
    $createOneContactContactedByContactedByContactsFilter: ContactFilter
    $createOneContactContactedByContactedByContactsSorting: [ContactSort!]
    $createOneContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $createOneContactContactedByContactedByProjectChangesFilter: ContactFilter
    $createOneContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createOneContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createOneContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createOneContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createOneContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $createOneContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createOneContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createOneContact(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $createOneContactCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $createOneContactCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $createOneContactCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $createOneContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $createOneContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $createOneContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $createOneContactCompanyCompanyHistoriesPaging
          filter: $createOneContactCompanyCompanyHistoriesFilter
          sorting: $createOneContactCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createOneContactContactedByContactedByCreatedCompaniesPaging
          filter: $createOneContactContactedByContactedByCreatedCompaniesFilter
          sorting: $createOneContactContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        createdProjects(
          paging: $createOneContactContactedByContactedByCreatedProjectsPaging
          filter: $createOneContactContactedByContactedByCreatedProjectsFilter
          sorting: $createOneContactContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createOneContactContactedByContactedByContactsPaging
          filter: $createOneContactContactedByContactedByContactsFilter
          sorting: $createOneContactContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        projectChanges(
          paging: $createOneContactContactedByContactedByProjectChangesPaging
          filter: $createOneContactContactedByContactedByProjectChangesFilter
          sorting: $createOneContactContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdWorkers(
          paging: $createOneContactContactedByContactedByCreatedWorkersPaging
          filter: $createOneContactContactedByContactedByCreatedWorkersFilter
          sorting: $createOneContactContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createOneContactContactedByContactedByCreatedContractsPaging
          filter: $createOneContactContactedByContactedByCreatedContractsFilter
          sorting: $createOneContactContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createOneContactContactedByContactedByCreatedMiddlemansPaging
          filter: $createOneContactContactedByContactedByCreatedMiddlemansFilter
          sorting: $createOneContactContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateOneContactMutation__
 *
 * To run a mutation, you first call `useCreateOneContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'createOneContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     createOneContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     createOneContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'createOneContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     createOneContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneContactCompanyCompanyHistoriesPaging: // value for 'createOneContactCompanyCompanyHistoriesPaging'
 *     createOneContactCompanyCompanyHistoriesFilter: // value for 'createOneContactCompanyCompanyHistoriesFilter'
 *     createOneContactCompanyCompanyHistoriesSorting: // value for 'createOneContactCompanyCompanyHistoriesSorting'
 *     createOneContactContactedByContactedByCreatedCompaniesPaging: // value for 'createOneContactContactedByContactedByCreatedCompaniesPaging'
 *     createOneContactContactedByContactedByCreatedCompaniesFilter: // value for 'createOneContactContactedByContactedByCreatedCompaniesFilter'
 *     createOneContactContactedByContactedByCreatedCompaniesSorting: // value for 'createOneContactContactedByContactedByCreatedCompaniesSorting'
 *     createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneContactContactedByContactedByCreatedProjectsPaging: // value for 'createOneContactContactedByContactedByCreatedProjectsPaging'
 *     createOneContactContactedByContactedByCreatedProjectsFilter: // value for 'createOneContactContactedByContactedByCreatedProjectsFilter'
 *     createOneContactContactedByContactedByCreatedProjectsSorting: // value for 'createOneContactContactedByContactedByCreatedProjectsSorting'
 *     createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneContactContactedByContactedByContactsPaging: // value for 'createOneContactContactedByContactedByContactsPaging'
 *     createOneContactContactedByContactedByContactsFilter: // value for 'createOneContactContactedByContactedByContactsFilter'
 *     createOneContactContactedByContactedByContactsSorting: // value for 'createOneContactContactedByContactedByContactsSorting'
 *     createOneContactContactedByContactedByProjectChangesPaging: // value for 'createOneContactContactedByContactedByProjectChangesPaging'
 *     createOneContactContactedByContactedByProjectChangesFilter: // value for 'createOneContactContactedByContactedByProjectChangesFilter'
 *     createOneContactContactedByContactedByProjectChangesSorting: // value for 'createOneContactContactedByContactedByProjectChangesSorting'
 *     createOneContactContactedByContactedByCreatedWorkersPaging: // value for 'createOneContactContactedByContactedByCreatedWorkersPaging'
 *     createOneContactContactedByContactedByCreatedWorkersFilter: // value for 'createOneContactContactedByContactedByCreatedWorkersFilter'
 *     createOneContactContactedByContactedByCreatedWorkersSorting: // value for 'createOneContactContactedByContactedByCreatedWorkersSorting'
 *     createOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneContactContactedByContactedByCreatedContractsPaging: // value for 'createOneContactContactedByContactedByCreatedContractsPaging'
 *     createOneContactContactedByContactedByCreatedContractsFilter: // value for 'createOneContactContactedByContactedByCreatedContractsFilter'
 *     createOneContactContactedByContactedByCreatedContractsSorting: // value for 'createOneContactContactedByContactedByCreatedContractsSorting'
 *     createOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneContactContactedByContactedByCreatedMiddlemansPaging: // value for 'createOneContactContactedByContactedByCreatedMiddlemansPaging'
 *     createOneContactContactedByContactedByCreatedMiddlemansFilter: // value for 'createOneContactContactedByContactedByCreatedMiddlemansFilter'
 *     createOneContactContactedByContactedByCreatedMiddlemansSorting: // value for 'createOneContactContactedByContactedByCreatedMiddlemansSorting'
 *     createOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneContactMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneContactMutation,
        CreateOneContactMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneContactMutation,
          CreateOneContactMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneContactMutation,
    CreateOneContactMutationVariables
  >(CreateOneContactMutationDocument, options);
}
export type CreateOneContactMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneContactMutation,
    CreateOneContactMutationVariables
  >;
export const CreateManyContactsMutationDocument = gql`
  mutation createManyContactsMutation(
    $input: CreateManyContactsInput!
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyContactsCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $createManyContactsCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyContactsCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyContactsCompanyCompanyHistoriesFilter: ContactFilter
    $createManyContactsCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyContactsContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createManyContactsContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createManyContactsContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyContactsContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createManyContactsContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createManyContactsContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyContactsContactedByContactedByContactsPaging: OffsetPaging
    $createManyContactsContactedByContactedByContactsFilter: ContactFilter
    $createManyContactsContactedByContactedByContactsSorting: [ContactSort!]
    $createManyContactsContactedByContactedByProjectChangesPaging: OffsetPaging
    $createManyContactsContactedByContactedByProjectChangesFilter: ContactFilter
    $createManyContactsContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createManyContactsContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createManyContactsContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createManyContactsContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createManyContactsContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyContactsContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyContactsContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createManyContactsContactedByContactedByCreatedContractsFilter: ContractFilter
    $createManyContactsContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createManyContactsContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyContactsContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyContactsContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createManyContactsContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyContactsContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyContactsContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyContactsContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createManyContacts(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $createManyContactsCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $createManyContactsCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $createManyContactsCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $createManyContactsCompanyCompanyHistoriesPaging
          filter: $createManyContactsCompanyCompanyHistoriesFilter
          sorting: $createManyContactsCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createManyContactsContactedByContactedByCreatedCompaniesPaging
          filter: $createManyContactsContactedByContactedByCreatedCompaniesFilter
          sorting: $createManyContactsContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        createdProjects(
          paging: $createManyContactsContactedByContactedByCreatedProjectsPaging
          filter: $createManyContactsContactedByContactedByCreatedProjectsFilter
          sorting: $createManyContactsContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createManyContactsContactedByContactedByContactsPaging
          filter: $createManyContactsContactedByContactedByContactsFilter
          sorting: $createManyContactsContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        projectChanges(
          paging: $createManyContactsContactedByContactedByProjectChangesPaging
          filter: $createManyContactsContactedByContactedByProjectChangesFilter
          sorting: $createManyContactsContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdWorkers(
          paging: $createManyContactsContactedByContactedByCreatedWorkersPaging
          filter: $createManyContactsContactedByContactedByCreatedWorkersFilter
          sorting: $createManyContactsContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createManyContactsContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createManyContactsContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createManyContactsContactedByContactedByCreatedContractsPaging
          filter: $createManyContactsContactedByContactedByCreatedContractsFilter
          sorting: $createManyContactsContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createManyContactsContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createManyContactsContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createManyContactsContactedByContactedByCreatedMiddlemansPaging
          filter: $createManyContactsContactedByContactedByCreatedMiddlemansFilter
          sorting: $createManyContactsContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createManyContactsContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createManyContactsContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateManyContactsMutation__
 *
 * To run a mutation, you first call `useCreateManyContactsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyContactsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyContactsMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyContactsCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByContactsPaging'
 *     createManyContactsCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByContactsFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByContactsSorting'
 *     createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyContactsCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyContactsCompanyCompanyHistoriesPaging: // value for 'createManyContactsCompanyCompanyHistoriesPaging'
 *     createManyContactsCompanyCompanyHistoriesFilter: // value for 'createManyContactsCompanyCompanyHistoriesFilter'
 *     createManyContactsCompanyCompanyHistoriesSorting: // value for 'createManyContactsCompanyCompanyHistoriesSorting'
 *     createManyContactsContactedByContactedByCreatedCompaniesPaging: // value for 'createManyContactsContactedByContactedByCreatedCompaniesPaging'
 *     createManyContactsContactedByContactedByCreatedCompaniesFilter: // value for 'createManyContactsContactedByContactedByCreatedCompaniesFilter'
 *     createManyContactsContactedByContactedByCreatedCompaniesSorting: // value for 'createManyContactsContactedByContactedByCreatedCompaniesSorting'
 *     createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyContactsContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyContactsContactedByContactedByCreatedProjectsPaging: // value for 'createManyContactsContactedByContactedByCreatedProjectsPaging'
 *     createManyContactsContactedByContactedByCreatedProjectsFilter: // value for 'createManyContactsContactedByContactedByCreatedProjectsFilter'
 *     createManyContactsContactedByContactedByCreatedProjectsSorting: // value for 'createManyContactsContactedByContactedByCreatedProjectsSorting'
 *     createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyContactsContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyContactsContactedByContactedByContactsPaging: // value for 'createManyContactsContactedByContactedByContactsPaging'
 *     createManyContactsContactedByContactedByContactsFilter: // value for 'createManyContactsContactedByContactedByContactsFilter'
 *     createManyContactsContactedByContactedByContactsSorting: // value for 'createManyContactsContactedByContactedByContactsSorting'
 *     createManyContactsContactedByContactedByProjectChangesPaging: // value for 'createManyContactsContactedByContactedByProjectChangesPaging'
 *     createManyContactsContactedByContactedByProjectChangesFilter: // value for 'createManyContactsContactedByContactedByProjectChangesFilter'
 *     createManyContactsContactedByContactedByProjectChangesSorting: // value for 'createManyContactsContactedByContactedByProjectChangesSorting'
 *     createManyContactsContactedByContactedByCreatedWorkersPaging: // value for 'createManyContactsContactedByContactedByCreatedWorkersPaging'
 *     createManyContactsContactedByContactedByCreatedWorkersFilter: // value for 'createManyContactsContactedByContactedByCreatedWorkersFilter'
 *     createManyContactsContactedByContactedByCreatedWorkersSorting: // value for 'createManyContactsContactedByContactedByCreatedWorkersSorting'
 *     createManyContactsContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyContactsContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyContactsContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyContactsContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyContactsContactedByContactedByCreatedContractsPaging: // value for 'createManyContactsContactedByContactedByCreatedContractsPaging'
 *     createManyContactsContactedByContactedByCreatedContractsFilter: // value for 'createManyContactsContactedByContactedByCreatedContractsFilter'
 *     createManyContactsContactedByContactedByCreatedContractsSorting: // value for 'createManyContactsContactedByContactedByCreatedContractsSorting'
 *     createManyContactsContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyContactsContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyContactsContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyContactsContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyContactsContactedByContactedByCreatedMiddlemansPaging: // value for 'createManyContactsContactedByContactedByCreatedMiddlemansPaging'
 *     createManyContactsContactedByContactedByCreatedMiddlemansFilter: // value for 'createManyContactsContactedByContactedByCreatedMiddlemansFilter'
 *     createManyContactsContactedByContactedByCreatedMiddlemansSorting: // value for 'createManyContactsContactedByContactedByCreatedMiddlemansSorting'
 *     createManyContactsContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyContactsContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyContactsContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyContactsContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyContactsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyContactsMutation,
        CreateManyContactsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyContactsMutation,
          CreateManyContactsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyContactsMutation,
    CreateManyContactsMutationVariables
  >(CreateManyContactsMutationDocument, options);
}
export type CreateManyContactsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyContactsMutation,
    CreateManyContactsMutationVariables
  >;
export const UpdateOneContactMutationDocument = gql`
  mutation updateOneContactMutation(
    $input: UpdateOneContactInput!
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $updateOneContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneContactCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneContactCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $updateOneContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $updateOneContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $updateOneContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $updateOneContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneContactContactedByContactedByContactsPaging: OffsetPaging
    $updateOneContactContactedByContactedByContactsFilter: ContactFilter
    $updateOneContactContactedByContactedByContactsSorting: [ContactSort!]
    $updateOneContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $updateOneContactContactedByContactedByProjectChangesFilter: ContactFilter
    $updateOneContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $updateOneContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $updateOneContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $updateOneContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $updateOneContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $updateOneContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $updateOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    updateOneContact(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdProjects(
            paging: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $updateOneContactCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $updateOneContactCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $updateOneContactCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $updateOneContactCompanyCompanyHistoriesPaging
          filter: $updateOneContactCompanyCompanyHistoriesFilter
          sorting: $updateOneContactCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $updateOneContactContactedByContactedByCreatedCompaniesPaging
          filter: $updateOneContactContactedByContactedByCreatedCompaniesFilter
          sorting: $updateOneContactContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            histories(
              paging: $updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        createdProjects(
          paging: $updateOneContactContactedByContactedByCreatedProjectsPaging
          filter: $updateOneContactContactedByContactedByCreatedProjectsFilter
          sorting: $updateOneContactContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $updateOneContactContactedByContactedByContactsPaging
          filter: $updateOneContactContactedByContactedByContactsFilter
          sorting: $updateOneContactContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        projectChanges(
          paging: $updateOneContactContactedByContactedByProjectChangesPaging
          filter: $updateOneContactContactedByContactedByProjectChangesFilter
          sorting: $updateOneContactContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdWorkers(
          paging: $updateOneContactContactedByContactedByCreatedWorkersPaging
          filter: $updateOneContactContactedByContactedByCreatedWorkersFilter
          sorting: $updateOneContactContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $updateOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $updateOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $updateOneContactContactedByContactedByCreatedContractsPaging
          filter: $updateOneContactContactedByContactedByCreatedContractsFilter
          sorting: $updateOneContactContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $updateOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $updateOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $updateOneContactContactedByContactedByCreatedMiddlemansPaging
          filter: $updateOneContactContactedByContactedByCreatedMiddlemansFilter
          sorting: $updateOneContactContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $updateOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $updateOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useUpdateOneContactMutation__
 *
 * To run a mutation, you first call `useUpdateOneContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     updateOneContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneContactCompanyCompanyHistoriesPaging: // value for 'updateOneContactCompanyCompanyHistoriesPaging'
 *     updateOneContactCompanyCompanyHistoriesFilter: // value for 'updateOneContactCompanyCompanyHistoriesFilter'
 *     updateOneContactCompanyCompanyHistoriesSorting: // value for 'updateOneContactCompanyCompanyHistoriesSorting'
 *     updateOneContactContactedByContactedByCreatedCompaniesPaging: // value for 'updateOneContactContactedByContactedByCreatedCompaniesPaging'
 *     updateOneContactContactedByContactedByCreatedCompaniesFilter: // value for 'updateOneContactContactedByContactedByCreatedCompaniesFilter'
 *     updateOneContactContactedByContactedByCreatedCompaniesSorting: // value for 'updateOneContactContactedByContactedByCreatedCompaniesSorting'
 *     updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneContactContactedByContactedByCreatedProjectsPaging: // value for 'updateOneContactContactedByContactedByCreatedProjectsPaging'
 *     updateOneContactContactedByContactedByCreatedProjectsFilter: // value for 'updateOneContactContactedByContactedByCreatedProjectsFilter'
 *     updateOneContactContactedByContactedByCreatedProjectsSorting: // value for 'updateOneContactContactedByContactedByCreatedProjectsSorting'
 *     updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneContactContactedByContactedByContactsPaging: // value for 'updateOneContactContactedByContactedByContactsPaging'
 *     updateOneContactContactedByContactedByContactsFilter: // value for 'updateOneContactContactedByContactedByContactsFilter'
 *     updateOneContactContactedByContactedByContactsSorting: // value for 'updateOneContactContactedByContactedByContactsSorting'
 *     updateOneContactContactedByContactedByProjectChangesPaging: // value for 'updateOneContactContactedByContactedByProjectChangesPaging'
 *     updateOneContactContactedByContactedByProjectChangesFilter: // value for 'updateOneContactContactedByContactedByProjectChangesFilter'
 *     updateOneContactContactedByContactedByProjectChangesSorting: // value for 'updateOneContactContactedByContactedByProjectChangesSorting'
 *     updateOneContactContactedByContactedByCreatedWorkersPaging: // value for 'updateOneContactContactedByContactedByCreatedWorkersPaging'
 *     updateOneContactContactedByContactedByCreatedWorkersFilter: // value for 'updateOneContactContactedByContactedByCreatedWorkersFilter'
 *     updateOneContactContactedByContactedByCreatedWorkersSorting: // value for 'updateOneContactContactedByContactedByCreatedWorkersSorting'
 *     updateOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneContactContactedByContactedByCreatedContractsPaging: // value for 'updateOneContactContactedByContactedByCreatedContractsPaging'
 *     updateOneContactContactedByContactedByCreatedContractsFilter: // value for 'updateOneContactContactedByContactedByCreatedContractsFilter'
 *     updateOneContactContactedByContactedByCreatedContractsSorting: // value for 'updateOneContactContactedByContactedByCreatedContractsSorting'
 *     updateOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneContactContactedByContactedByCreatedMiddlemansPaging: // value for 'updateOneContactContactedByContactedByCreatedMiddlemansPaging'
 *     updateOneContactContactedByContactedByCreatedMiddlemansFilter: // value for 'updateOneContactContactedByContactedByCreatedMiddlemansFilter'
 *     updateOneContactContactedByContactedByCreatedMiddlemansSorting: // value for 'updateOneContactContactedByContactedByCreatedMiddlemansSorting'
 *     updateOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneContactMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneContactMutation,
        UpdateOneContactMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneContactMutation,
          UpdateOneContactMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneContactMutation,
    UpdateOneContactMutationVariables
  >(UpdateOneContactMutationDocument, options);
}
export type UpdateOneContactMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneContactMutation,
    UpdateOneContactMutationVariables
  >;
export const UpdateManyContactsMutationDocument = gql`
  mutation updateManyContactsMutation($input: UpdateManyContactsInput!) {
    updateManyContacts(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyContactsMutation__
 *
 * To run a mutation, you first call `useUpdateManyContactsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyContactsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyContactsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyContactsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyContactsMutation,
        UpdateManyContactsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyContactsMutation,
          UpdateManyContactsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyContactsMutation,
    UpdateManyContactsMutationVariables
  >(UpdateManyContactsMutationDocument, options);
}
export type UpdateManyContactsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyContactsMutation,
    UpdateManyContactsMutationVariables
  >;
export const DeleteOneContactMutationDocument = gql`
  mutation deleteOneContactMutation($input: DeleteOneContactInput!) {
    deleteOneContact(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
    }
  }
`;

/**
 * __useDeleteOneContactMutation__
 *
 * To run a mutation, you first call `useDeleteOneContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneContactMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneContactMutation,
        DeleteOneContactMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneContactMutation,
          DeleteOneContactMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneContactMutation,
    DeleteOneContactMutationVariables
  >(DeleteOneContactMutationDocument, options);
}
export type DeleteOneContactMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneContactMutation,
    DeleteOneContactMutationVariables
  >;
export const DeleteManyContactsMutationDocument = gql`
  mutation deleteManyContactsMutation($input: DeleteManyContactsInput!) {
    deleteManyContacts(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyContactsMutation__
 *
 * To run a mutation, you first call `useDeleteManyContactsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyContactsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyContactsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyContactsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyContactsMutation,
        DeleteManyContactsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyContactsMutation,
          DeleteManyContactsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyContactsMutation,
    DeleteManyContactsMutationVariables
  >(DeleteManyContactsMutationDocument, options);
}
export type DeleteManyContactsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyContactsMutation,
    DeleteManyContactsMutationVariables
  >;
export const SetCompanyOnContactPilverMutationDocument = gql`
  mutation setCompanyOnContactPilverMutation(
    $input: SetCompanyOnContactPilverInput!
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCompanyOnContactPilverCompanyCompanyHistoriesPaging: OffsetPaging
    $setCompanyOnContactPilverCompanyCompanyHistoriesFilter: ContactPilverFilter
    $setCompanyOnContactPilverCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCompanyOnContactPilverContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setCompanyOnContactPilverContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCompanyOnContactPilverContactedByContactedByContactsPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByContactsFilter: ContactFilter
    $setCompanyOnContactPilverContactedByContactedByContactsSorting: [ContactSort!]
    $setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCompanyOnContactPilverContactedByContactedByProjectChangesPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByProjectChangesFilter: ContactFilter
    $setCompanyOnContactPilverContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCompanyOnContactPilverContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setCompanyOnContactPilverContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setCompanyOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCompanyOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCompanyOnContactPilverContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByCreatedContractsFilter: ContractFilter
    $setCompanyOnContactPilverContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setCompanyOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCompanyOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCompanyOnContactPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $setCompanyOnContactPilverCompanyCompanyHistoriesPaging
          filter: $setCompanyOnContactPilverCompanyCompanyHistoriesFilter
          sorting: $setCompanyOnContactPilverCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesPaging
          filter: $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesFilter
          sorting: $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCompanyOnContactPilverContactedByContactedByCreatedProjectsPaging
          filter: $setCompanyOnContactPilverContactedByContactedByCreatedProjectsFilter
          sorting: $setCompanyOnContactPilverContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCompanyOnContactPilverContactedByContactedByContactsPaging
          filter: $setCompanyOnContactPilverContactedByContactedByContactsFilter
          sorting: $setCompanyOnContactPilverContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCompanyOnContactPilverContactedByContactedByProjectChangesPaging
          filter: $setCompanyOnContactPilverContactedByContactedByProjectChangesFilter
          sorting: $setCompanyOnContactPilverContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCompanyOnContactPilverContactedByContactedByCreatedWorkersPaging
          filter: $setCompanyOnContactPilverContactedByContactedByCreatedWorkersFilter
          sorting: $setCompanyOnContactPilverContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCompanyOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCompanyOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCompanyOnContactPilverContactedByContactedByCreatedContractsPaging
          filter: $setCompanyOnContactPilverContactedByContactedByCreatedContractsFilter
          sorting: $setCompanyOnContactPilverContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCompanyOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCompanyOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansPaging
          filter: $setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansFilter
          sorting: $setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetCompanyOnContactPilverMutation__
 *
 * To run a mutation, you first call `useSetCompanyOnContactPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCompanyOnContactPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCompanyOnContactPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCompanyOnContactPilverCompanyCompanyHistoriesPaging: // value for 'setCompanyOnContactPilverCompanyCompanyHistoriesPaging'
 *     setCompanyOnContactPilverCompanyCompanyHistoriesFilter: // value for 'setCompanyOnContactPilverCompanyCompanyHistoriesFilter'
 *     setCompanyOnContactPilverCompanyCompanyHistoriesSorting: // value for 'setCompanyOnContactPilverCompanyCompanyHistoriesSorting'
 *     setCompanyOnContactPilverContactedByContactedByCreatedCompaniesPaging: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedCompaniesPaging'
 *     setCompanyOnContactPilverContactedByContactedByCreatedCompaniesFilter: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedCompaniesFilter'
 *     setCompanyOnContactPilverContactedByContactedByCreatedCompaniesSorting: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedCompaniesSorting'
 *     setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCompanyOnContactPilverContactedByContactedByCreatedProjectsPaging: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedProjectsPaging'
 *     setCompanyOnContactPilverContactedByContactedByCreatedProjectsFilter: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedProjectsFilter'
 *     setCompanyOnContactPilverContactedByContactedByCreatedProjectsSorting: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedProjectsSorting'
 *     setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCompanyOnContactPilverContactedByContactedByContactsPaging: // value for 'setCompanyOnContactPilverContactedByContactedByContactsPaging'
 *     setCompanyOnContactPilverContactedByContactedByContactsFilter: // value for 'setCompanyOnContactPilverContactedByContactedByContactsFilter'
 *     setCompanyOnContactPilverContactedByContactedByContactsSorting: // value for 'setCompanyOnContactPilverContactedByContactedByContactsSorting'
 *     setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCompanyOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCompanyOnContactPilverContactedByContactedByProjectChangesPaging: // value for 'setCompanyOnContactPilverContactedByContactedByProjectChangesPaging'
 *     setCompanyOnContactPilverContactedByContactedByProjectChangesFilter: // value for 'setCompanyOnContactPilverContactedByContactedByProjectChangesFilter'
 *     setCompanyOnContactPilverContactedByContactedByProjectChangesSorting: // value for 'setCompanyOnContactPilverContactedByContactedByProjectChangesSorting'
 *     setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCompanyOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCompanyOnContactPilverContactedByContactedByCreatedWorkersPaging: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedWorkersPaging'
 *     setCompanyOnContactPilverContactedByContactedByCreatedWorkersFilter: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedWorkersFilter'
 *     setCompanyOnContactPilverContactedByContactedByCreatedWorkersSorting: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedWorkersSorting'
 *     setCompanyOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCompanyOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCompanyOnContactPilverContactedByContactedByCreatedContractsPaging: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedContractsPaging'
 *     setCompanyOnContactPilverContactedByContactedByCreatedContractsFilter: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedContractsFilter'
 *     setCompanyOnContactPilverContactedByContactedByCreatedContractsSorting: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedContractsSorting'
 *     setCompanyOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCompanyOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansPaging: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansPaging'
 *     setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansFilter: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansFilter'
 *     setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansSorting: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansSorting'
 *     setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCompanyOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCompanyOnContactPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCompanyOnContactPilverMutation,
        SetCompanyOnContactPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCompanyOnContactPilverMutation,
          SetCompanyOnContactPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCompanyOnContactPilverMutation,
    SetCompanyOnContactPilverMutationVariables
  >(SetCompanyOnContactPilverMutationDocument, options);
}
export type SetCompanyOnContactPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCompanyOnContactPilverMutation,
    SetCompanyOnContactPilverMutationVariables
  >;
export const SetContactedByOnContactPilverMutationDocument = gql`
  mutation setContactedByOnContactPilverMutation(
    $input: SetContactedByOnContactPilverInput!
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactedByOnContactPilverCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactedByOnContactPilverCompanyCompanyHistoriesFilter: ContactPilverFilter
    $setContactedByOnContactPilverCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactedByOnContactPilverContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setContactedByOnContactPilverContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactedByOnContactPilverContactedByContactedByContactsPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByContactsFilter: ContactFilter
    $setContactedByOnContactPilverContactedByContactedByContactsSorting: [ContactSort!]
    $setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactedByOnContactPilverContactedByContactedByProjectChangesPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByProjectChangesFilter: ContactFilter
    $setContactedByOnContactPilverContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactedByOnContactPilverContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setContactedByOnContactPilverContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setContactedByOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactedByOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactedByOnContactPilverContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByCreatedContractsFilter: ContractFilter
    $setContactedByOnContactPilverContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setContactedByOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactedByOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setContactedByOnContactPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $setContactedByOnContactPilverCompanyCompanyHistoriesPaging
          filter: $setContactedByOnContactPilverCompanyCompanyHistoriesFilter
          sorting: $setContactedByOnContactPilverCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesPaging
          filter: $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesFilter
          sorting: $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setContactedByOnContactPilverContactedByContactedByCreatedProjectsPaging
          filter: $setContactedByOnContactPilverContactedByContactedByCreatedProjectsFilter
          sorting: $setContactedByOnContactPilverContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setContactedByOnContactPilverContactedByContactedByContactsPaging
          filter: $setContactedByOnContactPilverContactedByContactedByContactsFilter
          sorting: $setContactedByOnContactPilverContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setContactedByOnContactPilverContactedByContactedByProjectChangesPaging
          filter: $setContactedByOnContactPilverContactedByContactedByProjectChangesFilter
          sorting: $setContactedByOnContactPilverContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setContactedByOnContactPilverContactedByContactedByCreatedWorkersPaging
          filter: $setContactedByOnContactPilverContactedByContactedByCreatedWorkersFilter
          sorting: $setContactedByOnContactPilverContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setContactedByOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setContactedByOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setContactedByOnContactPilverContactedByContactedByCreatedContractsPaging
          filter: $setContactedByOnContactPilverContactedByContactedByCreatedContractsFilter
          sorting: $setContactedByOnContactPilverContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setContactedByOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setContactedByOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansPaging
          filter: $setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansFilter
          sorting: $setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetContactedByOnContactPilverMutation__
 *
 * To run a mutation, you first call `useSetContactedByOnContactPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetContactedByOnContactPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetContactedByOnContactPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactedByOnContactPilverCompanyCompanyHistoriesPaging: // value for 'setContactedByOnContactPilverCompanyCompanyHistoriesPaging'
 *     setContactedByOnContactPilverCompanyCompanyHistoriesFilter: // value for 'setContactedByOnContactPilverCompanyCompanyHistoriesFilter'
 *     setContactedByOnContactPilverCompanyCompanyHistoriesSorting: // value for 'setContactedByOnContactPilverCompanyCompanyHistoriesSorting'
 *     setContactedByOnContactPilverContactedByContactedByCreatedCompaniesPaging: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedCompaniesPaging'
 *     setContactedByOnContactPilverContactedByContactedByCreatedCompaniesFilter: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedCompaniesFilter'
 *     setContactedByOnContactPilverContactedByContactedByCreatedCompaniesSorting: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedCompaniesSorting'
 *     setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactedByOnContactPilverContactedByContactedByCreatedProjectsPaging: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedProjectsPaging'
 *     setContactedByOnContactPilverContactedByContactedByCreatedProjectsFilter: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedProjectsFilter'
 *     setContactedByOnContactPilverContactedByContactedByCreatedProjectsSorting: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedProjectsSorting'
 *     setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactedByOnContactPilverContactedByContactedByContactsPaging: // value for 'setContactedByOnContactPilverContactedByContactedByContactsPaging'
 *     setContactedByOnContactPilverContactedByContactedByContactsFilter: // value for 'setContactedByOnContactPilverContactedByContactedByContactsFilter'
 *     setContactedByOnContactPilverContactedByContactedByContactsSorting: // value for 'setContactedByOnContactPilverContactedByContactedByContactsSorting'
 *     setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactedByOnContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactedByOnContactPilverContactedByContactedByProjectChangesPaging: // value for 'setContactedByOnContactPilverContactedByContactedByProjectChangesPaging'
 *     setContactedByOnContactPilverContactedByContactedByProjectChangesFilter: // value for 'setContactedByOnContactPilverContactedByContactedByProjectChangesFilter'
 *     setContactedByOnContactPilverContactedByContactedByProjectChangesSorting: // value for 'setContactedByOnContactPilverContactedByContactedByProjectChangesSorting'
 *     setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactedByOnContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactedByOnContactPilverContactedByContactedByCreatedWorkersPaging: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedWorkersPaging'
 *     setContactedByOnContactPilverContactedByContactedByCreatedWorkersFilter: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedWorkersFilter'
 *     setContactedByOnContactPilverContactedByContactedByCreatedWorkersSorting: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedWorkersSorting'
 *     setContactedByOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactedByOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactedByOnContactPilverContactedByContactedByCreatedContractsPaging: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedContractsPaging'
 *     setContactedByOnContactPilverContactedByContactedByCreatedContractsFilter: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedContractsFilter'
 *     setContactedByOnContactPilverContactedByContactedByCreatedContractsSorting: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedContractsSorting'
 *     setContactedByOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactedByOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansPaging: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansPaging'
 *     setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansFilter: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansFilter'
 *     setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansSorting: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansSorting'
 *     setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactedByOnContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetContactedByOnContactPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetContactedByOnContactPilverMutation,
        SetContactedByOnContactPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetContactedByOnContactPilverMutation,
          SetContactedByOnContactPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetContactedByOnContactPilverMutation,
    SetContactedByOnContactPilverMutationVariables
  >(SetContactedByOnContactPilverMutationDocument, options);
}
export type SetContactedByOnContactPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetContactedByOnContactPilverMutation,
    SetContactedByOnContactPilverMutationVariables
  >;
export const RemoveCompanyFromContactPilverMutationDocument = gql`
  mutation removeCompanyFromContactPilverMutation(
    $input: RemoveCompanyFromContactPilverInput!
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCompanyFromContactPilverCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCompanyFromContactPilverCompanyCompanyHistoriesFilter: ContactPilverFilter
    $removeCompanyFromContactPilverCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCompanyFromContactPilverContactedByContactedByContactsPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByContactsFilter: ContactFilter
    $removeCompanyFromContactPilverContactedByContactedByContactsSorting: [ContactSort!]
    $removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCompanyFromContactPilverContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByProjectChangesFilter: ContactFilter
    $removeCompanyFromContactPilverContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCompanyFromContactPilverContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeCompanyFromContactPilverContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeCompanyFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCompanyFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCompanyFromContactPilverContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeCompanyFromContactPilverContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeCompanyFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCompanyFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCompanyFromContactPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $removeCompanyFromContactPilverCompanyCompanyHistoriesPaging
          filter: $removeCompanyFromContactPilverCompanyCompanyHistoriesFilter
          sorting: $removeCompanyFromContactPilverCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesPaging
          filter: $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesFilter
          sorting: $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsPaging
          filter: $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsFilter
          sorting: $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCompanyFromContactPilverContactedByContactedByContactsPaging
          filter: $removeCompanyFromContactPilverContactedByContactedByContactsFilter
          sorting: $removeCompanyFromContactPilverContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCompanyFromContactPilverContactedByContactedByProjectChangesPaging
          filter: $removeCompanyFromContactPilverContactedByContactedByProjectChangesFilter
          sorting: $removeCompanyFromContactPilverContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCompanyFromContactPilverContactedByContactedByCreatedWorkersPaging
          filter: $removeCompanyFromContactPilverContactedByContactedByCreatedWorkersFilter
          sorting: $removeCompanyFromContactPilverContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCompanyFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCompanyFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCompanyFromContactPilverContactedByContactedByCreatedContractsPaging
          filter: $removeCompanyFromContactPilverContactedByContactedByCreatedContractsFilter
          sorting: $removeCompanyFromContactPilverContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCompanyFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCompanyFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansPaging
          filter: $removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansFilter
          sorting: $removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCompanyFromContactPilverMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyFromContactPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyFromContactPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCompanyFromContactPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCompanyFromContactPilverCompanyCompanyHistoriesPaging: // value for 'removeCompanyFromContactPilverCompanyCompanyHistoriesPaging'
 *     removeCompanyFromContactPilverCompanyCompanyHistoriesFilter: // value for 'removeCompanyFromContactPilverCompanyCompanyHistoriesFilter'
 *     removeCompanyFromContactPilverCompanyCompanyHistoriesSorting: // value for 'removeCompanyFromContactPilverCompanyCompanyHistoriesSorting'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesPaging'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesFilter'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesSorting'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedProjectsPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedProjectsPaging'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedProjectsFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedProjectsFilter'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedProjectsSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedProjectsSorting'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCompanyFromContactPilverContactedByContactedByContactsPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByContactsPaging'
 *     removeCompanyFromContactPilverContactedByContactedByContactsFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByContactsFilter'
 *     removeCompanyFromContactPilverContactedByContactedByContactsSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByContactsSorting'
 *     removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCompanyFromContactPilverContactedByContactedByProjectChangesPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByProjectChangesPaging'
 *     removeCompanyFromContactPilverContactedByContactedByProjectChangesFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByProjectChangesFilter'
 *     removeCompanyFromContactPilverContactedByContactedByProjectChangesSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByProjectChangesSorting'
 *     removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedWorkersPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedWorkersPaging'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedWorkersFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedWorkersFilter'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedWorkersSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedWorkersSorting'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedContractsPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedContractsPaging'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedContractsFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedContractsFilter'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedContractsSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedContractsSorting'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansPaging: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansPaging'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansFilter'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansSorting'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCompanyFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCompanyFromContactPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCompanyFromContactPilverMutation,
        RemoveCompanyFromContactPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCompanyFromContactPilverMutation,
          RemoveCompanyFromContactPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCompanyFromContactPilverMutation,
    RemoveCompanyFromContactPilverMutationVariables
  >(RemoveCompanyFromContactPilverMutationDocument, options);
}
export type RemoveCompanyFromContactPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCompanyFromContactPilverMutation,
    RemoveCompanyFromContactPilverMutationVariables
  >;
export const RemoveContactedByFromContactPilverMutationDocument = gql`
  mutation removeContactedByFromContactPilverMutation(
    $input: RemoveContactedByFromContactPilverInput!
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactedByFromContactPilverCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactedByFromContactPilverCompanyCompanyHistoriesFilter: ContactPilverFilter
    $removeContactedByFromContactPilverCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactedByFromContactPilverContactedByContactedByContactsPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByContactsFilter: ContactFilter
    $removeContactedByFromContactPilverContactedByContactedByContactsSorting: [ContactSort!]
    $removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactedByFromContactPilverContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByProjectChangesFilter: ContactFilter
    $removeContactedByFromContactPilverContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactedByFromContactPilverContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeContactedByFromContactPilverContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactedByFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactedByFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactedByFromContactPilverContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeContactedByFromContactPilverContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeContactedByFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactedByFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeContactedByFromContactPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $removeContactedByFromContactPilverCompanyCompanyHistoriesPaging
          filter: $removeContactedByFromContactPilverCompanyCompanyHistoriesFilter
          sorting: $removeContactedByFromContactPilverCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesPaging
          filter: $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesFilter
          sorting: $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsPaging
          filter: $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsFilter
          sorting: $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeContactedByFromContactPilverContactedByContactedByContactsPaging
          filter: $removeContactedByFromContactPilverContactedByContactedByContactsFilter
          sorting: $removeContactedByFromContactPilverContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeContactedByFromContactPilverContactedByContactedByProjectChangesPaging
          filter: $removeContactedByFromContactPilverContactedByContactedByProjectChangesFilter
          sorting: $removeContactedByFromContactPilverContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeContactedByFromContactPilverContactedByContactedByCreatedWorkersPaging
          filter: $removeContactedByFromContactPilverContactedByContactedByCreatedWorkersFilter
          sorting: $removeContactedByFromContactPilverContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeContactedByFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeContactedByFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeContactedByFromContactPilverContactedByContactedByCreatedContractsPaging
          filter: $removeContactedByFromContactPilverContactedByContactedByCreatedContractsFilter
          sorting: $removeContactedByFromContactPilverContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeContactedByFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeContactedByFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansPaging
          filter: $removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansFilter
          sorting: $removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveContactedByFromContactPilverMutation__
 *
 * To run a mutation, you first call `useRemoveContactedByFromContactPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactedByFromContactPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveContactedByFromContactPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactedByFromContactPilverCompanyCompanyHistoriesPaging: // value for 'removeContactedByFromContactPilverCompanyCompanyHistoriesPaging'
 *     removeContactedByFromContactPilverCompanyCompanyHistoriesFilter: // value for 'removeContactedByFromContactPilverCompanyCompanyHistoriesFilter'
 *     removeContactedByFromContactPilverCompanyCompanyHistoriesSorting: // value for 'removeContactedByFromContactPilverCompanyCompanyHistoriesSorting'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesPaging'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesFilter'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesSorting'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedProjectsPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedProjectsPaging'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedProjectsFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedProjectsFilter'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedProjectsSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedProjectsSorting'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactedByFromContactPilverContactedByContactedByContactsPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByContactsPaging'
 *     removeContactedByFromContactPilverContactedByContactedByContactsFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByContactsFilter'
 *     removeContactedByFromContactPilverContactedByContactedByContactsSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByContactsSorting'
 *     removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactedByFromContactPilverContactedByContactedByProjectChangesPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByProjectChangesPaging'
 *     removeContactedByFromContactPilverContactedByContactedByProjectChangesFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByProjectChangesFilter'
 *     removeContactedByFromContactPilverContactedByContactedByProjectChangesSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByProjectChangesSorting'
 *     removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedWorkersPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedWorkersPaging'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedWorkersFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedWorkersFilter'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedWorkersSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedWorkersSorting'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedContractsPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedContractsPaging'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedContractsFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedContractsFilter'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedContractsSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedContractsSorting'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansPaging: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansPaging'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansFilter'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansSorting'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactedByFromContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveContactedByFromContactPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveContactedByFromContactPilverMutation,
        RemoveContactedByFromContactPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveContactedByFromContactPilverMutation,
          RemoveContactedByFromContactPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveContactedByFromContactPilverMutation,
    RemoveContactedByFromContactPilverMutationVariables
  >(RemoveContactedByFromContactPilverMutationDocument, options);
}
export type RemoveContactedByFromContactPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveContactedByFromContactPilverMutation,
    RemoveContactedByFromContactPilverMutationVariables
  >;
export const CreateOneContactPilverMutationDocument = gql`
  mutation createOneContactPilverMutation(
    $input: CreateOneContactPilverInput!
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneContactPilverCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneContactPilverCompanyCompanyHistoriesFilter: ContactPilverFilter
    $createOneContactPilverCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $createOneContactPilverContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createOneContactPilverContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneContactPilverContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createOneContactPilverContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneContactPilverContactedByContactedByContactsPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByContactsFilter: ContactFilter
    $createOneContactPilverContactedByContactedByContactsSorting: [ContactSort!]
    $createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneContactPilverContactedByContactedByProjectChangesPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByProjectChangesFilter: ContactFilter
    $createOneContactPilverContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneContactPilverContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createOneContactPilverContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneContactPilverContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByCreatedContractsFilter: ContractFilter
    $createOneContactPilverContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneContactPilverContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createOneContactPilverContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneContactPilverContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createOneContactPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $createOneContactPilverCompanyCompanyHistoriesPaging
          filter: $createOneContactPilverCompanyCompanyHistoriesFilter
          sorting: $createOneContactPilverCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createOneContactPilverContactedByContactedByCreatedCompaniesPaging
          filter: $createOneContactPilverContactedByContactedByCreatedCompaniesFilter
          sorting: $createOneContactPilverContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createOneContactPilverContactedByContactedByCreatedProjectsPaging
          filter: $createOneContactPilverContactedByContactedByCreatedProjectsFilter
          sorting: $createOneContactPilverContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createOneContactPilverContactedByContactedByContactsPaging
          filter: $createOneContactPilverContactedByContactedByContactsFilter
          sorting: $createOneContactPilverContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createOneContactPilverContactedByContactedByProjectChangesPaging
          filter: $createOneContactPilverContactedByContactedByProjectChangesFilter
          sorting: $createOneContactPilverContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createOneContactPilverContactedByContactedByCreatedWorkersPaging
          filter: $createOneContactPilverContactedByContactedByCreatedWorkersFilter
          sorting: $createOneContactPilverContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createOneContactPilverContactedByContactedByCreatedContractsPaging
          filter: $createOneContactPilverContactedByContactedByCreatedContractsFilter
          sorting: $createOneContactPilverContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createOneContactPilverContactedByContactedByCreatedMiddlemansPaging
          filter: $createOneContactPilverContactedByContactedByCreatedMiddlemansFilter
          sorting: $createOneContactPilverContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateOneContactPilverMutation__
 *
 * To run a mutation, you first call `useCreateOneContactPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContactPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneContactPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByContactsPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByContactsFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByContactsSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneContactPilverCompanyCompanyHistoriesPaging: // value for 'createOneContactPilverCompanyCompanyHistoriesPaging'
 *     createOneContactPilverCompanyCompanyHistoriesFilter: // value for 'createOneContactPilverCompanyCompanyHistoriesFilter'
 *     createOneContactPilverCompanyCompanyHistoriesSorting: // value for 'createOneContactPilverCompanyCompanyHistoriesSorting'
 *     createOneContactPilverContactedByContactedByCreatedCompaniesPaging: // value for 'createOneContactPilverContactedByContactedByCreatedCompaniesPaging'
 *     createOneContactPilverContactedByContactedByCreatedCompaniesFilter: // value for 'createOneContactPilverContactedByContactedByCreatedCompaniesFilter'
 *     createOneContactPilverContactedByContactedByCreatedCompaniesSorting: // value for 'createOneContactPilverContactedByContactedByCreatedCompaniesSorting'
 *     createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneContactPilverContactedByContactedByCreatedProjectsPaging: // value for 'createOneContactPilverContactedByContactedByCreatedProjectsPaging'
 *     createOneContactPilverContactedByContactedByCreatedProjectsFilter: // value for 'createOneContactPilverContactedByContactedByCreatedProjectsFilter'
 *     createOneContactPilverContactedByContactedByCreatedProjectsSorting: // value for 'createOneContactPilverContactedByContactedByCreatedProjectsSorting'
 *     createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneContactPilverContactedByContactedByContactsPaging: // value for 'createOneContactPilverContactedByContactedByContactsPaging'
 *     createOneContactPilverContactedByContactedByContactsFilter: // value for 'createOneContactPilverContactedByContactedByContactsFilter'
 *     createOneContactPilverContactedByContactedByContactsSorting: // value for 'createOneContactPilverContactedByContactedByContactsSorting'
 *     createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneContactPilverContactedByContactedByProjectChangesPaging: // value for 'createOneContactPilverContactedByContactedByProjectChangesPaging'
 *     createOneContactPilverContactedByContactedByProjectChangesFilter: // value for 'createOneContactPilverContactedByContactedByProjectChangesFilter'
 *     createOneContactPilverContactedByContactedByProjectChangesSorting: // value for 'createOneContactPilverContactedByContactedByProjectChangesSorting'
 *     createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneContactPilverContactedByContactedByCreatedWorkersPaging: // value for 'createOneContactPilverContactedByContactedByCreatedWorkersPaging'
 *     createOneContactPilverContactedByContactedByCreatedWorkersFilter: // value for 'createOneContactPilverContactedByContactedByCreatedWorkersFilter'
 *     createOneContactPilverContactedByContactedByCreatedWorkersSorting: // value for 'createOneContactPilverContactedByContactedByCreatedWorkersSorting'
 *     createOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneContactPilverContactedByContactedByCreatedContractsPaging: // value for 'createOneContactPilverContactedByContactedByCreatedContractsPaging'
 *     createOneContactPilverContactedByContactedByCreatedContractsFilter: // value for 'createOneContactPilverContactedByContactedByCreatedContractsFilter'
 *     createOneContactPilverContactedByContactedByCreatedContractsSorting: // value for 'createOneContactPilverContactedByContactedByCreatedContractsSorting'
 *     createOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneContactPilverContactedByContactedByCreatedMiddlemansPaging: // value for 'createOneContactPilverContactedByContactedByCreatedMiddlemansPaging'
 *     createOneContactPilverContactedByContactedByCreatedMiddlemansFilter: // value for 'createOneContactPilverContactedByContactedByCreatedMiddlemansFilter'
 *     createOneContactPilverContactedByContactedByCreatedMiddlemansSorting: // value for 'createOneContactPilverContactedByContactedByCreatedMiddlemansSorting'
 *     createOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneContactPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneContactPilverMutation,
        CreateOneContactPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneContactPilverMutation,
          CreateOneContactPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneContactPilverMutation,
    CreateOneContactPilverMutationVariables
  >(CreateOneContactPilverMutationDocument, options);
}
export type CreateOneContactPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneContactPilverMutation,
    CreateOneContactPilverMutationVariables
  >;
export const CreateManyContactPilversMutationDocument = gql`
  mutation createManyContactPilversMutation(
    $input: CreateManyContactPilversInput!
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyContactPilversCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyContactPilversCompanyCompanyHistoriesFilter: ContactPilverFilter
    $createManyContactPilversCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $createManyContactPilversContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $createManyContactPilversContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyContactPilversContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $createManyContactPilversContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyContactPilversContactedByContactedByContactsPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByContactsFilter: ContactFilter
    $createManyContactPilversContactedByContactedByContactsSorting: [ContactSort!]
    $createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyContactPilversContactedByContactedByProjectChangesPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByProjectChangesFilter: ContactFilter
    $createManyContactPilversContactedByContactedByProjectChangesSorting: [ContactSort!]
    $createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyContactPilversContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $createManyContactPilversContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $createManyContactPilversContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyContactPilversContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyContactPilversContactedByContactedByCreatedContractsPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByCreatedContractsFilter: ContractFilter
    $createManyContactPilversContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $createManyContactPilversContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyContactPilversContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyContactPilversContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $createManyContactPilversContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyContactPilversContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyContactPilversContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyContactPilversContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createManyContactPilvers(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $createManyContactPilversCompanyCompanyHistoriesPaging
          filter: $createManyContactPilversCompanyCompanyHistoriesFilter
          sorting: $createManyContactPilversCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createManyContactPilversContactedByContactedByCreatedCompaniesPaging
          filter: $createManyContactPilversContactedByContactedByCreatedCompaniesFilter
          sorting: $createManyContactPilversContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createManyContactPilversContactedByContactedByCreatedProjectsPaging
          filter: $createManyContactPilversContactedByContactedByCreatedProjectsFilter
          sorting: $createManyContactPilversContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createManyContactPilversContactedByContactedByContactsPaging
          filter: $createManyContactPilversContactedByContactedByContactsFilter
          sorting: $createManyContactPilversContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createManyContactPilversContactedByContactedByProjectChangesPaging
          filter: $createManyContactPilversContactedByContactedByProjectChangesFilter
          sorting: $createManyContactPilversContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createManyContactPilversContactedByContactedByCreatedWorkersPaging
          filter: $createManyContactPilversContactedByContactedByCreatedWorkersFilter
          sorting: $createManyContactPilversContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createManyContactPilversContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createManyContactPilversContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createManyContactPilversContactedByContactedByCreatedContractsPaging
          filter: $createManyContactPilversContactedByContactedByCreatedContractsFilter
          sorting: $createManyContactPilversContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createManyContactPilversContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createManyContactPilversContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createManyContactPilversContactedByContactedByCreatedMiddlemansPaging
          filter: $createManyContactPilversContactedByContactedByCreatedMiddlemansFilter
          sorting: $createManyContactPilversContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createManyContactPilversContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createManyContactPilversContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateManyContactPilversMutation__
 *
 * To run a mutation, you first call `useCreateManyContactPilversMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyContactPilversMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyContactPilversMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByContactsPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByContactsFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByContactsSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyContactPilversCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyContactPilversCompanyCompanyHistoriesPaging: // value for 'createManyContactPilversCompanyCompanyHistoriesPaging'
 *     createManyContactPilversCompanyCompanyHistoriesFilter: // value for 'createManyContactPilversCompanyCompanyHistoriesFilter'
 *     createManyContactPilversCompanyCompanyHistoriesSorting: // value for 'createManyContactPilversCompanyCompanyHistoriesSorting'
 *     createManyContactPilversContactedByContactedByCreatedCompaniesPaging: // value for 'createManyContactPilversContactedByContactedByCreatedCompaniesPaging'
 *     createManyContactPilversContactedByContactedByCreatedCompaniesFilter: // value for 'createManyContactPilversContactedByContactedByCreatedCompaniesFilter'
 *     createManyContactPilversContactedByContactedByCreatedCompaniesSorting: // value for 'createManyContactPilversContactedByContactedByCreatedCompaniesSorting'
 *     createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyContactPilversContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyContactPilversContactedByContactedByCreatedProjectsPaging: // value for 'createManyContactPilversContactedByContactedByCreatedProjectsPaging'
 *     createManyContactPilversContactedByContactedByCreatedProjectsFilter: // value for 'createManyContactPilversContactedByContactedByCreatedProjectsFilter'
 *     createManyContactPilversContactedByContactedByCreatedProjectsSorting: // value for 'createManyContactPilversContactedByContactedByCreatedProjectsSorting'
 *     createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyContactPilversContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyContactPilversContactedByContactedByContactsPaging: // value for 'createManyContactPilversContactedByContactedByContactsPaging'
 *     createManyContactPilversContactedByContactedByContactsFilter: // value for 'createManyContactPilversContactedByContactedByContactsFilter'
 *     createManyContactPilversContactedByContactedByContactsSorting: // value for 'createManyContactPilversContactedByContactedByContactsSorting'
 *     createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyContactPilversContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyContactPilversContactedByContactedByProjectChangesPaging: // value for 'createManyContactPilversContactedByContactedByProjectChangesPaging'
 *     createManyContactPilversContactedByContactedByProjectChangesFilter: // value for 'createManyContactPilversContactedByContactedByProjectChangesFilter'
 *     createManyContactPilversContactedByContactedByProjectChangesSorting: // value for 'createManyContactPilversContactedByContactedByProjectChangesSorting'
 *     createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyContactPilversContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyContactPilversContactedByContactedByCreatedWorkersPaging: // value for 'createManyContactPilversContactedByContactedByCreatedWorkersPaging'
 *     createManyContactPilversContactedByContactedByCreatedWorkersFilter: // value for 'createManyContactPilversContactedByContactedByCreatedWorkersFilter'
 *     createManyContactPilversContactedByContactedByCreatedWorkersSorting: // value for 'createManyContactPilversContactedByContactedByCreatedWorkersSorting'
 *     createManyContactPilversContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyContactPilversContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyContactPilversContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyContactPilversContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyContactPilversContactedByContactedByCreatedContractsPaging: // value for 'createManyContactPilversContactedByContactedByCreatedContractsPaging'
 *     createManyContactPilversContactedByContactedByCreatedContractsFilter: // value for 'createManyContactPilversContactedByContactedByCreatedContractsFilter'
 *     createManyContactPilversContactedByContactedByCreatedContractsSorting: // value for 'createManyContactPilversContactedByContactedByCreatedContractsSorting'
 *     createManyContactPilversContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyContactPilversContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyContactPilversContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyContactPilversContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyContactPilversContactedByContactedByCreatedMiddlemansPaging: // value for 'createManyContactPilversContactedByContactedByCreatedMiddlemansPaging'
 *     createManyContactPilversContactedByContactedByCreatedMiddlemansFilter: // value for 'createManyContactPilversContactedByContactedByCreatedMiddlemansFilter'
 *     createManyContactPilversContactedByContactedByCreatedMiddlemansSorting: // value for 'createManyContactPilversContactedByContactedByCreatedMiddlemansSorting'
 *     createManyContactPilversContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyContactPilversContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyContactPilversContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyContactPilversContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyContactPilversMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyContactPilversMutation,
        CreateManyContactPilversMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyContactPilversMutation,
          CreateManyContactPilversMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyContactPilversMutation,
    CreateManyContactPilversMutationVariables
  >(CreateManyContactPilversMutationDocument, options);
}
export type CreateManyContactPilversMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyContactPilversMutation,
    CreateManyContactPilversMutationVariables
  >;
export const UpdateOneContactPilverMutationDocument = gql`
  mutation updateOneContactPilverMutation(
    $input: UpdateOneContactPilverInput!
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneContactPilverCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneContactPilverCompanyCompanyHistoriesFilter: ContactPilverFilter
    $updateOneContactPilverCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $updateOneContactPilverContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $updateOneContactPilverContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneContactPilverContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $updateOneContactPilverContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneContactPilverContactedByContactedByContactsPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByContactsFilter: ContactFilter
    $updateOneContactPilverContactedByContactedByContactsSorting: [ContactSort!]
    $updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneContactPilverContactedByContactedByProjectChangesPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByProjectChangesFilter: ContactFilter
    $updateOneContactPilverContactedByContactedByProjectChangesSorting: [ContactSort!]
    $updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneContactPilverContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $updateOneContactPilverContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneContactPilverContactedByContactedByCreatedContractsPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByCreatedContractsFilter: ContractFilter
    $updateOneContactPilverContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $updateOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneContactPilverContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneContactPilverContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneContactPilverContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    updateOneContactPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
      company {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Webpage
        Address
        Focus
        Language
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
            filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
            sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
            filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
            sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsPaging
            filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsFilter
            sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging
            filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter
            sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
            filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
            sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging
            filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter
            sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
            filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $updateOneContactPilverCompanyCompanyHistoriesPaging
          filter: $updateOneContactPilverCompanyCompanyHistoriesFilter
          sorting: $updateOneContactPilverCompanyCompanyHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      contactedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $updateOneContactPilverContactedByContactedByCreatedCompaniesPaging
          filter: $updateOneContactPilverContactedByContactedByCreatedCompaniesFilter
          sorting: $updateOneContactPilverContactedByContactedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $updateOneContactPilverContactedByContactedByCreatedProjectsPaging
          filter: $updateOneContactPilverContactedByContactedByCreatedProjectsFilter
          sorting: $updateOneContactPilverContactedByContactedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $updateOneContactPilverContactedByContactedByContactsPaging
          filter: $updateOneContactPilverContactedByContactedByContactsFilter
          sorting: $updateOneContactPilverContactedByContactedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $updateOneContactPilverContactedByContactedByProjectChangesPaging
          filter: $updateOneContactPilverContactedByContactedByProjectChangesFilter
          sorting: $updateOneContactPilverContactedByContactedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $updateOneContactPilverContactedByContactedByCreatedWorkersPaging
          filter: $updateOneContactPilverContactedByContactedByCreatedWorkersFilter
          sorting: $updateOneContactPilverContactedByContactedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $updateOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $updateOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $updateOneContactPilverContactedByContactedByCreatedContractsPaging
          filter: $updateOneContactPilverContactedByContactedByCreatedContractsFilter
          sorting: $updateOneContactPilverContactedByContactedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $updateOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $updateOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $updateOneContactPilverContactedByContactedByCreatedMiddlemansPaging
          filter: $updateOneContactPilverContactedByContactedByCreatedMiddlemansFilter
          sorting: $updateOneContactPilverContactedByContactedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $updateOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $updateOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useUpdateOneContactPilverMutation__
 *
 * To run a mutation, you first call `useUpdateOneContactPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContactPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneContactPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneContactPilverCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneContactPilverCompanyCompanyHistoriesPaging: // value for 'updateOneContactPilverCompanyCompanyHistoriesPaging'
 *     updateOneContactPilverCompanyCompanyHistoriesFilter: // value for 'updateOneContactPilverCompanyCompanyHistoriesFilter'
 *     updateOneContactPilverCompanyCompanyHistoriesSorting: // value for 'updateOneContactPilverCompanyCompanyHistoriesSorting'
 *     updateOneContactPilverContactedByContactedByCreatedCompaniesPaging: // value for 'updateOneContactPilverContactedByContactedByCreatedCompaniesPaging'
 *     updateOneContactPilverContactedByContactedByCreatedCompaniesFilter: // value for 'updateOneContactPilverContactedByContactedByCreatedCompaniesFilter'
 *     updateOneContactPilverContactedByContactedByCreatedCompaniesSorting: // value for 'updateOneContactPilverContactedByContactedByCreatedCompaniesSorting'
 *     updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneContactPilverContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneContactPilverContactedByContactedByCreatedProjectsPaging: // value for 'updateOneContactPilverContactedByContactedByCreatedProjectsPaging'
 *     updateOneContactPilverContactedByContactedByCreatedProjectsFilter: // value for 'updateOneContactPilverContactedByContactedByCreatedProjectsFilter'
 *     updateOneContactPilverContactedByContactedByCreatedProjectsSorting: // value for 'updateOneContactPilverContactedByContactedByCreatedProjectsSorting'
 *     updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneContactPilverContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneContactPilverContactedByContactedByContactsPaging: // value for 'updateOneContactPilverContactedByContactedByContactsPaging'
 *     updateOneContactPilverContactedByContactedByContactsFilter: // value for 'updateOneContactPilverContactedByContactedByContactsFilter'
 *     updateOneContactPilverContactedByContactedByContactsSorting: // value for 'updateOneContactPilverContactedByContactedByContactsSorting'
 *     updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneContactPilverContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneContactPilverContactedByContactedByProjectChangesPaging: // value for 'updateOneContactPilverContactedByContactedByProjectChangesPaging'
 *     updateOneContactPilverContactedByContactedByProjectChangesFilter: // value for 'updateOneContactPilverContactedByContactedByProjectChangesFilter'
 *     updateOneContactPilverContactedByContactedByProjectChangesSorting: // value for 'updateOneContactPilverContactedByContactedByProjectChangesSorting'
 *     updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneContactPilverContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneContactPilverContactedByContactedByCreatedWorkersPaging: // value for 'updateOneContactPilverContactedByContactedByCreatedWorkersPaging'
 *     updateOneContactPilverContactedByContactedByCreatedWorkersFilter: // value for 'updateOneContactPilverContactedByContactedByCreatedWorkersFilter'
 *     updateOneContactPilverContactedByContactedByCreatedWorkersSorting: // value for 'updateOneContactPilverContactedByContactedByCreatedWorkersSorting'
 *     updateOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneContactPilverContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneContactPilverContactedByContactedByCreatedContractsPaging: // value for 'updateOneContactPilverContactedByContactedByCreatedContractsPaging'
 *     updateOneContactPilverContactedByContactedByCreatedContractsFilter: // value for 'updateOneContactPilverContactedByContactedByCreatedContractsFilter'
 *     updateOneContactPilverContactedByContactedByCreatedContractsSorting: // value for 'updateOneContactPilverContactedByContactedByCreatedContractsSorting'
 *     updateOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneContactPilverContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneContactPilverContactedByContactedByCreatedMiddlemansPaging: // value for 'updateOneContactPilverContactedByContactedByCreatedMiddlemansPaging'
 *     updateOneContactPilverContactedByContactedByCreatedMiddlemansFilter: // value for 'updateOneContactPilverContactedByContactedByCreatedMiddlemansFilter'
 *     updateOneContactPilverContactedByContactedByCreatedMiddlemansSorting: // value for 'updateOneContactPilverContactedByContactedByCreatedMiddlemansSorting'
 *     updateOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneContactPilverContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneContactPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneContactPilverMutation,
        UpdateOneContactPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneContactPilverMutation,
          UpdateOneContactPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneContactPilverMutation,
    UpdateOneContactPilverMutationVariables
  >(UpdateOneContactPilverMutationDocument, options);
}
export type UpdateOneContactPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneContactPilverMutation,
    UpdateOneContactPilverMutationVariables
  >;
export const UpdateManyContactPilversMutationDocument = gql`
  mutation updateManyContactPilversMutation(
    $input: UpdateManyContactPilversInput!
  ) {
    updateManyContactPilvers(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyContactPilversMutation__
 *
 * To run a mutation, you first call `useUpdateManyContactPilversMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyContactPilversMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyContactPilversMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyContactPilversMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyContactPilversMutation,
        UpdateManyContactPilversMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyContactPilversMutation,
          UpdateManyContactPilversMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyContactPilversMutation,
    UpdateManyContactPilversMutationVariables
  >(UpdateManyContactPilversMutationDocument, options);
}
export type UpdateManyContactPilversMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyContactPilversMutation,
    UpdateManyContactPilversMutationVariables
  >;
export const DeleteOneContactPilverMutationDocument = gql`
  mutation deleteOneContactPilverMutation(
    $input: DeleteOneContactPilverInput!
  ) {
    deleteOneContactPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      ContactPerson
      Email
      Phone
      Description
      Interest
    }
  }
`;

/**
 * __useDeleteOneContactPilverMutation__
 *
 * To run a mutation, you first call `useDeleteOneContactPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContactPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneContactPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneContactPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneContactPilverMutation,
        DeleteOneContactPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneContactPilverMutation,
          DeleteOneContactPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneContactPilverMutation,
    DeleteOneContactPilverMutationVariables
  >(DeleteOneContactPilverMutationDocument, options);
}
export type DeleteOneContactPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneContactPilverMutation,
    DeleteOneContactPilverMutationVariables
  >;
export const DeleteManyContactPilversMutationDocument = gql`
  mutation deleteManyContactPilversMutation(
    $input: DeleteManyContactPilversInput!
  ) {
    deleteManyContactPilvers(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyContactPilversMutation__
 *
 * To run a mutation, you first call `useDeleteManyContactPilversMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyContactPilversMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyContactPilversMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyContactPilversMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyContactPilversMutation,
        DeleteManyContactPilversMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyContactPilversMutation,
          DeleteManyContactPilversMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyContactPilversMutation,
    DeleteManyContactPilversMutationVariables
  >(DeleteManyContactPilversMutationDocument, options);
}
export type DeleteManyContactPilversMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyContactPilversMutation,
    DeleteManyContactPilversMutationVariables
  >;
export const SetProjectChangeOnProjectMutationDocument = gql`
  mutation setProjectChangeOnProjectMutation(
    $input: SetProjectChangeOnProjectInput!
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setProjectChangeOnProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $setProjectChangeOnProjectCreatedByCreatedByContactsFilter: ContactFilter
    $setProjectChangeOnProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectChangeOnProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setProjectChangeOnProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setProjectChangeOnProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setProjectChangeOnProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setProjectChangeOnProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setProjectChangeOnProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setProjectChangeOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setProjectChangeOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setProjectChangeOnProjectHistoriesPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesFilter: ProjectChangeFilter
    $setProjectChangeOnProjectHistoriesSorting: [ProjectChangeSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setProjectChangeOnProject(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesPaging
          filter: $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsPaging
          filter: $setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsFilter
          sorting: $setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $setProjectChangeOnProjectCreatedByCreatedByContactsPaging
          filter: $setProjectChangeOnProjectCreatedByCreatedByContactsFilter
          sorting: $setProjectChangeOnProjectCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setProjectChangeOnProjectCreatedByCreatedByProjectChangesPaging
          filter: $setProjectChangeOnProjectCreatedByCreatedByProjectChangesFilter
          sorting: $setProjectChangeOnProjectCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersPaging
          filter: $setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersFilter
          sorting: $setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setProjectChangeOnProjectCreatedByCreatedByCreatedContractsPaging
          filter: $setProjectChangeOnProjectCreatedByCreatedByCreatedContractsFilter
          sorting: $setProjectChangeOnProjectCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setProjectChangeOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setProjectChangeOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setProjectChangeOnProjectHistoriesPaging
        filter: $setProjectChangeOnProjectHistoriesFilter
        sorting: $setProjectChangeOnProjectHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetProjectChangeOnProjectMutation__
 *
 * To run a mutation, you first call `useSetProjectChangeOnProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectChangeOnProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetProjectChangeOnProjectMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setProjectChangeOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsPaging'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedProjectsSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByContactsPaging: // value for 'setProjectChangeOnProjectCreatedByCreatedByContactsPaging'
 *     setProjectChangeOnProjectCreatedByCreatedByContactsFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByContactsFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByContactsSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByContactsSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByProjectChangesPaging: // value for 'setProjectChangeOnProjectCreatedByCreatedByProjectChangesPaging'
 *     setProjectChangeOnProjectCreatedByCreatedByProjectChangesFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByProjectChangesFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByProjectChangesSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByProjectChangesSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersPaging'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedContractsPaging: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedContractsPaging'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedContractsFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedContractsFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedContractsSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedContractsSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setProjectChangeOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setProjectChangeOnProjectHistoriesPaging: // value for 'setProjectChangeOnProjectHistoriesPaging'
 *     setProjectChangeOnProjectHistoriesFilter: // value for 'setProjectChangeOnProjectHistoriesFilter'
 *     setProjectChangeOnProjectHistoriesSorting: // value for 'setProjectChangeOnProjectHistoriesSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setProjectChangeOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetProjectChangeOnProjectMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetProjectChangeOnProjectMutation,
        SetProjectChangeOnProjectMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetProjectChangeOnProjectMutation,
          SetProjectChangeOnProjectMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetProjectChangeOnProjectMutation,
    SetProjectChangeOnProjectMutationVariables
  >(SetProjectChangeOnProjectMutationDocument, options);
}
export type SetProjectChangeOnProjectMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetProjectChangeOnProjectMutation,
    SetProjectChangeOnProjectMutationVariables
  >;
export const SetCreatedByOnProjectMutationDocument = gql`
  mutation setCreatedByOnProjectMutation(
    $input: SetCreatedByOnProjectInput!
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnProjectCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnProjectHistoriesPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnProjectHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedByOnProject(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnProjectCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnProjectCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnProjectCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $setCreatedByOnProjectCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnProjectCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnProjectCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnProjectCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnProjectCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnProjectCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnProjectCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnProjectCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnProjectCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnProjectCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnProjectCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnProjectCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setCreatedByOnProjectHistoriesPaging
        filter: $setCreatedByOnProjectHistoriesFilter
        sorting: $setCreatedByOnProjectHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedByOnProjectMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnProjectMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnProjectCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnProjectCreatedByCreatedByContactsPaging'
 *     setCreatedByOnProjectCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByContactsFilter'
 *     setCreatedByOnProjectCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByContactsSorting'
 *     setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnProjectCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnProjectCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnProjectCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnProjectCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnProjectHistoriesPaging: // value for 'setCreatedByOnProjectHistoriesPaging'
 *     setCreatedByOnProjectHistoriesFilter: // value for 'setCreatedByOnProjectHistoriesFilter'
 *     setCreatedByOnProjectHistoriesSorting: // value for 'setCreatedByOnProjectHistoriesSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnProjectMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnProjectMutation,
        SetCreatedByOnProjectMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnProjectMutation,
          SetCreatedByOnProjectMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnProjectMutation,
    SetCreatedByOnProjectMutationVariables
  >(SetCreatedByOnProjectMutationDocument, options);
}
export type SetCreatedByOnProjectMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnProjectMutation,
    SetCreatedByOnProjectMutationVariables
  >;
export const AddHistoriesToProjectMutationDocument = gql`
  mutation addHistoriesToProjectMutation(
    $input: AddHistoriesToProjectInput!
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $addHistoriesToProjectCreatedByCreatedByContactsFilter: ContactFilter
    $addHistoriesToProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addHistoriesToProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addHistoriesToProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addHistoriesToProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToProjectHistoriesPaging: OffsetPaging
    $addHistoriesToProjectHistoriesFilter: ProjectChangeFilter
    $addHistoriesToProjectHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addHistoriesToProject(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesPaging
          filter: $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addHistoriesToProjectCreatedByCreatedByCreatedProjectsPaging
          filter: $addHistoriesToProjectCreatedByCreatedByCreatedProjectsFilter
          sorting: $addHistoriesToProjectCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $addHistoriesToProjectCreatedByCreatedByContactsPaging
          filter: $addHistoriesToProjectCreatedByCreatedByContactsFilter
          sorting: $addHistoriesToProjectCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addHistoriesToProjectCreatedByCreatedByProjectChangesPaging
          filter: $addHistoriesToProjectCreatedByCreatedByProjectChangesFilter
          sorting: $addHistoriesToProjectCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addHistoriesToProjectCreatedByCreatedByCreatedWorkersPaging
          filter: $addHistoriesToProjectCreatedByCreatedByCreatedWorkersFilter
          sorting: $addHistoriesToProjectCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addHistoriesToProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addHistoriesToProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addHistoriesToProjectCreatedByCreatedByCreatedContractsPaging
          filter: $addHistoriesToProjectCreatedByCreatedByCreatedContractsFilter
          sorting: $addHistoriesToProjectCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addHistoriesToProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addHistoriesToProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $addHistoriesToProjectHistoriesPaging
        filter: $addHistoriesToProjectHistoriesFilter
        sorting: $addHistoriesToProjectHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAddHistoriesToProjectMutation__
 *
 * To run a mutation, you first call `useAddHistoriesToProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddHistoriesToProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddHistoriesToProjectMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     addHistoriesToProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     addHistoriesToProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedProjectsPaging'
 *     addHistoriesToProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedProjectsFilter'
 *     addHistoriesToProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedProjectsSorting'
 *     addHistoriesToProjectCreatedByCreatedByContactsPaging: // value for 'addHistoriesToProjectCreatedByCreatedByContactsPaging'
 *     addHistoriesToProjectCreatedByCreatedByContactsFilter: // value for 'addHistoriesToProjectCreatedByCreatedByContactsFilter'
 *     addHistoriesToProjectCreatedByCreatedByContactsSorting: // value for 'addHistoriesToProjectCreatedByCreatedByContactsSorting'
 *     addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToProjectCreatedByCreatedByProjectChangesPaging: // value for 'addHistoriesToProjectCreatedByCreatedByProjectChangesPaging'
 *     addHistoriesToProjectCreatedByCreatedByProjectChangesFilter: // value for 'addHistoriesToProjectCreatedByCreatedByProjectChangesFilter'
 *     addHistoriesToProjectCreatedByCreatedByProjectChangesSorting: // value for 'addHistoriesToProjectCreatedByCreatedByProjectChangesSorting'
 *     addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedWorkersPaging'
 *     addHistoriesToProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedWorkersFilter'
 *     addHistoriesToProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedWorkersSorting'
 *     addHistoriesToProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToProjectCreatedByCreatedByCreatedContractsPaging: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedContractsPaging'
 *     addHistoriesToProjectCreatedByCreatedByCreatedContractsFilter: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedContractsFilter'
 *     addHistoriesToProjectCreatedByCreatedByCreatedContractsSorting: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedContractsSorting'
 *     addHistoriesToProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToProjectHistoriesPaging: // value for 'addHistoriesToProjectHistoriesPaging'
 *     addHistoriesToProjectHistoriesFilter: // value for 'addHistoriesToProjectHistoriesFilter'
 *     addHistoriesToProjectHistoriesSorting: // value for 'addHistoriesToProjectHistoriesSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddHistoriesToProjectMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddHistoriesToProjectMutation,
        AddHistoriesToProjectMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddHistoriesToProjectMutation,
          AddHistoriesToProjectMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddHistoriesToProjectMutation,
    AddHistoriesToProjectMutationVariables
  >(AddHistoriesToProjectMutationDocument, options);
}
export type AddHistoriesToProjectMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddHistoriesToProjectMutation,
    AddHistoriesToProjectMutationVariables
  >;
export const SetHistoriesOnProjectMutationDocument = gql`
  mutation setHistoriesOnProjectMutation(
    $input: SetHistoriesOnProjectInput!
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $setHistoriesOnProjectCreatedByCreatedByContactsFilter: ContactFilter
    $setHistoriesOnProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setHistoriesOnProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnProjectHistoriesPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnProjectHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setHistoriesOnProject(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesPaging
          filter: $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setHistoriesOnProjectCreatedByCreatedByCreatedProjectsPaging
          filter: $setHistoriesOnProjectCreatedByCreatedByCreatedProjectsFilter
          sorting: $setHistoriesOnProjectCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $setHistoriesOnProjectCreatedByCreatedByContactsPaging
          filter: $setHistoriesOnProjectCreatedByCreatedByContactsFilter
          sorting: $setHistoriesOnProjectCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setHistoriesOnProjectCreatedByCreatedByProjectChangesPaging
          filter: $setHistoriesOnProjectCreatedByCreatedByProjectChangesFilter
          sorting: $setHistoriesOnProjectCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setHistoriesOnProjectCreatedByCreatedByCreatedWorkersPaging
          filter: $setHistoriesOnProjectCreatedByCreatedByCreatedWorkersFilter
          sorting: $setHistoriesOnProjectCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setHistoriesOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setHistoriesOnProjectCreatedByCreatedByCreatedContractsPaging
          filter: $setHistoriesOnProjectCreatedByCreatedByCreatedContractsFilter
          sorting: $setHistoriesOnProjectCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setHistoriesOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setHistoriesOnProjectHistoriesPaging
        filter: $setHistoriesOnProjectHistoriesFilter
        sorting: $setHistoriesOnProjectHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetHistoriesOnProjectMutation__
 *
 * To run a mutation, you first call `useSetHistoriesOnProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetHistoriesOnProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetHistoriesOnProjectMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedProjectsPaging'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedProjectsFilter'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedProjectsSorting'
 *     setHistoriesOnProjectCreatedByCreatedByContactsPaging: // value for 'setHistoriesOnProjectCreatedByCreatedByContactsPaging'
 *     setHistoriesOnProjectCreatedByCreatedByContactsFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByContactsFilter'
 *     setHistoriesOnProjectCreatedByCreatedByContactsSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByContactsSorting'
 *     setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnProjectCreatedByCreatedByProjectChangesPaging: // value for 'setHistoriesOnProjectCreatedByCreatedByProjectChangesPaging'
 *     setHistoriesOnProjectCreatedByCreatedByProjectChangesFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByProjectChangesFilter'
 *     setHistoriesOnProjectCreatedByCreatedByProjectChangesSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByProjectChangesSorting'
 *     setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedWorkersPaging'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedWorkersFilter'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedWorkersSorting'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedContractsPaging: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedContractsPaging'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedContractsFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedContractsFilter'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedContractsSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedContractsSorting'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnProjectHistoriesPaging: // value for 'setHistoriesOnProjectHistoriesPaging'
 *     setHistoriesOnProjectHistoriesFilter: // value for 'setHistoriesOnProjectHistoriesFilter'
 *     setHistoriesOnProjectHistoriesSorting: // value for 'setHistoriesOnProjectHistoriesSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetHistoriesOnProjectMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetHistoriesOnProjectMutation,
        SetHistoriesOnProjectMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetHistoriesOnProjectMutation,
          SetHistoriesOnProjectMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetHistoriesOnProjectMutation,
    SetHistoriesOnProjectMutationVariables
  >(SetHistoriesOnProjectMutationDocument, options);
}
export type SetHistoriesOnProjectMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetHistoriesOnProjectMutation,
    SetHistoriesOnProjectMutationVariables
  >;
export const RemoveProjectChangeFromProjectMutationDocument = gql`
  mutation removeProjectChangeFromProjectMutation(
    $input: RemoveProjectChangeFromProjectInput!
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $removeProjectChangeFromProjectCreatedByCreatedByContactsFilter: ContactFilter
    $removeProjectChangeFromProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeProjectChangeFromProjectHistoriesPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesFilter: ProjectChangeFilter
    $removeProjectChangeFromProjectHistoriesSorting: [ProjectChangeSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeProjectChangeFromProject(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsPaging
          filter: $removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $removeProjectChangeFromProjectCreatedByCreatedByContactsPaging
          filter: $removeProjectChangeFromProjectCreatedByCreatedByContactsFilter
          sorting: $removeProjectChangeFromProjectCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesPaging
          filter: $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesFilter
          sorting: $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersPaging
          filter: $removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsPaging
          filter: $removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsFilter
          sorting: $removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeProjectChangeFromProjectHistoriesPaging
        filter: $removeProjectChangeFromProjectHistoriesFilter
        sorting: $removeProjectChangeFromProjectHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveProjectChangeFromProjectMutation__
 *
 * To run a mutation, you first call `useRemoveProjectChangeFromProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectChangeFromProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveProjectChangeFromProjectMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeProjectChangeFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsPaging'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedProjectsSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByContactsPaging: // value for 'removeProjectChangeFromProjectCreatedByCreatedByContactsPaging'
 *     removeProjectChangeFromProjectCreatedByCreatedByContactsFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByContactsFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByContactsSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByContactsSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByProjectChangesPaging: // value for 'removeProjectChangeFromProjectCreatedByCreatedByProjectChangesPaging'
 *     removeProjectChangeFromProjectCreatedByCreatedByProjectChangesFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByProjectChangesFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByProjectChangesSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByProjectChangesSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersPaging'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsPaging: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsPaging'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeProjectChangeFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeProjectChangeFromProjectHistoriesPaging: // value for 'removeProjectChangeFromProjectHistoriesPaging'
 *     removeProjectChangeFromProjectHistoriesFilter: // value for 'removeProjectChangeFromProjectHistoriesFilter'
 *     removeProjectChangeFromProjectHistoriesSorting: // value for 'removeProjectChangeFromProjectHistoriesSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeProjectChangeFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveProjectChangeFromProjectMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveProjectChangeFromProjectMutation,
        RemoveProjectChangeFromProjectMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveProjectChangeFromProjectMutation,
          RemoveProjectChangeFromProjectMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveProjectChangeFromProjectMutation,
    RemoveProjectChangeFromProjectMutationVariables
  >(RemoveProjectChangeFromProjectMutationDocument, options);
}
export type RemoveProjectChangeFromProjectMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveProjectChangeFromProjectMutation,
    RemoveProjectChangeFromProjectMutationVariables
  >;
export const RemoveCreatedByFromProjectMutationDocument = gql`
  mutation removeCreatedByFromProjectMutation(
    $input: RemoveCreatedByFromProjectInput!
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromProjectCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromProjectHistoriesPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromProjectHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedByFromProject(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $removeCreatedByFromProjectCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromProjectCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromProjectCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromProjectCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromProjectCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromProjectCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromProjectCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromProjectCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromProjectCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeCreatedByFromProjectHistoriesPaging
        filter: $removeCreatedByFromProjectHistoriesFilter
        sorting: $removeCreatedByFromProjectHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromProjectMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromProjectMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromProjectCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromProjectCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromProjectCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromProjectCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromProjectHistoriesPaging: // value for 'removeCreatedByFromProjectHistoriesPaging'
 *     removeCreatedByFromProjectHistoriesFilter: // value for 'removeCreatedByFromProjectHistoriesFilter'
 *     removeCreatedByFromProjectHistoriesSorting: // value for 'removeCreatedByFromProjectHistoriesSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromProjectMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromProjectMutation,
        RemoveCreatedByFromProjectMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromProjectMutation,
          RemoveCreatedByFromProjectMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromProjectMutation,
    RemoveCreatedByFromProjectMutationVariables
  >(RemoveCreatedByFromProjectMutationDocument, options);
}
export type RemoveCreatedByFromProjectMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromProjectMutation,
    RemoveCreatedByFromProjectMutationVariables
  >;
export const RemoveHistoriesFromProjectMutationDocument = gql`
  mutation removeHistoriesFromProjectMutation(
    $input: RemoveHistoriesFromProjectInput!
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $removeHistoriesFromProjectCreatedByCreatedByContactsFilter: ContactFilter
    $removeHistoriesFromProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromProjectHistoriesPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromProjectHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeHistoriesFromProject(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsPaging
          filter: $removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $removeHistoriesFromProjectCreatedByCreatedByContactsPaging
          filter: $removeHistoriesFromProjectCreatedByCreatedByContactsFilter
          sorting: $removeHistoriesFromProjectCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeHistoriesFromProjectCreatedByCreatedByProjectChangesPaging
          filter: $removeHistoriesFromProjectCreatedByCreatedByProjectChangesFilter
          sorting: $removeHistoriesFromProjectCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersPaging
          filter: $removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeHistoriesFromProjectCreatedByCreatedByCreatedContractsPaging
          filter: $removeHistoriesFromProjectCreatedByCreatedByCreatedContractsFilter
          sorting: $removeHistoriesFromProjectCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeHistoriesFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeHistoriesFromProjectHistoriesPaging
        filter: $removeHistoriesFromProjectHistoriesFilter
        sorting: $removeHistoriesFromProjectHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveHistoriesFromProjectMutation__
 *
 * To run a mutation, you first call `useRemoveHistoriesFromProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHistoriesFromProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveHistoriesFromProjectMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsPaging'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedProjectsSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByContactsPaging: // value for 'removeHistoriesFromProjectCreatedByCreatedByContactsPaging'
 *     removeHistoriesFromProjectCreatedByCreatedByContactsFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByContactsFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByContactsSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByContactsSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByProjectChangesPaging: // value for 'removeHistoriesFromProjectCreatedByCreatedByProjectChangesPaging'
 *     removeHistoriesFromProjectCreatedByCreatedByProjectChangesFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByProjectChangesFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByProjectChangesSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByProjectChangesSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersPaging'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedContractsPaging: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedContractsPaging'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedContractsFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedContractsFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedContractsSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedContractsSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromProjectHistoriesPaging: // value for 'removeHistoriesFromProjectHistoriesPaging'
 *     removeHistoriesFromProjectHistoriesFilter: // value for 'removeHistoriesFromProjectHistoriesFilter'
 *     removeHistoriesFromProjectHistoriesSorting: // value for 'removeHistoriesFromProjectHistoriesSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveHistoriesFromProjectMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveHistoriesFromProjectMutation,
        RemoveHistoriesFromProjectMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveHistoriesFromProjectMutation,
          RemoveHistoriesFromProjectMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveHistoriesFromProjectMutation,
    RemoveHistoriesFromProjectMutationVariables
  >(RemoveHistoriesFromProjectMutationDocument, options);
}
export type RemoveHistoriesFromProjectMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveHistoriesFromProjectMutation,
    RemoveHistoriesFromProjectMutationVariables
  >;
export const CreateOneProjectMutationDocument = gql`
  mutation createOneProjectMutation(
    $input: CreateOneProjectInput!
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createOneProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createOneProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createOneProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createOneProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createOneProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $createOneProjectCreatedByCreatedByContactsFilter: ContactFilter
    $createOneProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createOneProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createOneProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createOneProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createOneProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createOneProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createOneProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createOneProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneProjectHistoriesPaging: OffsetPaging
    $createOneProjectHistoriesFilter: ProjectChangeFilter
    $createOneProjectHistoriesSorting: [ProjectChangeSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createOneProject(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createOneProjectCreatedByCreatedByCreatedCompaniesPaging
          filter: $createOneProjectCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createOneProjectCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createOneProjectCreatedByCreatedByCreatedProjectsPaging
          filter: $createOneProjectCreatedByCreatedByCreatedProjectsFilter
          sorting: $createOneProjectCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $createOneProjectCreatedByCreatedByContactsPaging
          filter: $createOneProjectCreatedByCreatedByContactsFilter
          sorting: $createOneProjectCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createOneProjectCreatedByCreatedByProjectChangesPaging
          filter: $createOneProjectCreatedByCreatedByProjectChangesFilter
          sorting: $createOneProjectCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createOneProjectCreatedByCreatedByCreatedWorkersPaging
          filter: $createOneProjectCreatedByCreatedByCreatedWorkersFilter
          sorting: $createOneProjectCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createOneProjectCreatedByCreatedByCreatedContractsPaging
          filter: $createOneProjectCreatedByCreatedByCreatedContractsFilter
          sorting: $createOneProjectCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createOneProjectCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createOneProjectCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createOneProjectCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $createOneProjectHistoriesPaging
        filter: $createOneProjectHistoriesFilter
        sorting: $createOneProjectHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateOneProjectMutation__
 *
 * To run a mutation, you first call `useCreateOneProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneProjectMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'createOneProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     createOneProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'createOneProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     createOneProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'createOneProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'createOneProjectCreatedByCreatedByCreatedProjectsPaging'
 *     createOneProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'createOneProjectCreatedByCreatedByCreatedProjectsFilter'
 *     createOneProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'createOneProjectCreatedByCreatedByCreatedProjectsSorting'
 *     createOneProjectCreatedByCreatedByContactsPaging: // value for 'createOneProjectCreatedByCreatedByContactsPaging'
 *     createOneProjectCreatedByCreatedByContactsFilter: // value for 'createOneProjectCreatedByCreatedByContactsFilter'
 *     createOneProjectCreatedByCreatedByContactsSorting: // value for 'createOneProjectCreatedByCreatedByContactsSorting'
 *     createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneProjectCreatedByCreatedByProjectChangesPaging: // value for 'createOneProjectCreatedByCreatedByProjectChangesPaging'
 *     createOneProjectCreatedByCreatedByProjectChangesFilter: // value for 'createOneProjectCreatedByCreatedByProjectChangesFilter'
 *     createOneProjectCreatedByCreatedByProjectChangesSorting: // value for 'createOneProjectCreatedByCreatedByProjectChangesSorting'
 *     createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'createOneProjectCreatedByCreatedByCreatedWorkersPaging'
 *     createOneProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'createOneProjectCreatedByCreatedByCreatedWorkersFilter'
 *     createOneProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'createOneProjectCreatedByCreatedByCreatedWorkersSorting'
 *     createOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneProjectCreatedByCreatedByCreatedContractsPaging: // value for 'createOneProjectCreatedByCreatedByCreatedContractsPaging'
 *     createOneProjectCreatedByCreatedByCreatedContractsFilter: // value for 'createOneProjectCreatedByCreatedByCreatedContractsFilter'
 *     createOneProjectCreatedByCreatedByCreatedContractsSorting: // value for 'createOneProjectCreatedByCreatedByCreatedContractsSorting'
 *     createOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createOneProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     createOneProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createOneProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     createOneProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createOneProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     createOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneProjectHistoriesPaging: // value for 'createOneProjectHistoriesPaging'
 *     createOneProjectHistoriesFilter: // value for 'createOneProjectHistoriesFilter'
 *     createOneProjectHistoriesSorting: // value for 'createOneProjectHistoriesSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneProjectMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneProjectMutation,
        CreateOneProjectMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneProjectMutation,
          CreateOneProjectMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneProjectMutation,
    CreateOneProjectMutationVariables
  >(CreateOneProjectMutationDocument, options);
}
export type CreateOneProjectMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneProjectMutation,
    CreateOneProjectMutationVariables
  >;
export const CreateManyProjectsMutationDocument = gql`
  mutation createManyProjectsMutation(
    $input: CreateManyProjectsInput!
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyProjectsCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createManyProjectsCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createManyProjectsCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyProjectsCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createManyProjectsCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createManyProjectsCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createManyProjectsCreatedByCreatedByContactsPaging: OffsetPaging
    $createManyProjectsCreatedByCreatedByContactsFilter: ContactFilter
    $createManyProjectsCreatedByCreatedByContactsSorting: [ContactSort!]
    $createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyProjectsCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createManyProjectsCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createManyProjectsCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyProjectsCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createManyProjectsCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createManyProjectsCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createManyProjectsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyProjectsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyProjectsCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createManyProjectsCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createManyProjectsCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createManyProjectsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyProjectsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyProjectsCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createManyProjectsCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyProjectsCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyProjectsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyProjectsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyProjectsHistoriesPaging: OffsetPaging
    $createManyProjectsHistoriesFilter: ProjectChangeFilter
    $createManyProjectsHistoriesSorting: [ProjectChangeSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createManyProjects(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createManyProjectsCreatedByCreatedByCreatedCompaniesPaging
          filter: $createManyProjectsCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createManyProjectsCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createManyProjectsCreatedByCreatedByCreatedProjectsPaging
          filter: $createManyProjectsCreatedByCreatedByCreatedProjectsFilter
          sorting: $createManyProjectsCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $createManyProjectsCreatedByCreatedByContactsPaging
          filter: $createManyProjectsCreatedByCreatedByContactsFilter
          sorting: $createManyProjectsCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createManyProjectsCreatedByCreatedByProjectChangesPaging
          filter: $createManyProjectsCreatedByCreatedByProjectChangesFilter
          sorting: $createManyProjectsCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createManyProjectsCreatedByCreatedByCreatedWorkersPaging
          filter: $createManyProjectsCreatedByCreatedByCreatedWorkersFilter
          sorting: $createManyProjectsCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createManyProjectsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createManyProjectsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createManyProjectsCreatedByCreatedByCreatedContractsPaging
          filter: $createManyProjectsCreatedByCreatedByCreatedContractsFilter
          sorting: $createManyProjectsCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createManyProjectsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createManyProjectsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createManyProjectsCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createManyProjectsCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createManyProjectsCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createManyProjectsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createManyProjectsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $createManyProjectsHistoriesPaging
        filter: $createManyProjectsHistoriesFilter
        sorting: $createManyProjectsHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateManyProjectsMutation__
 *
 * To run a mutation, you first call `useCreateManyProjectsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyProjectsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyProjectsMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyProjectsProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyProjectsCreatedByCreatedByCreatedCompaniesPaging: // value for 'createManyProjectsCreatedByCreatedByCreatedCompaniesPaging'
 *     createManyProjectsCreatedByCreatedByCreatedCompaniesFilter: // value for 'createManyProjectsCreatedByCreatedByCreatedCompaniesFilter'
 *     createManyProjectsCreatedByCreatedByCreatedCompaniesSorting: // value for 'createManyProjectsCreatedByCreatedByCreatedCompaniesSorting'
 *     createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyProjectsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyProjectsCreatedByCreatedByCreatedProjectsPaging: // value for 'createManyProjectsCreatedByCreatedByCreatedProjectsPaging'
 *     createManyProjectsCreatedByCreatedByCreatedProjectsFilter: // value for 'createManyProjectsCreatedByCreatedByCreatedProjectsFilter'
 *     createManyProjectsCreatedByCreatedByCreatedProjectsSorting: // value for 'createManyProjectsCreatedByCreatedByCreatedProjectsSorting'
 *     createManyProjectsCreatedByCreatedByContactsPaging: // value for 'createManyProjectsCreatedByCreatedByContactsPaging'
 *     createManyProjectsCreatedByCreatedByContactsFilter: // value for 'createManyProjectsCreatedByCreatedByContactsFilter'
 *     createManyProjectsCreatedByCreatedByContactsSorting: // value for 'createManyProjectsCreatedByCreatedByContactsSorting'
 *     createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyProjectsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyProjectsCreatedByCreatedByProjectChangesPaging: // value for 'createManyProjectsCreatedByCreatedByProjectChangesPaging'
 *     createManyProjectsCreatedByCreatedByProjectChangesFilter: // value for 'createManyProjectsCreatedByCreatedByProjectChangesFilter'
 *     createManyProjectsCreatedByCreatedByProjectChangesSorting: // value for 'createManyProjectsCreatedByCreatedByProjectChangesSorting'
 *     createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyProjectsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyProjectsCreatedByCreatedByCreatedWorkersPaging: // value for 'createManyProjectsCreatedByCreatedByCreatedWorkersPaging'
 *     createManyProjectsCreatedByCreatedByCreatedWorkersFilter: // value for 'createManyProjectsCreatedByCreatedByCreatedWorkersFilter'
 *     createManyProjectsCreatedByCreatedByCreatedWorkersSorting: // value for 'createManyProjectsCreatedByCreatedByCreatedWorkersSorting'
 *     createManyProjectsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyProjectsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyProjectsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyProjectsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyProjectsCreatedByCreatedByCreatedContractsPaging: // value for 'createManyProjectsCreatedByCreatedByCreatedContractsPaging'
 *     createManyProjectsCreatedByCreatedByCreatedContractsFilter: // value for 'createManyProjectsCreatedByCreatedByCreatedContractsFilter'
 *     createManyProjectsCreatedByCreatedByCreatedContractsSorting: // value for 'createManyProjectsCreatedByCreatedByCreatedContractsSorting'
 *     createManyProjectsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyProjectsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyProjectsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyProjectsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyProjectsCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createManyProjectsCreatedByCreatedByCreatedMiddlemansPaging'
 *     createManyProjectsCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createManyProjectsCreatedByCreatedByCreatedMiddlemansFilter'
 *     createManyProjectsCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createManyProjectsCreatedByCreatedByCreatedMiddlemansSorting'
 *     createManyProjectsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyProjectsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyProjectsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyProjectsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyProjectsHistoriesPaging: // value for 'createManyProjectsHistoriesPaging'
 *     createManyProjectsHistoriesFilter: // value for 'createManyProjectsHistoriesFilter'
 *     createManyProjectsHistoriesSorting: // value for 'createManyProjectsHistoriesSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyProjectsHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyProjectsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyProjectsMutation,
        CreateManyProjectsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyProjectsMutation,
          CreateManyProjectsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyProjectsMutation,
    CreateManyProjectsMutationVariables
  >(CreateManyProjectsMutationDocument, options);
}
export type CreateManyProjectsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyProjectsMutation,
    CreateManyProjectsMutationVariables
  >;
export const UpdateOneProjectMutationDocument = gql`
  mutation updateOneProjectMutation(
    $input: UpdateOneProjectInput!
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $updateOneProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $updateOneProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $updateOneProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $updateOneProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $updateOneProjectCreatedByCreatedByContactsFilter: ContactFilter
    $updateOneProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $updateOneProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $updateOneProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $updateOneProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $updateOneProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $updateOneProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $updateOneProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $updateOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneProjectHistoriesPaging: OffsetPaging
    $updateOneProjectHistoriesFilter: ProjectChangeFilter
    $updateOneProjectHistoriesSorting: [ProjectChangeSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: OffsetPaging
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: ContactFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: [ContactSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    updateOneProject(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
      projectChange {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Description
        State
        modifiedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging
            filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter
            sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging
            filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter
            sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging
            filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter
            sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging
            filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter
            sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging
            filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter
            sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging
            filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter
            sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
            filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
            sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $updateOneProjectCreatedByCreatedByCreatedCompaniesPaging
          filter: $updateOneProjectCreatedByCreatedByCreatedCompaniesFilter
          sorting: $updateOneProjectCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $updateOneProjectCreatedByCreatedByCreatedProjectsPaging
          filter: $updateOneProjectCreatedByCreatedByCreatedProjectsFilter
          sorting: $updateOneProjectCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        contacts(
          paging: $updateOneProjectCreatedByCreatedByContactsPaging
          filter: $updateOneProjectCreatedByCreatedByContactsFilter
          sorting: $updateOneProjectCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $updateOneProjectCreatedByCreatedByProjectChangesPaging
          filter: $updateOneProjectCreatedByCreatedByProjectChangesFilter
          sorting: $updateOneProjectCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $updateOneProjectCreatedByCreatedByCreatedWorkersPaging
          filter: $updateOneProjectCreatedByCreatedByCreatedWorkersFilter
          sorting: $updateOneProjectCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $updateOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $updateOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $updateOneProjectCreatedByCreatedByCreatedContractsPaging
          filter: $updateOneProjectCreatedByCreatedByCreatedContractsFilter
          sorting: $updateOneProjectCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $updateOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $updateOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $updateOneProjectCreatedByCreatedByCreatedMiddlemansPaging
          filter: $updateOneProjectCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $updateOneProjectCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $updateOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $updateOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $updateOneProjectHistoriesPaging
        filter: $updateOneProjectHistoriesFilter
        sorting: $updateOneProjectHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Description
          State
          modifiedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging
              filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter
              sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging
              filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter
              sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            contacts(
              paging: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging
              filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter
              sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging
              filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter
              sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging
              filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter
              sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging
              filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter
              sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging
              filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter
              sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useUpdateOneProjectMutation__
 *
 * To run a mutation, you first call `useUpdateOneProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneProjectMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsPaging'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneProjectProjectChangeProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'updateOneProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     updateOneProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'updateOneProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     updateOneProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'updateOneProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'updateOneProjectCreatedByCreatedByCreatedProjectsPaging'
 *     updateOneProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'updateOneProjectCreatedByCreatedByCreatedProjectsFilter'
 *     updateOneProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'updateOneProjectCreatedByCreatedByCreatedProjectsSorting'
 *     updateOneProjectCreatedByCreatedByContactsPaging: // value for 'updateOneProjectCreatedByCreatedByContactsPaging'
 *     updateOneProjectCreatedByCreatedByContactsFilter: // value for 'updateOneProjectCreatedByCreatedByContactsFilter'
 *     updateOneProjectCreatedByCreatedByContactsSorting: // value for 'updateOneProjectCreatedByCreatedByContactsSorting'
 *     updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneProjectCreatedByCreatedByProjectChangesPaging: // value for 'updateOneProjectCreatedByCreatedByProjectChangesPaging'
 *     updateOneProjectCreatedByCreatedByProjectChangesFilter: // value for 'updateOneProjectCreatedByCreatedByProjectChangesFilter'
 *     updateOneProjectCreatedByCreatedByProjectChangesSorting: // value for 'updateOneProjectCreatedByCreatedByProjectChangesSorting'
 *     updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'updateOneProjectCreatedByCreatedByCreatedWorkersPaging'
 *     updateOneProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'updateOneProjectCreatedByCreatedByCreatedWorkersFilter'
 *     updateOneProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'updateOneProjectCreatedByCreatedByCreatedWorkersSorting'
 *     updateOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneProjectCreatedByCreatedByCreatedContractsPaging: // value for 'updateOneProjectCreatedByCreatedByCreatedContractsPaging'
 *     updateOneProjectCreatedByCreatedByCreatedContractsFilter: // value for 'updateOneProjectCreatedByCreatedByCreatedContractsFilter'
 *     updateOneProjectCreatedByCreatedByCreatedContractsSorting: // value for 'updateOneProjectCreatedByCreatedByCreatedContractsSorting'
 *     updateOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'updateOneProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     updateOneProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'updateOneProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     updateOneProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'updateOneProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     updateOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneProjectHistoriesPaging: // value for 'updateOneProjectHistoriesPaging'
 *     updateOneProjectHistoriesFilter: // value for 'updateOneProjectHistoriesFilter'
 *     updateOneProjectHistoriesSorting: // value for 'updateOneProjectHistoriesSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesPaging'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsPaging'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedProjectsSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsPaging'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesPaging'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersPaging'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsPaging'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansPaging'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansSorting'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneProjectHistoriesHistoriesNodesNodesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneProjectMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneProjectMutation,
        UpdateOneProjectMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneProjectMutation,
          UpdateOneProjectMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneProjectMutation,
    UpdateOneProjectMutationVariables
  >(UpdateOneProjectMutationDocument, options);
}
export type UpdateOneProjectMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneProjectMutation,
    UpdateOneProjectMutationVariables
  >;
export const UpdateManyProjectsMutationDocument = gql`
  mutation updateManyProjectsMutation($input: UpdateManyProjectsInput!) {
    updateManyProjects(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyProjectsMutation__
 *
 * To run a mutation, you first call `useUpdateManyProjectsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyProjectsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyProjectsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyProjectsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyProjectsMutation,
        UpdateManyProjectsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyProjectsMutation,
          UpdateManyProjectsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyProjectsMutation,
    UpdateManyProjectsMutationVariables
  >(UpdateManyProjectsMutationDocument, options);
}
export type UpdateManyProjectsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyProjectsMutation,
    UpdateManyProjectsMutationVariables
  >;
export const DeleteOneProjectMutationDocument = gql`
  mutation deleteOneProjectMutation($input: DeleteOneProjectInput!) {
    deleteOneProject(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Address
      Type
      Language
      Manager
      ManagerContact
    }
  }
`;

/**
 * __useDeleteOneProjectMutation__
 *
 * To run a mutation, you first call `useDeleteOneProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneProjectMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneProjectMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneProjectMutation,
        DeleteOneProjectMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneProjectMutation,
          DeleteOneProjectMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneProjectMutation,
    DeleteOneProjectMutationVariables
  >(DeleteOneProjectMutationDocument, options);
}
export type DeleteOneProjectMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneProjectMutation,
    DeleteOneProjectMutationVariables
  >;
export const DeleteManyProjectsMutationDocument = gql`
  mutation deleteManyProjectsMutation($input: DeleteManyProjectsInput!) {
    deleteManyProjects(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyProjectsMutation__
 *
 * To run a mutation, you first call `useDeleteManyProjectsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyProjectsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyProjectsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyProjectsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyProjectsMutation,
        DeleteManyProjectsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyProjectsMutation,
          DeleteManyProjectsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyProjectsMutation,
    DeleteManyProjectsMutationVariables
  >(DeleteManyProjectsMutationDocument, options);
}
export type DeleteManyProjectsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyProjectsMutation,
    DeleteManyProjectsMutationVariables
  >;
export const SetProjectOnProjectChangeMutationDocument = gql`
  mutation setProjectOnProjectChangeMutation(
    $input: SetProjectOnProjectChangeInput!
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsFilter: ContactFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setProjectOnProjectChangeProjectProjectHistoriesPaging: OffsetPaging
    $setProjectOnProjectChangeProjectProjectHistoriesFilter: ProjectChangeFilter
    $setProjectOnProjectChangeProjectProjectHistoriesSorting: [ProjectChangeSort!]
    $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setProjectOnProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $setProjectOnProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectOnProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $setProjectOnProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setProjectOnProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $setProjectOnProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $setProjectOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setProjectOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setProjectOnProjectChange(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Description
      State
      project {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Address
        Type
        Language
        Manager
        ManagerContact
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging
            filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter
            sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging
            filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter
            sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsPaging
            filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsFilter
            sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging
            filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter
            sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging
            filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter
            sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging
            filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter
            sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging
            filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $setProjectOnProjectChangeProjectProjectHistoriesPaging
          filter: $setProjectOnProjectChangeProjectProjectHistoriesFilter
          sorting: $setProjectOnProjectChangeProjectProjectHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      modifiedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesPaging
          filter: $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesFilter
          sorting: $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsPaging
          filter: $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsFilter
          sorting: $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            histories(
              paging: $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        contacts(
          paging: $setProjectOnProjectChangeModifiedByModifiedByContactsPaging
          filter: $setProjectOnProjectChangeModifiedByModifiedByContactsFilter
          sorting: $setProjectOnProjectChangeModifiedByModifiedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setProjectOnProjectChangeModifiedByModifiedByProjectChangesPaging
          filter: $setProjectOnProjectChangeModifiedByModifiedByProjectChangesFilter
          sorting: $setProjectOnProjectChangeModifiedByModifiedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersPaging
          filter: $setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersFilter
          sorting: $setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setProjectOnProjectChangeModifiedByModifiedByCreatedContractsPaging
          filter: $setProjectOnProjectChangeModifiedByModifiedByCreatedContractsFilter
          sorting: $setProjectOnProjectChangeModifiedByModifiedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setProjectOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setProjectOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
          filter: $setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
          sorting: $setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetProjectOnProjectChangeMutation__
 *
 * To run a mutation, you first call `useSetProjectOnProjectChangeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectOnProjectChangeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetProjectOnProjectChangeMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsPaging: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsPaging'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setProjectOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setProjectOnProjectChangeProjectProjectHistoriesPaging: // value for 'setProjectOnProjectChangeProjectProjectHistoriesPaging'
 *     setProjectOnProjectChangeProjectProjectHistoriesFilter: // value for 'setProjectOnProjectChangeProjectProjectHistoriesFilter'
 *     setProjectOnProjectChangeProjectProjectHistoriesSorting: // value for 'setProjectOnProjectChangeProjectProjectHistoriesSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByContactsPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByContactsPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByContactsFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByContactsFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByContactsSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByContactsSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setProjectOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetProjectOnProjectChangeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetProjectOnProjectChangeMutation,
        SetProjectOnProjectChangeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetProjectOnProjectChangeMutation,
          SetProjectOnProjectChangeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetProjectOnProjectChangeMutation,
    SetProjectOnProjectChangeMutationVariables
  >(SetProjectOnProjectChangeMutationDocument, options);
}
export type SetProjectOnProjectChangeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetProjectOnProjectChangeMutation,
    SetProjectOnProjectChangeMutationVariables
  >;
export const SetModifiedByOnProjectChangeMutationDocument = gql`
  mutation setModifiedByOnProjectChangeMutation(
    $input: SetModifiedByOnProjectChangeInput!
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsFilter: ContactFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setModifiedByOnProjectChangeProjectProjectHistoriesPaging: OffsetPaging
    $setModifiedByOnProjectChangeProjectProjectHistoriesFilter: ProjectChangeFilter
    $setModifiedByOnProjectChangeProjectProjectHistoriesSorting: [ProjectChangeSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setModifiedByOnProjectChange(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Description
      State
      project {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Address
        Type
        Language
        Manager
        ManagerContact
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging
            filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter
            sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging
            filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter
            sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsPaging
            filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsFilter
            sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging
            filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter
            sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging
            filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter
            sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging
            filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter
            sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging
            filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $setModifiedByOnProjectChangeProjectProjectHistoriesPaging
          filter: $setModifiedByOnProjectChangeProjectProjectHistoriesFilter
          sorting: $setModifiedByOnProjectChangeProjectProjectHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      modifiedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesPaging
          filter: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesFilter
          sorting: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsPaging
          filter: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsFilter
          sorting: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            histories(
              paging: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        contacts(
          paging: $setModifiedByOnProjectChangeModifiedByModifiedByContactsPaging
          filter: $setModifiedByOnProjectChangeModifiedByModifiedByContactsFilter
          sorting: $setModifiedByOnProjectChangeModifiedByModifiedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesPaging
          filter: $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesFilter
          sorting: $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersPaging
          filter: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersFilter
          sorting: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsPaging
          filter: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsFilter
          sorting: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
          filter: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
          sorting: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetModifiedByOnProjectChangeMutation__
 *
 * To run a mutation, you first call `useSetModifiedByOnProjectChangeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetModifiedByOnProjectChangeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetModifiedByOnProjectChangeMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsPaging: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsPaging'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setModifiedByOnProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setModifiedByOnProjectChangeProjectProjectHistoriesPaging: // value for 'setModifiedByOnProjectChangeProjectProjectHistoriesPaging'
 *     setModifiedByOnProjectChangeProjectProjectHistoriesFilter: // value for 'setModifiedByOnProjectChangeProjectProjectHistoriesFilter'
 *     setModifiedByOnProjectChangeProjectProjectHistoriesSorting: // value for 'setModifiedByOnProjectChangeProjectProjectHistoriesSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByContactsPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByContactsPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByContactsFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByContactsFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByContactsSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByContactsSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setModifiedByOnProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetModifiedByOnProjectChangeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetModifiedByOnProjectChangeMutation,
        SetModifiedByOnProjectChangeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetModifiedByOnProjectChangeMutation,
          SetModifiedByOnProjectChangeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetModifiedByOnProjectChangeMutation,
    SetModifiedByOnProjectChangeMutationVariables
  >(SetModifiedByOnProjectChangeMutationDocument, options);
}
export type SetModifiedByOnProjectChangeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetModifiedByOnProjectChangeMutation,
    SetModifiedByOnProjectChangeMutationVariables
  >;
export const RemoveProjectFromProjectChangeMutationDocument = gql`
  mutation removeProjectFromProjectChangeMutation(
    $input: RemoveProjectFromProjectChangeInput!
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsFilter: ContactFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeProjectFromProjectChangeProjectProjectHistoriesPaging: OffsetPaging
    $removeProjectFromProjectChangeProjectProjectHistoriesFilter: ProjectChangeFilter
    $removeProjectFromProjectChangeProjectProjectHistoriesSorting: [ProjectChangeSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $removeProjectFromProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeProjectFromProjectChange(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Description
      State
      project {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Address
        Type
        Language
        Manager
        ManagerContact
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging
            filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter
            sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging
            filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter
            sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsPaging
            filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsFilter
            sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging
            filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter
            sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging
            filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter
            sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging
            filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter
            sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging
            filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $removeProjectFromProjectChangeProjectProjectHistoriesPaging
          filter: $removeProjectFromProjectChangeProjectProjectHistoriesFilter
          sorting: $removeProjectFromProjectChangeProjectProjectHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      modifiedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesPaging
          filter: $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesFilter
          sorting: $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsPaging
          filter: $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsFilter
          sorting: $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            histories(
              paging: $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        contacts(
          paging: $removeProjectFromProjectChangeModifiedByModifiedByContactsPaging
          filter: $removeProjectFromProjectChangeModifiedByModifiedByContactsFilter
          sorting: $removeProjectFromProjectChangeModifiedByModifiedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesPaging
          filter: $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesFilter
          sorting: $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersPaging
          filter: $removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersFilter
          sorting: $removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsPaging
          filter: $removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsFilter
          sorting: $removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
          filter: $removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
          sorting: $removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveProjectFromProjectChangeMutation__
 *
 * To run a mutation, you first call `useRemoveProjectFromProjectChangeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectFromProjectChangeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveProjectFromProjectChangeMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsPaging: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsPaging'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeProjectFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeProjectFromProjectChangeProjectProjectHistoriesPaging: // value for 'removeProjectFromProjectChangeProjectProjectHistoriesPaging'
 *     removeProjectFromProjectChangeProjectProjectHistoriesFilter: // value for 'removeProjectFromProjectChangeProjectProjectHistoriesFilter'
 *     removeProjectFromProjectChangeProjectProjectHistoriesSorting: // value for 'removeProjectFromProjectChangeProjectProjectHistoriesSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByContactsPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByContactsPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByContactsFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByContactsFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByContactsSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByContactsSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeProjectFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveProjectFromProjectChangeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveProjectFromProjectChangeMutation,
        RemoveProjectFromProjectChangeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveProjectFromProjectChangeMutation,
          RemoveProjectFromProjectChangeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveProjectFromProjectChangeMutation,
    RemoveProjectFromProjectChangeMutationVariables
  >(RemoveProjectFromProjectChangeMutationDocument, options);
}
export type RemoveProjectFromProjectChangeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveProjectFromProjectChangeMutation,
    RemoveProjectFromProjectChangeMutationVariables
  >;
export const RemoveModifiedByFromProjectChangeMutationDocument = gql`
  mutation removeModifiedByFromProjectChangeMutation(
    $input: RemoveModifiedByFromProjectChangeInput!
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsFilter: ContactFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeModifiedByFromProjectChangeProjectProjectHistoriesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeProjectProjectHistoriesFilter: ProjectChangeFilter
    $removeModifiedByFromProjectChangeProjectProjectHistoriesSorting: [ProjectChangeSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeModifiedByFromProjectChange(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Description
      State
      project {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Address
        Type
        Language
        Manager
        ManagerContact
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging
            filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter
            sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging
            filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter
            sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsPaging
            filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsFilter
            sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging
            filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter
            sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging
            filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter
            sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging
            filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter
            sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging
            filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $removeModifiedByFromProjectChangeProjectProjectHistoriesPaging
          filter: $removeModifiedByFromProjectChangeProjectProjectHistoriesFilter
          sorting: $removeModifiedByFromProjectChangeProjectProjectHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      modifiedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesPaging
          filter: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesFilter
          sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsPaging
          filter: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsFilter
          sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            histories(
              paging: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        contacts(
          paging: $removeModifiedByFromProjectChangeModifiedByModifiedByContactsPaging
          filter: $removeModifiedByFromProjectChangeModifiedByModifiedByContactsFilter
          sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesPaging
          filter: $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesFilter
          sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersPaging
          filter: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersFilter
          sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsPaging
          filter: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsFilter
          sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
          filter: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
          sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveModifiedByFromProjectChangeMutation__
 *
 * To run a mutation, you first call `useRemoveModifiedByFromProjectChangeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveModifiedByFromProjectChangeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveModifiedByFromProjectChangeMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsPaging'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeModifiedByFromProjectChangeProjectProjectHistoriesPaging: // value for 'removeModifiedByFromProjectChangeProjectProjectHistoriesPaging'
 *     removeModifiedByFromProjectChangeProjectProjectHistoriesFilter: // value for 'removeModifiedByFromProjectChangeProjectProjectHistoriesFilter'
 *     removeModifiedByFromProjectChangeProjectProjectHistoriesSorting: // value for 'removeModifiedByFromProjectChangeProjectProjectHistoriesSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByContactsPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByContactsPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByContactsFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByContactsFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByContactsSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByContactsSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeModifiedByFromProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveModifiedByFromProjectChangeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveModifiedByFromProjectChangeMutation,
        RemoveModifiedByFromProjectChangeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveModifiedByFromProjectChangeMutation,
          RemoveModifiedByFromProjectChangeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveModifiedByFromProjectChangeMutation,
    RemoveModifiedByFromProjectChangeMutationVariables
  >(RemoveModifiedByFromProjectChangeMutationDocument, options);
}
export type RemoveModifiedByFromProjectChangeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveModifiedByFromProjectChangeMutation,
    RemoveModifiedByFromProjectChangeMutationVariables
  >;
export const CreateOneProjectChangeMutationDocument = gql`
  mutation createOneProjectChangeMutation(
    $input: CreateOneProjectChangeInput!
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $createOneProjectChangeProjectProjectCreatedByCreatedByContactsFilter: ContactFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneProjectChangeProjectProjectHistoriesPaging: OffsetPaging
    $createOneProjectChangeProjectProjectHistoriesFilter: ProjectChangeFilter
    $createOneProjectChangeProjectProjectHistoriesSorting: [ProjectChangeSort!]
    $createOneProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $createOneProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $createOneProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $createOneProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $createOneProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $createOneProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $createOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $createOneProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $createOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $createOneProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createOneProjectChange(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Description
      State
      project {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Address
        Type
        Language
        Manager
        ManagerContact
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging
            filter: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter
            sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging
            filter: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter
            sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $createOneProjectChangeProjectProjectCreatedByCreatedByContactsPaging
            filter: $createOneProjectChangeProjectProjectCreatedByCreatedByContactsFilter
            sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging
            filter: $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter
            sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging
            filter: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter
            sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging
            filter: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter
            sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging
            filter: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $createOneProjectChangeProjectProjectHistoriesPaging
          filter: $createOneProjectChangeProjectProjectHistoriesFilter
          sorting: $createOneProjectChangeProjectProjectHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      modifiedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createOneProjectChangeModifiedByModifiedByCreatedCompaniesPaging
          filter: $createOneProjectChangeModifiedByModifiedByCreatedCompaniesFilter
          sorting: $createOneProjectChangeModifiedByModifiedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createOneProjectChangeModifiedByModifiedByCreatedProjectsPaging
          filter: $createOneProjectChangeModifiedByModifiedByCreatedProjectsFilter
          sorting: $createOneProjectChangeModifiedByModifiedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            histories(
              paging: $createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        contacts(
          paging: $createOneProjectChangeModifiedByModifiedByContactsPaging
          filter: $createOneProjectChangeModifiedByModifiedByContactsFilter
          sorting: $createOneProjectChangeModifiedByModifiedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createOneProjectChangeModifiedByModifiedByProjectChangesPaging
          filter: $createOneProjectChangeModifiedByModifiedByProjectChangesFilter
          sorting: $createOneProjectChangeModifiedByModifiedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createOneProjectChangeModifiedByModifiedByCreatedWorkersPaging
          filter: $createOneProjectChangeModifiedByModifiedByCreatedWorkersFilter
          sorting: $createOneProjectChangeModifiedByModifiedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createOneProjectChangeModifiedByModifiedByCreatedContractsPaging
          filter: $createOneProjectChangeModifiedByModifiedByCreatedContractsFilter
          sorting: $createOneProjectChangeModifiedByModifiedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createOneProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
          filter: $createOneProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
          sorting: $createOneProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateOneProjectChangeMutation__
 *
 * To run a mutation, you first call `useCreateOneProjectChangeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneProjectChangeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneProjectChangeMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByContactsPaging: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByContactsPaging'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByContactsFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByContactsFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByContactsSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByContactsSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneProjectChangeProjectProjectHistoriesPaging: // value for 'createOneProjectChangeProjectProjectHistoriesPaging'
 *     createOneProjectChangeProjectProjectHistoriesFilter: // value for 'createOneProjectChangeProjectProjectHistoriesFilter'
 *     createOneProjectChangeProjectProjectHistoriesSorting: // value for 'createOneProjectChangeProjectProjectHistoriesSorting'
 *     createOneProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'createOneProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     createOneProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'createOneProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     createOneProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'createOneProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'createOneProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     createOneProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'createOneProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     createOneProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'createOneProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneProjectChangeModifiedByModifiedByContactsPaging: // value for 'createOneProjectChangeModifiedByModifiedByContactsPaging'
 *     createOneProjectChangeModifiedByModifiedByContactsFilter: // value for 'createOneProjectChangeModifiedByModifiedByContactsFilter'
 *     createOneProjectChangeModifiedByModifiedByContactsSorting: // value for 'createOneProjectChangeModifiedByModifiedByContactsSorting'
 *     createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'createOneProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     createOneProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'createOneProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     createOneProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'createOneProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'createOneProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     createOneProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'createOneProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     createOneProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'createOneProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     createOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'createOneProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     createOneProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'createOneProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     createOneProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'createOneProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     createOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'createOneProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     createOneProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'createOneProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     createOneProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'createOneProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     createOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneProjectChangeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneProjectChangeMutation,
        CreateOneProjectChangeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneProjectChangeMutation,
          CreateOneProjectChangeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneProjectChangeMutation,
    CreateOneProjectChangeMutationVariables
  >(CreateOneProjectChangeMutationDocument, options);
}
export type CreateOneProjectChangeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneProjectChangeMutation,
    CreateOneProjectChangeMutationVariables
  >;
export const CreateManyProjectChangesMutationDocument = gql`
  mutation createManyProjectChangesMutation(
    $input: CreateManyProjectChangesInput!
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $createManyProjectChangesProjectProjectCreatedByCreatedByContactsFilter: ContactFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyProjectChangesProjectProjectHistoriesPaging: OffsetPaging
    $createManyProjectChangesProjectProjectHistoriesFilter: ProjectChangeFilter
    $createManyProjectChangesProjectProjectHistoriesSorting: [ProjectChangeSort!]
    $createManyProjectChangesModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $createManyProjectChangesModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyProjectChangesModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $createManyProjectChangesModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyProjectChangesModifiedByModifiedByContactsPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByContactsFilter: ContactFilter
    $createManyProjectChangesModifiedByModifiedByContactsSorting: [ContactSort!]
    $createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyProjectChangesModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByProjectChangesFilter: ContactFilter
    $createManyProjectChangesModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyProjectChangesModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $createManyProjectChangesModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $createManyProjectChangesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyProjectChangesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyProjectChangesModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $createManyProjectChangesModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $createManyProjectChangesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyProjectChangesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyProjectChangesModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $createManyProjectChangesModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyProjectChangesModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyProjectChangesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyProjectChangesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createManyProjectChanges(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Description
      State
      project {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Address
        Type
        Language
        Manager
        ManagerContact
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesPaging
            filter: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesFilter
            sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsPaging
            filter: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsFilter
            sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $createManyProjectChangesProjectProjectCreatedByCreatedByContactsPaging
            filter: $createManyProjectChangesProjectProjectCreatedByCreatedByContactsFilter
            sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesPaging
            filter: $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesFilter
            sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersPaging
            filter: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersFilter
            sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsPaging
            filter: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsFilter
            sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansPaging
            filter: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $createManyProjectChangesProjectProjectHistoriesPaging
          filter: $createManyProjectChangesProjectProjectHistoriesFilter
          sorting: $createManyProjectChangesProjectProjectHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      modifiedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createManyProjectChangesModifiedByModifiedByCreatedCompaniesPaging
          filter: $createManyProjectChangesModifiedByModifiedByCreatedCompaniesFilter
          sorting: $createManyProjectChangesModifiedByModifiedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createManyProjectChangesModifiedByModifiedByCreatedProjectsPaging
          filter: $createManyProjectChangesModifiedByModifiedByCreatedProjectsFilter
          sorting: $createManyProjectChangesModifiedByModifiedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            histories(
              paging: $createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        contacts(
          paging: $createManyProjectChangesModifiedByModifiedByContactsPaging
          filter: $createManyProjectChangesModifiedByModifiedByContactsFilter
          sorting: $createManyProjectChangesModifiedByModifiedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createManyProjectChangesModifiedByModifiedByProjectChangesPaging
          filter: $createManyProjectChangesModifiedByModifiedByProjectChangesFilter
          sorting: $createManyProjectChangesModifiedByModifiedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createManyProjectChangesModifiedByModifiedByCreatedWorkersPaging
          filter: $createManyProjectChangesModifiedByModifiedByCreatedWorkersFilter
          sorting: $createManyProjectChangesModifiedByModifiedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createManyProjectChangesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createManyProjectChangesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createManyProjectChangesModifiedByModifiedByCreatedContractsPaging
          filter: $createManyProjectChangesModifiedByModifiedByCreatedContractsFilter
          sorting: $createManyProjectChangesModifiedByModifiedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createManyProjectChangesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createManyProjectChangesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createManyProjectChangesModifiedByModifiedByCreatedMiddlemansPaging
          filter: $createManyProjectChangesModifiedByModifiedByCreatedMiddlemansFilter
          sorting: $createManyProjectChangesModifiedByModifiedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createManyProjectChangesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createManyProjectChangesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreateManyProjectChangesMutation__
 *
 * To run a mutation, you first call `useCreateManyProjectChangesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyProjectChangesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyProjectChangesMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsPaging'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedProjectsSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByContactsPaging: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByContactsPaging'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByContactsFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByContactsFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByContactsSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByContactsSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesPaging: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesPaging'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersPaging'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsPaging: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsPaging'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyProjectChangesProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyProjectChangesProjectProjectHistoriesPaging: // value for 'createManyProjectChangesProjectProjectHistoriesPaging'
 *     createManyProjectChangesProjectProjectHistoriesFilter: // value for 'createManyProjectChangesProjectProjectHistoriesFilter'
 *     createManyProjectChangesProjectProjectHistoriesSorting: // value for 'createManyProjectChangesProjectProjectHistoriesSorting'
 *     createManyProjectChangesModifiedByModifiedByCreatedCompaniesPaging: // value for 'createManyProjectChangesModifiedByModifiedByCreatedCompaniesPaging'
 *     createManyProjectChangesModifiedByModifiedByCreatedCompaniesFilter: // value for 'createManyProjectChangesModifiedByModifiedByCreatedCompaniesFilter'
 *     createManyProjectChangesModifiedByModifiedByCreatedCompaniesSorting: // value for 'createManyProjectChangesModifiedByModifiedByCreatedCompaniesSorting'
 *     createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyProjectChangesModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyProjectChangesModifiedByModifiedByCreatedProjectsPaging: // value for 'createManyProjectChangesModifiedByModifiedByCreatedProjectsPaging'
 *     createManyProjectChangesModifiedByModifiedByCreatedProjectsFilter: // value for 'createManyProjectChangesModifiedByModifiedByCreatedProjectsFilter'
 *     createManyProjectChangesModifiedByModifiedByCreatedProjectsSorting: // value for 'createManyProjectChangesModifiedByModifiedByCreatedProjectsSorting'
 *     createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyProjectChangesModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyProjectChangesModifiedByModifiedByContactsPaging: // value for 'createManyProjectChangesModifiedByModifiedByContactsPaging'
 *     createManyProjectChangesModifiedByModifiedByContactsFilter: // value for 'createManyProjectChangesModifiedByModifiedByContactsFilter'
 *     createManyProjectChangesModifiedByModifiedByContactsSorting: // value for 'createManyProjectChangesModifiedByModifiedByContactsSorting'
 *     createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyProjectChangesModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyProjectChangesModifiedByModifiedByProjectChangesPaging: // value for 'createManyProjectChangesModifiedByModifiedByProjectChangesPaging'
 *     createManyProjectChangesModifiedByModifiedByProjectChangesFilter: // value for 'createManyProjectChangesModifiedByModifiedByProjectChangesFilter'
 *     createManyProjectChangesModifiedByModifiedByProjectChangesSorting: // value for 'createManyProjectChangesModifiedByModifiedByProjectChangesSorting'
 *     createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyProjectChangesModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyProjectChangesModifiedByModifiedByCreatedWorkersPaging: // value for 'createManyProjectChangesModifiedByModifiedByCreatedWorkersPaging'
 *     createManyProjectChangesModifiedByModifiedByCreatedWorkersFilter: // value for 'createManyProjectChangesModifiedByModifiedByCreatedWorkersFilter'
 *     createManyProjectChangesModifiedByModifiedByCreatedWorkersSorting: // value for 'createManyProjectChangesModifiedByModifiedByCreatedWorkersSorting'
 *     createManyProjectChangesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyProjectChangesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyProjectChangesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyProjectChangesModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyProjectChangesModifiedByModifiedByCreatedContractsPaging: // value for 'createManyProjectChangesModifiedByModifiedByCreatedContractsPaging'
 *     createManyProjectChangesModifiedByModifiedByCreatedContractsFilter: // value for 'createManyProjectChangesModifiedByModifiedByCreatedContractsFilter'
 *     createManyProjectChangesModifiedByModifiedByCreatedContractsSorting: // value for 'createManyProjectChangesModifiedByModifiedByCreatedContractsSorting'
 *     createManyProjectChangesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyProjectChangesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyProjectChangesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyProjectChangesModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyProjectChangesModifiedByModifiedByCreatedMiddlemansPaging: // value for 'createManyProjectChangesModifiedByModifiedByCreatedMiddlemansPaging'
 *     createManyProjectChangesModifiedByModifiedByCreatedMiddlemansFilter: // value for 'createManyProjectChangesModifiedByModifiedByCreatedMiddlemansFilter'
 *     createManyProjectChangesModifiedByModifiedByCreatedMiddlemansSorting: // value for 'createManyProjectChangesModifiedByModifiedByCreatedMiddlemansSorting'
 *     createManyProjectChangesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyProjectChangesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyProjectChangesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyProjectChangesModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyProjectChangesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyProjectChangesMutation,
        CreateManyProjectChangesMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyProjectChangesMutation,
          CreateManyProjectChangesMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyProjectChangesMutation,
    CreateManyProjectChangesMutationVariables
  >(CreateManyProjectChangesMutationDocument, options);
}
export type CreateManyProjectChangesMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyProjectChangesMutation,
    CreateManyProjectChangesMutationVariables
  >;
export const UpdateOneProjectChangeMutationDocument = gql`
  mutation updateOneProjectChangeMutation(
    $input: UpdateOneProjectChangeInput!
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsFilter: ContactFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsSorting: [ContactSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: ContactFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneProjectChangeProjectProjectHistoriesPaging: OffsetPaging
    $updateOneProjectChangeProjectProjectHistoriesFilter: ProjectChangeFilter
    $updateOneProjectChangeProjectProjectHistoriesSorting: [ProjectChangeSort!]
    $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesFilter: CompanyFilter
    $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneProjectChangeModifiedByModifiedByCreatedProjectsPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByCreatedProjectsFilter: ProjectFilter
    $updateOneProjectChangeModifiedByModifiedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneProjectChangeModifiedByModifiedByContactsPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByContactsFilter: ContactFilter
    $updateOneProjectChangeModifiedByModifiedByContactsSorting: [ContactSort!]
    $updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneProjectChangeModifiedByModifiedByProjectChangesPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByProjectChangesFilter: ContactFilter
    $updateOneProjectChangeModifiedByModifiedByProjectChangesSorting: [ContactSort!]
    $updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneProjectChangeModifiedByModifiedByCreatedWorkersPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByCreatedWorkersFilter: WorkerFilter
    $updateOneProjectChangeModifiedByModifiedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneProjectChangeModifiedByModifiedByCreatedContractsPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByCreatedContractsFilter: ContractFilter
    $updateOneProjectChangeModifiedByModifiedByCreatedContractsSorting: [ContractSort!]
    $updateOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    updateOneProjectChange(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Description
      State
      project {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
        Address
        Type
        Language
        Manager
        ManagerContact
        createdBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging
            filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter
            sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging
            filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter
            sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          contacts(
            paging: $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsPaging
            filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsFilter
            sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging
            filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter
            sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging
            filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter
            sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging
            filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter
            sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging
            filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter
            sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
        histories(
          paging: $updateOneProjectChangeProjectProjectHistoriesPaging
          filter: $updateOneProjectChangeProjectProjectHistoriesFilter
          sorting: $updateOneProjectChangeProjectProjectHistoriesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      modifiedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesPaging
          filter: $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesFilter
          sorting: $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $updateOneProjectChangeModifiedByModifiedByCreatedProjectsPaging
          filter: $updateOneProjectChangeModifiedByModifiedByCreatedProjectsFilter
          sorting: $updateOneProjectChangeModifiedByModifiedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            histories(
              paging: $updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
        contacts(
          paging: $updateOneProjectChangeModifiedByModifiedByContactsPaging
          filter: $updateOneProjectChangeModifiedByModifiedByContactsFilter
          sorting: $updateOneProjectChangeModifiedByModifiedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $updateOneProjectChangeModifiedByModifiedByProjectChangesPaging
          filter: $updateOneProjectChangeModifiedByModifiedByProjectChangesFilter
          sorting: $updateOneProjectChangeModifiedByModifiedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $updateOneProjectChangeModifiedByModifiedByCreatedWorkersPaging
          filter: $updateOneProjectChangeModifiedByModifiedByCreatedWorkersFilter
          sorting: $updateOneProjectChangeModifiedByModifiedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $updateOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $updateOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $updateOneProjectChangeModifiedByModifiedByCreatedContractsPaging
          filter: $updateOneProjectChangeModifiedByModifiedByCreatedContractsFilter
          sorting: $updateOneProjectChangeModifiedByModifiedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $updateOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $updateOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansPaging
          filter: $updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansFilter
          sorting: $updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useUpdateOneProjectChangeMutation__
 *
 * To run a mutation, you first call `useUpdateOneProjectChangeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneProjectChangeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneProjectChangeMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesPaging'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsPaging'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedProjectsSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByContactsPaging: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByContactsPaging'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByContactsFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByContactsFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByContactsSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByContactsSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesPaging'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersPaging'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsPaging'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansPaging'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansSorting'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneProjectChangeProjectProjectCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneProjectChangeProjectProjectHistoriesPaging: // value for 'updateOneProjectChangeProjectProjectHistoriesPaging'
 *     updateOneProjectChangeProjectProjectHistoriesFilter: // value for 'updateOneProjectChangeProjectProjectHistoriesFilter'
 *     updateOneProjectChangeProjectProjectHistoriesSorting: // value for 'updateOneProjectChangeProjectProjectHistoriesSorting'
 *     updateOneProjectChangeModifiedByModifiedByCreatedCompaniesPaging: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedCompaniesPaging'
 *     updateOneProjectChangeModifiedByModifiedByCreatedCompaniesFilter: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedCompaniesFilter'
 *     updateOneProjectChangeModifiedByModifiedByCreatedCompaniesSorting: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedCompaniesSorting'
 *     updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneProjectChangeModifiedByModifiedByCreatedProjectsPaging: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedProjectsPaging'
 *     updateOneProjectChangeModifiedByModifiedByCreatedProjectsFilter: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedProjectsFilter'
 *     updateOneProjectChangeModifiedByModifiedByCreatedProjectsSorting: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedProjectsSorting'
 *     updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneProjectChangeModifiedByModifiedByContactsPaging: // value for 'updateOneProjectChangeModifiedByModifiedByContactsPaging'
 *     updateOneProjectChangeModifiedByModifiedByContactsFilter: // value for 'updateOneProjectChangeModifiedByModifiedByContactsFilter'
 *     updateOneProjectChangeModifiedByModifiedByContactsSorting: // value for 'updateOneProjectChangeModifiedByModifiedByContactsSorting'
 *     updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneProjectChangeModifiedByModifiedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneProjectChangeModifiedByModifiedByProjectChangesPaging: // value for 'updateOneProjectChangeModifiedByModifiedByProjectChangesPaging'
 *     updateOneProjectChangeModifiedByModifiedByProjectChangesFilter: // value for 'updateOneProjectChangeModifiedByModifiedByProjectChangesFilter'
 *     updateOneProjectChangeModifiedByModifiedByProjectChangesSorting: // value for 'updateOneProjectChangeModifiedByModifiedByProjectChangesSorting'
 *     updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneProjectChangeModifiedByModifiedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneProjectChangeModifiedByModifiedByCreatedWorkersPaging: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedWorkersPaging'
 *     updateOneProjectChangeModifiedByModifiedByCreatedWorkersFilter: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedWorkersFilter'
 *     updateOneProjectChangeModifiedByModifiedByCreatedWorkersSorting: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedWorkersSorting'
 *     updateOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneProjectChangeModifiedByModifiedByCreatedContractsPaging: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedContractsPaging'
 *     updateOneProjectChangeModifiedByModifiedByCreatedContractsFilter: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedContractsFilter'
 *     updateOneProjectChangeModifiedByModifiedByCreatedContractsSorting: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedContractsSorting'
 *     updateOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansPaging: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansPaging'
 *     updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansFilter: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansFilter'
 *     updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansSorting: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansSorting'
 *     updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneProjectChangeModifiedByModifiedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneProjectChangeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneProjectChangeMutation,
        UpdateOneProjectChangeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneProjectChangeMutation,
          UpdateOneProjectChangeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneProjectChangeMutation,
    UpdateOneProjectChangeMutationVariables
  >(UpdateOneProjectChangeMutationDocument, options);
}
export type UpdateOneProjectChangeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneProjectChangeMutation,
    UpdateOneProjectChangeMutationVariables
  >;
export const UpdateManyProjectChangesMutationDocument = gql`
  mutation updateManyProjectChangesMutation(
    $input: UpdateManyProjectChangesInput!
  ) {
    updateManyProjectChanges(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyProjectChangesMutation__
 *
 * To run a mutation, you first call `useUpdateManyProjectChangesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyProjectChangesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyProjectChangesMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyProjectChangesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyProjectChangesMutation,
        UpdateManyProjectChangesMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyProjectChangesMutation,
          UpdateManyProjectChangesMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyProjectChangesMutation,
    UpdateManyProjectChangesMutationVariables
  >(UpdateManyProjectChangesMutationDocument, options);
}
export type UpdateManyProjectChangesMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyProjectChangesMutation,
    UpdateManyProjectChangesMutationVariables
  >;
export const DeleteOneProjectChangeMutationDocument = gql`
  mutation deleteOneProjectChangeMutation(
    $input: DeleteOneProjectChangeInput!
  ) {
    deleteOneProjectChange(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Description
      State
    }
  }
`;

/**
 * __useDeleteOneProjectChangeMutation__
 *
 * To run a mutation, you first call `useDeleteOneProjectChangeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneProjectChangeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneProjectChangeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneProjectChangeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneProjectChangeMutation,
        DeleteOneProjectChangeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneProjectChangeMutation,
          DeleteOneProjectChangeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneProjectChangeMutation,
    DeleteOneProjectChangeMutationVariables
  >(DeleteOneProjectChangeMutationDocument, options);
}
export type DeleteOneProjectChangeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneProjectChangeMutation,
    DeleteOneProjectChangeMutationVariables
  >;
export const DeleteManyProjectChangesMutationDocument = gql`
  mutation deleteManyProjectChangesMutation(
    $input: DeleteManyProjectChangesInput!
  ) {
    deleteManyProjectChanges(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyProjectChangesMutation__
 *
 * To run a mutation, you first call `useDeleteManyProjectChangesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyProjectChangesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyProjectChangesMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyProjectChangesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyProjectChangesMutation,
        DeleteManyProjectChangesMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyProjectChangesMutation,
          DeleteManyProjectChangesMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyProjectChangesMutation,
    DeleteManyProjectChangesMutationVariables
  >(DeleteManyProjectChangesMutationDocument, options);
}
export type DeleteManyProjectChangesMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyProjectChangesMutation,
    DeleteManyProjectChangesMutationVariables
  >;
export const SetCreatedByOnWorkerMutationDocument = gql`
  mutation setCreatedByOnWorkerMutation(
    $input: SetCreatedByOnWorkerInput!
    $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnWorkerAttachmentsFilter: AttachmentFilter
    $setCreatedByOnWorkerAttachmentsSorting: [AttachmentSort!]
  ) {
    setCreatedByOnWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnWorkerCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnWorkerCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnWorkerCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdContracts(
          paging: $setCreatedByOnWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $setCreatedByOnWorkerAttachmentsFilter
        sorting: $setCreatedByOnWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useSetCreatedByOnWorkerMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByContactsPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByContactsFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByContactsSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnWorkerAttachmentsFilter: // value for 'setCreatedByOnWorkerAttachmentsFilter'
 *     setCreatedByOnWorkerAttachmentsSorting: // value for 'setCreatedByOnWorkerAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnWorkerMutation,
        SetCreatedByOnWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnWorkerMutation,
          SetCreatedByOnWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnWorkerMutation,
    SetCreatedByOnWorkerMutationVariables
  >(SetCreatedByOnWorkerMutationDocument, options);
}
export type SetCreatedByOnWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnWorkerMutation,
    SetCreatedByOnWorkerMutationVariables
  >;
export const AddAttachmentsToWorkerMutationDocument = gql`
  mutation addAttachmentsToWorkerMutation(
    $input: AddAttachmentsToWorkerInput!
    $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addAttachmentsToWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $addAttachmentsToWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addAttachmentsToWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addAttachmentsToWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addAttachmentsToWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addAttachmentsToWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addAttachmentsToWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addAttachmentsToWorkerAttachmentsFilter: AttachmentFilter
    $addAttachmentsToWorkerAttachmentsSorting: [AttachmentSort!]
  ) {
    addAttachmentsToWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addAttachmentsToWorkerCreatedByCreatedByContactsPaging
          filter: $addAttachmentsToWorkerCreatedByCreatedByContactsFilter
          sorting: $addAttachmentsToWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addAttachmentsToWorkerCreatedByCreatedByProjectChangesPaging
          filter: $addAttachmentsToWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $addAttachmentsToWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdContracts(
          paging: $addAttachmentsToWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $addAttachmentsToWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $addAttachmentsToWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addAttachmentsToWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $addAttachmentsToWorkerAttachmentsFilter
        sorting: $addAttachmentsToWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useAddAttachmentsToWorkerMutation__
 *
 * To run a mutation, you first call `useAddAttachmentsToWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentsToWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddAttachmentsToWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByContactsPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByContactsPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByContactsFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByContactsFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByContactsSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByContactsSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByProjectChangesPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByProjectChangesPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByProjectChangesFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByProjectChangesFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByProjectChangesSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByProjectChangesSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedContractsPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedContractsFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedContractsSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addAttachmentsToWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addAttachmentsToWorkerAttachmentsFilter: // value for 'addAttachmentsToWorkerAttachmentsFilter'
 *     addAttachmentsToWorkerAttachmentsSorting: // value for 'addAttachmentsToWorkerAttachmentsSorting'
 *   },
 * });
 */
export function useAddAttachmentsToWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddAttachmentsToWorkerMutation,
        AddAttachmentsToWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddAttachmentsToWorkerMutation,
          AddAttachmentsToWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddAttachmentsToWorkerMutation,
    AddAttachmentsToWorkerMutationVariables
  >(AddAttachmentsToWorkerMutationDocument, options);
}
export type AddAttachmentsToWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddAttachmentsToWorkerMutation,
    AddAttachmentsToWorkerMutationVariables
  >;
export const SetAttachmentsOnWorkerMutationDocument = gql`
  mutation setAttachmentsOnWorkerMutation(
    $input: SetAttachmentsOnWorkerInput!
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $setAttachmentsOnWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setAttachmentsOnWorkerAttachmentsFilter: AttachmentFilter
    $setAttachmentsOnWorkerAttachmentsSorting: [AttachmentSort!]
  ) {
    setAttachmentsOnWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setAttachmentsOnWorkerCreatedByCreatedByContactsPaging
          filter: $setAttachmentsOnWorkerCreatedByCreatedByContactsFilter
          sorting: $setAttachmentsOnWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesPaging
          filter: $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdContracts(
          paging: $setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $setAttachmentsOnWorkerAttachmentsFilter
        sorting: $setAttachmentsOnWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useSetAttachmentsOnWorkerMutation__
 *
 * To run a mutation, you first call `useSetAttachmentsOnWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetAttachmentsOnWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetAttachmentsOnWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByContactsPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByContactsPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByContactsFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByContactsFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByContactsSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByContactsSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByProjectChangesPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByProjectChangesPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByProjectChangesFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByProjectChangesFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByProjectChangesSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByProjectChangesSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setAttachmentsOnWorkerAttachmentsFilter: // value for 'setAttachmentsOnWorkerAttachmentsFilter'
 *     setAttachmentsOnWorkerAttachmentsSorting: // value for 'setAttachmentsOnWorkerAttachmentsSorting'
 *   },
 * });
 */
export function useSetAttachmentsOnWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetAttachmentsOnWorkerMutation,
        SetAttachmentsOnWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetAttachmentsOnWorkerMutation,
          SetAttachmentsOnWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetAttachmentsOnWorkerMutation,
    SetAttachmentsOnWorkerMutationVariables
  >(SetAttachmentsOnWorkerMutationDocument, options);
}
export type SetAttachmentsOnWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetAttachmentsOnWorkerMutation,
    SetAttachmentsOnWorkerMutationVariables
  >;
export const RemoveCreatedByFromWorkerMutationDocument = gql`
  mutation removeCreatedByFromWorkerMutation(
    $input: RemoveCreatedByFromWorkerInput!
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromWorkerAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromWorkerAttachmentsSorting: [AttachmentSort!]
  ) {
    removeCreatedByFromWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromWorkerCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromWorkerCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdContracts(
          paging: $removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $removeCreatedByFromWorkerAttachmentsFilter
        sorting: $removeCreatedByFromWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromWorkerMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromWorkerAttachmentsFilter: // value for 'removeCreatedByFromWorkerAttachmentsFilter'
 *     removeCreatedByFromWorkerAttachmentsSorting: // value for 'removeCreatedByFromWorkerAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromWorkerMutation,
        RemoveCreatedByFromWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromWorkerMutation,
          RemoveCreatedByFromWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromWorkerMutation,
    RemoveCreatedByFromWorkerMutationVariables
  >(RemoveCreatedByFromWorkerMutationDocument, options);
}
export type RemoveCreatedByFromWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromWorkerMutation,
    RemoveCreatedByFromWorkerMutationVariables
  >;
export const RemoveAttachmentsFromWorkerMutationDocument = gql`
  mutation removeAttachmentsFromWorkerMutation(
    $input: RemoveAttachmentsFromWorkerInput!
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeAttachmentsFromWorkerAttachmentsFilter: AttachmentFilter
    $removeAttachmentsFromWorkerAttachmentsSorting: [AttachmentSort!]
  ) {
    removeAttachmentsFromWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeAttachmentsFromWorkerCreatedByCreatedByContactsPaging
          filter: $removeAttachmentsFromWorkerCreatedByCreatedByContactsFilter
          sorting: $removeAttachmentsFromWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesPaging
          filter: $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdContracts(
          paging: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $removeAttachmentsFromWorkerAttachmentsFilter
        sorting: $removeAttachmentsFromWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useRemoveAttachmentsFromWorkerMutation__
 *
 * To run a mutation, you first call `useRemoveAttachmentsFromWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttachmentsFromWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveAttachmentsFromWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByContactsPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByContactsPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByContactsFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByContactsFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByContactsSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByContactsSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromWorkerAttachmentsFilter: // value for 'removeAttachmentsFromWorkerAttachmentsFilter'
 *     removeAttachmentsFromWorkerAttachmentsSorting: // value for 'removeAttachmentsFromWorkerAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveAttachmentsFromWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveAttachmentsFromWorkerMutation,
        RemoveAttachmentsFromWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveAttachmentsFromWorkerMutation,
          RemoveAttachmentsFromWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveAttachmentsFromWorkerMutation,
    RemoveAttachmentsFromWorkerMutationVariables
  >(RemoveAttachmentsFromWorkerMutationDocument, options);
}
export type RemoveAttachmentsFromWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveAttachmentsFromWorkerMutation,
    RemoveAttachmentsFromWorkerMutationVariables
  >;
export const CreateOneWorkerMutationDocument = gql`
  mutation createOneWorkerMutation(
    $input: CreateOneWorkerInput!
    $createOneWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createOneWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createOneWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $createOneWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createOneWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createOneWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createOneWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createOneWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createOneWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneWorkerAttachmentsFilter: AttachmentFilter
    $createOneWorkerAttachmentsSorting: [AttachmentSort!]
  ) {
    createOneWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createOneWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $createOneWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createOneWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createOneWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $createOneWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $createOneWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createOneWorkerCreatedByCreatedByContactsPaging
          filter: $createOneWorkerCreatedByCreatedByContactsFilter
          sorting: $createOneWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createOneWorkerCreatedByCreatedByProjectChangesPaging
          filter: $createOneWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $createOneWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createOneWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $createOneWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $createOneWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdContracts(
          paging: $createOneWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $createOneWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $createOneWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createOneWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createOneWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createOneWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $createOneWorkerAttachmentsFilter
        sorting: $createOneWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCreateOneWorkerMutation__
 *
 * To run a mutation, you first call `useCreateOneWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'createOneWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     createOneWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'createOneWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     createOneWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'createOneWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'createOneWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     createOneWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'createOneWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     createOneWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'createOneWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneWorkerCreatedByCreatedByContactsPaging: // value for 'createOneWorkerCreatedByCreatedByContactsPaging'
 *     createOneWorkerCreatedByCreatedByContactsFilter: // value for 'createOneWorkerCreatedByCreatedByContactsFilter'
 *     createOneWorkerCreatedByCreatedByContactsSorting: // value for 'createOneWorkerCreatedByCreatedByContactsSorting'
 *     createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneWorkerCreatedByCreatedByProjectChangesPaging: // value for 'createOneWorkerCreatedByCreatedByProjectChangesPaging'
 *     createOneWorkerCreatedByCreatedByProjectChangesFilter: // value for 'createOneWorkerCreatedByCreatedByProjectChangesFilter'
 *     createOneWorkerCreatedByCreatedByProjectChangesSorting: // value for 'createOneWorkerCreatedByCreatedByProjectChangesSorting'
 *     createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'createOneWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     createOneWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'createOneWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     createOneWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'createOneWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     createOneWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'createOneWorkerCreatedByCreatedByCreatedContractsPaging'
 *     createOneWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'createOneWorkerCreatedByCreatedByCreatedContractsFilter'
 *     createOneWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'createOneWorkerCreatedByCreatedByCreatedContractsSorting'
 *     createOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createOneWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     createOneWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createOneWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     createOneWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createOneWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     createOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneWorkerAttachmentsFilter: // value for 'createOneWorkerAttachmentsFilter'
 *     createOneWorkerAttachmentsSorting: // value for 'createOneWorkerAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneWorkerMutation,
        CreateOneWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneWorkerMutation,
          CreateOneWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneWorkerMutation,
    CreateOneWorkerMutationVariables
  >(CreateOneWorkerMutationDocument, options);
}
export type CreateOneWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneWorkerMutation,
    CreateOneWorkerMutationVariables
  >;
export const CreateManyWorkersMutationDocument = gql`
  mutation createManyWorkersMutation(
    $input: CreateManyWorkersInput!
    $createManyWorkersCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createManyWorkersCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyWorkersCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createManyWorkersCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyWorkersCreatedByCreatedByContactsPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByContactsFilter: ContactFilter
    $createManyWorkersCreatedByCreatedByContactsSorting: [ContactSort!]
    $createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyWorkersCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createManyWorkersCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyWorkersCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createManyWorkersCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createManyWorkersCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createManyWorkersCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createManyWorkersCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyWorkersCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyWorkersCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createManyWorkersCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyWorkersCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyWorkersCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyWorkersCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyWorkersAttachmentsFilter: AttachmentFilter
    $createManyWorkersAttachmentsSorting: [AttachmentSort!]
  ) {
    createManyWorkers(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createManyWorkersCreatedByCreatedByCreatedCompaniesPaging
          filter: $createManyWorkersCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createManyWorkersCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createManyWorkersCreatedByCreatedByCreatedProjectsPaging
          filter: $createManyWorkersCreatedByCreatedByCreatedProjectsFilter
          sorting: $createManyWorkersCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createManyWorkersCreatedByCreatedByContactsPaging
          filter: $createManyWorkersCreatedByCreatedByContactsFilter
          sorting: $createManyWorkersCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createManyWorkersCreatedByCreatedByProjectChangesPaging
          filter: $createManyWorkersCreatedByCreatedByProjectChangesFilter
          sorting: $createManyWorkersCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createManyWorkersCreatedByCreatedByCreatedWorkersPaging
          filter: $createManyWorkersCreatedByCreatedByCreatedWorkersFilter
          sorting: $createManyWorkersCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdContracts(
          paging: $createManyWorkersCreatedByCreatedByCreatedContractsPaging
          filter: $createManyWorkersCreatedByCreatedByCreatedContractsFilter
          sorting: $createManyWorkersCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createManyWorkersCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createManyWorkersCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createManyWorkersCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createManyWorkersCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createManyWorkersCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createManyWorkersCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createManyWorkersCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $createManyWorkersAttachmentsFilter
        sorting: $createManyWorkersAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCreateManyWorkersMutation__
 *
 * To run a mutation, you first call `useCreateManyWorkersMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyWorkersMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyWorkersMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyWorkersCreatedByCreatedByCreatedCompaniesPaging: // value for 'createManyWorkersCreatedByCreatedByCreatedCompaniesPaging'
 *     createManyWorkersCreatedByCreatedByCreatedCompaniesFilter: // value for 'createManyWorkersCreatedByCreatedByCreatedCompaniesFilter'
 *     createManyWorkersCreatedByCreatedByCreatedCompaniesSorting: // value for 'createManyWorkersCreatedByCreatedByCreatedCompaniesSorting'
 *     createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyWorkersCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyWorkersCreatedByCreatedByCreatedProjectsPaging: // value for 'createManyWorkersCreatedByCreatedByCreatedProjectsPaging'
 *     createManyWorkersCreatedByCreatedByCreatedProjectsFilter: // value for 'createManyWorkersCreatedByCreatedByCreatedProjectsFilter'
 *     createManyWorkersCreatedByCreatedByCreatedProjectsSorting: // value for 'createManyWorkersCreatedByCreatedByCreatedProjectsSorting'
 *     createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyWorkersCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyWorkersCreatedByCreatedByContactsPaging: // value for 'createManyWorkersCreatedByCreatedByContactsPaging'
 *     createManyWorkersCreatedByCreatedByContactsFilter: // value for 'createManyWorkersCreatedByCreatedByContactsFilter'
 *     createManyWorkersCreatedByCreatedByContactsSorting: // value for 'createManyWorkersCreatedByCreatedByContactsSorting'
 *     createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyWorkersCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyWorkersCreatedByCreatedByProjectChangesPaging: // value for 'createManyWorkersCreatedByCreatedByProjectChangesPaging'
 *     createManyWorkersCreatedByCreatedByProjectChangesFilter: // value for 'createManyWorkersCreatedByCreatedByProjectChangesFilter'
 *     createManyWorkersCreatedByCreatedByProjectChangesSorting: // value for 'createManyWorkersCreatedByCreatedByProjectChangesSorting'
 *     createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyWorkersCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyWorkersCreatedByCreatedByCreatedWorkersPaging: // value for 'createManyWorkersCreatedByCreatedByCreatedWorkersPaging'
 *     createManyWorkersCreatedByCreatedByCreatedWorkersFilter: // value for 'createManyWorkersCreatedByCreatedByCreatedWorkersFilter'
 *     createManyWorkersCreatedByCreatedByCreatedWorkersSorting: // value for 'createManyWorkersCreatedByCreatedByCreatedWorkersSorting'
 *     createManyWorkersCreatedByCreatedByCreatedContractsPaging: // value for 'createManyWorkersCreatedByCreatedByCreatedContractsPaging'
 *     createManyWorkersCreatedByCreatedByCreatedContractsFilter: // value for 'createManyWorkersCreatedByCreatedByCreatedContractsFilter'
 *     createManyWorkersCreatedByCreatedByCreatedContractsSorting: // value for 'createManyWorkersCreatedByCreatedByCreatedContractsSorting'
 *     createManyWorkersCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyWorkersCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyWorkersCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyWorkersCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyWorkersCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createManyWorkersCreatedByCreatedByCreatedMiddlemansPaging'
 *     createManyWorkersCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createManyWorkersCreatedByCreatedByCreatedMiddlemansFilter'
 *     createManyWorkersCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createManyWorkersCreatedByCreatedByCreatedMiddlemansSorting'
 *     createManyWorkersCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyWorkersCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyWorkersCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyWorkersCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyWorkersAttachmentsFilter: // value for 'createManyWorkersAttachmentsFilter'
 *     createManyWorkersAttachmentsSorting: // value for 'createManyWorkersAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyWorkersMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyWorkersMutation,
        CreateManyWorkersMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyWorkersMutation,
          CreateManyWorkersMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyWorkersMutation,
    CreateManyWorkersMutationVariables
  >(CreateManyWorkersMutationDocument, options);
}
export type CreateManyWorkersMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyWorkersMutation,
    CreateManyWorkersMutationVariables
  >;
export const UpdateOneWorkerMutationDocument = gql`
  mutation updateOneWorkerMutation(
    $input: UpdateOneWorkerInput!
    $updateOneWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $updateOneWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $updateOneWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $updateOneWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $updateOneWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $updateOneWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $updateOneWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $updateOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneWorkerAttachmentsFilter: AttachmentFilter
    $updateOneWorkerAttachmentsSorting: [AttachmentSort!]
  ) {
    updateOneWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $updateOneWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $updateOneWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $updateOneWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $updateOneWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $updateOneWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $updateOneWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $updateOneWorkerCreatedByCreatedByContactsPaging
          filter: $updateOneWorkerCreatedByCreatedByContactsFilter
          sorting: $updateOneWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $updateOneWorkerCreatedByCreatedByProjectChangesPaging
          filter: $updateOneWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $updateOneWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $updateOneWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $updateOneWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $updateOneWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdContracts(
          paging: $updateOneWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $updateOneWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $updateOneWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $updateOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $updateOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $updateOneWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $updateOneWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $updateOneWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $updateOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $updateOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $updateOneWorkerAttachmentsFilter
        sorting: $updateOneWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useUpdateOneWorkerMutation__
 *
 * To run a mutation, you first call `useUpdateOneWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'updateOneWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     updateOneWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'updateOneWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     updateOneWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'updateOneWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'updateOneWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     updateOneWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'updateOneWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     updateOneWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'updateOneWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneWorkerCreatedByCreatedByContactsPaging: // value for 'updateOneWorkerCreatedByCreatedByContactsPaging'
 *     updateOneWorkerCreatedByCreatedByContactsFilter: // value for 'updateOneWorkerCreatedByCreatedByContactsFilter'
 *     updateOneWorkerCreatedByCreatedByContactsSorting: // value for 'updateOneWorkerCreatedByCreatedByContactsSorting'
 *     updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneWorkerCreatedByCreatedByProjectChangesPaging: // value for 'updateOneWorkerCreatedByCreatedByProjectChangesPaging'
 *     updateOneWorkerCreatedByCreatedByProjectChangesFilter: // value for 'updateOneWorkerCreatedByCreatedByProjectChangesFilter'
 *     updateOneWorkerCreatedByCreatedByProjectChangesSorting: // value for 'updateOneWorkerCreatedByCreatedByProjectChangesSorting'
 *     updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'updateOneWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     updateOneWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'updateOneWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     updateOneWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'updateOneWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     updateOneWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'updateOneWorkerCreatedByCreatedByCreatedContractsPaging'
 *     updateOneWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'updateOneWorkerCreatedByCreatedByCreatedContractsFilter'
 *     updateOneWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'updateOneWorkerCreatedByCreatedByCreatedContractsSorting'
 *     updateOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'updateOneWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     updateOneWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'updateOneWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     updateOneWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'updateOneWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     updateOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneWorkerAttachmentsFilter: // value for 'updateOneWorkerAttachmentsFilter'
 *     updateOneWorkerAttachmentsSorting: // value for 'updateOneWorkerAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneWorkerMutation,
        UpdateOneWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneWorkerMutation,
          UpdateOneWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneWorkerMutation,
    UpdateOneWorkerMutationVariables
  >(UpdateOneWorkerMutationDocument, options);
}
export type UpdateOneWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneWorkerMutation,
    UpdateOneWorkerMutationVariables
  >;
export const UpdateManyWorkersMutationDocument = gql`
  mutation updateManyWorkersMutation($input: UpdateManyWorkersInput!) {
    updateManyWorkers(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyWorkersMutation__
 *
 * To run a mutation, you first call `useUpdateManyWorkersMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyWorkersMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyWorkersMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyWorkersMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyWorkersMutation,
        UpdateManyWorkersMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyWorkersMutation,
          UpdateManyWorkersMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyWorkersMutation,
    UpdateManyWorkersMutationVariables
  >(UpdateManyWorkersMutationDocument, options);
}
export type UpdateManyWorkersMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyWorkersMutation,
    UpdateManyWorkersMutationVariables
  >;
export const DeleteOneWorkerMutationDocument = gql`
  mutation deleteOneWorkerMutation($input: DeleteOneWorkerInput!) {
    deleteOneWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
    }
  }
`;

/**
 * __useDeleteOneWorkerMutation__
 *
 * To run a mutation, you first call `useDeleteOneWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneWorkerMutation,
        DeleteOneWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneWorkerMutation,
          DeleteOneWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneWorkerMutation,
    DeleteOneWorkerMutationVariables
  >(DeleteOneWorkerMutationDocument, options);
}
export type DeleteOneWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneWorkerMutation,
    DeleteOneWorkerMutationVariables
  >;
export const DeleteManyWorkersMutationDocument = gql`
  mutation deleteManyWorkersMutation($input: DeleteManyWorkersInput!) {
    deleteManyWorkers(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyWorkersMutation__
 *
 * To run a mutation, you first call `useDeleteManyWorkersMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyWorkersMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyWorkersMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyWorkersMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyWorkersMutation,
        DeleteManyWorkersMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyWorkersMutation,
          DeleteManyWorkersMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyWorkersMutation,
    DeleteManyWorkersMutationVariables
  >(DeleteManyWorkersMutationDocument, options);
}
export type DeleteManyWorkersMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyWorkersMutation,
    DeleteManyWorkersMutationVariables
  >;
export const CreateOneAttachmentMutationDocument = gql`
  mutation createOneAttachmentMutation($input: CreateOneAttachmentInput!) {
    createOneAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCreateOneAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateOneAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneAttachmentMutation,
        CreateOneAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneAttachmentMutation,
          CreateOneAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneAttachmentMutation,
    CreateOneAttachmentMutationVariables
  >(CreateOneAttachmentMutationDocument, options);
}
export type CreateOneAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneAttachmentMutation,
    CreateOneAttachmentMutationVariables
  >;
export const CreateManyAttachmentsMutationDocument = gql`
  mutation createManyAttachmentsMutation($input: CreateManyAttachmentsInput!) {
    createManyAttachments(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCreateManyAttachmentsMutation__
 *
 * To run a mutation, you first call `useCreateManyAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyAttachmentsMutation,
        CreateManyAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyAttachmentsMutation,
          CreateManyAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyAttachmentsMutation,
    CreateManyAttachmentsMutationVariables
  >(CreateManyAttachmentsMutationDocument, options);
}
export type CreateManyAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyAttachmentsMutation,
    CreateManyAttachmentsMutationVariables
  >;
export const UpdateOneAttachmentMutationDocument = gql`
  mutation updateOneAttachmentMutation($input: UpdateOneAttachmentInput!) {
    updateOneAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useUpdateOneAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateOneAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneAttachmentMutation,
        UpdateOneAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneAttachmentMutation,
          UpdateOneAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneAttachmentMutation,
    UpdateOneAttachmentMutationVariables
  >(UpdateOneAttachmentMutationDocument, options);
}
export type UpdateOneAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneAttachmentMutation,
    UpdateOneAttachmentMutationVariables
  >;
export const UpdateManyAttachmentsMutationDocument = gql`
  mutation updateManyAttachmentsMutation($input: UpdateManyAttachmentsInput!) {
    updateManyAttachments(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyAttachmentsMutation__
 *
 * To run a mutation, you first call `useUpdateManyAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyAttachmentsMutation,
        UpdateManyAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyAttachmentsMutation,
          UpdateManyAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyAttachmentsMutation,
    UpdateManyAttachmentsMutationVariables
  >(UpdateManyAttachmentsMutationDocument, options);
}
export type UpdateManyAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyAttachmentsMutation,
    UpdateManyAttachmentsMutationVariables
  >;
export const DeleteOneAttachmentMutationDocument = gql`
  mutation deleteOneAttachmentMutation($input: DeleteOneAttachmentInput!) {
    deleteOneAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useDeleteOneAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteOneAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneAttachmentMutation,
        DeleteOneAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneAttachmentMutation,
          DeleteOneAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneAttachmentMutation,
    DeleteOneAttachmentMutationVariables
  >(DeleteOneAttachmentMutationDocument, options);
}
export type DeleteOneAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneAttachmentMutation,
    DeleteOneAttachmentMutationVariables
  >;
export const DeleteManyAttachmentsMutationDocument = gql`
  mutation deleteManyAttachmentsMutation($input: DeleteManyAttachmentsInput!) {
    deleteManyAttachments(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyAttachmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyAttachmentsMutation,
        DeleteManyAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyAttachmentsMutation,
          DeleteManyAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyAttachmentsMutation,
    DeleteManyAttachmentsMutationVariables
  >(DeleteManyAttachmentsMutationDocument, options);
}
export type DeleteManyAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyAttachmentsMutation,
    DeleteManyAttachmentsMutationVariables
  >;
export const SetCreatedByOnContractMutationDocument = gql`
  mutation setCreatedByOnContractMutation(
    $input: SetCreatedByOnContractInput!
    $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnContractCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnContractCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnContractCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnContractCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnContractCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnContractCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnContractCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnContractCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnContractCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnContractCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnContractAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnContractAttachmentsSorting: [ContractAttachmentSort!]
  ) {
    setCreatedByOnContract(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnContractCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnContractCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnContractCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnContractCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnContractCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnContractCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnContractCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnContractCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnContractCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnContractCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnContractCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnContractCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnContractCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnContractCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnContractCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $setCreatedByOnContractAttachmentsFilter
        sorting: $setCreatedByOnContractAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useSetCreatedByOnContractMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnContractMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnContractMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnContractMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnContractCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnContractCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnContractCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnContractCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnContractCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnContractCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnContractCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnContractCreatedByCreatedByContactsPaging'
 *     setCreatedByOnContractCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnContractCreatedByCreatedByContactsFilter'
 *     setCreatedByOnContractCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnContractCreatedByCreatedByContactsSorting'
 *     setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnContractCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnContractCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnContractCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnContractCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnContractCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnContractCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnContractCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnContractCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnContractCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnContractCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnContractCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnContractCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnContractAttachmentsFilter: // value for 'setCreatedByOnContractAttachmentsFilter'
 *     setCreatedByOnContractAttachmentsSorting: // value for 'setCreatedByOnContractAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnContractMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnContractMutation,
        SetCreatedByOnContractMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnContractMutation,
          SetCreatedByOnContractMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnContractMutation,
    SetCreatedByOnContractMutationVariables
  >(SetCreatedByOnContractMutationDocument, options);
}
export type SetCreatedByOnContractMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnContractMutation,
    SetCreatedByOnContractMutationVariables
  >;
export const AddAttachmentsToContractMutationDocument = gql`
  mutation addAttachmentsToContractMutation(
    $input: AddAttachmentsToContractInput!
    $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addAttachmentsToContractCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addAttachmentsToContractCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addAttachmentsToContractCreatedByCreatedByContactsPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByContactsFilter: ContactFilter
    $addAttachmentsToContractCreatedByCreatedByContactsSorting: [ContactSort!]
    $addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToContractCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addAttachmentsToContractCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToContractCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addAttachmentsToContractCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addAttachmentsToContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addAttachmentsToContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addAttachmentsToContractCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addAttachmentsToContractCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addAttachmentsToContractAttachmentsFilter: ContractAttachmentFilter
    $addAttachmentsToContractAttachmentsSorting: [ContractAttachmentSort!]
  ) {
    addAttachmentsToContract(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesPaging
          filter: $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addAttachmentsToContractCreatedByCreatedByCreatedProjectsPaging
          filter: $addAttachmentsToContractCreatedByCreatedByCreatedProjectsFilter
          sorting: $addAttachmentsToContractCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addAttachmentsToContractCreatedByCreatedByContactsPaging
          filter: $addAttachmentsToContractCreatedByCreatedByContactsFilter
          sorting: $addAttachmentsToContractCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addAttachmentsToContractCreatedByCreatedByProjectChangesPaging
          filter: $addAttachmentsToContractCreatedByCreatedByProjectChangesFilter
          sorting: $addAttachmentsToContractCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addAttachmentsToContractCreatedByCreatedByCreatedWorkersPaging
          filter: $addAttachmentsToContractCreatedByCreatedByCreatedWorkersFilter
          sorting: $addAttachmentsToContractCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addAttachmentsToContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addAttachmentsToContractCreatedByCreatedByCreatedContractsPaging
          filter: $addAttachmentsToContractCreatedByCreatedByCreatedContractsFilter
          sorting: $addAttachmentsToContractCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdMiddlemans(
          paging: $addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $addAttachmentsToContractAttachmentsFilter
        sorting: $addAttachmentsToContractAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useAddAttachmentsToContractMutation__
 *
 * To run a mutation, you first call `useAddAttachmentsToContractMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentsToContractMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddAttachmentsToContractMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addAttachmentsToContractCreatedByCreatedByCreatedCompaniesPaging: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedCompaniesPaging'
 *     addAttachmentsToContractCreatedByCreatedByCreatedCompaniesFilter: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedCompaniesFilter'
 *     addAttachmentsToContractCreatedByCreatedByCreatedCompaniesSorting: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedCompaniesSorting'
 *     addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addAttachmentsToContractCreatedByCreatedByCreatedProjectsPaging: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedProjectsPaging'
 *     addAttachmentsToContractCreatedByCreatedByCreatedProjectsFilter: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedProjectsFilter'
 *     addAttachmentsToContractCreatedByCreatedByCreatedProjectsSorting: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedProjectsSorting'
 *     addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addAttachmentsToContractCreatedByCreatedByContactsPaging: // value for 'addAttachmentsToContractCreatedByCreatedByContactsPaging'
 *     addAttachmentsToContractCreatedByCreatedByContactsFilter: // value for 'addAttachmentsToContractCreatedByCreatedByContactsFilter'
 *     addAttachmentsToContractCreatedByCreatedByContactsSorting: // value for 'addAttachmentsToContractCreatedByCreatedByContactsSorting'
 *     addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToContractCreatedByCreatedByProjectChangesPaging: // value for 'addAttachmentsToContractCreatedByCreatedByProjectChangesPaging'
 *     addAttachmentsToContractCreatedByCreatedByProjectChangesFilter: // value for 'addAttachmentsToContractCreatedByCreatedByProjectChangesFilter'
 *     addAttachmentsToContractCreatedByCreatedByProjectChangesSorting: // value for 'addAttachmentsToContractCreatedByCreatedByProjectChangesSorting'
 *     addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToContractCreatedByCreatedByCreatedWorkersPaging: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedWorkersPaging'
 *     addAttachmentsToContractCreatedByCreatedByCreatedWorkersFilter: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedWorkersFilter'
 *     addAttachmentsToContractCreatedByCreatedByCreatedWorkersSorting: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedWorkersSorting'
 *     addAttachmentsToContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addAttachmentsToContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addAttachmentsToContractCreatedByCreatedByCreatedContractsPaging: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedContractsPaging'
 *     addAttachmentsToContractCreatedByCreatedByCreatedContractsFilter: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedContractsFilter'
 *     addAttachmentsToContractCreatedByCreatedByCreatedContractsSorting: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedContractsSorting'
 *     addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansPaging'
 *     addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansFilter'
 *     addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansSorting'
 *     addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addAttachmentsToContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addAttachmentsToContractAttachmentsFilter: // value for 'addAttachmentsToContractAttachmentsFilter'
 *     addAttachmentsToContractAttachmentsSorting: // value for 'addAttachmentsToContractAttachmentsSorting'
 *   },
 * });
 */
export function useAddAttachmentsToContractMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddAttachmentsToContractMutation,
        AddAttachmentsToContractMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddAttachmentsToContractMutation,
          AddAttachmentsToContractMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddAttachmentsToContractMutation,
    AddAttachmentsToContractMutationVariables
  >(AddAttachmentsToContractMutationDocument, options);
}
export type AddAttachmentsToContractMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddAttachmentsToContractMutation,
    AddAttachmentsToContractMutationVariables
  >;
export const SetAttachmentsOnContractMutationDocument = gql`
  mutation setAttachmentsOnContractMutation(
    $input: SetAttachmentsOnContractInput!
    $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setAttachmentsOnContractCreatedByCreatedByContactsPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByContactsFilter: ContactFilter
    $setAttachmentsOnContractCreatedByCreatedByContactsSorting: [ContactSort!]
    $setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnContractCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setAttachmentsOnContractCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnContractCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setAttachmentsOnContractCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setAttachmentsOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setAttachmentsOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setAttachmentsOnContractCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setAttachmentsOnContractCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setAttachmentsOnContractAttachmentsFilter: ContractAttachmentFilter
    $setAttachmentsOnContractAttachmentsSorting: [ContractAttachmentSort!]
  ) {
    setAttachmentsOnContract(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesPaging
          filter: $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsPaging
          filter: $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsFilter
          sorting: $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setAttachmentsOnContractCreatedByCreatedByContactsPaging
          filter: $setAttachmentsOnContractCreatedByCreatedByContactsFilter
          sorting: $setAttachmentsOnContractCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setAttachmentsOnContractCreatedByCreatedByProjectChangesPaging
          filter: $setAttachmentsOnContractCreatedByCreatedByProjectChangesFilter
          sorting: $setAttachmentsOnContractCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setAttachmentsOnContractCreatedByCreatedByCreatedWorkersPaging
          filter: $setAttachmentsOnContractCreatedByCreatedByCreatedWorkersFilter
          sorting: $setAttachmentsOnContractCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setAttachmentsOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setAttachmentsOnContractCreatedByCreatedByCreatedContractsPaging
          filter: $setAttachmentsOnContractCreatedByCreatedByCreatedContractsFilter
          sorting: $setAttachmentsOnContractCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdMiddlemans(
          paging: $setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $setAttachmentsOnContractAttachmentsFilter
        sorting: $setAttachmentsOnContractAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useSetAttachmentsOnContractMutation__
 *
 * To run a mutation, you first call `useSetAttachmentsOnContractMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetAttachmentsOnContractMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetAttachmentsOnContractMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesPaging'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesFilter'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesSorting'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedProjectsPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedProjectsPaging'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedProjectsFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedProjectsFilter'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedProjectsSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedProjectsSorting'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setAttachmentsOnContractCreatedByCreatedByContactsPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByContactsPaging'
 *     setAttachmentsOnContractCreatedByCreatedByContactsFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByContactsFilter'
 *     setAttachmentsOnContractCreatedByCreatedByContactsSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByContactsSorting'
 *     setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnContractCreatedByCreatedByProjectChangesPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByProjectChangesPaging'
 *     setAttachmentsOnContractCreatedByCreatedByProjectChangesFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByProjectChangesFilter'
 *     setAttachmentsOnContractCreatedByCreatedByProjectChangesSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByProjectChangesSorting'
 *     setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedWorkersPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedWorkersPaging'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedWorkersFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedWorkersFilter'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedWorkersSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedWorkersSorting'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedContractsPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedContractsPaging'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedContractsFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedContractsFilter'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedContractsSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedContractsSorting'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansPaging'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansFilter'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansSorting'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setAttachmentsOnContractAttachmentsFilter: // value for 'setAttachmentsOnContractAttachmentsFilter'
 *     setAttachmentsOnContractAttachmentsSorting: // value for 'setAttachmentsOnContractAttachmentsSorting'
 *   },
 * });
 */
export function useSetAttachmentsOnContractMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetAttachmentsOnContractMutation,
        SetAttachmentsOnContractMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetAttachmentsOnContractMutation,
          SetAttachmentsOnContractMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetAttachmentsOnContractMutation,
    SetAttachmentsOnContractMutationVariables
  >(SetAttachmentsOnContractMutationDocument, options);
}
export type SetAttachmentsOnContractMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetAttachmentsOnContractMutation,
    SetAttachmentsOnContractMutationVariables
  >;
export const RemoveCreatedByFromContractMutationDocument = gql`
  mutation removeCreatedByFromContractMutation(
    $input: RemoveCreatedByFromContractInput!
    $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromContractCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromContractCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromContractCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromContractCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromContractCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromContractCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromContractCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromContractCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromContractAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromContractAttachmentsSorting: [ContractAttachmentSort!]
  ) {
    removeCreatedByFromContract(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromContractCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromContractCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromContractCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromContractCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromContractCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromContractCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromContractCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromContractCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromContractCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromContractCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromContractCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromContractCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $removeCreatedByFromContractAttachmentsFilter
        sorting: $removeCreatedByFromContractAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromContractMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromContractMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromContractMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromContractMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromContractCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromContractCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromContractCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromContractCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromContractCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromContractCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromContractAttachmentsFilter: // value for 'removeCreatedByFromContractAttachmentsFilter'
 *     removeCreatedByFromContractAttachmentsSorting: // value for 'removeCreatedByFromContractAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromContractMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromContractMutation,
        RemoveCreatedByFromContractMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromContractMutation,
          RemoveCreatedByFromContractMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromContractMutation,
    RemoveCreatedByFromContractMutationVariables
  >(RemoveCreatedByFromContractMutationDocument, options);
}
export type RemoveCreatedByFromContractMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromContractMutation,
    RemoveCreatedByFromContractMutationVariables
  >;
export const RemoveAttachmentsFromContractMutationDocument = gql`
  mutation removeAttachmentsFromContractMutation(
    $input: RemoveAttachmentsFromContractInput!
    $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeAttachmentsFromContractCreatedByCreatedByContactsPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByContactsFilter: ContactFilter
    $removeAttachmentsFromContractCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromContractCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeAttachmentsFromContractCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeAttachmentsFromContractCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeAttachmentsFromContractCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeAttachmentsFromContractAttachmentsFilter: ContractAttachmentFilter
    $removeAttachmentsFromContractAttachmentsSorting: [ContractAttachmentSort!]
  ) {
    removeAttachmentsFromContract(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsPaging
          filter: $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeAttachmentsFromContractCreatedByCreatedByContactsPaging
          filter: $removeAttachmentsFromContractCreatedByCreatedByContactsFilter
          sorting: $removeAttachmentsFromContractCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeAttachmentsFromContractCreatedByCreatedByProjectChangesPaging
          filter: $removeAttachmentsFromContractCreatedByCreatedByProjectChangesFilter
          sorting: $removeAttachmentsFromContractCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersPaging
          filter: $removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeAttachmentsFromContractCreatedByCreatedByCreatedContractsPaging
          filter: $removeAttachmentsFromContractCreatedByCreatedByCreatedContractsFilter
          sorting: $removeAttachmentsFromContractCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdMiddlemans(
          paging: $removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $removeAttachmentsFromContractAttachmentsFilter
        sorting: $removeAttachmentsFromContractAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useRemoveAttachmentsFromContractMutation__
 *
 * To run a mutation, you first call `useRemoveAttachmentsFromContractMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttachmentsFromContractMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveAttachmentsFromContractMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByContactsPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByContactsPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByContactsFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByContactsFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByContactsSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByContactsSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByProjectChangesPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByProjectChangesPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByProjectChangesFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByProjectChangesFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByProjectChangesSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByProjectChangesSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedContractsPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedContractsPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedContractsFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedContractsFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedContractsSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedContractsSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromContractAttachmentsFilter: // value for 'removeAttachmentsFromContractAttachmentsFilter'
 *     removeAttachmentsFromContractAttachmentsSorting: // value for 'removeAttachmentsFromContractAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveAttachmentsFromContractMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveAttachmentsFromContractMutation,
        RemoveAttachmentsFromContractMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveAttachmentsFromContractMutation,
          RemoveAttachmentsFromContractMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveAttachmentsFromContractMutation,
    RemoveAttachmentsFromContractMutationVariables
  >(RemoveAttachmentsFromContractMutationDocument, options);
}
export type RemoveAttachmentsFromContractMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveAttachmentsFromContractMutation,
    RemoveAttachmentsFromContractMutationVariables
  >;
export const CreateOneContractMutationDocument = gql`
  mutation createOneContractMutation(
    $input: CreateOneContractInput!
    $createOneContractCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createOneContractCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createOneContractCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneContractCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createOneContractCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createOneContractCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneContractCreatedByCreatedByContactsPaging: OffsetPaging
    $createOneContractCreatedByCreatedByContactsFilter: ContactFilter
    $createOneContractCreatedByCreatedByContactsSorting: [ContactSort!]
    $createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneContractCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createOneContractCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createOneContractCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneContractCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createOneContractCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createOneContractCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneContractCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createOneContractCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createOneContractCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createOneContractCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createOneContractCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneContractCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneContractAttachmentsFilter: ContractAttachmentFilter
    $createOneContractAttachmentsSorting: [ContractAttachmentSort!]
  ) {
    createOneContract(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createOneContractCreatedByCreatedByCreatedCompaniesPaging
          filter: $createOneContractCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createOneContractCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createOneContractCreatedByCreatedByCreatedProjectsPaging
          filter: $createOneContractCreatedByCreatedByCreatedProjectsFilter
          sorting: $createOneContractCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createOneContractCreatedByCreatedByContactsPaging
          filter: $createOneContractCreatedByCreatedByContactsFilter
          sorting: $createOneContractCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createOneContractCreatedByCreatedByProjectChangesPaging
          filter: $createOneContractCreatedByCreatedByProjectChangesFilter
          sorting: $createOneContractCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createOneContractCreatedByCreatedByCreatedWorkersPaging
          filter: $createOneContractCreatedByCreatedByCreatedWorkersFilter
          sorting: $createOneContractCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createOneContractCreatedByCreatedByCreatedContractsPaging
          filter: $createOneContractCreatedByCreatedByCreatedContractsFilter
          sorting: $createOneContractCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdMiddlemans(
          paging: $createOneContractCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createOneContractCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createOneContractCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $createOneContractAttachmentsFilter
        sorting: $createOneContractAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCreateOneContractMutation__
 *
 * To run a mutation, you first call `useCreateOneContractMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContractMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneContractMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneContractCreatedByCreatedByCreatedCompaniesPaging: // value for 'createOneContractCreatedByCreatedByCreatedCompaniesPaging'
 *     createOneContractCreatedByCreatedByCreatedCompaniesFilter: // value for 'createOneContractCreatedByCreatedByCreatedCompaniesFilter'
 *     createOneContractCreatedByCreatedByCreatedCompaniesSorting: // value for 'createOneContractCreatedByCreatedByCreatedCompaniesSorting'
 *     createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneContractCreatedByCreatedByCreatedProjectsPaging: // value for 'createOneContractCreatedByCreatedByCreatedProjectsPaging'
 *     createOneContractCreatedByCreatedByCreatedProjectsFilter: // value for 'createOneContractCreatedByCreatedByCreatedProjectsFilter'
 *     createOneContractCreatedByCreatedByCreatedProjectsSorting: // value for 'createOneContractCreatedByCreatedByCreatedProjectsSorting'
 *     createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneContractCreatedByCreatedByContactsPaging: // value for 'createOneContractCreatedByCreatedByContactsPaging'
 *     createOneContractCreatedByCreatedByContactsFilter: // value for 'createOneContractCreatedByCreatedByContactsFilter'
 *     createOneContractCreatedByCreatedByContactsSorting: // value for 'createOneContractCreatedByCreatedByContactsSorting'
 *     createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneContractCreatedByCreatedByProjectChangesPaging: // value for 'createOneContractCreatedByCreatedByProjectChangesPaging'
 *     createOneContractCreatedByCreatedByProjectChangesFilter: // value for 'createOneContractCreatedByCreatedByProjectChangesFilter'
 *     createOneContractCreatedByCreatedByProjectChangesSorting: // value for 'createOneContractCreatedByCreatedByProjectChangesSorting'
 *     createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneContractCreatedByCreatedByCreatedWorkersPaging: // value for 'createOneContractCreatedByCreatedByCreatedWorkersPaging'
 *     createOneContractCreatedByCreatedByCreatedWorkersFilter: // value for 'createOneContractCreatedByCreatedByCreatedWorkersFilter'
 *     createOneContractCreatedByCreatedByCreatedWorkersSorting: // value for 'createOneContractCreatedByCreatedByCreatedWorkersSorting'
 *     createOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneContractCreatedByCreatedByCreatedContractsPaging: // value for 'createOneContractCreatedByCreatedByCreatedContractsPaging'
 *     createOneContractCreatedByCreatedByCreatedContractsFilter: // value for 'createOneContractCreatedByCreatedByCreatedContractsFilter'
 *     createOneContractCreatedByCreatedByCreatedContractsSorting: // value for 'createOneContractCreatedByCreatedByCreatedContractsSorting'
 *     createOneContractCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createOneContractCreatedByCreatedByCreatedMiddlemansPaging'
 *     createOneContractCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createOneContractCreatedByCreatedByCreatedMiddlemansFilter'
 *     createOneContractCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createOneContractCreatedByCreatedByCreatedMiddlemansSorting'
 *     createOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneContractAttachmentsFilter: // value for 'createOneContractAttachmentsFilter'
 *     createOneContractAttachmentsSorting: // value for 'createOneContractAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneContractMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneContractMutation,
        CreateOneContractMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneContractMutation,
          CreateOneContractMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneContractMutation,
    CreateOneContractMutationVariables
  >(CreateOneContractMutationDocument, options);
}
export type CreateOneContractMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneContractMutation,
    CreateOneContractMutationVariables
  >;
export const CreateManyContractsMutationDocument = gql`
  mutation createManyContractsMutation(
    $input: CreateManyContractsInput!
    $createManyContractsCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createManyContractsCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyContractsCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createManyContractsCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyContractsCreatedByCreatedByContactsPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByContactsFilter: ContactFilter
    $createManyContractsCreatedByCreatedByContactsSorting: [ContactSort!]
    $createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyContractsCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createManyContractsCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyContractsCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createManyContractsCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createManyContractsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyContractsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyContractsCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createManyContractsCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createManyContractsCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createManyContractsCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyContractsCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyContractsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyContractsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyContractsAttachmentsFilter: ContractAttachmentFilter
    $createManyContractsAttachmentsSorting: [ContractAttachmentSort!]
  ) {
    createManyContracts(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createManyContractsCreatedByCreatedByCreatedCompaniesPaging
          filter: $createManyContractsCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createManyContractsCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createManyContractsCreatedByCreatedByCreatedProjectsPaging
          filter: $createManyContractsCreatedByCreatedByCreatedProjectsFilter
          sorting: $createManyContractsCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createManyContractsCreatedByCreatedByContactsPaging
          filter: $createManyContractsCreatedByCreatedByContactsFilter
          sorting: $createManyContractsCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createManyContractsCreatedByCreatedByProjectChangesPaging
          filter: $createManyContractsCreatedByCreatedByProjectChangesFilter
          sorting: $createManyContractsCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createManyContractsCreatedByCreatedByCreatedWorkersPaging
          filter: $createManyContractsCreatedByCreatedByCreatedWorkersFilter
          sorting: $createManyContractsCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createManyContractsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createManyContractsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createManyContractsCreatedByCreatedByCreatedContractsPaging
          filter: $createManyContractsCreatedByCreatedByCreatedContractsFilter
          sorting: $createManyContractsCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdMiddlemans(
          paging: $createManyContractsCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createManyContractsCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createManyContractsCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createManyContractsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createManyContractsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $createManyContractsAttachmentsFilter
        sorting: $createManyContractsAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCreateManyContractsMutation__
 *
 * To run a mutation, you first call `useCreateManyContractsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyContractsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyContractsMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyContractsCreatedByCreatedByCreatedCompaniesPaging: // value for 'createManyContractsCreatedByCreatedByCreatedCompaniesPaging'
 *     createManyContractsCreatedByCreatedByCreatedCompaniesFilter: // value for 'createManyContractsCreatedByCreatedByCreatedCompaniesFilter'
 *     createManyContractsCreatedByCreatedByCreatedCompaniesSorting: // value for 'createManyContractsCreatedByCreatedByCreatedCompaniesSorting'
 *     createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyContractsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyContractsCreatedByCreatedByCreatedProjectsPaging: // value for 'createManyContractsCreatedByCreatedByCreatedProjectsPaging'
 *     createManyContractsCreatedByCreatedByCreatedProjectsFilter: // value for 'createManyContractsCreatedByCreatedByCreatedProjectsFilter'
 *     createManyContractsCreatedByCreatedByCreatedProjectsSorting: // value for 'createManyContractsCreatedByCreatedByCreatedProjectsSorting'
 *     createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyContractsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyContractsCreatedByCreatedByContactsPaging: // value for 'createManyContractsCreatedByCreatedByContactsPaging'
 *     createManyContractsCreatedByCreatedByContactsFilter: // value for 'createManyContractsCreatedByCreatedByContactsFilter'
 *     createManyContractsCreatedByCreatedByContactsSorting: // value for 'createManyContractsCreatedByCreatedByContactsSorting'
 *     createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyContractsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyContractsCreatedByCreatedByProjectChangesPaging: // value for 'createManyContractsCreatedByCreatedByProjectChangesPaging'
 *     createManyContractsCreatedByCreatedByProjectChangesFilter: // value for 'createManyContractsCreatedByCreatedByProjectChangesFilter'
 *     createManyContractsCreatedByCreatedByProjectChangesSorting: // value for 'createManyContractsCreatedByCreatedByProjectChangesSorting'
 *     createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyContractsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyContractsCreatedByCreatedByCreatedWorkersPaging: // value for 'createManyContractsCreatedByCreatedByCreatedWorkersPaging'
 *     createManyContractsCreatedByCreatedByCreatedWorkersFilter: // value for 'createManyContractsCreatedByCreatedByCreatedWorkersFilter'
 *     createManyContractsCreatedByCreatedByCreatedWorkersSorting: // value for 'createManyContractsCreatedByCreatedByCreatedWorkersSorting'
 *     createManyContractsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyContractsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyContractsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyContractsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyContractsCreatedByCreatedByCreatedContractsPaging: // value for 'createManyContractsCreatedByCreatedByCreatedContractsPaging'
 *     createManyContractsCreatedByCreatedByCreatedContractsFilter: // value for 'createManyContractsCreatedByCreatedByCreatedContractsFilter'
 *     createManyContractsCreatedByCreatedByCreatedContractsSorting: // value for 'createManyContractsCreatedByCreatedByCreatedContractsSorting'
 *     createManyContractsCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createManyContractsCreatedByCreatedByCreatedMiddlemansPaging'
 *     createManyContractsCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createManyContractsCreatedByCreatedByCreatedMiddlemansFilter'
 *     createManyContractsCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createManyContractsCreatedByCreatedByCreatedMiddlemansSorting'
 *     createManyContractsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyContractsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyContractsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyContractsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyContractsAttachmentsFilter: // value for 'createManyContractsAttachmentsFilter'
 *     createManyContractsAttachmentsSorting: // value for 'createManyContractsAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyContractsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyContractsMutation,
        CreateManyContractsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyContractsMutation,
          CreateManyContractsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyContractsMutation,
    CreateManyContractsMutationVariables
  >(CreateManyContractsMutationDocument, options);
}
export type CreateManyContractsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyContractsMutation,
    CreateManyContractsMutationVariables
  >;
export const UpdateOneContractMutationDocument = gql`
  mutation updateOneContractMutation(
    $input: UpdateOneContractInput!
    $updateOneContractCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $updateOneContractCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneContractCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $updateOneContractCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneContractCreatedByCreatedByContactsPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByContactsFilter: ContactFilter
    $updateOneContractCreatedByCreatedByContactsSorting: [ContactSort!]
    $updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneContractCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByProjectChangesFilter: ContactFilter
    $updateOneContractCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneContractCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $updateOneContractCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneContractCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $updateOneContractCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $updateOneContractCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneContractCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneContractCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneContractAttachmentsFilter: ContractAttachmentFilter
    $updateOneContractAttachmentsSorting: [ContractAttachmentSort!]
  ) {
    updateOneContract(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $updateOneContractCreatedByCreatedByCreatedCompaniesPaging
          filter: $updateOneContractCreatedByCreatedByCreatedCompaniesFilter
          sorting: $updateOneContractCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $updateOneContractCreatedByCreatedByCreatedProjectsPaging
          filter: $updateOneContractCreatedByCreatedByCreatedProjectsFilter
          sorting: $updateOneContractCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $updateOneContractCreatedByCreatedByContactsPaging
          filter: $updateOneContractCreatedByCreatedByContactsFilter
          sorting: $updateOneContractCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $updateOneContractCreatedByCreatedByProjectChangesPaging
          filter: $updateOneContractCreatedByCreatedByProjectChangesFilter
          sorting: $updateOneContractCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $updateOneContractCreatedByCreatedByCreatedWorkersPaging
          filter: $updateOneContractCreatedByCreatedByCreatedWorkersFilter
          sorting: $updateOneContractCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $updateOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $updateOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $updateOneContractCreatedByCreatedByCreatedContractsPaging
          filter: $updateOneContractCreatedByCreatedByCreatedContractsFilter
          sorting: $updateOneContractCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        createdMiddlemans(
          paging: $updateOneContractCreatedByCreatedByCreatedMiddlemansPaging
          filter: $updateOneContractCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $updateOneContractCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $updateOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $updateOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $updateOneContractAttachmentsFilter
        sorting: $updateOneContractAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useUpdateOneContractMutation__
 *
 * To run a mutation, you first call `useUpdateOneContractMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContractMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneContractMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneContractCreatedByCreatedByCreatedCompaniesPaging: // value for 'updateOneContractCreatedByCreatedByCreatedCompaniesPaging'
 *     updateOneContractCreatedByCreatedByCreatedCompaniesFilter: // value for 'updateOneContractCreatedByCreatedByCreatedCompaniesFilter'
 *     updateOneContractCreatedByCreatedByCreatedCompaniesSorting: // value for 'updateOneContractCreatedByCreatedByCreatedCompaniesSorting'
 *     updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneContractCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneContractCreatedByCreatedByCreatedProjectsPaging: // value for 'updateOneContractCreatedByCreatedByCreatedProjectsPaging'
 *     updateOneContractCreatedByCreatedByCreatedProjectsFilter: // value for 'updateOneContractCreatedByCreatedByCreatedProjectsFilter'
 *     updateOneContractCreatedByCreatedByCreatedProjectsSorting: // value for 'updateOneContractCreatedByCreatedByCreatedProjectsSorting'
 *     updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneContractCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneContractCreatedByCreatedByContactsPaging: // value for 'updateOneContractCreatedByCreatedByContactsPaging'
 *     updateOneContractCreatedByCreatedByContactsFilter: // value for 'updateOneContractCreatedByCreatedByContactsFilter'
 *     updateOneContractCreatedByCreatedByContactsSorting: // value for 'updateOneContractCreatedByCreatedByContactsSorting'
 *     updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneContractCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneContractCreatedByCreatedByProjectChangesPaging: // value for 'updateOneContractCreatedByCreatedByProjectChangesPaging'
 *     updateOneContractCreatedByCreatedByProjectChangesFilter: // value for 'updateOneContractCreatedByCreatedByProjectChangesFilter'
 *     updateOneContractCreatedByCreatedByProjectChangesSorting: // value for 'updateOneContractCreatedByCreatedByProjectChangesSorting'
 *     updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneContractCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneContractCreatedByCreatedByCreatedWorkersPaging: // value for 'updateOneContractCreatedByCreatedByCreatedWorkersPaging'
 *     updateOneContractCreatedByCreatedByCreatedWorkersFilter: // value for 'updateOneContractCreatedByCreatedByCreatedWorkersFilter'
 *     updateOneContractCreatedByCreatedByCreatedWorkersSorting: // value for 'updateOneContractCreatedByCreatedByCreatedWorkersSorting'
 *     updateOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneContractCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneContractCreatedByCreatedByCreatedContractsPaging: // value for 'updateOneContractCreatedByCreatedByCreatedContractsPaging'
 *     updateOneContractCreatedByCreatedByCreatedContractsFilter: // value for 'updateOneContractCreatedByCreatedByCreatedContractsFilter'
 *     updateOneContractCreatedByCreatedByCreatedContractsSorting: // value for 'updateOneContractCreatedByCreatedByCreatedContractsSorting'
 *     updateOneContractCreatedByCreatedByCreatedMiddlemansPaging: // value for 'updateOneContractCreatedByCreatedByCreatedMiddlemansPaging'
 *     updateOneContractCreatedByCreatedByCreatedMiddlemansFilter: // value for 'updateOneContractCreatedByCreatedByCreatedMiddlemansFilter'
 *     updateOneContractCreatedByCreatedByCreatedMiddlemansSorting: // value for 'updateOneContractCreatedByCreatedByCreatedMiddlemansSorting'
 *     updateOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneContractCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneContractAttachmentsFilter: // value for 'updateOneContractAttachmentsFilter'
 *     updateOneContractAttachmentsSorting: // value for 'updateOneContractAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneContractMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneContractMutation,
        UpdateOneContractMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneContractMutation,
          UpdateOneContractMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneContractMutation,
    UpdateOneContractMutationVariables
  >(UpdateOneContractMutationDocument, options);
}
export type UpdateOneContractMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneContractMutation,
    UpdateOneContractMutationVariables
  >;
export const UpdateManyContractsMutationDocument = gql`
  mutation updateManyContractsMutation($input: UpdateManyContractsInput!) {
    updateManyContracts(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyContractsMutation__
 *
 * To run a mutation, you first call `useUpdateManyContractsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyContractsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyContractsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyContractsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyContractsMutation,
        UpdateManyContractsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyContractsMutation,
          UpdateManyContractsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyContractsMutation,
    UpdateManyContractsMutationVariables
  >(UpdateManyContractsMutationDocument, options);
}
export type UpdateManyContractsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyContractsMutation,
    UpdateManyContractsMutationVariables
  >;
export const DeleteOneContractMutationDocument = gql`
  mutation deleteOneContractMutation($input: DeleteOneContractInput!) {
    deleteOneContract(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
    }
  }
`;

/**
 * __useDeleteOneContractMutation__
 *
 * To run a mutation, you first call `useDeleteOneContractMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContractMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneContractMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneContractMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneContractMutation,
        DeleteOneContractMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneContractMutation,
          DeleteOneContractMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneContractMutation,
    DeleteOneContractMutationVariables
  >(DeleteOneContractMutationDocument, options);
}
export type DeleteOneContractMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneContractMutation,
    DeleteOneContractMutationVariables
  >;
export const DeleteManyContractsMutationDocument = gql`
  mutation deleteManyContractsMutation($input: DeleteManyContractsInput!) {
    deleteManyContracts(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyContractsMutation__
 *
 * To run a mutation, you first call `useDeleteManyContractsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyContractsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyContractsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyContractsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyContractsMutation,
        DeleteManyContractsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyContractsMutation,
          DeleteManyContractsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyContractsMutation,
    DeleteManyContractsMutationVariables
  >(DeleteManyContractsMutationDocument, options);
}
export type DeleteManyContractsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyContractsMutation,
    DeleteManyContractsMutationVariables
  >;
export const CreateOneContractAttachmentMutationDocument = gql`
  mutation createOneContractAttachmentMutation(
    $input: CreateOneContractAttachmentInput!
  ) {
    createOneContractAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCreateOneContractAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateOneContractAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContractAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneContractAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneContractAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneContractAttachmentMutation,
        CreateOneContractAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneContractAttachmentMutation,
          CreateOneContractAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneContractAttachmentMutation,
    CreateOneContractAttachmentMutationVariables
  >(CreateOneContractAttachmentMutationDocument, options);
}
export type CreateOneContractAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneContractAttachmentMutation,
    CreateOneContractAttachmentMutationVariables
  >;
export const CreateManyContractAttachmentsMutationDocument = gql`
  mutation createManyContractAttachmentsMutation(
    $input: CreateManyContractAttachmentsInput!
  ) {
    createManyContractAttachments(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCreateManyContractAttachmentsMutation__
 *
 * To run a mutation, you first call `useCreateManyContractAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyContractAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyContractAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyContractAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyContractAttachmentsMutation,
        CreateManyContractAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyContractAttachmentsMutation,
          CreateManyContractAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyContractAttachmentsMutation,
    CreateManyContractAttachmentsMutationVariables
  >(CreateManyContractAttachmentsMutationDocument, options);
}
export type CreateManyContractAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyContractAttachmentsMutation,
    CreateManyContractAttachmentsMutationVariables
  >;
export const UpdateOneContractAttachmentMutationDocument = gql`
  mutation updateOneContractAttachmentMutation(
    $input: UpdateOneContractAttachmentInput!
  ) {
    updateOneContractAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useUpdateOneContractAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateOneContractAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContractAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneContractAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneContractAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneContractAttachmentMutation,
        UpdateOneContractAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneContractAttachmentMutation,
          UpdateOneContractAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneContractAttachmentMutation,
    UpdateOneContractAttachmentMutationVariables
  >(UpdateOneContractAttachmentMutationDocument, options);
}
export type UpdateOneContractAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneContractAttachmentMutation,
    UpdateOneContractAttachmentMutationVariables
  >;
export const UpdateManyContractAttachmentsMutationDocument = gql`
  mutation updateManyContractAttachmentsMutation(
    $input: UpdateManyContractAttachmentsInput!
  ) {
    updateManyContractAttachments(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyContractAttachmentsMutation__
 *
 * To run a mutation, you first call `useUpdateManyContractAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyContractAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyContractAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyContractAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyContractAttachmentsMutation,
        UpdateManyContractAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyContractAttachmentsMutation,
          UpdateManyContractAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyContractAttachmentsMutation,
    UpdateManyContractAttachmentsMutationVariables
  >(UpdateManyContractAttachmentsMutationDocument, options);
}
export type UpdateManyContractAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyContractAttachmentsMutation,
    UpdateManyContractAttachmentsMutationVariables
  >;
export const DeleteOneContractAttachmentMutationDocument = gql`
  mutation deleteOneContractAttachmentMutation(
    $input: DeleteOneContractAttachmentInput!
  ) {
    deleteOneContractAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useDeleteOneContractAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteOneContractAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContractAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneContractAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneContractAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneContractAttachmentMutation,
        DeleteOneContractAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneContractAttachmentMutation,
          DeleteOneContractAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneContractAttachmentMutation,
    DeleteOneContractAttachmentMutationVariables
  >(DeleteOneContractAttachmentMutationDocument, options);
}
export type DeleteOneContractAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneContractAttachmentMutation,
    DeleteOneContractAttachmentMutationVariables
  >;
export const DeleteManyContractAttachmentsMutationDocument = gql`
  mutation deleteManyContractAttachmentsMutation(
    $input: DeleteManyContractAttachmentsInput!
  ) {
    deleteManyContractAttachments(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyContractAttachmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyContractAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyContractAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyContractAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyContractAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyContractAttachmentsMutation,
        DeleteManyContractAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyContractAttachmentsMutation,
          DeleteManyContractAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyContractAttachmentsMutation,
    DeleteManyContractAttachmentsMutationVariables
  >(DeleteManyContractAttachmentsMutationDocument, options);
}
export type DeleteManyContractAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyContractAttachmentsMutation,
    DeleteManyContractAttachmentsMutationVariables
  >;
export const AddAttachmentsToContractPilverMutationDocument = gql`
  mutation addAttachmentsToContractPilverMutation(
    $input: AddAttachmentsToContractPilverInput!
    $addAttachmentsToContractPilverAttachmentsFilter: ContractPilverAttachmentFilter
    $addAttachmentsToContractPilverAttachmentsSorting: [ContractPilverAttachmentSort!]
  ) {
    addAttachmentsToContractPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      attachments(
        filter: $addAttachmentsToContractPilverAttachmentsFilter
        sorting: $addAttachmentsToContractPilverAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useAddAttachmentsToContractPilverMutation__
 *
 * To run a mutation, you first call `useAddAttachmentsToContractPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentsToContractPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddAttachmentsToContractPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addAttachmentsToContractPilverAttachmentsFilter: // value for 'addAttachmentsToContractPilverAttachmentsFilter'
 *     addAttachmentsToContractPilverAttachmentsSorting: // value for 'addAttachmentsToContractPilverAttachmentsSorting'
 *   },
 * });
 */
export function useAddAttachmentsToContractPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddAttachmentsToContractPilverMutation,
        AddAttachmentsToContractPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddAttachmentsToContractPilverMutation,
          AddAttachmentsToContractPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddAttachmentsToContractPilverMutation,
    AddAttachmentsToContractPilverMutationVariables
  >(AddAttachmentsToContractPilverMutationDocument, options);
}
export type AddAttachmentsToContractPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddAttachmentsToContractPilverMutation,
    AddAttachmentsToContractPilverMutationVariables
  >;
export const SetAttachmentsOnContractPilverMutationDocument = gql`
  mutation setAttachmentsOnContractPilverMutation(
    $input: SetAttachmentsOnContractPilverInput!
    $setAttachmentsOnContractPilverAttachmentsFilter: ContractPilverAttachmentFilter
    $setAttachmentsOnContractPilverAttachmentsSorting: [ContractPilverAttachmentSort!]
  ) {
    setAttachmentsOnContractPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      attachments(
        filter: $setAttachmentsOnContractPilverAttachmentsFilter
        sorting: $setAttachmentsOnContractPilverAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useSetAttachmentsOnContractPilverMutation__
 *
 * To run a mutation, you first call `useSetAttachmentsOnContractPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetAttachmentsOnContractPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetAttachmentsOnContractPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setAttachmentsOnContractPilverAttachmentsFilter: // value for 'setAttachmentsOnContractPilverAttachmentsFilter'
 *     setAttachmentsOnContractPilverAttachmentsSorting: // value for 'setAttachmentsOnContractPilverAttachmentsSorting'
 *   },
 * });
 */
export function useSetAttachmentsOnContractPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetAttachmentsOnContractPilverMutation,
        SetAttachmentsOnContractPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetAttachmentsOnContractPilverMutation,
          SetAttachmentsOnContractPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetAttachmentsOnContractPilverMutation,
    SetAttachmentsOnContractPilverMutationVariables
  >(SetAttachmentsOnContractPilverMutationDocument, options);
}
export type SetAttachmentsOnContractPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetAttachmentsOnContractPilverMutation,
    SetAttachmentsOnContractPilverMutationVariables
  >;
export const RemoveAttachmentsFromContractPilverMutationDocument = gql`
  mutation removeAttachmentsFromContractPilverMutation(
    $input: RemoveAttachmentsFromContractPilverInput!
    $removeAttachmentsFromContractPilverAttachmentsFilter: ContractPilverAttachmentFilter
    $removeAttachmentsFromContractPilverAttachmentsSorting: [ContractPilverAttachmentSort!]
  ) {
    removeAttachmentsFromContractPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      attachments(
        filter: $removeAttachmentsFromContractPilverAttachmentsFilter
        sorting: $removeAttachmentsFromContractPilverAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useRemoveAttachmentsFromContractPilverMutation__
 *
 * To run a mutation, you first call `useRemoveAttachmentsFromContractPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttachmentsFromContractPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveAttachmentsFromContractPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeAttachmentsFromContractPilverAttachmentsFilter: // value for 'removeAttachmentsFromContractPilverAttachmentsFilter'
 *     removeAttachmentsFromContractPilverAttachmentsSorting: // value for 'removeAttachmentsFromContractPilverAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveAttachmentsFromContractPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveAttachmentsFromContractPilverMutation,
        RemoveAttachmentsFromContractPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveAttachmentsFromContractPilverMutation,
          RemoveAttachmentsFromContractPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveAttachmentsFromContractPilverMutation,
    RemoveAttachmentsFromContractPilverMutationVariables
  >(RemoveAttachmentsFromContractPilverMutationDocument, options);
}
export type RemoveAttachmentsFromContractPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveAttachmentsFromContractPilverMutation,
    RemoveAttachmentsFromContractPilverMutationVariables
  >;
export const CreateOneContractPilverMutationDocument = gql`
  mutation createOneContractPilverMutation(
    $input: CreateOneContractPilverInput!
    $createOneContractPilverAttachmentsFilter: ContractPilverAttachmentFilter
    $createOneContractPilverAttachmentsSorting: [ContractPilverAttachmentSort!]
  ) {
    createOneContractPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      attachments(
        filter: $createOneContractPilverAttachmentsFilter
        sorting: $createOneContractPilverAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCreateOneContractPilverMutation__
 *
 * To run a mutation, you first call `useCreateOneContractPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContractPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneContractPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneContractPilverAttachmentsFilter: // value for 'createOneContractPilverAttachmentsFilter'
 *     createOneContractPilverAttachmentsSorting: // value for 'createOneContractPilverAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneContractPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneContractPilverMutation,
        CreateOneContractPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneContractPilverMutation,
          CreateOneContractPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneContractPilverMutation,
    CreateOneContractPilverMutationVariables
  >(CreateOneContractPilverMutationDocument, options);
}
export type CreateOneContractPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneContractPilverMutation,
    CreateOneContractPilverMutationVariables
  >;
export const CreateManyContractPilversMutationDocument = gql`
  mutation createManyContractPilversMutation(
    $input: CreateManyContractPilversInput!
    $createManyContractPilversAttachmentsFilter: ContractPilverAttachmentFilter
    $createManyContractPilversAttachmentsSorting: [ContractPilverAttachmentSort!]
  ) {
    createManyContractPilvers(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      attachments(
        filter: $createManyContractPilversAttachmentsFilter
        sorting: $createManyContractPilversAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCreateManyContractPilversMutation__
 *
 * To run a mutation, you first call `useCreateManyContractPilversMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyContractPilversMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyContractPilversMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyContractPilversAttachmentsFilter: // value for 'createManyContractPilversAttachmentsFilter'
 *     createManyContractPilversAttachmentsSorting: // value for 'createManyContractPilversAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyContractPilversMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyContractPilversMutation,
        CreateManyContractPilversMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyContractPilversMutation,
          CreateManyContractPilversMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyContractPilversMutation,
    CreateManyContractPilversMutationVariables
  >(CreateManyContractPilversMutationDocument, options);
}
export type CreateManyContractPilversMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyContractPilversMutation,
    CreateManyContractPilversMutationVariables
  >;
export const UpdateOneContractPilverMutationDocument = gql`
  mutation updateOneContractPilverMutation(
    $input: UpdateOneContractPilverInput!
    $updateOneContractPilverAttachmentsFilter: ContractPilverAttachmentFilter
    $updateOneContractPilverAttachmentsSorting: [ContractPilverAttachmentSort!]
  ) {
    updateOneContractPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
      attachments(
        filter: $updateOneContractPilverAttachmentsFilter
        sorting: $updateOneContractPilverAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useUpdateOneContractPilverMutation__
 *
 * To run a mutation, you first call `useUpdateOneContractPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContractPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneContractPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneContractPilverAttachmentsFilter: // value for 'updateOneContractPilverAttachmentsFilter'
 *     updateOneContractPilverAttachmentsSorting: // value for 'updateOneContractPilverAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneContractPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneContractPilverMutation,
        UpdateOneContractPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneContractPilverMutation,
          UpdateOneContractPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneContractPilverMutation,
    UpdateOneContractPilverMutationVariables
  >(UpdateOneContractPilverMutationDocument, options);
}
export type UpdateOneContractPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneContractPilverMutation,
    UpdateOneContractPilverMutationVariables
  >;
export const UpdateManyContractPilversMutationDocument = gql`
  mutation updateManyContractPilversMutation(
    $input: UpdateManyContractPilversInput!
  ) {
    updateManyContractPilvers(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyContractPilversMutation__
 *
 * To run a mutation, you first call `useUpdateManyContractPilversMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyContractPilversMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyContractPilversMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyContractPilversMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyContractPilversMutation,
        UpdateManyContractPilversMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyContractPilversMutation,
          UpdateManyContractPilversMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyContractPilversMutation,
    UpdateManyContractPilversMutationVariables
  >(UpdateManyContractPilversMutationDocument, options);
}
export type UpdateManyContractPilversMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyContractPilversMutation,
    UpdateManyContractPilversMutationVariables
  >;
export const DeleteOneContractPilverMutationDocument = gql`
  mutation deleteOneContractPilverMutation(
    $input: DeleteOneContractPilverInput!
  ) {
    deleteOneContractPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Type
      Year
    }
  }
`;

/**
 * __useDeleteOneContractPilverMutation__
 *
 * To run a mutation, you first call `useDeleteOneContractPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContractPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneContractPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneContractPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneContractPilverMutation,
        DeleteOneContractPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneContractPilverMutation,
          DeleteOneContractPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneContractPilverMutation,
    DeleteOneContractPilverMutationVariables
  >(DeleteOneContractPilverMutationDocument, options);
}
export type DeleteOneContractPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneContractPilverMutation,
    DeleteOneContractPilverMutationVariables
  >;
export const DeleteManyContractPilversMutationDocument = gql`
  mutation deleteManyContractPilversMutation(
    $input: DeleteManyContractPilversInput!
  ) {
    deleteManyContractPilvers(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyContractPilversMutation__
 *
 * To run a mutation, you first call `useDeleteManyContractPilversMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyContractPilversMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyContractPilversMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyContractPilversMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyContractPilversMutation,
        DeleteManyContractPilversMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyContractPilversMutation,
          DeleteManyContractPilversMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyContractPilversMutation,
    DeleteManyContractPilversMutationVariables
  >(DeleteManyContractPilversMutationDocument, options);
}
export type DeleteManyContractPilversMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyContractPilversMutation,
    DeleteManyContractPilversMutationVariables
  >;
export const CreateOneContractPilverAttachmentMutationDocument = gql`
  mutation createOneContractPilverAttachmentMutation(
    $input: CreateOneContractPilverAttachmentInput!
  ) {
    createOneContractPilverAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCreateOneContractPilverAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateOneContractPilverAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContractPilverAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneContractPilverAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneContractPilverAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneContractPilverAttachmentMutation,
        CreateOneContractPilverAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneContractPilverAttachmentMutation,
          CreateOneContractPilverAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneContractPilverAttachmentMutation,
    CreateOneContractPilverAttachmentMutationVariables
  >(CreateOneContractPilverAttachmentMutationDocument, options);
}
export type CreateOneContractPilverAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneContractPilverAttachmentMutation,
    CreateOneContractPilverAttachmentMutationVariables
  >;
export const CreateManyContractPilverAttachmentsMutationDocument = gql`
  mutation createManyContractPilverAttachmentsMutation(
    $input: CreateManyContractPilverAttachmentsInput!
  ) {
    createManyContractPilverAttachments(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCreateManyContractPilverAttachmentsMutation__
 *
 * To run a mutation, you first call `useCreateManyContractPilverAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyContractPilverAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyContractPilverAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyContractPilverAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyContractPilverAttachmentsMutation,
        CreateManyContractPilverAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyContractPilverAttachmentsMutation,
          CreateManyContractPilverAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyContractPilverAttachmentsMutation,
    CreateManyContractPilverAttachmentsMutationVariables
  >(CreateManyContractPilverAttachmentsMutationDocument, options);
}
export type CreateManyContractPilverAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyContractPilverAttachmentsMutation,
    CreateManyContractPilverAttachmentsMutationVariables
  >;
export const UpdateOneContractPilverAttachmentMutationDocument = gql`
  mutation updateOneContractPilverAttachmentMutation(
    $input: UpdateOneContractPilverAttachmentInput!
  ) {
    updateOneContractPilverAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useUpdateOneContractPilverAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateOneContractPilverAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContractPilverAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneContractPilverAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneContractPilverAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneContractPilverAttachmentMutation,
        UpdateOneContractPilverAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneContractPilverAttachmentMutation,
          UpdateOneContractPilverAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneContractPilverAttachmentMutation,
    UpdateOneContractPilverAttachmentMutationVariables
  >(UpdateOneContractPilverAttachmentMutationDocument, options);
}
export type UpdateOneContractPilverAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneContractPilverAttachmentMutation,
    UpdateOneContractPilverAttachmentMutationVariables
  >;
export const UpdateManyContractPilverAttachmentsMutationDocument = gql`
  mutation updateManyContractPilverAttachmentsMutation(
    $input: UpdateManyContractPilverAttachmentsInput!
  ) {
    updateManyContractPilverAttachments(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyContractPilverAttachmentsMutation__
 *
 * To run a mutation, you first call `useUpdateManyContractPilverAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyContractPilverAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyContractPilverAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyContractPilverAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyContractPilverAttachmentsMutation,
        UpdateManyContractPilverAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyContractPilverAttachmentsMutation,
          UpdateManyContractPilverAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyContractPilverAttachmentsMutation,
    UpdateManyContractPilverAttachmentsMutationVariables
  >(UpdateManyContractPilverAttachmentsMutationDocument, options);
}
export type UpdateManyContractPilverAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyContractPilverAttachmentsMutation,
    UpdateManyContractPilverAttachmentsMutationVariables
  >;
export const DeleteOneContractPilverAttachmentMutationDocument = gql`
  mutation deleteOneContractPilverAttachmentMutation(
    $input: DeleteOneContractPilverAttachmentInput!
  ) {
    deleteOneContractPilverAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useDeleteOneContractPilverAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteOneContractPilverAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContractPilverAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneContractPilverAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneContractPilverAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneContractPilverAttachmentMutation,
        DeleteOneContractPilverAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneContractPilverAttachmentMutation,
          DeleteOneContractPilverAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneContractPilverAttachmentMutation,
    DeleteOneContractPilverAttachmentMutationVariables
  >(DeleteOneContractPilverAttachmentMutationDocument, options);
}
export type DeleteOneContractPilverAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneContractPilverAttachmentMutation,
    DeleteOneContractPilverAttachmentMutationVariables
  >;
export const DeleteManyContractPilverAttachmentsMutationDocument = gql`
  mutation deleteManyContractPilverAttachmentsMutation(
    $input: DeleteManyContractPilverAttachmentsInput!
  ) {
    deleteManyContractPilverAttachments(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyContractPilverAttachmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyContractPilverAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyContractPilverAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyContractPilverAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyContractPilverAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyContractPilverAttachmentsMutation,
        DeleteManyContractPilverAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyContractPilverAttachmentsMutation,
          DeleteManyContractPilverAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyContractPilverAttachmentsMutation,
    DeleteManyContractPilverAttachmentsMutationVariables
  >(DeleteManyContractPilverAttachmentsMutationDocument, options);
}
export type DeleteManyContractPilverAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyContractPilverAttachmentsMutation,
    DeleteManyContractPilverAttachmentsMutationVariables
  >;
export const SetContactOnDeletedCompanyMutationDocument = gql`
  mutation setContactOnDeletedCompanyMutation(
    $input: SetContactOnDeletedCompanyInput!
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnDeletedCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyDeletedByDeletedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setContactOnDeletedCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging
            filter: $setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter
            sorting: $setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
          createdProjects(
            paging: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setContactOnDeletedCompanyContactContactContactedByContactedByContactsPaging
            filter: $setContactOnDeletedCompanyContactContactContactedByContactedByContactsFilter
            sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setContactOnDeletedCompanyCreatedByCreatedByContactsPaging
          filter: $setContactOnDeletedCompanyCreatedByCreatedByContactsFilter
          sorting: $setContactOnDeletedCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesPaging
          filter: $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setContactOnDeletedCompanyHistoriesPaging
        filter: $setContactOnDeletedCompanyHistoriesFilter
        sorting: $setContactOnDeletedCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdProjects(
                paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
            createdProjects(
              paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging
          filter: $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter
          sorting: $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging
          filter: $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter
          sorting: $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setContactOnDeletedCompanyDeletedByDeletedByContactsPaging
          filter: $setContactOnDeletedCompanyDeletedByDeletedByContactsFilter
          sorting: $setContactOnDeletedCompanyDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesPaging
          filter: $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesFilter
          sorting: $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging
          filter: $setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter
          sorting: $setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging
          filter: $setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter
          sorting: $setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging
          filter: $setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetContactOnDeletedCompanyMutation__
 *
 * To run a mutation, you first call `useSetContactOnDeletedCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetContactOnDeletedCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetContactOnDeletedCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByContactsPaging: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByContactsPaging'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByContactsFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByContactsFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByContactsSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByContactsSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByContactsPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByContactsPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByContactsFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByContactsFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByContactsSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByContactsSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByProjectChangesPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByProjectChangesFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByProjectChangesSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByContactsPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByContactsPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByContactsFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByContactsFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByContactsSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByContactsSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByProjectChangesPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByProjectChangesFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByProjectChangesSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetContactOnDeletedCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetContactOnDeletedCompanyMutation,
        SetContactOnDeletedCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetContactOnDeletedCompanyMutation,
          SetContactOnDeletedCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetContactOnDeletedCompanyMutation,
    SetContactOnDeletedCompanyMutationVariables
  >(SetContactOnDeletedCompanyMutationDocument, options);
}
export type SetContactOnDeletedCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetContactOnDeletedCompanyMutation,
    SetContactOnDeletedCompanyMutationVariables
  >;
export const SetCreatedByOnDeletedCompanyMutationDocument = gql`
  mutation setCreatedByOnDeletedCompanyMutation(
    $input: SetCreatedByOnDeletedCompanyInput!
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedByOnDeletedCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging
            filter: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter
            sorting: $setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
          createdProjects(
            paging: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsPaging
            filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsFilter
            sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setCreatedByOnDeletedCompanyHistoriesPaging
        filter: $setCreatedByOnDeletedCompanyHistoriesFilter
        sorting: $setCreatedByOnDeletedCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdProjects(
                paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
            createdProjects(
              paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging
          filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter
          sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging
          filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter
          sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsPaging
          filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsFilter
          sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesPaging
          filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesFilter
          sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging
          filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter
          sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging
          filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter
          sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging
          filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedByOnDeletedCompanyMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnDeletedCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnDeletedCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnDeletedCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsPaging'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByContactsSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByContactsPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByContactsFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByContactsSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByContactsPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByContactsFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByContactsSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnDeletedCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnDeletedCompanyMutation,
        SetCreatedByOnDeletedCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnDeletedCompanyMutation,
          SetCreatedByOnDeletedCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnDeletedCompanyMutation,
    SetCreatedByOnDeletedCompanyMutationVariables
  >(SetCreatedByOnDeletedCompanyMutationDocument, options);
}
export type SetCreatedByOnDeletedCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnDeletedCompanyMutation,
    SetCreatedByOnDeletedCompanyMutationVariables
  >;
export const AddHistoriesToDeletedCompanyMutationDocument = gql`
  mutation addHistoriesToDeletedCompanyMutation(
    $input: AddHistoriesToDeletedCompanyInput!
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToDeletedCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addHistoriesToDeletedCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesPaging
            filter: $addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesFilter
            sorting: $addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
          createdProjects(
            paging: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsPaging
            filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsFilter
            sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addHistoriesToDeletedCompanyCreatedByCreatedByContactsPaging
          filter: $addHistoriesToDeletedCompanyCreatedByCreatedByContactsFilter
          sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesPaging
          filter: $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $addHistoriesToDeletedCompanyHistoriesPaging
        filter: $addHistoriesToDeletedCompanyHistoriesFilter
        sorting: $addHistoriesToDeletedCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdProjects(
                paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
            createdProjects(
              paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging
          filter: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter
          sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsPaging
          filter: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsFilter
          sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addHistoriesToDeletedCompanyDeletedByDeletedByContactsPaging
          filter: $addHistoriesToDeletedCompanyDeletedByDeletedByContactsFilter
          sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesPaging
          filter: $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesFilter
          sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersPaging
          filter: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersFilter
          sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsPaging
          filter: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsFilter
          sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging
          filter: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAddHistoriesToDeletedCompanyMutation__
 *
 * To run a mutation, you first call `useAddHistoriesToDeletedCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddHistoriesToDeletedCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddHistoriesToDeletedCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyContactContactCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsPaging: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsPaging'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByContactsSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByContactsPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByContactsPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByContactsFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByContactsFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByContactsSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByContactsSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByContactsPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByContactsPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByContactsFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByContactsFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByContactsSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByContactsSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddHistoriesToDeletedCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddHistoriesToDeletedCompanyMutation,
        AddHistoriesToDeletedCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddHistoriesToDeletedCompanyMutation,
          AddHistoriesToDeletedCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddHistoriesToDeletedCompanyMutation,
    AddHistoriesToDeletedCompanyMutationVariables
  >(AddHistoriesToDeletedCompanyMutationDocument, options);
}
export type AddHistoriesToDeletedCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddHistoriesToDeletedCompanyMutation,
    AddHistoriesToDeletedCompanyMutationVariables
  >;
export const SetHistoriesOnDeletedCompanyMutationDocument = gql`
  mutation setHistoriesOnDeletedCompanyMutation(
    $input: SetHistoriesOnDeletedCompanyInput!
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnDeletedCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setHistoriesOnDeletedCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging
            filter: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter
            sorting: $setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
          createdProjects(
            paging: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsPaging
            filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsFilter
            sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsPaging
          filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsFilter
          sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesPaging
          filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setHistoriesOnDeletedCompanyHistoriesPaging
        filter: $setHistoriesOnDeletedCompanyHistoriesFilter
        sorting: $setHistoriesOnDeletedCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdProjects(
                paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
            createdProjects(
              paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging
          filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter
          sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging
          filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter
          sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsPaging
          filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsFilter
          sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesPaging
          filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesFilter
          sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging
          filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter
          sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging
          filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter
          sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging
          filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetHistoriesOnDeletedCompanyMutation__
 *
 * To run a mutation, you first call `useSetHistoriesOnDeletedCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetHistoriesOnDeletedCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetHistoriesOnDeletedCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyContactContactCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsPaging'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByContactsSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByContactsPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByContactsFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByContactsSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByContactsPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByContactsFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByContactsSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetHistoriesOnDeletedCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetHistoriesOnDeletedCompanyMutation,
        SetHistoriesOnDeletedCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetHistoriesOnDeletedCompanyMutation,
          SetHistoriesOnDeletedCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetHistoriesOnDeletedCompanyMutation,
    SetHistoriesOnDeletedCompanyMutationVariables
  >(SetHistoriesOnDeletedCompanyMutationDocument, options);
}
export type SetHistoriesOnDeletedCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetHistoriesOnDeletedCompanyMutation,
    SetHistoriesOnDeletedCompanyMutationVariables
  >;
export const RemoveContactFromDeletedCompanyMutationDocument = gql`
  mutation removeContactFromDeletedCompanyMutation(
    $input: RemoveContactFromDeletedCompanyInput!
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromDeletedCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeContactFromDeletedCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging
            filter: $removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter
            sorting: $removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
          createdProjects(
            paging: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeContactFromDeletedCompanyContactContactContactedByContactedByContactsPaging
            filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByContactsFilter
            sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeContactFromDeletedCompanyCreatedByCreatedByContactsPaging
          filter: $removeContactFromDeletedCompanyCreatedByCreatedByContactsFilter
          sorting: $removeContactFromDeletedCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesPaging
          filter: $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeContactFromDeletedCompanyHistoriesPaging
        filter: $removeContactFromDeletedCompanyHistoriesFilter
        sorting: $removeContactFromDeletedCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdProjects(
                paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
            createdProjects(
              paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging
          filter: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter
          sorting: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging
          filter: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter
          sorting: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeContactFromDeletedCompanyDeletedByDeletedByContactsPaging
          filter: $removeContactFromDeletedCompanyDeletedByDeletedByContactsFilter
          sorting: $removeContactFromDeletedCompanyDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesPaging
          filter: $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesFilter
          sorting: $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging
          filter: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter
          sorting: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging
          filter: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter
          sorting: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging
          filter: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveContactFromDeletedCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveContactFromDeletedCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactFromDeletedCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveContactFromDeletedCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByContactsPaging: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByContactsPaging'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByContactsFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByContactsFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByContactsSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByContactsSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByContactsPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByContactsPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByContactsFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByContactsFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByContactsSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByContactsSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByContactsPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByContactsPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByContactsFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByContactsFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByContactsSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByContactsSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveContactFromDeletedCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveContactFromDeletedCompanyMutation,
        RemoveContactFromDeletedCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveContactFromDeletedCompanyMutation,
          RemoveContactFromDeletedCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveContactFromDeletedCompanyMutation,
    RemoveContactFromDeletedCompanyMutationVariables
  >(RemoveContactFromDeletedCompanyMutationDocument, options);
}
export type RemoveContactFromDeletedCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveContactFromDeletedCompanyMutation,
    RemoveContactFromDeletedCompanyMutationVariables
  >;
export const RemoveCreatedByFromDeletedCompanyMutationDocument = gql`
  mutation removeCreatedByFromDeletedCompanyMutation(
    $input: RemoveCreatedByFromDeletedCompanyInput!
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedByFromDeletedCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging
            filter: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter
            sorting: $removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
          createdProjects(
            paging: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsPaging
            filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsFilter
            sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeCreatedByFromDeletedCompanyHistoriesPaging
        filter: $removeCreatedByFromDeletedCompanyHistoriesFilter
        sorting: $removeCreatedByFromDeletedCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdProjects(
                paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
            createdProjects(
              paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging
          filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging
          filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter
          sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsPaging
          filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsFilter
          sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesPaging
          filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesFilter
          sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging
          filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter
          sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging
          filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter
          sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromDeletedCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromDeletedCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromDeletedCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromDeletedCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromDeletedCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromDeletedCompanyMutation,
        RemoveCreatedByFromDeletedCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromDeletedCompanyMutation,
          RemoveCreatedByFromDeletedCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromDeletedCompanyMutation,
    RemoveCreatedByFromDeletedCompanyMutationVariables
  >(RemoveCreatedByFromDeletedCompanyMutationDocument, options);
}
export type RemoveCreatedByFromDeletedCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromDeletedCompanyMutation,
    RemoveCreatedByFromDeletedCompanyMutationVariables
  >;
export const RemoveHistoriesFromDeletedCompanyMutationDocument = gql`
  mutation removeHistoriesFromDeletedCompanyMutation(
    $input: RemoveHistoriesFromDeletedCompanyInput!
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromDeletedCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeHistoriesFromDeletedCompany(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdProjects(
              paging: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging
            filter: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter
            sorting: $removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
          createdProjects(
            paging: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsPaging
            filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsFilter
            sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          projectChanges(
            paging: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging
            filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          createdWorkers(
            paging: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging
          filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsPaging
          filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsFilter
          sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesPaging
          filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesFilter
          sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging
          filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging
          filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter
          sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeHistoriesFromDeletedCompanyHistoriesPaging
        filter: $removeHistoriesFromDeletedCompanyHistoriesFilter
        sorting: $removeHistoriesFromDeletedCompanyHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdProjects(
                paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              projectChanges(
                paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
              createdWorkers(
                paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
            createdProjects(
              paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            projectChanges(
              paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
            createdWorkers(
              paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging
          filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter
          sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging
          filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter
          sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsPaging
          filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsFilter
          sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesPaging
          filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesFilter
          sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging
          filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter
          sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging
          filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter
          sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging
          filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveHistoriesFromDeletedCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveHistoriesFromDeletedCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHistoriesFromDeletedCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveHistoriesFromDeletedCompanyMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveHistoriesFromDeletedCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveHistoriesFromDeletedCompanyMutation,
        RemoveHistoriesFromDeletedCompanyMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveHistoriesFromDeletedCompanyMutation,
          RemoveHistoriesFromDeletedCompanyMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveHistoriesFromDeletedCompanyMutation,
    RemoveHistoriesFromDeletedCompanyMutationVariables
  >(RemoveHistoriesFromDeletedCompanyMutationDocument, options);
}
export type RemoveHistoriesFromDeletedCompanyMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveHistoriesFromDeletedCompanyMutation,
    RemoveHistoriesFromDeletedCompanyMutationVariables
  >;
export const SetContactOnDeletedCompanyPilverMutationDocument = gql`
  mutation setContactOnDeletedCompanyPilverMutation(
    $input: SetContactOnDeletedCompanyPilverInput!
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: ContactPilverFilter
    $setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnDeletedCompanyPilverHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesFilter: ContactPilverFilter
    $setContactOnDeletedCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactPilverFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsFilter: ContactFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setContactOnDeletedCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging
            filter: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter
            sorting: $setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsPaging
          filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setContactOnDeletedCompanyPilverHistoriesPaging
        filter: $setContactOnDeletedCompanyPilverHistoriesFilter
        sorting: $setContactOnDeletedCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging
          filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter
          sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging
          filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter
          sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsPaging
          filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsFilter
          sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging
          filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter
          sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging
          filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter
          sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging
          filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter
          sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging
          filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetContactOnDeletedCompanyPilverMutation__
 *
 * To run a mutation, you first call `useSetContactOnDeletedCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetContactOnDeletedCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetContactOnDeletedCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByContactsPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByContactsPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByContactsFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByContactsFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByContactsSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByContactsSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByContactsPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByContactsPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByContactsFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByContactsFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByContactsSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByContactsSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setContactOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetContactOnDeletedCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetContactOnDeletedCompanyPilverMutation,
        SetContactOnDeletedCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetContactOnDeletedCompanyPilverMutation,
          SetContactOnDeletedCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetContactOnDeletedCompanyPilverMutation,
    SetContactOnDeletedCompanyPilverMutationVariables
  >(SetContactOnDeletedCompanyPilverMutationDocument, options);
}
export type SetContactOnDeletedCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetContactOnDeletedCompanyPilverMutation,
    SetContactOnDeletedCompanyPilverMutationVariables
  >;
export const SetCreatedByOnDeletedCompanyPilverMutationDocument = gql`
  mutation setCreatedByOnDeletedCompanyPilverMutation(
    $input: SetCreatedByOnDeletedCompanyPilverInput!
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: ContactPilverFilter
    $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesFilter: ContactPilverFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactPilverFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedByOnDeletedCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging
            filter: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter
            sorting: $setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setCreatedByOnDeletedCompanyPilverHistoriesPaging
        filter: $setCreatedByOnDeletedCompanyPilverHistoriesFilter
        sorting: $setCreatedByOnDeletedCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging
          filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter
          sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging
          filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter
          sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsPaging
          filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsFilter
          sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging
          filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter
          sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging
          filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter
          sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging
          filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter
          sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging
          filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedByOnDeletedCompanyPilverMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnDeletedCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnDeletedCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnDeletedCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnDeletedCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnDeletedCompanyPilverMutation,
        SetCreatedByOnDeletedCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnDeletedCompanyPilverMutation,
          SetCreatedByOnDeletedCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnDeletedCompanyPilverMutation,
    SetCreatedByOnDeletedCompanyPilverMutationVariables
  >(SetCreatedByOnDeletedCompanyPilverMutationDocument, options);
}
export type SetCreatedByOnDeletedCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnDeletedCompanyPilverMutation,
    SetCreatedByOnDeletedCompanyPilverMutationVariables
  >;
export const AddHistoriesToDeletedCompanyPilverMutationDocument = gql`
  mutation addHistoriesToDeletedCompanyPilverMutation(
    $input: AddHistoriesToDeletedCompanyPilverInput!
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: ContactPilverFilter
    $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesFilter: ContactPilverFilter
    $addHistoriesToDeletedCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactPilverFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addHistoriesToDeletedCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging
            filter: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter
            sorting: $addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsPaging
          filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $addHistoriesToDeletedCompanyPilverHistoriesPaging
        filter: $addHistoriesToDeletedCompanyPilverHistoriesFilter
        sorting: $addHistoriesToDeletedCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging
          filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter
          sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging
          filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter
          sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsPaging
          filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsFilter
          sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging
          filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter
          sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging
          filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter
          sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging
          filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter
          sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging
          filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAddHistoriesToDeletedCompanyPilverMutation__
 *
 * To run a mutation, you first call `useAddHistoriesToDeletedCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddHistoriesToDeletedCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddHistoriesToDeletedCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addHistoriesToDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddHistoriesToDeletedCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddHistoriesToDeletedCompanyPilverMutation,
        AddHistoriesToDeletedCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddHistoriesToDeletedCompanyPilverMutation,
          AddHistoriesToDeletedCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddHistoriesToDeletedCompanyPilverMutation,
    AddHistoriesToDeletedCompanyPilverMutationVariables
  >(AddHistoriesToDeletedCompanyPilverMutationDocument, options);
}
export type AddHistoriesToDeletedCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddHistoriesToDeletedCompanyPilverMutation,
    AddHistoriesToDeletedCompanyPilverMutationVariables
  >;
export const SetHistoriesOnDeletedCompanyPilverMutationDocument = gql`
  mutation setHistoriesOnDeletedCompanyPilverMutation(
    $input: SetHistoriesOnDeletedCompanyPilverInput!
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: ContactPilverFilter
    $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesFilter: ContactPilverFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactPilverFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setHistoriesOnDeletedCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging
            filter: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter
            sorting: $setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsPaging
          filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $setHistoriesOnDeletedCompanyPilverHistoriesPaging
        filter: $setHistoriesOnDeletedCompanyPilverHistoriesFilter
        sorting: $setHistoriesOnDeletedCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging
          filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter
          sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging
          filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter
          sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsPaging
          filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsFilter
          sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging
          filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter
          sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging
          filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter
          sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging
          filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter
          sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging
          filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetHistoriesOnDeletedCompanyPilverMutation__
 *
 * To run a mutation, you first call `useSetHistoriesOnDeletedCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetHistoriesOnDeletedCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetHistoriesOnDeletedCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setHistoriesOnDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetHistoriesOnDeletedCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetHistoriesOnDeletedCompanyPilverMutation,
        SetHistoriesOnDeletedCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetHistoriesOnDeletedCompanyPilverMutation,
          SetHistoriesOnDeletedCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetHistoriesOnDeletedCompanyPilverMutation,
    SetHistoriesOnDeletedCompanyPilverMutationVariables
  >(SetHistoriesOnDeletedCompanyPilverMutationDocument, options);
}
export type SetHistoriesOnDeletedCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetHistoriesOnDeletedCompanyPilverMutation,
    SetHistoriesOnDeletedCompanyPilverMutationVariables
  >;
export const RemoveContactFromDeletedCompanyPilverMutationDocument = gql`
  mutation removeContactFromDeletedCompanyPilverMutation(
    $input: RemoveContactFromDeletedCompanyPilverInput!
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: ContactPilverFilter
    $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromDeletedCompanyPilverHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesFilter: ContactPilverFilter
    $removeContactFromDeletedCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactPilverFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeContactFromDeletedCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging
            filter: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter
            sorting: $removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsPaging
          filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeContactFromDeletedCompanyPilverHistoriesPaging
        filter: $removeContactFromDeletedCompanyPilverHistoriesFilter
        sorting: $removeContactFromDeletedCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging
          filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter
          sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging
          filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter
          sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsPaging
          filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsFilter
          sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging
          filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter
          sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging
          filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter
          sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging
          filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter
          sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging
          filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveContactFromDeletedCompanyPilverMutation__
 *
 * To run a mutation, you first call `useRemoveContactFromDeletedCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactFromDeletedCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveContactFromDeletedCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeContactFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveContactFromDeletedCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveContactFromDeletedCompanyPilverMutation,
        RemoveContactFromDeletedCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveContactFromDeletedCompanyPilverMutation,
          RemoveContactFromDeletedCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveContactFromDeletedCompanyPilverMutation,
    RemoveContactFromDeletedCompanyPilverMutationVariables
  >(RemoveContactFromDeletedCompanyPilverMutationDocument, options);
}
export type RemoveContactFromDeletedCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveContactFromDeletedCompanyPilverMutation,
    RemoveContactFromDeletedCompanyPilverMutationVariables
  >;
export const RemoveCreatedByFromDeletedCompanyPilverMutationDocument = gql`
  mutation removeCreatedByFromDeletedCompanyPilverMutation(
    $input: RemoveCreatedByFromDeletedCompanyPilverInput!
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: ContactPilverFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesFilter: ContactPilverFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactPilverFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedByFromDeletedCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging
            filter: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter
            sorting: $removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeCreatedByFromDeletedCompanyPilverHistoriesPaging
        filter: $removeCreatedByFromDeletedCompanyPilverHistoriesFilter
        sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging
          filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging
          filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsPaging
          filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging
          filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging
          filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging
          filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromDeletedCompanyPilverMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromDeletedCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromDeletedCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromDeletedCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromDeletedCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromDeletedCompanyPilverMutation,
        RemoveCreatedByFromDeletedCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromDeletedCompanyPilverMutation,
          RemoveCreatedByFromDeletedCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromDeletedCompanyPilverMutation,
    RemoveCreatedByFromDeletedCompanyPilverMutationVariables
  >(RemoveCreatedByFromDeletedCompanyPilverMutationDocument, options);
}
export type RemoveCreatedByFromDeletedCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromDeletedCompanyPilverMutation,
    RemoveCreatedByFromDeletedCompanyPilverMutationVariables
  >;
export const RemoveHistoriesFromDeletedCompanyPilverMutationDocument = gql`
  mutation removeHistoriesFromDeletedCompanyPilverMutation(
    $input: RemoveHistoriesFromDeletedCompanyPilverInput!
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: ContactPilverFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesFilter: ContactPilverFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesSorting: [ContactPilverSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: ContactPilverFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: [ContactPilverSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeHistoriesFromDeletedCompanyPilver(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Webpage
      Address
      Focus
      Language
      contact {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        ContactPerson
        Email
        Phone
        Description
        Interest
        company {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Name
          Webpage
          Address
          Focus
          Language
          createdBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
              filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
              filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging
              filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging
              filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
              filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging
              filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
              filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
          histories(
            paging: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging
            filter: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter
            sorting: $removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
        contactedBy {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          Username
          Email
          Status
          Role
          createdCompanies(
            paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging
            filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter
            sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              contact {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
              histories(
                paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
              }
            }
          }
          createdProjects(
            paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging
            filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter
            sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Address
              Type
              Language
              Manager
              ManagerContact
              projectChange {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
              histories(
                paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
              }
            }
          }
          contacts(
            paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging
            filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter
            sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          projectChanges(
            paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging
            filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter
            sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
              company {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                histories(
                  paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                  filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                }
              }
            }
          }
          createdWorkers(
            paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging
            filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter
            sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              Profession
              Type
              attachments(
                filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdContracts(
            paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging
            filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter
            sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Description
              Type
              Year
              attachments(
                filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
          createdMiddlemans(
            paging: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging
            filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter
            sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            nodes {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Phone
              Email
              Note
              attachments(
                filter: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
              ) {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
              }
            }
          }
        }
      }
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging
          filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsPaging
          filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging
          filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging
          filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging
          filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      histories(
        paging: $removeHistoriesFromDeletedCompanyPilverHistoriesPaging
        filter: $removeHistoriesFromDeletedCompanyPilverHistoriesFilter
        sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesSorting
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        nodes {
          Id
          CreatedAt
          UpdatedAt
          DeletedAt
          ContactPerson
          Email
          Phone
          Description
          Interest
          company {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            createdBy {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Username
              Email
              Status
              Role
              createdCompanies(
                paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging
                filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  contact {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                  histories(
                    paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                    filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                    sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      ContactPerson
                      Email
                      Phone
                      Description
                      Interest
                    }
                  }
                }
              }
              createdProjects(
                paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging
                filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Address
                  Type
                  Language
                  Manager
                  ManagerContact
                  projectChange {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                  histories(
                    paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                    filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                    sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    nodes {
                      Id
                      CreatedAt
                      UpdatedAt
                      DeletedAt
                      Description
                      State
                    }
                  }
                }
              }
              contacts(
                paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging
                filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                      filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              projectChanges(
                paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging
                filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                  company {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                    Webpage
                    Address
                    Focus
                    Language
                    histories(
                      paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                      filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                      sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                    ) {
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                      }
                    }
                  }
                }
              }
              createdWorkers(
                paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging
                filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  Profession
                  Type
                  attachments(
                    filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                    sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdContracts(
                paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging
                filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Description
                  Type
                  Year
                  attachments(
                    filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                    sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
              createdMiddlemans(
                paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging
                filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                nodes {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Phone
                  Email
                  Note
                  attachments(
                    filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                    sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                  ) {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Name
                  }
                }
              }
            }
            histories(
              paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging
              filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
          contactedBy {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Username
            Email
            Status
            Role
            createdCompanies(
              paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging
              filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Webpage
                Address
                Focus
                Language
                contact {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  ContactPerson
                  Email
                  Phone
                  Description
                  Interest
                }
                histories(
                  paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    ContactPerson
                    Email
                    Phone
                    Description
                    Interest
                  }
                }
              }
            }
            createdProjects(
              paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging
              filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Address
                Type
                Language
                Manager
                ManagerContact
                projectChange {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Description
                  State
                }
                histories(
                  paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
                  filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  nodes {
                    Id
                    CreatedAt
                    UpdatedAt
                    DeletedAt
                    Description
                    State
                  }
                }
              }
            }
            contacts(
              paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging
              filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            projectChanges(
              paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging
              filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
                company {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                  Webpage
                  Address
                  Focus
                  Language
                  histories(
                    paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                    filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                    sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
                  ) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                  }
                }
              }
            }
            createdWorkers(
              paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging
              filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                Profession
                Type
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdContracts(
              paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging
              filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Description
                Type
                Year
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
            createdMiddlemans(
              paging: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging
              filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Name
                Phone
                Email
                Note
                attachments(
                  filter: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
                  sorting: $removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
                ) {
                  Id
                  CreatedAt
                  UpdatedAt
                  DeletedAt
                  Name
                }
              }
            }
          }
        }
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging
          filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging
          filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsPaging
          filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging
          filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging
          filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging
          filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging
          filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveHistoriesFromDeletedCompanyPilverMutation__
 *
 * To run a mutation, you first call `useRemoveHistoriesFromDeletedCompanyPilverMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHistoriesFromDeletedCompanyPilverMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveHistoriesFromDeletedCompanyPilverMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverContactContactContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverHistoriesHistoriesNodesNodesContactedByContactedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansPaging'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansSorting'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeHistoriesFromDeletedCompanyPilverDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveHistoriesFromDeletedCompanyPilverMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveHistoriesFromDeletedCompanyPilverMutation,
        RemoveHistoriesFromDeletedCompanyPilverMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveHistoriesFromDeletedCompanyPilverMutation,
          RemoveHistoriesFromDeletedCompanyPilverMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveHistoriesFromDeletedCompanyPilverMutation,
    RemoveHistoriesFromDeletedCompanyPilverMutationVariables
  >(RemoveHistoriesFromDeletedCompanyPilverMutationDocument, options);
}
export type RemoveHistoriesFromDeletedCompanyPilverMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveHistoriesFromDeletedCompanyPilverMutation,
    RemoveHistoriesFromDeletedCompanyPilverMutationVariables
  >;
export const SetCreatedByOnDeletedMiddlemanMutationDocument = gql`
  mutation setCreatedByOnDeletedMiddlemanMutation(
    $input: SetCreatedByOnDeletedMiddlemanInput!
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedByOnDeletedMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $setCreatedByOnDeletedMiddlemanAttachmentsFilter
        sorting: $setCreatedByOnDeletedMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging
          filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter
          sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging
          filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter
          sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsPaging
          filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsFilter
          sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesPaging
          filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesFilter
          sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging
          filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter
          sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging
          filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter
          sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging
          filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedByOnDeletedMiddlemanMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnDeletedMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnDeletedMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnDeletedMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedMiddlemanAttachmentsFilter: // value for 'setCreatedByOnDeletedMiddlemanAttachmentsFilter'
 *     setCreatedByOnDeletedMiddlemanAttachmentsSorting: // value for 'setCreatedByOnDeletedMiddlemanAttachmentsSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnDeletedMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnDeletedMiddlemanMutation,
        SetCreatedByOnDeletedMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnDeletedMiddlemanMutation,
          SetCreatedByOnDeletedMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnDeletedMiddlemanMutation,
    SetCreatedByOnDeletedMiddlemanMutationVariables
  >(SetCreatedByOnDeletedMiddlemanMutationDocument, options);
}
export type SetCreatedByOnDeletedMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnDeletedMiddlemanMutation,
    SetCreatedByOnDeletedMiddlemanMutationVariables
  >;
export const AddAttachmentsToDeletedMiddlemanMutationDocument = gql`
  mutation addAttachmentsToDeletedMiddlemanMutation(
    $input: AddAttachmentsToDeletedMiddlemanInput!
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addAttachmentsToDeletedMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $addAttachmentsToDeletedMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsFilter: ContactFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsSorting: [ContactSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesFilter: ContactFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addAttachmentsToDeletedMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsPaging
          filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsFilter
          sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $addAttachmentsToDeletedMiddlemanAttachmentsFilter
        sorting: $addAttachmentsToDeletedMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging
          filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter
          sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging
          filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter
          sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsPaging
          filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsFilter
          sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesPaging
          filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesFilter
          sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging
          filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter
          sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging
          filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter
          sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging
          filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAddAttachmentsToDeletedMiddlemanMutation__
 *
 * To run a mutation, you first call `useAddAttachmentsToDeletedMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentsToDeletedMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddAttachmentsToDeletedMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addAttachmentsToDeletedMiddlemanAttachmentsFilter: // value for 'addAttachmentsToDeletedMiddlemanAttachmentsFilter'
 *     addAttachmentsToDeletedMiddlemanAttachmentsSorting: // value for 'addAttachmentsToDeletedMiddlemanAttachmentsSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddAttachmentsToDeletedMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddAttachmentsToDeletedMiddlemanMutation,
        AddAttachmentsToDeletedMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddAttachmentsToDeletedMiddlemanMutation,
          AddAttachmentsToDeletedMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddAttachmentsToDeletedMiddlemanMutation,
    AddAttachmentsToDeletedMiddlemanMutationVariables
  >(AddAttachmentsToDeletedMiddlemanMutationDocument, options);
}
export type AddAttachmentsToDeletedMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddAttachmentsToDeletedMiddlemanMutation,
    AddAttachmentsToDeletedMiddlemanMutationVariables
  >;
export const SetAttachmentsOnDeletedMiddlemanMutationDocument = gql`
  mutation setAttachmentsOnDeletedMiddlemanMutation(
    $input: SetAttachmentsOnDeletedMiddlemanInput!
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setAttachmentsOnDeletedMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $setAttachmentsOnDeletedMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsFilter: ContactFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsSorting: [ContactSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesFilter: ContactFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setAttachmentsOnDeletedMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsPaging
          filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsFilter
          sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $setAttachmentsOnDeletedMiddlemanAttachmentsFilter
        sorting: $setAttachmentsOnDeletedMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging
          filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter
          sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging
          filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter
          sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsPaging
          filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsFilter
          sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesPaging
          filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesFilter
          sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging
          filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter
          sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging
          filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter
          sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging
          filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetAttachmentsOnDeletedMiddlemanMutation__
 *
 * To run a mutation, you first call `useSetAttachmentsOnDeletedMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetAttachmentsOnDeletedMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetAttachmentsOnDeletedMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setAttachmentsOnDeletedMiddlemanAttachmentsFilter: // value for 'setAttachmentsOnDeletedMiddlemanAttachmentsFilter'
 *     setAttachmentsOnDeletedMiddlemanAttachmentsSorting: // value for 'setAttachmentsOnDeletedMiddlemanAttachmentsSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetAttachmentsOnDeletedMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetAttachmentsOnDeletedMiddlemanMutation,
        SetAttachmentsOnDeletedMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetAttachmentsOnDeletedMiddlemanMutation,
          SetAttachmentsOnDeletedMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetAttachmentsOnDeletedMiddlemanMutation,
    SetAttachmentsOnDeletedMiddlemanMutationVariables
  >(SetAttachmentsOnDeletedMiddlemanMutationDocument, options);
}
export type SetAttachmentsOnDeletedMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetAttachmentsOnDeletedMiddlemanMutation,
    SetAttachmentsOnDeletedMiddlemanMutationVariables
  >;
export const RemoveCreatedByFromDeletedMiddlemanMutationDocument = gql`
  mutation removeCreatedByFromDeletedMiddlemanMutation(
    $input: RemoveCreatedByFromDeletedMiddlemanInput!
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedByFromDeletedMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $removeCreatedByFromDeletedMiddlemanAttachmentsFilter
        sorting: $removeCreatedByFromDeletedMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging
          filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging
          filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter
          sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsPaging
          filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsFilter
          sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesPaging
          filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesFilter
          sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging
          filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter
          sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging
          filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter
          sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromDeletedMiddlemanMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromDeletedMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromDeletedMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromDeletedMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedMiddlemanAttachmentsFilter: // value for 'removeCreatedByFromDeletedMiddlemanAttachmentsFilter'
 *     removeCreatedByFromDeletedMiddlemanAttachmentsSorting: // value for 'removeCreatedByFromDeletedMiddlemanAttachmentsSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromDeletedMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromDeletedMiddlemanMutation,
        RemoveCreatedByFromDeletedMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromDeletedMiddlemanMutation,
          RemoveCreatedByFromDeletedMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromDeletedMiddlemanMutation,
    RemoveCreatedByFromDeletedMiddlemanMutationVariables
  >(RemoveCreatedByFromDeletedMiddlemanMutationDocument, options);
}
export type RemoveCreatedByFromDeletedMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromDeletedMiddlemanMutation,
    RemoveCreatedByFromDeletedMiddlemanMutationVariables
  >;
export const RemoveAttachmentsFromDeletedMiddlemanMutationDocument = gql`
  mutation removeAttachmentsFromDeletedMiddlemanMutation(
    $input: RemoveAttachmentsFromDeletedMiddlemanInput!
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeAttachmentsFromDeletedMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $removeAttachmentsFromDeletedMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsFilter: ContactFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsSorting: [ContactSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesFilter: ContactFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeAttachmentsFromDeletedMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsPaging
          filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $removeAttachmentsFromDeletedMiddlemanAttachmentsFilter
        sorting: $removeAttachmentsFromDeletedMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging
          filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging
          filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsPaging
          filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesPaging
          filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging
          filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging
          filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging
          filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveAttachmentsFromDeletedMiddlemanMutation__
 *
 * To run a mutation, you first call `useRemoveAttachmentsFromDeletedMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttachmentsFromDeletedMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveAttachmentsFromDeletedMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromDeletedMiddlemanAttachmentsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanAttachmentsFilter'
 *     removeAttachmentsFromDeletedMiddlemanAttachmentsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanAttachmentsSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansPaging'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansSorting'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedMiddlemanDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveAttachmentsFromDeletedMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveAttachmentsFromDeletedMiddlemanMutation,
        RemoveAttachmentsFromDeletedMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveAttachmentsFromDeletedMiddlemanMutation,
          RemoveAttachmentsFromDeletedMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveAttachmentsFromDeletedMiddlemanMutation,
    RemoveAttachmentsFromDeletedMiddlemanMutationVariables
  >(RemoveAttachmentsFromDeletedMiddlemanMutationDocument, options);
}
export type RemoveAttachmentsFromDeletedMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveAttachmentsFromDeletedMiddlemanMutation,
    RemoveAttachmentsFromDeletedMiddlemanMutationVariables
  >;
export const SetCreatedByOnDeletedWorkerMutationDocument = gql`
  mutation setCreatedByOnDeletedWorkerMutation(
    $input: SetCreatedByOnDeletedWorkerInput!
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnDeletedWorkerAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedWorkerAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsFilter: ContactFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsSorting: [ContactSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesFilter: ContactFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedByOnDeletedWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $setCreatedByOnDeletedWorkerAttachmentsFilter
        sorting: $setCreatedByOnDeletedWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging
          filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter
          sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsPaging
          filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsFilter
          sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsPaging
          filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsFilter
          sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesPaging
          filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesFilter
          sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersPaging
          filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersFilter
          sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsPaging
          filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsFilter
          sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging
          filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetCreatedByOnDeletedWorkerMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnDeletedWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnDeletedWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnDeletedWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByContactsPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByContactsFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByContactsSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedWorkerAttachmentsFilter: // value for 'setCreatedByOnDeletedWorkerAttachmentsFilter'
 *     setCreatedByOnDeletedWorkerAttachmentsSorting: // value for 'setCreatedByOnDeletedWorkerAttachmentsSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByContactsPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByContactsPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByContactsFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByContactsFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByContactsSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByContactsSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnDeletedWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnDeletedWorkerMutation,
        SetCreatedByOnDeletedWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnDeletedWorkerMutation,
          SetCreatedByOnDeletedWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnDeletedWorkerMutation,
    SetCreatedByOnDeletedWorkerMutationVariables
  >(SetCreatedByOnDeletedWorkerMutationDocument, options);
}
export type SetCreatedByOnDeletedWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnDeletedWorkerMutation,
    SetCreatedByOnDeletedWorkerMutationVariables
  >;
export const AddAttachmentsToDeletedWorkerMutationDocument = gql`
  mutation addAttachmentsToDeletedWorkerMutation(
    $input: AddAttachmentsToDeletedWorkerInput!
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addAttachmentsToDeletedWorkerAttachmentsFilter: AttachmentFilter
    $addAttachmentsToDeletedWorkerAttachmentsSorting: [AttachmentSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsFilter: ContactFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsSorting: [ContactSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesFilter: ContactFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addAttachmentsToDeletedWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsPaging
          filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsFilter
          sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesPaging
          filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $addAttachmentsToDeletedWorkerAttachmentsFilter
        sorting: $addAttachmentsToDeletedWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging
          filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter
          sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsPaging
          filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsFilter
          sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsPaging
          filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsFilter
          sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesPaging
          filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesFilter
          sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersPaging
          filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersFilter
          sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsPaging
          filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsFilter
          sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging
          filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAddAttachmentsToDeletedWorkerMutation__
 *
 * To run a mutation, you first call `useAddAttachmentsToDeletedWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentsToDeletedWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddAttachmentsToDeletedWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByContactsPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByContactsPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByContactsFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByContactsFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByContactsSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByContactsSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addAttachmentsToDeletedWorkerAttachmentsFilter: // value for 'addAttachmentsToDeletedWorkerAttachmentsFilter'
 *     addAttachmentsToDeletedWorkerAttachmentsSorting: // value for 'addAttachmentsToDeletedWorkerAttachmentsSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByContactsPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByContactsPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByContactsFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByContactsFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByContactsSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByContactsSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addAttachmentsToDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useAddAttachmentsToDeletedWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddAttachmentsToDeletedWorkerMutation,
        AddAttachmentsToDeletedWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddAttachmentsToDeletedWorkerMutation,
          AddAttachmentsToDeletedWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddAttachmentsToDeletedWorkerMutation,
    AddAttachmentsToDeletedWorkerMutationVariables
  >(AddAttachmentsToDeletedWorkerMutationDocument, options);
}
export type AddAttachmentsToDeletedWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddAttachmentsToDeletedWorkerMutation,
    AddAttachmentsToDeletedWorkerMutationVariables
  >;
export const SetAttachmentsOnDeletedWorkerMutationDocument = gql`
  mutation setAttachmentsOnDeletedWorkerMutation(
    $input: SetAttachmentsOnDeletedWorkerInput!
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setAttachmentsOnDeletedWorkerAttachmentsFilter: AttachmentFilter
    $setAttachmentsOnDeletedWorkerAttachmentsSorting: [AttachmentSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsFilter: ContactFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsSorting: [ContactSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesFilter: ContactFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setAttachmentsOnDeletedWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsPaging
          filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsFilter
          sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesPaging
          filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $setAttachmentsOnDeletedWorkerAttachmentsFilter
        sorting: $setAttachmentsOnDeletedWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging
          filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter
          sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsPaging
          filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsFilter
          sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsPaging
          filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsFilter
          sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesPaging
          filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesFilter
          sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersPaging
          filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersFilter
          sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsPaging
          filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsFilter
          sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging
          filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSetAttachmentsOnDeletedWorkerMutation__
 *
 * To run a mutation, you first call `useSetAttachmentsOnDeletedWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetAttachmentsOnDeletedWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetAttachmentsOnDeletedWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setAttachmentsOnDeletedWorkerAttachmentsFilter: // value for 'setAttachmentsOnDeletedWorkerAttachmentsFilter'
 *     setAttachmentsOnDeletedWorkerAttachmentsSorting: // value for 'setAttachmentsOnDeletedWorkerAttachmentsSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useSetAttachmentsOnDeletedWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetAttachmentsOnDeletedWorkerMutation,
        SetAttachmentsOnDeletedWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetAttachmentsOnDeletedWorkerMutation,
          SetAttachmentsOnDeletedWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetAttachmentsOnDeletedWorkerMutation,
    SetAttachmentsOnDeletedWorkerMutationVariables
  >(SetAttachmentsOnDeletedWorkerMutationDocument, options);
}
export type SetAttachmentsOnDeletedWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetAttachmentsOnDeletedWorkerMutation,
    SetAttachmentsOnDeletedWorkerMutationVariables
  >;
export const RemoveCreatedByFromDeletedWorkerMutationDocument = gql`
  mutation removeCreatedByFromDeletedWorkerMutation(
    $input: RemoveCreatedByFromDeletedWorkerInput!
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromDeletedWorkerAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedWorkerAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsFilter: ContactFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsSorting: [ContactSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedByFromDeletedWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $removeCreatedByFromDeletedWorkerAttachmentsFilter
        sorting: $removeCreatedByFromDeletedWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging
          filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsPaging
          filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsFilter
          sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsPaging
          filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsFilter
          sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesPaging
          filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesFilter
          sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersPaging
          filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersFilter
          sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsPaging
          filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsFilter
          sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromDeletedWorkerMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromDeletedWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromDeletedWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromDeletedWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedWorkerAttachmentsFilter: // value for 'removeCreatedByFromDeletedWorkerAttachmentsFilter'
 *     removeCreatedByFromDeletedWorkerAttachmentsSorting: // value for 'removeCreatedByFromDeletedWorkerAttachmentsSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromDeletedWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromDeletedWorkerMutation,
        RemoveCreatedByFromDeletedWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromDeletedWorkerMutation,
          RemoveCreatedByFromDeletedWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromDeletedWorkerMutation,
    RemoveCreatedByFromDeletedWorkerMutationVariables
  >(RemoveCreatedByFromDeletedWorkerMutationDocument, options);
}
export type RemoveCreatedByFromDeletedWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromDeletedWorkerMutation,
    RemoveCreatedByFromDeletedWorkerMutationVariables
  >;
export const RemoveAttachmentsFromDeletedWorkerMutationDocument = gql`
  mutation removeAttachmentsFromDeletedWorkerMutation(
    $input: RemoveAttachmentsFromDeletedWorkerInput!
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsFilter: ContactFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeAttachmentsFromDeletedWorkerAttachmentsFilter: AttachmentFilter
    $removeAttachmentsFromDeletedWorkerAttachmentsSorting: [AttachmentSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter: CompanyFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting: [CompanySort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsFilter: ProjectFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsSorting: [ProjectSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsFilter: ContactFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsSorting: [ContactSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesFilter: ContactFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersFilter: WorkerFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersSorting: [WorkerSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsFilter: ContractFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsSorting: [ContractSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: OffsetPaging
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeAttachmentsFromDeletedWorker(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      Profession
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsPaging
          filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsPaging
          filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsFilter
          sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesPaging
          filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesFilter
          sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersPaging
          filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsPaging
          filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsFilter
          sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $removeAttachmentsFromDeletedWorkerAttachmentsFilter
        sorting: $removeAttachmentsFromDeletedWorkerAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
      deletedBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging
          filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter
          sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsPaging
          filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsFilter
          sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsPaging
          filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsFilter
          sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesPaging
          filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesFilter
          sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersPaging
          filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersFilter
          sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsPaging
          filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsFilter
          sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging
          filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter
          sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveAttachmentsFromDeletedWorkerMutation__
 *
 * To run a mutation, you first call `useRemoveAttachmentsFromDeletedWorkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttachmentsFromDeletedWorkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveAttachmentsFromDeletedWorkerMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedWorkerCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromDeletedWorkerAttachmentsFilter: // value for 'removeAttachmentsFromDeletedWorkerAttachmentsFilter'
 *     removeAttachmentsFromDeletedWorkerAttachmentsSorting: // value for 'removeAttachmentsFromDeletedWorkerAttachmentsSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansPaging'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansSorting'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromDeletedWorkerDeletedByDeletedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveAttachmentsFromDeletedWorkerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveAttachmentsFromDeletedWorkerMutation,
        RemoveAttachmentsFromDeletedWorkerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveAttachmentsFromDeletedWorkerMutation,
          RemoveAttachmentsFromDeletedWorkerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveAttachmentsFromDeletedWorkerMutation,
    RemoveAttachmentsFromDeletedWorkerMutationVariables
  >(RemoveAttachmentsFromDeletedWorkerMutationDocument, options);
}
export type RemoveAttachmentsFromDeletedWorkerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveAttachmentsFromDeletedWorkerMutation,
    RemoveAttachmentsFromDeletedWorkerMutationVariables
  >;
export const SetCreatedByOnMiddlemanMutationDocument = gql`
  mutation setCreatedByOnMiddlemanMutation(
    $input: SetCreatedByOnMiddlemanInput!
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setCreatedByOnMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnMiddlemanCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnMiddlemanCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      attachments(
        filter: $setCreatedByOnMiddlemanAttachmentsFilter
        sorting: $setCreatedByOnMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useSetCreatedByOnMiddlemanMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByContactsPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByContactsFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByContactsSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnMiddlemanAttachmentsFilter: // value for 'setCreatedByOnMiddlemanAttachmentsFilter'
 *     setCreatedByOnMiddlemanAttachmentsSorting: // value for 'setCreatedByOnMiddlemanAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnMiddlemanMutation,
        SetCreatedByOnMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnMiddlemanMutation,
          SetCreatedByOnMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnMiddlemanMutation,
    SetCreatedByOnMiddlemanMutationVariables
  >(SetCreatedByOnMiddlemanMutationDocument, options);
}
export type SetCreatedByOnMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnMiddlemanMutation,
    SetCreatedByOnMiddlemanMutationVariables
  >;
export const AddAttachmentsToMiddlemanMutationDocument = gql`
  mutation addAttachmentsToMiddlemanMutation(
    $input: AddAttachmentsToMiddlemanInput!
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addAttachmentsToMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $addAttachmentsToMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    addAttachmentsToMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addAttachmentsToMiddlemanCreatedByCreatedByContactsPaging
          filter: $addAttachmentsToMiddlemanCreatedByCreatedByContactsFilter
          sorting: $addAttachmentsToMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      attachments(
        filter: $addAttachmentsToMiddlemanAttachmentsFilter
        sorting: $addAttachmentsToMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useAddAttachmentsToMiddlemanMutation__
 *
 * To run a mutation, you first call `useAddAttachmentsToMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentsToMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddAttachmentsToMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByContactsPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByContactsPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByContactsFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByContactsFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByContactsSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByContactsSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addAttachmentsToMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     addAttachmentsToMiddlemanAttachmentsFilter: // value for 'addAttachmentsToMiddlemanAttachmentsFilter'
 *     addAttachmentsToMiddlemanAttachmentsSorting: // value for 'addAttachmentsToMiddlemanAttachmentsSorting'
 *   },
 * });
 */
export function useAddAttachmentsToMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddAttachmentsToMiddlemanMutation,
        AddAttachmentsToMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddAttachmentsToMiddlemanMutation,
          AddAttachmentsToMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddAttachmentsToMiddlemanMutation,
    AddAttachmentsToMiddlemanMutationVariables
  >(AddAttachmentsToMiddlemanMutationDocument, options);
}
export type AddAttachmentsToMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddAttachmentsToMiddlemanMutation,
    AddAttachmentsToMiddlemanMutationVariables
  >;
export const SetAttachmentsOnMiddlemanMutationDocument = gql`
  mutation setAttachmentsOnMiddlemanMutation(
    $input: SetAttachmentsOnMiddlemanInput!
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setAttachmentsOnMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $setAttachmentsOnMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    setAttachmentsOnMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setAttachmentsOnMiddlemanCreatedByCreatedByContactsPaging
          filter: $setAttachmentsOnMiddlemanCreatedByCreatedByContactsFilter
          sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      attachments(
        filter: $setAttachmentsOnMiddlemanAttachmentsFilter
        sorting: $setAttachmentsOnMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useSetAttachmentsOnMiddlemanMutation__
 *
 * To run a mutation, you first call `useSetAttachmentsOnMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetAttachmentsOnMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetAttachmentsOnMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByContactsPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByContactsPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByContactsFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByContactsFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByContactsSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByContactsSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setAttachmentsOnMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     setAttachmentsOnMiddlemanAttachmentsFilter: // value for 'setAttachmentsOnMiddlemanAttachmentsFilter'
 *     setAttachmentsOnMiddlemanAttachmentsSorting: // value for 'setAttachmentsOnMiddlemanAttachmentsSorting'
 *   },
 * });
 */
export function useSetAttachmentsOnMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetAttachmentsOnMiddlemanMutation,
        SetAttachmentsOnMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetAttachmentsOnMiddlemanMutation,
          SetAttachmentsOnMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetAttachmentsOnMiddlemanMutation,
    SetAttachmentsOnMiddlemanMutationVariables
  >(SetAttachmentsOnMiddlemanMutationDocument, options);
}
export type SetAttachmentsOnMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetAttachmentsOnMiddlemanMutation,
    SetAttachmentsOnMiddlemanMutationVariables
  >;
export const RemoveCreatedByFromMiddlemanMutationDocument = gql`
  mutation removeCreatedByFromMiddlemanMutation(
    $input: RemoveCreatedByFromMiddlemanInput!
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeCreatedByFromMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromMiddlemanCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromMiddlemanCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      attachments(
        filter: $removeCreatedByFromMiddlemanAttachmentsFilter
        sorting: $removeCreatedByFromMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromMiddlemanMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromMiddlemanAttachmentsFilter: // value for 'removeCreatedByFromMiddlemanAttachmentsFilter'
 *     removeCreatedByFromMiddlemanAttachmentsSorting: // value for 'removeCreatedByFromMiddlemanAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromMiddlemanMutation,
        RemoveCreatedByFromMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromMiddlemanMutation,
          RemoveCreatedByFromMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromMiddlemanMutation,
    RemoveCreatedByFromMiddlemanMutationVariables
  >(RemoveCreatedByFromMiddlemanMutationDocument, options);
}
export type RemoveCreatedByFromMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromMiddlemanMutation,
    RemoveCreatedByFromMiddlemanMutationVariables
  >;
export const RemoveAttachmentsFromMiddlemanMutationDocument = gql`
  mutation removeAttachmentsFromMiddlemanMutation(
    $input: RemoveAttachmentsFromMiddlemanInput!
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeAttachmentsFromMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $removeAttachmentsFromMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    removeAttachmentsFromMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsPaging
          filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsFilter
          sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      attachments(
        filter: $removeAttachmentsFromMiddlemanAttachmentsFilter
        sorting: $removeAttachmentsFromMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useRemoveAttachmentsFromMiddlemanMutation__
 *
 * To run a mutation, you first call `useRemoveAttachmentsFromMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttachmentsFromMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveAttachmentsFromMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByContactsPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByContactsPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByContactsFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByContactsFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByContactsSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByContactsSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeAttachmentsFromMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeAttachmentsFromMiddlemanAttachmentsFilter: // value for 'removeAttachmentsFromMiddlemanAttachmentsFilter'
 *     removeAttachmentsFromMiddlemanAttachmentsSorting: // value for 'removeAttachmentsFromMiddlemanAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveAttachmentsFromMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveAttachmentsFromMiddlemanMutation,
        RemoveAttachmentsFromMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveAttachmentsFromMiddlemanMutation,
          RemoveAttachmentsFromMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveAttachmentsFromMiddlemanMutation,
    RemoveAttachmentsFromMiddlemanMutationVariables
  >(RemoveAttachmentsFromMiddlemanMutationDocument, options);
}
export type RemoveAttachmentsFromMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveAttachmentsFromMiddlemanMutation,
    RemoveAttachmentsFromMiddlemanMutationVariables
  >;
export const CreateOneMiddlemanMutationDocument = gql`
  mutation createOneMiddlemanMutation(
    $input: CreateOneMiddlemanInput!
    $createOneMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createOneMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createOneMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $createOneMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createOneMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createOneMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createOneMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createOneMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createOneMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createOneMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $createOneMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createOneMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createOneMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $createOneMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $createOneMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createOneMiddlemanCreatedByCreatedByContactsPaging
          filter: $createOneMiddlemanCreatedByCreatedByContactsFilter
          sorting: $createOneMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createOneMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $createOneMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $createOneMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createOneMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $createOneMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $createOneMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createOneMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $createOneMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $createOneMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createOneMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createOneMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createOneMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      attachments(
        filter: $createOneMiddlemanAttachmentsFilter
        sorting: $createOneMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCreateOneMiddlemanMutation__
 *
 * To run a mutation, you first call `useCreateOneMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'createOneMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     createOneMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'createOneMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     createOneMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'createOneMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'createOneMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     createOneMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'createOneMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     createOneMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'createOneMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneMiddlemanCreatedByCreatedByContactsPaging: // value for 'createOneMiddlemanCreatedByCreatedByContactsPaging'
 *     createOneMiddlemanCreatedByCreatedByContactsFilter: // value for 'createOneMiddlemanCreatedByCreatedByContactsFilter'
 *     createOneMiddlemanCreatedByCreatedByContactsSorting: // value for 'createOneMiddlemanCreatedByCreatedByContactsSorting'
 *     createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'createOneMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     createOneMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'createOneMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     createOneMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'createOneMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'createOneMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     createOneMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'createOneMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     createOneMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'createOneMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     createOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'createOneMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     createOneMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'createOneMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     createOneMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'createOneMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     createOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createOneMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     createOneMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createOneMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     createOneMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createOneMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     createOneMiddlemanAttachmentsFilter: // value for 'createOneMiddlemanAttachmentsFilter'
 *     createOneMiddlemanAttachmentsSorting: // value for 'createOneMiddlemanAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneMiddlemanMutation,
        CreateOneMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneMiddlemanMutation,
          CreateOneMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneMiddlemanMutation,
    CreateOneMiddlemanMutationVariables
  >(CreateOneMiddlemanMutationDocument, options);
}
export type CreateOneMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneMiddlemanMutation,
    CreateOneMiddlemanMutationVariables
  >;
export const CreateManyMiddlemenMutationDocument = gql`
  mutation createManyMiddlemenMutation(
    $input: CreateManyMiddlemenInput!
    $createManyMiddlemenCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createManyMiddlemenCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyMiddlemenCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createManyMiddlemenCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyMiddlemenCreatedByCreatedByContactsPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByContactsFilter: ContactFilter
    $createManyMiddlemenCreatedByCreatedByContactsSorting: [ContactSort!]
    $createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyMiddlemenCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createManyMiddlemenCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyMiddlemenCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createManyMiddlemenCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createManyMiddlemenCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyMiddlemenCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyMiddlemenCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createManyMiddlemenCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createManyMiddlemenCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyMiddlemenCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyMiddlemenCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createManyMiddlemenCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyMiddlemenCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyMiddlemenAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyMiddlemenAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    createManyMiddlemen(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createManyMiddlemenCreatedByCreatedByCreatedCompaniesPaging
          filter: $createManyMiddlemenCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createManyMiddlemenCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createManyMiddlemenCreatedByCreatedByCreatedProjectsPaging
          filter: $createManyMiddlemenCreatedByCreatedByCreatedProjectsFilter
          sorting: $createManyMiddlemenCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createManyMiddlemenCreatedByCreatedByContactsPaging
          filter: $createManyMiddlemenCreatedByCreatedByContactsFilter
          sorting: $createManyMiddlemenCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createManyMiddlemenCreatedByCreatedByProjectChangesPaging
          filter: $createManyMiddlemenCreatedByCreatedByProjectChangesFilter
          sorting: $createManyMiddlemenCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createManyMiddlemenCreatedByCreatedByCreatedWorkersPaging
          filter: $createManyMiddlemenCreatedByCreatedByCreatedWorkersFilter
          sorting: $createManyMiddlemenCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createManyMiddlemenCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createManyMiddlemenCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createManyMiddlemenCreatedByCreatedByCreatedContractsPaging
          filter: $createManyMiddlemenCreatedByCreatedByCreatedContractsFilter
          sorting: $createManyMiddlemenCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createManyMiddlemenCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createManyMiddlemenCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createManyMiddlemenCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createManyMiddlemenCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createManyMiddlemenCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      attachments(
        filter: $createManyMiddlemenAttachmentsFilter
        sorting: $createManyMiddlemenAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCreateManyMiddlemenMutation__
 *
 * To run a mutation, you first call `useCreateManyMiddlemenMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyMiddlemenMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyMiddlemenMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyMiddlemenCreatedByCreatedByCreatedCompaniesPaging: // value for 'createManyMiddlemenCreatedByCreatedByCreatedCompaniesPaging'
 *     createManyMiddlemenCreatedByCreatedByCreatedCompaniesFilter: // value for 'createManyMiddlemenCreatedByCreatedByCreatedCompaniesFilter'
 *     createManyMiddlemenCreatedByCreatedByCreatedCompaniesSorting: // value for 'createManyMiddlemenCreatedByCreatedByCreatedCompaniesSorting'
 *     createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyMiddlemenCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyMiddlemenCreatedByCreatedByCreatedProjectsPaging: // value for 'createManyMiddlemenCreatedByCreatedByCreatedProjectsPaging'
 *     createManyMiddlemenCreatedByCreatedByCreatedProjectsFilter: // value for 'createManyMiddlemenCreatedByCreatedByCreatedProjectsFilter'
 *     createManyMiddlemenCreatedByCreatedByCreatedProjectsSorting: // value for 'createManyMiddlemenCreatedByCreatedByCreatedProjectsSorting'
 *     createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyMiddlemenCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyMiddlemenCreatedByCreatedByContactsPaging: // value for 'createManyMiddlemenCreatedByCreatedByContactsPaging'
 *     createManyMiddlemenCreatedByCreatedByContactsFilter: // value for 'createManyMiddlemenCreatedByCreatedByContactsFilter'
 *     createManyMiddlemenCreatedByCreatedByContactsSorting: // value for 'createManyMiddlemenCreatedByCreatedByContactsSorting'
 *     createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyMiddlemenCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyMiddlemenCreatedByCreatedByProjectChangesPaging: // value for 'createManyMiddlemenCreatedByCreatedByProjectChangesPaging'
 *     createManyMiddlemenCreatedByCreatedByProjectChangesFilter: // value for 'createManyMiddlemenCreatedByCreatedByProjectChangesFilter'
 *     createManyMiddlemenCreatedByCreatedByProjectChangesSorting: // value for 'createManyMiddlemenCreatedByCreatedByProjectChangesSorting'
 *     createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyMiddlemenCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyMiddlemenCreatedByCreatedByCreatedWorkersPaging: // value for 'createManyMiddlemenCreatedByCreatedByCreatedWorkersPaging'
 *     createManyMiddlemenCreatedByCreatedByCreatedWorkersFilter: // value for 'createManyMiddlemenCreatedByCreatedByCreatedWorkersFilter'
 *     createManyMiddlemenCreatedByCreatedByCreatedWorkersSorting: // value for 'createManyMiddlemenCreatedByCreatedByCreatedWorkersSorting'
 *     createManyMiddlemenCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyMiddlemenCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyMiddlemenCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyMiddlemenCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyMiddlemenCreatedByCreatedByCreatedContractsPaging: // value for 'createManyMiddlemenCreatedByCreatedByCreatedContractsPaging'
 *     createManyMiddlemenCreatedByCreatedByCreatedContractsFilter: // value for 'createManyMiddlemenCreatedByCreatedByCreatedContractsFilter'
 *     createManyMiddlemenCreatedByCreatedByCreatedContractsSorting: // value for 'createManyMiddlemenCreatedByCreatedByCreatedContractsSorting'
 *     createManyMiddlemenCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyMiddlemenCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyMiddlemenCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyMiddlemenCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyMiddlemenCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createManyMiddlemenCreatedByCreatedByCreatedMiddlemansPaging'
 *     createManyMiddlemenCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createManyMiddlemenCreatedByCreatedByCreatedMiddlemansFilter'
 *     createManyMiddlemenCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createManyMiddlemenCreatedByCreatedByCreatedMiddlemansSorting'
 *     createManyMiddlemenAttachmentsFilter: // value for 'createManyMiddlemenAttachmentsFilter'
 *     createManyMiddlemenAttachmentsSorting: // value for 'createManyMiddlemenAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyMiddlemenMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyMiddlemenMutation,
        CreateManyMiddlemenMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyMiddlemenMutation,
          CreateManyMiddlemenMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyMiddlemenMutation,
    CreateManyMiddlemenMutationVariables
  >(CreateManyMiddlemenMutationDocument, options);
}
export type CreateManyMiddlemenMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyMiddlemenMutation,
    CreateManyMiddlemenMutationVariables
  >;
export const UpdateOneMiddlemanMutationDocument = gql`
  mutation updateOneMiddlemanMutation(
    $input: UpdateOneMiddlemanInput!
    $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneMiddlemanCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $updateOneMiddlemanCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneMiddlemanCreatedByCreatedByContactsPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByContactsFilter: ContactFilter
    $updateOneMiddlemanCreatedByCreatedByContactsSorting: [ContactSort!]
    $updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneMiddlemanCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByProjectChangesFilter: ContactFilter
    $updateOneMiddlemanCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneMiddlemanCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $updateOneMiddlemanCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneMiddlemanCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $updateOneMiddlemanCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $updateOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneMiddlemanAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneMiddlemanAttachmentsSorting: [MiddlemanAttachmentSort!]
  ) {
    updateOneMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesPaging
          filter: $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesFilter
          sorting: $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $updateOneMiddlemanCreatedByCreatedByCreatedProjectsPaging
          filter: $updateOneMiddlemanCreatedByCreatedByCreatedProjectsFilter
          sorting: $updateOneMiddlemanCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $updateOneMiddlemanCreatedByCreatedByContactsPaging
          filter: $updateOneMiddlemanCreatedByCreatedByContactsFilter
          sorting: $updateOneMiddlemanCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $updateOneMiddlemanCreatedByCreatedByProjectChangesPaging
          filter: $updateOneMiddlemanCreatedByCreatedByProjectChangesFilter
          sorting: $updateOneMiddlemanCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $updateOneMiddlemanCreatedByCreatedByCreatedWorkersPaging
          filter: $updateOneMiddlemanCreatedByCreatedByCreatedWorkersFilter
          sorting: $updateOneMiddlemanCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $updateOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $updateOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $updateOneMiddlemanCreatedByCreatedByCreatedContractsPaging
          filter: $updateOneMiddlemanCreatedByCreatedByCreatedContractsFilter
          sorting: $updateOneMiddlemanCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $updateOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $updateOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansPaging
          filter: $updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      attachments(
        filter: $updateOneMiddlemanAttachmentsFilter
        sorting: $updateOneMiddlemanAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useUpdateOneMiddlemanMutation__
 *
 * To run a mutation, you first call `useUpdateOneMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneMiddlemanCreatedByCreatedByCreatedCompaniesPaging: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedCompaniesPaging'
 *     updateOneMiddlemanCreatedByCreatedByCreatedCompaniesFilter: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedCompaniesFilter'
 *     updateOneMiddlemanCreatedByCreatedByCreatedCompaniesSorting: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedCompaniesSorting'
 *     updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneMiddlemanCreatedByCreatedByCreatedProjectsPaging: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedProjectsPaging'
 *     updateOneMiddlemanCreatedByCreatedByCreatedProjectsFilter: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedProjectsFilter'
 *     updateOneMiddlemanCreatedByCreatedByCreatedProjectsSorting: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedProjectsSorting'
 *     updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneMiddlemanCreatedByCreatedByContactsPaging: // value for 'updateOneMiddlemanCreatedByCreatedByContactsPaging'
 *     updateOneMiddlemanCreatedByCreatedByContactsFilter: // value for 'updateOneMiddlemanCreatedByCreatedByContactsFilter'
 *     updateOneMiddlemanCreatedByCreatedByContactsSorting: // value for 'updateOneMiddlemanCreatedByCreatedByContactsSorting'
 *     updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneMiddlemanCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneMiddlemanCreatedByCreatedByProjectChangesPaging: // value for 'updateOneMiddlemanCreatedByCreatedByProjectChangesPaging'
 *     updateOneMiddlemanCreatedByCreatedByProjectChangesFilter: // value for 'updateOneMiddlemanCreatedByCreatedByProjectChangesFilter'
 *     updateOneMiddlemanCreatedByCreatedByProjectChangesSorting: // value for 'updateOneMiddlemanCreatedByCreatedByProjectChangesSorting'
 *     updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneMiddlemanCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneMiddlemanCreatedByCreatedByCreatedWorkersPaging: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedWorkersPaging'
 *     updateOneMiddlemanCreatedByCreatedByCreatedWorkersFilter: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedWorkersFilter'
 *     updateOneMiddlemanCreatedByCreatedByCreatedWorkersSorting: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedWorkersSorting'
 *     updateOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneMiddlemanCreatedByCreatedByCreatedContractsPaging: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedContractsPaging'
 *     updateOneMiddlemanCreatedByCreatedByCreatedContractsFilter: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedContractsFilter'
 *     updateOneMiddlemanCreatedByCreatedByCreatedContractsSorting: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedContractsSorting'
 *     updateOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansPaging: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansPaging'
 *     updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansFilter: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansFilter'
 *     updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansSorting: // value for 'updateOneMiddlemanCreatedByCreatedByCreatedMiddlemansSorting'
 *     updateOneMiddlemanAttachmentsFilter: // value for 'updateOneMiddlemanAttachmentsFilter'
 *     updateOneMiddlemanAttachmentsSorting: // value for 'updateOneMiddlemanAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneMiddlemanMutation,
        UpdateOneMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneMiddlemanMutation,
          UpdateOneMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneMiddlemanMutation,
    UpdateOneMiddlemanMutationVariables
  >(UpdateOneMiddlemanMutationDocument, options);
}
export type UpdateOneMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneMiddlemanMutation,
    UpdateOneMiddlemanMutationVariables
  >;
export const UpdateManyMiddlemenMutationDocument = gql`
  mutation updateManyMiddlemenMutation($input: UpdateManyMiddlemenInput!) {
    updateManyMiddlemen(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyMiddlemenMutation__
 *
 * To run a mutation, you first call `useUpdateManyMiddlemenMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyMiddlemenMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyMiddlemenMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyMiddlemenMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyMiddlemenMutation,
        UpdateManyMiddlemenMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyMiddlemenMutation,
          UpdateManyMiddlemenMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyMiddlemenMutation,
    UpdateManyMiddlemenMutationVariables
  >(UpdateManyMiddlemenMutationDocument, options);
}
export type UpdateManyMiddlemenMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyMiddlemenMutation,
    UpdateManyMiddlemenMutationVariables
  >;
export const DeleteOneMiddlemanMutationDocument = gql`
  mutation deleteOneMiddlemanMutation($input: DeleteOneMiddlemanInput!) {
    deleteOneMiddleman(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Phone
      Email
      Note
    }
  }
`;

/**
 * __useDeleteOneMiddlemanMutation__
 *
 * To run a mutation, you first call `useDeleteOneMiddlemanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneMiddlemanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneMiddlemanMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneMiddlemanMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneMiddlemanMutation,
        DeleteOneMiddlemanMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneMiddlemanMutation,
          DeleteOneMiddlemanMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneMiddlemanMutation,
    DeleteOneMiddlemanMutationVariables
  >(DeleteOneMiddlemanMutationDocument, options);
}
export type DeleteOneMiddlemanMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneMiddlemanMutation,
    DeleteOneMiddlemanMutationVariables
  >;
export const DeleteManyMiddlemenMutationDocument = gql`
  mutation deleteManyMiddlemenMutation($input: DeleteManyMiddlemenInput!) {
    deleteManyMiddlemen(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyMiddlemenMutation__
 *
 * To run a mutation, you first call `useDeleteManyMiddlemenMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyMiddlemenMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyMiddlemenMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyMiddlemenMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyMiddlemenMutation,
        DeleteManyMiddlemenMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyMiddlemenMutation,
          DeleteManyMiddlemenMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyMiddlemenMutation,
    DeleteManyMiddlemenMutationVariables
  >(DeleteManyMiddlemenMutationDocument, options);
}
export type DeleteManyMiddlemenMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyMiddlemenMutation,
    DeleteManyMiddlemenMutationVariables
  >;
export const CreateOneMiddlemanAttachmentMutationDocument = gql`
  mutation createOneMiddlemanAttachmentMutation(
    $input: CreateOneMiddlemanAttachmentInput!
  ) {
    createOneMiddlemanAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCreateOneMiddlemanAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateOneMiddlemanAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneMiddlemanAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneMiddlemanAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneMiddlemanAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneMiddlemanAttachmentMutation,
        CreateOneMiddlemanAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneMiddlemanAttachmentMutation,
          CreateOneMiddlemanAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneMiddlemanAttachmentMutation,
    CreateOneMiddlemanAttachmentMutationVariables
  >(CreateOneMiddlemanAttachmentMutationDocument, options);
}
export type CreateOneMiddlemanAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneMiddlemanAttachmentMutation,
    CreateOneMiddlemanAttachmentMutationVariables
  >;
export const CreateManyMiddlemanAttachmentsMutationDocument = gql`
  mutation createManyMiddlemanAttachmentsMutation(
    $input: CreateManyMiddlemanAttachmentsInput!
  ) {
    createManyMiddlemanAttachments(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCreateManyMiddlemanAttachmentsMutation__
 *
 * To run a mutation, you first call `useCreateManyMiddlemanAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyMiddlemanAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyMiddlemanAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyMiddlemanAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyMiddlemanAttachmentsMutation,
        CreateManyMiddlemanAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyMiddlemanAttachmentsMutation,
          CreateManyMiddlemanAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyMiddlemanAttachmentsMutation,
    CreateManyMiddlemanAttachmentsMutationVariables
  >(CreateManyMiddlemanAttachmentsMutationDocument, options);
}
export type CreateManyMiddlemanAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyMiddlemanAttachmentsMutation,
    CreateManyMiddlemanAttachmentsMutationVariables
  >;
export const UpdateOneMiddlemanAttachmentMutationDocument = gql`
  mutation updateOneMiddlemanAttachmentMutation(
    $input: UpdateOneMiddlemanAttachmentInput!
  ) {
    updateOneMiddlemanAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useUpdateOneMiddlemanAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateOneMiddlemanAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneMiddlemanAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneMiddlemanAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneMiddlemanAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneMiddlemanAttachmentMutation,
        UpdateOneMiddlemanAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneMiddlemanAttachmentMutation,
          UpdateOneMiddlemanAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneMiddlemanAttachmentMutation,
    UpdateOneMiddlemanAttachmentMutationVariables
  >(UpdateOneMiddlemanAttachmentMutationDocument, options);
}
export type UpdateOneMiddlemanAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneMiddlemanAttachmentMutation,
    UpdateOneMiddlemanAttachmentMutationVariables
  >;
export const UpdateManyMiddlemanAttachmentsMutationDocument = gql`
  mutation updateManyMiddlemanAttachmentsMutation(
    $input: UpdateManyMiddlemanAttachmentsInput!
  ) {
    updateManyMiddlemanAttachments(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyMiddlemanAttachmentsMutation__
 *
 * To run a mutation, you first call `useUpdateManyMiddlemanAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyMiddlemanAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyMiddlemanAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyMiddlemanAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyMiddlemanAttachmentsMutation,
        UpdateManyMiddlemanAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyMiddlemanAttachmentsMutation,
          UpdateManyMiddlemanAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyMiddlemanAttachmentsMutation,
    UpdateManyMiddlemanAttachmentsMutationVariables
  >(UpdateManyMiddlemanAttachmentsMutationDocument, options);
}
export type UpdateManyMiddlemanAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyMiddlemanAttachmentsMutation,
    UpdateManyMiddlemanAttachmentsMutationVariables
  >;
export const DeleteOneMiddlemanAttachmentMutationDocument = gql`
  mutation deleteOneMiddlemanAttachmentMutation(
    $input: DeleteOneMiddlemanAttachmentInput!
  ) {
    deleteOneMiddlemanAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useDeleteOneMiddlemanAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteOneMiddlemanAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneMiddlemanAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneMiddlemanAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneMiddlemanAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneMiddlemanAttachmentMutation,
        DeleteOneMiddlemanAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneMiddlemanAttachmentMutation,
          DeleteOneMiddlemanAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneMiddlemanAttachmentMutation,
    DeleteOneMiddlemanAttachmentMutationVariables
  >(DeleteOneMiddlemanAttachmentMutationDocument, options);
}
export type DeleteOneMiddlemanAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneMiddlemanAttachmentMutation,
    DeleteOneMiddlemanAttachmentMutationVariables
  >;
export const DeleteManyMiddlemanAttachmentsMutationDocument = gql`
  mutation deleteManyMiddlemanAttachmentsMutation(
    $input: DeleteManyMiddlemanAttachmentsInput!
  ) {
    deleteManyMiddlemanAttachments(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyMiddlemanAttachmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyMiddlemanAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyMiddlemanAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyMiddlemanAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyMiddlemanAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyMiddlemanAttachmentsMutation,
        DeleteManyMiddlemanAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyMiddlemanAttachmentsMutation,
          DeleteManyMiddlemanAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyMiddlemanAttachmentsMutation,
    DeleteManyMiddlemanAttachmentsMutationVariables
  >(DeleteManyMiddlemanAttachmentsMutationDocument, options);
}
export type DeleteManyMiddlemanAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyMiddlemanAttachmentsMutation,
    DeleteManyMiddlemanAttachmentsMutationVariables
  >;
export const SetCreatedByOnCarMutationDocument = gql`
  mutation setCreatedByOnCarMutation(
    $input: SetCreatedByOnCarInput!
    $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setCreatedByOnCarCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setCreatedByOnCarCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setCreatedByOnCarCreatedByCreatedByContactsPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByContactsFilter: ContactFilter
    $setCreatedByOnCarCreatedByCreatedByContactsSorting: [ContactSort!]
    $setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnCarCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setCreatedByOnCarCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setCreatedByOnCarCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setCreatedByOnCarCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setCreatedByOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setCreatedByOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setCreatedByOnCarCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setCreatedByOnCarCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setCreatedByOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setCreatedByOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setCreatedByOnCarAttachmentsFilter: CarAttachmentFilter
    $setCreatedByOnCarAttachmentsSorting: [CarAttachmentSort!]
  ) {
    setCreatedByOnCar(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Evidence
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesPaging
          filter: $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setCreatedByOnCarCreatedByCreatedByCreatedProjectsPaging
          filter: $setCreatedByOnCarCreatedByCreatedByCreatedProjectsFilter
          sorting: $setCreatedByOnCarCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setCreatedByOnCarCreatedByCreatedByContactsPaging
          filter: $setCreatedByOnCarCreatedByCreatedByContactsFilter
          sorting: $setCreatedByOnCarCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setCreatedByOnCarCreatedByCreatedByProjectChangesPaging
          filter: $setCreatedByOnCarCreatedByCreatedByProjectChangesFilter
          sorting: $setCreatedByOnCarCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setCreatedByOnCarCreatedByCreatedByCreatedWorkersPaging
          filter: $setCreatedByOnCarCreatedByCreatedByCreatedWorkersFilter
          sorting: $setCreatedByOnCarCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setCreatedByOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setCreatedByOnCarCreatedByCreatedByCreatedContractsPaging
          filter: $setCreatedByOnCarCreatedByCreatedByCreatedContractsFilter
          sorting: $setCreatedByOnCarCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setCreatedByOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $setCreatedByOnCarAttachmentsFilter
        sorting: $setCreatedByOnCarAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useSetCreatedByOnCarMutation__
 *
 * To run a mutation, you first call `useSetCreatedByOnCarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCreatedByOnCarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCreatedByOnCarMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setCreatedByOnCarCreatedByCreatedByCreatedCompaniesPaging: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedCompaniesPaging'
 *     setCreatedByOnCarCreatedByCreatedByCreatedCompaniesFilter: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedCompaniesFilter'
 *     setCreatedByOnCarCreatedByCreatedByCreatedCompaniesSorting: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedCompaniesSorting'
 *     setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setCreatedByOnCarCreatedByCreatedByCreatedProjectsPaging: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedProjectsPaging'
 *     setCreatedByOnCarCreatedByCreatedByCreatedProjectsFilter: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedProjectsFilter'
 *     setCreatedByOnCarCreatedByCreatedByCreatedProjectsSorting: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedProjectsSorting'
 *     setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setCreatedByOnCarCreatedByCreatedByContactsPaging: // value for 'setCreatedByOnCarCreatedByCreatedByContactsPaging'
 *     setCreatedByOnCarCreatedByCreatedByContactsFilter: // value for 'setCreatedByOnCarCreatedByCreatedByContactsFilter'
 *     setCreatedByOnCarCreatedByCreatedByContactsSorting: // value for 'setCreatedByOnCarCreatedByCreatedByContactsSorting'
 *     setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnCarCreatedByCreatedByProjectChangesPaging: // value for 'setCreatedByOnCarCreatedByCreatedByProjectChangesPaging'
 *     setCreatedByOnCarCreatedByCreatedByProjectChangesFilter: // value for 'setCreatedByOnCarCreatedByCreatedByProjectChangesFilter'
 *     setCreatedByOnCarCreatedByCreatedByProjectChangesSorting: // value for 'setCreatedByOnCarCreatedByCreatedByProjectChangesSorting'
 *     setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setCreatedByOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setCreatedByOnCarCreatedByCreatedByCreatedWorkersPaging: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedWorkersPaging'
 *     setCreatedByOnCarCreatedByCreatedByCreatedWorkersFilter: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedWorkersFilter'
 *     setCreatedByOnCarCreatedByCreatedByCreatedWorkersSorting: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedWorkersSorting'
 *     setCreatedByOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setCreatedByOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setCreatedByOnCarCreatedByCreatedByCreatedContractsPaging: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedContractsPaging'
 *     setCreatedByOnCarCreatedByCreatedByCreatedContractsFilter: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedContractsFilter'
 *     setCreatedByOnCarCreatedByCreatedByCreatedContractsSorting: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedContractsSorting'
 *     setCreatedByOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setCreatedByOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansPaging'
 *     setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansFilter'
 *     setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansSorting'
 *     setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setCreatedByOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setCreatedByOnCarAttachmentsFilter: // value for 'setCreatedByOnCarAttachmentsFilter'
 *     setCreatedByOnCarAttachmentsSorting: // value for 'setCreatedByOnCarAttachmentsSorting'
 *   },
 * });
 */
export function useSetCreatedByOnCarMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetCreatedByOnCarMutation,
        SetCreatedByOnCarMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetCreatedByOnCarMutation,
          SetCreatedByOnCarMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetCreatedByOnCarMutation,
    SetCreatedByOnCarMutationVariables
  >(SetCreatedByOnCarMutationDocument, options);
}
export type SetCreatedByOnCarMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetCreatedByOnCarMutation,
    SetCreatedByOnCarMutationVariables
  >;
export const AddAttachmentsToCarMutationDocument = gql`
  mutation addAttachmentsToCarMutation(
    $input: AddAttachmentsToCarInput!
    $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $addAttachmentsToCarCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $addAttachmentsToCarCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $addAttachmentsToCarCreatedByCreatedByContactsPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByContactsFilter: ContactFilter
    $addAttachmentsToCarCreatedByCreatedByContactsSorting: [ContactSort!]
    $addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToCarCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByProjectChangesFilter: ContactFilter
    $addAttachmentsToCarCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $addAttachmentsToCarCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $addAttachmentsToCarCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $addAttachmentsToCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $addAttachmentsToCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $addAttachmentsToCarCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $addAttachmentsToCarCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $addAttachmentsToCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $addAttachmentsToCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $addAttachmentsToCarAttachmentsFilter: CarAttachmentFilter
    $addAttachmentsToCarAttachmentsSorting: [CarAttachmentSort!]
  ) {
    addAttachmentsToCar(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Evidence
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesPaging
          filter: $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesFilter
          sorting: $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $addAttachmentsToCarCreatedByCreatedByCreatedProjectsPaging
          filter: $addAttachmentsToCarCreatedByCreatedByCreatedProjectsFilter
          sorting: $addAttachmentsToCarCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $addAttachmentsToCarCreatedByCreatedByContactsPaging
          filter: $addAttachmentsToCarCreatedByCreatedByContactsFilter
          sorting: $addAttachmentsToCarCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $addAttachmentsToCarCreatedByCreatedByProjectChangesPaging
          filter: $addAttachmentsToCarCreatedByCreatedByProjectChangesFilter
          sorting: $addAttachmentsToCarCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $addAttachmentsToCarCreatedByCreatedByCreatedWorkersPaging
          filter: $addAttachmentsToCarCreatedByCreatedByCreatedWorkersFilter
          sorting: $addAttachmentsToCarCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $addAttachmentsToCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $addAttachmentsToCarCreatedByCreatedByCreatedContractsPaging
          filter: $addAttachmentsToCarCreatedByCreatedByCreatedContractsFilter
          sorting: $addAttachmentsToCarCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $addAttachmentsToCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansPaging
          filter: $addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $addAttachmentsToCarAttachmentsFilter
        sorting: $addAttachmentsToCarAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useAddAttachmentsToCarMutation__
 *
 * To run a mutation, you first call `useAddAttachmentsToCarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentsToCarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddAttachmentsToCarMutation({
 *   variables: {
 *     input: // value for 'input'
 *     addAttachmentsToCarCreatedByCreatedByCreatedCompaniesPaging: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedCompaniesPaging'
 *     addAttachmentsToCarCreatedByCreatedByCreatedCompaniesFilter: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedCompaniesFilter'
 *     addAttachmentsToCarCreatedByCreatedByCreatedCompaniesSorting: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedCompaniesSorting'
 *     addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     addAttachmentsToCarCreatedByCreatedByCreatedProjectsPaging: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedProjectsPaging'
 *     addAttachmentsToCarCreatedByCreatedByCreatedProjectsFilter: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedProjectsFilter'
 *     addAttachmentsToCarCreatedByCreatedByCreatedProjectsSorting: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedProjectsSorting'
 *     addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     addAttachmentsToCarCreatedByCreatedByContactsPaging: // value for 'addAttachmentsToCarCreatedByCreatedByContactsPaging'
 *     addAttachmentsToCarCreatedByCreatedByContactsFilter: // value for 'addAttachmentsToCarCreatedByCreatedByContactsFilter'
 *     addAttachmentsToCarCreatedByCreatedByContactsSorting: // value for 'addAttachmentsToCarCreatedByCreatedByContactsSorting'
 *     addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToCarCreatedByCreatedByProjectChangesPaging: // value for 'addAttachmentsToCarCreatedByCreatedByProjectChangesPaging'
 *     addAttachmentsToCarCreatedByCreatedByProjectChangesFilter: // value for 'addAttachmentsToCarCreatedByCreatedByProjectChangesFilter'
 *     addAttachmentsToCarCreatedByCreatedByProjectChangesSorting: // value for 'addAttachmentsToCarCreatedByCreatedByProjectChangesSorting'
 *     addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'addAttachmentsToCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     addAttachmentsToCarCreatedByCreatedByCreatedWorkersPaging: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedWorkersPaging'
 *     addAttachmentsToCarCreatedByCreatedByCreatedWorkersFilter: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedWorkersFilter'
 *     addAttachmentsToCarCreatedByCreatedByCreatedWorkersSorting: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedWorkersSorting'
 *     addAttachmentsToCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     addAttachmentsToCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     addAttachmentsToCarCreatedByCreatedByCreatedContractsPaging: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedContractsPaging'
 *     addAttachmentsToCarCreatedByCreatedByCreatedContractsFilter: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedContractsFilter'
 *     addAttachmentsToCarCreatedByCreatedByCreatedContractsSorting: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedContractsSorting'
 *     addAttachmentsToCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     addAttachmentsToCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansPaging: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansPaging'
 *     addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansFilter: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansFilter'
 *     addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansSorting: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansSorting'
 *     addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'addAttachmentsToCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     addAttachmentsToCarAttachmentsFilter: // value for 'addAttachmentsToCarAttachmentsFilter'
 *     addAttachmentsToCarAttachmentsSorting: // value for 'addAttachmentsToCarAttachmentsSorting'
 *   },
 * });
 */
export function useAddAttachmentsToCarMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddAttachmentsToCarMutation,
        AddAttachmentsToCarMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddAttachmentsToCarMutation,
          AddAttachmentsToCarMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddAttachmentsToCarMutation,
    AddAttachmentsToCarMutationVariables
  >(AddAttachmentsToCarMutationDocument, options);
}
export type AddAttachmentsToCarMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddAttachmentsToCarMutation,
    AddAttachmentsToCarMutationVariables
  >;
export const SetAttachmentsOnCarMutationDocument = gql`
  mutation setAttachmentsOnCarMutation(
    $input: SetAttachmentsOnCarInput!
    $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $setAttachmentsOnCarCreatedByCreatedByContactsPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByContactsFilter: ContactFilter
    $setAttachmentsOnCarCreatedByCreatedByContactsSorting: [ContactSort!]
    $setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnCarCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByProjectChangesFilter: ContactFilter
    $setAttachmentsOnCarCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $setAttachmentsOnCarCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $setAttachmentsOnCarCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $setAttachmentsOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $setAttachmentsOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $setAttachmentsOnCarCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $setAttachmentsOnCarCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $setAttachmentsOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $setAttachmentsOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $setAttachmentsOnCarAttachmentsFilter: CarAttachmentFilter
    $setAttachmentsOnCarAttachmentsSorting: [CarAttachmentSort!]
  ) {
    setAttachmentsOnCar(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Evidence
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesPaging
          filter: $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesFilter
          sorting: $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsPaging
          filter: $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsFilter
          sorting: $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $setAttachmentsOnCarCreatedByCreatedByContactsPaging
          filter: $setAttachmentsOnCarCreatedByCreatedByContactsFilter
          sorting: $setAttachmentsOnCarCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $setAttachmentsOnCarCreatedByCreatedByProjectChangesPaging
          filter: $setAttachmentsOnCarCreatedByCreatedByProjectChangesFilter
          sorting: $setAttachmentsOnCarCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $setAttachmentsOnCarCreatedByCreatedByCreatedWorkersPaging
          filter: $setAttachmentsOnCarCreatedByCreatedByCreatedWorkersFilter
          sorting: $setAttachmentsOnCarCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $setAttachmentsOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $setAttachmentsOnCarCreatedByCreatedByCreatedContractsPaging
          filter: $setAttachmentsOnCarCreatedByCreatedByCreatedContractsFilter
          sorting: $setAttachmentsOnCarCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $setAttachmentsOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansPaging
          filter: $setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $setAttachmentsOnCarAttachmentsFilter
        sorting: $setAttachmentsOnCarAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useSetAttachmentsOnCarMutation__
 *
 * To run a mutation, you first call `useSetAttachmentsOnCarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetAttachmentsOnCarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetAttachmentsOnCarMutation({
 *   variables: {
 *     input: // value for 'input'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesPaging'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesFilter'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesSorting'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedProjectsPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedProjectsPaging'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedProjectsFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedProjectsFilter'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedProjectsSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedProjectsSorting'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     setAttachmentsOnCarCreatedByCreatedByContactsPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByContactsPaging'
 *     setAttachmentsOnCarCreatedByCreatedByContactsFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByContactsFilter'
 *     setAttachmentsOnCarCreatedByCreatedByContactsSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByContactsSorting'
 *     setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnCarCreatedByCreatedByProjectChangesPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByProjectChangesPaging'
 *     setAttachmentsOnCarCreatedByCreatedByProjectChangesFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByProjectChangesFilter'
 *     setAttachmentsOnCarCreatedByCreatedByProjectChangesSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByProjectChangesSorting'
 *     setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedWorkersPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedWorkersPaging'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedWorkersFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedWorkersFilter'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedWorkersSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedWorkersSorting'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedContractsPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedContractsPaging'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedContractsFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedContractsFilter'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedContractsSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedContractsSorting'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansPaging: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansPaging'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansFilter'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansSorting'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'setAttachmentsOnCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     setAttachmentsOnCarAttachmentsFilter: // value for 'setAttachmentsOnCarAttachmentsFilter'
 *     setAttachmentsOnCarAttachmentsSorting: // value for 'setAttachmentsOnCarAttachmentsSorting'
 *   },
 * });
 */
export function useSetAttachmentsOnCarMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SetAttachmentsOnCarMutation,
        SetAttachmentsOnCarMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SetAttachmentsOnCarMutation,
          SetAttachmentsOnCarMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SetAttachmentsOnCarMutation,
    SetAttachmentsOnCarMutationVariables
  >(SetAttachmentsOnCarMutationDocument, options);
}
export type SetAttachmentsOnCarMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    SetAttachmentsOnCarMutation,
    SetAttachmentsOnCarMutationVariables
  >;
export const RemoveCreatedByFromCarMutationDocument = gql`
  mutation removeCreatedByFromCarMutation(
    $input: RemoveCreatedByFromCarInput!
    $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeCreatedByFromCarCreatedByCreatedByContactsPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByContactsFilter: ContactFilter
    $removeCreatedByFromCarCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCarCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeCreatedByFromCarCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeCreatedByFromCarCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeCreatedByFromCarCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeCreatedByFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeCreatedByFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeCreatedByFromCarCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeCreatedByFromCarCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeCreatedByFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeCreatedByFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeCreatedByFromCarAttachmentsFilter: CarAttachmentFilter
    $removeCreatedByFromCarAttachmentsSorting: [CarAttachmentSort!]
  ) {
    removeCreatedByFromCar(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Evidence
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsPaging
          filter: $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeCreatedByFromCarCreatedByCreatedByContactsPaging
          filter: $removeCreatedByFromCarCreatedByCreatedByContactsFilter
          sorting: $removeCreatedByFromCarCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeCreatedByFromCarCreatedByCreatedByProjectChangesPaging
          filter: $removeCreatedByFromCarCreatedByCreatedByProjectChangesFilter
          sorting: $removeCreatedByFromCarCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeCreatedByFromCarCreatedByCreatedByCreatedWorkersPaging
          filter: $removeCreatedByFromCarCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeCreatedByFromCarCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeCreatedByFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeCreatedByFromCarCreatedByCreatedByCreatedContractsPaging
          filter: $removeCreatedByFromCarCreatedByCreatedByCreatedContractsFilter
          sorting: $removeCreatedByFromCarCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeCreatedByFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $removeCreatedByFromCarAttachmentsFilter
        sorting: $removeCreatedByFromCarAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useRemoveCreatedByFromCarMutation__
 *
 * To run a mutation, you first call `useRemoveCreatedByFromCarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreatedByFromCarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCreatedByFromCarMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesPaging'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesFilter'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesSorting'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedProjectsPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedProjectsPaging'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedProjectsFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedProjectsFilter'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedProjectsSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedProjectsSorting'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeCreatedByFromCarCreatedByCreatedByContactsPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByContactsPaging'
 *     removeCreatedByFromCarCreatedByCreatedByContactsFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByContactsFilter'
 *     removeCreatedByFromCarCreatedByCreatedByContactsSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByContactsSorting'
 *     removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromCarCreatedByCreatedByProjectChangesPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByProjectChangesPaging'
 *     removeCreatedByFromCarCreatedByCreatedByProjectChangesFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByProjectChangesFilter'
 *     removeCreatedByFromCarCreatedByCreatedByProjectChangesSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByProjectChangesSorting'
 *     removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedWorkersPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedWorkersPaging'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedWorkersFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedWorkersFilter'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedWorkersSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedWorkersSorting'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedContractsPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedContractsPaging'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedContractsFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedContractsFilter'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedContractsSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedContractsSorting'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeCreatedByFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeCreatedByFromCarAttachmentsFilter: // value for 'removeCreatedByFromCarAttachmentsFilter'
 *     removeCreatedByFromCarAttachmentsSorting: // value for 'removeCreatedByFromCarAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveCreatedByFromCarMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveCreatedByFromCarMutation,
        RemoveCreatedByFromCarMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveCreatedByFromCarMutation,
          RemoveCreatedByFromCarMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveCreatedByFromCarMutation,
    RemoveCreatedByFromCarMutationVariables
  >(RemoveCreatedByFromCarMutationDocument, options);
}
export type RemoveCreatedByFromCarMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveCreatedByFromCarMutation,
    RemoveCreatedByFromCarMutationVariables
  >;
export const RemoveAttachmentsFromCarMutationDocument = gql`
  mutation removeAttachmentsFromCarMutation(
    $input: RemoveAttachmentsFromCarInput!
    $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $removeAttachmentsFromCarCreatedByCreatedByContactsPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByContactsFilter: ContactFilter
    $removeAttachmentsFromCarCreatedByCreatedByContactsSorting: [ContactSort!]
    $removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromCarCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByProjectChangesFilter: ContactFilter
    $removeAttachmentsFromCarCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $removeAttachmentsFromCarCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $removeAttachmentsFromCarCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $removeAttachmentsFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $removeAttachmentsFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $removeAttachmentsFromCarAttachmentsFilter: CarAttachmentFilter
    $removeAttachmentsFromCarAttachmentsSorting: [CarAttachmentSort!]
  ) {
    removeAttachmentsFromCar(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Evidence
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesPaging
          filter: $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesFilter
          sorting: $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsPaging
          filter: $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsFilter
          sorting: $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $removeAttachmentsFromCarCreatedByCreatedByContactsPaging
          filter: $removeAttachmentsFromCarCreatedByCreatedByContactsFilter
          sorting: $removeAttachmentsFromCarCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $removeAttachmentsFromCarCreatedByCreatedByProjectChangesPaging
          filter: $removeAttachmentsFromCarCreatedByCreatedByProjectChangesFilter
          sorting: $removeAttachmentsFromCarCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersPaging
          filter: $removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersFilter
          sorting: $removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $removeAttachmentsFromCarCreatedByCreatedByCreatedContractsPaging
          filter: $removeAttachmentsFromCarCreatedByCreatedByCreatedContractsFilter
          sorting: $removeAttachmentsFromCarCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $removeAttachmentsFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansPaging
          filter: $removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $removeAttachmentsFromCarAttachmentsFilter
        sorting: $removeAttachmentsFromCarAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useRemoveAttachmentsFromCarMutation__
 *
 * To run a mutation, you first call `useRemoveAttachmentsFromCarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttachmentsFromCarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveAttachmentsFromCarMutation({
 *   variables: {
 *     input: // value for 'input'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByContactsPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByContactsPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByContactsFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByContactsFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByContactsSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByContactsSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByProjectChangesPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByProjectChangesPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByProjectChangesFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByProjectChangesFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByProjectChangesSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByProjectChangesSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedContractsPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedContractsPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedContractsFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedContractsFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedContractsSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedContractsSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansPaging: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansPaging'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansSorting'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'removeAttachmentsFromCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     removeAttachmentsFromCarAttachmentsFilter: // value for 'removeAttachmentsFromCarAttachmentsFilter'
 *     removeAttachmentsFromCarAttachmentsSorting: // value for 'removeAttachmentsFromCarAttachmentsSorting'
 *   },
 * });
 */
export function useRemoveAttachmentsFromCarMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveAttachmentsFromCarMutation,
        RemoveAttachmentsFromCarMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveAttachmentsFromCarMutation,
          RemoveAttachmentsFromCarMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveAttachmentsFromCarMutation,
    RemoveAttachmentsFromCarMutationVariables
  >(RemoveAttachmentsFromCarMutationDocument, options);
}
export type RemoveAttachmentsFromCarMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveAttachmentsFromCarMutation,
    RemoveAttachmentsFromCarMutationVariables
  >;
export const CreateOneCarMutationDocument = gql`
  mutation createOneCarMutation(
    $input: CreateOneCarInput!
    $createOneCarCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createOneCarCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createOneCarCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createOneCarCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createOneCarCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createOneCarCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createOneCarCreatedByCreatedByContactsPaging: OffsetPaging
    $createOneCarCreatedByCreatedByContactsFilter: ContactFilter
    $createOneCarCreatedByCreatedByContactsSorting: [ContactSort!]
    $createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneCarCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createOneCarCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createOneCarCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createOneCarCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createOneCarCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createOneCarCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createOneCarCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createOneCarCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createOneCarCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createOneCarCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createOneCarCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createOneCarCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createOneCarAttachmentsFilter: CarAttachmentFilter
    $createOneCarAttachmentsSorting: [CarAttachmentSort!]
  ) {
    createOneCar(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Evidence
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createOneCarCreatedByCreatedByCreatedCompaniesPaging
          filter: $createOneCarCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createOneCarCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createOneCarCreatedByCreatedByCreatedProjectsPaging
          filter: $createOneCarCreatedByCreatedByCreatedProjectsFilter
          sorting: $createOneCarCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createOneCarCreatedByCreatedByContactsPaging
          filter: $createOneCarCreatedByCreatedByContactsFilter
          sorting: $createOneCarCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createOneCarCreatedByCreatedByProjectChangesPaging
          filter: $createOneCarCreatedByCreatedByProjectChangesFilter
          sorting: $createOneCarCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createOneCarCreatedByCreatedByCreatedWorkersPaging
          filter: $createOneCarCreatedByCreatedByCreatedWorkersFilter
          sorting: $createOneCarCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createOneCarCreatedByCreatedByCreatedContractsPaging
          filter: $createOneCarCreatedByCreatedByCreatedContractsFilter
          sorting: $createOneCarCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createOneCarCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createOneCarCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createOneCarCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $createOneCarAttachmentsFilter
        sorting: $createOneCarAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCreateOneCarMutation__
 *
 * To run a mutation, you first call `useCreateOneCarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneCarMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createOneCarCreatedByCreatedByCreatedCompaniesPaging: // value for 'createOneCarCreatedByCreatedByCreatedCompaniesPaging'
 *     createOneCarCreatedByCreatedByCreatedCompaniesFilter: // value for 'createOneCarCreatedByCreatedByCreatedCompaniesFilter'
 *     createOneCarCreatedByCreatedByCreatedCompaniesSorting: // value for 'createOneCarCreatedByCreatedByCreatedCompaniesSorting'
 *     createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createOneCarCreatedByCreatedByCreatedProjectsPaging: // value for 'createOneCarCreatedByCreatedByCreatedProjectsPaging'
 *     createOneCarCreatedByCreatedByCreatedProjectsFilter: // value for 'createOneCarCreatedByCreatedByCreatedProjectsFilter'
 *     createOneCarCreatedByCreatedByCreatedProjectsSorting: // value for 'createOneCarCreatedByCreatedByCreatedProjectsSorting'
 *     createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createOneCarCreatedByCreatedByContactsPaging: // value for 'createOneCarCreatedByCreatedByContactsPaging'
 *     createOneCarCreatedByCreatedByContactsFilter: // value for 'createOneCarCreatedByCreatedByContactsFilter'
 *     createOneCarCreatedByCreatedByContactsSorting: // value for 'createOneCarCreatedByCreatedByContactsSorting'
 *     createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneCarCreatedByCreatedByProjectChangesPaging: // value for 'createOneCarCreatedByCreatedByProjectChangesPaging'
 *     createOneCarCreatedByCreatedByProjectChangesFilter: // value for 'createOneCarCreatedByCreatedByProjectChangesFilter'
 *     createOneCarCreatedByCreatedByProjectChangesSorting: // value for 'createOneCarCreatedByCreatedByProjectChangesSorting'
 *     createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createOneCarCreatedByCreatedByCreatedWorkersPaging: // value for 'createOneCarCreatedByCreatedByCreatedWorkersPaging'
 *     createOneCarCreatedByCreatedByCreatedWorkersFilter: // value for 'createOneCarCreatedByCreatedByCreatedWorkersFilter'
 *     createOneCarCreatedByCreatedByCreatedWorkersSorting: // value for 'createOneCarCreatedByCreatedByCreatedWorkersSorting'
 *     createOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createOneCarCreatedByCreatedByCreatedContractsPaging: // value for 'createOneCarCreatedByCreatedByCreatedContractsPaging'
 *     createOneCarCreatedByCreatedByCreatedContractsFilter: // value for 'createOneCarCreatedByCreatedByCreatedContractsFilter'
 *     createOneCarCreatedByCreatedByCreatedContractsSorting: // value for 'createOneCarCreatedByCreatedByCreatedContractsSorting'
 *     createOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createOneCarCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createOneCarCreatedByCreatedByCreatedMiddlemansPaging'
 *     createOneCarCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createOneCarCreatedByCreatedByCreatedMiddlemansFilter'
 *     createOneCarCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createOneCarCreatedByCreatedByCreatedMiddlemansSorting'
 *     createOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createOneCarAttachmentsFilter: // value for 'createOneCarAttachmentsFilter'
 *     createOneCarAttachmentsSorting: // value for 'createOneCarAttachmentsSorting'
 *   },
 * });
 */
export function useCreateOneCarMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneCarMutation,
        CreateOneCarMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneCarMutation,
          CreateOneCarMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneCarMutation,
    CreateOneCarMutationVariables
  >(CreateOneCarMutationDocument, options);
}
export type CreateOneCarMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneCarMutation,
    CreateOneCarMutationVariables
  >;
export const CreateManyCarsMutationDocument = gql`
  mutation createManyCarsMutation(
    $input: CreateManyCarsInput!
    $createManyCarsCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $createManyCarsCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $createManyCarsCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $createManyCarsCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $createManyCarsCreatedByCreatedByContactsPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByContactsFilter: ContactFilter
    $createManyCarsCreatedByCreatedByContactsSorting: [ContactSort!]
    $createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyCarsCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByProjectChangesFilter: ContactFilter
    $createManyCarsCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $createManyCarsCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $createManyCarsCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $createManyCarsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $createManyCarsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $createManyCarsCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $createManyCarsCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $createManyCarsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $createManyCarsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $createManyCarsCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $createManyCarsCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $createManyCarsCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $createManyCarsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $createManyCarsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $createManyCarsAttachmentsFilter: CarAttachmentFilter
    $createManyCarsAttachmentsSorting: [CarAttachmentSort!]
  ) {
    createManyCars(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Evidence
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $createManyCarsCreatedByCreatedByCreatedCompaniesPaging
          filter: $createManyCarsCreatedByCreatedByCreatedCompaniesFilter
          sorting: $createManyCarsCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $createManyCarsCreatedByCreatedByCreatedProjectsPaging
          filter: $createManyCarsCreatedByCreatedByCreatedProjectsFilter
          sorting: $createManyCarsCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $createManyCarsCreatedByCreatedByContactsPaging
          filter: $createManyCarsCreatedByCreatedByContactsFilter
          sorting: $createManyCarsCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $createManyCarsCreatedByCreatedByProjectChangesPaging
          filter: $createManyCarsCreatedByCreatedByProjectChangesFilter
          sorting: $createManyCarsCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $createManyCarsCreatedByCreatedByCreatedWorkersPaging
          filter: $createManyCarsCreatedByCreatedByCreatedWorkersFilter
          sorting: $createManyCarsCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $createManyCarsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $createManyCarsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $createManyCarsCreatedByCreatedByCreatedContractsPaging
          filter: $createManyCarsCreatedByCreatedByCreatedContractsFilter
          sorting: $createManyCarsCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $createManyCarsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $createManyCarsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $createManyCarsCreatedByCreatedByCreatedMiddlemansPaging
          filter: $createManyCarsCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $createManyCarsCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $createManyCarsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $createManyCarsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $createManyCarsAttachmentsFilter
        sorting: $createManyCarsAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useCreateManyCarsMutation__
 *
 * To run a mutation, you first call `useCreateManyCarsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyCarsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyCarsMutation({
 *   variables: {
 *     input: // value for 'input'
 *     createManyCarsCreatedByCreatedByCreatedCompaniesPaging: // value for 'createManyCarsCreatedByCreatedByCreatedCompaniesPaging'
 *     createManyCarsCreatedByCreatedByCreatedCompaniesFilter: // value for 'createManyCarsCreatedByCreatedByCreatedCompaniesFilter'
 *     createManyCarsCreatedByCreatedByCreatedCompaniesSorting: // value for 'createManyCarsCreatedByCreatedByCreatedCompaniesSorting'
 *     createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'createManyCarsCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     createManyCarsCreatedByCreatedByCreatedProjectsPaging: // value for 'createManyCarsCreatedByCreatedByCreatedProjectsPaging'
 *     createManyCarsCreatedByCreatedByCreatedProjectsFilter: // value for 'createManyCarsCreatedByCreatedByCreatedProjectsFilter'
 *     createManyCarsCreatedByCreatedByCreatedProjectsSorting: // value for 'createManyCarsCreatedByCreatedByCreatedProjectsSorting'
 *     createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'createManyCarsCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     createManyCarsCreatedByCreatedByContactsPaging: // value for 'createManyCarsCreatedByCreatedByContactsPaging'
 *     createManyCarsCreatedByCreatedByContactsFilter: // value for 'createManyCarsCreatedByCreatedByContactsFilter'
 *     createManyCarsCreatedByCreatedByContactsSorting: // value for 'createManyCarsCreatedByCreatedByContactsSorting'
 *     createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyCarsCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyCarsCreatedByCreatedByProjectChangesPaging: // value for 'createManyCarsCreatedByCreatedByProjectChangesPaging'
 *     createManyCarsCreatedByCreatedByProjectChangesFilter: // value for 'createManyCarsCreatedByCreatedByProjectChangesFilter'
 *     createManyCarsCreatedByCreatedByProjectChangesSorting: // value for 'createManyCarsCreatedByCreatedByProjectChangesSorting'
 *     createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'createManyCarsCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     createManyCarsCreatedByCreatedByCreatedWorkersPaging: // value for 'createManyCarsCreatedByCreatedByCreatedWorkersPaging'
 *     createManyCarsCreatedByCreatedByCreatedWorkersFilter: // value for 'createManyCarsCreatedByCreatedByCreatedWorkersFilter'
 *     createManyCarsCreatedByCreatedByCreatedWorkersSorting: // value for 'createManyCarsCreatedByCreatedByCreatedWorkersSorting'
 *     createManyCarsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'createManyCarsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     createManyCarsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'createManyCarsCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     createManyCarsCreatedByCreatedByCreatedContractsPaging: // value for 'createManyCarsCreatedByCreatedByCreatedContractsPaging'
 *     createManyCarsCreatedByCreatedByCreatedContractsFilter: // value for 'createManyCarsCreatedByCreatedByCreatedContractsFilter'
 *     createManyCarsCreatedByCreatedByCreatedContractsSorting: // value for 'createManyCarsCreatedByCreatedByCreatedContractsSorting'
 *     createManyCarsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'createManyCarsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     createManyCarsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'createManyCarsCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     createManyCarsCreatedByCreatedByCreatedMiddlemansPaging: // value for 'createManyCarsCreatedByCreatedByCreatedMiddlemansPaging'
 *     createManyCarsCreatedByCreatedByCreatedMiddlemansFilter: // value for 'createManyCarsCreatedByCreatedByCreatedMiddlemansFilter'
 *     createManyCarsCreatedByCreatedByCreatedMiddlemansSorting: // value for 'createManyCarsCreatedByCreatedByCreatedMiddlemansSorting'
 *     createManyCarsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'createManyCarsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     createManyCarsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'createManyCarsCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     createManyCarsAttachmentsFilter: // value for 'createManyCarsAttachmentsFilter'
 *     createManyCarsAttachmentsSorting: // value for 'createManyCarsAttachmentsSorting'
 *   },
 * });
 */
export function useCreateManyCarsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyCarsMutation,
        CreateManyCarsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyCarsMutation,
          CreateManyCarsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyCarsMutation,
    CreateManyCarsMutationVariables
  >(CreateManyCarsMutationDocument, options);
}
export type CreateManyCarsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyCarsMutation,
    CreateManyCarsMutationVariables
  >;
export const UpdateOneCarMutationDocument = gql`
  mutation updateOneCarMutation(
    $input: UpdateOneCarInput!
    $updateOneCarCreatedByCreatedByCreatedCompaniesPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByCreatedCompaniesFilter: CompanyFilter
    $updateOneCarCreatedByCreatedByCreatedCompaniesSorting: [CompanySort!]
    $updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: ContactFilter
    $updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: [ContactSort!]
    $updateOneCarCreatedByCreatedByCreatedProjectsPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByCreatedProjectsFilter: ProjectFilter
    $updateOneCarCreatedByCreatedByCreatedProjectsSorting: [ProjectSort!]
    $updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: ProjectChangeFilter
    $updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: [ProjectChangeSort!]
    $updateOneCarCreatedByCreatedByContactsPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByContactsFilter: ContactFilter
    $updateOneCarCreatedByCreatedByContactsSorting: [ContactSort!]
    $updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneCarCreatedByCreatedByProjectChangesPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByProjectChangesFilter: ContactFilter
    $updateOneCarCreatedByCreatedByProjectChangesSorting: [ContactSort!]
    $updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: ContactFilter
    $updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: [ContactSort!]
    $updateOneCarCreatedByCreatedByCreatedWorkersPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByCreatedWorkersFilter: WorkerFilter
    $updateOneCarCreatedByCreatedByCreatedWorkersSorting: [WorkerSort!]
    $updateOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: AttachmentFilter
    $updateOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: [AttachmentSort!]
    $updateOneCarCreatedByCreatedByCreatedContractsPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByCreatedContractsFilter: ContractFilter
    $updateOneCarCreatedByCreatedByCreatedContractsSorting: [ContractSort!]
    $updateOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: ContractAttachmentFilter
    $updateOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: [ContractAttachmentSort!]
    $updateOneCarCreatedByCreatedByCreatedMiddlemansPaging: OffsetPaging
    $updateOneCarCreatedByCreatedByCreatedMiddlemansFilter: MiddlemanFilter
    $updateOneCarCreatedByCreatedByCreatedMiddlemansSorting: [MiddlemanSort!]
    $updateOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: MiddlemanAttachmentFilter
    $updateOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: [MiddlemanAttachmentSort!]
    $updateOneCarAttachmentsFilter: CarAttachmentFilter
    $updateOneCarAttachmentsSorting: [CarAttachmentSort!]
  ) {
    updateOneCar(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Evidence
      Type
      createdBy {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Username
        Email
        Status
        Role
        createdCompanies(
          paging: $updateOneCarCreatedByCreatedByCreatedCompaniesPaging
          filter: $updateOneCarCreatedByCreatedByCreatedCompaniesFilter
          sorting: $updateOneCarCreatedByCreatedByCreatedCompaniesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Webpage
            Address
            Focus
            Language
            contact {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              ContactPerson
              Email
              Phone
              Description
              Interest
            }
            histories(
              paging: $updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging
              filter: $updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter
              sorting: $updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                ContactPerson
                Email
                Phone
                Description
                Interest
              }
            }
          }
        }
        createdProjects(
          paging: $updateOneCarCreatedByCreatedByCreatedProjectsPaging
          filter: $updateOneCarCreatedByCreatedByCreatedProjectsFilter
          sorting: $updateOneCarCreatedByCreatedByCreatedProjectsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Address
            Type
            Language
            Manager
            ManagerContact
            projectChange {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Description
              State
            }
            histories(
              paging: $updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging
              filter: $updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter
              sorting: $updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              nodes {
                Id
                CreatedAt
                UpdatedAt
                DeletedAt
                Description
                State
              }
            }
          }
        }
        contacts(
          paging: $updateOneCarCreatedByCreatedByContactsPaging
          filter: $updateOneCarCreatedByCreatedByContactsFilter
          sorting: $updateOneCarCreatedByCreatedByContactsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        projectChanges(
          paging: $updateOneCarCreatedByCreatedByProjectChangesPaging
          filter: $updateOneCarCreatedByCreatedByProjectChangesFilter
          sorting: $updateOneCarCreatedByCreatedByProjectChangesSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            ContactPerson
            Email
            Phone
            Description
            Interest
            company {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
              Webpage
              Address
              Focus
              Language
              histories(
                paging: $updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging
                filter: $updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter
                sorting: $updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
        createdWorkers(
          paging: $updateOneCarCreatedByCreatedByCreatedWorkersPaging
          filter: $updateOneCarCreatedByCreatedByCreatedWorkersFilter
          sorting: $updateOneCarCreatedByCreatedByCreatedWorkersSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            Profession
            Type
            attachments(
              filter: $updateOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter
              sorting: $updateOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdContracts(
          paging: $updateOneCarCreatedByCreatedByCreatedContractsPaging
          filter: $updateOneCarCreatedByCreatedByCreatedContractsFilter
          sorting: $updateOneCarCreatedByCreatedByCreatedContractsSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Description
            Type
            Year
            attachments(
              filter: $updateOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter
              sorting: $updateOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
        createdMiddlemans(
          paging: $updateOneCarCreatedByCreatedByCreatedMiddlemansPaging
          filter: $updateOneCarCreatedByCreatedByCreatedMiddlemansFilter
          sorting: $updateOneCarCreatedByCreatedByCreatedMiddlemansSorting
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          nodes {
            Id
            CreatedAt
            UpdatedAt
            DeletedAt
            Name
            Phone
            Email
            Note
            attachments(
              filter: $updateOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter
              sorting: $updateOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting
            ) {
              Id
              CreatedAt
              UpdatedAt
              DeletedAt
              Name
            }
          }
        }
      }
      attachments(
        filter: $updateOneCarAttachmentsFilter
        sorting: $updateOneCarAttachmentsSorting
      ) {
        Id
        CreatedAt
        UpdatedAt
        DeletedAt
        Name
      }
    }
  }
`;

/**
 * __useUpdateOneCarMutation__
 *
 * To run a mutation, you first call `useUpdateOneCarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneCarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneCarMutation({
 *   variables: {
 *     input: // value for 'input'
 *     updateOneCarCreatedByCreatedByCreatedCompaniesPaging: // value for 'updateOneCarCreatedByCreatedByCreatedCompaniesPaging'
 *     updateOneCarCreatedByCreatedByCreatedCompaniesFilter: // value for 'updateOneCarCreatedByCreatedByCreatedCompaniesFilter'
 *     updateOneCarCreatedByCreatedByCreatedCompaniesSorting: // value for 'updateOneCarCreatedByCreatedByCreatedCompaniesSorting'
 *     updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging: // value for 'updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesPaging'
 *     updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter: // value for 'updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesFilter'
 *     updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting: // value for 'updateOneCarCreatedByCreatedByCreatedCompaniesCreatedCompaniesNodesNodesHistoriesSorting'
 *     updateOneCarCreatedByCreatedByCreatedProjectsPaging: // value for 'updateOneCarCreatedByCreatedByCreatedProjectsPaging'
 *     updateOneCarCreatedByCreatedByCreatedProjectsFilter: // value for 'updateOneCarCreatedByCreatedByCreatedProjectsFilter'
 *     updateOneCarCreatedByCreatedByCreatedProjectsSorting: // value for 'updateOneCarCreatedByCreatedByCreatedProjectsSorting'
 *     updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging: // value for 'updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesPaging'
 *     updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter: // value for 'updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesFilter'
 *     updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting: // value for 'updateOneCarCreatedByCreatedByCreatedProjectsCreatedProjectsNodesNodesHistoriesSorting'
 *     updateOneCarCreatedByCreatedByContactsPaging: // value for 'updateOneCarCreatedByCreatedByContactsPaging'
 *     updateOneCarCreatedByCreatedByContactsFilter: // value for 'updateOneCarCreatedByCreatedByContactsFilter'
 *     updateOneCarCreatedByCreatedByContactsSorting: // value for 'updateOneCarCreatedByCreatedByContactsSorting'
 *     updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneCarCreatedByCreatedByContactsContactsNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneCarCreatedByCreatedByProjectChangesPaging: // value for 'updateOneCarCreatedByCreatedByProjectChangesPaging'
 *     updateOneCarCreatedByCreatedByProjectChangesFilter: // value for 'updateOneCarCreatedByCreatedByProjectChangesFilter'
 *     updateOneCarCreatedByCreatedByProjectChangesSorting: // value for 'updateOneCarCreatedByCreatedByProjectChangesSorting'
 *     updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging: // value for 'updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesPaging'
 *     updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter: // value for 'updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesFilter'
 *     updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting: // value for 'updateOneCarCreatedByCreatedByProjectChangesProjectChangesNodesNodesCompanyCompanyHistoriesSorting'
 *     updateOneCarCreatedByCreatedByCreatedWorkersPaging: // value for 'updateOneCarCreatedByCreatedByCreatedWorkersPaging'
 *     updateOneCarCreatedByCreatedByCreatedWorkersFilter: // value for 'updateOneCarCreatedByCreatedByCreatedWorkersFilter'
 *     updateOneCarCreatedByCreatedByCreatedWorkersSorting: // value for 'updateOneCarCreatedByCreatedByCreatedWorkersSorting'
 *     updateOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter: // value for 'updateOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsFilter'
 *     updateOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting: // value for 'updateOneCarCreatedByCreatedByCreatedWorkersCreatedWorkersNodesNodesAttachmentsSorting'
 *     updateOneCarCreatedByCreatedByCreatedContractsPaging: // value for 'updateOneCarCreatedByCreatedByCreatedContractsPaging'
 *     updateOneCarCreatedByCreatedByCreatedContractsFilter: // value for 'updateOneCarCreatedByCreatedByCreatedContractsFilter'
 *     updateOneCarCreatedByCreatedByCreatedContractsSorting: // value for 'updateOneCarCreatedByCreatedByCreatedContractsSorting'
 *     updateOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter: // value for 'updateOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsFilter'
 *     updateOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting: // value for 'updateOneCarCreatedByCreatedByCreatedContractsCreatedContractsNodesNodesAttachmentsSorting'
 *     updateOneCarCreatedByCreatedByCreatedMiddlemansPaging: // value for 'updateOneCarCreatedByCreatedByCreatedMiddlemansPaging'
 *     updateOneCarCreatedByCreatedByCreatedMiddlemansFilter: // value for 'updateOneCarCreatedByCreatedByCreatedMiddlemansFilter'
 *     updateOneCarCreatedByCreatedByCreatedMiddlemansSorting: // value for 'updateOneCarCreatedByCreatedByCreatedMiddlemansSorting'
 *     updateOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter: // value for 'updateOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsFilter'
 *     updateOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting: // value for 'updateOneCarCreatedByCreatedByCreatedMiddlemansCreatedMiddlemansNodesNodesAttachmentsSorting'
 *     updateOneCarAttachmentsFilter: // value for 'updateOneCarAttachmentsFilter'
 *     updateOneCarAttachmentsSorting: // value for 'updateOneCarAttachmentsSorting'
 *   },
 * });
 */
export function useUpdateOneCarMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneCarMutation,
        UpdateOneCarMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneCarMutation,
          UpdateOneCarMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneCarMutation,
    UpdateOneCarMutationVariables
  >(UpdateOneCarMutationDocument, options);
}
export type UpdateOneCarMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneCarMutation,
    UpdateOneCarMutationVariables
  >;
export const UpdateManyCarsMutationDocument = gql`
  mutation updateManyCarsMutation($input: UpdateManyCarsInput!) {
    updateManyCars(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyCarsMutation__
 *
 * To run a mutation, you first call `useUpdateManyCarsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyCarsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyCarsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyCarsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyCarsMutation,
        UpdateManyCarsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyCarsMutation,
          UpdateManyCarsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyCarsMutation,
    UpdateManyCarsMutationVariables
  >(UpdateManyCarsMutationDocument, options);
}
export type UpdateManyCarsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyCarsMutation,
    UpdateManyCarsMutationVariables
  >;
export const DeleteOneCarMutationDocument = gql`
  mutation deleteOneCarMutation($input: DeleteOneCarInput!) {
    deleteOneCar(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
      Description
      Evidence
      Type
    }
  }
`;

/**
 * __useDeleteOneCarMutation__
 *
 * To run a mutation, you first call `useDeleteOneCarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneCarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneCarMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneCarMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneCarMutation,
        DeleteOneCarMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneCarMutation,
          DeleteOneCarMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneCarMutation,
    DeleteOneCarMutationVariables
  >(DeleteOneCarMutationDocument, options);
}
export type DeleteOneCarMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneCarMutation,
    DeleteOneCarMutationVariables
  >;
export const DeleteManyCarsMutationDocument = gql`
  mutation deleteManyCarsMutation($input: DeleteManyCarsInput!) {
    deleteManyCars(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyCarsMutation__
 *
 * To run a mutation, you first call `useDeleteManyCarsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCarsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyCarsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyCarsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyCarsMutation,
        DeleteManyCarsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyCarsMutation,
          DeleteManyCarsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyCarsMutation,
    DeleteManyCarsMutationVariables
  >(DeleteManyCarsMutationDocument, options);
}
export type DeleteManyCarsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyCarsMutation,
    DeleteManyCarsMutationVariables
  >;
export const CreateOneCarAttachmentMutationDocument = gql`
  mutation createOneCarAttachmentMutation(
    $input: CreateOneCarAttachmentInput!
  ) {
    createOneCarAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCreateOneCarAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateOneCarAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCarAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneCarAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneCarAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOneCarAttachmentMutation,
        CreateOneCarAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOneCarAttachmentMutation,
          CreateOneCarAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOneCarAttachmentMutation,
    CreateOneCarAttachmentMutationVariables
  >(CreateOneCarAttachmentMutationDocument, options);
}
export type CreateOneCarAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOneCarAttachmentMutation,
    CreateOneCarAttachmentMutationVariables
  >;
export const CreateManyCarAttachmentsMutationDocument = gql`
  mutation createManyCarAttachmentsMutation(
    $input: CreateManyCarAttachmentsInput!
  ) {
    createManyCarAttachments(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useCreateManyCarAttachmentsMutation__
 *
 * To run a mutation, you first call `useCreateManyCarAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyCarAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateManyCarAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyCarAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateManyCarAttachmentsMutation,
        CreateManyCarAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateManyCarAttachmentsMutation,
          CreateManyCarAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateManyCarAttachmentsMutation,
    CreateManyCarAttachmentsMutationVariables
  >(CreateManyCarAttachmentsMutationDocument, options);
}
export type CreateManyCarAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateManyCarAttachmentsMutation,
    CreateManyCarAttachmentsMutationVariables
  >;
export const UpdateOneCarAttachmentMutationDocument = gql`
  mutation updateOneCarAttachmentMutation(
    $input: UpdateOneCarAttachmentInput!
  ) {
    updateOneCarAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useUpdateOneCarAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateOneCarAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneCarAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneCarAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneCarAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOneCarAttachmentMutation,
        UpdateOneCarAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOneCarAttachmentMutation,
          UpdateOneCarAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOneCarAttachmentMutation,
    UpdateOneCarAttachmentMutationVariables
  >(UpdateOneCarAttachmentMutationDocument, options);
}
export type UpdateOneCarAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOneCarAttachmentMutation,
    UpdateOneCarAttachmentMutationVariables
  >;
export const UpdateManyCarAttachmentsMutationDocument = gql`
  mutation updateManyCarAttachmentsMutation(
    $input: UpdateManyCarAttachmentsInput!
  ) {
    updateManyCarAttachments(input: $input) {
      updatedCount
    }
  }
`;

/**
 * __useUpdateManyCarAttachmentsMutation__
 *
 * To run a mutation, you first call `useUpdateManyCarAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyCarAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManyCarAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyCarAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateManyCarAttachmentsMutation,
        UpdateManyCarAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateManyCarAttachmentsMutation,
          UpdateManyCarAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateManyCarAttachmentsMutation,
    UpdateManyCarAttachmentsMutationVariables
  >(UpdateManyCarAttachmentsMutationDocument, options);
}
export type UpdateManyCarAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateManyCarAttachmentsMutation,
    UpdateManyCarAttachmentsMutationVariables
  >;
export const DeleteOneCarAttachmentMutationDocument = gql`
  mutation deleteOneCarAttachmentMutation(
    $input: DeleteOneCarAttachmentInput!
  ) {
    deleteOneCarAttachment(input: $input) {
      Id
      CreatedAt
      UpdatedAt
      DeletedAt
      Name
    }
  }
`;

/**
 * __useDeleteOneCarAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteOneCarAttachmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneCarAttachmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOneCarAttachmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneCarAttachmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOneCarAttachmentMutation,
        DeleteOneCarAttachmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOneCarAttachmentMutation,
          DeleteOneCarAttachmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOneCarAttachmentMutation,
    DeleteOneCarAttachmentMutationVariables
  >(DeleteOneCarAttachmentMutationDocument, options);
}
export type DeleteOneCarAttachmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOneCarAttachmentMutation,
    DeleteOneCarAttachmentMutationVariables
  >;
export const DeleteManyCarAttachmentsMutationDocument = gql`
  mutation deleteManyCarAttachmentsMutation(
    $input: DeleteManyCarAttachmentsInput!
  ) {
    deleteManyCarAttachments(input: $input) {
      deletedCount
    }
  }
`;

/**
 * __useDeleteManyCarAttachmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyCarAttachmentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCarAttachmentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManyCarAttachmentsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyCarAttachmentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteManyCarAttachmentsMutation,
        DeleteManyCarAttachmentsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteManyCarAttachmentsMutation,
          DeleteManyCarAttachmentsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteManyCarAttachmentsMutation,
    DeleteManyCarAttachmentsMutationVariables
  >(DeleteManyCarAttachmentsMutationDocument, options);
}
export type DeleteManyCarAttachmentsMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteManyCarAttachmentsMutation,
    DeleteManyCarAttachmentsMutationVariables
  >;
